import { AddWorksheetOptions, Cell, CellValue, DataValidation, Workbook } from 'exceljs';
import Excel from './Excel';

interface Column {
  header: string;
  values?: CellValue[];
  validation?: DataValidation;
}

export default function excelCreateBook(
  sheetName: string,
  columns: Column[],
  options: { freezeHeader?: boolean } = {},
): Workbook {
  // create book
  const book = new Excel.Workbook();
  book.creator = 'Edac Platform';
  book.created = new Date();

  // create sheet
  const sheetOptions: Partial<AddWorksheetOptions> = {};
  if (options.freezeHeader) {
    sheetOptions.views = [{ state: 'frozen', ySplit: 1 }];
  }
  const sheet = book.addWorksheet(sheetName, sheetOptions);

  // Step 1: Create a hidden sheet for dropdown options
  const hiddenSheet = book.addWorksheet('Options');
  hiddenSheet.state = 'hidden'; // Hide the sheet

  // Step 2: Add columns with validation to the main sheet
  columns.forEach(({ header, values, validation }, index) => {
    const col = sheet.getColumn(index + 1);
    col.values = [header, ...(values || [])];
   

    // Get the Excel column character (A, B, C, etc.)
    const columnChar = String.fromCharCode(65 + index); // 65 is the ASCII code for 'A'
    

    // apply Data Validation
    if (validation && col.eachCell) {
      col.eachCell({ includeEmpty: true }, (cell: Cell, rowNumber: number) => {
        if (rowNumber === 1) return; // skip data validation for header

        // Step 3: Handle long dropdowns via hidden sheet reference
        if (validation.type === 'list' && Array.isArray(validation.formulae)) {
          const options = validation.formulae;

          // Step 4: Ensure that the options are split correctly and clean quotes
          const dropdownEntries = options[0]
            .split(',')                     // Split the single string by commas
            .map(entry => entry.trim())      // Trim any spaces around each name
            .map(entry => entry.replace(/^"|"$/g, ''));  // Remove leading/trailing quotes

          // Add each option to a new row in the hidden sheet
          dropdownEntries.forEach((entry, optionIndex) => {
            hiddenSheet.getCell(`${columnChar}${optionIndex + 1}`).value = entry;
          });

          // Reference the range in the hidden sheet
          const rangeReference = `'Options'!$${columnChar}$1:$${columnChar}$${dropdownEntries.length}`;
          cell.dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [rangeReference],  // Correct reference to the range
            error: 'Invalid selection',  // Optional: Add custom error message
          };
        } else {
          // Normal validation for shorter lists
          cell.dataValidation = validation;
        }
      });
    }

    // cell format
    col.width = 20;
  });

  // format header
  const header = sheet.getRow(1);
  header.height = 20;
  header.alignment = { vertical: 'middle' };
  header.font = { bold: true };
  header.border = {
    bottom: { style: 'double', color: { argb: '00000000' } },
  };

  return book;
}
