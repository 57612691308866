import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useMutation } from '@apollo/client';
import {
  getContractor,
  getContractorVariables,
  removeRequestVariables,
  removeRequest as removeRequestGQL,
  getContractor_contractor as Contractor,
} from 'models/graphql';
import { GET_CONTRACTOR, REMOVE_REQUEST } from 'containers/shared/graphql';
import AppButton from 'components/AppButton';
import Rating from 'components/Rating';
import RemoveDialog from 'components/RemoveDialog';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import useParentLocation from 'utils/useParentLocation.hook';
import useText from 'texts/useText.hook';
import { useNavigate, useParams } from 'react-router';

const Item: React.FC<{ name: string; value?: string }> = ({ name, value }) => (
  <Box sx={{
    mb: 3
  }}>
    <Typography>{name}</Typography>
    <Typography sx={{
      color: "textSecondary"
    }}>{value}</Typography>
  </Box>
);

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    background: '#f3f4f9',
    width: '175px',
    height: '175px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stars: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const ClientContractorPage: React.FC = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const parentUrl = useParentLocation();
  const { contractorId, clientId } = useParams<{
    contractorId: string;
    clientId: string;
  }>();
  const { data, loading } = useQuery<getContractor, getContractorVariables>(GET_CONTRACTOR, {
    variables: { id: contractorId },
  });
  const [removeRequest, { loading: removeRequestLoading }] = useMutation<removeRequestGQL, removeRequestVariables>(
    REMOVE_REQUEST,
    {
      update(cache, { data: cachedData }) {
        if (!cachedData) return;
        apolloCacheEvictQuery({ cache, query: 'contractorRequests' });
      },
      onCompleted: () => navigate(parentUrl),
    },
  );

  const [removeAccessDialogOpen, setRemoveAccessDialogOpen] = useState(false);
  const closeRemoveAccessDialog = () => setRemoveAccessDialogOpen(false);
  const openRemoveAccessDialog = () => {
    setRemoveAccessDialogOpen(true);
  };

  const { tt } = useText('contractors');

  const renderExpertise = (row: Contractor | undefined) =>
    !row ? '' : row.expertise.map((expertise) => tt('contractors')('expertises')(expertise)).join(', ');

  return <>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}>
        <div>
          <AppButton
            color="primary"
            variant="outlined"
            onClick={() => {
              openRemoveAccessDialog();
            }}
          >
            Remove Access
          </AppButton>
          {removeRequestLoading && (
            <div>
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
      </Box>
      {loading ? (
        <CircularProgress color="primary" />
      ) : (
        <Box sx={{
          display: "flex"
        }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1
            }}>
            <Item name="Company Name" value={data?.contractor.companyName} />
            <Item name="Address" value={data?.contractor.address} />
            <Item name="Contact Name" value={data?.contractor.contactName} />
            <Item name="Contact Email" value={data?.contractor.contactEmail} />
            <Item name="Expertise" value={renderExpertise(data?.contractor)} />
            <Box sx={{
              mb: 3
            }}>
              <Typography>Rating</Typography>
              <Rating
                addMargin
                rating={data?.contractor.rating}
                noRating={<Typography sx={{
                  color: "textSecondary"
                }}>n/a</Typography>}
              />
            </Box>
          </Box>
          <Box sx={{
            flex: 1
          }}>
            <Typography>Logo</Typography>
            <Box className={styles.logo}>
              {data?.contractor.logo?.srcUrl && (
                <img src={data?.contractor.logo?.srcUrl} alt={`logo of ${data?.contractor.companyName}`} />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
    <RemoveDialog
      open={removeAccessDialogOpen}
      title="Remove Client Access?"
      descriptionText="All the ongoing works will be cancelled."
      agreementText="I understand that the contractor will not be able to continue with works from the client."
      onSend={async () => {
        await removeRequest({
          variables: {
            contractorId,
            clientId,
          },
        });
      }}
      onClose={closeRemoveAccessDialog}
    />
  </>;
};

export default ClientContractorPage;
