import React, { useEffect, useState } from 'react';
import { ApolloProvider, ApolloClient, from, InMemoryCache, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { useStores } from 'stores';
import { apiRoot } from 'utils/Api';
import GraphqlErrorCodes from 'shared/graphqlErrorCodes';
import { useNavigate } from 'react-router';

const Apollo: React.FC = ({ children }) => {  
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);

  useEffect(() => {
    const apollo = new ApolloClient({
      uri: `${apiRoot}graphql`,
      credentials: 'include',
      cache: new InMemoryCache(),
      link: from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path, extensions }) => {
              if (extensions && extensions.code === GraphqlErrorCodes.requires2fa) {
                navigate('/auth/sms');
              }
              if (!ENV_PRODUCTION) {
                // eslint-disable-next-line no-console
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
              }
            });
          }
          if (networkError) {
            if ('statusCode' in networkError && networkError.statusCode === 401) {
              authStore.reset();
            }
          }
        }),
        new HttpLink({
          uri: `${apiRoot}graphql`,
          credentials: 'include',
        }),
      ]),
    });
    setClient(apollo);
  }, [])

  if (client) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  } else {
    return null;
  }
};

export default Apollo;
