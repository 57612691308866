import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import questionIcon from 'assets/questionIcon.svg';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0)',
    border: '1px solid #000000',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  arrow: {
    color: theme.palette.common.white,
    border: '1px solid #000000',
  },
}))(Tooltip);

const AppTooltip: React.FC<{
  note?: string;
  open?: boolean;
  disableFocusListener?: boolean;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
}> = ({ note, open, disableFocusListener, disableHoverListener, disableTouchListener }) => {
  if (note && note !== '') {
    return (
      <LightTooltip
        title={note}
        placement="top-start"
        open={open}
        disableFocusListener={disableFocusListener}
        disableHoverListener={disableHoverListener}
        disableTouchListener={disableTouchListener}
      >
        <img alt="note" src={questionIcon} />
      </LightTooltip>
    );
  }
  return null;
};

export default AppTooltip;
