/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, FormikErrors } from 'formik';
import { addYears, endOfDay } from 'date-fns';
import { useStores } from 'stores';
import cancelIcon from 'assets/cancelIcon.svg';
import useSearchParams from 'utils/useSearchParams';
import AppIconButton from 'components/AppIconButton';
import { AppDateField, AppFieldError, AppForm, AppFormControlLabel, AppSwitch } from 'components/form';
import AppSelectMultipleField from 'components/form/AppSelectMultipleField';
import { FormLayoutTask, FullScreenPage } from 'components/layout';
import useText from 'texts/useText.hook';
import switcher from 'utils/switcher';
import useCancel from 'utils/useCancel.hook';
import Yup from 'utils/yup';
import currentWeek from 'utils/currentWeek';
import enumToArray from 'utils/enumToArray';
import styleUtils from 'style/styleUtils';
import AppTextField from 'components/form/AppTextField';                // FW addition for title
import ButtonSelector from 'components/form/ButtonSelector';
import AppSelectField from 'components/form/AppSelectField';
import AppTextAreaField from 'components/form/AppTextAreaField';
import AppFormFieldsRow from 'components/form/AppFormFieldsRow';
import FileInput from 'components/FileInput';
import useArrayOfObjectsState from 'utils/useArrayOfObjectsState';
import FilesList from 'components/FileInput/FilesList';
import apolloCacheEvict, { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import assetAvailableTaskTypes from 'shared/assetAvailableTaskTypes';
import ReOccurenceField from 'components/form/ReOccurenceField';
import { dayIndexes, weekDays } from 'shared/days';
import { monthIndexes } from 'shared/months';
import {
  reOccurenceResolveInput,
  reOccurenceValidationSchema,
} from 'components/form/ReOccurenceField/ReOccurenceField';
import taskEditableFields from 'shared/taskEditableFields';
import TaskTypeIcon from 'containers/shared/TaskTypeIcon';
import formatDayDate, { readDayDate } from 'shared/formatDayDate';
import useFieldStyles from 'components/form/AppField.style';
import AppButton from 'components/AppButton';
import WarningDialog from 'components/WarningDialog';
import {
  CREATE_TASK,
  GET_CLIENT_FOR_TASK_CREATION,
  GET_TASK_FOR_EDITION,
  UPDATE_TASK,
} from 'containers/Client/Tasks/graphql';
import {
  Day,
  getClientForTaskCreation,
  getClientForTaskCreationVariables,
  getClientForTaskCreation_client_requests as Request,
  ReOccurenceInput,
  ReOccurenceType,
  ReOccurenceWeekIndex,
  SystemType,
  taskCreate,
  taskCreateVariables,
  taskForEdition,
  taskForEditionVariables,
  TaskType,
  taskUpdate,
  taskUpdateVariables,
  AssetType
} from 'models/graphql';
import AppCheckboxField from 'components/form/AppCheckbox';
import Swal from 'sweetalert2';
import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { matchRoutes, useNavigate, useParams, useLocation } from 'react-router';

export interface assetListingStruc {
  systemtype(systemtype: any): unknown;
  id: string;
  name: string;
  type: string;
  systype: SystemType;
  sysid: string;
  compliant: boolean;
  __typename: string;

}

export interface subforms {
  subform: string;
}

export interface Project {
  id: string;
  projectname: string;
  startdate: Date;
  enddate: Date;
}


// Styles
const useStyles = makeStyles((theme: Theme) => {
  const taskBoxSize = 180;

  return createStyles({
    taskTypesContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    taskTypesFooter: {
      display: 'flex',
      marginTop: theme.spacing(3),
      whiteSpace: 'pre-wrap',
    },
    taskTypesCancel: {
      marginLeft: 'auto',
    },
    taskTypesPrevious: {
      paddingRight: '50px',
      marginLeft: '80%',

    },
    taskTypes: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '50px 50px',
    },
    taskType: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3),
      width: taskBoxSize,
      height: taskBoxSize,
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius * 2,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      transition: theme.transitions.create(['background'], {
        duration: theme.transitions.duration.short,
      }),

      '&:hover': {
        background: theme.palette.grey[100],
      },
    },
    taskTypeIcon: {
      flexGrow: 1,
      ...styleUtils.rowCenterCenter,
    },
    filesInput: {
      width: 330,
    },
    link: {
      color: theme.palette.primary.main,
    },
    buttons: {
      gridColumnStart: 2,
      gridRowStart: 2,
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('lg')]: {
        marginTop: theme.spacing(3),
        gridColumnStart: 1,
        gridRowStart: 3,
      },
      '&>*': {
        marginLeft: theme.spacing(3),
      },
    },

  });
});

// Phases
enum Phase {
  'asset',
  'type',
  'details',
} // 0,1,2. 2 Being the value for Edit or Corrective Action

export interface subFormStruc {
  id: string;
  formtype: string;
  assettype: string;
  active: boolean;
  subform: string;
}

// Function to check if a date is between two other dates
const isDateInRange = (date: any, start: any, end: any,) => {
  console.log("In isDateInRange Date passed to be checked:", date)

  console.log("start Date passed to be checked:", start)
  console.log("end Date passed to be checked:", end)

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  let dateagain = new Date(year + ' ' + month + ' ' + day);

  console.log("Date passed to be checked again:", new Date(dateagain))

  
  return dateagain >= start && dateagain <= end;

};

/*interface Project {
  id: number;
  startdate: string; // Use string or Date depending on your format
  enddate: string;
} */

function formatDate(isoDate: any) {
  // Create a Date object from the ISO string

  console.log("AM IN formatDate")
  if (isoDate) {

    const date = new Date(isoDate);


    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    // Return the formatted date string as DD/MM/YYYY
    return `${day}/${month}/${year}`;


  }
  else {
    return ` `;


  }

}

// For Select fields
export const availableContractors: (
  availableRequests: Request[],
  assignedContractor?: { id: string; companyName: string; logo?: { srcUrl: string } | null },
) => Array<{ id: string; companyName: string; logo?: { srcUrl: string } | null }> = (
  availableRequests,
  assignedContractor,
) => {
    const contractors: Array<{
      id: string;
      companyName: string;
      logo?: { srcUrl: string } | null;
    }> = availableRequests.map((request) => request.contractor);

    if (assignedContractor) {
      if (!contractors.find((contractor) => contractor.id === assignedContractor.id)) {
        contractors.push(assignedContractor);
      }
    }

    return contractors;
  };

const availableWorkOrderIDs: (
  availableRequests: Request[],
  contractorId?: string,
  assignedWorkOrderId?: string,
) => string[] = (availableRequests, contractorId, assignedWorkOrderId) => {
  const workOrderIDs: string[] =
    availableRequests
      .find((request) => request.contractor.id === contractorId)
      ?.workOrders?.filter((workOrder) => {
        return endOfDay(new Date(workOrder.expiracy)) > new Date();
      })
      ?.map((workOrder) => workOrder.id) || [];

  if (assignedWorkOrderId) {
    if (!workOrderIDs.find((workOrderID) => workOrderID === assignedWorkOrderId)) {
      workOrderIDs.push(assignedWorkOrderId);
    }
  }

  return workOrderIDs;
};

// Form
enum ResponsibleType {
  internalResponsible = 'internalResponsible',
  contractorResponsible = 'contractorResponsible',
}

interface FormValues {
  systemTypes: SystemType[];
  assetIds: string[];
  type?: TaskType;
  contractorId?: string;
  workOrderId?: string;
  internalUserId?: string;
  approvalUserId?: string;
  tasktitle?: string;
  notes?: string;
  deadline?: Date;
  reOccurence: ReOccurenceInput;
  documentDeadline?: Date;
  urgent?: boolean;
  applyChangesToFutureOccurences?: boolean;
  subform?: string;
  assetsList?: any[];
  taskstart?: Date;
  projectid?: string;
  selprojstart?: string;
  selprojendset?: string;
}

const TaskCreatePage: React.FC = () => {
  const { clientId, siteId, taskId } = useParams<{
    clientId: string;
    siteId: string;
    taskId?: string;
  }>();
  const navigate = useNavigate();
  const location = useLocation();

  let assetIdVal = useSearchParams('assetId');
  const { contextStore } = useStores();
  let assetAvailableTaskTypesDyn: { [Key in AssetType]: TaskType[] }    // will populate from table

  if (taskId) {

    // TaskID Passed so get the docsrequired.  Alternatively if documentdeadline is blank, then don't require
    //console.log("ON ENTRY. TASK ID IS:", taskId)
    // gets here

  }


  // Now set assetIds value after a check
  let assetIds: string[] | undefined;
  let assettasktypelist;

  let corTypes: never[] = [];


  if (assetIdVal[0] === undefined) {
    assetIds = location.state?.assetIds;
  }
  else {
    assetIds = assetIdVal;
  }


  const curSelectedModulesStorage = window.localStorage.getItem('selectedModules');
  let preselectedmodules: string | any[] = []
  if (curSelectedModulesStorage) {
    preselectedmodules = JSON.parse(curSelectedModulesStorage);

  }
  console.log("Line 351 preselectedmodules:", preselectedmodules)
  const [selectedModules, setSelectedModules] = useState<string[] | null>();


  let correctiveMode = false;
  const correctiveMatches = matchRoutes([
    { path: `/client/${clientId}/tasks/${siteId}/${taskId}/corrective` },
  ], location);
  if (correctiveMatches && correctiveMatches?.length > 0) {
    correctiveMode = true;
  }  

  let duplicateMode = false;
  const duplicateMatches = matchRoutes([
    { path: `/client/${clientId}/tasks/${siteId}/${taskId}/duplicate` },
  ], location);
  if (duplicateMatches && duplicateMatches?.length > 0) {
    duplicateMode = true;
  }

  const editMode = !correctiveMode && !!taskId && !duplicateMode;
  const [connectedAssetsError, setConnectedAssetsError] = useState(false);
  const currentWeekString = currentWeek();

  // Modules Related

  const [selectedModule, setSelectedModule] = useState<string | null>();

  const [modulesAvailable, setModulesAvailable] = useState<boolean | false>(false);



  // Sub Forms Selection
  const [subFormsListing, setSubFormsListing] = useState<subFormStruc[] | []>();
  const [subFormsList, setSubFormsList] = useState<any | null>(null);
  const [selectedSubFormTemplate, setSelectedSubFormTemplate] = useState<string | null>(null);
  const [showSubForms, setShowSubForms] = useState<boolean | false>(false);
  const [availableForms, setAvailableForms] = useState<[] | []>();
  const [docsRequired, setDocsRequired] = useState<boolean | true>(true);

  const [showProjects, setShowProjects] = useState<boolean | false>(false);
  const [minDateProj, setMinDateProj] = useState(new Date());
  const [maxDateProj, setMaxDateProj] = useState(new Date());
  const [selMaxDateProj, setSelMaxDateProj] = useState('');

  const [projectStart, setProjectStart] = useState(null);
  const [projectEnd, setProjectEnd] = useState(null);

  const [projDatesMatch, setProjDatesMatch] = useState<boolean | false>(false);


  const fetchSubForms = async () => {
    // Perform your data fetching logic here
    // For example, make an API call using axios or fetch
    const urltogoto = 'forms/subforms';
    let dataret: any[];
    let dataretforms: any[];
    let dataretassets: any[];
    let fullurl = `forms/subforms?siteid=${siteId}`;
    let curmodule;


    if (preselectedmodules && preselectedmodules.length === 1) {
      curmodule = preselectedmodules[0];
      fullurl = `forms/subforms?siteid=${siteId}&module=${curmodule}`
    }


    const response = await fetch(`${process.env.API_ROOT}${fullurl}`, {
      method: 'GET',
      credentials: 'include',
    })
    const data = await response.json();
    return data;
  };



  // Changes for REST API
  let clientDataLoading = true;
  let taskDataLoading = false;

  const [correctiveSystemTypes, setCorrectiveSystemTypes] = useState([]);


  const [dataRequired, setDataRequired] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [loadingSystems, setLoadingSystems] = useState(true);

  const [projectsList, setProjectsList] = useState<Project[] | []>([]);
  const [selProject, setSelProject] = useState<Project | null>(null);



  const getSiteProjects = async (siteid: any) => {

    let getProjectsLink = `projects/project/${siteid}?inform=Y`
    console.log("getProjectsLink:", getProjectsLink)
    try {
      fetch(`${process.env.API_ROOT}${getProjectsLink}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response: any) => {
        const dataret = await response.json();
        console.log("dataret:", dataret)




        setProjectsList(dataret)
      });
    }
    catch (error) {
      console.error(error); /* eslint-disable-line */
    }

  }

  useEffect(() => {

    getSiteProjects(siteId)
    console.log("Will show Projects")

    if (!showProjects) {
      setMinDateProj(new Date());
      const newDate = new Date();
      newDate.setFullYear(newDate.getFullYear() + 10);
      setMaxDateProj(newDate);
    }

    if (task?.projectid) {

      // find the project
      const passedTask = projectsList.find((prj) => prj.id === task?.projectid);

      console.log("passedTask at 484:", passedTask)

      if (passedTask)
      {
          // No Need for following 2 lines as being done in selProject useEffect
       //   setSelMaxDateProj(new Date(passedTask?.enddate));
        //  setMinDateProj(new Date(passedTask?.startdate));

          
       //   setSelProject(passedTask);


      }
  
      


    }


  }, [showProjects]);

  useEffect(() => {
    
    console.log("510 IN SEL PRoJECT")

    console.log("Selected Project IS:", selProject)

    if (selProject)
    {
     
        console.log("Selected Project AT 497:", selProject.id)
     
        console.log("startdate date:", new Date(selProject.startdate));


        console.log("passedTask Deadline at 517: ", task?.deadline)
  
     // Comment Out for Moment
        setSelMaxDateProj(new Date(selProject.enddate));
        setMinDateProj(new Date(selProject.startdate));
        setMaxDateProj(new Date(selProject.enddate));
  
        if (task)
        {
          const result =  isDateInRange(new Date(task.deadline), new Date(selProject.startdate), new Date(selProject.enddate));
          console.log("Dates Check Result 527:", result)
          if (result === true) {
                  console.log("Setting Dates Banding Here at 528")
                  setProjDatesMatch(true)
          }

          setSelMaxDateProj(new Date(selProject.enddate));
          setMinDateProj(new Date(selProject.startdate));
          setMaxDateProj(new Date(selProject.enddate));
        }
     
      

    }
  


  }, [selProject]);



  const fetchData = async () => {
    // Perform your data fetching logic here
    // For example, make an API call using axios or fetch
    const urltogoto = 'listings/systemtypes/';
    let dataret: any[];
    let dataretforms: any[];
    let dataretassets: any[];
    let fullurl = `listings/systemtypes?siteid=${siteId}`;

    let curmodule;

    if (preselectedmodules && preselectedmodules.length === 1) {
      curmodule = preselectedmodules[0];
      fullurl = `listings/systemtypes?siteid=${siteId}&module=${curmodule}`
    }


    const response = await fetch(`${process.env.API_ROOT}${fullurl}`, {
      method: 'GET',
      credentials: 'include',
    })
    const data = await response.json();
    return data;
  };

  const fetchDataAssets = async () => {
    // Perform your data fetching logic here
    // For example, make an API call using axios or fetch
    const urltogoto = 'listings/assetsfortask/';
    let dataret: any[];
    let dataretforms: any[];
    let dataretassets: any[];
    let fullurl = urltogoto + siteId;


    const response = await fetch(`${process.env.API_ROOT}${fullurl}`, {
      method: 'GET',
      credentials: 'include',
    })
    const data = await response.json();
    return data;
  };

  const fetchDataTaskTypes = async () => {
    // Perform your data fetching logic here
    // For example, make an API call using axios or fetch
    const urltogoto = `listings/sitetypes?siteid=`;
    let dataret: any[];
    let dataretforms: any[];
    let dataretassets: any[];
    let fullurl = `listings/sitetypes?siteid=${siteId}`
    let curmodule;



    if (preselectedmodules && preselectedmodules.length === 1) {
      console.log("At 544 :", preselectedmodules[0])
      curmodule = preselectedmodules[0];
      fullurl = `listings/sitetypes?siteid=${siteId}&module=${curmodule}`
    }

    const response = await fetch(`${process.env.API_ROOT}${fullurl}`, {
      method: 'GET',
      credentials: 'include',
    })
    const data = await response.json();
    return data;
  };


  // Queries & Mutations
  const { data: clientData, loading: clientDataLoadingGQL } = useQuery<
    getClientForTaskCreation,
    getClientForTaskCreationVariables
  >(GET_CLIENT_FOR_TASK_CREATION, { variables: { clientId, siteId } });
  const client = clientData?.client;
  const site = clientData?.site;




  // changes to use REST APIs
  let mytestlist: assetListingStruc[] = [];

  const [sysTypesListing, setSysTypesListing] = useState<SystemType[] | undefined>();

  const [taskTypesListing, setTaskTypesListing] = useState<TaskType[] | undefined>();

  const [assetsListing, setAssetsListing] = useState<assetListingStruc[] | undefined>();



  const { data: taskData, loading: taskDataLoadingGQL } = useQuery<taskForEdition, taskForEditionVariables>(
    GET_TASK_FOR_EDITION,
    {
      skip: !taskId,
      variables: { taskId: taskId! },
    },
  );
  const task = taskData?.task;

  const [updateTask] = useMutation<taskUpdate, taskUpdateVariables>(UPDATE_TASK);

  const [createTask] = useMutation<taskCreate, taskCreateVariables>(CREATE_TASK, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Site',
        id: siteId,
        fieldName: 'tasks',
      }),
  });

  // Texts
  const { t } = useText('tasks', 'common', 'systemTypes', 'assetTypes', 'taskTypes');



  // Phases




  let initialPhase: Phase;
  /*  if (editMode || correctiveMode) {
      initialPhase = Phase.details;
    } else if (assetIds && assetIds.length) {
      initialPhase = Phase.type;
    } else {
      initialPhase = Phase.asset;
    } */

  if (editMode) {
    initialPhase = Phase.details;
  } else if (assetIds && assetIds.length) {
    initialPhase = Phase.type;
  }
  else if (correctiveMode) {
    initialPhase = Phase.asset;

    // could do checks here



  } else {
    initialPhase = Phase.asset;
  }




  const [phase, goToPhase] = useState<Phase>(initialPhase);

  useEffect(() => {

    console.log("CorrectiveMode has been set", correctiveMode)
    console.log("isLoading:", isLoading)
    console.log("duplicateMode has been set", duplicateMode)
    if (!isLoading) {

      if ((correctiveMode && task) || (duplicateMode && task)) {
        console.log("isLoading is false, should now have task")
        console.log("task is:", task)

        let corTypesToAdd = new Array();
        // loop through the task.assets and check the system value.  Add each one to the corrective System type if they're not already in it
        for (let i = 0; i < task.assets.length; i++) {
          console.log("Task Asset Is:", task.assets[i]);
          let idtofind = task.assets[i].asset.id;
          let fullasset = assetsListing.find((_asset) => _asset.id === idtofind)
          console.log("fullasset system type", fullasset.systemtype);

          if (!corTypes.includes(fullasset.systemtype)) {
            corTypes.push(fullasset.systemtype)
          }
          if (!corTypesToAdd.includes(fullasset.systemtype)) {
            corTypesToAdd.push(fullasset.systemtype)
          }

        }
        console.log("LINE 532, after loop, corTypes is", corTypes)
        console.log("LINE 533, after loop, corTypesToAdd is", corTypesToAdd)

        setCorrectiveSystemTypes(corTypesToAdd);
        setLoadingSystems(false)

      }


    }



  }, [isLoading])
  // Moved


// Initial useEffect with no parameter

  useEffect(() => {

    // get the subforms

    setMinDateProj(new Date())

    fetchDataTaskTypes().then((response) => {
      // setDataRequired(response);
      setTaskTypesListing(response);
    }).then(async () => {

      await fetchSubForms().then((response) => {

        const groupedData = response.reduce((result, item) => {
          const index = result.findIndex(group => group[0].assettype === item.assettype);
          if (index !== -1) {
            result[index].push(item);
          } else {
            result.push([item]);
          }
          return result;
        }, []);
        setSubFormsListing(groupedData);
        setAvailableForms(response);
      })


    }).then(async () => {

      await fetchDataAssets().then((response) => {
        // setDataRequired(response);


        setAssetsListing(response);
      })
    }).then(async () => {
      await fetchData().then((response) => {
        // setDataRequired(response);
        // will check if corrective mode and set the assets in advance
        setSysTypesListing(response);



        setIsLoading(false);
      });
    })

  }, []);



  // Types
  const [responsibleType, setResponsibleType] = useState<ResponsibleType>(ResponsibleType.internalResponsible);
  useEffect(() => {
    if (responsibleType !== ResponsibleType.internalResponsible && task?.internalResponsible) {
      setResponsibleType(ResponsibleType.internalResponsible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task?.internalResponsible?.id]);

  const [taskType, setTaskType] = useState<TaskType | undefined>();
  const [assetType, setAaskType] = useState<string | undefined>();



  useEffect(() => {
    if (!taskType && task && task.type) {
      setTaskType(task.type);
    }
    // console.log("task?.documentDeadline :", task?.documentDeadline )
    if (task?.documentDeadline === null) {
      setDocsRequired(false);
    }



    if ((correctiveMode && task && assetsListing) || (duplicateMode && task && assetsListing)) {


      let corTypesToAdd = new Array();
      // loop through the task.assets and check the system value.  Add each one to the corrective System type if they're not already in it
      for (let i = 0; i < task.assets.length; i++) {

        let idtofind = task.assets[i].asset.id;
        let fullasset = assetsListing.find((_asset) => _asset.id === idtofind)


        if (!corTypes.includes(fullasset.systemtype)) {
          corTypes.push(fullasset.systemtype)
        }
        if (!corTypesToAdd.includes(fullasset.systemtype)) {
          corTypesToAdd.push(fullasset.systemtype)
        }

      }


      setCorrectiveSystemTypes(corTypesToAdd);
      setLoadingSystems(false)

    }

    if (clientData?.client) {
      if (clientData?.client.modules) {
        const filteredModules = Object.entries(clientData?.client.modules)
          .filter(([key, value]) => !value) // Filter by value being true
          .map(([key]) => key); // Extract the key

        console.log("filteredModules:", filteredModules)
        setSelectedModules(filteredModules)

      }

    }

    if (task?.projectid) {

      // project has been added to task so enable
      console.log("Enable projects")
      setShowProjects(true);

    }

  }, [task, taskType]);



  const [taskTypeHasNoFormTemplateError, setTaskTypeHasNoFormTemplateError] = useState<string | undefined>();

  // Files
  const [files, addToFiles, removeFromFiles, setFiles] = useArrayOfObjectsState<{
    id: string;
    name: string;
  }>();
  useEffect(() => {
    if (task?.attachments) {
      setFiles(task.attachments.map((attachment) => ({ id: attachment.id, name: attachment.originalName })));
    }
  }, [setFiles, task?.attachments]);

  // Cancel
  const parentHref = `/client/${clientId}/tasks/${siteId}?${currentWeekString}`;
  const cancel = useCancel(parentHref);

  const styles = useStyles();
  const fieldStyles = useFieldStyles();
  let initialDay = dayIndexes[new Date().getDay()];
  if (!weekDays.includes(initialDay)) initialDay = Day.MONDAY;
  let title: string;
  if (editMode) {
    title = t('tasks')('editTask');
  } else if (correctiveMode) {
    title = t('tasks')('createCorrectiveTask');
  } else if (duplicateMode) {
    title = t('tasks')('createDuplicateTask');
  } else if (taskType) {
    title = t('tasks')('phaseDetails', {
      type: t('taskTypes')(taskType),
    });
  } else if (phase === Phase.asset) {
    title = t('tasks')('phaseAssets');
  } else {
    title = t('tasks')('phaseType');
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //console.log("task.assets:", task.assets)
  /* useEffect(() => {
    if (client)
    {
      console.log("sitesData.client.modules:", clientData?.client.modules)

     
      if (clientData?.client.modules !== null)
      {
       // Convert the modules object to an array of entries


        // Filter modules to include only those with true values
        const filteredModules = Object.entries(clientData?.client.modules)
        .filter(([key, value]) => !value) // Filter by value being true
        .map(([key]) => key); // Extract the key



        // State to manage the selected option
       // const [selectedModule, setSelectedModule] = useState(filteredModules[0] || '');


       console.log("filteredModules:", filteredModules);

       if (filteredModules.length > 0)
       {
      //  setSelectedModules(filteredModules)
     //   setModulesAvailable(true);
       }

   
      }

 
    }

  }, [client]); */

  interface ValidationSchema {
    assetIds?: string[];
    contractorId?: string;
    workOrderId?: string;
    internalUserId?: string;
    approvalUserId?: string;
    tasktitle?: string;
    notes?: string;
    deadline?: Date;
    reOccurence?: ReOccurenceInput;
    documentDeadline?: Date;
    applyChangesToFutureOccurences?: boolean;
    taskstart?: Date;
    projectid?: string;
  }
  interface CorrectiveValidationSchema {
    assetIds?: string[];
    contractorId?: string;
    workOrderId?: string;
    internalUserId?: string;
    approvalUserId?: string;
    tasktitle?: string;
    notes?: string;
    deadline?: Date;
    reOccurence?: ReOccurenceInput;
    documentDeadline?: Date;
    applyChangesToFutureOccurences?: boolean;
  }

  const validationSchema: Yup.ObjectSchema<ValidationSchema> = Yup.object().shape({
    assetIds: !editMode ? Yup.array().min(
      1, t('common')('required', {
        field: t('tasks')('asset_optionalPlural')
      })
    ).of(Yup.string().required()).required() : Yup.array().optional(),
    contractorId:
      phase === Phase.details && responsibleType === ResponsibleType.contractorResponsible
        ? Yup.string().required(
          t('common')('required', {
            field: t('tasks')('responsible')
          })
        )
        : Yup.string().optional(),
    workOrderId:
      phase === Phase.details && responsibleType === ResponsibleType.contractorResponsible
        ? Yup.string().required(
          t('common')('required', {
            field: t('tasks')('responsible')
          })
        )
        : Yup.string().optional(),
    internalUserId:
      phase === Phase.details && responsibleType === ResponsibleType.internalResponsible
        ? Yup.string().required(
          t('common')('required', {
            field: t('tasks')('responsible')
          })
        )
        : Yup.string().optional(),
    approvalUserId: phase === Phase.details ? Yup.string().required(
      t('common')('required', {
        field: t('tasks')('approval')
      })
    ) : Yup.string().optional(),
    //subform: phase === Phase.details ? Yup.string().required() : undefined,
    notes: phase === Phase.details ? Yup.string() : Yup.string().optional(),
    tasktitle: phase === Phase.details ? Yup.string().required(
      t('common')('required', {
        field: t('tasks')('tasktitle')
      })
    ) : Yup.string().optional(),
    reOccurence: reOccurenceValidationSchema.required(),
    deadline: phase === Phase.details ? Yup.date().required(
      t('common')('required', {
        field: t('tasks')('deadline')
      })
    ) : Yup.date().optional(),
    documentDeadline:
      phase === Phase.details && docsRequired === true
        ? Yup.date().required(
          t('common')('required', {
            field: t('tasks')('documentDeadline')
          })
        )
        : Yup.date().optional(),
    projectid:
      phase === Phase.details && projDatesMatch === true && showProjects
        ? Yup.string().required()
        : Yup.string().optional(),
    taskstart: phase === Phase.details && showProjects === true && projDatesMatch === false && showProjects
      ? Yup.date().required()
      : Yup.date().optional(),
    applyChangesToFutureOccurences: Yup.boolean(),
  })


  if (!correctiveMode && !duplicateMode) {
    console.log("LINE 780")
    return (

      <FullScreenPage title={title} loading={clientDataLoadingGQL || taskDataLoadingGQL || isLoading}>
        {!editMode || task ? (
          <Formik<FormValues>
            initialValues={{
              systemTypes: [],
              assetIds: assetIds || (correctiveMode && task ? task.assets.map((taskAsset) => taskAsset.asset.id) : []),
              contractorId: task?.contractorResponsible?.id || '',
              // type: correctiveMode && task ? task.type : undefined,
              type: task ? task.type : undefined,
              workOrderId: task?.contractorResponsibleWorkOrderId || '',
              internalUserId: task?.internalResponsible?.id || '',
              approvalUserId: task?.approval?.id || '',
              tasktitle: task?.tasktitle || '',
              notes: task?.notes || '',
              subform: '',
              projectid: task?.projectid || '',
              selprojstart: task?.project?.startdate || '',
              selprojendset: task?.project?.enddate || '',
              taskstart: task?.taskstart && editMode ? readDayDate(task.taskstart) : undefined,
              reOccurence: {
                type: ReOccurenceType.NEVER,
                endDate: addYears(new Date(), 1),
                months: [monthIndexes[new Date().getMonth()]],
                day: initialDay,
                days: [Day.MONDAY, Day.TUESDAY, Day.WEDNESDAY, Day.THURSDAY, Day.FRIDAY],
                weekIndex: ReOccurenceWeekIndex.FIRST,

              },
              deadline: task?.deadline && editMode ? readDayDate(task.deadline) : undefined,
              documentDeadline: task?.documentDeadline && editMode ? readDayDate(task.documentDeadline) : undefined,
              urgent: task?.urgent || false,
              assetsList: assetsListing,
            }}
            validationSchema={validationSchema}
            validate={(values) => {


              const errors: FormikErrors<FormValues> = {};

              if (values.documentDeadline && values.deadline && values.documentDeadline < values.deadline) {
                errors.documentDeadline = t('tasks')('documentDeadlineBeforeDeadlineError');
              }
              if (projDatesMatch === false && showProjects === true) {
                errors.projectid = 'Task dates outside range of selected project. Please choose a different project.'

              }

              return errors;
            }}
            onSubmit={async (values, { setTouched }) => {
              // Edit Mode
              if (editMode && task) {
                await updateTask({
                  variables: {
                    id: task.id,
                    changes: {
                      type: values.type!,
                      assetIds: values.assetIds || undefined,
                      approvalUserId: values.approvalUserId || undefined,
                      internalUserId: values.internalUserId || undefined,
                      subform: values.subform || undefined,
                      contractorId: values.contractorId || undefined,
                      workOrderId: values.workOrderId || undefined,
                      deadline: values.deadline ? formatDayDate(values.deadline) : undefined,
                      documentDeadline: values.documentDeadline ? formatDayDate(values.documentDeadline) : undefined,
                      fileIds: files.map((file) => file.id),
                      tasktitle: values.tasktitle || undefined,
                      notes: values.notes || undefined,
                      //urgent: values.urgent,
                      taskstart: showProjects && values.taskstart ? formatDayDate(values.taskstart)  || undefined :null,
                      projectid: showProjects ? values.projectid || undefined :null, // Empty string if showProjects is false
                      applyChangesToFutureOccurences: values.applyChangesToFutureOccurences,
                    },
                  },
                  update: (cache) => {
                    if (values.applyChangesToFutureOccurences) {
                      // if we update multiple tasks, evict all tasks from the cache
                      // we don't know exactly how many tasks will be updated (it could be many), so we should not query back all of them
                      // we also don't know what pages of the task list are affected, so the easiest way is just to evict the cache
                      apolloCacheEvict({
                        cache,
                        typename: 'Site',
                        id: siteId,
                        fieldName: 'tasks',
                      });
                      apolloCacheEvictQuery({
                        cache,
                        query: 'task',
                      });
                    }
                  },
                });
                cancel();
                return;
              }

              // Create Or Corrective mode

              // Phase asset selection
              if (phase === Phase.asset) {
                // go to next phase
                // set remaining fields untouched (because of the submit every field set touched automatically)
                setTouched({
                  contractorId: false,
                  workOrderId: false,
                  internalUserId: false,
                  approvalUserId: false,
                  tasktitle: false,
                  notes: false,
                  deadline: false,
                  documentDeadline: false,
                  urgent: false,
                });
                goToPhase(Phase.type);
                return;
              }

              // Phase details
              if (!values.type || !values.approvalUserId) {
                throw new Error();
              }
              // the following will be changed based on docsrequired
              let docDeadlinevalue = '';
              if (docsRequired) {
                if (!values.deadline || !values.documentDeadline) throw new Error('Deadlines are not set.');
                docDeadlinevalue = formatDayDate(values.documentDeadline)
              }
              if (!docsRequired) {
                if (!values.deadline) throw new Error('Deadlines are not set.');
                docDeadlinevalue = '';
              }

              const createdTask = await createTask({
                variables: {
                  task: {
                    siteId,
                    assetIds: values.assetIds,
                    type: values.type,
                    contractorId:
                      responsibleType === ResponsibleType.contractorResponsible ? values.contractorId : undefined,
                    workOrderId:
                      responsibleType === ResponsibleType.contractorResponsible ? values.workOrderId : undefined,
                    internalUserId:
                      responsibleType === ResponsibleType.internalResponsible ? values.internalUserId : undefined,
                    approvalUserId: values.approvalUserId,
                    notes: values.notes,
                    tasktitle: values.tasktitle,
                    reOccurence: !correctiveMode ? reOccurenceResolveInput(values.reOccurence) : undefined,
                    deadline: formatDayDate(values.deadline),
                    documentDeadline: docDeadlinevalue,
                    urgent: values.urgent,
                    fileIds: files.map((file) => file.id),
                    correctiveOfTaskId: correctiveMode && task ? task.id : undefined,
                    // subform: values.subform,
                    subform: selectedSubFormTemplate,
                    projectid: values.projectid,
                    taskstart: values.taskstart,
                  },
                },
              });
              if (createdTask.data?.taskCreate.id) {

                console.log("Work ORDER ID:", values.workOrderId)
                console.log("Work ORDER ID:", values.contractorId)
                console.log("New Task ID:", createdTask.data.taskCreate.id)

                // At this point check if the work order ID requires an email sent for the job to be created in SF by MCB
                if (values.workOrderId) {
                  if (values.workOrderId.includes('SF-LINK')) {
                    // Send email via backend. Just need to send the task ID, rest can be queried at back
                    let urltogoto = `sf/sendsfmail?jobid=${createdTask.data.taskCreate.id}`
                    fetch(`${process.env.API_ROOT}${urltogoto}`, {
                      method: 'GET',
                      credentials: 'include',
                    })
                  }
                }
                navigate(
                  `/client/${clientId}/tasks/${siteId}?task=${createdTask.data.taskCreate.id}&${currentWeekString}`,
                );
              } else {
                cancel();
              }
            }}
          >
            {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
              if (!site || !client) return <></>;

              const editableFields = editMode && task ? taskEditableFields[task.status] : undefined;

              const availableSystemTypes: SystemType[] = [];
              const availableSystemTypesAgain: SystemType[] = [];

              /*site.assets.forEach((asset) => {
                if (!availableSystemTypes.includes(asset.system.type)) {
                  availableSystemTypes.push(asset.system.type);
                }
              }); */

              sysTypesListing.forEach((sys: any) => {
                if (!availableSystemTypes.includes(sys.type)) {
                  availableSystemTypes.push(sys.type);
                }
              });

              let availableTaskTypes: TaskType[] = [];
              try {
                if (values.assetIds[0]) {
                  const assets = values.assetIds.map((assetId) => {
                    //const asset = site?.assets.find((_asset) => _asset.id === assetId);
                    const asset = assetsListing.find((_asset) => _asset.id === assetId);


                    if (!asset) throw new Error();
                    return asset;
                  });
                  const firstAsset = assets.find((_asset) => _asset.id === values.assetIds[0]);
                  if (!firstAsset) throw new Error();


                  // FW 2023-07-01 Issue with previous version only picking up task types for first asset and not others

                  /*   availableTaskTypes = assetAvailableTaskTypes[firstAsset.type].filter(
                       (type) => !assets.find((asset) => !assetAvailableTaskTypes[asset.type].includes(type)),
                     ); */

                  /* taskTypesListing.forEach((task: any) => {
                    if (!availableTaskTypes.includes(task.assettype)) {
                      availableTaskTypes.push(task.assettype);
                    }
                  }); */

                  // Need to filter the available task types based on the selected asset type

                  setAaskType(firstAsset.type);




                  // find the entry
                  const groupedData = taskTypesListing.reduce((result, item) => {
                    const index = result.findIndex(group => group[0].assettype === item.assettype);
                    if (index !== -1) {
                      result[index].push(item);
                    } else {
                      result.push([item]);
                    }
                    return result;
                  }, []);


                  for (let i = 0; i < values.assetIds.length; i++) {
                    const curAsset = assets.find((_asset) => _asset.id === values.assetIds[i]);

                    const selTaskTypes = groupedData.find(group => group[0].assettype === curAsset.type);


                    selTaskTypes.forEach((task: any) => {
                      if (!availableTaskTypes.includes(task.tasktypes)) {
                        availableTaskTypes.push(task.tasktypes);

                      }
                    });

                  }


                  //const selTaskTypes = groupedData.find(group => group[0].assettype === "BUNDS");
                  // Now populate the availableTaskTypes
                  /* availableTaskTypes = taskTypesListing[firstAsset.type].filter(
                     (assettype) => !assets.find((asset) => !taskTypesListing[asset.type].includes(assettype)),
                   ); */
                }
              } catch (_e) {
                setConnectedAssetsError(true);
              }

              return switcher(phase, [
                [
                  Phase.asset,
                  <AppForm handleSubmit={handleSubmit} key={1}>
                    <Box
                      sx={{
                        position: "absolute",
                        p: 3,
                        top: 0,
                        right: 0
                      }}>
                      <AppIconButton noBorder onClick={cancel}>
                        <img alt="Close report" src={cancelIcon} height="40px" />
                      </AppIconButton>
                    </Box>
                    <FormLayoutTask isSubmitting={isSubmitting} parentHref={parentHref} saveButtonText={t('common')('next')}>
                      {/* Select Systems */}
                      <AppSelectMultipleField
                        label={t('tasks')('system_optionalPlural')}
                        name="systemTypes"
                        required
                        options={{
                          array: availableSystemTypes,
                          key: (system) => system,
                          value: (system) => system,
                          template: (system) => t('systemTypes')(system),
                        }}
                        onRemove={(removedSystem) => {
                          const systemTypes = values.systemTypes.filter((system) => system !== removedSystem);
                          setFieldValue(
                            'assetIds',
                            values.assetIds.filter((assetId) =>
                              assetsListing.find(
                                //  site.assets.find(
                                //    (asset) => asset.id === assetId && systemTypes.includes(asset.system.type),
                                (asset) => asset.id === assetId && systemTypes.includes(assetsListing.systype),
                              ),
                            ),
                          );
                        }}
                      />
                      {/* Select Assets */}
                      <AppSelectMultipleField
                        label={t('tasks')('asset_optionalPlural')}
                        name="assetIds"
                        required
                        options={{
                          //    array: site.assets.filter((asset) => values.systemTypes.includes(asset.system.type)),
                          array: assetsListing.filter((asset) => values.systemTypes.includes(asset.systemtype)),
                          key: (asset) => asset.id,
                          value: (asset) => asset.id,
                          template: (asset) => asset.name,
                        }}
                      />
                      <div className={styles.buttons}>
                        <AppButton type="submit" color="primary" inProgress={isSubmitting}>
                          Next
                        </AppButton>
                      </div>

                    </FormLayoutTask>
                  </AppForm>,
                ],
                [
                  Phase.type,
                  <div className={styles.taskTypesContainer} key={1}>
                    <Box
                      sx={{
                        position: "absolute",
                        p: 3,
                        top: 0,
                        right: 0
                      }}>
                      <AppIconButton noBorder onClick={cancel}>
                        <img alt="Close report" src={cancelIcon} height="40px" />
                      </AppIconButton>
                    </Box>
                    <div className={styles.taskTypes}>
                      {availableTaskTypes
                        .sort((taskType1, taskType2) => {
                          if (taskType1 === TaskType.OTHER) {
                            return 1;
                          }
                          if (taskType2 === TaskType.OTHER) {
                            return -1;
                          }
                          return 0;
                        })
                        .map((_taskType) => (
                          // Select Task Type buttons
                          <button
                            type="button"
                            onClick={() => {
                              const assetTypes = values.assetIds.map(
                                (assetId) => assetsListing.find((asset) => asset.id === assetId)!.type,
                                //(assetId) => assetsListing.find((asset) => asset.id === assetId)!.type,
                              );

                              if (
                                assetTypes.every(
                                  (assetType) =>
                                    !!site.formTemplates.find(
                                      (formTemplate) =>
                                        formTemplate.assetType === assetType &&
                                        formTemplate.formType === _taskType &&
                                        formTemplate.active,
                                    ),
                                )
                              ) {
                                // Check when called - will need to get the subforms
                                // fetch sub forms for selected type
                                // Need asset type as well to filter subforms
                                //filter subforms listing

                                const selTaskTypes = subFormsListing.find(group => group[0].assettype === assetTypes[0]);

                                // now further breakdown by the task type
                                const groupedData = selTaskTypes.reduce((result, item) => {
                                  const index = result.findIndex(group => group[0].formtype === item.formtype);
                                  if (index !== -1) {
                                    result[index].push(item);
                                  } else {
                                    result.push([item]);
                                  }
                                  return result;
                                }, []);

                                const selFormTypes = groupedData.find(group => group[0].formtype === _taskType);

                                let subformslist: any[] = [];
                                if (selFormTypes) {
                                  selFormTypes.forEach((form: any) => {
                                    if (!subformslist.includes(form.subform)) {
                                      subformslist.push(form.subform.charAt(0).toUpperCase() + form.subform.slice(1).toLowerCase());
                                    }
                                  });
                                }

                                if (subformslist.length > 1) {
                                  setSubFormsList(subformslist);
                                }
                                else {
                                  setSelectedSubFormTemplate(subformslist[0])


                                  // need to handle document deadline
                                  const selectedFormDeets = availableForms.filter(item =>
                                    item.assettype === assetType &&
                                    item.formtype === _taskType &&
                                    item.subform === subformslist[0].toUpperCase()
                                  );

                                  // have tasktype, selected sub and asset
                                  setDocsRequired(selectedFormDeets[0].docsrequired)


                                }
                                setTaskTypeHasNoFormTemplateError(undefined);
                                setTaskType(_taskType);
                                setFieldValue('type', _taskType);
                                goToPhase(Phase.details);
                              } else {
                                const assetType = assetTypes.find(
                                  (_assetType) =>
                                    !site.formTemplates.find(
                                      (formTemplate) =>
                                        formTemplate.assetType === _assetType &&
                                        formTemplate.formType === _taskType &&
                                        formTemplate.active,
                                    ),
                                );
                                if (assetType) {
                                  setTaskTypeHasNoFormTemplateError(
                                    t('tasks')('formTemplateMissing', {
                                      siteName: site.name,
                                      assetType: t('assetTypes')(assetType),
                                      taskType: t('taskTypes')(_taskType),
                                    }),
                                  );
                                }
                              }
                            }}
                            className={styles.taskType}
                            key={_taskType}
                          >
                            <div className={styles.taskTypeIcon}>
                              <TaskTypeIcon type={_taskType} />
                            </div>
                            <div>{t('taskTypes')(_taskType)}</div>
                          </button>
                        ))}
                    </div>
                    <div className={styles.taskTypesFooter}>

                      <AppFieldError message={taskTypeHasNoFormTemplateError} />
                      <div className={styles.taskTypesPrevious}>
                        <AppButton color="primary" onClick={() => goToPhase(Phase.asset)}>
                          Previous
                        </AppButton>
                      </div>

                    </div>
                  </div>,
                ],
                [
                  Phase.details,
                  <AppForm handleSubmit={handleSubmit} key={1}>
                    <Box
                      sx={{
                        position: "absolute",
                        p: 3,
                        top: 0,
                        right: 0
                      }}>
                      <AppIconButton noBorder onClick={cancel}>
                        <img alt="Close report" src={cancelIcon} height="40px" />
                      </AppIconButton>
                    </Box>
                    <FormLayoutTask
                      isSubmitting={isSubmitting}
                      parentHref={parentHref}
                      sidebar={
                        <><div className={styles.filesInput}>
                          {/* Upload Attachments */}
                          <FilesList
                            label={t('tasks')('attachments')}
                            files={files}
                            onRemove={(removedFileId) => removeFromFiles(removedFileId)}
                            bottomSection={!editableFields || editableFields.includes('fileIds') ? (
                              // TODO: Add document repository Selector
                              <FileInput
                                onUpload={(id, name) => {
                                  addToFiles({ id, name });
                                }}
                                regular
                                multiple
                                documentRepositoryBrowser />
                            ) : undefined}
                            disabled={editableFields && !editableFields.includes('fileIds')} />
                          <br></br>

                          <br></br>
                          {client.projects ? (
                            <table><tr><td> <AppCheckboxField name="checkbox" checked={showProjects} className={styles.checkbox} onChange={(event, checked) => {
                              setShowProjects(checked);
                            }} /></td><td> <Typography className={styles.paddingLeft}>Include in Project?</Typography></td></tr></table>
                          ) : null}
                        </div><div>

                            {showProjects ? (
                              <><AppSelectField
                                key="projectid"
                                label="Choose Project"
                                name="projectid"

                                value={values.projectid}
                                options={{
                                  array: projectsList,
                                  key: (project) => project.id,
                                  value: (project) => project.id,
                                  template: (project) => project.projectname,

                                }}
                                onChange={(selproj) => {
                                  console.log("selproj:", selproj)
                                  /*const filteredItems = projectsList.filter(proj =>
                                    proj.id ===  selproj
                                  ); */

                                  // check start and end date of project and current deadline for comparison.  Don't save the task to the project if the deadline is before or after
                                  // the project
                                  console.log("Selected project:", selproj)
                                  console.log("Selected project startdate:", new Date(selproj.startdate))
                                  console.log("Selected project enddate:", new Date(selproj.enddate))
                                  setFieldValue('selprojendset', new Date(selproj.enddate));
                                  console.log("Task Deadline", values.deadline)


                                  if (values.deadline) {

                                    const result = isDateInRange(new Date(values.deadline), new Date(selproj.startdate), new Date(selproj.enddate));

                                    console.log("result at line 1589: ", result)

                                    if (result === false) {

                                      Swal.fire({
                                        title: 'Warning',
                                        text: 'The task deadline is outside the project dates. Amend the deadline date or choose a different project. The selected project will not be saved against the task.',
                                        icon: 'warning',
                                        confirmButtonText: 'Ok',
                                        background: '#fff',
                                        customClass: {
                                          confirmButton: 'custom-confirm-button'
                                        },
                                      });



                                    }
                                    else {
                                      setProjDatesMatch(true)

                                    }


                                  }

                                  
                                  
                                  // The following will cause a crash:
                                  setSelProject(selproj)

                                  //console.log("selProject:", selProject)
                                }} />
                                <br></br>
                                {showProjects  ? (

                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>Project Start:</td>
                                        <td>{selProject ? formatDate(selProject.startdate) : formatDate(task?.project?.startdate)}</td>

                                      </tr>
                                      <tr>
                                        <td>Project End:</td>
                                        <td>{selProject ? formatDate(selProject.enddate) : formatDate(task?.project?.enddate)}</td>

                                      </tr>

                                    </tbody>
                                  </table>
                                ) : null}
                                     

                                <br></br>
                                <br></br>
                                <AppDateField
                                  label='Task Start'
                                  name="taskstart"
                                  minDate={minDateProj}
                                  maxDate={maxDateProj}
                                  required
                                />

                              </>
                            ) : null}


                          </div></>
                      }
                    >

                      {/* Corrective Task */}
                      {correctiveMode && task ? (
                        <AppFormControlLabel
                          className={fieldStyles.label}
                          control={
                            <a
                              className={styles.link}
                              href={`/client/${clientId}/tasks/${siteId}?search=${task.idReadable}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              #{task.idReadable}
                            </a>
                          }
                          labelPlacement="top"
                          label={t('tasks')('correctiveOf')}
                        />
                      ) : null}

                      {/* Internal / Contractor */}
                      {!editableFields ||
                        (editableFields.includes('contractorId') && editableFields.includes('internalUserId')) ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center"
                          }}>
                          <ButtonSelector
                            name="responsibleType"
                            value={responsibleType}
                            options={{
                              array: enumToArray(ResponsibleType),
                              key: (type) => type.toString(),
                              text: (type) => t('tasks')(type),
                            }}
                            onChange={(type) => {
                              setResponsibleType(type);
                              if (type === ResponsibleType.internalResponsible) {
                                setFieldValue('contractorId', '');
                                setFieldValue('workOrderId', '');
                              } else {
                                setFieldValue('internalUserId', '');
                              }
                            }}
                          />
                        </Box>
                      ) : null}

                      {switcher(responsibleType, [

                        [
                          ResponsibleType.contractorResponsible,
                          // If Contractor

                          <>    {subFormsList?.length > 1 ? (
                            <AppSelectField
                              key="subform"
                              label="Choose Form"
                              name="subform"
                              required
                              options={{
                                // array: assetAvailableTaskTypes[selectedAssetType],
                                array: subFormsList,
                                value: (formtTemplateType) => formtTemplateType,
                                key: (formtTemplateType) => formtTemplateType,
                                template: (formtTemplateType) => `${t('taskTypes')(formtTemplateType)} ${t('common')('form')}`,

                              }}
                              value={''}
                              onChange={(selsub) => {

                                const selectedFormDeets = availableForms.filter(item =>
                                  item.assettype === assetType &&
                                  item.formtype === taskType &&
                                  item.subform === selsub.toUpperCase()
                                );

                                // have tasktype, selected sub and asset

                                setDocsRequired(selectedFormDeets[0].docsrequired)
                                setSelectedSubFormTemplate(selsub)
                              }
                              }
                            />
                          ) : null}
                            {/* Select Contractor */}
                            <AppSelectField
                              label={t('tasks')('responsible')}
                              name="contractorId"
                              required
                              disabled={editableFields && !editableFields.includes('contractorId')}
                              options={{
                                array: availableContractors(client.requests, task?.contractorResponsible || undefined),
                                key: (contractor) => contractor.id,
                                value: (contractor) => contractor.id,
                                template: (contractor) => contractor.companyName,
                                ifArrayEmpty: t('tasks')('noContractor'),
                              }}
                            />

                            {/* Select Work Order ID */}
                            <AppSelectField
                              label={t('tasks')('workOrderId')}
                              name="workOrderId"
                              required
                              disabled={
                                !values.contractorId || (editableFields && !editableFields.includes('workOrderId'))
                              }
                              options={{
                                array: availableWorkOrderIDs(
                                  client.requests,
                                  values.contractorId,
                                  task?.contractorResponsibleWorkOrderId || undefined,
                                ),
                                key: (workOrderID) => workOrderID,
                                value: (workOrderID) => workOrderID,
                                template: (workOrderID) => workOrderID,
                                ifArrayEmpty: t('tasks')('noWorkOrder'),
                              }}
                            />
                          </>,
                        ],
                        [
                          ResponsibleType.internalResponsible,
                          // If Internal
                          // Select Internal Responsible

                          // Check if multiple forms for the selected task type

                          <>    {subFormsList?.length > 1 ? (
                            <AppSelectField
                              key="subform"
                              label="Choose Form"
                              name="subform"
                              required
                              options={{
                                // array: assetAvailableTaskTypes[selectedAssetType],
                                array: subFormsList,
                                value: (formtTemplateType) => formtTemplateType,
                                key: (formtTemplateType) => formtTemplateType,
                                template: (formtTemplateType) => `${t('taskTypes')(formtTemplateType)} ${t('common')('form')}`,

                              }}
                              value={''}
                              onChange={(selsub) => {

                                const selectedFormDeets = availableForms.filter(item =>
                                  item.assettype === assetType &&
                                  item.formtype === taskType &&
                                  item.subform === selsub.toUpperCase()
                                );

                                // have tasktype, selected sub and asset

                                setDocsRequired(selectedFormDeets[0].docsrequired)
                                setSelectedSubFormTemplate(selsub)
                              }
                              }
                            />
                          ) : null}







                            <AppSelectField
                              key="internalUserId"
                              label={t('tasks')('responsible')}
                              name="internalUserId"
                              required
                              disabled={editableFields && !editableFields.includes('internalUserId')}
                              options={{
                                array: [
                                  ...client.users,
                                  ...(task?.internalResponsible &&
                                    !client.users.find((user) => user.id === task.internalResponsible?.id)
                                    ? [task.internalResponsible]
                                    : []),
                                ].filter(
                                  (user) => !editableFields ||
                                    !editableFields.includes('internalUserId') ||
                                    editableFields.includes('approvalUserId') ||
                                    // if internalUserId is editable but approvalUserId is not editable:
                                    // filter out the approval user from here
                                    values.approvalUserId !== user.id
                                ),
                                key: (user) => user.id,
                                value: (user) => user.id,
                                template: (user) => user.name,
                                ifArrayEmpty: t('tasks')('noUsers'),
                              }}
                              onChange={(newResponsible) => {
                                if (newResponsible.id === values.approvalUserId) {
                                  setFieldValue('approvalUserId', '');
                                }
                              }} /></>,
                        ],
                      ])}

                      {/* Select Responsible for Approval */}
                      <AppSelectField
                        label={t('tasks')('approval')}
                        name="approvalUserId"
                        required
                        disabled={editableFields && !editableFields.includes('approvalUserId')}
                        options={{
                          array: [
                            ...client.users,
                            ...(task?.approval && !client.users.find((user) => user.id === task.approval?.id)
                              ? [task.approval]
                              : []),
                          ],
                          key: (user) => user.id,
                          value: (user) => user.id,
                          template: (user) => user.name,
                          ifArrayEmpty: t('tasks')('noUsersForApproval'),
                        }}
                      />

                      {/* Task Title */}
                      <AppTextField
                        label={t('tasks')('tasktitle')}
                        name="tasktitle"
                        required
                        disabled={editableFields && !editableFields.includes('tasktitle')}
                      />


                      {/* Notes */}
                      <AppTextAreaField
                        label={t('tasks')('notes')}
                        name="notes"
                        disabled={editableFields && !editableFields.includes('notes')}
                      />

                      {/* Apply changes to future occurence */}
                      {task?.haveAnyFutureOccurence ? (
                        <AppFormControlLabel
                          control={<AppSwitch name="applyChangesToFutureOccurences" />}
                          labelPlacement="start"
                          label={t('tasks')('applyChangesToFutureOccurences')}
                        />
                      ) : null}

                      {/* Re-occurence */}
                      {!editMode && !correctiveMode ? <ReOccurenceField name="reOccurence" projects={showProjects} /> : null}

                      {/* Deadlines */}
                      <AppFormFieldsRow>
                        <AppDateField
                          label={t('tasks')('deadline')}
                          name="deadline"
                          minDate={minDateProj}
                          maxDate={maxDateProj}
                          required
                          disabled={editableFields && !editableFields.includes('deadline')}
                          onChange={(selchange) => {
                            setFieldValue('deadline', selchange);
                            if (showProjects) {
                              const result = isDateInRange(new Date(selchange), new Date(minDateProj), new Date(selMaxDateProj));
                              if (result) {
                                setProjDatesMatch(true)
                              }
                              else {
                                setProjDatesMatch(false)
                              }


                            }


                          }}
                        />
                        <AppDateField
                          label={t('tasks')('documentDeadline')}
                          name="documentDeadline"
                          minDate={minDateProj}
                          maxDate={maxDateProj}
                          required={docsRequired ? true : false}
                          disabled={editableFields && !editableFields.includes('documentDeadline')}

                        />
                      </AppFormFieldsRow>

                      {/* Urgent */}
                      <AppFormControlLabel
                        control={<AppSwitch name="urgent" />}
                        labelPlacement="start"
                        label={t('tasks')('urgent')}
                        disabled={editableFields && !editableFields.includes('urgent')}
                      />
                      <div className={styles.taskTypesFooter}>
                        {!editMode ? (
                          <div className={styles.taskTypesPrevious}>

                            <AppButton type="button" variant="outlined" onClick={() => goToPhase(Phase.type)}>
                              Previous
                            </AppButton>
                          </div>) : null}
                        <div className={styles.taskTypesCancel}>
                          <AppButton type="submit" color="primary" inProgress={isSubmitting}>
                            Save
                          </AppButton>
                        </div>
                      </div>


                    </FormLayoutTask>
                  </AppForm>,
                ],
              ]);
            }}

          </Formik>

        ) : null}
        <WarningDialog
          open={connectedAssetsError}
          onClose={cancel}
          title="Error"
          descriptionText="Assets connected to the task have been archived or missing."
        />
      </FullScreenPage>
    );
  }
  else {
    console.log("At 1521 and correctiveSystemTypes = ", correctiveSystemTypes)

    const correctiveValidationSchema: Yup.ObjectSchema<CorrectiveValidationSchema> = Yup.object().shape({
      assetIds: !editMode ? Yup.array().of(Yup.string().required()).required().min(
        1, t('common')('required', {
          field: t('tasks')('asset_optionalPlural')
        })
      ) : Yup.array().optional(),
      contractorId:
        phase === Phase.details && responsibleType === ResponsibleType.contractorResponsible
          ? Yup.string().required(
            t('common')('required', {
              field: t('tasks')('responsible')
            })
          )
          : Yup.string().optional(),
      workOrderId:
        phase === Phase.details && responsibleType === ResponsibleType.contractorResponsible
          ? Yup.string().required(
            t('common')('required', {
              field: t('tasks')('responsible')
            })
          )
          : Yup.string().optional(),
      internalUserId:
        phase === Phase.details && responsibleType === ResponsibleType.internalResponsible
          ? Yup.string().required(
            t('common')('required', {
              field: t('tasks')('responsible')
            })
          )
          : Yup.string().optional(),
      approvalUserId: phase === Phase.details ? Yup.string().required(
        t('common')('required', {
          field: t('tasks')('approval')
        })
      ) : Yup.string().optional(),
      notes: phase === Phase.details ? Yup.string() : Yup.string().optional(),
      tasktitle: phase === Phase.details ? Yup.string().required(
        t('common')('required', {
          field: t('tasks')('tasktitle')
        })
      ) : Yup.string().optional(),
      reOccurence: reOccurenceValidationSchema.required(),
      deadline: phase === Phase.details ? Yup.date().required(
        t('common')('required', {
          field: t('tasks')('deadline')
        })
      ) : Yup.date().optional(),
      documentDeadline:
        phase === Phase.details && docsRequired === true
          ? Yup.date().required(
            t('common')('required', {
              field: t('tasks')('documentDeadline')
            })
          )
          : Yup.date().optional(),
      applyChangesToFutureOccurences: Yup.boolean()
    })

    return (
      <div>
        {loadingSystems ? (
          <p>Loading...</p>
        ) : (
          <>
            <FullScreenPage title={title} loading={clientDataLoadingGQL || taskDataLoadingGQL || isLoading}>
              {!editMode || task || correctiveSystemTypes ? (
                <Formik<FormValues>
                  initialValues={{
                    systemTypes: correctiveSystemTypes,
                    assetIds: assetIds || (correctiveMode && task || duplicateMode && task ? task.assets.map((taskAsset) => taskAsset.asset.id) : []),
                    contractorId: task?.contractorResponsible?.id || '',
                    // type: correctiveMode && task ? task.type : undefined,
                    type: task ? task.type : undefined,
                    workOrderId: task?.contractorResponsibleWorkOrderId || '',
                    internalUserId: task?.internalResponsible?.id || '',
                    approvalUserId: task?.approval?.id || '',
                    tasktitle: task?.tasktitle || '',
                    notes: task?.notes || '',
                    subform: '',
                    projectid: task?.projectid || '',
                    selprojstart: minDateProj || '',
                    selprojendset: selMaxDateProj || '',
                    taskstart: undefined,
                    reOccurence: {
                      type: ReOccurenceType.NEVER,
                      endDate: addYears(new Date(), 1),
                      months: [monthIndexes[new Date().getMonth()]],
                      day: initialDay,
                      days: [Day.MONDAY, Day.TUESDAY, Day.WEDNESDAY, Day.THURSDAY, Day.FRIDAY],
                      weekIndex: ReOccurenceWeekIndex.FIRST,

                    },
                    deadline: task?.deadline && editMode ? readDayDate(task.deadline) : undefined,
                    documentDeadline: task?.documentDeadline && editMode ? readDayDate(task.documentDeadline) : undefined,
                    urgent: task?.urgent || false,
                    assetsList: assetsListing,
                  }}
                  validationSchema={correctiveValidationSchema}
                  validate={(values) => {
                    const errors: FormikErrors<FormValues> = {};

                    if (values.documentDeadline && values.deadline && values.documentDeadline < values.deadline) {
                      errors.documentDeadline = t('tasks')('documentDeadlineBeforeDeadlineError');
                    }

                    return errors;
                  }}
                  onSubmit={async (values, { setTouched }) => {
                    // Edit Mode
                    if (editMode && task) {
                      await updateTask({
                        variables: {
                          id: task.id,
                          changes: {
                            type: values.type!,
                            assetIds: values.assetIds || undefined,
                            approvalUserId: values.approvalUserId || undefined,
                            internalUserId: values.internalUserId || undefined,
                            subform: values.subform || undefined,
                            contractorId: values.contractorId || undefined,
                            workOrderId: values.workOrderId || undefined,
                            deadline: values.deadline ? formatDayDate(values.deadline) : undefined,
                            documentDeadline: values.documentDeadline ? formatDayDate(values.documentDeadline) : undefined,
                            fileIds: files.map((file) => file.id),
                            tasktitle: values.tasktitle || undefined,
                            notes: values.notes || undefined,
                            urgent: values.urgent,
                            applyChangesToFutureOccurences: values.applyChangesToFutureOccurences,
                          },
                        },
                        update: (cache) => {
                          if (values.applyChangesToFutureOccurences) {
                            // if we update multiple tasks, evict all tasks from the cache
                            // we don't know exactly how many tasks will be updated (it could be many), so we should not query back all of them
                            // we also don't know what pages of the task list are affected, so the easiest way is just to evict the cache
                            apolloCacheEvict({
                              cache,
                              typename: 'Site',
                              id: siteId,
                              fieldName: 'tasks',
                            });
                            apolloCacheEvictQuery({
                              cache,
                              query: 'task',
                            });
                          }
                        },
                      });
                      cancel();
                      return;
                    }

                    // Create Or Corrective mode

                    // Phase asset selection
                    if (phase === Phase.asset) {
                      // go to next phase
                      // set remaining fields untouched (because of the submit every field set touched automatically)
                      setTouched({
                        contractorId: false,
                        workOrderId: false,
                        internalUserId: false,
                        approvalUserId: false,
                        tasktitle: false,
                        notes: false,
                        deadline: false,
                        documentDeadline: false,
                        urgent: false,
                      });
                      goToPhase(Phase.type);
                      return;
                    }

                    // Phase details
                    if (!values.type || !values.approvalUserId) {
                      throw new Error();
                    }
                    // the following will be changed based on docsrequired
                    let docDeadlinevalue = '';
                    if (docsRequired) {
                      if (!values.deadline || !values.documentDeadline) throw new Error('Deadlines are not set.');
                      docDeadlinevalue = formatDayDate(values.documentDeadline)
                    }
                    if (!docsRequired) {
                      if (!values.deadline) throw new Error('Deadlines are not set.');
                      docDeadlinevalue = '';
                    }

                    const createdTask = await createTask({
                      variables: {
                        task: {
                          siteId,
                          assetIds: values.assetIds,
                          type: values.type,
                          contractorId:
                            responsibleType === ResponsibleType.contractorResponsible ? values.contractorId : undefined,
                          workOrderId:
                            responsibleType === ResponsibleType.contractorResponsible ? values.workOrderId : undefined,
                          internalUserId:
                            responsibleType === ResponsibleType.internalResponsible ? values.internalUserId : undefined,
                          approvalUserId: values.approvalUserId,
                          notes: values.notes,
                          tasktitle: values.tasktitle,
                          reOccurence: !correctiveMode ? reOccurenceResolveInput(values.reOccurence) : undefined,
                          deadline: formatDayDate(values.deadline),
                          documentDeadline: docDeadlinevalue,
                          urgent: values.urgent,
                          fileIds: files.map((file) => file.id),
                          correctiveOfTaskId: correctiveMode && task ? task.id : undefined,
                          // subform: values.subform,
                          subform: selectedSubFormTemplate,
                          projectid: values.projectid,
                          taskstart: values.taskstart,
                        },
                      },
                    });
                    if (createdTask.data?.taskCreate.id) {
                      navigate(
                        `/client/${clientId}/tasks/${siteId}?task=${createdTask.data.taskCreate.id}&${currentWeekString}`,
                      );
                    } else {
                      cancel();
                    }
                  }}
                >
                  {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
                    if (!site || !client) return <></>;

                    const editableFields = editMode && task ? taskEditableFields[task.status] : undefined;

                    const availableSystemTypes: SystemType[] = [];
                    const availableSystemTypesAgain: SystemType[] = [];

                    /*site.assets.forEach((asset) => {
                      if (!availableSystemTypes.includes(asset.system.type)) {
                        availableSystemTypes.push(asset.system.type);
                      }
                    }); */

                    sysTypesListing.forEach((sys: any) => {
                      if (!availableSystemTypes.includes(sys.type)) {
                        availableSystemTypes.push(sys.type);
                      }
                    });

                    let availableTaskTypes: TaskType[] = [];
                    try {
                      if (values.assetIds[0]) {
                        const assets = values.assetIds.map((assetId) => {
                          //const asset = site?.assets.find((_asset) => _asset.id === assetId);
                          const asset = assetsListing.find((_asset) => _asset.id === assetId);


                          if (!asset) throw new Error();
                          return asset;
                        });
                        const firstAsset = assets.find((_asset) => _asset.id === values.assetIds[0]);
                        if (!firstAsset) throw new Error();


                        // FW 2023-07-01 Issue with previous version only picking up task types for first asset and not others

                        /*   availableTaskTypes = assetAvailableTaskTypes[firstAsset.type].filter(
                             (type) => !assets.find((asset) => !assetAvailableTaskTypes[asset.type].includes(type)),
                           ); */

                        /* taskTypesListing.forEach((task: any) => {
                          if (!availableTaskTypes.includes(task.assettype)) {
                            availableTaskTypes.push(task.assettype);
                          }
                        }); */

                        // Need to filter the available task types based on the selected asset type

                        setAaskType(firstAsset.type);




                        // find the entry
                        const groupedData = taskTypesListing.reduce((result, item) => {
                          const index = result.findIndex(group => group[0].assettype === item.assettype);
                          if (index !== -1) {
                            result[index].push(item);
                          } else {
                            result.push([item]);
                          }
                          return result;
                        }, []);


                        for (let i = 0; i < values.assetIds.length; i++) {
                          const curAsset = assets.find((_asset) => _asset.id === values.assetIds[i]);

                          const selTaskTypes = groupedData.find(group => group[0].assettype === curAsset.type);


                          selTaskTypes.forEach((task: any) => {
                            if (!availableTaskTypes.includes(task.tasktypes)) {
                              availableTaskTypes.push(task.tasktypes);

                            }
                          });

                        }


                        //const selTaskTypes = groupedData.find(group => group[0].assettype === "BUNDS");
                        // Now populate the availableTaskTypes
                        /* availableTaskTypes = taskTypesListing[firstAsset.type].filter(
                           (assettype) => !assets.find((asset) => !taskTypesListing[asset.type].includes(assettype)),
                         ); */
                      }
                    } catch (_e) {
                      setConnectedAssetsError(true);
                    }

                    return switcher(phase, [
                      [
                        Phase.asset,
                        <AppForm handleSubmit={handleSubmit} key={1}>
                          <Box
                            sx={{
                              position: "absolute",
                              p: 3,
                              top: 0,
                              right: 0
                            }}>
                            <AppIconButton noBorder onClick={cancel}>
                              <img alt="Close report" src={cancelIcon} height="40px" />
                            </AppIconButton>
                          </Box>
                          <FormLayoutTask isSubmitting={isSubmitting} parentHref={parentHref} saveButtonText={t('common')('next')}>
                            {/* Select Systems */}
                            <AppSelectMultipleField
                              label={t('tasks')('system_optionalPlural')}
                              name="systemTypes"
                              required
                              options={{
                                array: availableSystemTypes,
                                key: (system) => system,
                                value: (system) => system,
                                template: (system) => t('systemTypes')(system),
                              }}
                              onRemove={(removedSystem) => {
                                const systemTypes = values.systemTypes.filter((system) => system !== removedSystem);
                                setFieldValue(
                                  'assetIds',
                                  values.assetIds.filter((assetId) =>
                                    assetsListing.find(
                                      //  site.assets.find(
                                      //    (asset) => asset.id === assetId && systemTypes.includes(asset.system.type),
                                      (asset) => asset.id === assetId && systemTypes.includes(assetsListing.systype),
                                    ),
                                  ),
                                );
                              }}
                            />
                            {/* Select Assets */}
                            <AppSelectMultipleField
                              label={t('tasks')('asset_optionalPlural')}
                              name="assetIds"
                              required
                              options={{
                                //    array: site.assets.filter((asset) => values.systemTypes.includes(asset.system.type)),
                                array: assetsListing.filter((asset) => values.systemTypes.includes(asset.systemtype)),
                                key: (asset) => asset.id,
                                value: (asset) => asset.id,
                                template: (asset) => asset.name,
                              }}
                            />
                            <div className={styles.buttons}>
                              <AppButton type="submit" color="primary" inProgress={isSubmitting}>
                                Next
                              </AppButton>
                            </div>
                          </FormLayoutTask>
                        </AppForm>,
                      ],
                      [
                        Phase.type,
                        <div className={styles.taskTypesContainer} key={1}>
                          <Box
                            sx={{
                              position: "absolute",
                              p: 3,
                              top: 0,
                              right: 0
                            }}>
                            <AppIconButton noBorder onClick={cancel}>
                              <img alt="Close report" src={cancelIcon} height="40px" />
                            </AppIconButton>
                          </Box>
                          <div className={styles.taskTypes}>
                            {availableTaskTypes
                              .sort((taskType1, taskType2) => {
                                if (taskType1 === TaskType.OTHER) {
                                  return 1;
                                }
                                if (taskType2 === TaskType.OTHER) {
                                  return -1;
                                }
                                return 0;
                              })
                              .map((_taskType) => (
                                // Select Task Type buttons
                                <button
                                  type="button"
                                  onClick={() => {
                                    const assetTypes = values.assetIds.map(
                                      (assetId) => assetsListing.find((asset) => asset.id === assetId)!.type,
                                      //(assetId) => assetsListing.find((asset) => asset.id === assetId)!.type,
                                    );

                                    if (
                                      assetTypes.every(
                                        (assetType) =>
                                          !!site.formTemplates.find(
                                            (formTemplate) =>
                                              formTemplate.assetType === assetType &&
                                              formTemplate.formType === _taskType &&
                                              formTemplate.active,
                                          ),
                                      )
                                    ) {
                                      // Check when called - will need to get the subforms
                                      // fetch sub forms for selected type
                                      // Need asset type as well to filter subforms
                                      //filter subforms listing

                                      const selTaskTypes = subFormsListing.find(group => group[0].assettype === assetTypes[0]);

                                      // now further breakdown by the task type
                                      const groupedData = selTaskTypes.reduce((result, item) => {
                                        const index = result.findIndex(group => group[0].formtype === item.formtype);
                                        if (index !== -1) {
                                          result[index].push(item);
                                        } else {
                                          result.push([item]);
                                        }
                                        return result;
                                      }, []);

                                      const selFormTypes = groupedData.find(group => group[0].formtype === _taskType);

                                      let subformslist: any[] = [];
                                      if (selFormTypes) {
                                        selFormTypes.forEach((form: any) => {
                                          if (!subformslist.includes(form.subform)) {
                                            subformslist.push(form.subform.charAt(0).toUpperCase() + form.subform.slice(1).toLowerCase());
                                          }
                                        });
                                      }

                                      if (subformslist.length > 1) {
                                        setSubFormsList(subformslist);
                                      }
                                      else {
                                        setSelectedSubFormTemplate(subformslist[0])


                                        // need to handle document deadline
                                        const selectedFormDeets = availableForms.filter(item =>
                                          item.assettype === assetType &&
                                          item.formtype === _taskType &&
                                          item.subform === subformslist[0].toUpperCase()
                                        );

                                        // have tasktype, selected sub and asset
                                        setDocsRequired(selectedFormDeets[0].docsrequired)


                                      }





                                      setTaskTypeHasNoFormTemplateError(undefined);
                                      setTaskType(_taskType);
                                      setFieldValue('type', _taskType);
                                      goToPhase(Phase.details);
                                    } else {
                                      const assetType = assetTypes.find(
                                        (_assetType) =>
                                          !site.formTemplates.find(
                                            (formTemplate) =>
                                              formTemplate.assetType === _assetType &&
                                              formTemplate.formType === _taskType &&
                                              formTemplate.active,
                                          ),
                                      );
                                      if (assetType) {
                                        setTaskTypeHasNoFormTemplateError(
                                          t('tasks')('formTemplateMissing', {
                                            siteName: site.name,
                                            assetType: t('assetTypes')(assetType),
                                            taskType: t('taskTypes')(_taskType),
                                          }),
                                        );
                                      }
                                    }
                                  }}
                                  className={styles.taskType}
                                  key={_taskType}
                                >
                                  <div className={styles.taskTypeIcon}>
                                    <TaskTypeIcon type={_taskType} />
                                  </div>
                                  <div>{t('taskTypes')(_taskType)}</div>
                                </button>
                              ))}
                          </div>
                          <div className={styles.taskTypesFooter}>
                            <AppFieldError message={taskTypeHasNoFormTemplateError} />
                            <div className={styles.taskTypesPrevious}>
                              <AppButton color="primary" onClick={() => goToPhase(Phase.asset)}>
                                Previous
                              </AppButton>
                            </div>
                          </div>
                        </div>,
                      ],
                      [
                        Phase.details,
                        <AppForm handleSubmit={handleSubmit} key={1}>
                          <Box
                            sx={{
                              position: "absolute",
                              p: 3,
                              top: 0,
                              right: 0
                            }}>
                            <AppIconButton noBorder onClick={cancel}>
                              <img alt="Close report" src={cancelIcon} height="40px" />
                            </AppIconButton>
                          </Box>
                          <FormLayoutTask
                            isSubmitting={isSubmitting}
                            parentHref={parentHref}
                            sidebar={
                              <div className={styles.filesInput}>
                                {/* Upload Attachments */}
                                <FilesList
                                  label={t('tasks')('attachments')}
                                  files={files}
                                  onRemove={(removedFileId) => removeFromFiles(removedFileId)}
                                  bottomSection={
                                    !editableFields || editableFields.includes('fileIds') ? (
                                      // TODO: Add document repository Selector
                                      <FileInput
                                        onUpload={(id, name) => {
                                          addToFiles({ id, name });
                                        }}
                                        regular
                                        multiple
                                        documentRepositoryBrowser
                                      />
                                    ) : undefined
                                  }
                                  disabled={editableFields && !editableFields.includes('fileIds')}
                                />
                              </div>
                            }
                          >


                            {/* Corrective Task */}
                            {correctiveMode && task ? (
                              <AppFormControlLabel
                                className={fieldStyles.label}
                                control={
                                  <a
                                    className={styles.link}
                                    href={`/client/${clientId}/tasks/${siteId}?search=${task.idReadable}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    #{task.idReadable}
                                  </a>
                                }
                                labelPlacement="top"
                                label={t('tasks')('correctiveOf')}
                              />
                            ) : null}

                            {/* Internal / Contractor */}
                            {!editableFields ||
                              (editableFields.includes('contractorId') && editableFields.includes('internalUserId')) ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center"
                                }}>
                                <ButtonSelector
                                  name="responsibleType"
                                  value={responsibleType}
                                  options={{
                                    array: enumToArray(ResponsibleType),
                                    key: (type) => type.toString(),
                                    text: (type) => t('tasks')(type),
                                  }}
                                  onChange={(type) => {
                                    setResponsibleType(type);
                                    if (type === ResponsibleType.internalResponsible) {
                                      setFieldValue('contractorId', '');
                                      setFieldValue('workOrderId', '');
                                    } else {
                                      setFieldValue('internalUserId', '');
                                    }
                                  }}
                                />
                              </Box>
                            ) : null}

                            {switcher(responsibleType, [

                              [
                                ResponsibleType.contractorResponsible,
                                // If Contractor

                                <>    {subFormsList?.length > 1 ? (
                                  <AppSelectField
                                    key="subform"
                                    label="Choose Form"
                                    name="subform"
                                    required
                                    options={{
                                      // array: assetAvailableTaskTypes[selectedAssetType],
                                      array: subFormsList,
                                      value: (formtTemplateType) => formtTemplateType,
                                      key: (formtTemplateType) => formtTemplateType,
                                      template: (formtTemplateType) => `${t('taskTypes')(formtTemplateType)} ${t('common')('form')}`,

                                    }}
                                    value={''}
                                    onChange={(selsub) => {

                                      const selectedFormDeets = availableForms.filter(item =>
                                        item.assettype === assetType &&
                                        item.formtype === taskType &&
                                        item.subform === selsub.toUpperCase()
                                      );

                                      // have tasktype, selected sub and asset

                                      setDocsRequired(selectedFormDeets[0].docsrequired)
                                      setSelectedSubFormTemplate(selsub)
                                    }
                                    }
                                  />
                                ) : null}
                                  {/* Select Contractor */}
                                  <AppSelectField
                                    label={t('tasks')('responsible')}
                                    name="contractorId"
                                    required
                                    disabled={editableFields && !editableFields.includes('contractorId')}
                                    options={{
                                      array: availableContractors(client.requests, task?.contractorResponsible || undefined),
                                      key: (contractor) => contractor.id,
                                      value: (contractor) => contractor.id,
                                      template: (contractor) => contractor.companyName,
                                      ifArrayEmpty: t('tasks')('noContractor'),
                                    }}
                                  />

                                  {/* Select Work Order ID */}
                                  <AppSelectField
                                    label={t('tasks')('workOrderId')}
                                    name="workOrderId"
                                    required
                                    disabled={
                                      !values.contractorId || (editableFields && !editableFields.includes('workOrderId'))
                                    }
                                    options={{
                                      array: availableWorkOrderIDs(
                                        client.requests,
                                        values.contractorId,
                                        task?.contractorResponsibleWorkOrderId || undefined,
                                      ),
                                      key: (workOrderID) => workOrderID,
                                      value: (workOrderID) => workOrderID,
                                      template: (workOrderID) => workOrderID,
                                      ifArrayEmpty: t('tasks')('noWorkOrder'),
                                    }}
                                  />
                                </>,
                              ],
                              [
                                ResponsibleType.internalResponsible,
                                // If Internal
                                // Select Internal Responsible

                                // Check if multiple forms for the selected task type

                                <>    {subFormsList?.length > 1 ? (
                                  <AppSelectField
                                    key="subform"
                                    label="Choose Form"
                                    name="subform"
                                    required
                                    options={{
                                      // array: assetAvailableTaskTypes[selectedAssetType],
                                      array: subFormsList,
                                      value: (formtTemplateType) => formtTemplateType,
                                      key: (formtTemplateType) => formtTemplateType,
                                      template: (formtTemplateType) => `${t('taskTypes')(formtTemplateType)} ${t('common')('form')}`,

                                    }}
                                    value={''}
                                    onChange={(selsub) => {

                                      const selectedFormDeets = availableForms.filter(item =>
                                        item.assettype === assetType &&
                                        item.formtype === taskType &&
                                        item.subform === selsub.toUpperCase()
                                      );

                                      // have tasktype, selected sub and asset

                                      setDocsRequired(selectedFormDeets[0].docsrequired)
                                      setSelectedSubFormTemplate(selsub)
                                    }
                                    }
                                  />
                                ) : null}







                                  <AppSelectField
                                    key="internalUserId"
                                    label={t('tasks')('responsible')}
                                    name="internalUserId"
                                    required
                                    disabled={editableFields && !editableFields.includes('internalUserId')}
                                    options={{
                                      array: [
                                        ...client.users,
                                        ...(task?.internalResponsible &&
                                          !client.users.find((user) => user.id === task.internalResponsible?.id)
                                          ? [task.internalResponsible]
                                          : []),
                                      ].filter(
                                        (user) => !editableFields ||
                                          !editableFields.includes('internalUserId') ||
                                          editableFields.includes('approvalUserId') ||
                                          // if internalUserId is editable but approvalUserId is not editable:
                                          // filter out the approval user from here
                                          values.approvalUserId !== user.id
                                      ),
                                      key: (user) => user.id,
                                      value: (user) => user.id,
                                      template: (user) => user.name,
                                      ifArrayEmpty: t('tasks')('noUsers'),
                                    }}
                                    onChange={(newResponsible) => {
                                      if (newResponsible.id === values.approvalUserId) {
                                        setFieldValue('approvalUserId', '');
                                      }
                                    }} /></>,
                              ],
                            ])}

                            {/* Select Responsible for Approval */}
                            <AppSelectField
                              label={t('tasks')('approval')}
                              name="approvalUserId"
                              required
                              disabled={editableFields && !editableFields.includes('approvalUserId')}
                              options={{
                                array: [
                                  ...client.users,
                                  ...(task?.approval && !client.users.find((user) => user.id === task.approval?.id)
                                    ? [task.approval]
                                    : []),
                                ],
                                key: (user) => user.id,
                                value: (user) => user.id,
                                template: (user) => user.name,
                                ifArrayEmpty: t('tasks')('noUsersForApproval'),
                              }}
                            />

                            {/* Task Title */}
                            <AppTextField
                              label={t('tasks')('tasktitle')}
                              name="tasktitle"
                              required
                              disabled={editableFields && !editableFields.includes('tasktitle')}
                            />


                            {/* Notes */}
                            <AppTextAreaField
                              label={t('tasks')('notes')}
                              name="notes"
                              disabled={editableFields && !editableFields.includes('notes')}
                            />

                            {/* Apply changes to future occurence */}
                            {task?.haveAnyFutureOccurence ? (
                              <AppFormControlLabel
                                control={<AppSwitch name="applyChangesToFutureOccurences" />}
                                labelPlacement="start"
                                label={t('tasks')('applyChangesToFutureOccurences')}
                              />
                            ) : null}

                            {/* Re-occurence */}
                            {!editMode && !correctiveMode ? <ReOccurenceField name="reOccurence" projects={showProjects} /> : null}

                            {/* Deadlines */}
                            <AppFormFieldsRow>
                              <AppDateField
                                label={t('tasks')('deadline')}
                                name="deadline"
                                minDate={minDateProj}
                                required
                                disabled={editableFields && !editableFields.includes('deadline')}
                              />
                              <AppDateField
                                label={t('tasks')('documentDeadline')}
                                name="documentDeadline"
                                minDate={minDateProj}
                                required={docsRequired ? true : false}
                                disabled={editableFields && !editableFields.includes('documentDeadline')}
                              />
                            </AppFormFieldsRow>

                            {/* Urgent */}
                            <AppFormControlLabel
                              control={<AppSwitch name="urgent" />}
                              labelPlacement="start"
                              label={t('tasks')('urgent')}
                              disabled={editableFields && !editableFields.includes('urgent')}
                            />
                            <div className={styles.taskTypesFooter}>
                              {!editMode ? (
                                <div className={styles.taskTypesPrevious}>

                                  <AppButton type="button" variant="outlined" onClick={() => goToPhase(Phase.type)}>
                                    Previous
                                  </AppButton>
                                </div>) : null}
                              <div className={styles.taskTypesCancel}>
                                <AppButton type="submit" color="primary" inProgress={isSubmitting}>
                                  Save
                                </AppButton>
                              </div>
                            </div>
                          </FormLayoutTask>
                        </AppForm>,
                      ],
                    ]);
                  }}
                </Formik>
              ) : null}
              <WarningDialog
                open={connectedAssetsError}
                onClose={cancel}
                title="Error"
                descriptionText="Assets connected to the task have been archived or missing."
              />
            </FullScreenPage>



          </>
        )}
      </div>
    );



  }

};

export default TaskCreatePage;
