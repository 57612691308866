import React, { useState } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  ContractorStatus,
  contractorStatusUpdate,
  contractorStatusUpdateVariables,
  getClients,
  contractorRequest,
  contractorRequestVariables,
  removeRequest,
  removeRequestVariables,
  getContractorsWithRequests,
  getContractorsWithRequestsVariables,
} from 'models/graphql';
import switcher from 'utils/switcher';
import ActionsButton from 'components/ActionsButton';
import AppButtonLink from 'components/AppButtonLink';
import { GET_CLIENTS } from 'containers/Pm/Clients/graphql';
import { REQUEST_CONTRACTOR, REMOVE_REQUEST } from 'containers/shared/graphql';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import UpdateRequestDialog from 'containers/Pm/Contractors/components/UpdateRequestDialog';
import ContractorsList from 'containers/shared/Contractors/ContractorsList';
import { AppTableStatus } from 'components/AppTable';
import { GET_CONTRACTORS_WITH_REQUESTS, UPDATE_STATUS } from './graphql';
import { useNavigate } from 'react-router';

interface UpdateRequestDialogData {
  type: 'request' | 'remove';
  contractorId: string;
  contractorName: string;
  alreadyRequestedClientIds: string[];
}

const ContractorsPage: React.FC = () => {
  const { loading: contractorsLoading, data: contractorsData, refetch: contractorsRefetch } = useQuery<
    getContractorsWithRequests,
    getContractorsWithRequestsVariables
  >(GET_CONTRACTORS_WITH_REQUESTS);
  const contractors = contractorsData?.contractors;

  const [updateStatus] = useMutation<contractorStatusUpdate, contractorStatusUpdateVariables>(UPDATE_STATUS);

  const [requestAccess] = useMutation<contractorRequest, contractorRequestVariables>(REQUEST_CONTRACTOR, {
    update: (cache, { data }) => {
      if (!data?.contractorRequest.client.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'contractorRequests',
        args: { clientId: data.contractorRequest.client.id },
      });
    },
  });

  const [removeAccess] = useMutation<removeRequest, removeRequestVariables>(REMOVE_REQUEST, {
    update: (cache, { data }) => {
      if (!data?.removeRequest.client.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'contractorRequests',
        args: { clientId: data.removeRequest.client.id },
      });
    },
  });

  const [loadClients, { data: clientsData }] = useLazyQuery<getClients, getClients>(GET_CLIENTS);
  const clients = clientsData?.clients;

  const [requestAccessDialogData, setRequestAccessDialogData] = useState<UpdateRequestDialogData>();
  const [requestAccessDialogOpen, setRequestAccessDialogOpen] = useState(false);
  const openRequestAccessDialog = (data: UpdateRequestDialogData) => {
    setRequestAccessDialogData(data);
    setRequestAccessDialogOpen(true);
  };
  const closeRequestAccessDialog = () => setRequestAccessDialogOpen(false);

  const navigate = useNavigate();
  return (
    <>
      <ContractorsList
        onFilterSubmit={async ({ search, showArchived }) => {
          await contractorsRefetch({
            search: search || undefined,
            archived: showArchived || undefined,
          });
        }}
        addNewButton={
          <AppButtonLink to="/pm/contractors/add" type="button" color="primary">
            Add Contractor
          </AppButtonLink>
        }
        contractors={contractors}
        contractorsLoading={contractorsLoading}
        rowLink={(row) => `/pm/contractors/${row.id}`}
        clients={(row) => row.requests.map((request) => request.client.companyName).join(', ')}
        status={(row) => <AppTableStatus isArchived={row.status === ContractorStatus.ARCHIVED} />}
        actions={(row) => {
          return (
            <ActionsButton
              actions={[
                {
                  title: 'Request Contractor Access to Client',
                  action: () => {
                    loadClients();
                    openRequestAccessDialog({
                      type: 'request',
                      contractorId: row.id,
                      contractorName: row.companyName,
                      alreadyRequestedClientIds: row.requests.map((request) => request.client.id),
                    });
                  },
                },
                {
                  title: 'Remove Contractor Access to Client ',
                  action: () => {
                    loadClients();
                    openRequestAccessDialog({
                      type: 'remove',
                      contractorId: row.id,
                      contractorName: row.companyName,
                      alreadyRequestedClientIds: row.requests.map((request) => request.client.id),
                    });
                  },
                },
                {
                  title: 'Edit Details',
                  action: () => navigate(`/pm/contractors/${row.id}/edit`),
                },
                {
                  title:
                    switcher(row.status, [
                      [ContractorStatus.ACTIVE, 'Archive'],
                      [ContractorStatus.ARCHIVED, 'Activate'],
                    ]) || 'Archive',
                  action: async (setLoading) => {
                    setLoading(true);
                    await updateStatus({
                      variables: {
                        id: row.id,
                        status: switcher(row.status, [
                          [ContractorStatus.ACTIVE, ContractorStatus.ARCHIVED],
                          [ContractorStatus.ARCHIVED, ContractorStatus.ACTIVE],
                        ])!,
                      },
                    });
                    setLoading(false);
                  },
                },
              ]}
            />
          );
        }}
      />

      <UpdateRequestDialog
        open={requestAccessDialogOpen}
        title={
          requestAccessDialogData?.type === 'remove'
            ? 'Remove Contractor Access to Client'
            : 'Request Contractor Access to Client'
        }
        description={
          requestAccessDialogData?.type === 'remove'
            ? 'Select the client the contractor will no longer have an access.'
            : undefined
        }
        clientOptions={
          requestAccessDialogData
            ? clients?.filter((client) =>
                requestAccessDialogData.type === 'remove'
                  ? requestAccessDialogData.alreadyRequestedClientIds.includes(client.id)
                  : !requestAccessDialogData.alreadyRequestedClientIds.includes(client.id),
              )
            : undefined
        }
        ifClientOptionsEmpty={
          requestAccessDialogData?.type === 'remove' ? (
            <div>
              <i>{requestAccessDialogData.contractorName}</i> has no Access to any Client.
            </div>
          ) : (
            <div>
              <i>{requestAccessDialogData?.contractorName}</i> has already requested Access to all Clients.
            </div>
          )
        }
        onSend={async (clientId) => {
          if (!requestAccessDialogData) return;

          if (requestAccessDialogData.type === 'remove') {
            await removeAccess({
              variables: {
                clientId,
                contractorId: requestAccessDialogData.contractorId,
              },
            });
          } else {
            await requestAccess({
              variables: {
                clientId,
                contractorId: requestAccessDialogData.contractorId,
              },
            });
          }
        }}
        onClose={closeRequestAccessDialog}
      />
    </>
  );
};

export default ContractorsPage;
