import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useText from 'texts/useText.hook';
import MUIDataTable, { MUIDataTableOptions, SelectableRows } from 'mui-datatables';
import {
  getDocuments_documents as Document,
} from 'models/graphql';
import theme from 'style/theme';

import { useStores } from 'stores';
import useFieldStyles from 'components/form/AppField.style';
import { ReactComponent as EditIcon } from "assets/editIcon.svg";
import { ReactComponent as TrashIcon } from "assets/trashIcon.svg";
import { ReactComponent as AddIcon } from "assets/addIcon.svg";
import AddProjectModal from './AddProject'
import EditProjectModal from './EditProject'
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import GanttChart from '../../../components/Gantt'
import switcher from 'utils/switcher';
import AppTabs from 'components/AppTabs';
import ExpandedRows from "./ProjectExplandDetails"
import { useParams } from 'react-router';


const useStyles = makeStyles({
  table: {
    margin: '2px', // Adjust the margin value as needed
  },
  cell: {
    padding: '10px', // Adjust the padding value as needed
  },
  farRightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  selectField: {
    '& .MuiSelect-root': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: theme.fontSizes['14'],
      '&:focus': {
        background: 'inherit',
      },
    },
    '&.Mui-disabled .MuiSelect-icon': {
      filter: 'grayscale(100%)',
    },
    '&.displayPlaceholder .MuiSelect-select': {
      color: theme.palette.grey[500],
    },
  },
  arrayEmptyText: {
    color: theme.palette.grey[500],
  },
  placeholderItem: {
    display: 'none',
  },
  addInnerPaddingRight: {
    paddingRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '&>*': {
      marginLeft: '16px',
    },
    width: '100%',
  },
  iconButton: {
    width: 'min-content',
    minWidth: 'min-content',
    padding: '0 20px',
    '& > span': {
      width: 'max-content',
      height: 'max-content',
    },
  },
  gantt_control: {
    background: '#ededed',
    textAlign: 'center',
  },

})

const dataGantt = {
  /* data: [
       { id: 1, text: 'Task #1', start_date: '2019-04-15', duration: 3, progress: 0.6 },
       { id: 2, text: 'Task #2', start_date: '2019-04-18', duration: 3, progress: 0.4 }
   ],
   links: [
       { id: 1, source: 1, target: 2, type: '0' }
   ] */
  tasks: [{ "id": "f4f9090b-d26b-4e66-85ac-0b56da5bea1d", "text": "Shutdown Activities", "responsibleid": null, "start_date": "2024-06-18", "end_date": "2024-06-29", "type": null, "siteid": "3a690154-6fac-4dcf-9e36-3e06da3f3390", "details": "Carry out various tasks during July shutdown", "responsible": "Joe Bloggs ", "duration": 11 }, { "id": "18f8cf48-1ec0-40c9-b2dc-ea0930c08a2c", "text": "CCTV July Survey", "responsibleid": null, "start_date": "2024-05-31", "end_date": "2024-07-30", "type": null, "siteid": "3a690154-6fac-4dcf-9e36-3e06da3f3390", "details": "CCTV July Survey", "responsible": "Joe Bloggs", "duration": 60 }]
};





const ProjectPage: React.FC = () => {
  const styles = useStyles();
  const fieldStyles = useFieldStyles();
  const { t } = useText('projects');
  const { siteId } = useParams<{
    siteId: string;
  }>();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentsFiltered, setDocumentsFiltered] = useState<Document[]>([]);
  const classes = useStyles();
  const [projectsList, setprojectsList] = useState([])
  const { contextStore } = useStores();
  const [items, setItems] = useState([]); // State to store fetched items
  const [selectedItem, setSelectedItem] = useState('selhazard'); // State to store selected item

  const [isOpen, setIsOpen] = useState(false);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [cMeasureEntry, setCMeasureEntry] = useState([]);
  const [addHazardOpen, setAddHazardOpen] = useState(false);
  const [editHazardOpen, setEditHazardOpen] = useState(false);

  const [ganttData, setGanttData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activePanel, setActivePanel] = useState<'Projects' | 'plan'>('plan');

  const scrollToToday = useRef(null);
  const exportPDF = useRef(null);


  const [config, setConfig] = useState({
    date_format: "%Y-%m-%d %H:%i",
    //   scale_unit: "day",
    //   date_scale: "%d %M",
  });





  useEffect(() => {
    checktabindex(activePanel);
  }, [activePanel]);


  function checktabindex(indexvalue: any) {

    // console.log('Tab value', indexvalue)

    if (indexvalue === 'plan') {
      // console.log("Update Pie Charts for Tasks and hide compliance options");
      console.log("IN PLAN")
      getSiteProjectsGantt(siteId);

    }
    else if (indexvalue === 'Projects') {
      // console.log("Will call reset");
      console.log("IN PROJECTS")
      getSiteProjects(siteId);

    }


  }



  const ExtraButtons = () => (
    <>
      <Tooltip disableFocusListener title="Add New Project">
        <IconButton size="large" onClick={() => handleAdd()}>
          <AddIcon  />
        </IconButton>
      </Tooltip>
    </>
  );

  const tableoptions = {
    filter: true,
    customToolbar: ExtraButtons,
    print: false,
    selectableRows: 'none' as SelectableRows,
    searchable: true,
    download: true,
    viewColumns: false,
    tableBodyHeight: "500px",
    fixedHeader: true,
    jumpToPage: false,
    expandableRows: true,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      console.log("Selected Proj: ", projectsList[rowMeta.dataIndex])
      // changeRowColor(rowMeta.dataIndex);
      return <ExpandedRows index={projectsList[rowMeta.dataIndex]} />;

    },
  };


  const columnsMUITable = [
    {
      name: "projectname",
      label: "Project",
      options: {
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
      }
    },
    {
      name: "details",
      label: "Description",
      options: {
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
      }
    },
    {
      name: "responsible",
      label: "Responsible",
      options: {
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
      }
    },
    {
      name: "startdate",
      label: "Start Date",
      options: {
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
        sort: true,
        customBodyRender: (value: string | number | Date) =>
          moment(new Date(value)).format('DD/MM/YYYY')
      }
    },
    {
      name: "enddate",
      label: "End Date",
      options: {
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
        sort: true,
        customBodyRender: (value: string | number | Date) =>
          moment(new Date(value)).format('DD/MM/YYYY')
      }
    },
    {
      name: "Action",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            (<div>
              <IconButton onClick={() => handleEdit(projectsList[dataIndex])} size="large">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(projectsList[dataIndex])} size="large">
                <TrashIcon />
              </IconButton>
            </div>)
          );
        },
      },
    }
  ]



  const [cmeasuresColumnDisplay, setCmeasuresColumnDisplay] = useState(columnsMUITable)

  const [filterSelected, setFilterSelected] = useState();


  const handleEdit = (cmeasureentry: any) => {
    // Handle edit logic
    console.log('Edit row with ID:', cmeasureentry.id);
    setCMeasureEntry(cmeasureentry)


    //setSelectedItem(cmeasureentry.id);


    setEditOpen(true);
  };




  const handleAdd = () => {

    setConfirmOpen(true);


  }





  const handleDelete = (selproject: any) => {
    // Handle delete logic
    console.log('Delete Project:', selproject.projectname);


    const isConfirmed = window.confirm(`Are you sure you want to delete project "${selproject.projectname}"? Warning: This cannot be undone.`);
    const cid = selproject.id;
    // If the user confirms, call the onDelete function
    if (isConfirmed) {
      console.log("DELETE")

      let updCmeasure = `projects/project/${cid}`
      try {
        fetch(`${process.env.API_ROOT}${updCmeasure}`, {
          method: 'DELETE',
          credentials: 'include',
        }).then(async (response: any) => {

          // refresh the list
          getSiteProjects(siteId);


        });
      }
      catch (error) {
        console.error(error); /* eslint-disable-line */
      }

    }
  };


  const handleSave = (updatedValue, id) => {
    // Handle the updated value received from the modal
    console.log('Updated value:', updatedValue);
    console.log("ID to update:", cMeasureEntry.id)

    let projectid = cMeasureEntry.id;
    // Here, you can update the state or perform any other actions based on the updated value
    // For example, you can update the content state:

    // Will perform the update here - call backend
    let bodytopass = {
      "projectName": updatedValue.projectName,
      "projectDesc": updatedValue.projectDesc,
      "responsible": updatedValue.responsible,
      "startdate": updatedValue.startDate,
      "endDate": updatedValue.endDate
    }

    let valtopass = JSON.stringify(bodytopass);

    console.log("valtopass:", valtopass)
    let updCmeasure = `projects/updateproject/?projid=${projectid}`
    try {
      fetch(`${process.env.API_ROOT}${updCmeasure}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: valtopass,
        credentials: 'include',
      }).then(async (response: any) => {

        console.log("Have comeback")
        // refresh the list
        // getHazardControlMeasures(selectedItem.id);
        //console.log("selectedItem:", selectedItem)

      });
    }
    catch (error) {
      console.error(error); /* eslint-disable-line */
    }



  };




  const handleNewProject = (newValue) => {
    // Handle the updated value received from the modal
    console.log('newValue value:', newValue);

    // Here, you can update the state or perform any other actions based on the updated value
    // For example, you can update the content state:

    // need the hazard id = selectedItem.id


    // Will put in body but for the moment...
    let creProject = `projects/project/?projectDesc=${newValue.projectDesc}&projectName=${newValue.projectName}&responsible=${newValue.responsible}&startDate=${newValue.startDate}&endDate=${newValue.endDate}&siteid=${siteId}`
    try {
      fetch(`${process.env.API_ROOT}${creProject}`, {
        method: 'POST',
        credentials: 'include',
      }).then(async (response: any) => {

        // refresh the list
        //getHazardControlMeasures(selectedItem.id);
        //console.log("selectedItem:", selectedItem)
        getSiteProjects(siteId);
      });
    }
    catch (error) {
      console.error(error); /* eslint-disable-line */
    }



  };



  const getSiteProjectsGantt = async (siteid: any) => {
    let url = `projects/projgantt/${siteid}`;

    try {
      const response = await fetch(`${process.env.API_ROOT}${url}`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      // Now get links
      url = `projects/projganttlinks/${siteid}`;
      const linkresponse = await fetch(`${process.env.API_ROOT}${url}`, {
        method: 'GET',
        credentials: 'include',
      });
      const linksdata = await linkresponse.json();

      if (linksdata) {
        setGanttData({ tasks: data, links: linksdata });

      }
      else if (data) {
        setGanttData({ tasks: data });
      } else {
        console.error('Invalid data format:', data);
      }


      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  const getSiteProjects = async (siteid: any) => {
    const isMounted = { current: true };
    let getProjectsLink = `projects/project/${siteid}`
    console.log("getProjectsLink:", getProjectsLink)
    try {
      fetch(`${process.env.API_ROOT}${getProjectsLink}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response: any) => {
        const dataret = await response.json();
        console.log("dataret:", dataret)
        setprojectsList(dataret)
      });
    }
    catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }



  const getHazardControlMeasures = async (hazid: any) => {


    let getControlsLink = `ra/controls/${hazid}`
    try {
      fetch(`${process.env.API_ROOT}${getControlsLink}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response: any) => {
        const dataret = await response.json();
        console.log("dataret:", dataret)
        setprojectsList(dataret)
      });
    }
    catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }

  useEffect(() => {
    console.log("Will get projects Gantt")
    getSiteProjectsGantt(siteId);
    getSiteProjects(siteId);
    setLoading(false)
  }, []);


  const handleSelectionChange = (event: { target: { value: any; }; }) => {
    const selectedItemId = event.target.value;
    // Find the selected item from the fetched items
    const selectedItem = items.find(item => item.id === selectedItemId);
    setSelectedItem(selectedItem);

    // fetch the rest of the tables
    getHazardControlMeasures(selectedItemId);


  };

  /* const handleScrollToToday = () => {
    console.log("Scroll Pressed")
    if (scrollToToday.current) {
      scrollToToday.current();
    }
  }; */
  /*const handleExportToPDF = () => {
    console.log("Export")
    if (exportPDF.current) {
      exportPDF.current();
    }
  }; */

  return (
    <div> {loading ? (
        <label> </label>
      ) : (
        <>
          <div className={classes.farRightContainer}>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td className={classes.cell}> </td>



                  <td className={classes.cell}>


                  </td>
                  <td className={classes.cell}> </td>
                </tr>
              </tbody>
            </table>

          </div>


          <Box style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }} sx={{
            mb: 2
          }}>



          </Box>
          <>
            <AppTabs
              fullWidth


              tabs={[
                {
                  name: "Plan",
                  onActive: () => setActivePanel('plan'),
                  active: activePanel === 'plan',
                },
                {
                  name: "Projects",
                  onActive: () => { setActivePanel('Projects') },
                  active: activePanel === 'Projects',
                },

              ]}
            />
          </>
          <br></br>

          {switcher(activePanel, [

            ['plan', <>


              <div className="gantt-container" style={{ width: '100%', height: '600px' }}>    <GanttChart tasks={JSON.stringify(ganttData)} config={config} site={siteId} />




              </div></>],
            ['Projects', <MUIDataTable title='Projects' data={projectsList} columns={columnsMUITable} options={tableoptions} />],
          ])}




          <div>



            <br></br>
            <br></br>
            <br></br>

            <AddProjectModal
              content=""
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onSave={handleNewProject}
              onClose={() => setConfirmOpen(false)}
              title="Add Project" />
            <EditProjectModal
              content={cMeasureEntry}
              open={editOpen}
              setOpen={setEditOpen}
              onSave={handleSave}
              onClose={() => setEditOpen(false)}
              title="Edit Project" />




          </div>
          <div></div>
        </>)}
    </div>
  );
};

export default ProjectPage;