import { PaginationInput } from 'models/graphql';
import useText from 'texts/useText.hook';
import useSearchParams from './useSearchParams';

export default function usePaginationParams(): PaginationInput | undefined {
  const { tt } = useText('urls');

  let pageNumber: number;
  const [pageParam] = useSearchParams(tt('urls')('queries')('page'));
  if (pageParam === undefined) {
    pageNumber = 1;
  } else {
    const parsedPageParam = parseInt(pageParam, 10);
    pageNumber = Number.isNaN(parsedPageParam) ? 1 : parsedPageParam;
  }

  return { page: pageNumber };
}
