import { Tab, Tabs, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import cn from 'utils/cn';

const styleRules = (theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      '&.marginBottom': {
        marginBottom: theme.spacing(3),
      },
    },
    indicator: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1,
      background: theme.palette.grey['300'],
    },
    tabs: {
      minHeight: 0,

      '&.normalCase .MuiTabs-indicator': {
        height: 4,
      },
      '& .Mui-selected': {
        backgroundColor: theme.palette.grey['100'],
        borderBottom: "2px solid #373985",
        borderColor: "#373985",
      },
      '& .MuiTabs-scrollButtons': {
        top: '10%',
        height: '80%',
        position: 'absolute',
        '&:first-child': {
          left: 0,
        },
        '&:last-child': {
          right: 0,
        },
      },
      '& .MuiButtonBase-root.MuiTabs-scrollButtons': {
        zIndex: 1,
        backgroundColor: theme.palette.grey['100'],
      },
    },
    tab: {
      minWidth: 0,
      minHeight: 0,
      textAlign: 'left',
      fontSize: theme.fontSizes['14'],
      color: theme.palette.grey['600'],

      '&.fullWidth': {
        flexGrow: 1,
        flexBasis: 0,
        marginRight: 0,
      },

      '&.normalCase': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontSize: theme.fontSizes[12],
        textTransform: 'none',
        color: theme.brandColors.black,
      },
    },
  });
const useStyles = makeStyles(styleRules);

interface Props {
  tabs: Array<{ name: string; onActive: () => void; active: boolean }>;
  fullWidth?: boolean;
  normalCase?: boolean;
  marginBottom?: boolean;
  scrollable?: boolean;
}

const AppTabs: React.FC<Props> = ({ tabs, fullWidth, normalCase, marginBottom, scrollable }) => {
  const [selected, setSelected] = useState<string | false>(tabs.find((tab) => tab.active)?.name || false);
  useEffect(() => {
    setSelected(tabs.find((tab) => tab.active)?.name || false);
  }, [tabs]);

  const styles = useStyles();

  const selectedValue = tabs.find(({ name }) => name === selected) ? selected : false;

  return (
    <div className={cn(styles.container, { marginBottom })}>
      <div className={styles.indicator} />
      <Tabs
        variant={scrollable ? 'scrollable' : undefined}
        scrollButtons={scrollable ? true : undefined}
        value={selectedValue}
        onChange={(event, value) => {
          setSelected(value);
          const selectedTab = tabs.find((tab) => tab.name === value);
          if (selectedTab && selectedTab.onActive) {
            selectedTab.onActive();
          }
        }}
        indicatorColor="primary"
        textColor="primary"
        className={cn(styles.tabs, { normalCase })}
      >
        {tabs.map((tab) => (
          <Tab label={tab.name} value={tab.name} key={tab.name} className={cn(styles.tab, { fullWidth, normalCase })}/>
        ))}
      </Tabs>
    </div>
  );
};

export default AppTabs;