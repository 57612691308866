import React, { useState } from 'react';
import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useHideNavigation } from 'components/HideNavigation';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import {
  drawingCreate,
  drawingCreateVariables,
  clientSites,
  clientSitesVariables,
  drawingUpdate,
  drawingUpdateVariables,
  drawing as drawingGQL,
  drawingVariables,
  SystemType,
} from 'models/graphql';
import Yup from 'utils/yup';
import AppForm from 'components/form/AppForm';
import { PdfFileInput } from 'components/FileInput';
import { FullScreenPage, FormLayout } from 'components/layout';
import AppSelectField from 'components/form/AppSelectField';
import enumToArray from 'utils/enumToArray';
import useText from 'texts/useText.hook';
import apolloCacheEvict, { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import useParentLocation from 'utils/useParentLocation.hook';
import FilesList from 'components/FileInput/FilesList';
import { GET_CLIENT_SITES, GET_DRAWING } from '../graphql';
import { CREATE_DRAWING, UPDATE_DRAWING } from './graphql';
import errorIcon from 'assets/errorIcon.svg';
import { useNavigate, useParams } from 'react-router';

const styleRules = () =>
  createStyles({
    noFileErrorText: {
      color: '#F15541',
      fontSize: '12px',
      lineHeight: '1',
      marginLeft: '8px',
    },
    noFileError: {
      marginTop: '8px',
    },
  });
const useStyles = makeStyles(styleRules);

const systemTypeArray = enumToArray(SystemType);

interface FormValues {
  site?: string;
  systemType?: SystemType;
}

const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
  site: Yup.string().required('Please choose a site.'),
  systemType: Yup.mixed<SystemType>().oneOf(systemTypeArray).required('Please choose a system type.'),
});

const ClientDrawingsCreatePage: React.FC = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { clientId, drawingId } = useParams<{
    clientId: string;
    drawingId: string;
  }>();
  const parentUrl = useParentLocation(drawingId ? '../..' : '..');
  const { t } = useText('systemTypes');
  const defState: { id: string; name: string }[] = [];
  const [files, setFiles] = useState(defState);
  const [error, setError] = useState(false);
  useHideNavigation();

  const mode = drawingId ? 'update' : 'create';

  const { data: drawingQueryData, loading: drawingQueryLoading } = useQuery<drawingGQL, drawingVariables>(GET_DRAWING, {
    skip: mode === 'create',
    variables: {
      drawingId,
    },
    onCompleted: (response) => {
      if (mode === 'update') {
        setFiles([
          {
            id: response?.drawing.file?.id,
            name: response?.drawing.file?.originalName,
          },
        ]);
      }
    },
  });

  const [createDrawing] = useMutation<drawingCreate, drawingCreateVariables>(CREATE_DRAWING, {
    update(cache, { data }) {
      if (!data) return;
      apolloCacheEvictQuery({ cache, query: 'drawings' });
      apolloCacheEvict({
        cache,
        typename: 'Site',
        id: data?.drawingCreate[0].site.id,
        fieldName: 'drawings',
      });
    },
  });

  const [updateDrawing] = useMutation<drawingUpdate, drawingUpdateVariables>(UPDATE_DRAWING, {
    update(cache, { data }) {
      if (!data) return;
      apolloCacheEvictQuery({ cache, query: 'drawings' });
      apolloCacheEvict({
        cache,
        typename: 'Site',
        id: data?.drawingUpdate.site.id,
        fieldName: 'drawings',
      });
    },
  });

  const { loading: sitesLoading, data: sitesData } = useQuery<clientSites, clientSitesVariables>(GET_CLIENT_SITES, {
    variables: { id: clientId },
  });

  return (
    <FullScreenPage
      title={mode === 'update' ? 'Edit Drawing' : 'Upload Drawings'}
      loading={sitesLoading || (mode === 'update' && drawingQueryLoading)}
    >
      <Formik<FormValues>
        initialValues={{
          site: drawingQueryData?.drawing.site?.id,
          systemType: drawingQueryData?.drawing.systemType,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (files.length < 1) {
            setError(true);
            return;
          }
          if (mode === 'update') {
            await updateDrawing({
              variables: {
                drawing: {
                  id: drawingId,
                  siteId: values.site!,
                  systemType: values.systemType!,
                  fileId: files[0].id,
                },
              },
            });
          } else {
            await createDrawing({
              variables: {
                drawing: {
                  siteId: values.site!,
                  systemType: values.systemType!,
                  files: files.map((file) => file.id),
                },
              },
            });
          }
          setSubmitting(false);
          resetForm();
          navigate(parentUrl);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <AppForm handleSubmit={handleSubmit}>
            <FormLayout isSubmitting={isSubmitting} parentHref={parentUrl}>
              <AppSelectField
                label="Site"
                name="site"
                autoFocus
                required
                options={{
                  array: sitesData!.client.sites,
                  value: (site) => site.id,
                  key: (site) => site.id,
                  template: (site) => site.name,
                }}
              />
              <AppSelectField
                label="System Type"
                name="systemType"
                required
                options={{
                  array: systemTypeArray,
                  value: (system) => system,
                  key: (system) => system,
                  template: (system) => t('systemTypes')(system),
                }}
              />
              <Box>
                <PdfFileInput
                  onUpload={(id: string, file: string) => {
                    if (mode === 'create') setFiles((oldFiles) => [...oldFiles, { id, name: file }]);
                    else setFiles([{ id, name: file }]);
                  }}
                  multiple={mode === 'create'}
                />
                {error && (
                  <div className={styles.noFileError}>
                    <img alt="Error" src={errorIcon} />
                    <span className={styles.noFileErrorText}>Please upload at least one file.</span>
                  </div>
                )}
                <Box sx={{
                  mt: 3
                }}>
                  <FilesList
                    files={files}
                    onRemove={(removedFileId) =>
                      setFiles((oldFiles) => oldFiles.filter((file) => file.id !== removedFileId))
                    }
                  />
                </Box>
              </Box>
            </FormLayout>
          </AppForm>
        )}
      </Formik>
    </FullScreenPage>
  );
};

export default ClientDrawingsCreatePage;
