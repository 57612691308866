import React, { useState } from 'react';
import AppDialog from 'components/AppDialog';
import { Formik } from 'formik';
import { AppForm } from 'components/form';
import AppButton from 'components/AppButton';
import AppCheckboxField from 'components/form/AppCheckbox';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import trashIcon from 'assets/trashIcon_mono.svg';
import warningIcon from 'assets/warningIcon.svg';


interface FormValues {
  checkbox: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    background: '#f3f4f9',
    width: '175px',
    height: '175px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stars: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  checkbox: {
    width: '100%',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
  },
  fullWidth: {
    gridColumn: '1/3',
  },
  warning: {
    height: '30px',
    width: '100%',
  },
  descGridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
}));

const RemoveDialog: React.FC<{
  open: boolean;
  title: string;
  descriptionText?: string;
  deleteIcon?: boolean;
  onClose: () => void;
  onSend: () => void | Promise<void>;
  agreementText?: string;
  submitText?: string;
}> = ({ open, deleteIcon, title, descriptionText, onClose, onSend, agreementText, submitText }) => {
  const styles = useStyles();
  const [error, setError] = useState(false);

  const description = (
    <div className={styles.descGridContainer}>
      <img
        src={deleteIcon ? trashIcon : warningIcon}
        alt="Warning"
        className={styles.warning}
      />
      <Typography className={styles.paddingLeft}>{descriptionText}</Typography>
    </div>
  );

  return (
    <AppDialog open={open} onClose={onClose} title={title}>
      <Formik<FormValues>
        initialValues={{ checkbox: false }}
        onSubmit={async (values) => {
          setError(false);
          if (agreementText && !values.checkbox) {
            setError(true);
            return;
          }
          await onSend();
          onClose();
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <AppForm handleSubmit={handleSubmit}>
              {descriptionText && <Box sx={{
                mb: 1
              }}>{description}</Box>}
              {agreementText && (
                <Box sx={{
                  mb: 1
                }}>
                  <div className={styles.gridContainer}>
                    <AppCheckboxField name="checkbox" className={styles.checkbox} />
                    <Typography className={styles.paddingLeft}>{agreementText}</Typography>
                    {error && (
                      <div className={styles.fullWidth}>
                        <Typography sx={{
                          color: "error"
                        }}>You must agree to the statement above to continue.</Typography>
                      </div>
                    )}
                  </div>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 3
                }}>
                <Box sx={{
                  mr: 1
                }}>
                  <AppButton type="button" variant="outlined" onClick={onClose} color="neutral">
                    Cancel
                  </AppButton>
                </Box>
                <AppButton type="submit" inProgress={isSubmitting} color="error">
                  {submitText || 'Remove'}
                </AppButton>
              </Box>
            </AppForm>
          );
        }}
      </Formik>
    </AppDialog>
  );
};

export default RemoveDialog;
