import { useMutation, useQuery } from '@apollo/client';
import ContentStateWrapper from 'components/ContentStateWrapper';
import { AppForm, AppSelectField, AppTextField } from 'components/form';
import { FormLayout, FullScreenPage } from 'components/layout';
import { Formik } from 'formik';
import {
  getClientSites,
  getClientSitesVariables,
  getSystem,
  getSystemVariables,
  systemCreate,
  systemCreateVariables,
  SystemType,
  systemUpdate,
  systemUpdateVariables,
} from 'models/graphql';
import React from 'react';
import useText from 'texts/useText.hook';
import useCancel from 'utils/useCancel.hook';
import Yup from 'utils/yup';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import { CREATE_SYSTEM, GET_SITES, GET_SYSTEM, UPDATE_SYSTEM } from '../graphql';
import { useParams, useLocation } from 'react-router';
import enumToArray from 'utils/enumToArray';

interface FormValues {
  siteId: string;
  systemType: SystemType;
  systemName: string;
}
interface ValidationSchema {
  systemType: SystemType;
}

const ClientSystemCreatePage: React.FC = () => {
  const { clientId, systemId } = useParams<{ clientId: string; systemId: string }>();

  const location = useLocation();
  const pathStateSystemType = location.state?.systemType;
  const pathStateSiteId = location.state?.siteId;

  const editMode = !!systemId;

  // Queries
  const { data: systemData, loading: systemDataLoading } = useQuery<getSystem, getSystemVariables>(GET_SYSTEM, {
    variables: { id: systemId },
    skip: !editMode,
  });
  const system = systemData?.system;

  const { data: sitesData, loading: sitesDataLoading } = useQuery<getClientSites, getClientSitesVariables>(GET_SITES, {
    variables: { clientId },
    skip: editMode,
  });
  const sites = sitesData?.client.sites;
  const allowedSystems = sitesData?.client.allowedSystems || (system ? [system.type] : undefined);

  // Mutations
  const [createSystem] = useMutation<systemCreate, systemCreateVariables>(CREATE_SYSTEM, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Client',
        id: clientId,
        fieldName: 'systems',
      }),
  });

  const [updateSystem] = useMutation<systemUpdate, systemUpdateVariables>(UPDATE_SYSTEM);

  // Form initial values
  const initialValues = (): FormValues => {
    if (editMode && system) {
      return { siteId: system.site.id, systemType: system.type, systemName: system.name };
    }
    if (!editMode && sites && sites[0] && allowedSystems && allowedSystems[0]) {
      return {
        siteId: pathStateSiteId || sites[0].id,
        systemType: pathStateSystemType || allowedSystems[0],
        systemName: '',
      };
    }
    // else
    throw new Error();
  };

  const parentHref = `/client/${clientId}/configure/assets/systems`;
  const cancel = useCancel(parentHref);
  const { t } = useText('clients', 'systemTypes');

  const systemTypeArray = enumToArray(SystemType);

  const validationSchema: Yup.ObjectSchema<ValidationSchema> = Yup.object().shape({
    systemType: Yup.mixed<SystemType>().oneOf(systemTypeArray).required()
  });
  
  return (
    <FullScreenPage title={editMode ? t('clients')('editSystem') : t('clients')('addSystem')}>
      <div>
        <ContentStateWrapper loading={sitesDataLoading || systemDataLoading}>
          {allowedSystems && ((editMode && system) || (!editMode && sites && sites[0])) ? (
            <Formik<FormValues>
              initialValues={initialValues()}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                if (editMode) {
                  await updateSystem({
                    variables: { id: systemId, system: { name: values.systemName } },
                  });
                } else {
                  await createSystem({
                    variables: { siteId: values.siteId, system: { name: values.systemName, type: values.systemType } },
                  });
                }

                cancel();
              }}
            >
              {({ handleSubmit, isSubmitting }) => {
                return (
                  <AppForm handleSubmit={handleSubmit}>
                    <FormLayout isSubmitting={isSubmitting} parentHref={parentHref}>
                      <AppSelectField
                        name="siteId"
                        label={t('clients')('site')}
                        options={{
                          array: sites || (system ? [system.site] : []),
                          value: (site) => site.id,
                          key: (site) => site.id,
                          template: (site) => site.name,
                        }}
                        disabled={editMode}
                      />
                      <AppSelectField
                        name="systemType"
                        label={t('clients')('systemType')}
                        options={{
                          array: allowedSystems,
                          value: (systemType) => systemType,
                          key: (systemType) => systemType,
                          template: (systemType) => t('systemTypes')(systemType),
                        }}
                        disabled={editMode}
                      />
                      <AppTextField label={t('clients')('systemName')} name="systemName" />
                    </FormLayout>
                  </AppForm>
                );
              }}
            </Formik>
          ) : null}
        </ContentStateWrapper>
      </div>
    </FullScreenPage>
  );
};

export default ClientSystemCreatePage;
