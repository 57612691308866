import React from 'react';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useMutation } from '@apollo/client';
import {
  contractorRequests,
  contractorRequestsVariables,
  removeRequestVariables,
  removeRequest as removeRequestGQL,
} from 'models/graphql';
import { Formik } from 'formik';
import AppTable from 'components/AppTable';
import ActionsButton from 'components/ActionsButton';
import AppButtonLink from 'components/AppButtonLink';
import { AppSearchField } from 'components/form';
import ContractorRequestStatus from 'components/ContractorRequestStatus';
import Rating from 'components/Rating';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import { REMOVE_REQUEST } from 'containers/shared/graphql';
import useText from 'texts/useText.hook';
import { GET_REQUESTS } from './graphql';
import { useParams, useLocation} from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
    filterRight: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    searchField: {
      width: theme.sizes.containerWidth,
      maxWidth: '100%',
    },
  });
const useStyles = makeStyles(styles);

interface FormValues {
  search: string;
}

const ClientContractorsPage: React.FC = () => {
  const classes = useStyles();
  const { clientId } = useParams<{ clientId: string }>();
  const { pathname } = useLocation();

  const { loading, data, refetch } = useQuery<contractorRequests, contractorRequestsVariables>(GET_REQUESTS, {
    variables: { clientId },
  });
  const [removeRequest] = useMutation<removeRequestGQL, removeRequestVariables>(REMOVE_REQUEST, {
    update(cache, { data: cachedData }) {
      if (!cachedData) return;
      apolloCacheEvictQuery({ cache, query: 'contractorRequests' });
    },
  });

  const { tt } = useText('contractors');

  return (
    <>
      <Formik<FormValues>
        initialValues={{
          search: '',
        }}
        onSubmit={async (values) => {
          const { search } = values;
          await refetch({ clientId, search });
        }}
      >
        {({ submitForm }) => (
          <div className={classes.filter}>
            <AppSearchField
              name="search"
              placeholder="Search by Contractor"
              onCommit={() => submitForm()}
              className={classes.searchField}
            />
            <div className={classes.filterRight}>
              <div>
                <AppButtonLink to="add" type="button" color="primary">
                  Add Contractor
                </AppButtonLink>
              </div>
            </div>
          </div>
        )}
      </Formik>

      <AppTable
        data={data && data.contractorRequests}
        loading={loading}
        noDataMessage="No Contractors"
        rowLink={(row) => `${pathname}/${row.contractor.id}`}
        columns={[
          {
            key: 'companyName',
            name: 'Company Name',
            template: (row) => row.contractor.companyName,
          },
          {
            key: 'address',
            name: 'Address',
            template: (row) => row.contractor.address,
          },
          {
            key: 'expertise',
            name: 'Expertise',
            template: (row) =>
              row.contractor.expertise.map((expertise) => tt('contractors')('expertises')(expertise)).join(', '),
          },
          {
            key: 'rating',
            name: 'Rating',
            align: 'center',
            template: function RatingTemplate(row) {
              return <Rating rating={row.contractor.rating} />;
            },
          },
          {
            key: 'status',
            name: 'Access Status',
            align: 'center',
            template: function statusTemplate(row) {
              return <ContractorRequestStatus accessStatus={row.accessStatus} />;
            },
          },
          {
            key: 'actions',
            link: null,
            template: function ActionsTemplate(row) {
              return (
                <ActionsButton
                  actions={[
                    {
                      title: 'Remove Access',
                      action: async (setLoading) => {
                        setLoading(true);
                        await removeRequest({
                          variables: {
                            contractorId: row.contractor.id,
                            clientId,
                          },
                        });
                        setLoading(false);
                      },
                    },
                  ]}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default ClientContractorsPage;
