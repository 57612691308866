import { useLocation, useNavigate } from 'react-router';

interface BaseTab {
  name: string;
  relativeUrl: string;
}

type TabType = BaseTab | false | undefined;

const useCreateTabs = (baseUrl: string, tabs: TabType[]) => {
  
  const location = useLocation();
  const navigate = useNavigate();


  const removeFalsyValues = (tab: TabType): tab is BaseTab => !!tab;
  const baseTabToTab = ({ name, relativeUrl }: BaseTab) => {
    const prefixedRelativeUrl = relativeUrl === '' || relativeUrl.startsWith('/') ? relativeUrl : `/${relativeUrl}`;
    const fullUrl = `${baseUrl}${prefixedRelativeUrl}`;

    return {
      name,
      onActive: () => navigate(fullUrl),
      active: location.pathname === fullUrl,
    };
  };
  return tabs.filter(removeFalsyValues).map(baseTabToTab);
};

export default useCreateTabs;
