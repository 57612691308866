import { FormControlLabel, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import useFieldStyles from 'components/form/AppField.style';
import React from 'react';
import cn from 'utils/cn';
import removeIcon from 'assets/removeIcon.svg';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileListItem: {
      textDecoration: 'underline',
      color: '#69CC84',

      '&.disabled': {
        color: theme.palette.grey[500],
      },
    },
    fileList: {
      listStyle: 'none',
      paddingLeft: '0',
      margin: '0',
    },
    fileListGrid: {
      width: '100%',
      minWidth: '250px',
      display: 'grid',
      gridTemplateColumns: '9fr 1fr',
      padding: theme.spacing(2),
      alignItems: 'center',
      '&:not(:first-child)': {
        borderTop: '1px solid #E5E5E5',
      },
    },
    filesArea: {
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      cursor: 'default',
    },
    removeButton: {
      cursor: 'pointer',
      maxHeight: 'unset',
    },
    noBorder: {
      border: 'none',
    },
  }),
);

const FilesList: React.FC<{
  label?: string;
  files: Array<{
    id: string;
    name: string;
  }>;
  onRemove?: (fileId: string) => void;
  bottomSection?: React.ReactNode;
  disabled?: boolean;
}> = ({ label, files, onRemove, bottomSection, disabled }) => {
  const styles = useStyles();
  const fieldStyles = useFieldStyles();

  return (
    <div>
      <FormControlLabel
        labelPlacement="top"
        className={fieldStyles.label}
        label={label}
        onClick={(event) => {
          // prevent calling the first file's remove button for every click inside FormControlLabel
          event.preventDefault();
        }}
        control={
          files.length || bottomSection ? (
            <div className={styles.filesArea}>
              <ul className={styles.fileList}>
                {files.map((file) => (
                  <li key={file.id} className={styles.fileListGrid}>
                    <div className={cn(styles.fileListItem, { disabled })}>{file.name}</div>
                    {!disabled && onRemove ? (
                      <button
                        onClick={() => onRemove(file.id)}
                        onKeyPress={() => onRemove(file.id)}
                        type="button"
                        tabIndex={0}
                        className={styles.noBorder}
                      >
                        <img src={removeIcon} alt="Remove file" className={styles.removeButton} />
                      </button>
                    ) : null}
                  </li>
                ))}
                {bottomSection ? <li className={styles.fileListGrid}>{bottomSection}</li> : null}
              </ul>
            </div>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export default FilesList;
