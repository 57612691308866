import React from 'react';

import useMode, { Mode } from 'utils/useMode.hook';
import AccountPage from 'containers/shared/AccountPage';
import NotificationsPage from 'containers/shared/Technician/NotificationsPage';
import HomePage from './Home/HomePage';
import TasksPage from './Tasks/TasksPage';
import AssetsRoutes from './Assets/AssetsRoutes';
import TaskDetailsRoutes from './Tasks/TaskDetails/TaskDetailsRoutes';
import { Navigate, Route, Routes } from 'react-router';

const TechRoutes: React.FC = () => {
  const { mode } = useMode();
  if (mode !== Mode.TECHNICIAN) {
    return <Navigate to="/" />;
  }
  return (
    <Routes>
      <Route path={`home`} element={<HomePage />} />
      <Route path={`notifications`} element={<NotificationsPage />} />

      <Route path={`works/:taskId`}>
        <Route index element={<TaskDetailsRoutes />} />
        <Route path={`*`} element={<TaskDetailsRoutes />} />
      </Route>

      <Route path={`works`} element={<TasksPage />} />
      <Route path={`account`} element={<AccountPage />} />

      <Route path={`:userId/assets`}>
        <Route index element={<AssetsRoutes />} />
        <Route path={`*`} element={<AssetsRoutes />} />
      </Route>

      <Route path="*" element={<Navigate to={`../home`} />} />
    </Routes>
  );
};

export default TechRoutes;
