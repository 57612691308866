import React from 'react';
import useMode, { Mode } from 'utils/useMode.hook';
import AccountPage from 'containers/shared/AccountPage';
import TaskRoutes from './Tasks/TaskRoutes';
import ContractorDetailsRoutes from './ContractorDetails/ContractorDetailsRoutes';
import { Navigate, Route, Routes } from 'react-router';

const ContractorRoutes: React.FC = () => {
  const { mode } = useMode();
  if (mode !== Mode.PM && mode !== Mode.CONTRACTOR) {
    return <Navigate to="/" />;
  }
  return (
    <Routes>
      <Route path={`work-instructions`}>
        <Route index element={<TaskRoutes />} />
        <Route path={`*`} element={<TaskRoutes />} />
      </Route>
      <Route path={`configure`}>
        <Route index element={<ContractorDetailsRoutes />} />
        <Route path={`*`} element={<ContractorDetailsRoutes />} />
      </Route>
      <Route path={`account`} element={<AccountPage />} />
      <Route path="*" element={<Navigate to={`../work-instructions`} />} />
    </Routes>
  );
};

export default ContractorRoutes;
