import React, { Dispatch, SetStateAction } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import thermometerIcon from 'assets/thermometerIcon.svg';
import cn from 'utils/cn';
import { assetsForSite_site_assets_pumpInfo as PumpInfoType } from 'models/graphql';

const styles = (theme: Theme) => {
  const green = theme.palette.primary.main;
  const white = '#FFF';

  return createStyles({
    pumpsContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    pumpWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      border: `1px solid ${green}`,
      borderRadius: theme.spacing(1),
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },
    pumpContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
    },
    pumpParams: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    paramTitle: {
      fontWeight: 'bold',
      '& span': {
        fontWeight: 'normal',
      },
    },
    paramSubTitle: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
      '& span': {
        fontWeight: 'normal',
      },
    },
    pumpGraphic: {
      marginLeft: 'auto',
    },
    box1: {
      display: 'flex',
      justifyContent: 'center',
      border: `1px solid ${green}`,
      borderTop: 0,
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      width: 240,
      height: 200,
      margin: '20px 20px 0 20px',
      overflow: 'hidden',
    },
    box2: {
      position: 'relative',
      height: '100%',
      width: '100%',
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
    box3: {
      border: `1px solid ${green}`,
      borderTop: 0,
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      height: '90%',
      backgroundColor: white,
      display: 'flex',
      justifyContent: 'space-around',
      margin: '0 20px 20px',
      paddingTop: 10,
    },
    pump: {
      width: 25,
      height: '60%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      backgroundColor: 'lightgray',
    },
    pumpActive: {
      backgroundColor: 'yellowgreen',
    },
    pumpName: {
      position: 'absolute',
      marginBottom: '-45px',
    },
    pumpSymbol: {
      height: 50,
      width: 50,
      borderRadius: '50%',
      position: 'absolute',
      marginBottom: '-20px',
      backgroundColor: 'lightgray',
      '&:after': {
        top: '60%',
        left: '50%',
        content: "''",
        position: 'absolute',
        transform: 'translateX(-50%) translateY(-80%)',
        width: 0,
        height: 0,
        borderLeft: '21px solid transparent',
        borderRight: '21px solid transparent',
        borderBottom: '35px solid black',
      },
    },
    pumpSymbolActive: {
      backgroundColor: 'yellowgreen',
    },
    pumpInfo: {
      margin: '10px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      '& div': {
        display: 'flex',
        alignItems: 'center',
        '& span': {
          marginTop: 7,
        },
      },
    },
    thermometerIcon: {
      width: 24,
    },
  });
};
const useStyles = makeStyles(styles);

function convertMinutes(num: number) {
  const d = Math.floor(num / 1440); // 60*24
  const h = Math.floor((num - d * 1440) / 60);
  const m = Math.round(num % 60);

  if (d > 0) {
    return `${d} days, ${h} hours, ${m} minutes`;
  }
  return `${h} hours, ${m} minutes`;
}

function getTankColor(level: number) {
  let tankColor: string;

  if (level <= 75) {
    tankColor = 'lightskyblue';
  } else if (level < 100) {
    tankColor = 'orange';
  } else {
    tankColor = 'orangered';
  }

  return tankColor;
}

interface Props {
  pumpPopup: boolean;
  setPumpPopup: Dispatch<SetStateAction<boolean>>;
  pumpInfo: PumpInfoType[] | null;
}

const PumpsDialog: React.FC<Props> = ({ pumpPopup, setPumpPopup, pumpInfo }) => {
  const classes = useStyles();
  
  return (
    <Dialog open={pumpPopup} onClose={() => setPumpPopup(false)} maxWidth="md">
      <DialogTitle>Pumps info</DialogTitle>
      <DialogContent className={classes.pumpsContent}>
        {pumpInfo
          ? pumpInfo.map((pump) => {
              const tankColor = getTankColor(pump.tankLevel);

              return (
                <fieldset key={pump.id} className={classes.pumpWrapper}>
                  <legend>{pump.name}</legend>
                  <div className={classes.pumpContent}>
                    <div className={classes.pumpParams}>
                      <Typography className={classes.paramTitle}>Pump 1</Typography>
                      <Typography className={classes.paramSubTitle}>
                        Status: <span>{pump.pump1 ? 'Active' : 'Not - Active'}</span>
                      </Typography>
                      <Typography className={classes.paramSubTitle}>
                        Runtime: <span>{convertMinutes(pump.pump1runtime)}</span>
                      </Typography>
                      <Typography className={classes.paramTitle}>Pump 2</Typography>
                      <Typography className={classes.paramSubTitle}>
                        Status: <span>{pump.pump2 ? 'Active' : 'Not - Active'}</span>
                      </Typography>
                      <Typography className={classes.paramSubTitle}>
                        Runtime: <span>{convertMinutes(pump.pump2runtime)}</span>
                      </Typography>
                      <Typography className={classes.paramTitle}>
                        Temperature: <span>{pump.temp}°C</span>
                      </Typography>
                      <Typography className={classes.paramTitle}>
                        Battery: <span>{pump.batt}%</span>
                      </Typography>
                      <Typography className={classes.paramTitle}>
                        Tank Level: <span>{pump.tankLevel}%</span>
                      </Typography>
                    </div>
                    <div className={classes.pumpGraphic}>
                      <div className={classes.box1}>
                        <div
                          className={cn(classes.box2)}
                          style={{
                            backgroundImage: `linear-gradient(transparent ${100 - pump.tankLevel}%, ${tankColor} 0px)`,
                          }}
                        >
                          <div className={cn(classes.box3)}>
                            <div className={cn(classes.pump, pump.pump1 ? classes.pumpActive : null)}>
                              <div className={cn(classes.pumpSymbol, pump.pump1 ? classes.pumpSymbolActive : null)} />
                              <span className={classes.pumpName}>Pump 1</span>
                            </div>
                            <div className={cn(classes.pump, pump.pump2 ? classes.pumpActive : null)}>
                              <div className={cn(classes.pumpSymbol, pump.pump2 ? classes.pumpSymbolActive : null)} />
                              <span className={classes.pumpName}>Pump 2</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.pumpInfo}>
                        <div>
                          <BatteryChargingFullIcon />
                          <span>{pump.batt}%</span>
                        </div>
                        <div>
                          <img className={classes.thermometerIcon} src={thermometerIcon} alt="thermometer" />
                          <span>{pump.temp}°C</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              );
            })
          : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPumpPopup(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PumpsDialog;
