/* eslint-disable no-param-reassign */
import useGetStaticFields from 'constants/staticFields';
import { taskForClient, taskReport_task_assets as taskReportTaskAsset } from 'models/graphql';
import { Workbook } from 'exceljs';
import useGetHandlersForFields from 'constants/fieldTypeHandlers';
import { FormContent } from 'shared/assetFormContent.interface';
import {
  generateDataForOneTaskForMultiReport,
  getIndexOfRowWithTokensForMultiReport,
  getTokensInfoForMultiReport,
} from './utilsForMapping';

const useMultiReportMapper = () => {
  const staticFields = useGetStaticFields();
  const fieldHandlers = useGetHandlersForFields();
  return async (
    workbook: Workbook,
    tasks: taskReportTaskAsset[],
    taskStaticInfo: taskForClient,
    reportLogsObj: Record<string, string | null> | null,
  ): Promise<Workbook> => {
    const indexRowWithToken = getIndexOfRowWithTokensForMultiReport(workbook);
    const tokensInfo = getTokensInfoForMultiReport(workbook, indexRowWithToken);
    let initialIndexLine = indexRowWithToken;
    tasks.forEach((currentTask) => {
      const currentRow =
        initialIndexLine === indexRowWithToken
          ? workbook.worksheets[0].findRow(indexRowWithToken!)!
          : workbook.worksheets[0].addRow({});
      const taskValue: Record<string, string | null> = currentTask.formValues
        ? JSON.parse(currentTask.formValues)
        : null;

      const formTemplateContent: FormContent = JSON.parse(currentTask.formTemplate.content);

      const valuesForTaskWithStaticInfo = {
        ...taskStaticInfo!.task,
        ...taskValue,
        assets: { ...currentTask.asset },
        logs: reportLogsObj,
        formTemplateContent,
      };

      const dataForTask = generateDataForOneTaskForMultiReport(
        staticFields,
        tokensInfo,
        valuesForTaskWithStaticInfo,
        fieldHandlers,
      );
      tokensInfo.forEach((tokenInfo) => {
        const currentCell = currentRow.getCell(tokenInfo.cellIndex);

        currentCell.value =
          dataForTask.find((dataItem) => dataItem.cellIndex === tokenInfo.cellIndex)?.cellValue || '-';

        if (tokenInfo.tokenName === 'Responsible for Works' && currentCell.value === '-') {
          currentCell.value = valuesForTaskWithStaticInfo.contractorResponsible
            ? valuesForTaskWithStaticInfo.contractorResponsible.companyName
            : currentCell.value;
        }
      });

      initialIndexLine += 1;
    });
    return workbook;
  };
};

export default useMultiReportMapper;
