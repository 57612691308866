import React from 'react';
import { useMutation } from '@apollo/client';
import { Box, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  licenseCreate,
  licenseCreateVariables,
  licenseArchive,
  licenseArchiveVariables,
  downloadRequestVariables,
  downloadRequest,
  clientSitesAssetTypes_client_sites as Site,
} from 'models/graphql';
import FileInput from 'components/FileInput';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import removeIcon from 'assets/removeIcon.svg';
import { DOWNLOAD_REQUEST } from 'components/FileInput/graphql';
import { CREATE_LICENSE, ARCHIVE_LICENSE } from '../graphql';

const styles = (theme: Theme) =>
  createStyles({
    filesArea: {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
    },
    fileListItem: {
      textDecoration: 'underline',
      color: '#69CC84',
    },
    fileList: {
      listStyle: 'none',
      paddingLeft: '0',
      margin: '0',
    },
    fileListGrid: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      '&:not(:first-child)': {
        borderTop: '1px solid #E5E5E5',
      },
    },
    removeButton: {
      cursor: 'pointer',
      maxHeight: 'unset',
    },
    noBorder: {
      border: 'none',
    },
  });
const useStyles = makeStyles(styles);

const Licenses: React.FC<{
  clientId: string;
  siteId: string;
  selectedSite: Site;
}> = ({ clientId, siteId, selectedSite }) => {
  const classes = useStyles();

  const [getSignedDownloadUrlMutation] = useMutation<downloadRequest, downloadRequestVariables>(DOWNLOAD_REQUEST, {
    update: (cache) => apolloCacheEvict({ cache, typename: 'Client', id: clientId }),
  });
  const [createLicense] = useMutation<licenseCreate, licenseCreateVariables>(CREATE_LICENSE, {
    update: (cache) => apolloCacheEvict({ cache, typename: 'Client', id: clientId }),
  });
  const [archiveLicense] = useMutation<licenseArchive, licenseArchiveVariables>(ARCHIVE_LICENSE, {
    update: (cache) => apolloCacheEvict({ cache, typename: 'Client', id: clientId }),
  });

  async function getSignedDownloadUrl(fileId: string) {
    const resp = await getSignedDownloadUrlMutation({ variables: { fileId } });
    return resp.data?.downloadRequest.url;
  }

  async function createLicenseEntity(fileId: string) {
    if (siteId) {
      await createLicense({
        variables: {
          siteId,
          fileId,
        },
      });
    }
  }

  async function archiveLicenseEntity(licenseId: string) {
    if (licenseId) {
      await archiveLicense({
        variables: {
          licenseId,
        },
      });
    }
  }

  const files =
    selectedSite?.licenses.map((license) => ({
      id: license.file.id,
      name: license.file.originalName,
      licenseId: license.id,
    })) || [];

  return (
    <Box>
      <div className={classes.filesArea}>
        <ul className={classes.fileList}>
          {files.map((file) => (
            <li key={file.id} className={classes.fileListGrid}>
              <button
                type="button"
                onClick={async () => {
                  const url = await getSignedDownloadUrl(file.id);
                  if (url) {
                    window.open(url, '_self');
                  }
                }}
                className={classes.fileListItem}
              >
                {file.name}
              </button>
              <button
                onClick={() => archiveLicenseEntity(file.licenseId)}
                type="button"
                tabIndex={0}
                className={classes.noBorder}
              >
                <img src={removeIcon} alt="Remove file" className={classes.removeButton} />
              </button>
            </li>
          ))}
          <li className={classes.fileListGrid}>
            <div>
              <FileInput onUpload={(id: string) => createLicenseEntity(id)} initialSrc="" regular />
            </div>
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default Licenses;
