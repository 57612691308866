import { format } from 'date-fns';
import { excelUtils } from 'utils/excel';
import useGetStaticFields from 'constants/staticFields';

const useGetExportFormVariables = () => {
  const staticFields = useGetStaticFields();
  return async (formData: Record<string, unknown>[]) => {
    const data = formData.map((v) => {
      const files =
        // eslint-disable-next-line no-nested-ternary
        v.parameterName === 'Files'
          ? { ...v, parameterName: 'Files 250 250 0' }
          : v.parameterName === 'Add Files'
          ? { ...v, parameterName: 'Add Files 250 250 0' }
          : 0;

      const signature =
        // eslint-disable-next-line no-nested-ternary
        v.parameterName === 'Signature'
          ? { ...v, parameterName: 'Signature 350 150 0' }
          : v.parameterName === 'Add Signature'
          ? { ...v, parameterName: 'Add Signature 350 150 0' }
          : 0;

      const changedRecord = files || signature || v;

      return `{{${changedRecord.parameterName}}}`;
    });

    const staticFieldsKeys = staticFields.map((field) => `{{${field.key}}}`);

    await excelUtils.downloadBook(
      excelUtils.createBook(
        'tokens',
        [
          {
            header: 'Variable tokens',
            values: [...staticFieldsKeys, ...data],
          },
        ],
        { freezeHeader: true },
      ),
      `form-variables-template-${format(new Date(), 'yyyy-MM-dd_HH_mm')}.xlsx`,
    );
  };
};

export default useGetExportFormVariables;
