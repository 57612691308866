import { useEffect } from 'react';
import { useStores } from 'stores';

const useRemovePaddingBottom = () => {
  const { contextStore } = useStores();
  useEffect(() => {
    contextStore.setPaddingBottomRemoved(true);
    return () => contextStore.setPaddingBottomRemoved(false);
  });
};

export default useRemovePaddingBottom;
