import React from 'react';
import { motion, MotionProps } from 'motion/react';
import { useTheme } from '@mui/material';

type State = MotionProps['initial'] & MotionProps['animate'] & MotionProps['exit'];

export interface AnimateStyleProps {
  children: React.ReactNode;
  className?: string;
  visible?: boolean;
  keepInDOM?: boolean;
  disableInitialAnimation?: boolean;
  slow?: boolean;
}

const AnimateStyle: React.FC<
  AnimateStyleProps & {
    states: {
      initial: State;
      visible: State;
    };
    style?: MotionProps['style'];
  }
> = ({ states, children, style, className, visible, keepInDOM, disableInitialAnimation, slow }) => {
  const theme = useTheme();

  return visible || keepInDOM ? (
    <motion.div
      initial={disableInitialAnimation ? states.visible : states.initial}
      animate={visible ? states.visible : states.initial}
      transition={slow ? theme.framerTransitions.slow : theme.framerTransitions.normal}
      style={style}
      className={className}
    >
      {children}
    </motion.div>
  ) : null;
};

export default AnimateStyle;
