import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgressCentered from 'components/CircualProgressCentered';
import {
  downloadRequest,
  downloadRequestVariables,
  taskAttachments,
  taskAttachmentsVariables,
  taskRemoveAttachment,
  taskRemoveAttachmentVariables,
  taskAttachFile,
  taskAttachFileVariables,
  TaskStatus,
} from 'models/graphql';
import useText from 'texts/useText.hook';
import FileInput from 'components/FileInput';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import { DOWNLOAD_REQUEST } from 'components/FileInput/graphql';
import WarningDialog from 'components/WarningDialog';
import useMode, { Mode } from 'utils/useMode.hook';
import RemoveDialog from 'components/RemoveDialog';
import { GET_TASK_ATTACHMENTS, TASK_ATTACH_FILE, TASK_REMOVE_ATTACHMENT } from '../graphql';
import removeIcon from 'assets/removeIcon.svg';
import { useNavigate, useParams } from 'react-router';

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
    width: 'fit-content',
  },
}));

const TaskAttachmentsPage: React.FC = () => {
  const { mode } = useMode();
  const navigate = useNavigate();
  const [warning, setWarning] = useState(false);

  const [deleteAttachmentDialog, setDeleteAttachmentDialog] = useState(false);
  const [deleteAttachmentDialogData, setDeleteAttachmentDialogData] = useState('');
  const closeDeleteAttachmentDialog = () => setDeleteAttachmentDialog(false);
  const openDeleteAttachmentDialog = () => {
    setDeleteAttachmentDialog(true);
  };

  const { link } = useStyles();
  const { t } = useText('tasks');
  const { taskId } = useParams<{ taskId: string }>();
  const { data, loading } = useQuery<taskAttachments, taskAttachmentsVariables>(GET_TASK_ATTACHMENTS, {
    variables: { id: taskId },
    onCompleted: (_data) => (_data.task.status === TaskStatus.SENT ? setWarning(true) : setWarning(false)),
  });
  const [getDownloadUrl] = useMutation<downloadRequest, downloadRequestVariables>(DOWNLOAD_REQUEST);
  const [attachFile] = useMutation<taskAttachFile, taskAttachFileVariables>(TASK_ATTACH_FILE, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Task',
        id: taskId,
        fieldName: 'attachments',
      }),
  });
  const [removeAttachment] = useMutation<taskRemoveAttachment, taskRemoveAttachmentVariables>(TASK_REMOVE_ATTACHMENT, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Task',
        id: taskId,
        fieldName: 'attachments',
      }),
  });
  const onFileUpload = (id: string) => attachFile({ variables: { taskId, fileId: id } });

  if (loading) return <CircularProgressCentered />;
  return <>
    {data && data.task.attachments.length > 0 ? (
      <>
        {data.task.attachments.map((attachment) => (
          <Box
            key={attachment.id}
            sx={{
              px: 2.5,
              py: 3.5,
              border: 1,
              borderColor: "grey.300",
              display: "flex",
              justifyContent: "space-between"
            }}>
            <Typography
              variant="body1"
              className={link}
              onClick={async () => {
                const { data: downloadData } = await getDownloadUrl({ variables: { fileId: attachment.id } });
                if (downloadData?.downloadRequest.url) {
                  window.open(downloadData?.downloadRequest.url, '_self');
                }
              }}
              sx={{
                color: "primary"
              }}
            >
              <u>{attachment.originalName}</u>
            </Typography>
            <Box>
              <button
                type="button"
                onClick={() => {
                  setDeleteAttachmentDialogData(attachment.id);
                  openDeleteAttachmentDialog();
                }}
              >
                <img src={removeIcon} alt="Delete Attachment" />
              </button>
            </Box>
          </Box>
        ))}
      </>
    ) : (
      <Box
        sx={{
          textAlign: "center",
          p: 3
        }}>
        <Typography variant="h3">{t('tasks')('noAttachments')}</Typography>
      </Box>
    )}
    {data &&
    [TaskStatus.CONTRACTOR_ACCEPTED, TaskStatus.IN_PROGRESS, TaskStatus.COMPLETED].includes(data.task.status) ? (
      <Box
        sx={{
          position: "absolute",
          left: "0",
          right: "0",
          textAlign: "center",
          top: "80%"
        }}>
        <FileInput buttonName={t('tasks')('addDocument')} contained regular onUpload={onFileUpload} />
      </Box>
    ) : null}
    <RemoveDialog
      open={deleteAttachmentDialog}
      deleteIcon
      title="Delete Attachment?"
      descriptionText="Attachment will be deleted."
      submitText="Delete"
      onSend={async () => {
        if (!deleteAttachmentDialogData) return;
        await removeAttachment({
          variables: {
            taskId,
            fileId: deleteAttachmentDialogData,
          },
        });
      }}
      onClose={closeDeleteAttachmentDialog}
    />
    {mode === Mode.OPERATIVE && (
      <WarningDialog
        open={warning}
        onClose={() => {
          setWarning(false);
          navigate(`/operative/works/${taskId}`);
        }}
        title="Cannot access page"
        descriptionText="You first need to accept the works to view this page."
      />
    )}
  </>;
};

export default TaskAttachmentsPage;
