import React, { useState, useEffect } from 'react';
import { withStores, AuthStore } from 'stores';
import { useStores } from 'stores';
import ActionsButton, { Actions } from 'components/ActionsButton';
import styleUtils from 'style/styleUtils';
import { useApolloClient } from '@apollo/client';
import AppAvatar from 'components/AppAvatar';
import useText from 'texts/useText.hook';
import Badge from 'components/Badge';
import useMode, { Mode, ModeResult } from 'utils/useMode.hook';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import Notifications from './Notifications';
import BreadcrumbBack from './Breadcrumb/BreadcrumbBack';
import AppSelectField from 'components/form/AppSelectFieldHeader';
import { GET_CLIENT } from 'containers/shared/ClientConfiguration/graphql';
import { getClient, getClientVariables } from 'models/graphql';
import { Formik } from 'formik';
import messagesIcon from 'assets/messagesIcon.svg'
import notificationsIcon from 'assets/notificationsIcon.svg'

import { AppBar, Theme, Toolbar, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Link, useLocation, useNavigate } from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      left: theme.sizes.drawerWidth,
      width: `calc(100% - ${theme.sizes.drawerWidth}px)`,
      borderBottom: `solid 1px ${theme.palette.grey['300']}`,
      [theme.breakpoints.down(BREAKPOINT)]: {
        display: 'none',
      },
      position: 'fixed',
    },
    toolbar: {
      height: theme.sizes.headerHeight,
      display: 'flex',

    },
    padding: {
      padding: theme.spacing(1),
    },
    actionImage: {
      padding: theme.spacing(1),
      display: 'block',
    },
    actionBar: {
      marginLeft: 'auto',
      display: 'flex',
    },
    user: {
      margin: 'auto',
      padding: theme.spacing(1),
    },
    action: {
      position: 'relative',
      minWidth: '40px',
      margin: 'auto',
    },
    mobileAppBar: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      height: theme.sizes.mobileHeaderHeight,
      position: 'relative',
      [theme.breakpoints.up(BREAKPOINT)]: {
        display: 'none',
      },
      background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
      boxShadow: theme.shadows[5],
    },
    leadText: {
      color: 'white',
      ...styleUtils.absoluteCenter,
      width: 'fit-content',
      maxWidth: '65%',
    },
    avatar: {
      display: 'flex',
      width: '100%',
      height: '100%',
      paddingRight: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexDirection: 'row',
    },

    select: {
      minWidth: 300,
      maxHeight: 200,
    },
    checkbox: {
      color: theme.palette.primary.main,
    },

    selectField: {
      width: theme.sizes.containerWidth,
      maxWidth: '70%',
      justifyContent: 'flex-end',
    },
  });
const useStyles = makeStyles(styles);

interface HeaderComponentProps {
  authStore: AuthStore;
}

const options = [
  { name: 'Compliance', disabled: false },
  { name: 'Facility Management', disabled: true },
]; 

const HeaderComponent: React.FC<HeaderComponentProps> = ({ authStore, contextStore }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const mode = useMode();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notificationsLength, setNotificationsLength] = useState<number | undefined>();
  const { t } = useText('roles', 'modules');

  const [clientFound, setClientFound] = useState([])
  const [clientIdFound, setClientidFound] = useState('');
  
  const curSelectedModules = contextStore.selectedModules;
  
  let preselectedmodules = []

  console.log("Header Line 140:", curSelectedModules)

  const curSelectedModulesStorage = window.localStorage.getItem('selectedModules');
  console.log("Header Line 144, curSelectedModulesStorage:", curSelectedModulesStorage)

  if (curSelectedModulesStorage)
  {
    preselectedmodules = JSON.parse(curSelectedModulesStorage);

  }

  const [selectedConfig, setSelectedConfig]  = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsAvail, setAvailOptions]  = useState(false);
  const [outputOptions, setOutputoptions] = useState();


  const [clientOptions, setClientOptions] = useState(null);



  const url = location.pathname; // Extract the path from the location object
  const userRole = authStore.user?.role;


  const getClientId =  async (url: string) => {
      // Use a regular expression to find the client ID
      const match = url.match(/\/client\/([^\/]+)/);
      if (match && match[1]) {
        let clientid = match[1];

        // now get the client's data
        let clientdata = await client.query<getClient, getClientVariables>({
          query: GET_CLIENT,
          variables: { id: clientid },
        }); 

        console.log("the client data for modules is :", clientdata.data.client.modules)
        let modulesSwitchedon = clientdata.data.client.modules;

        if (modulesSwitchedon)
        {
              let output = Object.keys(modulesSwitchedon).map(key => ({
              name: key
                .split('_') // Split the key by underscores
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                .join(' '), // Join words with a space
              disabled: modulesSwitchedon[key], // Assign the value of the key to the `disabled` property
            }));
        

            // Default to all modules if no preselected ones exist
            if (preselectedmodules.length === 0) {
              preselectedmodules = output.filter(module => !module.disabled).map(module => module.name);
              contextStore.setSelectedModules(preselectedmodules);
            }
        
            console.log("LINE 211 preselectedmodules:", preselectedmodules)
            console.log("LINE 212 output:", output)
            setClientOptions(output);
        
            return clientid;
   
         }
      
      }
      return null;
      
  };


  useEffect(() => {
    // check mode

    if (mode.mode === Mode.PM || userRole === 'PROJECT_MANAGER')
    {
          console.log("In here in PM")
          getClientId(url);
          setAvailOptions(true)
    }
    else
    {
        // process a normal login

        let modulesSwitchedon = authStore.userClient?.modules ;
        let output;

        console.log("modulesSwitchedon:", modulesSwitchedon)

        if (modulesSwitchedon)
        {
            output = Object.keys(modulesSwitchedon).map(key => ({
            name: key
              .split('_') // Split the key by underscores
              .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
              .join(' '), // Join words with a space
            disabled: modulesSwitchedon[key], // Assign the value of the key to the `disabled` property
          }));
      
          // Default to all modules if no preselected ones exist
          if (preselectedmodules.length === 0) {
            preselectedmodules = output.filter(module => !module.disabled).map(module => module.name);
            contextStore.setSelectedModules(preselectedmodules);
          }
          setClientOptions(output);
          setAvailOptions(true)
      
        }
        else
        {
          setAvailOptions(true)
        }

    }
  

  }, []);



/*

  if (modulesSwitchedon)
  {
      output = Object.keys(modulesSwitchedon).map(key => ({
      name: key
        .split('_') // Split the key by underscores
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' '), // Join words with a space
      disabled: modulesSwitchedon[key], // Assign the value of the key to the `disabled` property
    }));

    // Default to all modules if no preselected ones exist
    if (preselectedmodules.length === 0) {
      preselectedmodules = output.filter(module => !module.disabled).map(module => module.name);
      contextStore.setSelectedModules(preselectedmodules);
    }

  } */

  



  const getAvatarLink = (role: ModeResult): string => {
    switch (role.mode) {
      case Mode.TECHNICIAN:
        return '/technician/account';
      case Mode.OPERATIVE:
        return '/operative/account';
      case Mode.PM:
        return '/pm/account';
      case Mode.CLIENT:
        return `/client/${role.id}/account`;
      case Mode.CONTRACTOR:
        return `/contractor/${role.id}/account`;
      default:
        return '';
    }
  };





  const createActionMenu: () => Actions = () => {
    const actions: Actions = [
      {
        title: 'Logout',
        action: () => authStore.logout(client),
      },
    ];

    if (mode.mode === Mode.CLIENT && authStore.user?.isAdmin) {
      actions.unshift({
        title: 'Configure',
        action: () => {
          navigate(`/client/${mode.id}/configure`);
        },
      });
    } else if (mode.mode === Mode.CONTRACTOR) {
      actions.unshift({
        title: 'Configure',
        action: () => {
          navigate(`/contractor/${mode.id}/configure`);
        },
      });
    }

    return actions;
  };

  return (
    <>
     {optionsAvail && (
      <AppBar position="absolute" color="inherit" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Breadcrumb />
        
          <div className={classes.actionBar}>
           
          {clientOptions && (
              <>
                  <div className={classes.action}  style={{ paddingRight: '1em' }}>
                  <Formik
                        initialValues={{ site: '' }}
                        onSubmit={() => {
                          // do nothing
                        }}
                      >
                    <AppSelectField
                
                    name="module"
                    className={classes.selectField}
                    options={{
                      array: clientOptions,
                      value: (module) => module.name,
                      key: (module) => module.name,
                      template: (module)  => t('modules')(module.name),
                      disabled: (module) => module.disabled, // Handle the disabled property
                    }}
                    onChange={async (module) => {
                      console.log("Selected module:", module)
                      
                    }}
                    preselectedValues={ preselectedmodules}
                    />
                    </Formik>
                    </div>
              </>


            )}
        
            <div className={classes.action}>
              <img src={messagesIcon} alt="Messages" className={classes.actionImage} />
            </div>
            <button type="button" className={classes.action} onClick={() => setIsNotificationsOpen(true)}>
              <img src={notificationsIcon} alt="Notifications" className={classes.actionImage} />
              <Badge content={notificationsLength} />
            </button>
            <Notifications
              isOpen={isNotificationsOpen}
              onClose={() => setIsNotificationsOpen(false)}
              onCount={setNotificationsLength}
            />
            <div className={classes.action}>
              <div className={classes.padding}>
                <ActionsButton actions={createActionMenu()} horizontalPosition="left" />
              </div>
            </div>
            <div className={classes.action}>
              <div className={classes.padding}>
                <AppAvatar imageSrc={authStore.user?.picture?.srcUrl} name={authStore.user?.name || ''} />
              </div>
            </div>
            {authStore.user && !authStore.user.isconsultant ? (
              <div className={classes.user}>
                <Typography variant="body1">{t('roles')(authStore.user.role)}</Typography>
                <Typography variant="body1">
                  <b>{authStore.user.name}</b>
                </Typography>
              </div>
            ) : null}
             {authStore.user && authStore.user.isconsultant ?  (
              <div className={classes.user}>
                <Typography variant="body1">Consultant</Typography>
                <Typography variant="body1">
                  <b>{authStore.user.name}</b>
                </Typography>
              </div>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
       )}
      <AppBar color="inherit" className={classes.mobileAppBar}>
        <div>
          <BreadcrumbBack />
        </div>
        <div className={classes.leadText}>
          <Breadcrumb />
        </div>

        <div className={classes.avatar}>
          <Link to={getAvatarLink(mode)}>
            <AppAvatar imageSrc={authStore.user?.picture?.srcUrl} name={authStore.user?.name || ''} />
          </Link>
        </div>
      </AppBar>
    </>
  );
};

export default withStores('authStore', 'contextStore')(HeaderComponent);
