import gql from 'graphql-tag';

export const GET_CONTRACTORS_WITH_REQUESTS = gql`
  query getContractorsWithRequests($search: String, $archived: Boolean) {
    contractors(search: $search, archived: $archived) {
      id
      companyName
      contactName
      contactEmail
      address
      status
      expertise
      rating
      address
      requests {
        id
        client {
          id
          companyName
        }
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation contractorStatusUpdate($id: ID!, $status: ContractorStatus!) {
    contractorStatusUpdate(id: $id, status: $status) {
      id
      status
    }
  }
`;
