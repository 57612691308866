import { DocumentCategory } from 'models/graphql';

const enDocuments = {
  searchPlaceholder: 'Search by document name',
  documentsTitle: 'Documents',
  noDocuments: 'No documents',
  name: 'Document name',
  category: 'Category',
  uploadedBy: 'Uploaded by',
  uploadedAt: 'Uploaded date',
  status: 'Status',
  [DocumentCategory.CAD_DRAWING]: 'Cad drawing',
  [DocumentCategory.RAMS]: 'RAMS',
  [DocumentCategory.EMERGENCY_RESPONSE_PLAN]: 'Emergency response plan',
  [DocumentCategory.GENERAL]: 'General',
  [DocumentCategory.METHOD_STATEMENT]: 'Method statement',
  [DocumentCategory.REPORT]: 'Report',
  [DocumentCategory.RISK_ASSESMENT]: 'Risk assesment',
  [DocumentCategory.TOOLBOX_TALK]: 'Toolbox talk',
  [DocumentCategory.DRAWING]: 'Drawing',
  [DocumentCategory.PERMIT]: 'Permit',
  [DocumentCategory.AER]: 'AER',
  [DocumentCategory.FER]: 'FER',
  [DocumentCategory.FIREWATER_RETENTION]: 'Firewater Retention',
  [DocumentCategory.GROUND_WATER_REPORT]: 'Ground Water Report',
  [DocumentCategory.NUTRIENT_MANAGEMENT_PLAN]: 'Nutrient Management Plan',
  [DocumentCategory.ODOUR_MANAGEMENT_PLAN]: 'Odour Management Plan',
  [DocumentCategory.REPORT_NON_COMPLIANCE]: 'Report Non-Compliance',
  [DocumentCategory.WID_PERFORMANCE_REPORT]: 'WID Performance Report',
  [DocumentCategory.MEETING_MINUTES]: 'Meeting Minutes',
  [DocumentCategory.WINCAN_FOOTAGE]: 'WinCan Footage',
  systems: 'Systems',
  assets: 'Assets',
  createDocument: 'New Document',
  uniqueNameError: 'Name should be unique, please use the correct naming method.',
  formName: 'Name'
};
export default enDocuments;
