/* eslint-disable no-return-await */
interface AsyncIteratorFunction<T, U> {
  (value: T, index: number, array: T[]): Promise<U>;
}

interface AsyncReducerFunction<T, U> {
  (prevValue: U, currentValue: T, index: number, array: T[]): Promise<U>;
}

export const asyncMap = async <T, U>(array: T[], mapper: AsyncIteratorFunction<T, U>): Promise<U[]> =>
  await Promise.all(array.map(mapper));

export const asyncReduce = async <T, U = T>(
  array: T[],
  reducer: AsyncReducerFunction<T, U>,
  initialValue: U,
): Promise<U> =>
  await array.reduce(
    async (prevValue, currentValue, index, arr) => await reducer(await prevValue, currentValue, index, arr),
    Promise.resolve(initialValue),
  );

export const asyncFind = async <T>(array: T[], finder: AsyncIteratorFunction<T, boolean>): Promise<T | undefined> =>
  await asyncReduce<T, T | undefined>(
    array,
    async (foundValue, currentValue, index, arr) =>
      foundValue || ((await finder(currentValue, index, arr)) ? currentValue : undefined),
    undefined,
  );

export const asyncIndexOf = async <T>(array: T[], finder: AsyncIteratorFunction<T, boolean>): Promise<number> =>
  await asyncReduce(
    array,
    async (foundIndex, currentValue, index, arr) => {
      if (foundIndex > -1) return foundIndex;
      return (await finder(currentValue, index, arr)) ? index : -1;
    },
    -1,
  );

export const asyncSome = async <T>(array: T[], finder: AsyncIteratorFunction<T, boolean>): Promise<boolean> =>
  await asyncReduce<T, boolean>(
    array,
    async (isAnyTrue, currentValue, index, arr) => isAnyTrue || (await finder(currentValue, index, arr)),
    false,
  );

export const asyncForEach = async <T, U = void>(array: T[], func: AsyncIteratorFunction<T, U>): Promise<void> => {
  const func$Array: Promise<U>[] = [];
  array.forEach((element, index) => func$Array.push(func(element, index, array)));
  await Promise.all(func$Array);
};
