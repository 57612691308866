import { useMutation } from '@apollo/client';
import { DOWNLOAD_REQUEST_WITH_SRC } from 'components/FileInput/graphql';
import { downloadRequestWithSrc, downloadRequestWithSrcVariables } from 'models/graphql';
import { useCallback } from 'react';

export default function useFileSrc() {
  const [getSignedDownloadUrl] = useMutation<downloadRequestWithSrc, downloadRequestWithSrcVariables>(
    DOWNLOAD_REQUEST_WITH_SRC,
  );

  return useCallback(
    async (fileId: string) => {
      const res = await getSignedDownloadUrl({ variables: { fileId } });
      return res.data?.downloadRequest;
    },
    [getSignedDownloadUrl],
  );
}
