import { useQuery } from '@apollo/client';
import { LinearProgress, Theme, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Breadcrumb from 'components/Breadcrumb';
import Item from 'components/Item';
import { getAssetForTask, getAssetForTaskVariables } from 'models/graphql';
import React from 'react';
import useText from 'texts/useText.hook';
import coordinatesLink from 'utils/coordinatesLink';
import useFileDownload from 'utils/useFileDownload';
import { GET_ASSET } from './graphql';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    sectionHeader: {
      marginBottom: theme.spacing(1),
      fontSize: theme.fontSizes[12],
      fontWeight: theme.typography.fontWeightBold,
    },
    sectionItem: {
      display: 'flex',
      marginBottom: theme.spacing(0.5),
      padding: `${theme.spacing(1)} 0`,
      background: theme.palette.grey[100],
      fontSize: theme.fontSizes[12],

      '& button': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
    },
    sectionItemPart: {
      flexGrow: 1,
      flexBasis: 0,
      padding: `0 ${theme.spacing(1)}`,
    },
  });
});

const AssetPage: React.FC = () => {
  const { assetId, contractorId } = useParams<{
    assetId: string;
    contractorId: string;
  }>();

  const { data: assetData } = useQuery<getAssetForTask, getAssetForTaskVariables>(GET_ASSET, {
    variables: { assetId },
  });
  const asset = assetData?.asset;

  const download = useFileDownload();

  const styles = useStyles();
  const theme = useTheme();
  const { t } = useText('common', 'clients', 'assetTypes', 'systemTypes');
  return asset ? (
    <div>
      <Breadcrumb to={`/contractor/${contractorId}/work-instructions/assets/${assetId}`} name={asset.name} />

      <Item label={t('clients')('assetId')} value={asset.id} />
      <Item label={t('clients')('assetName')} value={asset.name} />
      <Item label={t('clients')('assetType')} value={t('assetTypes')(asset.type)} />
      <Item label={t('clients')('systemName')} value={asset.system.name} />
      <Item label={t('clients')('systemType')} value={t('systemTypes')(asset.system.type)} />
      <Item label={t('common')('created')} value={theme.dateFormat.normal(asset.createdAt)} />
      <Item label={t('clients')('assetOwner')} value={asset.owner.name} />
      {asset.department ? <Item label={t('clients')('department')} value={asset.department} /> : null}
      <Item label={t('clients')('site')} value={asset.site.name} />
      {asset.coordinates ? (
        <Item
          label={t('clients')('assetCoordinates')}
          value={`${asset.coordinates.latitude}, ${asset.coordinates.longitude}`}
          link={coordinatesLink(asset.coordinates)}
        />
      ) : null}
      {asset.additionalInformation ? (
        <Item label={t('clients')('additionalInformation')} value={asset.additionalInformation} />
      ) : null}
      <div>
        <div className={styles.sectionHeader}>Drawings</div>
        {asset.drawings.map((drawing) => (
          <div key={drawing.id} className={styles.sectionItem}>
            <div className={styles.sectionItemPart}>
              <button type="button" onClick={() => download(drawing.file.id)}>
                {drawing.file.originalName}.{drawing.file.extension}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <LinearProgress />
  );
};

export default AssetPage;
