import React from 'react';
import { useQuery } from '@apollo/client';

import { getClient, getClientVariables } from 'models/graphql';
import Breadcrumb from 'components/Breadcrumb';
import UserUpdate from 'containers/shared/UserUpdate';
import ContractorDetailsRoutes from 'containers/Client/Contractors/ContractorDetails/ContractorDetailsRoutes';
import useMode, { Mode } from 'utils/useMode.hook';
import ClientAssetsPage from './ClientAssets/ClientAssetsPage';
import ClientAssetCreatePage from './ClientAssets/ClientAssetCreatePage';
import ClientSubscriptionPage from './ClientSubscriptionPage';
import ClientDrawingsCreatePage from './ClientDrawingsCreatePage';
import ClientDrawingsPage from './ClientDrawingsPage';
import ClientLicensePage from './ClientLicense/ClientLicensePage';
import ClientSubscriptionCreatePage from './ClientSubscriptionCreatePage';
import ClientBasicDetailsPage from './ClientBasicDetailsPage';
import ClientUsers from './ClientUsers';
import ClientDetailsContainer from './ClientDetailsContainer';
import ClientContractorDetailsRoutes from './ClientContractorDetails/ClientContractorDetailsRoutes';
import ClientContractorsPage from './ClientContractorsPage';
import ClientContractorRequestPage from './ClientContractorRequestPage';
import { GET_CLIENT } from './graphql';
import DrawingPage from '../DrawingPage';
import ClientSystemsPage from './ClientAssets/ClientSystemsPage';
import ClientSystemCreatePage from './ClientAssets/ClientSystemCreatePage';
import { Route, Routes, useLocation, useParams } from 'react-router';

const ClientDetailsRoutes: React.FC = () => {
  const { pathname } = useLocation();
  const { clientId } = useParams<{ clientId: string}>();

  let updatedUrl = '';
  if (pathname.includes('configure')) {
    updatedUrl = pathname.replace(/(\/configure).*$/, '$1');
  } else if (pathname.includes('pm')) {
    updatedUrl = pathname.replace(new RegExp(`(${clientId}).*`), '$1');
  }  
  
  const { mode } = useMode();
  const { data } = useQuery<getClient, getClientVariables>(GET_CLIENT, {
    variables: {
      id: clientId,
    },
  });  

  return (
    <ClientDetailsContainer baseUrl={updatedUrl}>
      <Breadcrumb to={updatedUrl} name={data?.client.companyName || ''} />
      <Routes>
        <Route path={`subscription/add`} element={<ClientSubscriptionCreatePage />} />
        <Route path={`subscription/:subscriptionId/edit`} element={<ClientSubscriptionCreatePage />} />
        <Route path={`assets/systems/add`} element={<ClientSystemCreatePage />} />
        <Route path={`assets/systems/:systemId/edit`} element={<ClientSystemCreatePage />} />
        <Route path={`assets/systems`} element={<ClientSystemsPage />} />
        <Route path={`assets/add`} element={<ClientAssetCreatePage />} />
        <Route path={`assets/update`} element={<ClientAssetCreatePage />} />
        <Route path={`assets`} element={<ClientAssetsPage />} />
        <Route path={`license`} element={<ClientLicensePage />} />
        <Route path={`subscription`} element={<ClientSubscriptionPage />} />
        <Route path={`contractors/add`} element={<ClientContractorRequestPage />} />
        <Route path={`contractors/:contractorId`}>
          <Route
            index
            element={mode === Mode.CLIENT ? <ContractorDetailsRoutes /> : <ClientContractorDetailsRoutes />}
          />
          <Route
            path={`*`}
            element={mode === Mode.CLIENT ? <ContractorDetailsRoutes /> : <ClientContractorDetailsRoutes />}
          />
        </Route>
        <Route path={`contractors`} element={<ClientContractorsPage />} />
        <Route path={`drawings/add`} element={<ClientDrawingsCreatePage />} />
        <Route path={`drawings/:drawingId/edit`} element={<ClientDrawingsCreatePage />} />
        <Route path={`drawings/:drawingId`} element={<DrawingPage />} />
        <Route path={`drawings`} element={<ClientDrawingsPage />} />
        <Route path={`users`} element={<ClientUsers />} />
        <Route path={`users/add`} element={<UserUpdate />} />
        <Route path={`users/:userId`} element={<UserUpdate />} />
        <Route path={`/`} element={<ClientBasicDetailsPage />} />
      </Routes>
    </ClientDetailsContainer>
  );
};

export default ClientDetailsRoutes;
