import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',

    '&>*': {
      flexGrow: 1,
      '&:not(:first-child)': {
        paddingLeft: theme.spacing(3),
      },
    },
  },
}));

const AppFormFieldsRow: React.FC = ({ children }) => {
  const styles = useStyles();

  return <div className={styles.container}>{children}</div>;
};

export default AppFormFieldsRow;
