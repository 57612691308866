import gql from 'graphql-tag';

export const CREATE_CLIENT = gql`
  mutation clientCreate($client: ClientCreateInput!) {
    clientCreate(client: $client) {
      id
    }
  }
`;

export const GET_CLIENTS = gql`
  query getClients($search: String, $archived: Boolean) {
    clients(search: $search, archived: $archived) {
      id
      companyName
      contactName
      contactEmail
      status
    }
  }
`;

export const GET_CLIENTS_STATISTIC = gql`
  query getClientsStatistic($search: String) {
    clients(search: $search) {
      id
      companyName
      sitesNumber
      usersNumber
      assetsNumber
      logo {
        id
        srcUrl
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation clientStatusUpdate($id: ID!, $status: ClientStatus!) {
    clientStatusUpdate(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const GET_CLIENT = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      companyName
      contactName
      contactEmail
      contactPhone
      twoFactorAuth
      status
      logo {
        id
        srcUrl
      }
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation clientUpdate($id: ID!, $changes: ClientUpdateInput!) {
    clientUpdate(id: $id, changes: $changes) {
      id
      companyName
      contactName
      contactEmail
      contactPhone
      twoFactorAuth
      logo {
        id
        srcUrl
      }
    }
  }
`;
