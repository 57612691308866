import { Box, CircularProgress } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import React from 'react';

const CircularProgressCentered: React.FC<CircularProgressProps> = ({ ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center"
      }}>
      <CircularProgress {...props} />
    </Box>
  );
};

export default CircularProgressCentered;
