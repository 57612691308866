import { useMutation, useQuery } from '@apollo/client';
import TaskPanel from 'containers/shared/Tasks/TaskPanel';
import {
  taskResponsibleAccept,
  taskResponsibleAcceptVariables,
  taskForContractor,
  taskForContractorVariables,
  taskResponsibleReject,
  taskResponsibleRejectVariables,
  TaskStatus,
} from 'models/graphql';
import React, { useEffect, useState } from 'react';
import AcceptOrDeny from 'components/AcceptOrDeny';
import useText from 'texts/useText.hook';
import { Formik } from 'formik';
import { AppForm } from 'components/form';
import AppTextAreaField from 'components/form/AppTextAreaField';
import AppButton from 'components/AppButton';
import { AnimateHeight } from 'components/animations';
import Yup from 'utils/yup';
import useFileDownload from 'utils/useFileDownload';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import useTaskDetailsAcceptOrDenyStyles from 'containers/shared/Tasks/TaskDetailsStyles';
import { ACCEPT_TASK, REJECT_TASK } from 'containers/shared/graphql';
import { GET_TASK } from './graphql';
import { useParams } from 'react-router';

interface FormValues {
  comment?: string;
}

const TaskDetails: React.FC<{ taskId: string }> = ({ taskId }) => {
  const { contractorId } = useParams<{
    contractorId: string;
  }>();

  // Queries
  const { data: taskData, loading: taskDataLoading } = useQuery<taskForContractor, taskForContractorVariables>(
    GET_TASK,
    {
      variables: { taskId },
    },
  );
  const task = taskData?.task;

  // Mutations
  const [accept] = useMutation<taskResponsibleAccept, taskResponsibleAcceptVariables>(ACCEPT_TASK, {
    update: (cache, { data }) => {
      if (!data?.taskResponsibleAccept.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.taskResponsibleAccept.id },
      });
    },
  });
  const [reject] = useMutation<taskResponsibleReject, taskResponsibleRejectVariables>(REJECT_TASK, {
    update: (cache, { data }) => {
      if (!data?.taskResponsibleReject.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.taskResponsibleReject.id },
      });
    },
  });

  const download = useFileDownload();

  const [selected, setSelected] = useState<'accept' | 'deny' | undefined>();
  useEffect(() => setSelected(undefined), [setSelected, taskId]);

  const styles = useTaskDetailsAcceptOrDenyStyles();
  const { t } = useText('common', 'tasks');

  const validationSchema:  Yup.ObjectSchema<FormValues> = Yup.object().shape({
    comment: selected === 'deny' ? Yup.string().required(
      t('common')('required', {
        field: t('tasks')('comments')
      })
    ) : Yup.string(),
  })
  return (
    <TaskPanel
      task={task}
      loading={taskDataLoading}
      onDownload={download}
      assetLink={(assetId) => `/contractor/${contractorId}/work-instructions/assets/${assetId}`}
      acceptOrDeny={
        task?.status === TaskStatus.SENT ? (
          <div className={styles.acceptOrDenyContainer}>
            <div className={styles.acceptOrDeny}>
              <AcceptOrDeny
                acceptText={t('common')('approve')}
                selected={selected}
                onAccept={() => setSelected('accept')}
                onDeny={() => setSelected('deny')}
              />
              <AnimateHeight visible={!!selected} slow>
                <div className={styles.acceptOrDenyDetails}>
                  <Formik<FormValues>
                    initialValues={{
                      comment: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                      if (!task) return;

                      if (selected === 'deny') {
                        await reject({ variables: { taskId: task.id, comment: values.comment || '' } });
                      } else {
                        await accept({ variables: { taskId: task.id, comment: values.comment } });
                      }
                    }}
                  >
                    {({ handleSubmit, isSubmitting }) => (
                      <AppForm handleSubmit={handleSubmit}>
                        <AppTextAreaField name="comment" placeholder={t('tasks')('comments')} />

                        <div className={styles.acceptOrDenySubmit}>
                          <AppButton variant="outlined" type="submit" inProgress={isSubmitting}>
                            {t('common')('submit')}
                          </AppButton>
                        </div>
                      </AppForm>
                    )}
                  </Formik>
                </div>
              </AnimateHeight>
            </div>
          </div>
        ) : undefined
      }
    />
  );
};

export default TaskDetails;
