import React, { useEffect, useState } from 'react';
import AppFieldError from 'components/form/AppFieldError';
import calendarIcon from 'assets/calendarIcon.svg';
import { useFormikContext } from 'formik';
import cn from 'utils/cn';
import objectPropertyByString from 'utils/objectPropertyByString';
import { Calendar, CalendarProps } from 'primereact/calendar';
import { Theme, alpha } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const styleRules = (theme: Theme) => {
  const { height } = theme.sizes.button;
  const { borderRadius } = theme.shape;
  const grey = theme.palette.grey['300'];

  return createStyles({
    datePicker: {
      width: '100%',
      minWidth: theme.sizes.button.minWidth,
      borderRadius,
      '& .MuiInputBase-root': {
        height,
        '& input': {
          height,
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: theme.fontSizes['14'],

          '&.Mui-disabled': {
            background: alpha(grey, 0.1),
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0,
          '& legend': {
            display: 'none',
          },
        },
        '&:not(.Mui-focused):not(.Mui-error)': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: grey,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey['600'],
          },
        },
      },
      '&.no-value .MuiInputBase-root input': {
        color: theme.palette.grey[400],
      },
      '& .MuiFormLabel-root': {
        position: 'static',
        paddingBottom: theme.spacing(1),
        fontSize: theme.fontSizes['12'],
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.5,
        transform: 'none',
        color: theme.palette.text.primary,
        '&.Mui-disabled': {
          color: alpha(theme.palette.text.primary, 0.4),
        },
      },
    },
    datePickerContainer: {
      position: 'relative',
    },
    datePickerIcon: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: 12,
      zIndex: -1,
    },
    cancelIcon: {
      position: 'absolute',
      right: theme.spacing(1) + 18,
      marginRight: theme.spacing(1),
      bottom: 14,
      zIndex: 2,
      cursor: 'pointer',
    },
  });
};
const useStyles = makeStyles(styleRules);

type AppDateFieldProps = Omit<Omit<Omit<CalendarProps, 'onChange'>, 'name'>, 'value'> & {
  name: string;
  placeholder?: string;
  onChange?: (value: Date | null) => void;
  showCancelIcon?: boolean;
  label?: string;
};

const AppDateField: React.FC<AppDateFieldProps> = (props: AppDateFieldProps) => {
  const {
    name,
    placeholder,
    onChange,
    showCancelIcon,
    format,
    disabled,
    ...propsToPass
  } = props;

  const { values, setFieldValue, touched: touchedFields, errors } = useFormikContext<Record<string, unknown>>();
  const [value, setValue] = useState(objectPropertyByString(values, name) as Date | null);

  const touched = objectPropertyByString(touchedFields, name);
  const error = objectPropertyByString(errors, name);

  const styles = useStyles();

  useEffect(() => {
    setValue(objectPropertyByString(values, name) as Date | null);
  }, [name, values]);

  return (
    <div>
      <div className={styles.datePickerContainer}>
        {
          props?.label && <label data-shrink="true" style={{
            fontSize: '12px',
            fontWeight: 700,
            paddingBottom: '8px',
            display: 'block'
          }}>{props?.label}
            {props.required && <span> *</span>}
          </label>
        }
        <Calendar
          dateFormat={format || 'dd/mm/yy'}
          value={value} 
          onChange={(e) => {
            const newValue = e.value;
            setValue(newValue);
            if (onChange) {
              onChange(newValue);
            } else {
              setFieldValue(name, newValue);
            }
          }} 
          showIcon={props?.showIcon === false ? false : true}
          icon={<img alt="calendar" src={calendarIcon} width="18px"/>}
          showButtonBar={!!(showCancelIcon && value && !disabled)}
          onClearButtonClick={() => {
            setValue(null);
            if (onChange) {
              onChange(null);
            } else {
              setFieldValue(name, null);
            }
          }}
          disabled={disabled}
          placeholder={placeholder}
          className={cn(styles.datePicker, 'datepicker-element', {
            'no-value': !value,
          })}
          inputClassName={(touched && !!error) ? 'p-error' : ''}
          {...propsToPass}
          readOnlyInput
        />
      </div>
      <AppFieldError message={touched ? error : undefined} />
    </div>
  );
};

export default AppDateField;
