import React, { useState } from 'react';
import AppTextField from 'components/form/AppTextField';
import Yup from 'utils/yup';
import { Formik } from 'formik';
import AppButton from 'components/AppButton';
import FormComponent from './components/Form';
import { resetPassword } from './api';
import { createStyles, makeStyles } from '@mui/styles';
import { FormControl, Theme, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    login: {
      width: '100%',
    },
    back: {
      marginTop: theme.spacing(3),
      lineHeight: `${theme.sizes.button.height}px`,
      paddingRight: `${theme.spacing(4)}`,
    },
    loginHeader: {
      marginBottom: theme.spacing(5),
    },
  });
const useStyles = makeStyles(styles);

interface FormValues {
  email: string;
}

const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
  email: Yup.string().required('Please fill out this field.'),
});

const ResetPasswordPage: React.FC = () => {
  const classes = useStyles();
  const [linkSent, setLinkSent] = useState(false);
  const navigate = useNavigate();

  const back = (
    <AppButton type="button" variant="outlined" color="primary" className={classes.back} onClick={() => navigate(-1)}>
      Back
    </AppButton>
  );

  return (
    <div className={classes.login}>
      <Typography className={classes.loginHeader} component="h1" variant="h5">
        Forgot Password?
      </Typography>
      <Typography variant="body1">
        Enter your email and we&apos;ll send you instructions on how to reset your password.
      </Typography>
      <Formik<FormValues>
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (!values.email) {
            return;
          }
          await resetPassword(values.email);
          setLinkSent(true);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <FormComponent
            onSubmit={(e) => handleSubmit(e)}
            submitButtonText="Send"
            inProgress={isSubmitting}
            justify="space-between"
            actionButton={back}
          >
            <FormControl margin="normal" required fullWidth>
              <AppTextField
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                autoComplete="email"
                autoFocus
                required
              />
            </FormControl>
          </FormComponent>
        )}
      </Formik>
      {linkSent && <Typography variant="body1">Please check you inbox for a reset link.</Typography>}
    </div>
  );
};

export default ResetPasswordPage;
