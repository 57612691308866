import React from 'react';

import useMode, { Mode } from 'utils/useMode.hook';
import AccountPage from 'containers/shared/AccountPage';
import DashboardPage from './Dashboard/DashboardPage';
import ClientsRoutes from './Clients/ClientsRoutes';
import ContractorRoutes from './Contractors/ContractorRoutes';
import { Navigate, Route, Routes } from 'react-router';

const PmRoutes: React.FC = () => {
  const { mode } = useMode();
  if (mode !== Mode.PM) {
    return <Navigate to="/" />;
  }
  return (
    <Routes>
      <Route path={`dashboard`} element={<DashboardPage />} />
      <Route path={`clients`}>
        <Route index element={<ClientsRoutes />} />
        <Route path={`*`} element={<ClientsRoutes />} />
      </Route>
      <Route path={`contractors`}>
        <Route index element={<ContractorRoutes />} />
        <Route path={`*`} element={<ContractorRoutes />} />
      </Route>
      <Route path={`account`} element={<AccountPage />} />
      <Route path="*" element={<Navigate to={`../dashboard`} />} />
    </Routes>
  );
};

export default PmRoutes;
