import React from 'react';
import { Box, CircularProgress, Theme, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import {
  getContractorForClient,
  getContractorForClientVariables,
  ContractorRequestAccessStatus,
  getContractorForClient_contractor_request as Request,
} from 'models/graphql';
import { GET_CONTRACTOR_FOR_CLIENT } from 'containers/shared/graphql';
import AppIconButton from 'components/AppIconButton';
import editIcon from 'assets/editIcon.svg';
import useText from 'texts/useText.hook';
import { readDayDate } from 'shared/formatDayDate';
import { Link, useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  details: {
    width: '100%',
    maxWidth: 650,
    display: 'flex',
    flexWrap: 'wrap',
  },
  detail: {
    width: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
  },
  subdetail: {
    width: '50%',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
  },
  detailTitle: {
    fontSize: theme.fontSizes['12'],
  },
  detailValue: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey['500'],
  },
}));

const AccessRightsDetails: React.FC<{
  request: Request | null | undefined;
  clientId: string;
  contractorId: string;
}> = ({ request, clientId, contractorId }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { t } = useText('systemTypes');

  if (!request || request.accessStatus === ContractorRequestAccessStatus.DENIED) return null;
  if (request.accessStatus === ContractorRequestAccessStatus.REQUESTED) {
    return (
      <Link to={`/client/${clientId}/contractors/${contractorId}`}>
        You must first accept the contractor request on the contractor&apos;s page.
      </Link>
    );
  }

  return (
    <>
      <div className={styles.details}>
        <div className={styles.detail}>
          <div className={styles.detailTitle}>Site(s)</div>
          <div className={styles.detailValue}>{request.sites.map((site) => site.name).join(', ')}</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.detailTitle}>System(s)</div>
          <div className={styles.detailValue}>
            {request.systemTypes?.map((systemType) => t('systemTypes')(systemType)).join(', ')}
          </div>
        </div>
        <div className={styles.subdetail}>
          <div className={styles.detailTitle}>Contract Number</div>
          <div className={styles.detailValue}>{request.contractNumber}</div>
        </div>
        <div className={styles.subdetail}>
          <div className={styles.detailTitle}>Expiracy Date</div>
          <div className={styles.detailValue}>
            {request.contractExpiracy ? theme.dateFormat.normal(readDayDate(request.contractExpiracy)) : null}
          </div>
        </div>
        {request?.workOrders?.map(({ id, expiracy }) => (
          <React.Fragment key={uuidv4()}>
            <div className={styles.subdetail}>
              <div className={styles.detailTitle}>Work Order Number</div>
              <div className={styles.detailValue}>{id}</div>
            </div>
            <div className={styles.subdetail}>
              <div className={styles.detailTitle}>Expiracy Date</div>
              <div className={styles.detailValue}>{theme.dateFormat.normal(expiracy)}</div>
            </div>
          </React.Fragment>
        ))}
        <div className={styles.detail}>
          <div className={styles.detailTitle}>Supplier Manager</div>
          <div className={styles.detailValue}>{request.manager?.name}</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.detailTitle}>Authentication Level</div>
          <div className={styles.detailValue}>
            {request.twoFactorAuth ? '2-Factor Authentication' : '1-Factor Authentication'}
          </div>
        </div>
      </div>
    </>
  );
};

const ContractorAccessRightsPage: React.FC = () => {
  const { contractorId, clientId } = useParams<{
    contractorId: string;
    clientId: string;
  }>();
  const { data, loading } = useQuery<getContractorForClient, getContractorForClientVariables>(
    GET_CONTRACTOR_FOR_CLIENT,
    { variables: { contractorId, clientId } },
  );

  return (<>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}>
      {data?.contractor.request?.accessStatus === ContractorRequestAccessStatus.ACCEPTED && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}>
          <div>
            <Link to={`/client/${clientId}/configure/contractors/${contractorId}/edit/${data.contractor.request.id}`}>
              <AppIconButton>
                <img alt="update" src={editIcon} />
              </AppIconButton>
            </Link>
            {loading && (
              <div>
                <CircularProgress color="primary" />
              </div>
            )}
          </div>
        </Box>
      )}
      {loading ? (
        <CircularProgress color="primary" />
      ) : (
        <AccessRightsDetails request={data?.contractor.request} clientId={clientId} contractorId={contractorId} />
      )}
    </Box>
  </>);
};

export default ContractorAccessRightsPage;
