import React from 'react';
import { Paper, Theme, Typography } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import HideNavigation from 'components/HideNavigation';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import LoginPage from './LoginPage';
import ChangePasswordPage from './ChangePasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import SmsLoginPage from './SmsLoginPage';
import authBackground from 'assets/authBackground.jpg';
import edacLogo from 'assets/edacLogo.svg';
import RequireAuth from 'components/RequireAuth/RequireAuth';
import { Navigate, Route, Routes } from 'react-router';


const SUPPORT_EMAIL = 'info@edac.ie';
const LINE_WIDTH = 14;

const styles = (theme: Theme) =>
  createStyles({
    main: {
      height: '100vh',
      width: '100%',
      display: 'grid',
      [theme.breakpoints.down(BREAKPOINT)]: {
        gridTemplateColumns: '1fr',
      },
      gridTemplateColumns: '1fr 1fr',
    },
    mobileOnlyLogo: {
      [theme.breakpoints.up(BREAKPOINT)]: {
        display: 'none',
      },
      position: 'absolute',
      padding: theme.spacing(4),
      top: '0',
      left: '0',
    },
    desktopOnly: {
      [theme.breakpoints.down(BREAKPOINT)]: {
        display: 'none',
      },
      backgroundImage: `url(${authBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    shadow: {
      width: '100%',
      height: '100%',
      background: 'linear-gradient(180deg, rgba(20, 156, 80, 0.4) 0%, rgba(147, 202, 82, 0.4) 100%)',
      boxShadow: `inset calc(50vw - ${LINE_WIDTH}px) 0 0 0 rgba(42, 49, 72, 0.7)`,
      display: 'grid',
      gridTemplateRows: '1fr 1fr',
      textAlign: 'center',
    },
    content: {
      width: '100%',
      maxWidth: '600px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4),
      position: 'relative',
    },
    support: {
      width: '100%',
      textAlign: 'center',
      color: theme.brandColors.main,
      textDecoration: 'underline',
      position: 'absolute',
      padding: theme.spacing(4),
      bottom: '0',
    },
    leadText: {
      color: theme.brandColors.light,
      fontSize: '24px',
      fontWeight: 'normal',
      marginRight: LINE_WIDTH,
    },
    logoContainer: {
      textAlign: 'left',
      padding: `${theme.spacing(5)}`,
    },
  });

interface Props extends WithStyles<typeof styles> {}

const AuthContainer: React.FC<Props> = ({ classes }) => (
  <DefaultErrorBoundary>
    <Paper className={classes.main}>
      <HideNavigation />
      <div className={classes.desktopOnly}>
        <div className={classes.shadow}>
          <div className={classes.logoContainer}>
            <img alt="edac logo" src={edacLogo} />
          </div>
          <Typography variant="h2" className={classes.leadText}>
            Environment | Data | Asset | Compliance
          </Typography>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.mobileOnlyLogo}>
          <img alt="edac logo" src={edacLogo} />
        </div>

        <Routes>
          <Route path={`/login`} element={<LoginPage />} />
          <Route path={`/sms`}
            element={
              <RequireAuth>
                <SmsLoginPage/>
              </RequireAuth>
            }
          />
          <Route path={`/resetPassword`} element={<ResetPasswordPage />} />
          <Route path={`/changePassword`} element={<ChangePasswordPage />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>

        <div className={classes.support}>
          <Typography variant="body1">
            <a href={`mailto:${SUPPORT_EMAIL}`}>EDAC Support</a>
          </Typography>
        </div>
      </div>
    </Paper>
  </DefaultErrorBoundary>
);

export default withStyles(styles)(AuthContainer);
