import { TaskStatus } from 'models/graphql';

const taskStatusAvailableTransitions: Record<TaskStatus, TaskStatus[]> = {
  [TaskStatus.UNASSIGNED]: [TaskStatus.CANCELLED],
  [TaskStatus.SENT]: [TaskStatus.CANCELLED, TaskStatus.CONTRACTOR_ACCEPTED, TaskStatus.CONTRACTOR_REJECTED],
  [TaskStatus.CONTRACTOR_ACCEPTED]: [TaskStatus.CANCELLED],
  [TaskStatus.CONTRACTOR_REJECTED]: [TaskStatus.CANCELLED],
  [TaskStatus.IN_PROGRESS]: [],
  [TaskStatus.COMPLETED]: [],
  [TaskStatus.SUPP_INFO_SUBMITTED]: [TaskStatus.APPROVED_CLOSED, TaskStatus.REJECTED, TaskStatus.REJECTED_CLOSED],
  [TaskStatus.REVIEW_REQ_SENT]: [],
  [TaskStatus.REVIEW_REQ_ACCEPTED]: [],
  [TaskStatus.REVIEW_REQ_REJECTED]: [],
  [TaskStatus.CONSULTANT_REJECTED]: [],
  [TaskStatus.CONSULTANT_APPROVED]: [],
  [TaskStatus.REJECTED]: [TaskStatus.REJECTED_CLOSED],
  [TaskStatus.REJECTED_CLOSED]: [],
  [TaskStatus.APPROVED_CLOSED]: [],
  [TaskStatus.CANCELLED]: [],
};

export default taskStatusAvailableTransitions;
