import { Workbook } from 'exceljs';
import Excel from './Excel';

export default async function excelFileToBook(file: File): Promise<Workbook> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async (event) => {
      const data = event.target?.result as ArrayBuffer;
      if (!data) reject(new Error('No data can be read from the file'));

      const book = new Excel.Workbook();
      await book.xlsx.load(data!);
      resolve(book);
    };

    reader.onerror = (event) => {
      reject(new Error(`File could not be read! Code ${event.target!.error!.code}`));
    };

    reader.readAsArrayBuffer(file);
  });
}
