import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import AppTable, { AppTableFilters, AppTableStatus } from 'components/AppTable';
import {
  ClientStatus,
  clientStatusUpdate,
  clientStatusUpdateVariables,
  getClients,
  getClientsVariables,
  getClients_clients as Client,
} from 'models/graphql';
import switcher from 'utils/switcher';
import ActionsButton from 'components/ActionsButton';
import AppButtonLink from 'components/AppButtonLink';
import RemoveDialog from 'components/RemoveDialog';
import { GET_CLIENTS, UPDATE_STATUS } from './graphql';
import { withStores, AuthStore } from 'stores';
import { useNavigate } from 'react-router';

interface ClientComponentProps {
  authStore: AuthStore;
}

const ClientsPage: React.FC<ClientComponentProps>  =  ({ authStore }) => {
  const navigate = useNavigate();

  const [updateStatus] = useMutation<clientStatusUpdate, clientStatusUpdateVariables>(UPDATE_STATUS);
  const { loading: clientsQueryLoading, data: clientsQuery, refetch: refetchClientsQuery } = useQuery<
    getClients,
    getClientsVariables
  >(GET_CLIENTS);
  const [archive, setArchive] = useState(false);
  const [archiveData, setArchiveData] = useState<{
    id: string;
    status: ClientStatus;
  }>();
  const closeArchive = () => setArchive(false);
  const openArchive = () => {
    setArchive(true);
  };


  const renderStatus = (row: Client) => <AppTableStatus isArchived={row.status === ClientStatus.ARCHIVED} />;

  const isconsultantuser= authStore.user?.isconsultant;
  let shownewclient;


  if (!isconsultantuser) {
    shownewclient =    <AppTableFilters
    searchPlaceholder="Search by company name, contact name, etc."
    onSubmit={async ({ search, showArchived }) => {
      await refetchClientsQuery({
        search: search || undefined,
        archived: showArchived || undefined,
      });
    }}
    addNewButton={
      <AppButtonLink to="add" type="button" color="primary">
        Add Client
      </AppButtonLink>
    }
  />

  } else {
   
    shownewclient =    ""

  }



  return (
    <>
   
      {shownewclient}


      <AppTable
        data={clientsQuery && clientsQuery.clients}
        loading={clientsQueryLoading}
        noDataMessage="No Clients"
        rowLink={(row) => `${row.id}`}
        columns={[
          {
            key: 'name',
            name: 'Company Name',
            template: (row) => row.companyName,
          },
          {
            key: 'contactName',
            name: 'Contact Name',
            template: (row) => row.contactName,
          },
          {
            key: 'contactEmail',
            name: 'Contact Email',
            template: (row) => row.contactEmail,
          },
          {
            key: 'status',
            name: 'Status',
            align: 'center',
            template: renderStatus,
          },
          {
            key: 'actions',
            link: null,
            template: function ActionsTemplate(row) {
              if (!isconsultantuser) {
              return (
                <ActionsButton
                actions={[
                  {
                    title: 'Edit Details',
                    action: () => {
                      navigate(`${row.id}/edit`);
                    },
                  },
                  {
                    title:
                      switcher(row.status, [
                        [ClientStatus.ACTIVE, 'Archive'],
                        [ClientStatus.ARCHIVED, 'Activate'],
                      ]) || 'Archive',
                    action: () => {
                      setArchiveData({ id: row.id, status: row.status });
                      openArchive();
                    },
                  },
                ]}
              />
              );
            }},
          },
        ]}
      />
      <RemoveDialog
        open={archive && archiveData !== undefined}
        title={`${archiveData?.status === ClientStatus.ACTIVE ? 'Archive' : 'Activate'} Client?`}
        descriptionText={
          archiveData?.status === ClientStatus.ACTIVE
            ? 'The client details, users and data will be moved to the Archive.'
            : 'The client will be activated.'
        }
        agreementText={
          archiveData?.status === ClientStatus.ACTIVE
            ? 'I understand that the client will not be able to access their portal.'
            : 'I understand that the client will be able to access their portal.'
        }
        onSend={async () => {
          if (!archiveData) return;
          await updateStatus({
            variables: {
              id: archiveData?.id,
              status: switcher(archiveData?.status, [
                [ClientStatus.ACTIVE, ClientStatus.ARCHIVED],
                [ClientStatus.ARCHIVED, ClientStatus.ACTIVE],
              ])!,
            },
          });
        }}
        submitText={archiveData?.status === ClientStatus.ACTIVE ? 'Archive' : 'Activate'}
        onClose={closeArchive}
      />
    </>
  );
};

export default  withStores('authStore') (ClientsPage);