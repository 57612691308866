import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { fileUpload } from 'utils/Api';
import { useMutation } from '@apollo/client';
import { uploadRequest as UploadRequestResponse, uploadRequestVariables } from 'models/graphql';
import { asyncMap } from 'utils/asyncArray';
import { UPLOAD_REQUEST } from './graphql';
import { createStyles, makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import dropIcon from 'assets/dropIcon.svg';
import errorIcon from 'assets/errorIcon.svg';


const styles = () =>
  createStyles({
    dropZone: {
      width: '100%',
      height: '20vh',
      minHeight: '140px',
      backgroundColor: '#F3F4F9',
      border: '1px dashed rgba(0, 0, 0, 0.25)',
      boxSizing: 'border-box',
      textAlign: 'center',
      borderRadius: '8px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    activeStyle: {
      border: '2px dashed #4287F5',
    },
    acceptStyle: {
      border: '2px dashed #69CC84',
    },
    rejectStyle: {
      border: '2px dashed #F55142',
    },
    browseLink: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#69CC84',
      '&:active': {
        outline: '0',
      },
      '&:focus': {
        outline: '0',
      },
    },
    gridFileInput: {
      width: '100%',
      height: '100%',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
    },
    dropP: {
      marginBottom: '0',
      marginTop: '14px',
    },
    errorText: {
      color: '#F15541',
      fontSize: '12px',
      lineHeight: '1',
      marginLeft: '8px',
    },
    marginTop: {
      marginTop: '10px',
    },
  });
const useStyles = makeStyles(styles);

interface Props {
  onUpload: (id: string, name: string) => void;
  multiple?: boolean;
}

const PdfFileInput: React.FC<Props> = ({ onUpload, multiple }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [rejectOrActiveClass, setRejectOrActiveClass] = useState('');
  const [fileRejected, setFileRejected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [uploadRequest] = useMutation<UploadRequestResponse, uploadRequestVariables>(UPLOAD_REQUEST);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      asyncMap(acceptedFiles, async (file: File) => {
        try {
          setError(false);
          setIsUploading(true);
          const response = await uploadRequest({
            variables: { fileName: file.name, public: false },
          });
          if (!response.data) {
            throw new Error('Unable to upload.');
          }
          const { url, id } = response.data.uploadRequest;
          await fileUpload(url, file);
          onUpload(id, file.name);
          setIsUploading(false);
        } catch (e) {
          setError(true);
        }
      });
    },
    [uploadRequest, onUpload],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    multiple,
    onDropRejected: () => {
      setRejectOrActiveClass(classes.rejectStyle);
      setFileRejected(true);
      setTimeout(() => {
        setRejectOrActiveClass('');
        setFileRejected(false);
      }, 3500);
    },
    onDropAccepted: () => {
      setRejectOrActiveClass(classes.acceptStyle);
      setTimeout(() => setRejectOrActiveClass(''), 2500);
    },
  });

  return (
    <div
      {...getRootProps({
        className: `${classes.dropZone} ${isDragActive ? classes.activeStyle : rejectOrActiveClass}`,
      })}
    >
      <input {...getInputProps()} />
      <div className={classes.gridFileInput}>
        <div />
        <img src={dropIcon} alt="Drop files here" />
        <p className={classes.dropP}>
          Drag and drop PDF files <br />
          OR
          <br />
          <span className={classes.browseLink} onClick={open} onKeyPress={open} role="button" tabIndex={0}>
            Browse File
          </span>
        </p>
        <div />
      </div>
      {isUploading && (
        <div className={classes.marginTop}>
          <CircularProgress />
        </div>
      )}
      {error && (
        <div className={classes.marginTop}>
          <img alt="Error" src={errorIcon} />
          <span className={classes.errorText}>Upload error. Please try again later.</span>
        </div>
      )}
      {fileRejected && (
        <div className={classes.marginTop}>
          <img alt="Error" src={errorIcon} />
          <span className={classes.errorText}>Only PDF files are accepted.</span>
        </div>
      )}
    </div>
  );
};

export default PdfFileInput;
