import React from 'react';
import compliantIcon from 'assets/compliantIcon.svg';
import nonCompliantIcon from 'assets/nonCompliantIcon.svg';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'utils/cn';

const useStyles = makeStyles(() => {
  return createStyles({
    icon: {
      display: 'inline-block',
    },
    smallIcon: {
      width: 15,
    },
  });
});

const AssetComplianceIcon: React.FC<{
  asset: { compliant: boolean | null };
  small?: boolean;
}> = ({ asset: { compliant }, small }) => {
  const styles = useStyles();

  if (compliant === true) {
    return <img alt="compliant" src={compliantIcon} className={cn(styles.icon, { [styles.smallIcon]: small })} />;
  }
  if (compliant === false) {
    return (
      <img alt="non compliant" src={nonCompliantIcon} className={cn(styles.icon, { [styles.smallIcon]: small })} />
    );
  }
  return <></>;
};
export default AssetComplianceIcon;
