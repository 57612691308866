import React from 'react';

import DrawingPage from 'containers/shared/DrawingPage';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import SitesNotFoundOrLoading from 'components/SitesNotFoundOrLoading';
import AssetsPage from './AssetsPage';
import DrawingsPage from './DrawingsPage';
import useSites from '../useSites';

// FW Addition 12/02/2022 for Mapping Page
import MapPage from './Mapping/MapPage';
import { Route, Routes, useLocation } from 'react-router';
import { adaptPath } from 'utils/adaptPath';

const AssetsRoutes: React.FC = () => {
  const { pathname } = useLocation();
  const { current, renderBreadcrumb } = useSites(adaptPath(pathname, 'assets'));
  return current ? (
    <DefaultErrorBoundary>
      {renderBreadcrumb('Asset Register')}
      <Routes>
        <Route path={`drawing/:drawingId`} element={<DrawingPage />} />
        <Route path={`drawings`} element={<DrawingsPage />} />
        <Route path={`mapping`} element={<MapPage />} />
        <Route path={`/`} element={<AssetsPage />} />
      </Routes>
    </DefaultErrorBoundary>
  ) : (
    <SitesNotFoundOrLoading path={'/'} name="Assets" />
  );
};

export default AssetsRoutes;
