import { useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { AnimateHeight } from 'components/animations';
import AppDialog from 'components/AppDialog';
import { AppDateField, AppForm, AppFormControlLabel, AppSwitch, AppTextField } from 'components/form';
import AppFormFieldsRow from 'components/form/AppFormFieldsRow';
import AppSelectField from 'components/form/AppSelectField';
import AppSelectMultipleField from 'components/form/AppSelectMultipleField';
import AppTextAreaField from 'components/form/AppTextAreaField';
import { FormLayout, FullScreenPage } from 'components/layout';
import {
  ACCEPT_REQUEST,
  GET_CLIENT_FOR_ACCEPT_REQUEST,
  GET_CONTRACTORS_FOR_ACCEPT_REQUEST,
} from 'containers/Client/Contractors/graphql';
import { FieldArray, Formik } from 'formik';
import {
  clientAcceptRequest,
  clientAcceptRequestVariables,
  getClientForAcceptRequest,
  getClientForAcceptRequestVariables,
  getContractorForClient,
  getContractorForClientVariables,
  getContractorsForAcceptRequest,
  getContractorsForAcceptRequestVariables,
  SystemType,
} from 'models/graphql';
import React from 'react';
import useText from 'texts/useText.hook';
import enumToArray from 'utils/enumToArray';
import useCancel from 'utils/useCancel.hook';
import Yup from 'utils/yup';
import AppFieldArrayAdd from 'components/form/AppFieldArrayAdd';
import AppFieldArrayRemove from 'components/form/AppFieldArrayRemove';
import { GET_CONTRACTOR_FOR_CLIENT } from 'containers/shared/graphql';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import formatDayDate, { readDayDate } from 'shared/formatDayDate';
import { useParams } from 'react-router';
import i18next from 'i18next';

interface FormValues {
  contractorId: string;
  siteIds: string[];
  systems: SystemType[];
  contractNumber: string;
  contractExpiracy: Date | null;
  workOrders: { key: string; id: string; expiracy: Date | null }[];
  managerId: string;
  twoFactorAuth: boolean;
  customMessage?: string;
}

const systemsArray = enumToArray(SystemType);


const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
  contractorId: Yup.string().required(),
  siteIds: Yup.array().required().min(
    1, i18next.t('common:required', {
      field: i18next.t('clients:site_optionalPlural')
    })
  ).of(Yup.string().required()),
  systems: Yup.array().of<SystemType>(Yup.mixed<SystemType>().oneOf(systemsArray).required()).required().min(
    1, i18next.t('common:required', {
      field: i18next.t('clients:system_optionalPlural')
    })
  ),
  contractNumber: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('contractors:contractNumber')
    })
  ),
  contractExpiracy: Yup.date().required(
    i18next.t('common:required', {
      field: i18next.t('contractors:expiracy')
    })
  ),
  workOrders: Yup.array()
    .required()
    .of(
      Yup.object().required().shape({
        key: Yup.string().required(),
        id: Yup.string().required(
          i18next.t('common:required', {
            field: i18next.t('contractors:worksOrderNumber')
          })
        ),
        expiracy: Yup.date().required(
          i18next.t('common:required', {
            field: i18next.t('contractors:expiracy')
          })
        ),
      }),
    ),
  managerId: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('contractors:supplierManager')
    })
  ),
  twoFactorAuth: Yup.boolean().required(),
  customMessage: Yup.string().required(),
});

const AcceptRequestPage: React.FC = () => {
  const { clientId, contractorId: defaultContractorId, contractorRequestId } = useParams<{
    clientId: string;
    contractorId: string;
    contractorRequestId?: string;
  }>();

  const mode: 'update' | 'create' = contractorRequestId ? 'update' : 'create';

  const { loading: contractorsLoading, data: contractorsData } = useQuery<
    getContractorsForAcceptRequest,
    getContractorsForAcceptRequestVariables
  >(GET_CONTRACTORS_FOR_ACCEPT_REQUEST);
  const contractors = contractorsData?.contractors;

  const { loading: clientDataLoading, data: clientData } = useQuery<
    getClientForAcceptRequest,
    getClientForAcceptRequestVariables
  >(GET_CLIENT_FOR_ACCEPT_REQUEST, { variables: { clientId } });

  const { data: contractorRequestData, loading: contractorRequestDataLoading } = useQuery<
    getContractorForClient,
    getContractorForClientVariables
  >(GET_CONTRACTOR_FOR_CLIENT, {
    variables: { contractorId: defaultContractorId, clientId },
    skip: mode === 'create',
  });
  const sites = clientData?.client.sites;
  const subscription = clientData?.client.subscription;
  const allowedSystems = subscription?.allowedSystems;

  const [acceptRequest] = useMutation<clientAcceptRequest, clientAcceptRequestVariables>(ACCEPT_REQUEST, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Client',
        id: clientId,
        fieldName: 'requests',
      }),
  });

  const parentHref = '/contractors';
  const cancel = useCancel(parentHref);

  const { t } = useText('contractors', 'clients', 'systemTypes', 'common');

  if (!contractorsLoading && !clientDataLoading && (!subscription || !allowedSystems)) {
    return (
      <AppDialog open onClose={cancel} type="error" title={t('contractors')('acceptContractorError')}>
        {!subscription ? t('clients')('noSubscription') : t('clients')('noAllowedAssets')}
      </AppDialog>
    );
  }

  return (
    <FullScreenPage
      title={mode === 'update' ? t('contractors')('editContractorAccess') : t('contractors')('acceptContractor')}
      loading={contractorsLoading || clientDataLoading || (mode === 'update' && contractorRequestDataLoading)}
    >
      <Formik<FormValues>
        initialValues={{
          contractorId: defaultContractorId || '',
          siteIds: contractorRequestData?.contractor.request?.sites.map((site) => site.id) || [],
          systems: contractorRequestData?.contractor.request?.systemTypes || [],
          contractNumber: contractorRequestData?.contractor.request?.contractNumber || '',
          contractExpiracy: contractorRequestData?.contractor.request?.contractExpiracy
            ? readDayDate(contractorRequestData?.contractor.request?.contractExpiracy)
            : null,
          workOrders: contractorRequestData?.contractor.request?.workOrders?.map(({ expiracy, ...rest }) => ({
            key: uuidv4(),
            expiracy: new Date(expiracy),
            ...rest,
          })) || [{ key: uuidv4(), id: '', expiracy: null }],
          managerId: contractorRequestData?.contractor.request?.manager?.id || '',
          twoFactorAuth: contractorRequestData?.contractor.request?.twoFactorAuth || false,
          customMessage:
            contractorRequestData?.contractor.request?.customMessage ||
            '<client> has updated the contractor access rights for <contractor name>.',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (!values.contractExpiracy) {
            throw new Error('contractExpiracy is not set.');
          }

          await acceptRequest({
            variables: {
              contractorId: values.contractorId,
              clientId,
              accessRights: {
                managerId: values.managerId,
                siteIds: values.siteIds,
                systemTypes: values.systems,
                workOrders: values.workOrders.map((workOrder) => {
                  if (!workOrder.expiracy) throw new Error(`workOrder '${workOrder.id}' expiracy is not set.`);

                  return {
                    id: workOrder.id,
                    expiracy: formatDayDate(workOrder.expiracy),
                  };
                }),
                contractNumber: values.contractNumber,
                contractExpiracy: formatDayDate(values.contractExpiracy),
                canBeListedAsReference: false,
                twoFactorAuth: values.twoFactorAuth,
                customMessage: values.customMessage,
              },
            },
          });
          cancel();
        }}
      >
        {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
          if (!contractors || !sites) return null;
          const selectedContractor = contractors.find((contractor) => contractor.id === values.contractorId);

          return (
            <AppForm handleSubmit={handleSubmit}>
              <FormLayout isSubmitting={isSubmitting} parentHref={parentHref}>
                <AppSelectField
                  label={t('contractors')('contractor')}
                  name="contractorId"
                  required
                  options={{
                    array: contractors,
                    key: (contractor) => contractor.id,
                    value: (contractor) => contractor.id,
                    template: (contractor) => contractor.companyName,
                  }}
                />

                <AppSelectMultipleField
                  label={t('clients')('site_optionalPlural')}
                  name="siteIds"
                  required
                  options={{
                    array: sites,
                    key: (site) => site.id,
                    value: (site) => site.id,
                    template: (site) => site.name,
                  }}
                />

                <AppSelectMultipleField
                  label={t('clients')('system_optionalPlural')}
                  name="systems"
                  required
                  options={{
                    array: systemsArray,
                    key: (system) => system,
                    value: (system) => system,
                    template: (system) => t('systemTypes')(system),
                  }}
                />

                <AppFormFieldsRow>
                  <AppTextField label={t('contractors')('contractNumber')} name="contractNumber" />
                  <AppDateField
                    label={t('contractors')('expiracy')}
                    name="contractExpiracy"
                    minDate={new Date()}
                    onChange={(date) => {
                      if (date) setFieldValue('contractExpiracy', date);
                    }}
                    required
                  />
                </AppFormFieldsRow>

                <FieldArray
                  name="workOrders"
                  render={({ push, remove, replace }) => (
                    <>
                      {values.workOrders.map((workOrder, index) => (
                        <AnimateHeight visible key={workOrder.key}>
                          <AppFormFieldsRow>
                            <AppTextField
                              label={t('contractors')('worksOrderNumber')}
                              name={`workOrders.${index}.id`}
                              required
                            />
                            <AppDateField
                              label={t('contractors')('expiracy')}
                              name={`workOrders.${index}.expiracy`}
                              minDate={new Date()}
                              onChange={(date) => {
                                if (date) {
                                  const newWorkOrder = workOrder;
                                  newWorkOrder.expiracy = date;
                                  replace(index, newWorkOrder);
                                }
                              }}
                              required
                            />
                            {values.workOrders.length > 1 ? (
                              <AppFieldArrayRemove
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            ) : null}
                          </AppFormFieldsRow>
                        </AnimateHeight>
                      ))}

                      <AppFieldArrayAdd
                        onClick={() => {
                          push({ key: uuidv4(), id: '', expiracy: null });
                        }}
                        text={t('contractors')('addWorksOrder')}
                      />
                    </>
                  )}
                />

                <AppSelectField
                  label={t('contractors')('supplierManager')}
                  name="managerId"
                  required
                  disabled={!values.contractorId}
                  options={{
                    array: selectedContractor?.users || [],
                    key: (user) => user.id,
                    value: (user) => user.id,
                    template: (user) => user.name,
                    ifArrayEmpty: t('contractors')('noContractorUser', {
                      contractor: selectedContractor?.companyName,
                    }),
                  }}
                />

                <AppFormControlLabel
                  control={<AppSwitch name="twoFactorAuth" />}
                  labelPlacement="start"
                  label={t('contractors')('twoFactorAuth')}
                />

                <AppTextAreaField label={t('common')('message')} name="customMessage" />
              </FormLayout>
            </AppForm>
          );
        }}
      </Formik>
    </FullScreenPage>
  );
};

export default AcceptRequestPage;
