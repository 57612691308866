import { useStores } from 'stores';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export default function useCancel(defaultLocation: string) {
  const { contextStore } = useStores();
  const [previousLocation, setPreviousLocation] = useState(contextStore.previousLocation);
  const navigate = useNavigate();
  useEffect(() => {
    setPreviousLocation(contextStore.previousLocation);
  }, [contextStore.previousLocation])

  return useCallback(() => (previousLocation ? navigate(-1) : navigate(defaultLocation)), [
    defaultLocation,
    previousLocation,
    history,
  ]);
}
