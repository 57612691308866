import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import {
  userUpdate,
  userUpdateVariables,
  user as userQuery,
  userVariables,
  Role,
  clientSites,
  clientSitesVariables,
  userCreate,
  userCreateVariables,
  TaskStatus,
  NotificationScheduleType,
} from 'models/graphql';
import FileInput from 'components/FileInput';
import Yup from 'utils/yup';
import { AppFormControlLabel, AppForm, AppTextField, AppSwitch } from 'components/form';
import { FormLayout, FullScreenPage } from 'components/layout';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import useCancel from 'utils/useCancel.hook';
import useText from 'texts/useText.hook';
import AppTextAreaField from 'components/form/AppTextAreaField';
import AppSelectField from 'components/form/AppSelectField';
import useParentLocation from 'utils/useParentLocation.hook';
import AppSelectMultipleField from 'components/form/AppSelectMultipleField';
import { CLIENT_ROLES, CONTRACTOR_ROLES } from 'constants/roleGroups';
import resolveGraphqlErrorMessage from 'utils/resolveGraphqlErrorMessage';
import GraphqlErrorCode from 'shared/graphqlErrorCodes';
import { availableTaskStatuses } from 'shared/taskStatusGroups';
import notificationSchedule from 'shared/notificationsSchedule';
import { GET_CLIENT_SITES, CREATE_USER, UPDATE_USER, GET_USER } from './graphql';
import { useParams } from 'react-router';
import i18next from 'i18next';

interface FormValues {
  name: string;
  email: string;
  phonenumber: string;
  siteIds?: string[];
  role: Role;
  isAdmin: boolean;
  sendEmail: boolean;
  message?: string;
  imgId?: string;
  fullAccess: boolean;
  adhoc: boolean;
  notifications: boolean;
  notificationSchedule?: NotificationScheduleType[];
  notificationStatus?: TaskStatus[];
}

interface ValidationSchema {
  email: string;
  phonenumber: string;
  siteIds?: string[];
  role: Role;
  isAdmin: boolean;
  sendEmail: boolean;
  fullAccess: boolean;
  adhoc: boolean;
  notifications: boolean;
}


const UserUpdate: React.FC = () => {
  const { t, tt } = useText('tasks', 'taskStatuses', 'roles', 'notifications', 'common', 'user');
  const { clientId, contractorId, userId } = useParams<{
    clientId?: string;
    contractorId?: string;
    userId?: string;
  }>();
  
  const parentHref = useParentLocation();
  const cancel = useCancel(parentHref);
  const { loading: sitesLoading, data: sitesData } = useQuery<clientSites, clientSitesVariables>(GET_CLIENT_SITES, {
    variables: { id: clientId! },
    skip: !clientId,
  });

  const [create] = useMutation<userCreate, userCreateVariables>(CREATE_USER, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: clientId ? 'Client' : 'Contractor',
        id: clientId || contractorId || '',
        fieldName: 'users',
      }),
  });
  const [update] = useMutation<userUpdate, userUpdateVariables>(UPDATE_USER);

  const { loading: userLoading, data: userData, refetch } = useQuery<userQuery, userVariables>(GET_USER, {
    skip: !userId,
    variables: { id: userId! },
  });
  const user = userData?.user;  

  const validationSchema: Yup.ObjectSchema<ValidationSchema> = Yup.object().shape({
    name: Yup.string().required(
      i18next.t('common:required', {
        field: i18next.t('user:name')
      })
    ),
    email: Yup.string().email().required(
      i18next.t('common:required', {
        field: i18next.t('user:email')
      })
    ),
    phonenumber: Yup.string().required(
      i18next.t('common:required', {
        field: i18next.t('user:phonenumber')
      })
    ),
    siteIds: clientId ? Yup.array().required().min(
      1, i18next.t('common:required', {
        field: i18next.t('user:site')
      })
    ).of(Yup.string().required()) : Yup.array().of(Yup.string().required()),
    role: Yup.mixed<Role>().oneOf(Object.values(Role)).required(
      i18next.t('common:required', {
        field: i18next.t('user:role')
      })
    ),
    isAdmin: Yup.boolean().required(),
    sendEmail: Yup.boolean().required(),
    fullAccess: Yup.boolean().required(),
    adhoc: Yup.boolean().required(),
    notifications: Yup.boolean().required(),
  });

  return (
    <FullScreenPage title={`${userId ? 'Edit' : 'Add'} user`} loading={sitesLoading || userLoading}>
      <Formik<FormValues>
        initialValues={{
          name: user?.name || '',
          email: user?.email || '',
          phonenumber: user?.phonenumber || '',
          siteIds: user?.sites?.map((site) => site.id) || [],
          role: user?.role || (clientId ? Role.CLIENT_ENVIRONMENTAL_OFFICER : Role.CONTRACTOR_OPERATIONS_MANAGER),
          isAdmin: user?.isAdmin || false,
          sendEmail: !user,
          message: 'Please use the enclosed link to set the password for your Edac account.',
          imgId: user?.picture?.id || '',
          fullAccess: user?.fullAccess || false,
          adhoc: user?.adhoc || false,
          notifications: !!user?.notificationSchedule.length || !!user?.notificationStatus.length,
          notificationSchedule: user?.notificationSchedule,
          notificationStatus: user?.notificationStatus,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          const formValues = { ...values };
          try {
            if (!formValues.notifications) {
              formValues.notificationSchedule = [];
              formValues.notificationStatus = [];
            }
            delete formValues.notifications;

            if (userId) {
              await update({
                variables: {
                  id: userId,
                  user: formValues,
                },
              });
            } else {
              await create({
                variables: {
                  companyId: clientId || contractorId || '',
                  user: formValues,
                },
              });

              cancel();
            }

            await refetch();
          } catch (e) {
            const [code, fieldName] = resolveGraphqlErrorMessage(e);
            if (code === GraphqlErrorCode.uniqueViolationError && fieldName === 'email') {
              setFieldError('email', 'Email already exists.');
            }
            return;
          }

          cancel();
        }}
      >
        {({ isSubmitting, setFieldValue, values, handleSubmit, handleBlur, handleChange }) => (
          <AppForm handleSubmit={handleSubmit}>
            <FormLayout
              parentHref={parentHref}
              isSubmitting={isSubmitting}
              sidebar={
                <FileInput
                  onUpload={(id: string) => setFieldValue('imgId', id)}
                  initialSrc={user?.picture?.srcUrl}
                  accept="image/*"
                  label="Profile picture"
                  publicAccess
                />
              }
            >
              <AppTextField label={t('user')('name')} name="name" type="text" autoFocus required />
              <AppTextField
                label={t('user')('email')}
                name="email"
                type="email"
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
              <AppTextField label={t('user')('phonenumber')} name="phonenumber" type="text" required />
              {clientId ? (
                <AppSelectMultipleField
                  label={t('user')('site')}
                  name="siteIds"
                  required
                  options={{
                    array: sitesData?.client.sites || [],
                    key: (site) => site.id,
                    value: (site) => site.id,
                    template: (site) => site.name,
                  }}
                />
              ) : null}
              <AppSelectField
                label={t('user')('role')}
                name="role"
                required
                options={{
                  array: clientId ? CLIENT_ROLES : CONTRACTOR_ROLES,
                  key: (role) => role,
                  value: (role) => role,
                  template: (role) => t('roles')(role),
                }}
              />
              <AppFormControlLabel
                control={<AppSwitch name="isAdmin" />}
                labelPlacement="start"
                label={t('user')('isAdmin')}
              />
              <AppFormControlLabel
                control={<AppSwitch name="sendEmail" />}
                labelPlacement="start"
                label={t('user')('sendEmail')}
              />
              {values.role === Role.CLIENT_OPERATIVE || values.role === Role.CONTRACTOR_TECHNICIAN ? (
                <AppFormControlLabel
                  control={<AppSwitch name="fullAccess" />}
                  labelPlacement="start"
                  label={t('user')('fullAccess')}
                />
              ) : null}
              {values.role === Role.CLIENT_OPERATIVE ? (
                <AppFormControlLabel
                  control={<AppSwitch name="adhoc" />}
                  labelPlacement="start"
                  label={t('user')('adhoc')}
                />
              ) : null}

              <AppFormControlLabel
                control={<AppSwitch name="notifications" />}
                labelPlacement="start"
                label={t('user')('notifications')}
              />
              {values.notifications ? (
                <>
                  <AppSelectMultipleField
                    label={t('user')('notificationStatus')}
                    name="notificationStatus"
                    options={{
                      array: availableTaskStatuses,
                      key: (value) => value as string,
                      value: (value) => value as string,
                      template: (value) =>
                        TaskStatus[value as TaskStatus] === undefined
                          ? tt('tasks')('filter')('allStatuses')
                          : t('taskStatuses')(value as TaskStatus),
                    }}
                  />
                  <AppSelectMultipleField
                    label={t('user')('notificationSchedule')}
                    name="notificationSchedule"
                    options={{
                      array: notificationSchedule,
                      key: (value) => value,
                      value: (value) => value,
                      template: (value) => {
                        return tt('notifications')('schedule')(
                          value as
                            | NotificationScheduleType.DAY1
                            | NotificationScheduleType.DAY3
                            | NotificationScheduleType.WEEK1
                            | NotificationScheduleType.NON_COMPLIANCE,
                        );
                      },
                    }}
                  />
                </>
              ) : null}

              <AppTextAreaField label={t('user')('message')} name="message" />
            </FormLayout>
          </AppForm>
        )}
      </Formik>
    </FullScreenPage>
  );
};

export default UserUpdate;
