import React, { useCallback } from 'react';
import cn from 'classnames';
import AppFieldError from 'components/form/AppFieldError';
import { useFormikContext } from 'formik';
import useFieldStyles from 'components/form/AppField.style';
import { FormControlLabel, TextareaAutosize, TextareaAutosizeProps, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const styleRules = (theme: Theme) => {
  const padding = theme.spacing(1);
  const borderWidth = 1;
  const borderWidthOnFocus = 2;

  return createStyles({
    textarea: {
      width: '100%',
      minWidth: theme.sizes.button.minWidth,
      padding,
      border: `solid ${borderWidth}px ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.fontSizes[14],

      '&:hover': {
        borderColor: theme.palette.grey[600],
      },

      '&:focus': {
        // borderColor: theme.palette.primary.main,
        // borderWidth: borderWidthOnFocus,
        // padding: padding - (borderWidthOnFocus - borderWidth),
        // outline: 0,
        outlineColor: theme.palette.primary.main,
      },

      '&.error': {
        borderColor: theme.palette.error.main,
      },
    },
  });
};
const useStyles = makeStyles(styleRules);

export type AppTextAreaFieldProps = Omit<Omit<TextareaAutosizeProps, 'onChange'>, 'name'> & {
  name: string; // name is required
  label?: string;
  onChange?: (value: string) => void;
};

const AppTextAreaField: React.FC<AppTextAreaFieldProps> = ({
  label: _label,
  name,
  onChange,
  rows,
  required,
  className,
  ...propsToPass
}) => {
  // values, touched and errors coming form FormikContext.
  // setFieldValue writes the changed value back to FormikContext.
  const formikContext = useFormikContext<Record<string, unknown>>();
  if (formikContext === undefined) {
    throw new Error('Cannot get Formik context. Have you use the AppTextField as child of a <Formik> component?');
  }

  const { values, setFieldValue, touched, errors } = formikContext;

  // If the field supports be embedded inside <FieldArray />
  // we have to read values by objectPropertyByString
  // (otherwise 'value = values[name]' is enough)
  let value = values[name] as string;
  if (value === null || value === undefined) value = '';

  const fieldStyles = useFieldStyles();
  const styles = useStyles();

  const control = () => {
    return <TextareaAutosize
      {...propsToPass}
      name={name}
      value={value}
      required={required}
      minRows={rows || 2}
      onChange={(event) => {
        const newValue = event.target.value;
        setFieldValue(name, newValue);
        if (onChange) {
          onChange(newValue);
        }
      }}
      className={cn(className, styles.textarea, {
        // only show error, if the field is touched
        error: touched[name] && !!errors[name],
      })}
    />
  }
  return (
    <div>
      {
        _label ? <FormControlLabel
          labelPlacement="top"
          className={cn(fieldStyles.label, { 'no-label': !_label })}
          label={_label}
          control={control()}
        /> : control()
      }
      
      <AppFieldError
        message={
          // only show error, if the field is touched
          touched[name] ? errors[name] : undefined
        }
      />
    </div>
  );
};

export default AppTextAreaField;
