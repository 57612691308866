import React from 'react';
import { TaskType } from 'models/graphql';
import complianceTaskIcon from 'assets/complianceTaskIcon.svg';
import complianceTaskIconGrey from 'assets/complianceTaskIconGrey.svg';
import repairIcon from 'assets/repairIcon.svg';
import repairIconGrey from 'assets/repairIconGrey.svg';
import samplingIcon from 'assets/samplingIcon.svg';
import samplingIconGrey from 'assets/samplingIconGrey.svg';
import surveyIcon from 'assets/surveyIcon.svg';
import surveyIconGrey from 'assets/surveyIconGrey.svg';
import testingIcon from 'assets/testingIcon.svg';
import testingIconGrey from 'assets/testingIconGrey.svg';
import otherTaskIcon from 'assets/otherTaskIcon.svg';
import otherTaskIconGrey from 'assets/otherTaskIconGrey.svg';
import auditTaskIcon from 'assets/auditTaskIcon.svg';
import auditTaskIconGrey from 'assets/auditTaskIconGrey.svg';
import toolboxTalkIconGrey from 'assets/toolboxTalkIconGrey.svg';
import toolboxTalkIcon from 'assets/toolboxTalkIcon.svg';
import healthAndSafetyIconGrey from 'assets/healthAndSafetyIconGrey.svg';
import healthAndSafetyIcon from 'assets/healthAndSafetyIcon.svg';
import cleaningIconGrey from 'assets/cleaningIconGrey.svg';         // FW Cleaning TASK icon 2022-04-28
import cleaningIcon from 'assets/cleaningIcon.svg';                 // FW Cleaning TASK icon 2022-04-28
import pestcontrolIconGrey from 'assets/pestcontrolIconGrey.svg';         // FW Pest Control TASK icon 2022-04-28
import pestcontrolIcon from 'assets/pestcontrolIcon.svg';                 // FW Pest Control  TASK icon 2022-04-28
import reportIconGrey from 'assets/reportIconGrey.svg';         // FW Pest Control TASK icon 2022-04-28
import reportIcon from 'assets/reportIcon.svg';                 // FW Pest Control  TASK icon 2022-04-28

const TaskTypeIcon: React.FC<{ type: TaskType; grey?: boolean }> = ({ type, grey }) => {
  if (type === TaskType.COMPLIANCE_CHECK) {
    return <img alt="compliance check" src={grey ? complianceTaskIconGrey : complianceTaskIcon} />;
  }
  if (type === TaskType.SURVEY) {
    return <img alt="survey" src={grey ? surveyIconGrey : surveyIcon} />;
  }
  if (type === TaskType.REPAIR) {
    return <img alt="repair" src={grey ? repairIconGrey : repairIcon} />;
  }
  if (type === TaskType.SAMPLING) {
    return <img alt="sampling" src={grey ? samplingIconGrey : samplingIcon} />;
  }
  if (type === TaskType.TESTING) {
    return <img alt="testing" src={grey ? testingIconGrey : testingIcon} />;
  }
  if (type === TaskType.AUDIT) {
    return <img alt="audit" src={grey ? auditTaskIconGrey : auditTaskIcon} />;
  }
  if (type === TaskType.TOOLBOX_TALK) {
    return <img alt="toolbox talk" src={grey ? toolboxTalkIconGrey : toolboxTalkIcon} />;
  }
  if (type === TaskType.HEALTH_AND_SAFETY) {
    return <img alt="health and safety" src={grey ? healthAndSafetyIconGrey : healthAndSafetyIcon} />;
  }
  if (type === TaskType.CLEANING) {
    return <img alt="Cleaning" src={grey ? cleaningIconGrey : cleaningIcon} />;
  }
  if (type === TaskType.PEST_CONTROL) {
    return <img alt="Pest Control" src={grey ? pestcontrolIconGrey : pestcontrolIcon} />;
  }
  if (type === TaskType.REPORT) {
    return <img alt="Report" src={grey ? reportIconGrey : reportIcon} />;
  }
  return <img alt="other task" src={grey ? otherTaskIconGrey : otherTaskIcon} />;
};

export default TaskTypeIcon;
