import { useMutation, useQuery } from '@apollo/client';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AppAvatar from 'components/AppAvatar';
import AppButton from 'components/AppButton';
import AppTable from 'components/AppTable';
import { AppForm } from 'components/form';
import AppCheckboxField from 'components/form/AppCheckbox';
import { FullScreenPage } from 'components/layout';
import { Formik } from 'formik';
import {
  getTechnicians,
  getTechniciansVariables,
  taskAssignToContractorUser,
  taskAssignToContractorUserVariables,
} from 'models/graphql';
import React, { useState } from 'react';
import styleUtils from 'style/styleUtils';
import useText from 'texts/useText.hook';
import { ASSIGN_TO_CONTRACTOR_USER, GET_TECHNICIANS } from './graphql';
import { useNavigate, useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    buttons: {
      marginTop: theme.spacing(4),
      ...styleUtils.rowEndCenter,

      '&>*:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  });
});

const TaskAssign: React.FC = () => {
  const { contractorId, taskId } = useParams<{
    contractorId: string;
    taskId: string;
  }>();
  const parentUrl = `/contractor/${contractorId}/work-instructions`;

  const { data: techniciansData, loading: techniciansDataLoading } = useQuery<getTechnicians, getTechniciansVariables>(
    GET_TECHNICIANS,
    {
      variables: { contractorId, taskId },
    },
  );
  const technicians = techniciansData?.contractor.users;
  const taskClientId = techniciansData?.task.clientId;

  const [assign] = useMutation<taskAssignToContractorUser, taskAssignToContractorUserVariables>(
    ASSIGN_TO_CONTRACTOR_USER,
  );

  const [selectedTechnician, setSelectedTechnician] = useState<string | undefined>();

  const styles = useStyles();
  const navigate = useNavigate();
  const { t } = useText('tasks', 'roles', 'common');
  return (
    <FullScreenPage title={t('tasks')('assignToTechnician')}>
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          if (selectedTechnician) {
            await assign({ variables: { taskId, userId: selectedTechnician } });
            navigate(parentUrl);
          }
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <AppForm handleSubmit={handleSubmit}>
            <div>
              <AppTable
                data={technicians?.filter((technician) =>
                  technician.clients.find((client) => client.id === taskClientId),
                )}
                loading={techniciansDataLoading}
                columns={[
                  {
                    key: 'select',
                    template: function select(user) {
                      return (
                        <AppCheckboxField
                          name=""
                          checked={selectedTechnician === user.id}
                          onChange={(event, checked) => {
                            setSelectedTechnician(checked ? user.id : undefined);
                          }}
                        />
                      );
                    },
                  },
                  {
                    key: 'avatar',
                    template: function avatar(user) {
                      return <AppAvatar name={user.name} imageSrc={user.picture?.srcUrl} />;
                    },
                  },
                  { name: t('tasks')('name'), template: (user) => user.name },
                  { name: t('tasks')('role'), template: (user) => t('roles')(user.role) },
                  {
                    name: t('tasks')('clients'),
                    template: (user) => user.clients.map((client) => client.companyName).join(', '),
                  },
                  { name: t('tasks')('upcomingTasks'), template: (user) => user.upcomingTasksCount, align: 'right' },
                ]}
                noDataMessage={t('tasks')('noAcceptedUser')}
              />
              <div className={styles.buttons}>
                <AppButton type="button" variant="outlined" onClick={() => navigate(parentUrl)}>
                  {t('common')('cancel')}
                </AppButton>
                <AppButton disabled={selectedTechnician === undefined} type="submit" inProgress={isSubmitting}>
                  {t('tasks')('assign')}
                </AppButton>
              </div>
            </div>
          </AppForm>
        )}
      </Formik>
    </FullScreenPage>
  );
};

export default TaskAssign;
