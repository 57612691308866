import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  ContractorRequestAccessStatus,
  Role,
  userAcceptRequest,
  userAcceptRequestVariables,
  userRemoveRequest,
  userRemoveRequestVariables,
  UserRequestAccessStatus,
  contractorRequestedUsers,
  contractorRequestedUsersVariables,
} from 'models/graphql';
import { ACCEPT_USER_REQUEST, GET_CONTRACTOR_REQUESTED_USERS, REMOVE_USER_REQUEST } from 'containers/shared/graphql';
import AppTable from 'components/AppTable';
import switcher from 'utils/switcher';
import UserRequestStatus from 'components/UserRequestStatus';
import RemoveDialog from 'components/RemoveDialog';
import { Actions } from 'components/ActionsButton';
import useMode, { Mode } from 'utils/useMode.hook';
import { useStores } from 'stores';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import UserDetailsPanel from './components/UserDetailsPanel';
import { Link, useParams } from 'react-router';


const ClientContractorUsersPage: React.FC = () => {
  const { clientId, contractorId } = useParams<{
    clientId: string;
    contractorId: string;
  }>();
  
  const mode = useMode();

  const { authStore } = useStores();
  const { user } = authStore;

  const [removeAccessDialogOpen, setRemoveAccessDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState<{
    userId: string;
  }>();
  const closeRemoveAccessDialog = () => setRemoveAccessDialogOpen(false);
  const openRemoveAccessDialog = (data: { userId: string }) => {
    setDialogData(data);
    setRemoveAccessDialogOpen(true);
  };

  const [removeAccess] = useMutation<userRemoveRequest, userRemoveRequestVariables>(REMOVE_USER_REQUEST, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Contractor',
        id: contractorId,
        fieldName: 'request',
      }),
  });

  const [acceptUserRequest] = useMutation<userAcceptRequest, userAcceptRequestVariables>(ACCEPT_USER_REQUEST);

  const { loading: contractorDataLoading, data: contractorData } = useQuery<
    contractorRequestedUsers,
    contractorRequestedUsersVariables
  >(GET_CONTRACTOR_REQUESTED_USERS, {
    variables: {
      contractorId,
      clientId,
    },
  });
  const request = contractorData?.contractor.request;
  const users = request?.userRequests;

  if (!request || request.accessStatus === ContractorRequestAccessStatus.DENIED) return null;
  if (request.accessStatus === ContractorRequestAccessStatus.REQUESTED) {
    return (
      <Link to={`/client/${clientId}/contractors/${contractorId}`}>The contractor request is not accepted yet.</Link>
    );
  }

  return (
    <div>
      <AppTable
        data={users}
        loading={contractorDataLoading}
        noDataMessage="No Users Requested access yet."
        detailsPanel={(row) => {
          const actions: Actions = [];

          if (mode.mode === Mode.CLIENT && (user?.isAdmin || user?.role === Role.PROJECT_MANAGER)) {
            if (row.accessStatus === UserRequestAccessStatus.REQUESTED) {
              actions.push({
                title: 'Accept Request',
                action: async (setLoading) => {
                  setLoading(true);
                  await acceptUserRequest({ variables: { userId: row.user.id, clientId } });
                  setLoading(false);
                },
              });
            }
            actions.push({
              title: row.accessStatus === UserRequestAccessStatus.REQUESTED ? 'Remove Request' : 'Remove Access',
              action: () => {
                openRemoveAccessDialog({
                  userId: row.user.id,
                });
              },
            });
          }

          return {
            title: row.user.name || '',
            template: (
              <UserDetailsPanel
                user={{
                  ...row.user,
                }}
              />
            ),
            actions,
          };
        }}
        columns={[
          {
            key: 'name',
            name: 'Name',
            template: (row) => row.user.name,
          },
          {
            key: 'phonenumber',
            name: 'Phone',
            template: (row) => row.user.phonenumber,
          },
          {
            key: 'role',
            name: 'Role',
            template: (row) =>
              switcher(row.user.role, [
                [Role.CONTRACTOR_OPERATIONS_MANAGER, 'Operations Manager'],
                [Role.CONTRACTOR_TECHNICIAN, 'Technician'],
              ]),
          },
          {
            key: 'status',
            name: 'Status',
            align: 'center',
            template: function AccessStatus(row) {
              return <UserRequestStatus accessStatus={row.accessStatus} />;
            },
          },
        ]}
      />

      <RemoveDialog
        open={removeAccessDialogOpen}
        title="Remove User Access?"
        descriptionText="All the ongoing works will be cancelled."
        agreementText="I understand that the user will not be able to continue with works from the contractor."
        onSend={async () => {
          if (!dialogData) return;
          await removeAccess({
            variables: {
              userId: dialogData.userId,
              clientId,
            },
          });
        }}
        onClose={closeRemoveAccessDialog}
      />
    </div>
  );
};

export default ClientContractorUsersPage;
