import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';

import AppTextField from 'components/form/AppTextField';
import Yup from 'utils/yup';
import { useStores } from 'stores';
import { useApolloClient } from '@apollo/client';
import FormComponent from './components/Form';
import { sendSmsCode, verifySmsCode } from './api';
import { FormControl, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    placeholder: {
      color: '#9F9F9F',
    },
    login: {
      width: '100%',
    },
    forgotPassword: {
      marginTop: theme.spacing(3),
      lineHeight: `${theme.sizes.button.height}px`,
      paddingRight: theme.spacing(4),
      color: theme.brandColors.main,
      textDecoration: 'underline',
    },
    loginHeader: {
      marginBottom: theme.spacing(5),
    },
    cancel: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  });
const useStyles = makeStyles(styles);

interface FormValues {
  code: string;
}

const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
  code: Yup.string().required('Please fill out this field.'),
});

const SmsLoginPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authStore } = useStores();
  const client = useApolloClient();
  const [badCode, setBadCode] = useState(false);
  useEffect(() => {
    sendSmsCode();
  }, []);

  return (
    <div className={classes.login}>
      <Typography className={classes.loginHeader} component="h1" variant="h5">
        Verify login with sms code
      </Typography>
      <Formik<FormValues>
        initialValues={{
          code: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await verifySmsCode(values.code);
            navigate('/');
          } catch (e) {
            setBadCode(true);
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <FormComponent
              onSubmit={(e) => handleSubmit(e)}
              submitButtonText="Submit sms code"
              inProgress={isSubmitting}
              justify="space-between"
            >
              <FormControl margin="normal" required fullWidth>
                <AppTextField name="code" placeholder="Code" id="code" required autoFocus />
              </FormControl>
              {badCode && (
                <Typography variant="body1" sx={{
                  color: "error"
                }}>
                  Invalid code, please check and try again or request a new code
                </Typography>
              )}
            </FormComponent>
            <button type="button" onClick={() => authStore.logout(client)} className={classes.cancel}>
              Cancel
            </button>
          </>
        )}
      </Formik>
    </div>
  );
};

export default SmsLoginPage;
