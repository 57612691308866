import React, { useState } from 'react';
import { FormControl, Theme, Typography } from '@mui/material';
import { createStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';

import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import queryString from 'query-string';

import { withStores, AuthStore } from 'stores';
import AppTextField from 'components/form/AppTextField';
import Yup from 'utils/yup';
import { user, userVariables } from 'models/graphql';
import { GET_USER } from 'containers/shared/graphql';
import FormComponent from './components/Form';
import { login } from './api';

import { GET_CLIENT } from 'containers/shared/ClientConfiguration/graphql';
import { getClient, getClientVariables } from 'models/graphql';
import { Link, Navigate, useLocation } from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    placeholder: {
      color: '#9F9F9F',
    },
    login: {
      width: '100%',
    },
    forgotPassword: {
      marginTop: theme.spacing(3),
      lineHeight: `${theme.sizes.button.height}px`,
      paddingRight: `${theme.spacing(4)}`,
      color: theme.brandColors.main,
      textDecoration: 'underline',
    },
    loginHeader: {
      marginBottom: theme.spacing(5),
    },
  });
const useStyles = makeStyles(styles);

interface FormValues {
  email: string;
  password: string;
}

const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
  email: Yup.string().required('Please fill out this field.'),
  password: Yup.string().required('Please fill out this field.'),
});

const LoginPage: React.FC<{ authStore: AuthStore }> = ({ authStore }) => {
  const location = useLocation();
  const classes = useStyles();
  const client = useApolloClient();
  const redirect = authStore.fallbackRedirect;
  const [badCredentials, setBadCredentials] = useState<FormValues[]>([]);

  if (location.search) {
    const params = queryString.parse(location.search);

    if (params.redirectTo) {
      window.localStorage.setItem('redirectTo', JSON.stringify(params.redirectTo));
    }
  }

  if (authStore.isLoggedIn) {
    return <Navigate to={redirect} />;
  }

  const isBadCredential = (formValues: FormValues): boolean => {
    return !!badCredentials.find(
      (badCredential) => badCredential.email === formValues.email && badCredential.password === formValues.password,
    );
  };

  const forgotPassword = (
    <Typography variant="body1" className={classes.forgotPassword}>
      <Link to="/auth/resetPassword">Forgot Password?</Link>
    </Typography>
  );

  return (
    <div className={classes.login}>
      <Typography className={classes.loginHeader} component="h1" variant="h5">
        Login
      </Typography>
      <Formik<FormValues>
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { id } = await login(values.email, values.password);
            const { data } = await client.query<user, userVariables>({
              query: GET_USER,
              variables: { id },
            });
            if (!data) {
              throw Error('Unable to load user data');
            }
           // else
          // {

           /*   console.log("Will Set if company has modules and projects at this point")
             
              // write other bits here
             
              let clientdata = await client.query<getClient, getClientVariables>({
                query: GET_CLIENT,
                variables: { id: clientId },
              });
              console.log("data_client for clients:", clientdata)
              let clientDetails = {'projects':clientdata.data.client.projects,'modules': clientdata.data.client.modules}
              console.log("clientDetails:", clientDetails)
              authStore.clientDetails(clientDetails) */
            
          //  }
       
            let clientId = data.user.company?.id;
            let clientDetails = {'projects':false,'modules': ''}
            if (data.user.role !== 'PROJECT_MANAGER')
            {
              let clientdata = await client.query<getClient, getClientVariables>({
                query: GET_CLIENT,
                variables: { id: clientId },
              }); 
              if (!clientdata) {
                throw Error('Unable to get user client data');
              }
            
              clientDetails = {'projects':clientdata.data.client.projects,'modules': clientdata.data.client.modules}
              authStore.login(data.user, clientDetails);
            }
            else
            {
              authStore.login(data.user);
            }
           

   
     
          
          
        
          } catch (e) {
            setBadCredentials([...badCredentials, values]);
            setSubmitting(false);
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <FormComponent
            onSubmit={(e) => handleSubmit(e)}
            submitButtonText="Login"
            inProgress={isSubmitting}
            justify="space-between"
            actionButton={forgotPassword}
          >
            <FormControl margin="normal" required fullWidth>
              <AppTextField
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                autoComplete="email"
                autoFocus
                required
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <AppTextField
                name="password"
                type="password"
                placeholder="Password"
                id="password"
                required
                autoComplete="current-password"
              />
            </FormControl>
            {isBadCredential(values) ? (
              <Typography variant="body1" sx={{
                color: "error"
              }}>
                Invalid email password combination
              </Typography>
            ) : null}
          </FormComponent>
        )}
      </Formik>
    </div>
  );
};

export default withStores('authStore')(LoginPage);
