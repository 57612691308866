import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import Item from 'components/Item';
import useText from 'texts/useText.hook';
import moment from 'moment';  
import {  Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Equivalent icon to use
import cn from 'utils/cn';
import AddTask from 'assets/addTask.svg';
import Tooltip from '@mui/material/Tooltip';
import filmreelIcon from 'assets/filmreelIcon.svg';
import docsLinkIcon from 'assets/docsLinkIcon.svg';
import ReactPlayer from "react-player";


const useStyles = makeStyles(() => ({
  popupContainer: {
    backgroundColor: 'white',
    padding: '10px',
    fontSize: 'initial', // Prevent font size conflicts
    zIndex: 2000, // Ensure it appears above other elements
  },
  alignRight: {
    textAlign: 'right'
  },
  alignLeft: {
    textAlign: 'left'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '1px solid #ccc',
    padding: '8px',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'white'
  },
  td: {
    borderBottom: '1px solid #ccc',
    padding: '8px',
    color: 'white'
  },
  accordion: {
    marginTop: '16px',
    backgroundColor: '#9d9d9c', // Match the popup background color
    border: '1px solid #9d9d9c', // Border color
    boxShadow: 'none', // Remove default shadow for a flat look
  },
  accordionDetails: {
    backgroundColor: '#9d9d9c', // Match the popup background color
    display: 'flex',           // Ensures children align side by side
    flexWrap: 'wrap',          // Wraps content if needed
    padding: '16px',           // Add padding inside the panel
    overflow: 'hidden',        // Prevents content overflow
  },
  AccordionSummary: {
    flexDirection: 'row-reverse', // Moves the expand icon to the left
    padding: '0', // Removes default padding from ExpansionPanelSummary
    '& .MuiIconButton-root': {
      margin: '0', // Removes the margin on the expand icon
      padding: '0', // Ensures no padding around the icon
    },
  },
  summaryContent: {
    backgroundColor: '#9d9d9c',
    justifyContent: 'flex-start', // Ensures content aligns to the left
    alignItems: 'center', // Vertically aligns text and the expand icon
  },
  modal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px', // Updated width
    height: '600px',backgroundColor: 'white',
    color: 'black',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    overflow: 'hidden',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  body: {
    display: 'flex',
    height: '100%',
  },
  split: {
    // Styles for split
    display: 'flex',
  },
  left: {
    // Styles for left
    justifyContent: 'flex-start',
  },
  splitLeft: {
    // Combine split and left styles
    display: 'flex',
    justifyContent: 'flex-start',
  },
  right: {
    flex: '65%', // Right side takes up 70%
    position: 'relative',
    paddingTop: '20px',
  },
  splitRight: {
    // Combine split and left styles
    display: 'flex',
    flex: '65%', // Right side takes up 70%
    position: 'relative',
    paddingTop: '2px',
    paddingLeft: '20%',
  },
  centered: {
    color: 'white', // Change "Now Playing" text to white
    width: '100%',             // Ensure it spans the container width
    height: '100%',            // Make it flexible to fill space
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',  // Center the content vertically
    overflow: 'hidden',        // Prevent player from spilling out
  },
  centeredImg: {
    width: '150px',
    borderRadius: '50%',
  },
  button: {
    padding: '10px 20px',
    cursor: 'pointer',
    marginTop: '20px',
  },
  fileList: {
    listStyle: 'none', // Remove bullet points for the list
    padding: 0,
    margin: 0,
    color: 'white', // Ensure file list text is white
  },
  fileButton: {
    background: 'none', // Keep button styling minimal
    border: 'none',
    cursor: 'pointer',
    color: 'white', // Change button text to white
    textDecoration: 'none', // Optional: Add underline for emphasis
    '&:hover': {
      textDecoration: 'none', // Optional: Remove underline on hover
    },
  },
  nowPlayingText: {
    color: 'white', // Change "Now Playing" text to white
    
  },
  expandIconText: {
    marginLeft: '8px', // Adds spacing between the expand icon and "Video Footage"
    fontSize: '16px', // Adjust the font size if necessary
    color: 'white', // Adjust the text color to match the theme
    fontWeight: 'bold', // Make the text bold (optional)
  },

}));

function capitalizeFirstLetter(passedstr: string) {
  return passedstr.charAt(0).toUpperCase() + passedstr.slice(1);
}

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};



function showComplianceValue(passedcomp: any)
{

    if (passedcomp === true)
    {
      let compliantDisplay = "Compliant";
      return compliantDisplay;
    }
    else if (passedcomp === false)
    {
      let compliantDisplay = "Non Compliant";
      return compliantDisplay;
    }
    else
    {
      let compliantDisplay = "Undetermined";
      return compliantDisplay;

    }

}



//const AssetPopup = ( {feature} ,clientid , siteid) => {
  const AssetPopup: React.FC<{
    feature: { feature: any }[] | null | undefined;
    clientid: string;
    siteid: string;
    files?: any;
    docs?: any;
  }> = ({ feature, clientid, siteid, files, docs }) => {
  
  const [taskData, setTaskData] = useState([]);
  const classes = useStyles(); // Call useStyles to get class names
  const [currentFile, setCurrentFile] = useState(null); // Selected file
  const [footageFound, setFootageFound] = useState( false )
  const [docsFound, setDocsFound] = useState( false )


  useEffect(() => {
    console.log("Passed Feature:", feature)
    const fetchTasks = async () => {
      try {
        const response = await fetch(`${process.env.API_ROOT}listings/tasks/${feature.properties.id}?initial=yes`, { method: 'GET', credentials: 'include' });
        const dataret = await response.json();
        const tasks = dataret.map((u: { taskid: any; status: any; tasktitle: any; idreadable: any; compliant: any; tasktype: any; internalresponsible: any; deadline: any; documentdeadline: any; asset: any; siteid:any;clientid:any;}) => ({
          taskid: u.taskid,
          status: u.status,
          tasktitle: u.tasktitle,
          idreadable: u.idreadable,
          compliant: u.compliant,
          tasktype: u.tasktype,
          internalresponsible: u.internalresponsible,
          deadline: u.deadline,
          documentdeadline: u.documentdeadline,
          asset:u.asset,
          siteid: u.siteid,
          clientid: u.clientid,
          tasklink:`${window.location.protocol}//${window.location.host}/client/${clientid}/tasks/${siteid}?search=${u.idreadable}`,
        }));
        console.log(tasks);
        setTaskData(tasks);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTasks();

  }, [feature]);
  
  
  useEffect(() => {
    console.log("Passed files:", files)
    if (files.length > 0)
    {
      setFootageFound(true)
    }
  }, [files]);

  useEffect(() => {
    console.log("Passed docs:", docs)
    if (docs.length > 0)
    {
      setDocsFound(true)
    }
  }, [docs]);


  return (
    <div>
    <div  style={{ display: 'flex', alignItems: 'center' }}>
      <h3>{feature.properties.assetname}</h3>  
      <Tooltip title='Create Task'> 
      <button style={{ marginLeft: '71%', background: 'none', border: 'none', cursor: 'pointer' }}
        onClick={() => window.open(`/client/${clientid}/tasks/${siteid}/add?assetId=${feature.properties.id}`, '_blank')}
      >
        <img src={AddTask} alt="Download Icon" style={{ width: '20px', height: '20px' }} />
      </button>
      </Tooltip>
    

      
      </div>
      <table>
        <tbody>
          <tr><td className={classes.alignLeft}><strong>Asset Type:</strong></td><td className={classes.alignLeft}>{feature.properties.assettype}</td><td>&nbsp;&nbsp;&nbsp;</td><td  className={classes.alignLeft}><strong> System Ref:</strong></td><td className={classes.alignLeft}>{feature.properties.systemname}</td></tr>        
        </tbody>
      </table>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.th}>Task</th>
            <th className={classes.th}>Title</th>
            <th className={classes.th}>Type</th>
            <th className={classes.th}>Status</th>
            <th className={classes.th}>Deadline</th>
            <th className={classes.th}>Compliant</th>
          </tr>
        </thead>
        <tbody>
          {taskData.length > 0 ? (
            taskData.map((task, index) => ( 
              <tr key={index}>
                <td className={classes.td}><a href={task.tasklink} target='_blank'>{task.idreadable}</a></td>
                <td className={classes.td}>{task.tasktitle}</td>
                <td className={classes.td}>{task.tasktype}</td>
                <td className={classes.td}>{capitalizeFirstLetter(task.status)}</td>
                <td className={classes.td}>{moment(new Date(task.deadline)).format('DD/MM/YYYY')}</td>
                <td className={classes.td}>{showComplianceValue(task.compliant)}</td>
     
              </tr>
            ))
          ) : (
            <tr>
              <td className={classes.td} colSpan="6">No tasks available</td>
            </tr>
          )}
        </tbody>
        </table>
        {/* Render the ExpansionPanel only if footageFound is true */}
        {footageFound && (
            <Accordion className={classes.accordion}>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }} />} // Icon color (white in this case)
              sx={{
                backgroundColor: '#9d9d9c', // Matches the Accordion
                color: '#ffffff', // Text color for the summary
              }}
                classes={{ content: classes.summaryContent }}
                className={classes.AccordionSummary}
              > <span className={classes.expandIconText}>  <img src={filmreelIcon} alt="Clips" /></span>
             
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
              {/* Left Section */}
              <div className={classes.splitLeft}>
                <ul className={classes.fileList}>
                  {files.map((file, index) => (
                    <li key={index}>
                      <button   className={classes.fileButton} onClick={() => setCurrentFile(file)}>
                        {file.displayname} &nbsp;&nbsp; {formatDate(file.uploaddate)}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Right Section */}
              <div className={classes.splitRight}>
                {currentFile ? (
                  <div className={classes.centered}>
                    <h4 className={classes.nowPlayingText}>Now Playing: {currentFile.displayname}</h4>
                    <ReactPlayer
                      url={currentFile.link}
                      controls
                      width="50%"
                      height="auto"
                      style={{ maxHeight: '100%', maxWidth: '100%' }}
                    />
                  </div>
                ) : (
                  <p className={classes.centered}>Select a file to play</p>
                )}
              </div>
            </AccordionDetails>

            </Accordion>
          )}
          {docsFound && (  

            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }} />} // Icon color (white in this case)
                sx={{
                  backgroundColor: '#9d9d9c', // Matches the Accordion
                  color: '#ffffff', // Text color for the summary
                }}
                classes={{ content: classes.summaryContent }}
                className={classes.AccordionSummary}
              > <span className={classes.expandIconText}>  <img src={docsLinkIcon} alt="Linked Docs" /></span>
             
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
              <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.th}>Document</th>
                  <th className={classes.th}>Cateogry</th>
                  <th className={classes.th}>Uploaded</th>
                </tr>
              </thead>
              <tbody>
                {docs.length > 0 ? (
                  docs.map((doc, index) => ( 
                    <tr key={index}>
                      <td className={classes.td}><a href={doc.link} target='_blank'>{doc.displayname}</a></td>
                      <td className={classes.td}>{capitalizeFirstLetter(doc.category)}</td>
                      <td className={classes.td}>{moment(new Date(doc.uploaddate)).format('DD/MM/YYYY')}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className={classes.td} colSpan="3">No docs available</td>
                  </tr>
                )}
              </tbody>
              </table>

             </AccordionDetails>

            </Accordion>



            )}
    </div>
  );
};

export default AssetPopup;
