import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import downloadExcelIcon from 'assets/downloadExcelTemplateGreen.svg';
import uploadExcelTemplate from 'assets/uploadExcelTemplate.svg';
import AppButton from 'components/AppButton';
import AppDialog from 'components/AppDialog';
import AppMenu from 'components/AppMenu';
import XlsxFileInput from 'components/FileInput/XlsxFileInput';
import useGetStaticFields from 'constants/staticFields';
import React, { useRef, useState } from 'react';
import useText from 'texts/useText.hook';
import { excelUtils } from 'utils/excel';
import useFileDownload from 'utils/useFileDownload';
import useFileUpload from 'utils/useFileUpload';

const styleRules = (theme: Theme) =>
  createStyles({
    menu: {
      padding: theme.spacing(3),
    },
    fileInput: {
      marginTop: theme.spacing(3),
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& > button': {
        width: 'min-content',
        minWidth: 'min-content',
        borderRadius: '10px',
        borderColor: '#E5E5E5 !important',
      },
    },
    wrapper: {
      display: 'flex',
      fontSize: '14px',
    },
    blockWrapper: {
      display: 'flex',
      flexDirection: 'column',
      '&:not(:last-child)': {
        marginRight: '80px',
      },
    },
    fileName: {
      margin: 0,
      color: '#44924D',
    },
    title: {
      margin: 0,
      marginBottom: '4px',
    },
    button: {
      padding: '8px',
      marginRight: '8px',
      '& > span': {
        width: '22px',
        height: '24px',
      },
    },
    missingLine: {
      margin: 0,
    },
    fileListItem: {
      textDecoration: 'underline',
      color: '#44924D',
    },
  });
const useStyles = makeStyles(styleRules);

export interface FileInfo {
  id: string;
  name: string;
  extension: string;
}
interface Props {
  fileName?: string;
  uploadedFileId: string;
  isMulti?: boolean;
  // eslint-disable-next-line
  formTemplate: Array<any>;
  handleExcelDownload: () => void;
  setFileInfo: (fileInfo: FileInfo) => void;
}

const ReportButtonsBlock: React.FC<Props> = ({
  isMulti,
  formTemplate,
  handleExcelDownload,
  fileName,
  uploadedFileId,
  setFileInfo,
}) => {
  const { t } = useText('form');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openAssetsImport, setOpenAssetsImport] = useState(false);
  const [importProcessing, setImportProcessing] = useState(false);
  const [notAcceptedFields, setNotAcceptedFields] = useState<Array<string>>([]);
  const [noFieldsInTemplate, setNoFieldsInTemplate] = useState('');
  const staticFields = useGetStaticFields();
  const uploadFile = useFileUpload();
  const styles = useStyles();

  const staticFieldsKeys = staticFields.map((field) => field.key);

  const validateImportingFile = async (file: File) => {
    const book = await excelUtils.fileToBook(file);
    const fieldNamesFromForm: Array<string> = formTemplate?.map((template) => template.parameterName) || [];
    const fieldNamesFromExcel = [...new Set(excelUtils.parseFieldNamesFromExcel(book))];
    const errorsFields: Array<string> = [];

    if (!fieldNamesFromExcel.length) {
      setNoFieldsInTemplate(t('form')('noFields'));
    }

    fieldNamesFromExcel.forEach((fieldName) => {
      let isFilesFieldValid = true;
      let isValueAccepted;

      if (fieldName.includes('Files') || fieldName.includes('Signature')) {
        const words = fieldName.split(' ');
        words.map((word, index) => {
          if (index === 0) {
            isFilesFieldValid = ['Signature', 'Files'].includes(word);
          } else {
            isFilesFieldValid = Number.isInteger(Number(word));
          }
          return isFilesFieldValid;
        });
        isValueAccepted = isFilesFieldValid;
      } else {
        isValueAccepted = [...staticFieldsKeys, ...fieldNamesFromForm].includes(fieldName);
      }

      if (!isValueAccepted) {
        errorsFields.push(fieldName);
      }
    });
    setNotAcceptedFields(errorsFields);
    return !!errorsFields.length;
  };

  const importFormTemplate = async (file: File) => {
    setImportProcessing(true);
    const isErrorsExist = await validateImportingFile(file);
    if (!isErrorsExist) {
      const newFileInfo = await uploadFile(file);
      setFileInfo(newFileInfo);
    }
    setImportProcessing(false);
    setOpenAssetsImport(false);
  };

  const downloadFile = useFileDownload();

  return (
    <div className={styles.wrapper}>
      <div className={styles.blockWrapper}>
        <p className={styles.title}>{isMulti ? 'Multi Report' : 'Single Report'}</p>
        <div className={styles.buttonsWrapper}>
          <AppButton
            type="button"
            variant="outlined"
            disableFocusRipple
            disableRipple
            className={styles.button}
            onClick={handleExcelDownload}
          >
            <img src={downloadExcelIcon} alt="Download excel" />
          </AppButton>
          <AppButton
            ref={buttonRef}
            type="button"
            variant="outlined"
            disableFocusRipple
            disableRipple
            className={styles.button}
            onClick={() => setOpenAssetsImport(true)}
          >
            <img alt="upload template" src={uploadExcelTemplate} />
          </AppButton>
        </div>
      </div>
      <div className={styles.blockWrapper}>
        <p className={styles.title}>Uploaded File</p>
        {fileName ? (
          <button type="button" onClick={() => downloadFile(uploadedFileId)} className={styles.fileListItem}>
            {fileName}
          </button>
        ) : (
          <p className={styles.fileName}>Not upload any templates yet</p>
        )}
      </div>

      <AppMenu
        horizontalPosition="left"
        anchorEl={buttonRef.current}
        open={openAssetsImport}
        onClose={() => setOpenAssetsImport(false)}
      >
        <div className={styles.menu}>
          <Typography variant="h3">{t('form')('saveChanges')}</Typography>
          <div className={styles.fileInput}>
            <XlsxFileInput isLoading={importProcessing} onLoad={importFormTemplate} />
          </div>
        </div>
      </AppMenu>

      <AppDialog
        open={!!notAcceptedFields.length}
        onClose={() => {
          setNotAcceptedFields([]);
        }}
        type="error"
        title={t('form')('someTokensMissing')}
      >
        {t('form')('fieldWillNotBeShown')}
        {notAcceptedFields.map((errorField) => (
          <p key={errorField} className={styles.missingLine}>
            {errorField}
          </p>
        ))}
      </AppDialog>

      <AppDialog
        open={!!noFieldsInTemplate}
        onClose={() => setNoFieldsInTemplate('')}
        type="warning"
        title={t('form')('noTokens')}
      >
        <p className={styles.missingLine}>{noFieldsInTemplate}</p>
      </AppDialog>
    </div>
  );
};

export default ReportButtonsBlock;
