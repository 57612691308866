import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import useText from 'texts/useText.hook';
import AppTable, { AppTableFilters } from 'components/AppTable';
import {
  drawingsForSite,
  drawingsForSiteVariables,
  AssetType,
  SystemType,
  drawingsForSite_site_drawings as drawingsForSiteSiteDrawings,
  drawingsForSite_site_drawings_assets as drawingsForSiteSiteDrawingsAssets,
} from 'models/graphql';
import useSearchParams from 'utils/useSearchParams';
import useUpdateSearchParams from 'utils/useUpdateSearchParams';
import { Typography } from '@mui/material';
import ListMapSwitch from './components/ListMapSwitch';
import { GET_SITE_DRAWINGS } from './graphql';

// FW 2022-02-17 button icons for linking to maps/assets  /* eslint-disable-line */
import AppIconButton from 'components/AppIconButton';/* eslint-disable-line */
import { Theme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
/* eslint-disable-line */
import listViewIconGrey from 'assets/listViewIconGrey.svg';/* eslint-disable-line */
import mapViewIconGrey from 'assets/mapViewIconGrey.svg';/* eslint-disable-line */
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  actionButtons: {
    marginLeft: theme.spacing(2),
  },
}));

const DrawingsPage: React.FC = () => {
  const { clientId, siteId } = useParams<{ clientId: string; siteId: string }>();
  const { t, tt } = useText('assetTypes', 'drawings', 'systemTypes', 'urls');
  const [search, typeParam, systemTypeParam, systemParam] = useSearchParams(
    tt('urls')('queries')('search'),
    tt('urls')('queries')('type'),
    tt('urls')('queries')('systemType'),
    tt('urls')('queries')('system'),
  );
  const updateSearchParams = useUpdateSearchParams();
  const navigate = useNavigate();
  const { data } = useQuery<drawingsForSite, drawingsForSiteVariables>(GET_SITE_DRAWINGS, {
    variables: {
      siteId,
      search,
      type: AssetType[typeParam as AssetType],
      systemRef: systemParam && systemParam === 'all' ? null : systemParam,
      systemType: SystemType[systemTypeParam as SystemType],
      hasAsset: true,
    },
  });
  const [drawings, setDrawings] = useState<drawingsForSiteSiteDrawings[] | undefined>();

  const getSystem = (asset: drawingsForSiteSiteDrawingsAssets) => asset.system;
  const getAssets = (drawing: drawingsForSiteSiteDrawings) => drawing.assets;
  const _drawings = drawings || [];
  const systems = R.pipe(R.map(R.pipe(getAssets, R.map(getSystem))), R.flatten, R.uniq)(_drawings);

  // FW 2022-02-18
  const { actionButtons } = useStyles();

  useEffect(() => {
    setDrawings(data?.site.drawings);
  }, [data?.site.drawings]);

  return (
    <>
      <AppTableFilters
        searchPlaceholder={t('drawings')('searchPlaceholder')}
        onSubmit={async ({ search: newSearch, type, systemType, system }) => {
          setDrawings(undefined);
          navigate(
            updateSearchParams({
              [tt('urls')('queries')('search')]: newSearch,
              [tt('urls')('queries')('type')]: type || 'all',
              [tt('urls')('queries')('systemType')]: systemType || 'all',
              [tt('urls')('queries')('system')]: system || 'all',
            }),
          );
        }}
        searchValue={search}
        addNewButton={
          <>
          <AppIconButton
            className={actionButtons}
            onClick={() => navigate(`/client/${clientId}/assets/${siteId}`)}
          >
            <img alt="systems" src={listViewIconGrey} />
          </AppIconButton>
          <AppIconButton
            className={actionButtons}
            onClick={() => navigate(`/client/${clientId}/assets/${siteId}/mapping`)}
          >
            <img alt="systems" src={mapViewIconGrey} />
          </AppIconButton>
        </>
        }
        filters={[
          {
            name: 'systemType',
            options: {
              default: systemTypeParam || 'all',
              array: ['all', ...Object.keys(SystemType).map((key) => SystemType[key as SystemType])],
              key: (value) => value as string,
              value: (value) => value as string,
              template: (value) =>
                SystemType[value as SystemType] === undefined
                  ? tt('drawings')('filter')('allSystemTypes')
                  : t('systemTypes')(value as SystemType),
            },
          },
          {
            name: 'system',
            options: {
              default: systemParam || 'all',
              array: ['all', ...systems],
              key: (value: string | { id: string; name: string }) => (typeof value === 'string' ? value : value.id),
              value: (value: string | { id: string; name: string }) => (typeof value === 'string' ? value : value.id),
              template: (value: string | { id: string; name: string }) =>
                typeof value === 'string' ? tt('drawings')('filter')('allSystems') : value.name,
            },
          },
          {
            name: 'type',
            options: {
              default: typeParam || 'all',
              array: ['all', ...Object.keys(AssetType).map((key) => AssetType[key as AssetType])],
              key: (value) => value as string,
              value: (value) => value as string,
              template: (value) =>
                AssetType[value as AssetType] === undefined
                  ? tt('drawings')('filter')('allTypes')
                  : t('assetTypes')(value as AssetType),
            },
          },
        ]}
        hideShowArchived
      />
      <AppTable
        data={drawings}
        loading={!drawings}
        noDataMessage={t('drawings')('noDrawings')}
        doubleLineActions
        tableInfoLine={
          drawings && (
            <Typography>
              {drawings.length} {drawings.length !== 1 ? t('drawings')('drawings') : t('drawings')('drawing')}
            </Typography>
          )
        }
        columns={[
          {
            name: 'Site',
            template: (row) => row.site.name,
          },
          {
            name: 'Drawing ID',
            template: (row) => row.drawingId,
          },
          {
            name: 'File name',
            template: function DrawingsFileLink(row) {
              return <Link to={`/client/${clientId}/assets/${siteId}/drawing/${row.id}`}>{row.file.originalName}</Link>;
            },
          },
          {
            name: 'System type',
            template: (row) => t('systemTypes')(row.systemType),
          },
          {
            name: 'System Ref',
            template: (row) =>
              R.pipe(
                R.map((asset: drawingsForSiteSiteDrawingsAssets) => asset.system.name),
                R.groupBy(R.identity),
                R.toPairs,
                R.map((sysRef) => (sysRef[1].length > 1 ? `${sysRef[1].length}x ${sysRef[0]}` : sysRef[0])),
                R.join(', '),
              )(row.assets),
          },
          {
            name: 'Asset type',
            template: (row) =>
              R.pipe(
                R.map((asset: drawingsForSiteSiteDrawingsAssets) => t('assetTypes')(asset.type)),
                R.groupBy(R.identity),
                R.toPairs,
                R.map((type) => (type[1].length > 1 ? `${type[1].length}x ${type[0]}` : type[0])),
                R.join(', '),
              )(row.assets),
          },
          {
            name: 'No of linked assets',
            template: (row) => row.assets.length,
          },
        ]}
      />
    </>
  );
};
export default DrawingsPage;
