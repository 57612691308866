import React from 'react';
import { useFormikContext } from 'formik';
import objectPropertyByString from 'utils/objectPropertyByString';
import { Checkbox, CheckboxProps } from '@mui/material';

const AppCheckboxField: React.FC<Omit<CheckboxProps, 'name'> & { name: string }> = ({
  name,
  color,
  onChange: onChangeProp,
  ...propsToPass
}) => {
  const { values, setFieldValue } = useFormikContext<Record<string, unknown>>();

  return (
    <Checkbox
      name={name}
      color={color || 'primary'}
      checked={!!objectPropertyByString(values, name)}
      onChange={(event, checked) => {
        setFieldValue(name, checked);
        if (onChangeProp) {
          onChangeProp(event, checked);
        }
      }}
      {...propsToPass}
    />
  );
};

export default AppCheckboxField;
