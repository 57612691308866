import { useMutation, useQuery } from '@apollo/client';
import React from 'react';

import { useStores } from 'stores';
import TaskPanel from 'containers/shared/Tasks/TaskPanel';
import {
  taskResponsibleAccept,
  taskResponsibleAcceptVariables,
  taskResponsibleReject,
  taskResponsibleRejectVariables,
  taskAccept,
  taskAcceptVariables,
  taskForClient,
  taskForClientVariables,
  taskReject,
  taskRejectVariables,
  TaskStatus,
} from 'models/graphql';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import useFileDownload from 'utils/useFileDownload';
import TaskController from 'containers/shared/Technician/TaskDetails/TaskController';
import { ACCEPT_TASK as ACCEPT_TASK_RESP, REJECT_TASK as REJECT_TASK_RESP } from 'containers/shared/graphql';
import { ACCEPT_TASK, GET_TASK, REJECT_TASK } from './graphql';
import TaskAcceptOrDeny from './TaskAcceptOrDeny';
import { useParams } from 'react-router';

const TaskDetailsOnExpand: React.FC<{ taskId: string }> = ({ taskId }) => {
  const { clientId } = useParams<{
    clientId: string;
  }>();
  const download = useFileDownload();

  // Stores
  const { authStore } = useStores();

  const TIMER_SIZE = 60;

  // Queries
  const { data: taskData, loading: taskDataLoading } = useQuery<taskForClient, taskForClientVariables>(GET_TASK, {
    variables: { taskId },
  });
  const task = taskData?.task;

  // Approval Mutations
  const [accept] = useMutation<taskAccept, taskAcceptVariables>(ACCEPT_TASK, {
    update: (cache, { data }) => {
      if (!data?.taskAccept.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.taskAccept.id },
      });
    },
  });
  const [reject] = useMutation<taskReject, taskRejectVariables>(REJECT_TASK, {
    update: (cache, { data }) => {
      if (!data?.taskReject.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.taskReject.id },
      });
    },
  });

  // Accept Mutations
  const [acceptResp] = useMutation<taskResponsibleAccept, taskResponsibleAcceptVariables>(ACCEPT_TASK_RESP, {
    update: (cache, { data }) => {
      if (!data?.taskResponsibleAccept.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.taskResponsibleAccept.id },
      });
    },
  });
  const [rejectResp] = useMutation<taskResponsibleReject, taskResponsibleRejectVariables>(REJECT_TASK_RESP, {
    update: (cache, { data }) => {
      if (!data?.taskResponsibleReject.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.taskResponsibleReject.id },
      });
    },
  });

  return (
    <TaskPanel
      task={task}
      loading={taskDataLoading}
      assetLink={(assetId: string, siteId: string) => `/client/${clientId}/assets/${siteId}?asset=${assetId}`}
      onDownload={download}
      acceptOrDeny={
        (task?.approval?.id === authStore?.user?.id && task?.status === TaskStatus.SUPP_INFO_SUBMITTED) ||
        (task?.internalResponsible?.id === authStore?.user?.id && task && task?.status === TaskStatus.SENT) ? (
          <TaskAcceptOrDeny
            task={task}
            accept={task?.status === TaskStatus.SENT ? acceptResp : accept}
            reject={task?.status === TaskStatus.SENT ? rejectResp : reject}
          />
        ) : undefined
      }
      timer={
        task?.internalResponsible?.id === authStore?.user?.id &&
        (task?.status === TaskStatus.CONTRACTOR_ACCEPTED ||
          task?.status === TaskStatus.IN_PROGRESS ||
          task?.status === TaskStatus.COMPLETED) ? (
          <TaskController size={TIMER_SIZE} task={task!} desktop />
        ) : undefined
      }
    />
  );
};

export default TaskDetailsOnExpand;
