import { useEffect } from "react";
import { useMap } from "react-leaflet";
import useSearchParams from "utils/useSearchParams";
import { geosearch, arcgisOnlineProvider, featureLayerProvider } from 'esri-leaflet-geocoder';

// Following can be replaced with environment values
const ESRIapiKey = "AAPKb370089674f84eb78c8d5cb5b8ea80f5B7zXi9Uopm2gFPBXuhAZARH3ew-oUJYetJPPC1haLxJs-5fVHzzBG_KlS8i8RBWi";
const MAPBOX_API_KEY = "pk.eyJ1IjoiZmVyZ3doMXRlIiwiYSI6ImNremxteDQ4bDB0YXUyb3FycmVraWs1MjgifQ.fZkx9Ydl_MTzZGLUyCyAvg";
const MAPBOX_USERID = "fergwh1te";
const MAPBOX_STYLEID = "ckzps5ytf00n714rwo1hugf9y";


const LeafletControlGeocoder = (props) => {
  const { clientId } = props;
  const map = useMap();
  const [latParam, longParam] = useSearchParams('lat', 'long');

  const resultsSearch = new L.LayerGroup();

  const acmeTest = featureLayerProvider({
    url: 'https://services9.arcgis.com/j2zg3pDVaHZ5RF2d/arcgis/rest/services/assets/FeatureServer/0',
    searchFields: ['assetname'], // Search these fields for text matches
    label: 'Assets', // Group suggestions under this header
    where: ` companyid='${clientId}'`,
    formatSuggestion: function (feature) {
      return feature.properties.assetname; // format suggestions like this.
    }
  });

  function handleOnSearchResuts(data) {
    // Clear previous results
    resultsSearch.clearLayers();
    const latlng = data.latlng;
    const marker = L.marker(latlng);
    marker.bindPopup(`<p>${data.text}</p>`);
    marker.addTo(resultsSearch);
  }

  useEffect(() => {
    const groups = {
      assets: new L.LayerGroup()
    };
    // Define the layers, these could be moved elsewhere
    const Stamen_Toner = L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}', {
      attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      subdomains: 'abcd',
      minZoom: 0,
      maxZoom: 20,
      ext: 'png'
    });

    const Esri_WorldTopoMap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
    });
    
    const Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });
    
    const OpenTopoMap = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
      maxZoom: 22,
      maxNativeZoom: 20,
      attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    });
    
    const OpenStreetMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 22,
      maxNativeZoom: 20,
      attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> '
    });
    
    const OpenStreetMapCARTO = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png', {
      maxZoom: 22,
      maxNativeZoom: 20,
      attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> '
    });
    
    const MapBoxStreets = L.tileLayer(`https://api.mapbox.com/styles/v1/${MAPBOX_USERID}/${MAPBOX_STYLEID}/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_API_KEY}`, {
      maxZoom: 22,
      maxNativeZoom: 20,
      attribution: 'Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery © <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>'
    });
    
    //  End of Map Layers Definitions
    
    const mcg = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      spiderLegPolylineOptions: {
        color: '#ffc40c',
        opacity: 0
      }
    });

    const baseMaps = {
      "OpenStreetMap": OpenStreetMap,
      "OpenSteetMap (CARTO)": OpenStreetMapCARTO,
      "MapBox Streets": MapBoxStreets,
      "OpenTopo": OpenTopoMap,
      "Black & White": Stamen_Toner,
      "ESRI Topo": Esri_WorldTopoMap,
      "ESRI Imagery": Esri_WorldImagery
    };
  
    L.control.layers(baseMaps);

    const control = geosearch({
      position: "topleft",
      placeholder: "Enter an address or place",
      useMapBounds: false,
      providers: [arcgisOnlineProvider({
        apikey: ESRIapiKey
      }), acmeTest]
    });
    control.addTo(map);
    // Add Layer Group to Map so it can be cleared on a new search
    resultsSearch.addTo(map)
    control.on('results', handleOnSearchResuts);

    map.addLayer(OpenStreetMap)

    // cater for zoom and position if clicked on asset
    if (latParam && longParam) {
      map.setView([+latParam, +longParam], 20)
    }
    
    return () => {
      control.off('results', handleOnSearchResuts);
    }
  }, []);

  return null;
}

export default LeafletControlGeocoder;