import React from 'react';
import cn from 'classnames';
import { createStyles, makeStyles } from '@mui/styles';
import { IconButton, IconButtonProps, Theme } from '@mui/material';

const styleRules = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    border: {
      borderRadius: theme.shape.borderRadius,
      '&, &:focus': {
        border: `solid 1px ${theme.palette.grey['300']}`,
      },
    },
  });
const useStyles = makeStyles(styleRules);

interface Props {
  noBorder?: boolean;
}

const AppIconButton: React.FC<IconButtonProps & Props> = ({ noBorder, children, className, ...propsToPass }) => {
  const styles = useStyles();

  return (
    <IconButton
      className={cn(styles.root, className, noBorder ? null : styles.border)}
      {...propsToPass}
      size="large">
      {children}
    </IconButton>
  );
};

export default AppIconButton;
