import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Formik, FieldArray } from 'formik';
import Box from '@mui/material/Box';

import useText from 'texts/useText.hook';
import AppTable, { AppTableFilters } from 'components/AppTable';
import usePaginationParams from 'utils/usePaginationParams';    // FW Update 2022-04-11 For Asset Pagination
import {
  assetsForSite,
  assetsForSiteVariables,
  assetsForSite_site_assets as assetsForSiteSiteAssets,
  AssetType,
  SystemType,
  getAssetFilterBySite,
  getAssetFilterBySiteVariables,
  CompliantStatus,
  assetsForSitePaged,         // FW 2022-04-10 For Assets Paged
  assetsForSitePaged_site_assets as assetsForSiteSiteAssetsPaged,   // FW 2022-04-10 For Assets Paged
  assetsForSitePagedVariables,    // FW 2022-04-10 For Assets Paged
  assetsForSitePaged_site_assets_nodes as assetsForSiteSiteAssetsNodes     // FW 2022-04-10 For Assets Paged
} from 'models/graphql';
import useSearchParams from 'utils/useSearchParams';
import useUpdateSearchParams from 'utils/useUpdateSearchParams';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NFCScanner from 'components/NFCScanner';
import useGetUser from 'utils/useGetUser.hook';
import AssetDetailsPanel from '../../Client/Assets/AssetDetailsPanel';
import GET_SITE_ASSETS, { GET_ASSET_FILTER_BY_SITE } from '../../Client/Assets/graphql';
import {GET_SITE_ASSETS_PAGED} from '../../Client/Assets/graphql';     // FW 2022-04-10 For Assets Paged
import { Navigate, useNavigate, useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  assetName: {
    display: 'flex',
    flexDirection: 'column',
  },
  systemName: {
    paddingTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
}));

interface FormValues {
  selectAll: boolean;
  assetSelections: { id: string; selected: boolean }[];
}

// TODO: combine with technician?
const AssetsPage: React.FC = () => {
  const classes = useStyles();
  const { siteId, userId } = useParams<{ siteId: string; userId: string }>();
  const userData = useGetUser(userId);

  const { t, tt } = useText('assets', 'assetTypes', 'clients', 'compliance', 'systemTypes', 'tasks', 'urls');
  const [search, assetParam, typeParam, systemTypeParam, systemRefsParam, ownerParam] = useSearchParams(
    tt('urls')('queries')('search'),
    tt('urls')('queries')('asset'),
    tt('urls')('queries')('type'),
    tt('urls')('queries')('systemType'),
    tt('urls')('queries')('systemRef'),
    tt('urls')('queries')('owner'),
  );
  const updateSearchParams = useUpdateSearchParams();
  const navigate = useNavigate();
  
  const [assetsNFC, setAssetsForNFC] = useState<assetsForSiteSiteAssets[] | undefined>();
  const [assets, setAssets] = useState<assetsForSiteSiteAssetsPaged | undefined>();  // FW 2022-04-11
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:700px)');
  const [openNFC, setOpenNFC] = React.useState(false);

  //const { data, loading } = useQuery<assetsForSite, assetsForSiteVariables>(GET_SITE_ASSETS, {
    const { data, loading } = useQuery<assetsForSitePaged, assetsForSitePagedVariables>(GET_SITE_ASSETS_PAGED, {
    variables: {
      siteId,
      pagination: usePaginationParams(),
      search,
      type: AssetType[typeParam as AssetType],
      systemType: SystemType[systemTypeParam as SystemType],
      systemRef: systemRefsParam && systemRefsParam === 'all' ? null : systemRefsParam,
      owner: ownerParam && ownerParam === 'all' ? null : ownerParam,
    },
  });

  const { data: filter } = useQuery<getAssetFilterBySite, getAssetFilterBySiteVariables>(GET_ASSET_FILTER_BY_SITE, {
    variables: {
      siteId,
    },
  });

/* useEffect(() => {
    setAssets(data?.site.assets);
  }, [data?.site.assets]); */
  useEffect(() => {
    setAssets(data?.site.pageassets);
  }, [data?.site.pageassets]);

  if (!userData?.fullAccess) {
    return <Navigate to={'/'} replace />
  }

  return (
    <Formik<FormValues>
      initialValues={{
        selectAll: false,
        assetSelections: [],
      }}
      onSubmit={() => {
        // do nothing
      }}
    >
      {({ values, setFieldValue }) => {
        if (values.assetSelections.length === 0 && assets?.nodes.length) {
          setFieldValue(
            'assetSelections',
            assets.nodes.map((asset) => ({
              id: asset.id,
              selected: false,
            })),
          );
        }

        return (
          <>
            <AppTableFilters
              searchPlaceholder={t('assets')('searchPlaceholder')}
              onSubmit={async ({ search: newSearch, type, systemType, owner, systemRef }) => {
                setFieldValue('assetSelections', []);
                navigate(
                  updateSearchParams({
                    [tt('urls')('queries')('asset')]: null,
                    [tt('urls')('queries')('search')]: newSearch,
                    [tt('urls')('queries')('type')]: type || 'all',
                    [tt('urls')('queries')('systemType')]: systemType || 'all',
                    [tt('urls')('queries')('systemRef')]: systemRef || 'all',
                    [tt('urls')('queries')('owner')]: owner || 'all',
                  }),
                );
              }}
              searchValue={search}
              addNewButton={<NFCScanner openNFC={openNFC} setOpenNFC={setOpenNFC} setAssets={setAssets} />}
              filters={[
                {
                  name: 'type',
                  options: {
                    default: typeParam || 'all',
                    array: filter ? ['all', ...filter?.assetFilter.assetsTypes] : [],
                    key: (value) => value as string,
                    value: (value) => value as string,
                    template: (value) =>
                      AssetType[value as AssetType] === undefined
                        ? tt('assets')('filter')('allTypes')
                        : t('assetTypes')(value as AssetType),
                  },
                },
                {
                  name: 'systemType',
                  options: {
                    default: systemTypeParam || 'all',
                    array: filter ? ['all', ...filter?.assetFilter.systemTypes] : [],
                    key: (value) => value as string,
                    value: (value) => value as string,
                    template: (value) =>
                      SystemType[value as SystemType] === undefined
                        ? tt('assets')('filter')('allSystemTypes')
                        : t('systemTypes')(value as SystemType),
                  },
                },
                {
                  name: 'owner',
                  options: {
                    default: ownerParam || 'all',
                    array: filter ? ['all', ...filter?.assetFilter.owners] : [],
                    key: (value: string | { id: string; name: string }) =>
                      typeof value === 'string' ? value : value.id,
                    value: (value: string | { id: string; name: string }) =>
                      typeof value === 'string' ? value : value.id,
                    template: (value: string | { id: string; name: string }) =>
                      typeof value === 'string' ? tt('assets')('filter')('allOwners') : value.name,
                  },
                },
                {
                  name: 'systemRef',
                  options: {
                    default: systemRefsParam || 'all',
                    array: filter ? ['all', ...filter?.assetFilter.systemRefs] : [],
                    key: (value) => value as string,
                    value: (value) => value as string,
                    template: (value) =>
                      value === 'all' ? tt('assets')('filter')('allSystemRefs') : (value as string),
                  },
                },
              ]}
              hideShowArchived
            />
            <FieldArray
              name="assetSelections"
              render={() => (
                <AppTable
                  // data={assets}
                  dataPaginated={assets}
                  loading={loading}
                  noDataMessage={t('clients')('noAssets')}
                  activeRowId={assetParam}
                  doubleLineActions
                  tableInfoLine={
                    assets && 
                    assets.totalNodes && (
                      <Typography>
                        {assets.totalNodes} {assets.totalNodes !== 1 ? t('assets')('assets') : t('assets')('asset')}
                      </Typography>
                    )
                  }
                  detailsPanel={(row) => ({
                    title: row.name || '',
                    template: <AssetDetailsPanel asset={row} />,
                  })}
                  columns={
                    isMobile
                      ? [
                          {
                            name: 'Asset Name',
                            // eslint-disable-next-line react/display-name
                            template: (row) => (
                              <Box className={classes.assetName}>
                                <Box>{row.name}</Box>
                                <Box className={classes.systemName}>{row.system.name}</Box>
                              </Box>
                            ),
                          },
                          {
                            name: 'Asset Type',
                            template: (row) => t('assetTypes')(row.type),
                          },
                        ]
                      : [
                          {
                            name: 'Asset Name',
                            template: (row) => row.name,
                          },
                          {
                            name: 'Asset Type',
                            template: (row) => t('assetTypes')(row.type),
                          },
                          {
                            name: 'System Ref',
                            template: (row) => row.system.name,
                          },
                          {
                            name: 'System type',
                            template: (row) => t('systemTypes')(row.system.type),
                          },
                        ]
                  }
                  expandable={isMobile || isTablet}
                />
              )}
            />
          </>
        );
      }}
    </Formik>
  );
};
export default AssetsPage;
