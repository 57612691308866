import base64js from 'base64-js';
import imageFileExtensions from 'constants/imageFileExtensions';
import useFileSrc from './useFileSrc';
import imageFromCanvas from './imageFromCanvas';

const useGetBase64ByUrl = () => {
  const getFileSrc = useFileSrc();

  return async (fileId: string) => {
    const fileInfo = fileId ? await getFileSrc(fileId) : null;

    if (fileInfo) {
      const fetchedFile = await fetch(fileInfo.url);
      const imageBuffer = await fetchedFile.arrayBuffer();
      const imageInUint8View = new Uint8Array(imageBuffer);
      const ImageBase64 = base64js.fromByteArray(imageInUint8View);
      const imageBase64FromCanvas = imageFileExtensions.includes(fileInfo.extension)
        ? await imageFromCanvas(ImageBase64, fileInfo.extension)
        : ImageBase64;
      return {
        imageInBase64: imageBase64FromCanvas,
        extension: fileInfo.extension,
        url: fileInfo.url,
      };
    }
    return undefined;
  };
};

export default useGetBase64ByUrl;
