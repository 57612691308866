import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import AppAvatar from 'components/AppAvatar';
import AppButton from 'components/AppButton';
import { FullScreenPage } from 'components/layout';
import { useStores } from 'stores';
import useText from 'texts/useText.hook';
import useMode, { Mode } from 'utils/useMode.hook';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    textAlign: 'center',
  },
  userData: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
  userDataContent: {
    marginLeft: theme.spacing(1),
  },
  userDataEmail: {
    fontSize: theme.fontSizes[12],
    color: theme.palette.grey[500],
  },
  logoutButton: {
    width: '100%',
    maxWidth: '300px',
    marginTop: theme.spacing(5),
  },
}));

const AccountPage: React.FC = () => {
  const { mode } = useMode();
  const {
    authStore: { user, logout },
  } = useStores();
  const client = useApolloClient();

  const { t } = useText('common');
  const styles = useStyles();

  const onCancel = (role: Mode): string => {
    switch (role) {
      case Mode.OPERATIVE:
        return '/operative/home';
      case Mode.TECHNICIAN:
        return '/technician/home';
      case Mode.PM:
      case Mode.CLIENT:
      case Mode.CONTRACTOR:
        return '/';
      default:
        return '/';
    }
  };

  return (
    <FullScreenPage title={t('common')('account')} cancel={onCancel(mode)}>
      {user ? (
        <div className={styles.container}>
          <div className={styles.userData}>
            <div>
              <AppAvatar imageSrc={user.picture?.srcUrl} name={user.name || ''} />
            </div>
            <div className={styles.userDataContent}>
              <div>{user.name}</div>
              <div className={styles.userDataEmail}>{user.email}</div>
            </div>
          </div>

          <AppButton className={styles.logoutButton} variant="outlined" onClick={() => logout(client)}>
            {t('common')('logout')}
          </AppButton>
        </div>
      ) : null}
    </FullScreenPage>
  );
};

export default AccountPage;
