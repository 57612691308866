import {
  CircularProgress,
  FormControlLabel,
  Theme,
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AppFieldError } from 'components/form';
import useFieldStyles from 'components/form/AppField.style';
import imageFileExtensions from 'constants/imageFileExtensions';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import styleUtils from 'style/styleUtils';
import { asyncMap } from 'utils/asyncArray';
import FileListToArrayOfFile from 'utils/FileListToArrayOfFile';
import objectPropertyByString from 'utils/objectPropertyByString';
import useArrayOfObjectsState from 'utils/useArrayOfObjectsState';
import useFileDownload from 'utils/useFileDownload';
import useFileSrc from 'utils/useFileSrc';
import useFileUpload from 'utils/useFileUpload';
import { CloseRounded, SaveRounded } from '@mui/icons-material';

const inputSize = 40;
const useStyles = makeStyles((theme: Theme) => {
  const imageMaxWidth = 90;
  const imageMaxHeight = 60;

  return createStyles({
    inputHidden: {
      display: 'none',
    },
    filesContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    fakeInput: {
      position: 'relative',
      width: inputSize,
      height: inputSize,
      ...styleUtils.rowCenterCenter,
      background: theme.palette.grey[100],
      borderRadius: '50%',
    },
    inputTransparent: {
      ...styleUtils.absoluteFill,
      opacity: 0,
      zIndex: 1,
    },
    fileButton: {
      position: 'relative',
      margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
    },
    imageFile: {
      display: 'block',
      maxWidth: imageMaxWidth,
      maxHeight: imageMaxHeight,
    },
    fileNonDisplayable: {
      ...styleUtils.rowCenterCenter,
      width: imageMaxWidth,
      height: imageMaxHeight,

      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      background: theme.palette.grey[300],
    },
  });
});

interface UploadedFile {
  id: string;
  extension: string;
  src?: string | undefined;
}

const FileInputPreviewUpload: React.FC<{
  addFileIconSrc?: string;
  disableAdd?: boolean;
  name: string;
  multiple?: boolean;
  label?: string;
  required?: boolean;
  onFileClick?: () => void;
  onInputClick?: () => void;
}> = ({ addFileIconSrc, disableAdd, name, multiple, label, required, onFileClick, onInputClick }) => {
  // FormikContext
  const { values: formValues, setFieldValue, touched, errors } = useFormikContext<Record<string, unknown>>();
  const formValue = objectPropertyByString(formValues, name);

  const [selectedFile, setSelectedFile] = React.useState<UploadedFile | null>(null);

  // Uploaded files
  const getFileSrc = useFileSrc();
  const fileDownload = useFileDownload();
  const [files, addFile, removeFile, setFiles] = useArrayOfObjectsState<{
    id: string;
    extension: string;
    src?: string;
  }>();

  // Upload process
  const fileUpload = useFileUpload();
  const [filesLoading, setFilesLoading] = useState(false);
  const onFileChange = async ({ target }: { target: HTMLInputElement }) => {
    if (!target.files) return;
    const filesToUpload = FileListToArrayOfFile(target.files);

    setFilesLoading(true);
    await asyncMap(filesToUpload, async (file) => {
      const { id, extension } = await fileUpload(file);
      addFile({
        id,
        extension,
        src: imageFileExtensions.includes(extension) ? (await getFileSrc(id))?.url : undefined,
      });
    });

    if (!multiple && files.length) {
      removeFile(files[0].id);
    }

    setFilesLoading(false);
  };

  // Init files
  useEffect(() => {
    if (multiple) {
      if (formValue && formValue.length && !files.length) {
        setFilesLoading(true);
        asyncMap(formValue, async (fileId: string) => {
          const file = await getFileSrc(fileId);
          if (!file) throw new Error(`Cannot get file src for file id: ${fileId}`);
          return {
            id: fileId,
            extension: file.extension,
            src: imageFileExtensions.includes(file.extension) ? file.url : undefined,
          };
        }).then((_files) => {
          setFiles(_files);
          setFilesLoading(false);
        });
      }
    } else if (formValue) {
      if (!files.length || !files.find((file) => file.id === formValue)) {
        setFilesLoading(true);
        getFileSrc(formValue).then((file) => {
          if (file) {
            setFiles([{ id: formValue, extension: file?.extension, src: file?.url }]);
            setFilesLoading(false);
          }
        });
      }
    }
  }, [formValue, files, getFileSrc, multiple, setFiles]);

  // Update Formik value with files
  useEffect(() => {
    if (multiple) {
      setFieldValue(
        name,
        files.map((uploadedFile) => uploadedFile.id),
      );
    } else {
      setFieldValue(name, files.length ? files[0].id : null);
    }
  }, [setFieldValue, name, multiple, files]);

  const styles = useStyles();
  const fieldStyles = useFieldStyles();
  return (
    (<div>
      {label ? (
        <FormControlLabel
          labelPlacement="top"
          className={fieldStyles.label}
          label={label ? label + (required ? '*' : '') : undefined}
          control={
            !disableAdd && !onInputClick ? (
              <input className={styles.inputHidden} type="file" multiple={multiple} onChange={onFileChange} />
            ) : (
              <></>
            )
          }
          onClick={onInputClick ? () => onInputClick() : undefined}
        />
      ) : null}
      <div className={styles.filesContainer}>
        {!disableAdd && !onInputClick && addFileIconSrc && (multiple || (!filesLoading && !files.length)) ? (
          <div className={styles.fileButton}>
            <div className={styles.fakeInput}>
              <input className={styles.inputTransparent} type="file" multiple={multiple} onChange={onFileChange} />
              <img src={addFileIconSrc} alt={name} />
            </div>
          </div>
        ) : null}
        {onInputClick ? (
          <button type="button" onClick={() => onInputClick()}>
            <div className={styles.fileButton}>
              <div className={styles.fakeInput}>
                <img src={addFileIconSrc} alt={name} />
              </div>
            </div>
          </button>
        ) : null}

        {files.map((file) => (
          <button
            type="button"
            key={file.id}
            className={styles.fileButton}
            onClick={() => {
              if (
                file.extension === 'jpg' ||
                file.extension === 'jpeg' ||
                file.extension === 'png' ||
                file.extension === 'gif'
              ) {
                setSelectedFile(file);
              } else if (onFileClick) {
                onFileClick();
              } else if (multiple || disableAdd) {
                fileDownload(file.id);
              } else if (!multiple && onInputClick) {
                onInputClick();
              }
            }}
          >
            {!disableAdd && !multiple && !onFileClick && !onInputClick ? (
              <input className={styles.inputTransparent} type="file" multiple={multiple} onChange={onFileChange} />
            ) : null}
            {file.src ? (
              <img src={file.src} alt={file.id} className={styles.imageFile} />
            ) : (
              <div className={styles.fileNonDisplayable}>.{file.extension}</div>
            )}
          </button>
        ))}

        {filesLoading ? (
          <div className={styles.fileButton}>
            <CircularProgress size={inputSize / 1.5} />
          </div>
        ) : null}
      </div>
      {selectedFile && (
        <Dialog maxWidth="lg" open={selectedFile !== null} onClose={() => setSelectedFile(null)}>
          <AppBar>
            <Toolbar style={{ justifyContent: 'space-between' }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => fileDownload(selectedFile.id)}
                aria-label="download"
                size="large">
                <SaveRounded />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setSelectedFile(null)}
                aria-label="close"
                size="large">
                <CloseRounded />
              </IconButton>
            </Toolbar>
          </AppBar>
          {selectedFile && <img src={selectedFile.src} alt={selectedFile.id} />}
        </Dialog>
      )}
      <AppFieldError
        message={
          // only show error, if the field is touched
          objectPropertyByString(touched, name) ? objectPropertyByString(errors, name) : undefined
        }
      />
    </div>)
  );
};

export default FileInputPreviewUpload;