import React from 'react';
import UserUpdate from 'containers/shared/UserUpdate';
import ContractorUsersPage from 'containers/shared/Contractors/ContractorUsersPage';
import ContractorDetailsContainer from 'containers/shared/Contractors/ContractorDetailsContainer';
import ContractorCreatePage from 'containers/shared/Contractors/ContractorCreatePage';
import ContractorDetailsPage from './ContractorDetailsPage';
import { Route, Routes, useLocation } from 'react-router';

const ContractorDetailsRoutes: React.FC = () => {
  const location = useLocation();
  const path = location.pathname.replace(/\/(edit|users)$/, '');
  return (
    <ContractorDetailsContainer baseUrl={path}>
      <Routes>
        <Route path={`/edit`} element={<ContractorCreatePage/>} />
        <Route path={`/users/add`} element={<UserUpdate />} />
        <Route path={`/users/:userId`} element={<UserUpdate />} />
        <Route path={`/users`} element={<ContractorUsersPage />} />
        <Route path={`/`} element={<ContractorDetailsPage />} />
      </Routes>
    </ContractorDetailsContainer>
  );
};

export default ContractorDetailsRoutes;
