import GraphqlErrorCode from 'shared/graphqlErrorCodes';

export default function resolveGraphqlErrorMessage(error: {
  graphQLErrors: Array<{
    extensions: { code: GraphqlErrorCode };
    message: string;
  }>;
}): [string | undefined, string | undefined] | [null, null] {
  if (typeof error !== 'object') return [null, null];

  const { graphQLErrors } = error;
  if (!graphQLErrors || graphQLErrors.length !== 1) {
    throw new Error('More than one graphQLError received');
  }
  const { extensions, message } = graphQLErrors[0];

  return [extensions?.code, message];
}
