import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  userUpdateStatus,
  userUpdateStatusVariables,
  clientUsers,
  clientUsersVariables,
  clientUsers_client_users as User,
  Role,
} from 'models/graphql';
import AppTable, { AppTableFilters, AppTableStatus } from 'components/AppTable';
import switcher from 'utils/switcher';
import ActionsButton from 'components/ActionsButton';
import AppButtonLink from 'components/AppButtonLink';
import { UPDATE_USER_STATUS } from 'containers/shared/graphql';
import { GET_CLIENT_USERS } from './graphql';
import { useNavigate, useParams } from 'react-router';


function formatDateTime(dateVal:any)
{
  let formattedDate='';
  if (dateVal !== null)
  {
    const date = new Date(dateVal);

    // Get day, month, year, hours, minutes, and seconds
    const day = String(date.getUTCDate()).padStart(2, '0'); // Pad single digits to two
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getUTCFullYear();
  
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Combine into desired format
     formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

  }


  return formattedDate;

}

const ClientUsers: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const [updateStatus] = useMutation<userUpdateStatus, userUpdateStatusVariables>(UPDATE_USER_STATUS);
  const { loading: clientsQueryLoading, data: clientsQuery, refetch: refetchClientsQuery } = useQuery<
    clientUsers,
    clientUsersVariables
  >(GET_CLIENT_USERS, {
    variables: {
      id: clientId,
    },
  });
  const navigate = useNavigate();

  const renderStatus = (row: User) => <AppTableStatus isArchived={!row.active} />;
  return (
    <>
      <AppTableFilters
        searchPlaceholder="Search by user name"
        addNewButton={
          <AppButtonLink to="add" type="button" color="primary">
            Add User
          </AppButtonLink>
        }
        onSubmit={async ({ search, showArchived }) => {
          await refetchClientsQuery({
            id: clientId,
            search: search || undefined,
            archived: showArchived || undefined,
          });
        }}
      />

      <AppTable
        data={clientsQuery && clientsQuery.client.users}
        loading={clientsQueryLoading}
        noDataMessage="No Users"
        columns={[
          {
            key: 'name',
            name: 'Name',
            template: (row) => row.name,
          },
          {
            key: 'email',
            name: 'Email',
            template: (row) => row.email,
          },
          {
            key: 'phonenumber',
            name: 'Phone',
            template: (row) => row.phonenumber,
          },
          {
            key: 'site',
            name: 'Site',
            template: (row) => row.sites?.map((site) => site.name).join(', '),
          },
          {
            key: 'role',
            name: 'Role',
            template: (row) =>
              switcher(row.role, [
                [Role.CLIENT_ENVIRONMENTAL_OFFICER, 'Environmental Officer'],
                [Role.CLIENT_OPERATIVE, 'Operative'],
                [Role.CLIENT_TEAM_MEMBER, 'Team Member'],
              ]),
          },
          {
            key: 'isAdmin',
            name: 'Admin',
            template: (row) => (row.isAdmin ? 'YES' : 'NO'),
          },
          {
            key: 'loggedinAt',
            name: 'Logged In',
            template: (row) => formatDateTime(row.loggedinAt),
          },
          {
            key: 'status',
            name: 'Status',
            align: 'center',
            template: renderStatus,
          },
          {
            key: 'actions',
            template: function ActionsTemplate(row) {
              return (
                <ActionsButton
                  actions={[
                    {
                      title: 'Edit Details',
                      action: () => {
                        navigate(`${row.id}`);
                      },
                    },
                    {
                      title: row.active ? 'Archive' : 'Activate',
                      action: async (setLoading) => {
                        setLoading(true);
                        await updateStatus({
                          variables: {
                            id: row.id,
                            isActive: !row.active,
                          },
                        });
                        setLoading(false);
                      },
                    },
                  ]}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default ClientUsers;
