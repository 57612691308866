// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#app {
  min-height: 100vh;
}

a {
  text-decoration: inherit;
  color: inherit;
}

button {
  padding: 0;
  background: inherit;
  border: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  appearance: none;
  cursor: pointer;
  text-align: inherit;
}
button:focus {
  outline: unset;
}

img {
  max-width: 100%;
  max-height: 100%;
}


.taskcalendar-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.taskcalendar-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.taskcalendar-app-sidebar-section {
  padding: 2em;
}

.taskcalendar-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.fc-agendaWeek-view tr {
  height: 40px;
}

.fc-agendaDay-view tr {
  height: 40px;
}

.fc .fc-daygrid-day-frame {
    position: relative;
    min-height: 100%;
}

.MuiAppBar-positionAbsolute {
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
}`, "",{"version":3,"sources":["webpack://./src/style/globals.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,oBAAoB;EACpB,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;;AAGA;EACE,aAAa;EACb,gBAAgB;EAChB,yDAAyD;EACzD,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA,MAAM,sBAAsB;EAC1B,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;EACE,MAAM;EACN,UAAU;EACV,QAAQ;EACR,eAAe;AACjB","sourcesContent":["#app {\n  min-height: 100vh;\n}\n\na {\n  text-decoration: inherit;\n  color: inherit;\n}\n\nbutton {\n  padding: 0;\n  background: inherit;\n  border: inherit;\n  font-size: inherit;\n  font-weight: inherit;\n  font-family: inherit;\n  appearance: none;\n  cursor: pointer;\n  text-align: inherit;\n}\nbutton:focus {\n  outline: unset;\n}\n\nimg {\n  max-width: 100%;\n  max-height: 100%;\n}\n\n\n.taskcalendar-app {\n  display: flex;\n  min-height: 100%;\n  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;\n  font-size: 14px;\n}\n\n.taskcalendar-app-sidebar {\n  width: 300px;\n  line-height: 1.5;\n  background: #eaf9ff;\n  border-right: 1px solid #d3e2e8;\n}\n\n.taskcalendar-app-sidebar-section {\n  padding: 2em;\n}\n\n.taskcalendar-app-main {\n  flex-grow: 1;\n  padding: 3em;\n}\n\n.fc { /* the calendar root */\n  max-width: 1100px;\n  margin: 0 auto;\n}\n\n.fc-agendaWeek-view tr {\n  height: 40px;\n}\n\n.fc-agendaDay-view tr {\n  height: 40px;\n}\n\n.fc .fc-daygrid-day-frame {\n    position: relative;\n    min-height: 100%;\n}\n\n.MuiAppBar-positionAbsolute {\n  top: 0;\n  left: auto;\n  right: 0;\n  position: fixed;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
