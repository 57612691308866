import gql from 'graphql-tag';

export const GET_TASK = gql`
  query taskForUser($id: ID!) {
    task(id: $id) {
      id
      type
      status
    }
  }
`;

export const GET_TASK_TIMER = gql`
  query taskTimerForUser($id: ID!) {
    task(id: $id) {
      id
      timer {
        timeSpent
        onGoing
      }
    }
  }
`;

export const START_TASK = gql`
  mutation taskStart($id: ID!) {
    taskStart(id: $id) {
      id
      status
      timer {
        timeSpent
        onGoing
      }
    }
  }
`;

export const COMPLETE_TASK = gql`
  mutation taskComplete($id: ID!) {
    taskComplete(id: $id) {
      id
      status
      timer {
        timeSpent
        onGoing
      }
    }
  }
`;

export const SUBMIT_SUP_INFO = gql`
  mutation taskSubmitSuppInfo($id: ID!) {
    taskSubmitSuppInfo(id: $id) {
      id
      status
    }
  }
`;

export const PAUSE_TASK_TIMER = gql`
  mutation taskTimerPause($id: ID!) {
    taskTimerPause(id: $id) {
      id
      timer {
        timeSpent
        onGoing
      }
    }
  }
`;

export const RESUME_TASK_TIMER = gql`
  mutation taskTimerResume($id: ID!) {
    taskTimerResume(id: $id) {
      id
      timer {
        timeSpent
        onGoing
      }
    }
  }
`;

export const GET_TASK_DETAILS = gql`
  query taskDetails($id: ID!) {
    task(id: $id) {
      deadline
      documentDeadline
      contractorResponsibleWorkOrderId
      id
      idReadable
      urgent
      tasktitle
      subform
      notes
      reOccurenceType
      approval {
        companyName
        name
        phonenumber
      }
      site {
        id
        name
        address
      }
      status
    }
  }
`;

export const GET_TASK_ATTACHMENTS = gql`
  query taskAttachments($id: ID!) {
    task(id: $id) {
      id
      status
      attachments {
        id
        extension
        originalName
      }
    }
  }
`;

export const GET_TASK_ASSETS = gql`
  query taskAssets($id: ID!) {
    task(id: $id) {
      id
      type
      subform
      adhoc
      assets {
        id
        status
        compliant
        asset {
          id
          name
          type
          system {
            id
            name
            type
          }
          drawings {
            id
          }
        }
      }
    }
  }
`;

export const TASK_ATTACH_FILE = gql`
  mutation taskAttachFile($taskId: ID!, $fileId: ID!) {
    taskAttachFile(taskId: $taskId, fileId: $fileId) {
      id
      attachments {
        id
        extension
        originalName
      }
    }
  }
`;

export const TASK_REMOVE_ATTACHMENT = gql`
  mutation taskRemoveAttachment($taskId: ID!, $fileId: ID!) {
    taskRemoveAttachment(taskId: $taskId, fileId: $fileId) {
      id
    }
  }
`;

export const GET_TASK_ASSET_FORM = gql`
  query getTaskAssetForm($id: ID!) {
    taskAsset(id: $id) {
      id
      asset {
        id
        name
        type
      }
      status
      formTemplate {
        id
        content
      }
      formValues
    }
  }
`;

export const UPDATE_TASK_ASSET_FORM = gql`
  mutation updateTaskAssetForm($id: ID!, $formValues: String!, $draft: Boolean) {
    taskAssetFormValuesUpdate(id: $id, formValues: $formValues, draft: $draft) {
      id
      status
      formValues
      compliant
    }
  }
`;
