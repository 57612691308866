import React from 'react';
import useBreadcrumb from './useBreadcrumb';

interface Props {
  to: string;
  name: string;
  options?: Array<{
    to: string;
    name: string;
  }>;
}

const Breadcrumb: React.FC<Props> = ({ to, name, options, children }) => {
  useBreadcrumb(to, name, options);
  return <>{children}</>;
};
export default Breadcrumb;
