import React, { useState } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Formik, Form } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import { AppTextField, AppDateField } from 'components/form';

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: '500px', // Adjust the value as needed
    maxWidth: '80%',
  },
  table: {
    width: '100%',
  },
  row: {
    '&:not(:first-child)': {
      paddingTop: '5px', // Add spacing between rows
    },
  },
});

interface FormValues {
  projectName: string;
  projectDesc: string;
  responsible: string;
  startDate: string;
  endDate: string;
}

const AddProjectModal = (props) => {
  const { open, setOpen, title, onSave, onClose } = props;
  const classes = useStyles();

  const handleSubmit = (values: FormValues) => {
    console.log('Form values:', values);
    onSave(values);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="dialog-title"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        id="customized-dialog-title"
      >
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '80%' }}>{title}</td>
              <td style={{ width: '20%', textAlign: 'right', marginLeft: '80%' }}>
                <IconButton onClick={() => setOpen(false)} aria-label="close" size="large">
                  <ClearIcon />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            projectName:  '',
            projectDesc: '',
            responsible: '',
            startDate: '',
            endDate:  '',
          }}
          onSubmit={handleSubmit}
        >
          {({ handleChange }) => (
            <Form>
              <table className={classes.table}>
                <tbody>
                  <tr className={classes.row}>
                    <td>
                      <AppTextField
                        label="Project Name"
                        name="projectName"
                        type="text"
                        autoFocus
                        required
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr className={classes.row}>
                    <td>
                      <AppTextField
                        label="Description"
                        name="projectDesc"
                        type="text"
                        required
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr className={classes.row}>
                    <td>
                      <AppTextField
                        label="Responsible"
                        name="responsible"
                        type="text"
                        required
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr className={classes.row}>
                    <td>
                    <AppDateField
                        label="Start Date"
                        name="startDate"
                 
                        required
                        minDate={new Date('2024-01-01')}
                        maxDate={new Date('2026-01-01')}
                      
                      />
                    </td>
                  </tr>
                  <tr className={classes.row}>
                    <td>
                      <AppDateField
                        label="End Date"
                        name="endDate"
                      
                        required
                        minDate={new Date('2024-01-01')}
                        maxDate={new Date('2026-01-01')}
                      
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <DialogActions>
                <Button autoFocus onClick={() => setOpen(false)} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  SAVE
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddProjectModal;

