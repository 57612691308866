import gql from 'graphql-tag';

const GET_USER_ASSETS = gql`
  query assetsForUser(
    $id: ID!
    $search: String
    $type: String
    $systemType: String
    $owner: String
    $pagination: PaginationInput
  ) {
    useraAssets(
      id: $id
      search: $search
      type: $type
      systemType: $systemType
      owner: $owner
      pagination: $pagination
    ) {
      page
      totalPages
      totalNodes
      nodes {
        id
        department
        system {
          id
          type
          name
        }
        name
        type
        createdAt
        owner {
          id
          name
          picture {
            srcUrl
          }
        }
        status
        coordinates {
          longitude
          latitude
        }
        additionalInformation
        drawings {
          id
          drawingId
          file {
            id
            originalName
            extension
          }
        }
        upcomingWorks {
          id
          type
        }
        recentWorks {
          id
          type
        }
      }
    }
  }
`;

export default GET_USER_ASSETS;
