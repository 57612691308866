import React, { useState } from 'react';
import { Formik } from 'formik';

import AppTextField from 'components/form/AppTextField';
import Yup from 'utils/yup';
import queryString from 'query-string';
import FormComponent from './components/Form';
import { changePassword } from './api';
import { createStyles, makeStyles } from '@mui/styles';
import { FormControl, Theme, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    placeholder: {
      color: '#9F9F9F',
    },
    login: {
      width: '100%',
    },
    forgotPassword: {
      marginTop: theme.spacing(3),
      lineHeight: `${theme.sizes.button.height}px`,
      paddingRight: `${theme.spacing(4)}`,
      color: theme.brandColors.main,
      textDecoration: 'underline',
    },
    loginHeader: {
      marginBottom: theme.spacing(5),
    },
    weak: {
      color: '#F15541',
    },
    warning: {
      color: '#F8AC54',
    },
    success: {
      color: '#44924D',
    },
  });
const useStyles = makeStyles(styles);

interface FormValues {
  password1: string;
  password2: string;
}

const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
  password1: Yup.string().required('Please fill out this field.'),
  password2: Yup.string().required('Please fill out this field.'),
});

const ChangePasswordPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = queryString.parse(location.search) as {
    token: string;
  };
  const [dontMatch, setDontMatch] = useState(false);
  const [error, setError] = useState(false);

  function calcStrenght(str: string) {
    if (str.length > 11) return <span className={classes.success}>Strong</span>;
    return str.length > 7 ? (
      <span className={classes.warning}>Medium</span>
    ) : (
      <span className={classes.weak}>Weak</span>
    );
  }

  return (
    <div className={classes.login}>
      <Typography className={classes.loginHeader} component="h1" variant="h5">
        Enter new password
      </Typography>
      <Formik<FormValues>
        initialValues={{
          password1: '',
          password2: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (values.password1 !== values.password2) {
              setDontMatch(true);
            } else {
              await changePassword(token, values.password1);
              navigate('/');
            }
          } catch (e) {
            setError(true);
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <FormComponent
            onSubmit={(e) => handleSubmit(e)}
            submitButtonText="Submit"
            inProgress={isSubmitting}
            justify="flex-end"
          >
            <FormControl margin="normal" required fullWidth>
              <AppTextField name="password1" placeholder="New password" id="password1" type="password" required />
            </FormControl>
            {values.password1 && (
              <Typography variant="body1">Password Strength: {calcStrenght(values.password1)}</Typography>
            )}
            <FormControl margin="normal" required fullWidth>
              <AppTextField name="password2" placeholder="Re-enter password" id="password2" type="password" required />
            </FormControl>
            {dontMatch && (
              <Typography variant="body1" sx={{
                color: "error"
              }}>
                Passwords do not match, please try again.
              </Typography>
            )}
            {error && (
              <Typography variant="body1" sx={{
                color: "error"
              }}>
                Something went wrong. Please request a new password reset.
              </Typography>
            )}
          </FormComponent>
        )}
      </Formik>
    </div>
  );
};

export default ChangePasswordPage;
