import * as dateFns from 'date-fns';
import useSearchParams from 'utils/useSearchParams';
import { useQuery } from '@apollo/client';
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import cancelIcon from 'assets/cancelIcon.svg';
import downArrowBig from 'assets/downArrowBig.svg';
import editIcon from 'assets/editIcon.svg';
import rightArrow from 'assets/rightArrow.svg';
import { AnimateHeight } from 'components/animations';
import AppIconButton from 'components/AppIconButton';
import AssetComplianceIcon from 'components/complianceIcons/AssetComplianceIcon';
import ParameterComplianceIcon from 'components/complianceIcons/ParameterComplianceIcon';
import ContentStateWrapper from 'components/ContentStateWrapper';
import DownloadUploadButtons from 'components/DownloadUploadButtons';
import FileInputWithPreview from 'components/FileInput/FileInputWithPreview';
import { AppSelectField } from 'components/form';
import HideNavigation from 'components/HideNavigation';
import { TABLET_BREAKPOINT } from 'constants/breakpoints';
import { GET_TASK } from 'containers/Client/Tasks/graphql';
import { Formik } from 'formik';
import { motion } from 'motion/react';
import useFileDownload from 'utils/useFileDownload';
import htmlToPdfmake from 'html-to-pdfmake';
import {
  SystemType,
  taskForClient,
  taskForClientVariables,
  taskReport,
  taskReportVariables,
  taskReport_task_assets as TaskReportTaskAssets,
  TaskStatus,
  logs as logsGQL,
  logsVariables,
} from 'models/graphql';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { FormContent, FormContentType } from 'shared/assetFormContent.interface';
import { doesValueComply } from 'shared/assetFormValuesChecker';
import { ContextStore, useStores } from 'stores';
import useText from 'texts/useText.hook';
import cn from 'utils/cn';
import { excelUtils } from 'utils/excel';
import useCancel from 'utils/useCancel.hook';
import useFileSrc from 'utils/useFileSrc';
import useGenerateBase64FromWorkbook from 'utils/useGenerateBase64FromWorkbook';
import useGenerateAndDownloadPdfFromExcel from 'utils/useGeneratePdfFromExcel';
import useMode, { Mode } from 'utils/useMode.hook';
import XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import { GET_TASK_REPORT, LOGS } from '../graphql';
import useMultiReportMapper from './PopulateReports/usePopulateMultiReport';
import useSingleReportMapper from './PopulateReports/usePopulateSingleReport';
import { getReportsLog } from './PopulateReports/utilsForMapping';
import { useNavigate, useParams } from 'react-router';

// @ts-ignore
pdfMake.vfs = pdfFonts.pdfMake;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    loader: {
      marginTop: theme.spacing(2),
    },
    taskTypeFilter: {
      padding: theme.spacing(2),
    },
    assetContainerButton: {
      width: '100%',
    },
    assetContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      border: `solid 1px ${theme.palette.grey[300]}`,

      '&:not(:first-child)': {
        borderTop: 0,
      },
    },
    assetIconsContainer: {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',

      '& :not(:last-child)': {
        marginRight: theme.spacing(1),
      },

      '& img': {
        display: 'block',
      },
    },
    assetContentContainer: {
      flexGrow: 1,
      paddingRight: theme.spacing(2),
    },
    assetTitle: {
      fontSize: theme.fontSizes[16],
    },
    assetSubTitle: {
      marginTop: theme.spacing(0.5),
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[500],
    },

    assetFormContentContainer: {
      padding: theme.spacing(2),
      margin: theme.spacing(1, 0),
      background: theme.palette.grey[100],
    },
    assetFormContentItemContainer: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
    assetFormContentItemTitle: {
      marginBottom: theme.spacing(0.5),
    },
    assetFormContentItemValueContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      fontWeight: theme.typography.fontWeightBold,
      '& img': {
        display: 'block',
      },
    },
    assetFormContentItemValue: {
      flexGrow: 1,
    },
    desktopContainer: {
      display: 'grid',
      gridTemplateColumns: '3fr 5fr 1fr',
      maxWidth: '1400px',
      margin: 'auto',
    },
    padding: {
      padding: theme.spacing(4),
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        padding: theme.spacing(2),
      },
    },
    activeBackground: {
      background: theme.palette.table.main,
    },
    rounded: {
      borderRadius: '16px',
    },
    assetsPadding: {
      padding: `${theme.spacing(1.5)} 16`,
      height: '70vh',
      overflow: 'overlay',
    },
    title: {
      fontSize: '14px',
      margin: 0,
      marginBottom: '4px',
    },
    completed: {
      backgroundColor: '#E2FFDC', //'#d0f0c0',
    },
    inProgress: {
      backgroundColor: '#80808021', //#fff9c4',
    },
    intact: {
      backgroundColor: '#FFFFFF', //theme.palette.grey[200], // Grey for INTACT
    },
  });
});

const TaskReportPage: React.FC = () => {
  const { taskId, clientId, contractorId, siteId } = useParams<{
    taskId: string;
    clientId?: string;
    contractorId?: string;
    siteId?: string;
  }>();

  const { mode } = useMode();
  const { authStore } = useStores();
  const { contextStore } = useStores(); // for storing the report page has been viewed
  const fallbackUrl = clientId ? `/client/${clientId}/tasks` : `/contractor/${contractorId}/work-instructions`;
  const cancel = useCancel(fallbackUrl);
  const styles = useStyles();
  const { t } = useText('systemTypes', 'assetTypes', 'taskTypes', 'tasks', 'taskStatuses');
  const theme = useTheme();
  const tabletScreen = useMediaQuery(theme.breakpoints.up(TABLET_BREAKPOINT));
  const pageMode = mode === Mode.CLIENT || mode === Mode.CONTRACTOR ? 'regular' : 'technician';
  const getFileSrc = useFileSrc();
  const singleReportMapper = useSingleReportMapper();
  const multiReportMapper = useMultiReportMapper();
  const generateBase64FromWorkbook = useGenerateBase64FromWorkbook();
  const generateAndDownloadPdfFromExcel = useGenerateAndDownloadPdfFromExcel();
  const navigate = useNavigate();

  const [assetId] = useSearchParams('asset');

  const download = useFileDownload();

  contextStore.setReportViewedValue(true);


  // Data Query
  const { data: taskData, loading: taskDataLoading } = useQuery<taskReport, taskReportVariables>(GET_TASK_REPORT, {
    variables: { id: taskId },
  });

  const { data: taskStaticInfo, loading: taskStaticInfoLoading } = useQuery<taskForClient, taskForClientVariables>(
    GET_TASK,
    {
      variables: { taskId },
    },
  );

  const { data: logs } = useQuery<logsGQL, logsVariables>(LOGS, { variables: { dataId: taskId } });

  if (!taskDataLoading && !taskData && !taskStaticInfo && !taskStaticInfoLoading) {
    throw new Error('taskData have to be defined, when taskDataLoading is false');
  }
  const task = taskData?.task;
  const taskstatic= taskStaticInfo?.task.assets;


  const taskAssets = taskData?.task.assets;


  const systemTypes = taskAssets ? ([] as SystemType[]) : undefined;
  if (taskAssets && systemTypes) {
    taskAssets.forEach((taskAsset) => {
      if (!systemTypes.includes(taskAsset.asset.system.type)) {
        systemTypes.push(taskAsset.asset.system.type);
      }
    });
  }
  const allowMultiPdf: boolean = taskAssets
    ? !!taskAssets.filter((asset) => !!asset.formTemplate.allowMultiReportPdf).length
    : false;

  const reportLogsObj = getReportsLog(logs);

  // States
  const [selectedSystemType, setSelectedSystemType] = useState<SystemType>();
  const [isSingleExcelTemplateInProgress, setIsSingleExcelTemplateInProgress] = useState<boolean>(false);
  const [isSinglePDFTemplateInProgress, setIsSinglePDFTemplateInProgress] = useState<boolean>(false);
  const [isMultiExcelTemplateInProgress, setIsMultiExcelTemplateInProgress] = useState<boolean>(false);
  const [isMultiPDFTemplateInProgress, setIsMultiPDFTemplateInProgress] = useState<boolean>(false);

  function generatePdfFromHtml(fromHTML: string, fileName: string) {
    const val = htmlToPdfmake(fromHTML);
    pdfMake
      .createPdf({
        content: val,
        pageSize: '2A0',
        pageOrientation: 'portrait',
      })
      .download(fileName);
  }

  async function getFileFromUrl(url: string, name: string, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  }

  const getWorkbookByFileId = async (id: string, fileName: string) => {
    const file = await getFileSrc(id);
    const fileUrl = file?.url || '';
    const fetchedFile = await getFileFromUrl(fileUrl, fileName);
    return await excelUtils.fileToBook(fetchedFile);
  };

  useEffect(() => {
    if (selectedSystemType === undefined && systemTypes) setSelectedSystemType(systemTypes[0]);
  }, [systemTypes, selectedSystemType]);

  const [selectedAsset, setSelectedAsset] = useState<TaskReportTaskAssets>();

  useEffect(() => {
    if (selectedSystemType !== undefined && pageMode === 'regular') {
      const filteredTaskAssets = taskAssets?.filter((taskAsset) => taskAsset.asset.system.type === selectedSystemType);
      if (filteredTaskAssets && filteredTaskAssets.length > 0) {
        setSelectedAsset(filteredTaskAssets[0]);
      }
    }
    if (assetId)
    {
      const passedasset = taskAssets?.find((testrespAsset) => testrespAsset.asset.id === assetId);
      setSelectedAsset(passedasset);
    }
    if (task)
    {
      console.log("Task Details:", task.attachments);
    }
   
  }, [selectedSystemType, taskAssets, pageMode]);

  // Calculations
  const assetsComplianceStatus = (taskAsset: {
    formValues: string | null;
    asset: { id: string };
    formTemplate: { content: string };
  }): [number, number] => {
    let complienceableFieldsNum = 0;
    let compliantFieldsNum = 0;

   //  const formTemplateContent: FormContent = JSON.parse(taskAsset.formTemplate.content);
   const formTemplateContent: FormContent =  JSON.parse(taskAsset.formTemplate.content).filter(
    // filter out the formFields which are not required for this specific asset
    (field: { type: FormContentType; assets: string | string[]; }) =>
      (!field.assets ||
        !field.assets.length ||
        field.assets.includes(taskAsset.asset.id)),
  )

    const values: Record<string, string | null> = taskAsset.formValues ? JSON.parse(taskAsset.formValues) : {};

    formTemplateContent.forEach((parameter) => {
      const parameterComplient = doesValueComply(
        parameter.parameterName,
        values ? values[parameter.parameterName] : undefined,
        formTemplateContent,
      );
      if (parameterComplient === false) {
        complienceableFieldsNum += 1;
      } else if (parameterComplient === true) {
        complienceableFieldsNum += 1;
        compliantFieldsNum += 1;
      }
    });

    return [compliantFieldsNum, complienceableFieldsNum];
  };

  const isParameterValueText = (parameterName: string, formTemplateContent: FormContent): boolean | undefined => {
    const formTemplateField = formTemplateContent.find((field) => field.parameterName === parameterName);
    if (!formTemplateField) {
      throw new Error(`field for parameterName ${parameterName} was not found`);
    }
    return (
      formTemplateField.type === FormContentType.TEXT ||
      formTemplateField.type === FormContentType.DATE ||
      formTemplateField.type === FormContentType.NUMERIC ||
      formTemplateField.type === FormContentType.MULTIVALUE ||
      formTemplateField.type === FormContentType.BOOLEAN ||
      formTemplateField.type === FormContentType.MULTISELECT
    );
    // throw new Error(`field for parameterName ${parameterName} was not found`)
  };

  // Inner components
  const assetFormContentTextItem = (
    title: string,
    value: string | string[] | Date | null,
    badges?: React.ReactNode,
    formContent?: FormContent,
  ): React.ReactNode => {
    let valueMod: string | string[] | Date | null;
    const formTemplateField = formContent && formContent.find((field) => field.parameterName === title);

    if (formTemplateField?.type === FormContentType.DATE) {
      valueMod = dateFns.format(new Date(value as Date), 'dd/MM/yyyy');
    } else {
      valueMod = value;
    }

    return value ? (
      <div>
        <div className={styles.assetFormContentItemTitle}>{title}</div>
        <div className={styles.assetFormContentItemValueContainer}>
          <div className={styles.assetFormContentItemValue}>
            {Array.isArray(valueMod) ? valueMod.join(', ') : valueMod}
          </div>
          {badges ? <div>{badges}</div> : null}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const currentTaskAsset = taskAssets?.find((taskAsset) => taskAsset.asset.system.type === selectedSystemType);
  const multiTemplateFileId = (currentTaskAsset && currentTaskAsset.formTemplate?.fileMultiTemplate?.id) || '';
  const multiTemplateFileName =
    (currentTaskAsset && currentTaskAsset.formTemplate?.fileMultiTemplate?.originalName) || '';
  const generateHTMLFromEExcelJSWorkbook = async (workbook: Workbook): Promise<string> => {
    const buffer = await workbook.xlsx.writeBuffer();
    const wb = XLSX.read(buffer, { type: 'buffer' });
    return XLSX.write(wb, { sheet: wb.SheetNames[0], type: 'binary', bookType: 'html' });
  };

  const generatePopulatedExcelForMultiTemplate = async () => {
    if (multiTemplateFileId && taskAssets) {
      const initialWorkbook = await getWorkbookByFileId(multiTemplateFileId, multiTemplateFileName);
      const taskAssetsForCurrentMultiReport = taskAssets.filter(
        (taskAsset) => taskAsset.asset.system.type === selectedSystemType,
      );

      return multiReportMapper(initialWorkbook, taskAssetsForCurrentMultiReport, taskStaticInfo!, reportLogsObj);
    }
    return null;
  };

  const handleMultiExcelTemplateDownload = async () => {
    setIsMultiExcelTemplateInProgress(true);
    const populatedWorkbook = await generatePopulatedExcelForMultiTemplate();
    if (populatedWorkbook) {
      await excelUtils.downloadBook(populatedWorkbook, multiTemplateFileName);
    }
    setIsMultiExcelTemplateInProgress(false);
  };

  const handleMultiPDFTemplateDownload = async () => {
    setIsMultiPDFTemplateInProgress(true);
    const populatedWorkbook = await generatePopulatedExcelForMultiTemplate();
    if (populatedWorkbook) {
      const htmlStr = await generateHTMLFromEExcelJSWorkbook(populatedWorkbook);
      const fileNameWithExtension = multiTemplateFileName || 'Report';
      const splittedName = fileNameWithExtension.split('.');
      splittedName.splice(-1, 1);
      generatePdfFromHtml(htmlStr, `${splittedName.join('.')}.pdf`);
    }
    setIsMultiPDFTemplateInProgress(false);
  };

  const assetFormContent = (taskAsset: TaskReportTaskAssets, rounded?: boolean): React.ReactNode => {
    if (taskDataLoading || taskStaticInfoLoading) {
      return null;
    }

    const formTemplateContent: FormContent = JSON.parse(taskAsset.formTemplate.content);

    const values: Record<string, string | null> | null = taskAsset.formValues ? JSON.parse(taskAsset.formValues) : null;

    const valuesForReport = {
      ...taskStaticInfo?.task,
      ...task,
      assets: taskAsset.asset,
      logs: reportLogsObj,
      ...values,
      formTemplateContent,
    };

    const singleTemplateFileId = taskAsset.formTemplate.fileSingleTemplate?.id || '';
    const singleTemplateFileName = taskAsset.formTemplate.fileSingleTemplate?.originalName || '';

    const generatePopulatedExcelForSingleTemplate = async () => {
      const initialWorkbook = await getWorkbookByFileId(singleTemplateFileId, singleTemplateFileName);
      return singleReportMapper(initialWorkbook, valuesForReport);
    };

    const handleSingleExcelTemplateDownload = async () => {
      setIsSingleExcelTemplateInProgress(true);
      const populatedWorkbook = await generatePopulatedExcelForSingleTemplate();
      await excelUtils.downloadBook(populatedWorkbook, singleTemplateFileName);
      setIsSingleExcelTemplateInProgress(false);
    };

    const handleSinglePDFTemplateDownload = async () => {
      setIsSinglePDFTemplateInProgress(true);
      const populatedWorkbook = await generatePopulatedExcelForSingleTemplate();
      const b64encoded = await generateBase64FromWorkbook(populatedWorkbook);
      await generateAndDownloadPdfFromExcel(b64encoded, singleTemplateFileName);
      setIsSinglePDFTemplateInProgress(false);
    };
    return (<>
      <div className={cn(styles.assetFormContentContainer, rounded ? styles.rounded : undefined)}>
        {values ? (
          <>
            {/* TODO: date and time of inspection */}
            <div className={styles.assetFormContentItemContainer}>
              {assetFormContentTextItem(
                t('tasks')('inspector'),
                task?.contractorResponsibleUser?.name || task?.internalResponsible?.name || null,
              )}
            </div>
            {Object.entries(values).map(([key, value]) => {
              const _isParameterValueText = isParameterValueText(key, formTemplateContent);
              return (
                <div key={key} className={styles.assetFormContentItemContainer}>
                  {_isParameterValueText ? (
                    assetFormContentTextItem(
                      key,
                      value,
                      <ParameterComplianceIcon
                        parameterName={key}
                        value={value}
                        formTemplateContent={formTemplateContent}
                      />,
                      formTemplateContent,
                    )
                  ) : (
                    <>
                      {Array.isArray(value) ? (
                        <>
                          {value.length ? (
                            <div>
                              <div className={styles.assetFormContentItemTitle}>{key}</div>
                              <Formik<{ files: string[] }>
                                enableReinitialize
                                initialValues={{ files: value }}
                                onSubmit={async () => {
                                  // Do nothing
                                }}
                              >
                                {() => {
                                  return <FileInputWithPreview name="files" multiple disableAdd />;
                                }}
                              </Formik>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {value ? (
                            <div>
                              <div className={styles.assetFormContentItemTitle}>{key}</div>
                              <Formik<{ file: string }>
                                enableReinitialize
                                initialValues={{ file: value }}
                                onSubmit={async () => {
                                  // Do nothing
                                }}
                              >
                                {() => {
                                  return <FileInputWithPreview name="file" disableAdd />;
                                }}
                              </Formik>
                            </div>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <div>{t('tasks')('formEmpty')}</div>
        )}
      </div>
      <Box sx={{
        pb: 1
      }}>
        <p className={styles.title}>Single Report</p>
        <DownloadUploadButtons
          handleExcelDownload={handleSingleExcelTemplateDownload}
          handlePdfDownload={handleSinglePDFTemplateDownload}
          isExcelInProgress={isSingleExcelTemplateInProgress}
          isPDFInProgress={isSinglePDFTemplateInProgress}
          isButtonsDisable={!singleTemplateFileId}
          isAllowPdfReport={!!selectedAsset?.formTemplate?.allowSingleReportPdf}
        />
      </Box>
    </>);
  };

  return (
    <div className={pageMode === 'regular' && tabletScreen ? styles.padding : undefined}>
      {pageMode === 'regular' && tabletScreen && (
        <>
          <HideNavigation />
          <Box
            sx={{
              position: "absolute",
              p: 3,
              top: 0,
              right: 0
            }}>
            <AppIconButton noBorder onClick={cancel}>
              <img alt="Close report" src={cancelIcon} height="40px" />
            </AppIconButton>
          </Box>
          <Box
            sx={{
              mt: 10,
              textAlign: "center",
              p: 1
            }}>
            <Typography variant="h2" style={{paddingBottom: '20px'}}>{t('tasks')('report')}</Typography>
        
            <table style={{
                paddingBottom: '10px',
                paddingTop: '10px',
                borderBottom: '1px solid black',
                borderTop: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                alignContent: 'center',
                margin: 'auto',
                background: 'white',
                borderSpacing: 0,
                color: 'black',
                width: '70%'
            }}>
              <thead>
                <tr>
                  <th style={{fontWeight: 'bold', width: '5%',}}>
                    Task ID
                  </th>
                  <th style={{ fontWeight: 'bold', width: '16%', }}>
                    Task Title
                  </th>
                  <th style={{ fontWeight: 'bold', width: '16%'}}>
                    Task Type
                  </th>
                  <th style={{ fontWeight: 'bold', width: '16%'}}>
                    Task Status
                  </th>
                  <th style={{ fontWeight: 'bold', width: '16%'}}>
                    Responsible
                  </th>
                  <th style={{ fontWeight: 'bold', width: '16%'}}>
                    Deadline
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td >{task?.idReadable}</td>
                  <td >{task?.tasktitle}</td>
                  <td >{ task?.type && t('taskTypes')(task?.type)}</td>
                  <td >{ task?.status && t('taskStatuses')(task?.status)}</td>
                  <td >{task?.contractorResponsible?.companyName || task?.internalResponsible?.name || null}</td>
                  <td >{task?.deadline.split('-').reverse().join('/')}</td>
                </tr>
                {task?.attachments.length ? (
                  <>
                    <tr>
                      <td colSpan={6}>&nbsp;</td>
                    </tr>
                    <tr>
                      <td colSpan={1} style={{ fontWeight: 'bold', width: '16%', }}>Attachments</td>
                      <td colSpan={5} style={{ textAlign: 'left'}}>  
                        {task?.attachments.map((attachment) => (
                        <button type="button" onClick={() => download(attachment.id)}>&nbsp;&nbsp;
                          <u>{attachment.originalName}</u>&nbsp;&nbsp;
                        </button>
                        ))}
                      </td>
                    </tr>
                  </>) : null}
              </tbody>
          </table>
          </Box>
        </>
      )}
      <ContentStateWrapper loading={taskDataLoading || taskStaticInfoLoading}>
        {taskAssets && systemTypes && selectedSystemType ? (
          <div className={tabletScreen || pageMode === 'regular' ? styles.desktopContainer : undefined}>
            <div>
              <div className={styles.taskTypeFilter}>
                <Formik<{ systemType: SystemType }>
                  enableReinitialize
                  initialValues={{ systemType: selectedSystemType }}
                  onSubmit={async () => {
                    // Do nothing
                  }}
                >
                  {() => {
                    return (
                      <AppSelectField
                        name="systemType"
                        options={{
                          array: systemTypes,
                          key: (systemType) => systemType,
                          value: (systemType) => systemType,
                          template: (systemType) => t('systemTypes')(systemType),
                        }}
                        onChange={(systemType) => {
                          setSelectedSystemType(systemType);
                          setSelectedAsset(undefined);
                        }}
                      />
                    );
                  }}
                </Formik>
              </div>
              {selectedSystemType ? (
              
                <div className={tabletScreen || pageMode === 'regular' ? styles.assetsPadding : undefined}>
                  {  
                  [...taskAssets]
                    .sort((a, b) => a.asset.name.localeCompare(b.asset.name))
                    .filter((taskAsset) => taskAsset.asset.system.type === selectedSystemType)
                    .map((taskAsset) => {
                      const [compliantFieldsNum, complienceableFieldsNum] = assetsComplianceStatus(taskAsset);

                      let staticTaskA = taskstatic?.filter((taskAssetS) => taskAssetS.id === taskAsset.id) ;
                     
                      let statusClass = styles.intact;
                      if (staticTaskA)
                      {
                        statusClass =
                        staticTaskA[0].status === 'COMPLETED'
                          ? styles.completed
                          : staticTaskA[0].status === 'IN_PROGRESS'
                          ? styles.inProgress
                          : styles.intact;
                      }
                   
                      return (
                        <div
                          key={taskAsset.id}
                          className={
                            selectedAsset &&
                            selectedAsset.id === taskAsset.id &&
                            (tabletScreen || pageMode === 'regular')
                              ? styles.activeBackground
                              : undefined
                          }
                        >
                          <button
                            type="button"
                            className={styles.assetContainerButton}
                            onClick={() =>
                              setSelectedAsset(
                                selectedAsset && selectedAsset.id === taskAsset.id ? undefined : taskAsset,
                              )
                            }
                          >
                            <div className={cn(styles.assetContainer, statusClass)}>
                              <div className={styles.assetContentContainer}>
                                <div className={styles.assetTitle}>
                                  {taskAsset.asset.name} - {t('assetTypes')(taskAsset.asset.type)}
                                </div>
                                <div className={styles.assetSubTitle}>
                                  {t('systemTypes')(taskAsset.asset.system.type)}
                                </div>
                              </div>
                              <div className={styles.assetIconsContainer}>
                                {complienceableFieldsNum > 0 ? (
                                  <div>
                                    <AssetComplianceIcon
                                      asset={{
                                        ...taskAsset,
                                        compliant: compliantFieldsNum === complienceableFieldsNum,
                                      }}
                                    />
                                  </div>
                                ) : null}
                                <motion.div
                                  initial={{ rotate: 0 }}
                                  animate={
                                    selectedAsset &&
                                    selectedAsset.id === taskAsset.id &&
                                    !(tabletScreen || pageMode === 'regular')
                                      ? { rotate: 180 }
                                      : { rotate: 0 }
                                  }
                                  transition={theme.framerTransitions.normal}
                                >
                                  <img
                                    src={tabletScreen || pageMode === 'regular' ? rightArrow : downArrowBig}
                                    alt="open"
                                  />
                                </motion.div>
                              </div>
                            </div>
                          </button>
                          <AnimateHeight
                            visible={
                              selectedAsset &&
                              selectedAsset.id === taskAsset.id &&
                              !(tabletScreen || pageMode === 'regular')
                            }
                          >
                            {assetFormContent(taskAsset)}
                          </AnimateHeight>
                        </div>
                      );
                    })}
                </div>
              ) : null}
            </div>
            <Box
              sx={{
                pt: 1,
                pb: 2
              }}>
              <p className={styles.title}>Multi Report</p>
              <DownloadUploadButtons
                handleExcelDownload={handleMultiExcelTemplateDownload}
                handlePdfDownload={handleMultiPDFTemplateDownload}
                isExcelInProgress={isMultiExcelTemplateInProgress}
                isPDFInProgress={isMultiPDFTemplateInProgress}
                isButtonsDisable={!multiTemplateFileId}
                isAllowPdfReport={allowMultiPdf}
              />
              <Box
                sx={{
                  marginTop: "72px",
                  p: 1.5,
                  position: "relative"
                }}>
                {mode === Mode.CLIENT &&
                  task?.internalResponsible?.id === authStore?.user?.id &&
                  task?.status === TaskStatus.IN_PROGRESS &&
                  selectedAsset && (
                    <Box
                      sx={{
                        position: "absolute",
                        p: 1.5,
                        top: -69,
                        right: 0
                      }}>
                      <AppIconButton
                        onClick={() =>
                          navigate(`/client/${clientId}/tasks/${siteId}/${taskId}/${selectedAsset?.id}/form`)
                        }
                      >
                        <img alt="edit form" src={editIcon} />
                      </AppIconButton>
                    </Box>
                  )}
                {selectedAsset && (
                  <AnimateHeight visible={tabletScreen || pageMode === 'regular'}>
                    {assetFormContent(selectedAsset, true)}
                  </AnimateHeight>
                )}
              </Box>
            </Box>
          </div>
        ) : null}
      </ContentStateWrapper>
    </div>
  );
};

export default TaskReportPage;
