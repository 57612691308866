import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import AppTable, { AppTableStatus, AppTableFilters } from 'components/AppTable';
import {
  ContractorStatus,
  getContractorsForClient_contractors as Contractor,
  contractorRequest,
  contractorRequestVariables,
  getContractorsForClient,
  getContractorsForClientVariables,
} from 'models/graphql';
import ActionsButton from 'components/ActionsButton';
import { useBreadcrumb } from 'components/Breadcrumb';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import Rating from 'components/Rating';
import useText from 'texts/useText.hook';
import { GET_CONTRACTORS_FOR_CLIENT } from './graphql';
import { REQUEST_CONTRACTOR } from '../graphql';
import { useParams, useLocation} from 'react-router';

const renderStatus = (row: Contractor) => <AppTableStatus isArchived={row.status === ContractorStatus.ARCHIVED} />;

const ClientContractorRequestPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { pathname } = useLocation();
  useBreadcrumb(pathname, 'Add Contractor');

  const { loading, data: contractorsData, refetch } = useQuery<
    getContractorsForClient,
    getContractorsForClientVariables
  >(GET_CONTRACTORS_FOR_CLIENT);
  const availableContractors = contractorsData?.contractors.filter(
    (contractor) => !contractor.requests.find((request) => request.client.id === clientId),
  );

  const [requestAccess] = useMutation<contractorRequest, contractorRequestVariables>(REQUEST_CONTRACTOR, {
    update(cache) {
      apolloCacheEvictQuery({ cache, query: 'contractorRequests' });
    },
  });

  const { tt } = useText('contractors');

  return (
    <>
      <AppTableFilters
        searchPlaceholder="Search by company name, contact name, etc."
        onSubmit={async ({ search, showArchived }) => {
          await refetch({
            search: search || undefined,
            archived: showArchived || undefined,
          });
        }}
      />
      <AppTable
        data={availableContractors}
        loading={loading}
        noDataMessage="All the Contractors has Access to the Client"
        columns={[
          {
            key: 'name',
            name: 'Company Name',
            template: (row) => row.companyName,
          },
          {
            key: 'address',
            name: 'Address',
            template: (row) => row.address,
          },
          {
            key: 'contactName',
            name: 'Contact Name',
            template: (row) => row.contactName,
          },
          {
            key: 'expertise',
            name: 'Expertise',
            template: (row) => row.expertise.map((expertise) => tt('contractors')('expertises')(expertise)).join(', '),
          },
          {
            key: 'rating',
            name: 'Rating',
            align: 'center',
            template: function ContractorRating(row) {
              return <Rating rating={row.rating} />;
            },
          },
          {
            key: 'status',
            name: 'Status',
            align: 'center',
            template: renderStatus,
          },
          {
            key: 'actions',
            template: function ActionsTemplate(row) {
              return (
                <ActionsButton
                  actions={[
                    {
                      title: 'Request Contractor Access to Client',
                      action: async (setLoading) => {
                        setLoading(true);
                        await requestAccess({
                          variables: {
                            contractorId: row.id,
                            clientId,
                          },
                        });
                        setLoading(false);
                      },
                    },
                  ]}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default ClientContractorRequestPage;
