export default function imageFromCanvas(imageBase64: string, extension: string) {
  return new Promise((res) => {
    const img = new Image();
    img.onload = () => {
      const canvas64 = document.createElement('canvas');
      const ctx = canvas64.getContext('2d');
      canvas64.width = img.width;
      canvas64.height = img.height;
      if (ctx) ctx.drawImage(img, 0, 0);
      const dataURL = canvas64.toDataURL(`image/${extension}`);
      res(dataURL);
    };
    img.src = `data:image/${extension};base64,${imageBase64}`;
  });
}
