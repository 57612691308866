export default function stringToCoordinates(
  coordinatesString?: string,
): { latitude: number; longitude: number } | undefined | null {
  if (!coordinatesString) return undefined;

  const [latitudeString, longitudeString] = coordinatesString.replace(/\s/g, '').split(',');

  const latitude = Number.parseFloat(latitudeString);
  const longitude = Number.parseFloat(longitudeString);
  if (Number.isNaN(latitude) || Number.isNaN(longitude)) {
    return null;
  }
  return { latitude, longitude };
}

export function coordinatesToString(coordinates?: { latitude: number; longitude: number }): string | undefined {
  if (!coordinates) return undefined;

  return `${coordinates.latitude}, ${coordinates.longitude}`;
}
