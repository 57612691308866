import { observer } from "mobx-react"
import React, { ReactNode } from "react";
import { Navigate } from "react-router";
import { useStores } from "stores";

const RequireAuth = ({ children }: {
  children: ReactNode
}) => {
  const { authStore } = useStores();
  const isAuthenticated = authStore.isLoggedIn;
  
  return isAuthenticated ? children : <Navigate to={{ pathname: '/auth/login' }} />
}

export default observer(RequireAuth);