import React from 'react';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import Breadcrumb from 'components/Breadcrumb';
import AcceptRequestPage from 'containers/Client/Contractors/AcceptRequestPage';
import ContractorDetailsRoutes from 'containers/Client/Contractors/ContractorDetails/ContractorDetailsRoutes';
import ContractorsPage from './ContractorsPage';
import { Route, Routes, useLocation } from 'react-router';

const ContractorsRoutes: React.FC = () => {
  const {pathname} = useLocation();
  
  const updatedUrl = pathname.replace(/(\/contractors)(\/.*)?$/, '$1');
    
  return <DefaultErrorBoundary>
    <Breadcrumb to={updatedUrl} name="Contractors" />
    <Routes>
      <Route path={`accept/:contractorId`} element={<AcceptRequestPage />} />
      <Route path={`:contractorId`}>
        <Route index element={<ContractorDetailsRoutes />} />
        <Route path={`*`} element={<ContractorDetailsRoutes />} />
      </Route>
      <Route path={`/`} element={<ContractorsPage />} />
    </Routes>
  </DefaultErrorBoundary>
};

export default ContractorsRoutes;
