import React from 'react';
import { Navigate, useParams } from "react-router";
import { Routes, Route } from "react-router";

import ClientConfiguration from 'containers/shared/ClientConfiguration';
import useMode, { Mode } from 'utils/useMode.hook';
import AccountPage from 'containers/shared/AccountPage';
import AssetsRoutes from './Assets/AssetsRoutes';
import DashboardPage from './Dashboard/DashboardPage';
import ContractorsRoutes from './Contractors/ContractorRoutes';
import TaskRoutes from './Tasks/TaskRoutes';
import ProjectRoutes from './Projects/ProjectRoutes';
import DocumentRoutes from './Documents/DocumentRoutes';

const ClientRoutes: React.FC = () => {
  const { mode } = useMode();
  const { clientId, siteId } = useParams<{ clientId: string, siteId?: string }>();
  
  if (mode !== Mode.PM && mode !== Mode.CLIENT) {
    return <Navigate to="/" replace />;
  }  
    
  return (
    <Routes>
      <Route path={`dashboard/:siteId`} element={<DashboardPage />} />
      <Route path={`dashboard`} element={<DashboardPage />} />
      <Route path={`assets/:siteId`}>
        <Route index element={<AssetsRoutes />} />
        <Route path={`*`} element={<AssetsRoutes />} />
      </Route>
      <Route path={`assets`}>
        <Route index element={<AssetsRoutes />} />
        <Route path={`*`} element={<AssetsRoutes />} />
      </Route>
      <Route path={`configure`}>
        <Route index element={<ClientConfiguration />} />
        <Route path={`*`} element={<ClientConfiguration />} />
      </Route>
      <Route path={`contractors`}>
        <Route index element={<ContractorsRoutes />} />
        <Route path={`*`} element={<ContractorsRoutes />} />
      </Route>
      <Route path={`tasks/:siteId`}>
        <Route index element={<TaskRoutes />} />
        <Route path={`*`} element={<TaskRoutes />} />
      </Route>
      <Route path={`tasks`}>
        <Route index element={<TaskRoutes />} />
        <Route path={`*`} element={<TaskRoutes />} />
      </Route>
      <Route path={`projects/:siteId`}>
        <Route index element={<ProjectRoutes />} />
        <Route path={`*`} element={<ProjectRoutes />} />
      </Route>
      <Route path={`projects`}>
        <Route index element={<ProjectRoutes />} />
        <Route path={`*`} element={<ProjectRoutes />} />
      </Route>
      <Route path={`documents/:siteId`}>
        <Route index element={<DocumentRoutes />} />
        <Route path={`*`} element={<DocumentRoutes />} />
      </Route>
      <Route path={`documents`}>
        <Route index element={<DocumentRoutes />} />
        <Route path={`*`} element={<DocumentRoutes />} />
      </Route>
      <Route path={`account`}>
        <Route index element={<AccountPage />} />
        <Route path={`*`} element={<AccountPage />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={`../dashboard`} state={{
          siteId,
          clientId
        }} replace />}
      />
    </Routes>
  );
};

export default ClientRoutes;
