import { AssetType, SystemType } from 'models/graphql';

const assetAssociatedSystems: { [Key in AssetType]: SystemType[] } = {
  [AssetType.AIR_MONITOR]: [SystemType.AIR, SystemType.DUST, SystemType.GENERAL_FACILITY, SystemType.MISCELLANEOUS],
  [AssetType.DRAIN]: [
    SystemType.STORM,
    SystemType.FOUL,
    SystemType.PROCESS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.BUNDS]: [SystemType.BUNDS, SystemType.GENERAL_FACILITY, SystemType.MISCELLANEOUS],
  [AssetType.SAMPLING_POINT]: [
    SystemType.AIR,
    SystemType.PROCESS,
    SystemType.FOUL,
    SystemType.STORM,
    SystemType.NOISE,
    SystemType.ODOUR,
    SystemType.BUNDS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.GULLEY]: [
    SystemType.STORM,
    SystemType.FOUL,
    SystemType.PROCESS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.INTERCEPTOR]: [SystemType.STORM, SystemType.GENERAL_FACILITY, SystemType.MISCELLANEOUS],
  [AssetType.MANHOLE]: [
    SystemType.STORM,
    SystemType.FOUL,
    SystemType.PROCESS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.NOISE_MONITOR]: [SystemType.NOISE, SystemType.GENERAL_FACILITY, SystemType.MISCELLANEOUS],
  [AssetType.ODOUR_MONITOR]: [SystemType.ODOUR, SystemType.GENERAL_FACILITY, SystemType.MISCELLANEOUS],
  [AssetType.SURFACE_WATER]: [
    SystemType.STORM,
    SystemType.FOUL,
    SystemType.PROCESS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.GREASE_TRAP]: [
    SystemType.STORM,
    SystemType.FOUL,
    SystemType.PROCESS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.PUMP]: [
    SystemType.STORM,
    SystemType.FOUL,
    SystemType.PROCESS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.GROUND_WATER]: [
    SystemType.STORM,
    SystemType.FOUL,
    SystemType.PROCESS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
    SystemType.GROUND_WATER,
  ],
  [AssetType.BIOFILTER]: [
    SystemType.STORM,
    SystemType.FOUL,
    SystemType.PROCESS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
    SystemType.BIOFILTER,
  ],
  [AssetType.MISCELLANEOUS]: [
    SystemType.AIR,
    SystemType.PROCESS,
    SystemType.FOUL,
    SystemType.STORM,
    SystemType.NOISE,
    SystemType.ODOUR,
    SystemType.BUNDS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.GENERAL_FACILITY]: [
    SystemType.AIR,
    SystemType.PROCESS,
    SystemType.FOUL,
    SystemType.STORM,
    SystemType.NOISE,
    SystemType.ODOUR,
    SystemType.BUNDS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.EQUIPMENT_VEHICLES]: [
    SystemType.EQUIPMENT_VEHICLES,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.PEST_CONTROL_POINT]: [
    SystemType.AIR,
    SystemType.PROCESS,
    SystemType.FOUL,
    SystemType.STORM,
    SystemType.NOISE,
    SystemType.ODOUR,
    SystemType.BUNDS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.TANKS]: [
    SystemType.AIR,
    SystemType.PROCESS,
    SystemType.FOUL,
    SystemType.STORM,
    SystemType.NOISE,
    SystemType.ODOUR,
    SystemType.BUNDS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.PITS]: [
    SystemType.AIR,
    SystemType.PROCESS,
    SystemType.FOUL,
    SystemType.STORM,
    SystemType.NOISE,
    SystemType.ODOUR,
    SystemType.BUNDS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
  ],
  [AssetType.FIRE_EQUIPMENT]: [
    SystemType.AIR,
    SystemType.PROCESS,
    SystemType.FOUL,
    SystemType.STORM,
    SystemType.NOISE,
    SystemType.ODOUR,
    SystemType.BUNDS,
    SystemType.GENERAL_FACILITY,
    SystemType.MISCELLANEOUS,
    SystemType.FIRE_EQUIPMENT,
  ],
};
export default assetAssociatedSystems;