import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikErrors, FormikProps } from 'formik';

import HideNavigation from 'components/HideNavigation';
import {
  getTaskAssetForm,
  getTaskAssetFormVariables,
  taskAssets,
  TaskAssetStatus,
  taskAssetsVariables,
  updateTaskAssetForm as UpdateTaskAssetForm,
  updateTaskAssetFormVariables,
} from 'models/graphql';
import cancelSimpleIcon from 'assets/cancelSimpleIcon.svg';
import AppIconButton from 'components/AppIconButton';
import useCancel from 'utils/useCancel.hook';
import useText from 'texts/useText.hook';
import AppButton from 'components/AppButton';
import {
  AppForm,
  AppNumberField,
  AppSelectField,
  AppTextField,
  AppSelectMultipleField,
  AppDateField,
} from 'components/form';
import { FormContent, FormContentType } from 'shared/assetFormContent.interface';
import CircularProgressCentered from 'components/CircualProgressCentered';
import ButtonSelector from 'components/form/ButtonSelector';
import { validateFormValues } from 'shared/assetFormValuesChecker';
import FileInputPreviewUpload from 'components/FileInput/FileInputPreviewUpload';
import photoIcon from 'assets/photoIcon.svg';
import signatureIcon from 'assets/signatureIcon.svg';
import cancelIcon from 'assets/cancelIcon.svg';
import ParameterComplianceIcon from 'components/complianceIcons/ParameterComplianceIcon';
import styleUtils from 'style/styleUtils';
import AppDialog from 'components/AppDialog';
import { TooltipOnClick } from 'components/AppTooltip';
import useMode, { Mode } from 'utils/useMode.hook';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import { GET_TASK_ASSET_FORM, GET_TASK_ASSETS, UPDATE_TASK_ASSET_FORM } from '../graphql';
import TaskAssetSignature from './TaskAssetSignature';
import { useParams } from 'react-router';

const smallScreenSize = 380;
const styleRules = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: DESKTOP_BREAKPOINT,
      margin: 'auto',
    },
    clientContainer: {
      maxWidth: '460px',
      margin: 'auto',
      paddingTop: theme.spacing(1),
    },
    cancelButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      padding: theme.spacing(3),

      '& img': {
        maxHeight: 'unset',
      },
    },
    formContainer: {
      margin: `0 ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    },
    clientFormContainer: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: '16px',
      padding: theme.spacing(3),
      margin: `0 0 ${theme.spacing(3)} 0`,
    },
    subHeader: {
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[500],
    },
    header: {
      fontSize: theme.fontSizes[16],
      fontWeight: theme.typography.fontWeightBold,
      margin: 0,
    },
    ruler: {
      margin: `${theme.spacing(3)} 0`,
      border: 0,
      borderTop: `solid 1px ${theme.palette.grey[300]}`,
    },
    formFieldContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(3),
    },
    formField: {
      flexGrow: 1,
    },
    formFieldComplianceIcon: {
      flexShrink: 0,
      height: theme.sizes.button.height,
      ...styleUtils.columnCenterCenter,
      marginLeft: theme.spacing(1),
    },
    formButtons: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(smallScreenSize)]: {
        flexDirection: 'column',
      },
    },
    formButton: {
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: 0,
      },
      [theme.breakpoints.down(smallScreenSize)]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
      },
    },
    unsavedChangesButtons: {
      display: 'flex',
      flexDirection: 'column',

      '& > :not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
    labelElement: {
      paddingBottom: 8,
      fontSize: '12px',
      fontWeight: 700,
      display: 'flex',
      flexDirection: 'row',
      cursor: 'default',
    },
  });
const useStyles = makeStyles(styleRules);

type FormValues = Record<string, string | null>;

const TaskAssetFormPage: React.FC = () => {
  const { taskId, taskAssetId, clientId, siteId } = useParams<{
    taskId: string;
    taskAssetId: string;
    clientId?: string;
    siteId?: string;
  }>();

  const { mode } = useMode();
  let fallbackURL = `/${mode === Mode.OPERATIVE ? 'operative' : 'technician'}/works/${taskId}/assets`;
  fallbackURL = mode === Mode.CLIENT ? `/client/${clientId}/tasks/${siteId}` : fallbackURL;
  const cancel = useCancel(fallbackURL);
  const pageMode = mode === Mode.CLIENT || mode === Mode.CONTRACTOR ? 'regular' : 'technician';

  const [adhocAssetsIds, setAdhocAssetsIds] = useState<Array<string> | null>(null);
  const { data: taskAssetsData } = useQuery<taskAssets, taskAssetsVariables>(GET_TASK_ASSETS, {
    variables: { id: taskId },
  });
  useEffect(() => {
    if (taskAssetsData?.task.adhoc && taskAssetsData?.task.assets) {
      const ids = taskAssetsData?.task.assets.map((asset) => asset.id);
      if (ids) {
        setAdhocAssetsIds(ids);
      }
    }
  }, [taskAssetsData]);

  const { data: taskAssetData, loading: taskAssetDataLoading } = useQuery<getTaskAssetForm, getTaskAssetFormVariables>(
    GET_TASK_ASSET_FORM,
    {
      variables: { id: taskAssetId },
    },
  );

  const setComplianceExpiry = async (taid: any) => {
    // Perform your data fetching logic here
    // For example, make an API call using axios or fetch
    const urltogoto = 'forms/setassetcompexpiry/';
    let dataret: any [];
    let dataretforms: any [];
    let dataretassets: any [];
    let fullurl = urltogoto + taid;
  
  
    const response = await fetch(`${process.env.API_ROOT}${fullurl}`, {
      method: 'POST',
      credentials: 'include',
    })
    const data = await response.json();
    return data;
  };

  const taskAsset = taskAssetData?.taskAsset;
  const formTemplateContent = taskAsset
    ? (JSON.parse(taskAsset.formTemplate.content) as FormContent).filter(
        // filter out the formFields which are not required for this specific asset
        (field) =>
          field.type !== FormContentType.FREQUENCY &&
          ((field.type !== FormContentType.TEXT &&
            field.type !== FormContentType.DATE &&
            field.type !== FormContentType.BOOLEAN &&
            field.type !== FormContentType.NUMERIC &&
            field.type !== FormContentType.MULTIVALUE &&
            field.type !== FormContentType.FILES &&
            field.type !== FormContentType.SIGNATURE &&
            field.type !== FormContentType.MULTISELECT) ||
            !field.assets ||
            !field.assets.length ||
            field.assets.includes(taskAsset.asset.id)),
      )
    : undefined;
  if (!taskAssetDataLoading && !taskAsset) {
    throw new Error('taskAssetData is loaded, but taskAsset is not defined.');
  }

  // save data
  const [updateTaskAssetForm] = useMutation<UpdateTaskAssetForm, updateTaskAssetFormVariables>(UPDATE_TASK_ASSET_FORM);
  const [draftSaving, setDraftSaving] = useState(false);
  const [addingSignature, setAddingSignature] = useState(false);

  // Initial values
  const initialValues: FormValues = {};
  if (taskAsset && formTemplateContent) {
    formTemplateContent.forEach((field) => {
      initialValues[field.parameterName] = taskAsset.formValues
        ? JSON.parse(taskAsset.formValues)[field.parameterName]
        : null;
    });
  }

  // unsaved changes
  const formRef = useRef<FormikProps<FormValues>>(null);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = useState(false);
  const saveDraft = async () => {
    setDraftSaving(true);
    if (formRef.current) {
      if (adhocAssetsIds) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const adhocId of adhocAssetsIds) {
          await updateTaskAssetForm({
            variables: { id: adhocId, formValues: JSON.stringify(formRef.current.values), draft: true },
          });
        }
      } else {
        await updateTaskAssetForm({
          variables: { id: taskAssetId, formValues: JSON.stringify(formRef.current.values), draft: true },
        });
      }
    }
    cancel();
  };

  const { t } = useText('assetTypes', 'systemTypes', 'tasks', 'form', 'common');
  const styles = useStyles();

  return (
    <div className={pageMode === 'regular' ? styles.clientContainer : styles.container}>
      <HideNavigation />
      {pageMode === 'regular' ? (
        <>
          <Box
            sx={{
              position: "absolute",
              p: 3,
              top: 0,
              right: 0
            }}>
            <AppIconButton
              noBorder
              onClick={() => {
                if (formRef.current?.dirty) {
                  setUnsavedChangesDialogOpen(true);
                } else {
                  cancel();
                }
              }}
            >
              <img alt="Close report" src={cancelIcon} height="40px" />
            </AppIconButton>
          </Box>
          <Box
            sx={{
              mt: 13,
              mb: 10,
              textAlign: "center",
              p: 1
            }}>
            <Typography variant="h2">{t('tasks')('editForm')}</Typography>
          </Box>
        </>
      ) : (
        <div className={styles.cancelButtonContainer}>
          <button
            onClick={() => {
              if (formRef.current?.dirty) {
                setUnsavedChangesDialogOpen(true);
              } else {
                cancel();
              }
            }}
            type="button"
            className={styles.cancelButton}
          >
            <img src={cancelSimpleIcon} alt="Cancel" />
          </button>
        </div>
      )}
      <AppDialog
        open={unsavedChangesDialogOpen}
        type="error"
        title={t('form')('unsavedChangesTitle')}
        onClose={() => setUnsavedChangesDialogOpen(false)}
        actions={
          <div className={styles.unsavedChangesButtons}>
            <AppButton variant="outlined" onClick={() => cancel()}>
              {t('form')('discard')}
            </AppButton>
            <AppButton short onClick={() => saveDraft()} inProgress={draftSaving}>
              {t('form')('saveChanges')}
            </AppButton>
          </div>
        }
      >
        {t('form')('unsavedChangesDescription')}
      </AppDialog>
      <div className={pageMode === 'regular' ? styles.clientFormContainer : styles.formContainer}>
        {taskAsset && formTemplateContent ? (
          <div>
            <header>
              <div className={styles.subHeader}>{t('assetTypes')(taskAsset.asset.type)}</div>
              <h2 className={styles.header}>{taskAsset.asset.name}</h2>
            </header>
            <hr className={styles.ruler} />
            <Formik<FormValues>
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              validate={(values) => {
                const errors = validateFormValues(values, formTemplateContent);
                const formikErrors: FormikErrors<FormValues> = {};

                Object.keys(errors).forEach((fieldName) => {
                  if (errors[fieldName] === 'mandatory') formikErrors[fieldName] = t('form')('fieldRequired');
                });

                return formikErrors;
              }}
              onSubmit={async (values) => {
                if (adhocAssetsIds) {
                  // eslint-disable-next-line no-restricted-syntax
                  for await (const adhocId of adhocAssetsIds) {
                    await updateTaskAssetForm({ variables: { id: adhocId, formValues: JSON.stringify(values) } });
                  }
                  cancel();
                } else {
                  await updateTaskAssetForm({ variables: { id: taskAssetId, formValues: JSON.stringify(values) } });
                  console.log("ABOUT TO SET EXPIRY", taskAssetId)
                  // Update to set the compliance (?) - Have the task Asset ID which is all thats needed. Doesn't require changes to existing save
                  await setComplianceExpiry(taskAssetId);

                  cancel();
                }
              }}
            >
              {({ values, handleSubmit, isSubmitting }) => {
                return (
                  <AppForm handleSubmit={handleSubmit}>
                    {formTemplateContent.map((field) => (
                      <div key={field.parameterName} className={styles.formFieldContainer}>
                        <div className={styles.formField}>
                          {field.type === FormContentType.TEXT ? (
                            <>
                              <div className={styles.labelElement}>
                                {field.mandatory ? `${field.parameterName}*` : field.parameterName}
                                <TooltipOnClick note={field.note} />
                              </div>
                              <AppTextField
                                required={field.mandatory}
                                placeholder={field.value}
                                name={field.parameterName}
                                type="text"
                              />
                            </>
                          ) : null}

                          {field.type === FormContentType.DATE ? (
                            <>
                              <div className={styles.labelElement}>
                                {field.mandatory ? `${field.parameterName}*` : field.parameterName}
                                <TooltipOnClick note={field.note} />
                              </div>
                              <AppDateField
                                required={field.mandatory}
                                placeholder={field.value}
                                name={field.parameterName}
                              />
                            </>
                          ) : null}

                          {field.type === FormContentType.NUMERIC ? (
                            <>
                              <div className={styles.labelElement}>
                                {field.mandatory ? `${field.parameterName}*` : field.parameterName}
                                <TooltipOnClick note={field.note} />
                              </div>
                              <AppNumberField
                                required={field.mandatory}
                                placeholder={
                                  field.minValue && field.maxValue
                                    ? t('form')('fieldPlaceholderRange', { a: field.minValue, b: field.maxValue })
                                    : undefined
                                }
                                name={field.parameterName}
                              />
                            </>
                          ) : null}

                          {field.type === FormContentType.BOOLEAN ? (
                            <>
                              <div className={styles.labelElement}>
                                {field.mandatory ? `${field.parameterName}*` : field.parameterName}
                                <TooltipOnClick note={field.note} />
                              </div>
                              <ButtonSelector
                                name={field.parameterName}
                                options={{
                                  array: field.values.map((fieldValue) => fieldValue.value),
                                  key: (value) => value,
                                  text: (value) => value,
                                }}
                                nullable
                              />
                            </>
                          ) : null}

                          {field.type === FormContentType.MULTIVALUE ? (
                            <>
                              <div className={styles.labelElement}>
                                {field.mandatory ? `${field.parameterName}*` : field.parameterName}
                                <TooltipOnClick note={field.note} />
                              </div>
                              <AppSelectField
                                name={field.parameterName}
                                required={field.mandatory}
                                options={{
                                  array: field.values.map((fieldValue) => fieldValue.value),
                                  key: (value) => value,
                                  value: (value) => value,
                                  template: (value) => value,
                                }}
                              />
                            </>
                          ) : null}

                          {field.type === FormContentType.MULTISELECT ? (
                            <>
                              <div className={styles.labelElement}>
                                {field.mandatory ? `${field.parameterName}*` : field.parameterName}
                                <TooltipOnClick note={field.note} />
                              </div>
                              <AppSelectMultipleField
                                name={field.parameterName}
                                required={field.mandatory}
                                options={{
                                  array: field.values.map((fieldValue) => fieldValue.value),
                                  key: (value) => value,
                                  value: (value) => value,
                                  template: (value) => value,
                                }}
                              />
                            </>
                          ) : null}

                          {field.type === FormContentType.FILES ? (
                            <FileInputPreviewUpload
                              addFileIconSrc={photoIcon}
                              name={field.parameterName}
                              label={field.parameterName}
                              required={field.mandatory}
                              multiple
                            />
                          ) : null}

                          {field.type === FormContentType.SIGNATURE ? (
                            <>
                              <FileInputPreviewUpload
                                addFileIconSrc={signatureIcon}
                                name={field.parameterName}
                                label={field.parameterName}
                                required={field.mandatory}
                                onInputClick={() => setAddingSignature(true)}
                              />
                              {addingSignature ? (
                                <TaskAssetSignature
                                  name={field.parameterName}
                                  onClose={() => setAddingSignature(false)}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </div>
                        <div className={styles.formFieldComplianceIcon}>
                          <ParameterComplianceIcon
                            parameterName={field.parameterName}
                            value={values[field.parameterName]}
                            formTemplateContent={formTemplateContent}
                          />
                        </div>
                      </div>
                    ))}
                    <div className={styles.formButtons}>
                      {taskAsset.status === TaskAssetStatus.COMPLETED ? (
                        <AppButton type="submit" className={styles.formButton} inProgress={isSubmitting}>
                          {t('common')('save')}
                        </AppButton>
                      ) : (
                        <>
                          <AppButton
                            type="button"
                            variant="outlined"
                            short
                            className={styles.formButton}
                            onClick={() => saveDraft()}
                            inProgress={draftSaving}
                          >
                            {t('tasks')('saveFormDraft')}
                          </AppButton>
                          <AppButton type="submit" className={styles.formButton} inProgress={isSubmitting}>
                            {t('tasks')('completeForm')}
                          </AppButton>
                        </>
                      )}
                    </div>
                  </AppForm>
                );
              }}
            </Formik>
          </div>
        ) : (
          <CircularProgressCentered />
        )}
      </div>
    </div>
  );
};

export default TaskAssetFormPage;
