import { createTheme, ThemeOptions } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import './theme.interface';

// font family
import NexaRegularWOFF2 from 'assets/fontFamily/NexaRegular.woff2';
import NexaRegularWOFF from 'assets/fontFamily/NexaRegular.woff';
import NexaRegularTTF from 'assets/fontFamily/NexaRegular.ttf';
import NexaBoldWOFF2 from 'assets/fontFamily/Nexa-Bold.woff2';
import NexaBoldWOFF from 'assets/fontFamily/Nexa-Bold.woff';
import NexaBoldTTF from 'assets/fontFamily/Nexa-Bold.ttf';
import NexaLightWOFF2 from 'assets/fontFamily/Nexa-Light.woff2';
import NexaLightWOFF from 'assets/fontFamily/Nexa-Light.woff';
import NexaLightTTF from 'assets/fontFamily/Nexa-Light.ttf';
import { format } from 'date-fns';

const nexaRegular = {
  fontFamily: 'Nexa',
  fontWeight: 400,
  src: `
    url(${NexaRegularWOFF2}) format('woff2'),
    url(${NexaRegularWOFF}) format('woff'),
    url(${NexaRegularTTF}) format('truetype')
  `,
};
const nexaBold = {
  fontFamily: 'Nexa',
  fontWeight: 700,
  src: `
    url(${NexaBoldWOFF2}) format('woff2'),
    url(${NexaBoldWOFF}) format('woff'),
    url(${NexaBoldTTF}) format('truetype')
  `,
};
const nexaLight = {
  fontFamily: 'Nexa',
  fontWeight: 300,
  src: `
    url(${NexaLightWOFF2}) format('woff2'),
    url(${NexaLightWOFF}) format('woff'),
    url(${NexaLightTTF}) format('truetype')
  `,
};

export function createAppTheme(options: ThemeOptions) {
  return createTheme({
    typography: {
      fontFamily: 'Nexa, sans-serif',
      fontSize: options.fontSizes['14'],
      body1: {
        fontSize: options.fontSizes['14'],
      },
      body2: {
        fontSize: options.fontSizes['12'],
      },
      h2: {
        fontSize: options.fontSizes['24'],
        fontWeight: nexaLight.fontWeight,
      },
      h3: {
        fontFamily: 'Nexa, sans-serif',
        fontSize: options.fontSizes['18'],
        fontWeight: nexaBold.fontWeight,
      },
      h4: {
        fontSize: options.fontSizes['16'],
        fontWeight: nexaBold.fontWeight,
      }
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'Nexa, sans-serif',
          },
          h3: {
            fontFamily: 'Nexa, sans-serif',
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "Nexa",
            src: `url(${NexaRegularTTF}) format('truetype'), url(${NexaRegularWOFF}) format('woff'), url(${NexaRegularWOFF2}) format('woff2'), url(${NexaBoldTTF}) format('truetype'), url(${NexaBoldWOFF}) format('woff'), url(${NexaBoldWOFF2}) format('woff2'), url(${NexaLightTTF}) format('truetype'), url(${NexaLightWOFF}) format('woff'), url(${NexaLightWOFF2}) format('woff2')`
          },
          body: {
            '& .MuiMenu-paper': {
              border: `solid 2px ${options.palette?.primary?.main}`,
              borderRadius: options.shape?.borderRadius,
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 12,
          }
        }
      }


      // MuiCssBaseline: {
      //   styleOverrides: `
      //     @font-face {
      //       font-family: 'Nexa';
      //       font-style: normal;
      //       font-display: swap;
      //       font-weight: 400;
      //       src: url(${NexaRegularTTF}) format('truetype'), url(${NexaRegularWOFF}) format('woff'), url(${NexaRegularWOFF2}) format('woff2'), url(${NexaBoldTTF}) format('truetype'), url(${NexaBoldWOFF}) format('woff'), url(${NexaBoldWOFF2}) format('woff2'), url(${NexaLightTTF}) format('truetype'), url(${NexaLightWOFF}) format('woff'), url(${NexaLightWOFF2}) format('woff2');
      //       unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      //     }
      //   `,
      // },
    
    
    
      // MuiCssBaseline: {
      //   styleOverrides: {
          // '@global': {
          //   '@font-face': [nexaRegular, nexaBold, nexaLight],
          //   body: {
          //     '& .MuiMenu-paper': {
          //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //       // @ts-ignore
          //       border: `solid 2px ${options.palette?.primary?.main}`,
          //       borderRadius: options.shape?.borderRadius,
          //     },
          //   },
          // },
        // }
      // },
    },
    ...options,
  });
}

const shadows = Array(25).fill('none') as Shadows;
shadows[1] = '0px 4px 8px rgba(0, 0, 0, 0.1)';
shadows[2] = '0px 4px 4px rgba(0, 0, 0, 0.25)';
shadows[3] = '2px 4px 4px rgba(0, 0, 0, 0.25)';
shadows[4] = '0px -4px 4px rgba(0, 0, 0, 0.15)';
shadows[5] = '0px 3px 3px rgba(0, 0, 0, 0.25)';
shadows[6] = '0px -3px 3px rgba(0, 0, 0, 0.25)';
export default createAppTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#000000',
    },
    primary: {
      main: '#44924D',
      light: '#9BBE57',
      contrastText: '#FFFFFF',
    },
    action: {
      active: '#69CC84',
    },
    error: {
      main: '#F15541',
    },
    grey: {
      50: '#F6F7FF',
      100: '#F3F4F9',
      300: '#E5E5E5',
      400: '#C4C4C4',
      500: '#9D9D9C',
      600: '#737373',
    },
    table: {
      main: '#D3DBFF',
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows,
  brandColors: {
    main: '#44924D',
    light: '#9BBE57',
    vivid: '#D6D954',
    dark: '#2A3148',
    black: '#000000',
    grey: '#9D9D9C',
  },
  colors: { yellow: '#F8E754', orange: '#F8AC54', blue: '#3E50B4' },
  sizes: {
    drawerWidth: 177,
    headerHeight: 92,
    mobileHeaderHeight: 60,
    defaultContainerPadding: {
      desktop: 24,
      mobile: 16,
    },
    button: {
      height: 40,
      minWidth: 130,
    },
    switch: {
      height: 26,
      width: 58,
    },
    containerWidth: 450,
    iconNormal: 20,
    iconMedium: 24,
  },
  dateFormat: {
    normal: (date: string | Date) => format(new Date(date), 'dd/MM/yyyy'),
    shortMonth: (date: string | Date) => format(new Date(date), 'dd MMM yyyy'),
    fullMonth: (date: string | Date) => format(new Date(date), 'MMMM dd, yyyy'),
    time: (date: string | Date) => format(new Date(date), 'h:mm'),
    dayPeriod: (date: string | Date) => (new Date(date).getHours() >= 12 ? 'PM' : 'AM'),
  },
  framerTransitions: {
    normal: { ease: 'easeOut', duration: 0.15 },
    slow: { ease: 'easeInOut', duration: 0.3 },
  },
  fontSizes: {
    10: 10,
    11: 11,
    12: 12,
    14: 14,
    16: 16,
    18: 18,
    20: 20,
    24: 24,
  },
});
