import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react';

import AppTabs from 'components/AppTabs';
import useCreateTabs from 'utils/useCreateTabs.hook';
import { useStores } from 'stores';
import { taskForUser_task as Task } from 'models/graphql';
import cn from 'utils/cn';
import TaskController from './TaskController';

const timerSize = 60;

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    marginBottom: {
      marginBottom: timerSize,
    },
    navigation: {
      position: 'sticky',
      top: 0,
      background: theme.palette.grey[100],
      zIndex: 1,
    },
  };
});
const TaskDetailsContainer: React.FC<{ baseUrl: string; task: Task }> = ({ children, baseUrl, task }) => {
  const { contextStore } = useStores();
  const isNavigationHidden = contextStore.isNavigationHidden;

  const tabs = useCreateTabs(baseUrl, [
    { name: 'Details', relativeUrl: '' },
    { name: 'Tasks', relativeUrl: '/assets' },
    { name: 'Report', relativeUrl: '/report' },
    { name: 'Attachments', relativeUrl: '/attachments' }
  ]);
  const styles = useStyles();
  return (
    <div className={cn(styles.container, !isNavigationHidden ? styles.marginBottom : null)}>
      {!isNavigationHidden && (
        <div className={styles.navigation}>
          <AppTabs tabs={tabs} normalCase scrollable />
        </div>
      )}

      <div>{children}</div>
      {!isNavigationHidden && <TaskController size={timerSize} task={task} mode />}
    </div>
  );
};
export default observer(TaskDetailsContainer);
