import React from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import blackBorderMapIcon from 'assets/blackBorderMapIcon.svg';
import { useNavigate } from 'react-router';
import cn from 'utils/cn';

const useStyles = makeStyles((theme: Theme) => ({
  value: {
    paddingBottom: theme.spacing(2),
    color: '#9F9F9F !important',
  },
  label: {
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  sectionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.grey['100'],
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    },
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr',
  },
  secondary: {
    color: '#9F9F9F',
    textDecoration: 'none !important',
  },
  section: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  pointer: {
    cursor: 'pointer',
  },
  iconContainerCircle: {
    width: '3em',
    minWidth: '3em',
    height: '3em',
    border: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey['300'],
    borderRadius: '3em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '0.5em',
    padding: '11px 7px 7px',
    '& > button': {
      border: '0',
      cursor: 'pointer',
    },
  },
}));

const DetailsPanelSection: React.FC<{
  label: string;
  values: { id: string; value: string; secondaryValue?: string; link?: string }[] | null | undefined;
  useSecondaryValues?: boolean;
  link?: string;
  emptyText?: string;
}> = ({ label, values, useSecondaryValues, emptyText = 'n/a' }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const isTablet = useMediaQuery('(max-width:1024px)');

  return (
    <div className={styles.section}>
      <Typography variant="body1" className={styles.label}>
        {label}
      </Typography>
      {values && values.length > 0 ? (
        values.map((value) =>
          useSecondaryValues ? (
            <div key={value.id} className={cn(styles.sectionItem, styles.gridContainer)}>
              <Typography
                className={value.link ? styles.pointer : undefined}
                onClick={value.link ? () => navigate(value.link!) : undefined}
              >
                <u>{value.value}</u>
              </Typography>
              <Typography align="right" className={styles.secondary}>
                {value.secondaryValue}
              </Typography>
            </div>
          ) : (
            <Box key={value.id} className={cn(styles.sectionItem, value.link ? styles.pointer : null)} sx={{
              mb: 1
            }}>
              <Typography variant="body1" onClick={value.link ? () => navigate(value.link!) : undefined}>
                <u>{value.value}</u>
              </Typography>
              {isTablet && label === 'Drawings' && (
                <Box className={styles.iconContainerCircle}>
                  <button type="button" onClick={value.link ? () => navigate(value.link!) : undefined}>
                    <img src={blackBorderMapIcon} alt="icon" />
                  </button>
                </Box>
              )}
            </Box>
          ),
        )
      ) : (
        <Typography variant="body1" className={styles.value}>
          {emptyText}
        </Typography>
      )}
    </div>
  );
};

export default DetailsPanelSection;
