import gql from 'graphql-tag';

const GET_ASSET_REGISTER = gql`
query getAssetForRegister(
  $assetId: ID!
  $pagination: PaginationInput
) {
  pagedAssetsById(
    id: $assetId,
    pagination: $pagination
  ) {
    nodes {
      id
      department
      system {
        id
        type
        name
      }
      name
      type
      createdAt
      owner {
        id
        name
        picture {
          srcUrl
        }
      }
      status
      coordinates {
        longitude
        latitude
      }
      additionalInformation
      drawings {
        id
        drawingId
        file {
          id
          originalName
          extension
        }
      }
      upcomingWorks {
        id
        type
      }
      recentWorks {
        id
        type
      }
      
    }
    page
      totalPages
      totalNodes
  }
}
`;

export default GET_ASSET_REGISTER;
