import { useStores } from 'stores';
import { Role } from 'models/graphql';
import { CLIENT_ROLES } from 'constants/roleGroups';
import { useEffect, useState } from 'react';
import { matchRoutes } from 'react-router';

export enum Mode {
  NONE = 'NONE',
  PM = 'PM',
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
  OPERATIVE = 'OPERATIVE',
  TECHNICIAN = 'TECHNICIAN',
}

type None = { mode: Mode.NONE; mobile: boolean };
type Pm = { mode: Mode.PM; mobile: boolean };
type Client = { mode: Mode.CLIENT; id: string; mobile: boolean };
type Contractor = { mode: Mode.CONTRACTOR; id: string; mobile: boolean };
type Operative = { mode: Mode.OPERATIVE; id: string; mobile: boolean };
type Technician = { mode: Mode.TECHNICIAN; id: string; mobile: boolean };
export type ModeResult = None | Pm | Client | Contractor | Operative | Technician;

const getMatch = (location: Location, path: string) => {
  let firstMatch;
  const matches = matchRoutes([
    { path },
  ], location);
  if (matches && matches.length > 0) {
    firstMatch = matches[0]
  }
  return firstMatch;
}

export default function useMode(): ModeResult {
  const { authStore } = useStores();
  const [role, setRole] = useState(authStore.user?.role);
  const [userId, setUserId] = useState(authStore.user?.id);

  const isPm = getMatch(location,  `/pm/*`);
  const isClient = getMatch(location,  '/client/:clientId/*');
  const isContractor = getMatch(location, '/contractor/:contractorId/*');
  const isOperative = getMatch(location, '/operative/*');
  const isTechnician = getMatch(location, '/technician/*');
  
  useEffect(() => {
    setRole(authStore.user?.role);
    setUserId(authStore.user?.id);
  }, [authStore.user?.role, authStore.user?.id]);

  if (isPm) {
    if (role !== Role.PROJECT_MANAGER) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.PM, mobile: true };
  }
  if (isClient) {
    if (!role || ![Role.PROJECT_MANAGER, ...CLIENT_ROLES.filter((r) => r !== Role.CLIENT_OPERATIVE)].includes(role)) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.CLIENT, id: isClient.params.clientId, mobile: true };
  }
  if (isContractor) {
    if (!role || ![Role.PROJECT_MANAGER, Role.CONTRACTOR_OPERATIONS_MANAGER].includes(role)) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.CONTRACTOR, id: isContractor.params.contractorId, mobile: true };
  }
  if (isOperative) {
    if (!role || !userId || ![Role.CLIENT_OPERATIVE].includes(role)) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.OPERATIVE, id: userId, mobile: true };
  }
  if (isTechnician) {
    if (!role || !userId || ![Role.CONTRACTOR_TECHNICIAN].includes(role)) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.TECHNICIAN, id: userId, mobile: true };
  }

  return { mode: Mode.NONE, mobile: true };
}
