import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Theme, Box, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Formik } from 'formik';
import * as R from 'ramda';
import { useQuery, useMutation } from '@apollo/client';

import {
  AssetType,
  clientSitesAssetTypes,
  clientSitesAssetTypesVariables,
  formTemplateCreateOrUpdate,
  formTemplateActivate,
  formTemplateCreateOrUpdateVariables,
  formTemplateActivateVariables,
  TaskType,
} from 'models/graphql';
import useText from 'texts/useText.hook';
import AppSelectField from 'components/form/AppSelectField';
import AppDialog from 'components/AppDialog';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import assetAvailableTaskTypes from 'shared/assetAvailableTaskTypes';
import AppButton from 'components/AppButton';
import useGetExportFormVariables from 'components/DownloadUploadButtons/HandleDowloadFormTemplate';
import useGetExportMultiFormVariables from 'components/DownloadUploadButtons/HandleDowloadMultiFormTemplate';
import styleUtils from 'style/styleUtils';
import { GET_LICENSEPAGE_DATA, CREATE_OR_UPDATE_FORMTEMPLATE, ACTIVATE_FORMTEMPLATE } from '../graphql';
import FormTemplate from './FormTemplate';
import EditorForm, { EditorFormValues } from './EditorForm';
import Licenses from './Licenses';
import ReportButtonsBlock, { FileInfo } from './ReportButtonsBlock';
import { AppTextField } from 'components/form';
import { ReactComponent as ReportIconGreen } from "assets/reportIconGreen.svg";
import { ReactComponent as EditIcon } from "assets/editIcon.svg";
import { ReactComponent as CancelIcon } from "assets/cancelSimpleIcon.svg";
import AppIconButton from 'components/AppIconButton';/* eslint-disable-line */
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { AppTooltip } from 'components/AppTooltip';
import { useNavigate, useParams } from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    uploads: {
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      fontSize: '14px',
    },
    blockWrapper: {
      display: 'flex',
      flexDirection: 'column',
      '&:not(:last-child)': {
        marginRight: '80px',
      },
    },
    blockWrapperUpdate: {
      display: 'flex',
      flexDirection: 'column',
      '&:not(:last-child)': {
        marginRight: '10px',
      },
    },
    blockWrapperUpdateText: {
      display: 'flex',
      flexDirection: 'column',
      '&:not(:last-child)': {
        marginRight: '30px',
      },
    },
    pageSection: {
      fontSize: theme.fontSizes['18'],
    },
    pageSectionSmall: {
      fontSize: theme.fontSizes['12'],
    },
    filter: {
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
    selectField: {
      width: theme.sizes.containerWidth,
      maxWidth: '100%',
    },
    center: {
      width: '100%',
      height: '100%',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
    },
    linkButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: 0,
      margin: '16px',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      width: 'min-content',
      '& > img': {
        paddingRight: theme.spacing(1),
      },
      '& > div': {
        whiteSpace: 'nowrap',
      },
    },
    formAreaWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: 'min(100%, 1150px)',
    },
    formArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
    },
    frequency: {
      color: '#9F9F9F',
    },
    areaSection: {
      fontWeight: 600,
      fontSize: '1.5em',
      paddingTop: '1.5em',
      paddingBottom: '0.5em',
    },
    formTitle: {
      fontSize: '14px',
      paddingTop: '2em',
      paddingBottom: '1em',
      fontWeight: 'bold',
    },
    formTitleUpdate: {
      width:'300px;'
    },
    addButton: {
      marginTop: theme.spacing(4),
    },
    updateButton: {
      marginTop: theme.spacing(3),
    },
    cancelButton: {
      marginTop: theme.spacing(3),
      marginLeft:theme.spacing(1),
    },
    indexer: {
      fontSize: '30px',
      fontWeight: 100,
      paddingLeft: '5px',
      paddingRight: '5px',
      lineHeight: '10px',
    },
    downloadReports: {
      '& > div': {
        marginTop: '15px',
        '&:first-child': {
          marginTop: '26px',
        },
      },
    },
    downloadReport: {
      display: 'flex',
      fontSize: '14px',
    },
    toggleContainer: {
      display: 'flex',
      paddingLeft: '1em',
      marginRight: 100,
      justifyContent: 'space-between',
    },
    toggleBox: {
      display: 'flex',
      flexDirection: 'column',
    },
    toggleTitle: {
      fontSize: 14,
      paddingBottom: '0.5em',
    },
    fileInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    fileName: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      color: '#44924D',
    },
    root: {
      width: theme.sizes.switch.width,
      height: theme.sizes.switch.height,
      padding: 0,
      margin: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(32px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: theme.sizes.switch.height - 2,
      height: theme.sizes.switch.height - 2,
      boxShadow: 'none',
    },
    track: {
      borderRadius: theme.sizes.switch.height / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey['400'],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    loader: {
      ...styleUtils.rowCenterCenter,
      marginTop: theme.spacing(3),
    },
  });
const useStyles = makeStyles(styles);

interface FormValues {
  site: string;
}


export interface subFormStruc {
  id: string;
  formtype: string;
  assettype: string;
  active: boolean;
  subform: string;
}

const testSubFormList = ['Default', 'New']

const Indexer: React.FC<{ paddingTop: number }> = ({ paddingTop }) => {
  const classes = useStyles();
  return (
    <div className={classes.indexer} style={{ paddingTop: `${paddingTop}px` }}>
      &gt;
    </div>
  );
};

const ClientLicensePage: React.FC = () => {
  const classes = useStyles();
  const { t } = useText('clients', 'assetTypes', 'common', 'taskTypes','modules');
  const { clientId } = useParams<{ clientId: string }>();
  const navigate = useNavigate();

  const [subFormsListing, setSubFormsListing] = useState<subFormStruc[] | undefined>();
  const [selectedTaskType, setSelectedTaskType] = useState<TaskType | null>(null);
  const [subFormsList, setSubFormsList] = useState<[] | []>();

  const [newFormTitle, setNewFormTitle] = useState<boolean | false>(false);
  const [newFormTitleValue, setNewFormTitleValue] = useState<string | null>(null);
  const [selectedSubFormTemplate, setSelectedSubFormTemplate] = useState<string | null>(null);
  const [selectedSubFormTemplateNewName, setSelectedSubFormTemplateNewName] = useState<string | null>(null);
  const [subFormTitleEdit, setSubFormTitleEdit] = useState<boolean | false>(false);


  const [displayFormTitle, setDisplayFormTitle] = useState<string | null>(null);
 
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
  const [selectedModules, setSelectedModules] = useState<string[] | null>();
  const [selectedModule, setSelectedModule] = useState<string | null>();

  const [modulesAvailable, setModulesAvailable] = useState<boolean | false>(false);


  const [selectedAssetType, setSelectedAssetType] = useState<AssetType | null>(null);
  const [selectedFormTemplate, setSelectedFormTemplate] = useState<TaskType | null>(null);



  const [activeRef, setActiveRef] = useState<React.RefObject<HTMLElement> | null>(null);
  const [selectedKey, setSelectedKey] = useState<string>('');

  const formColumnRef = useRef<HTMLDivElement>(null);
  const [paddingTop, setPaddingTop] = useState(0);
  const [activeElementHalfHeight, setActiveElementHalfHeight] = useState(0);

  const [singleReportFileName, setSingleReportFileName] = useState<string>();
  const [singleReportFileId, setSingleReportFileId] = useState<string>();
  const [multiReportFileName, setMultiReportFileName] = useState<string>();
  const [multiReportFileId, setMultiReportFileId] = useState<string>();
  const [allowSingleReport, setAllowSingleReport] = useState<boolean>();
  const [allowMultiReport, setAllowMultiReport] = useState<boolean>();
  const [docsRequiredVal, setDocsRequiredVal] = useState<boolean>();
  const [canceling, setCanceling] = useState(false);
  const editorRef = useRef<{ formChanged: () => boolean }>(null);
  const exportFormVariables = useGetExportFormVariables();
  const exportMultiFormVariables = useGetExportMultiFormVariables();
  const subFormValue = '';


  const { loading: sitesLoading, data: sitesData,  refetch } = useQuery<clientSitesAssetTypes, clientSitesAssetTypesVariables>(
    GET_LICENSEPAGE_DATA,
    { variables: { id: clientId } },
  );


  const handleRename = () => {
    // Call the refetch function to fetch the data again
    refetch();
  };


  const [createOrUpdateFormTemplate, { loading }] = useMutation<
    formTemplateCreateOrUpdate,
    formTemplateCreateOrUpdateVariables
  >(CREATE_OR_UPDATE_FORMTEMPLATE, {
    update: (cache, { data }) => {
      apolloCacheEvict({ cache, typename: 'Client', id: clientId });
      if (data) {
        apolloCacheEvict({
          cache,
          typename: 'Site',
          id: data?.formTemplateCreateOrUpdate.siteId,
          fieldName: 'tasks',
        });
      }
    },
  });

  useEffect(() => {
    setSelectedSubFormTemplateNewName(selectedSubFormTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFormTitleEdit]);



  useEffect(() => {
    console.log("Sub FOrms Have Been Updated")
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (subFormsListing)
    {
      let selFormTypes;
      const selTaskTypes = subFormsListing.find(group => group[0].assettype === selectedAssetType);
      if (selTaskTypes)
      {
        const groupedData = selTaskTypes.reduce((result, item) => {
          const index = result.findIndex(group => group[0].formtype === item.formtype);
          if (index !== -1) {
            result[index].push(item);
          } else {
            result.push([item]);
          }
          return result;
        }, []);
        console.log("LINE 362 in useeffect . groupedData:", groupedData)

        if (selectedFormTemplate)
        {
          selFormTypes = groupedData.find(group => group[0].formtype === selectedFormTemplate);
          console.log("LINE 366 in useeffect subforms. selFormTypes:", selFormTypes)
        }

      }
      let subformslist: any[]=[];
      if (selFormTypes)
      {
        selFormTypes.forEach((form: any) => {
          if (!subformslist.includes(form.subform)) {
            subformslist.push(form.subform.charAt(0).toUpperCase() + form.subform.slice(1).toLowerCase());
          }
        });
      }
      console.log("subformslist:", subformslist)
      subformslist.push("New");
  
      setSubFormsList(subformslist);

      if (selectedSubFormTemplate)
      {
        let selectableFormTemplatesAgain = selectedAssetType
        ? selectedSite?.formTemplates.filter(({ assetType }) => assetType === selectedAssetType) || []
        : [];

        const newSelectedFormTemplate = (selectableFormTemplatesAgain?.filter(item => 
          item.assetType === selectedAssetType && 
          item.formType === selectedTaskType && 
          item.subForm === selectedSubFormTemplateNewName
        ))?.find(({ subForm }) => subForm === selectedSubFormTemplateNewName);

        console.log("newSelectedFormTemplate: at line 448", newSelectedFormTemplate)


      }


    }
   



  }, [subFormsListing]);

  
  function renamesub(){
    // get title of new form and current siteid, asset type, form type and selected subform
    console.log("Time to rename")
    let siteId =  selectedSiteId;
    let assetType = selectedAssetType ;
    let formType =  selectedFormTemplate;
    let subForm =  selectedSubFormTemplate;
    let newname = selectedSubFormTemplateNewName;

    console.log("Current Values. Site:", siteId)
    console.log("Current Values. assetType:", assetType)
    console.log("Current Values. formType:", formType)
    console.log("Current Values. subForm:", subForm)
    console.log("Current Values. newname:", newname)

    const urltogoto = 'forms/renamesubform/';

    let changestomake = {
        siteid: selectedSiteId,
        assettype: selectedAssetType,
        formtype: selectedFormTemplate,
        subform: selectedSubFormTemplate,
        newname: selectedSubFormTemplateNewName
    }

    try {
      fetch(`${process.env.API_ROOT}${urltogoto}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(changestomake),
      }).then(async (response) => {
        const respval = await response.json();
        console.log(respval);
        setSelectedSubFormTemplate(selectedSubFormTemplateNewName);
        setSubFormTitleEdit(false);

       
        await fetchSubForms(selectedSiteId).then((response) => {
          if (response)
          {
            console.log("response back at line 380", response)
            // Do refetch
            refetch();
            setDisplayFormTitle(selectedSubFormTemplateNewName);

            const groupedData = response.reduce((result, item) => {
              const index = result.findIndex(group => group[0].assettype === item.assettype);
              if (index !== -1) {
                result[index].push(item);
              } else {
                result.push([item]);
              }
              return result;
            }, []);
            setSubFormsListing(groupedData);

     

          }
         });
      







 


      })
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }




  const [activateFormTemplate] = useMutation<formTemplateActivate, formTemplateActivateVariables>(
    ACTIVATE_FORMTEMPLATE,
    {
      update: (cache, { data }) => {
        apolloCacheEvict({ cache, typename: 'Client', id: clientId });
        if (data) {
          apolloCacheEvict({
            cache,
            typename: 'Site',
            id: data?.formTemplateActivate.siteId,
            fieldName: 'tasks',
          });
        }
      },
    },
  );

  const selectedSite = sitesData?.client.sites.find(({ id }) => id === selectedSiteId);
  const selectableAssetTypes = selectedSite && R.uniq(selectedSite.assets.map((asset) => asset.type));

  const selectableFormTemplates = selectedAssetType
    ? selectedSite?.formTemplates.filter(({ assetType }) => assetType === selectedAssetType) || []
    : [];

  const onSelectedKeyChange = (key: string) => {
    const editorChanged = editorRef?.current?.formChanged();
    if (editorChanged) {
      setCanceling(true);
      return true;
    }
    if (!key) {
      setActiveRef(null);
    }
    setSelectedKey(key);
    return false;
  };



  const onFormTemplateChange = (templateName: TaskType) => {
    onSelectedKeyChange('');

    let selTemp = selectedTaskType;

    const editorChanged = editorRef?.current?.formChanged();
    if (editorChanged) {
      setCanceling(true);
      return true;
    }

    setDisplayFormTitle(templateName);
    setSelectedFormTemplate(selTemp);

    setSelectedSubFormTemplate(templateName)

    
    if (templateName === 'New')
    {

        // If new form, prompt for name of form
          setNewFormTitle(true)

    }
    else
    {

          console.log("Selected Asset Type", selectedAssetType)
          console.log("Selected Form Type", selectedTaskType)
          console.log("Passed SubForm Name:", templateName)


          console.log("Line 362 selectableFormTemplates:", selectableFormTemplates)
        
        // const newSelectedFormTemplate = selectableFormTemplates?.find(({ formType }) => formType === templateName);
          //const newSelectedFormTemplate = selectableFormTemplates?.find(({ subForm }) => subForm === templateName);

          //const newSelectedFormTemplate = selectableFormTemplates?.find(({ subForm }) => subForm === templateName);
          //const newSelectedFormTemplate = selectableFormTemplates?.find(({ subForm }) => subForm === templateName);

          const newSelectedFormTemplate = (selectableFormTemplates?.filter(item => 
            item.assetType === selectedAssetType && 
            item.formType === selectedTaskType && 
            item.subForm === templateName
          ))?.find(({ subForm }) => subForm === templateName);

          console.log("newSelectedFormTemplate: at line 366", newSelectedFormTemplate)

          setSingleReportFileName(newSelectedFormTemplate?.fileSingleTemplate?.originalName);
          setSingleReportFileId(newSelectedFormTemplate?.fileSingleTemplate?.id);
          setMultiReportFileName(newSelectedFormTemplate?.fileMultiTemplate?.originalName);
          setMultiReportFileId(newSelectedFormTemplate?.fileMultiTemplate?.id);
          setAllowSingleReport(newSelectedFormTemplate?.allowSingleReportPdf);
          setAllowMultiReport(newSelectedFormTemplate?.allowMultiReportPdf);
          setDocsRequiredVal(newSelectedFormTemplate?.docsRequired);

        // setSelectedFormTemplate(templateName);
        setSelectedFormTemplate(selTemp);

        setSelectedSubFormTemplate(templateName)

    }
    return false;
    
  };

  const handleClick = (ref: React.RefObject<HTMLElement>, refs: React.RefObject<HTMLElement>[]) => () => {
    if (activeRef === ref) {
      setActiveRef(null);
    } else if (ref.current) {
      setActiveRef(ref);
    }

    // UI manipulation through refs
    const offset = 0;
    const firstElementTop = refs[0] && refs[0].current?.getBoundingClientRect().top;
    const activeElement = ref.current?.getBoundingClientRect();
    const activeElementTop = activeElement?.top || 0;
    setPaddingTop(Math.max(activeElementTop - (firstElementTop || 0) + offset, 0));
    setActiveElementHalfHeight((activeElement?.height || 0) / 2);
  };

  function getActiveFormTemplate() {
   // return selectableFormTemplates?.find(({ formType }) => formType === selectedFormTemplate);
   let  foundrec = selectableFormTemplates?.filter(item => 
    item.assetType === selectedAssetType && 
    item.formType === selectedTaskType && 
    item.subForm === selectedSubFormTemplate
  );
  console.log("line 421 foundrec:",foundrec)
  return foundrec?.find(({ subForm }) => subForm === selectedSubFormTemplate);
  }

  const getActiveFormTemplateContent = () => {
    console.log("IN HERE AT LINE 624. selectedSubFormTemplate:", selectedSubFormTemplate)
   //const content = selectableFormTemplates?.find(({ formType }) => formType === selectedFormTemplate)?.content;
   //const content = selectableFormTemplates?.find(({ subForm }) => subForm === selectedSubFormTemplate)?.content;
   const foundrec = selectableFormTemplates?.filter(item => 
    item.assetType === selectedAssetType && 
    item.formType === selectedTaskType && 
    item.subForm === selectedSubFormTemplate
  );
    console.log("LINE 632 foundrec", foundrec)
  const content = foundrec?.find(({ subForm }) => subForm === selectedSubFormTemplate)?.content;
  console.log("content:",content)
    return content ? JSON.parse(content) : '';
  };

  const onUpdate = async (values: Partial<EditorFormValues>) => {
    const formTemplate = getActiveFormTemplate();
    if (!formTemplate) throw new Error('Missing form template');
    const content = JSON.parse(formTemplate.content);
    const parameterIndex = content.findIndex(
      ({ parameterName }: { parameterName: string }) => parameterName === selectedKey,
    );
    if (!selectedSiteId) return;
    const removeProps = R.omit(['selectedAssetIds', 'selectableAssets']);
    const omittedValues = removeProps(values);
    if (parameterIndex > -1) {
      const updated = R.assocPath([parameterIndex], omittedValues, content);
      await createOrUpdateFormTemplate({
        variables: {
          siteId: selectedSiteId,
          assetType: selectedAssetType as AssetType,
          formType: selectedFormTemplate as TaskType,
          subForm: selectedSubFormTemplate,
          content: JSON.stringify(updated),
          allowSingleReportPdf: !!allowSingleReport,
          allowMultiReportPdf: !!allowMultiReport,
          docsRequired: !!docsRequiredVal,
          module: selectedModule
        },
      });
     
    } else {
      await createOrUpdateFormTemplate({
        variables: {
          siteId: selectedSiteId,
          assetType: selectedAssetType as AssetType,
          formType: selectedFormTemplate as TaskType,
          subForm: selectedSubFormTemplate ,
          content: JSON.stringify([omittedValues, ...content]),
          allowSingleReportPdf: !!allowSingleReport,
          allowMultiReportPdf: !!allowMultiReport,
          docsRequired: !!docsRequiredVal,
          module: selectedModule
        },
      });
    
    }
    setActiveRef(null);
    setSelectedKey('');
    if (newFormTitle)
    {
        console.log("Line 465, will set new form title to false")
        setNewFormTitle(false)
    }
  };

  const onOrderUpdate = async (values: (JSX.Element | null)[]) => {
    const formTemplate = getActiveFormTemplate();
    if (!formTemplate) throw new Error('Missing form template');
    const content = JSON.parse(formTemplate.content);

    const orderedValues: Partial<EditorFormValues>[] = [];
    values.forEach((el: JSX.Element | null) => {
      if (el && el.key !== '_add_parameter') {
        content.forEach((el2: Partial<EditorFormValues>) => {
          if (el.key === el2.parameterName) orderedValues.push(el2);
        });
      }
    });

    if (!selectedSiteId) return;

    await createOrUpdateFormTemplate({
      variables: {
        siteId: selectedSiteId,
        assetType: selectedAssetType as AssetType,
        formType: selectedFormTemplate as TaskType,
        subForm: selectedSubFormTemplate ,
        content: JSON.stringify(orderedValues),
        allowSingleReportPdf: !!allowSingleReport,
        allowMultiReportPdf: !!allowMultiReport,
        docsRequired: !!docsRequiredVal,
        module: selectedModule
      },
    });
  };


  const fetchSubForms = async (siteId: any) => {
    // Perform your data fetching logic here
    // For example, make an API call using axios or fetch
    const urltogoto = `forms/subforms`;
    let dataret: any [];
    let dataretforms: any [];
    let dataretassets: any [];
    let fullurl = `forms/subforms?siteid=${siteId}`;


    const response = await fetch(`${process.env.API_ROOT}${fullurl}`, {
      method: 'GET',
      credentials: 'include',
    })
    const data = await response.json();
    console.log("data", data);
    return data;
  };

  const updateAllowed = async (allowSingleReportPdf: boolean, allowMultiReportPdf: boolean) => {
    const formTemplate = getActiveFormTemplate();
    if (!formTemplate) throw new Error('Missing form template');
    const content = JSON.parse(formTemplate.content);

    if (!selectedSiteId) return;

    await createOrUpdateFormTemplate({
      variables: {
        siteId: selectedSiteId,
        assetType: selectedAssetType as AssetType,
        formType: selectedFormTemplate as TaskType,
        subForm:selectedSubFormTemplate,
        content: JSON.stringify(content),
        allowSingleReportPdf,
        allowMultiReportPdf,
        module: selectedModule
      },
    });
  };

  const updateAllowedDocs = async (docsRequired: boolean) => {
    console.log("Have been passed docsRequired:", docsRequired)
    const formTemplate = getActiveFormTemplate();
    if (!formTemplate) throw new Error('Missing form template');
    const content = JSON.parse(formTemplate.content);

    if (!selectedSiteId) return;

    await createOrUpdateFormTemplate({
      variables: {
        siteId: selectedSiteId,
        assetType: selectedAssetType as AssetType,
        formType: selectedFormTemplate as TaskType,
        subForm:selectedSubFormTemplate,
        content: JSON.stringify(content),
        docsRequired,
        module: selectedModule
      },
    });
  };


  const onDelete = async () => {
    if (selectedKey === 'Frequency') return;
    const formTemplate = getActiveFormTemplate();
    if (!formTemplate) throw new Error('missing form tempalte');
    const content = JSON.parse(formTemplate.content);
    const updated = R.reject(({ parameterName }) => parameterName === selectedKey, content);
    if (selectedSiteId) {
      await createOrUpdateFormTemplate({
        variables: {
          siteId: selectedSiteId,
          assetType: selectedAssetType as AssetType,
          formType: selectedFormTemplate as TaskType,
          subForm: selectedSubFormTemplate,
          content: JSON.stringify(updated),
          module: selectedModule,
        },
      });
    }
    setActiveRef(null);
    setSelectedKey('');
  };

  const onCancel = () => {
    setActiveRef(null);
    setSelectedKey('');
    setCanceling(false);
  };

  function getActiveFormTemplateParameterNames() {
    const formTemplate = getActiveFormTemplate();
    console.log("formTemplate at line 607 in getActiveFormTemplateParameterNames", formTemplate)
    if (!formTemplate) return [];
    const content = JSON.parse(formTemplate.content);
    return content.map((formItem: { parameterName: string }) => formItem.parameterName);
  }

  function getActiveFormTemplateItem() {
    const formTemplate = getActiveFormTemplate();
    console.log("line 609. Form Template:",formTemplate)
    if (!formTemplate) return {};
    const content = JSON.parse(formTemplate.content);
    if (selectedKey) {
      return content.find(R.propEq(selectedKey, 'parameterName')) || {};
    }
    return {};
  }

  if (!sitesLoading && !sitesData?.client) {
    return null;
  }

  if (sitesData?.client.sites.length === 0) {
    return (
      <AppDialog
        open
        onClose={() => navigate(`/pm/clients/${clientId}/subscription`)}
        type="error"
        title="Can not set Environmental License"
      >
        {t('clients')('noSitesForClient')}
      </AppDialog>
    );
  }
 

  useEffect(() => {
    if (sitesData)
    {
      console.log("sitesData.client.modules:", sitesData.client.modules)

     
      if (sitesData.client.modules !== null)
      {
       // Convert the modules object to an array of entries


        // Filter modules to include only those with true values
        const filteredModules = Object.entries(sitesData.client.modules)
        .filter(([key, value]) => !value) // Filter by value being true
        .map(([key]) => key); // Extract the key



        // State to manage the selected option
       // const [selectedModule, setSelectedModule] = useState(filteredModules[0] || '');


       console.log("filteredModules:", filteredModules);

       if (filteredModules.length > 0)
       {
        setSelectedModules(filteredModules)
        setModulesAvailable(true);
       }

   
      }

 
    }

  }, [sitesData]);



  const initValues = {
   /* allowSingleReportPdf: selectableFormTemplates?.find(({ subForm }) => subForm === selectedSubFormTemplate)
      ?.allowSingleReportPdf,
    allowMultiReportPdf: selectableFormTemplates?.find(({ subForm }) => subForm === selectedSubFormTemplate)
      ?.allowMultiReportPdf,
    docsRequired: selectableFormTemplates?.find(({ subForm }) => subForm === selectedSubFormTemplate)
      ?.docsRequired,  */
    allowSingleReportPdf: (selectableFormTemplates?.filter(item => item.assetType === selectedAssetType && item.formType === selectedTaskType && item.subForm === selectedSubFormTemplate))?.find(({ subForm }) => subForm === selectedSubFormTemplate)
      ?.allowSingleReportPdf,
    allowMultiReportPdf:(selectableFormTemplates?.filter(item => item.assetType === selectedAssetType && item.formType === selectedTaskType && item.subForm === selectedSubFormTemplate))?.find(({ subForm }) => subForm === selectedSubFormTemplate)
      ?.allowMultiReportPdf,
    docsRequired:(selectableFormTemplates?.filter(item => item.assetType === selectedAssetType && item.formType === selectedTaskType && item.subForm === selectedSubFormTemplate))?.find(({ subForm }) => subForm === selectedSubFormTemplate)
      ?.docsRequired,
  };

  const loadingUpdate = () => {
    return (
      initValues.allowSingleReportPdf !== !!allowSingleReport || initValues.allowMultiReportPdf !== !!allowMultiReport ||  initValues.docsRequired !== !!docsRequiredVal
    );
  };

  return sitesLoading ? (
    <div className={classes.center}>
      <CircularProgress />
    </div>
  ) : (
    <Formik<FormValues>
      initialValues={{ site: '' }}
      onSubmit={() => {
        // do nothing
      }}
    >
      <>
        <AppSelectField
          label="Site"
          name="site"
          className={classes.selectField}
          options={{
            array: sitesData!.client.sites,
            value: (site) => site.id,
            key: (site) => site.id,
            template: (site) => site.name,
          }}
          onChange={async (site) => {
            setSelectedSiteId(site.id);
   
            setSelectedAssetType(null);
            setSelectedFormTemplate(null);
            setSelectedSubFormTemplate(null);

            console.log("About to fetch subforms")

            // get the subforms
            await fetchSubForms(site.id).then((response) => {
              if (response)
              {
              
                  const groupedData = response.reduce((result, item) => {
                    const index = result.findIndex(group => group[0].assettype === item.assettype);
                    if (index !== -1) {
                      result[index].push(item);
                    } else {
                      result.push([item]);
                    }
                    return result;
                  }, []);

          
                setSubFormsListing(groupedData);
              }
        

             })
          }}
        />
   {selectedSiteId && selectedSite && selectedModules && selectedModules.length > 0 && (
  
      <>
    
         <div className={classes.areaSection}>Choose Module</div>

        <AppSelectField
        label="Module"
        name="module"
        className={classes.selectField}
        options={{
          array: selectedModules,
          value: (module) => module,
          key: (module) => module,
          template: (module)  => t('modules')(module)
        }}
        onChange={async (module) => {
          console.log("Selected module:", module)
            setModulesAvailable(false)
            setSelectedModule(module)
        }}

        />
      </>)}


        {selectedSiteId && selectedSite && !modulesAvailable && (
          <>
            <div className={classes.areaSection}>License file</div>
            <Licenses clientId={clientId} siteId={selectedSiteId} selectedSite={selectedSite} />
            <div className={classes.areaSection}>Parameters</div>
            {selectableAssetTypes ? (
              <Box sx={{
                display: "flex"
              }}>
                <AppSelectField
                  label="Asset Types"
                  name="assetTypes"
                  className={classes.selectField}
                  options={{
                    array: selectableAssetTypes,
                    value: (assetType) => assetType,
                    key: (assetType) => assetType,
                    template: (assetType) => t('assetTypes')(assetType),
                    ifArrayEmpty: 'No Assets uploaded for the Site',
                  }}
                  value={selectedAssetType || ''}
                  onChange={(assetType) => {
                    setSelectedAssetType(assetType);
                    setSelectedFormTemplate(null);
                    setSelectedSubFormTemplate(null);
                  }}
                />
                {selectedAssetType && (
                  <Box sx={{
                    marginLeft: 3
                  }}>
                    <AppSelectField
                      label="Form Type"
                      name="formTypes"
                      className={classes.selectField}
                      disabled={!selectedAssetType}
                      options={{
                        array: assetAvailableTaskTypes[selectedAssetType],
                        value: (formtTemplateType) => formtTemplateType,
                        key: (formtTemplateType) => formtTemplateType,
                        template: (formtTemplateType) => `${t('taskTypes')(formtTemplateType)} ${t('common')('form')}`,
                      }}
                      value={selectedTaskType || ''}
                      onChange={(taskType) => {
                        setSelectedTaskType(taskType);
                        let selFormTypes;
                        const selTaskTypes = subFormsListing.find(group => group[0].assettype === selectedAssetType);
                        if (selTaskTypes)
                        {
                          const groupedData = selTaskTypes.reduce((result, item) => {
                            const index = result.findIndex(group => group[0].formtype === item.formtype);
                            if (index !== -1) {
                              result[index].push(item);
                            } else {
                              result.push([item]);
                            }
                            return result;
                          }, []);
                          console.log("groupedData:", groupedData)
                          selFormTypes = groupedData.find(group => group[0].formtype === taskType);
                          if (selectedModule)
                          {
                            selFormTypes = groupedData.find(group => group[0].formtype === taskType && group[0].module === selectedModule );
                          }
                          console.log("selFormTypes:", selFormTypes)

                        }
                     
                       
                        let subformslist: any[]=[];
                        if (selFormTypes)
                        {
                          selFormTypes.forEach((form: any) => {
                            if (!subformslist.includes(form.subform)) {
                              subformslist.push(form.subform.charAt(0).toUpperCase() + form.subform.slice(1).toLowerCase());
                            }
                          });
                        }
                        console.log("subformslist:", subformslist)
                        subformslist.push("New");
                    
                        setSubFormsList(subformslist);
                  
                    
                      }}

                    />
                  </Box>
                )}
                {selectedTaskType && (
                  <Box sx={{
                    marginLeft: 3
                  }}>
                    <AppSelectField
                      label="Sub Form"
                      name="subFormsTypes"
                      className={classes.selectField}
                      disabled={!selectedAssetType}
                      options={{
                       // array: assetAvailableTaskTypes[selectedAssetType],
                        array: subFormsList,
                        value: (formtTemplateType) => formtTemplateType,
                        key: (formtTemplateType) => formtTemplateType,
                        template: (formtTemplateType) => `${t('taskTypes')(formtTemplateType)} ${t('common')('form')}`,
                  
                      }}
                      value={ ''}
                       onChange={onFormTemplateChange}
                    />
                  </Box>
                )}
              </Box>
            ) : null}
          </>
        )}
        {newFormTitle && (

         <><Box className={classes.downloadReports}>  <div className={classes.toggleContainer}> <AppTextField label="Enter Report Title" name="reporttitle" type="text" onChange={(q) => setSelectedSubFormTemplate(q.charAt(0).toUpperCase() + q.slice(1).toLowerCase())}/></div> </Box><AppButton           className={classes.addButton}
              type="button"
              variant="contained"
              onClick={() => createOrUpdateFormTemplate({
                variables: {
                  siteId: selectedSiteId!,
                  formType: selectedFormTemplate,
                  assetType: selectedAssetType!,
                  subForm: selectedSubFormTemplate,
                  module: selectedModule
                },
              })}
              color="primary"
            >
              {`Create ${t('taskTypes')(selectedFormTemplate)} ${t('common')('form')}`}
            </AppButton></>
        )}

       

        {selectableFormTemplates?.find(({ subForm }) => subForm === selectedSubFormTemplate)?.content &&
          selectedFormTemplate &&
          !!getActiveFormTemplate() && (
            <Box className={classes.downloadReports}>
              <div className={classes.toggleContainer}>
                <ReportButtonsBlock
                  setFileInfo={async (fileInfo: FileInfo) => {
                    let subformToUpdate = selectableFormTemplates?.filter(item => 
                      item.assetType === selectedAssetType && 
                      item.formType === selectedTaskType && 
                      item.subForm === selectedSubFormTemplate
                    );
                    await createOrUpdateFormTemplate({
                   
                      variables: {
                        siteId: selectedSiteId!,
                        formType: selectedFormTemplate,
                        assetType: selectedAssetType!,
                        subForm: selectedSubFormTemplate,
                        module: selectedModule,
                        content: subformToUpdate?.find(({ subForm }) => subForm === selectedSubFormTemplate)
                          ?.content,
                        fileSingleTemplate: fileInfo.id || '',
                      },
                    });
                    setSingleReportFileName(fileInfo.name);
                    setSingleReportFileId(fileInfo.id);
                  }}
                  fileName={singleReportFileName}
                  uploadedFileId={singleReportFileId || ''}
                  formTemplate={getActiveFormTemplateContent()}
                  handleExcelDownload={async () => {
                    const foundrec = selectableFormTemplates?.filter(item => 
                      item.assetType === selectedAssetType && 
                      item.formType === selectedTaskType && 
                      item.subForm === selectedSubFormTemplate
                    );
                    await exportFormVariables(
                      JSON.parse(
                        foundrec?.find(({ subForm }) => subForm === selectedSubFormTemplate)?.content ||
                          '[]',
                      ),
                    );
                  }}
                />
                {loadingUpdate() && (
                  <div className={classes.loader}>
                    <CircularProgress color="primary" />
                  </div>
                )}
                <div className={classes.toggleBox}>
                  <div className={classes.toggleTitle}>Allow download PDF</div>
                  <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    name="allowSingleReport"
                    checked={!!allowSingleReport}
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                    onChange={async (event) => {
                      if (loadingUpdate()) return;
                      setAllowSingleReport(event.target.checked);
                      const allowSingleReportPdf = !initValues.allowSingleReportPdf;
                      const allowMultiReportPdf = !!initValues.allowMultiReportPdf;
                      await updateAllowed(allowSingleReportPdf, allowMultiReportPdf);
                    }}
                  />
                </div>
              </div>
              <div className={classes.toggleContainer}>
                <ReportButtonsBlock
                  isMulti
                  setFileInfo={async (fileInfo: FileInfo) => {
                    let subformToUpdate = selectableFormTemplates?.filter(item => 
                      item.assetType === selectedAssetType && 
                      item.formType === selectedTaskType && 
                      item.subForm === selectedSubFormTemplate
                    );
                    await createOrUpdateFormTemplate({
                      variables: {
                        siteId: selectedSiteId!,
                        formType: selectedFormTemplate,
                        assetType: selectedAssetType!,
                        subForm: selectedSubFormTemplate,
                        module: selectedModule,
                        content:
                        subformToUpdate?.find(({ subForm }) => subForm === selectedSubFormTemplate)?.content ||
                          '[]',
                        fileMultiTemplate: fileInfo.id || '',
                      },
                    });
                    setMultiReportFileName(fileInfo.name);
                    setMultiReportFileId(fileInfo.id);
                  }}
                  fileName={multiReportFileName}
                  uploadedFileId={multiReportFileId || ''}
                  formTemplate={getActiveFormTemplateContent()}
                  handleExcelDownload={async () => {
                    const foundrec = selectableFormTemplates?.filter(item => 
                      item.assetType === selectedAssetType && 
                      item.formType === selectedTaskType && 
                      item.subForm === selectedSubFormTemplate
                    );
                    await exportMultiFormVariables(
                     // JSON.parse(selectableFormTemplates?.find(({ subForm }) => subForm === selectedSubFormTemplate)?.content || '[]',),
                     JSON.parse(foundrec?.find(({ subForm }) => subForm === selectedSubFormTemplate)?.content || '[]',),
                    );
                  }}
                />
                <div className={classes.toggleBox}>
                  <div className={classes.toggleTitle}>Allow download PDF</div>
                  <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    name="allowMultiReport"
                    checked={!!allowMultiReport}
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                    onChange={async () => {
                      if (loadingUpdate()) return;
                      setAllowMultiReport((prev) => !prev);
                      const allowSingleReportPdf = !!initValues.allowSingleReportPdf;
                      const allowMultiReportPdf = !initValues.allowMultiReportPdf;
      
                      await updateAllowed(allowSingleReportPdf, allowMultiReportPdf);
                    }}
                  />
                </div>
              </div>
              <div className={classes.toggleContainer}>
              <div className={classes.wrapper}>
              <div className={classes.blockWrapper}>
                
              <div className={classes.toggleTitle}>Documents Required?</div>
              <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    name="docsRequired"
                    checked={!!docsRequiredVal}
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                    onChange={async () => {
                    //  if (loadingUpdate()) return;
                      setDocsRequiredVal((prev) => !prev);
                      console.log("In OnChange line 932 docsRequiredVal Value is :", docsRequiredVal)
                      const docsRequired = !initValues.docsRequired;
                      console.log("In OnChange line 934 docsRequired Value is :", docsRequired)
                      console.log("initValues.allowSingleReportPdf:", initValues.allowSingleReportPdf)
                      console.log("initValues.allowMultiReportPdf:", initValues.allowMultiReportPdf)
                      console.log("initValues.docsRequired:", initValues.docsRequired)
                     
                      await updateAllowedDocs(docsRequired);
                    }}
                  />
                
                
                </div></div></div>
             
              <div className={classes.formTitle}>{`${t('taskTypes')(selectedFormTemplate)} ${t('common') (
                'form',
              )} -  ${displayFormTitle}` }
              <span style={{marginLeft: 3 + 'em'}} >
               
             
              <Tooltip disableFocusListener title="Rename Sub Form"> 
                <IconButton onClick={() => setSubFormTitleEdit(true)} size="large">
                    <EditIcon />
                </IconButton>           
               </Tooltip>
           
              </span>
              </div> 
              {subFormTitleEdit && (
						   <div className={classes.wrapper}>
						                  <div className={classes.blockWrapperUpdateText}>
                      
						                    <AppTextField label="Rename Sub Form" name="renameform" type="text" className={classes.formTitleUpdate} value={selectedSubFormTemplateNewName || ''}  onChange={(q) => setSelectedSubFormTemplateNewName(q.charAt(0).toUpperCase() + q.slice(1).toLowerCase())}></AppTextField >
                            
                              </div>
						                  <div className={classes.blockWrapperUpdate}>
						                    <AppButton   className={classes.updateButton}
						                    type="button"
						                    variant="contained"color="primary" 
						                    onClick={() => renamesub()}>Update</AppButton>
						                  </div>
                              <div className={classes.blockWrapper}>

                              <AppButton   className={classes.cancelButton}
																	 						                    type="button"
																	 						                    variant="contained"color="primary" 
																	 						                    onClick={() => setSubFormTitleEdit(false)}>Cancel</AppButton>		



						                 
						                  </div>
						                </div>
			                )}
              <div className={classes.formAreaWrapper}>
                <div className={classes.formArea} ref={formColumnRef}>
                  <FormTemplate
                    type={selectedFormTemplate}
                    handleClick={handleClick}
                    activeRef={activeRef}
                    selectedKey={selectedKey}
                    setSelectedKey={onSelectedKeyChange}
                    templateForm={
                      
                    //  selectableFormTemplates?.find(({ subForm }) => subForm === selectedSubFormTemplate)?.content || ''
                    (selectableFormTemplates?.filter(item => 
                      item.assetType === selectedAssetType && 
                      item.formType === selectedTaskType && 
                      item.subForm === selectedSubFormTemplate
                    )).find(({ subForm }) => subForm === selectedSubFormTemplate)?.content || ''
                    }
                    onActivate={() =>
                      activateFormTemplate({
                        variables: {
                          siteId: selectedSiteId!,
                          formType: selectedFormTemplate,
                          assetType: selectedAssetType!,
                          subForm:selectedSubFormTemplate,
                        },
                      })
                    }
                    active={selectableFormTemplates!.find(({ subForm }) => subForm === selectedSubFormTemplate)!.active}
                    onOrderUpdate={onOrderUpdate}
                    loading={loading || loadingUpdate()}
                  />
                </div>
                {selectedSite && activeRef && (
                  <>
                    <Indexer paddingTop={paddingTop + activeElementHalfHeight} />
                    <EditorForm
                      content={{
                        ...getActiveFormTemplateItem(),
                        selectedAssetIds: getActiveFormTemplateItem().assets || [],
                        selectableAssets: selectedSite.assets.filter((asset) => asset.type === selectedAssetType),
                      }}
                      formTemplateActive={getActiveFormTemplate()?.active || false}
                      parameterNames={getActiveFormTemplateParameterNames()}
                      onDelete={onDelete}
                      onCancel={onCancel}
                      onUpdate={onUpdate}
                      paddingTop={paddingTop}
                      selectedKey={selectedKey}
                      selectedSite={selectedSite}
                      canceling={canceling}
                      setCanceling={setCanceling}
                      ref={editorRef}
                    />
                  </>
                )}
              </div>
            </Box>
          )}
      </>
    </Formik>
  );
};

export default ClientLicensePage;
