import gql from 'graphql-tag';

export const GET_OPERATIVE_HOME = gql`
  query operativeHome($id: ID!, $withoutRejected: Boolean, $status:[TaskStatus!]) {
    user(id: $id) {
      id
      tasks(withoutRejected: $withoutRejected, status:$status) {
        id
        type
        notes
        tasktitle
        adhoc
        deadline
        urgent
        assetsCount
        assets {
          asset {
            system {
              name
            }
          }
          compliant
        }
        compliant
        status
        site {
          companyName
        }
      }
    }
  }
`;

export const GET_TASKS = gql`
  query tasksForOperative(
    $userId: ID!
    $dayDateRange: DayDateRange
    $type: TaskType
    $urgent: Boolean
    $withoutRequests: Boolean
    $withoutRejected: Boolean
  ) {
    user(id: $userId) {
      id
      tasks(
        dayDateRange: $dayDateRange
        type: $type
        urgent: $urgent
        withoutRequests: $withoutRequests
        withoutRejected: $withoutRejected
      ) {
        id
        type
        notes
        tasktitle
        subform
        adhoc
        deadline
        status
        site {
          id
          companyName
        }
        assets {
          asset {
            system {
              name
            }
          }
          compliant
        }
        assetsCount
        urgent
        compliant
      }
      clients {
        id
        companyName
      }
    }
  }
`;
