import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import cn from 'utils/cn';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  timeSection: {
    marginRight: theme.spacing(0.4),
    fontSize: theme.fontSizes[16],
    fontWeight: theme.typography.fontWeightBold,
  },
  timeSectionSeconds: {
    color: theme.palette.grey[600],
  },
}));

const TaskTimerCounter: React.FC<{ seconds: number; onGoing?: boolean }> = ({ seconds: initialSeconds, onGoing }) => {
  const [totalSeconds, setTotalSeconds] = useState(initialSeconds);
  useEffect(() => {
    setTotalSeconds(initialSeconds);
  }, [initialSeconds]);
  useEffect(() => {
    if (onGoing) {
      const countSeconds = setInterval(() => {
        setTotalSeconds((seconds) => seconds + 1);
      }, 1000);
      return () => clearInterval(countSeconds);
    }
    return undefined;
  }, [onGoing]);

  const hours = Math.floor(totalSeconds / (60 * 60));
  const minutes = Math.floor((totalSeconds - hours * 60 * 60) / 60);
  const seconds = totalSeconds - hours * 60 * 60 - minutes * 60;

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <span className={styles.timeSection}>{hours}h </span>
      <span className={styles.timeSection}>{minutes}m </span>
      <div className={cn(styles.timeSection, styles.timeSectionSeconds)}>{seconds}s</div>
    </div>
  );
};

export default TaskTimerCounter;
