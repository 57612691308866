import { useState } from 'react';

export default function useModalState<Content>(
  initialContent?: Content,
): [boolean, Content | undefined, (content: Content) => void, () => void] {
  const [open, setOpen] = useState<boolean>(false);
  const [_content, setContent] = useState<Content | undefined>(initialContent);

  return [
    open,
    _content,
    (content: Content) => {
      setContent(content);
      setOpen(true);
    },
    () => {
      setOpen(false);
    },
  ];
}
