import { useLocation } from "react-router";

export default function useParentLocation(relativePath = '..', absolutePath?: string) {
  const { pathname } = useLocation();
  const relative = relativePath.split('/');
  const absolute = (absolutePath || pathname).split('/');
  return relative
    .reduce((absoluteParts, relativePart) => {
      if (!relativePart || relativePart === '.') {
        return absoluteParts;
      }
      if (relativePart === '..') {
        return absoluteParts.slice(0, absoluteParts.length - 1);
      }
      return absoluteParts.concat(relativePart);
    }, absolute)
    .join('/');
}
