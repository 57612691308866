import React, { useEffect, useState } from 'react';
import { taskForClient_task as ClientTask, taskForContractor_task as ContractorTask } from 'models/graphql';
import AppAvatar from 'components/AppAvatar';
import useText from 'texts/useText.hook';
import AppTabs from 'components/AppTabs';
import switcher from 'utils/switcher';
import TaskDetails from './TaskDetails';
import TaskComments from './TaskComments';
import TaskHistory from './TaskHistory';
import { LinearProgress, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      display: 'flex',
      marginLeft: theme.spacing(2),
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    headerTitle: {
      fontSize: theme.fontSizes[12],
    },
    headerSubTitle: {
      marginTop: theme.spacing(0.25),
      color: theme.palette.grey[500],
    },
    headerAdditonalInformation: {
      marginTop: theme.spacing(0.5),
      fontSize: theme.fontSizes[12],
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  });
});

const TaskPanel: React.FC<{
  task: ClientTask | ContractorTask | undefined;
  loading: boolean;
  assetLink?: (assetId: string, siteId: string) => string;
  onDownload: (fileId: string) => void;
  acceptOrDeny?: React.ReactNode;
  timer?: React.ReactNode;
}> = ({ task, loading, assetLink, onDownload, acceptOrDeny, timer }) => {
  // Panels
  const [activePanel, setActivePanel] = useState<'details' | 'history' | 'comments'>('details');
  useEffect(() => {
    setActivePanel('details');
  }, [task?.id, setActivePanel]);

  const { t } = useText('tasks');
  const styles = useStyles();


  if (loading) return <LinearProgress />;

  if (!task) throw new Error("Task should be defined when 'loading' is false");
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.avatar}>
          <AppAvatar name={task.approval?.name || ''} imageSrc={task.approval?.picture?.srcUrl} />
        </div>
        {task.approval ? (
          <div>
            <div className={styles.headerTitle}>{t('tasks')('approval')}</div>
            <div className={styles.headerSubTitle}>{task.approval.name}</div>
            {'email' in task.approval && task.approval.email ? (
              <div className={styles.headerAdditonalInformation}>
                <a href={`mailto:${task.approval.email}`} target="_blank" rel="noopener noreferrer">
                  {task.approval.email}
                </a>
              </div>
            ) : null}
            {'phonenumber' in task.approval && task.approval.phonenumber ? (
              <div className={styles.headerAdditonalInformation}>
                <a href={`tel:${task.approval.phonenumber}`} target="_blank" rel="noopener noreferrer">
                  {task.approval.phonenumber}
                </a>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <AppTabs
        fullWidth
        normalCase
        tabs={[
          {
            name: t('tasks')('details'),
            onActive: () => setActivePanel('details'),
            active: activePanel === 'details',
          },
          {
            name: t('tasks')('history'),
            onActive: () => setActivePanel('history'),
            active: activePanel === 'history',
          },
          {
            name: t('tasks')('comments'),
            onActive: () => setActivePanel('comments'),
            active: activePanel === 'comments',
          },
        ]}
      />
      {switcher(activePanel, [
        ['details', <TaskDetails key="TaskDetails" task={task} assetLink={assetLink} onDownload={onDownload} />],
        ['history', <TaskHistory key="TaskHistory" taskId={task.id} />],
        ['comments', <TaskComments key="TaskComments" taskId={task.id} />],
      ])}
      {acceptOrDeny}
      {timer}
    </div>
  );
};

export default TaskPanel;
