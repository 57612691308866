import { TaskStatus } from 'models/graphql';

export const closedTaskStatuses = [TaskStatus.REJECTED_CLOSED, TaskStatus.APPROVED_CLOSED, TaskStatus.CANCELLED];
export const completedTaskStatuses = [
  TaskStatus.COMPLETED,
  TaskStatus.SUPP_INFO_SUBMITTED,
  TaskStatus.REVIEW_REQ_SENT,
  TaskStatus.REVIEW_REQ_ACCEPTED,
  TaskStatus.REVIEW_REQ_REJECTED,
  TaskStatus.CONSULTANT_REJECTED,
  TaskStatus.CONSULTANT_APPROVED,
  TaskStatus.REJECTED,
  TaskStatus.REJECTED_CLOSED,
  TaskStatus.APPROVED_CLOSED,
  TaskStatus.CANCELLED,
];

// Task statuses available in MVP
export const availableTaskStatuses = [
  TaskStatus.APPROVED_CLOSED,
  TaskStatus.CANCELLED,
  TaskStatus.COMPLETED,
  TaskStatus.CONTRACTOR_ACCEPTED,
  TaskStatus.CONTRACTOR_REJECTED,
  TaskStatus.IN_PROGRESS,
  TaskStatus.REJECTED,
  TaskStatus.REJECTED_CLOSED,
  TaskStatus.SENT,
  TaskStatus.SUPP_INFO_SUBMITTED,
  TaskStatus.UNASSIGNED,
];

export const isTaskStatusUpcoming = (status: TaskStatus) => {
  return closedTaskStatuses.includes(status);
};
