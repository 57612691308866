import React from 'react';
import AppIconButton from 'components/AppIconButton';
import editIcon from 'assets/editIcon.svg';
import { useQuery } from '@apollo/client';
import { getContractor, getContractorVariables } from 'models/graphql';
import { GET_CONTRACTOR } from 'containers/shared/graphql';
import ContractorDetails from 'containers/shared/Contractors/ContractorDetails';
import { Link, useParams } from 'react-router';

const ContractorDetailsPage: React.FC = () => {
  const { contractorId } = useParams<{
    contractorId: string;
  }>();

  const { data: contractorData, loading: contractorDataLoading } = useQuery<getContractor, getContractorVariables>(
    GET_CONTRACTOR,
    {
      variables: { id: contractorId },
    },
  );

  return (
    <ContractorDetails
      contractor={contractorData?.contractor}
      loading={contractorDataLoading}
      topButton={
        <Link to={`/pm/contractors/${contractorId}/edit`}>
          <AppIconButton>
            <img alt="update" src={editIcon} />
          </AppIconButton>
        </Link>
      }
    />
  );
};

export default ContractorDetailsPage;
