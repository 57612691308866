import { useMutation, useQuery } from '@apollo/client';
import { MenuItem } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import addIcon from 'assets/addIcon.svg';
import AppButton from 'components/AppButton/AppButton';
import AppMenu from 'components/AppMenu';
import { CREATE_TASK_MYSELF } from 'containers/Operative/Tasks/graphql';
import { GET_USER_SITES } from 'containers/shared/graphql';
import { taskCreateMyself, taskCreateMyselfVariables, TaskType, userSites, userSitesVariables } from 'models/graphql';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import formatDayDate from 'shared/formatDayDate';
import useText from 'texts/useText.hook';
import { useNavigate, useLocation } from 'react-router';

export type Actions = Array<{
  title: string;
  action: (setLoading: Dispatch<SetStateAction<boolean>>) => void;
}>;

const stylesConfig = () => {
  return createStyles({
    container: {},
    button: {
      minWidth: 40,
      padding: '8px',
      marginRight: '8px',
      borderRadius: 10,
      borderColor: 'red',
      '& > span': {
        width: '22px',
        height: '24px',
      },
    },
  });
};
const useStyles = makeStyles(stylesConfig);

interface Props {
  userId: string;
  refetchTasks: () => void;
}

const AddTaskButton: React.FC<Props> = ({ userId, refetchTasks }) => {
  const classes = useStyles();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { t } = useText('taskTypes');
  const deadline = formatDayDate(new Date());
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { data } = useQuery<userSites, userSitesVariables>(GET_USER_SITES, {
    variables: { id: userId },
  });
  const [createTaskMyself] = useMutation<taskCreateMyself, taskCreateMyselfVariables>(CREATE_TASK_MYSELF);

  const createTask = (type: TaskType) => {
    if (type && data) {
      setOpen(false);
      createTaskMyself({
        variables: {
          task: {
            siteId: data.user.sites === null ? '' : data.user.sites[0].id,
            assetIds: [],
            type,
            approvalUserId: userId,
            documentDeadline: deadline,
            deadline,
            fileIds: [],
            internalUserId: userId,
            adhoc: true,
          },
        },
      }).then((res) => {
        if (res.data?.taskCreate.id) {
          navigate(`${pathname}/${res.data.taskCreate.id}`);
        } else {
          refetchTasks();
        }
      });
    }
  };

  return (
    <>
      <div className={classes.container}>
        <AppButton
          type="button"
          variant="outlined"
          disableFocusRipple
          disableRipple
          className={classes.button}
          ref={buttonRef}
          onClick={() => setOpen(true)}
        >
          <img src={addIcon} alt="Choose form type" />
        </AppButton>
      </div>

      <AppMenu anchorEl={buttonRef.current} open={open} horizontalPosition="left" onClose={() => setOpen(false)}>
        {Object.keys(TaskType).map((taskType) => (
          <MenuItem key={taskType} onClick={() => createTask(taskType as TaskType)}>
            {t('taskTypes')(taskType as TaskType)}
          </MenuItem>
        ))}
      </AppMenu>
    </>
  );
};

export default AddTaskButton;
