import { format, startOfWeek, endOfWeek } from 'date-fns';

const DATE_FORMAT = 'yyyy-MM-dd';

export default function formatDayDate(date: Date): string {
  return format(date, DATE_FORMAT); // 'yyyy-MM-dd' is the the preferred JavaScript date format (ISO 8601);
}

export function readDayDate(dayDateString: string): Date {
  return new Date(dayDateString);
}

export function startOfCurrentWeek(): Date {
  return startOfWeek(new Date(), { weekStartsOn: 1 });
}

export function endOfCurrentWeek(): Date {
  return endOfWeek(new Date(), { weekStartsOn: 1 });
}
