import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { useStores } from 'stores';
// import { CircularProgress, createStyles, FormGroup, makeStyles, Theme, Typography, useTheme, TableCell, TextField } from '@mui/material';
import useSearchParams from 'utils/useSearchParams';
import enumToArray from 'utils/enumToArray';
import styleUtils from 'style/styleUtils';
import useText from 'texts/useText.hook';
import cn from 'utils/cn';
import AppButtonLink from 'components/AppButtonLink';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import Breadcrumb from 'components/Breadcrumb';
import useSites from '../useSites';
import switcher from 'utils/switcher';
// import { alpha } from '@mui/material/styles';
// FW Graph
import { Chart as ChartJS, registerables, ArcElement, ChartData } from "chart.js";
import type { InteractionItem } from 'chart.js';
ChartJS.register(...registerables);
ChartJS.register(ArcElement);
import { Pie, Bar, getDatasetAtEvent, getElementAtEvent } from 'react-chartjs-2';
//import { Doughnut, Pie, Bar, HorizontalBar } from 'react-chartjs-3';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment';                                       //FW Calendar
import 'react-big-calendar/lib/css/react-big-calendar.css';        //FW Calendar
import StatusBadge, { StatusBadgeState } from 'components//StatusBadge';      // Keep separate for moment
import AppTabs from 'components/AppTabs';
// import TaskCalendar from './TaskCalendar';
import { observer } from 'mobx-react';
import {
  TaskStatus
} from 'models/graphql';

import compliantIcon from 'assets/compliantIcon.svg';
import nonCompliantIcon from 'assets/nonCompliantIcon.svg';
import alarmIconOrange from 'assets/alarmIconOrangeListing.svg';
import expiredIcon from 'assets/expiringIcon.svg';
import documentsIconGrey from 'assets/documentsIconGrey.svg';
// import {createMuiTheme } from "@mui/material/styles";
// import { Autorenew } from '@mui/icons-material';
import { ReactComponent as DrawingCompassIcon } from "assets/dashboardDrawingIconGreen.svg";
import { ReactComponent as MapIconGreen } from "assets/dashboardMapIconGreen.svg";

import calendarIcon from 'assets/calendarIcon.svg';
import cancelSimpleIcon from 'assets/cancelSimpleIcon.svg';
import ReportGenerator from '../Assets/ReportGenerator';
import SFExpandedRows from "./SalesforceExpandDetails";
import { createStyles, makeStyles, styled } from '@mui/styles';
import { alpha, FormGroup, IconButton, Theme, Tooltip, Typography, useTheme, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { Autorenew } from '@mui/icons-material';
import { Link, useLocation, useNavigate, useParams } from 'react-router';
import { adaptPath } from 'utils/adaptPath';
// import useFetcCalendarhData from './CalendarEvents';   

moment.locale('en-GB');

const localizer = momentLocalizer(moment);                         //FW Calendar
const initialFilter = '';

// Styled Components
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: "16px",
  
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#4caf50", // Customize the label color
  fontWeight: "bold",
  marginTop: "15%"
  
}));
const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#4caf50", // Change the border color
  "&.Mui-checked": {
    color: "#4caf50", // Change the checkmark color
  },
}));

interface pieOptions {
  numc?: number;
  compliant_display?: string;
}
const styleRules = (theme: Theme) =>
  createStyles({
    loader: {
      marginTop: theme.spacing(4),
      ...styleUtils.rowCenterCenter,
    },
    container: {
      display: 'flex',
    },

    containerTasks: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-around',
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },

    taskItem: {
      padding: '5px',
      width: '48%',
      height: '48%',
      marginTop: '10px'
    },

    taskList: {
      padding: '5px',
      width: '50%',
      height: '75%',
      marginTop: '10px'
    },



    assetTypesContainer: {
      flexGrow: 1,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      [theme.breakpoints.down('xl')]: {
        gridTemplateColumns: '1fr',
      },
      gap: theme.spacing(2),
    },
    barChartBox: {
      marginLeft: '10%'


    },

    assetTypeBox: {
      display: 'flex',
      boxSizing: 'border-box',
      align: 'left',
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius * 2,
      boxShadow: theme.shadows[1],

      '&.notAllowed': {
        background: theme.palette.grey[100],
      },
    },
    assetGraphBox: {
      display: 'flex',
      boxSizing: 'border-box',
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius * 2,
      boxShadow: theme.shadows[1],

    },
    assetTypeMain: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    complianceContainerContainer: {
      flexGrow: 1,
      display: 'flex',
      marginBottom: theme.spacing(4),
    },
    complianceContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    complianceLink: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.fontSizes[12],
      fontWeight: theme.typography.fontWeightMedium,

      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    complianceColor: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(1),
      borderRadius: theme.shape.borderRadius / 2,
    },
    complianceValue: {
      marginLeft: 'auto',
      paddingLeft: theme.spacing(3),
      fontWeight: theme.typography.fontWeightBold,
    },
    assetTypeSide: {
      width: 140,
      flexShrink: 0,
    },
    assetTypeSumValue: {
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.fontSizes[18],
    },
    assetTypeSumText: {
      textTransform: 'uppercase',
      color: theme.palette.grey[500],
      fontSize: theme.fontSizes[12],
    },
    assetTypeTitle: {
      marginBottom: theme.spacing(4),
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
    },
    assetTypeStatistics: {
      display: 'flex',
    },
    assetTypeStatisticBox: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius * 3,
    },
    assetTypeStatisticLink: {
      display: 'flex',
      alignItems: 'center',
    },
    createTaskLink: {
      display: 'flex',
      padding: '10px',
    },
    assetTypeStatisticBoxIcon: {
      width: theme.fontSizes[14],
      marginRight: theme.spacing(1),
    },
    assetTypeStatisticBoxText: {
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[500],
    },

    upcomingTasks: {
      flexShrink: 0,
      paddingLeft: theme.spacing(3),
      marginLeft: theme.spacing(2),
    },
    upcomingTasksTitle: {
      marginBottom: theme.spacing(3),
      fontWeight: theme.typography.fontWeightBold,
    },
    taskBox: {
      maxWidth: 260,
      display: 'flex',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
      background: theme.palette.grey[100],
      borderLeft: `solid 6px ${theme.palette.grey[600]}`,
      borderTopRightRadius: theme.shape.borderRadius * 2,
      borderBottomRightRadius: theme.shape.borderRadius * 2,

      '&.closeToDeadline': {
        borderLeftColor: theme.palette.error.main,
      },
    },
    taskTitle: {
      fontWeight: theme.typography.fontWeightBold,
    },
    taskDeadline: { /* eslint-disable-line */
      marginTop: theme.spacing(2),
      ...styleUtils.rowStartCenter,
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[600],
    },
    taskDeadlineIcon: {
      marginRight: theme.spacing(1),
    },
    taskTypeIcon: {
      flexShrink: 0, /* eslint-disable-line */
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(2),
      ...styleUtils.rowCenterCenter,
    },
    datePicker: {
      width: '145px',
      height: '35px',
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiInputBase-root': {
        height: '35px',
        '& input': {
          height: '35px',
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: theme.fontSizes['14'],
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0,
          '& legend': {
            display: 'none',
          },
        },
        '&:not(.Mui-focused):not(.Mui-error)': {

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey['600'],
          },
        },
      },
    },
  });
const useStyles = makeStyles(styleRules);

// Date Picker Styling
const calendarStyles = (theme: Theme) => {
  const { height } = theme.sizes.button;
  const { borderRadius } = theme.shape;
  const grey = theme.palette.grey['300'];
  return createStyles({

    datePicker: {
      width: '20%',
      padding: '2px',
      minWidth: theme.sizes.button.minWidth,
      borderRadius,
      '& .MuiInputBase-root': {
        height,
        '& input': {
          height,
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: theme.fontSizes['14'],

          '&.Mui-disabled': {
            background: alpha(grey, 0.1),
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0,
          '& legend': {
            display: 'none',
          },
        },
        '&:not(.Mui-focused):not(.Mui-error)': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: grey,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey['600'],
          },
        },
      },
      '&.no-value .MuiInputBase-root input': {
        color: theme.palette.grey[400],
      },
      '& .MuiFormLabel-root': {
        position: 'static',
        paddingBottom: theme.spacing(1),
        fontSize: theme.fontSizes['12'],
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1.5,
        transform: 'none',
        color: theme.palette.text.primary,
        '&.Mui-disabled': {
          color: alpha(theme.palette.text.primary, 0.4),
        },
      },
    },
    datePickerContainer: {
      position: 'relative',
    },




  });
}

const useCalendarStyles = makeStyles(calendarStyles);

const initialCompValue = 'noncompliant';

// set the background colors
const noncompliantbackground = [
  'rgba(220, 28, 19, 1)',
  'rgba(234, 76, 70, 1)',
  'rgba(240, 116, 112, 1)',
  'rgba(241, 149, 155, 1)',
  'rgba(246, 189, 192, 1)',
  'rgba(99, 28, 72, 1)',
  'rgba(159, 38, 92, 1)',
  'rgba(207, 41, 103, 1)',
  'rgba(241, 91, 103, 1)',
  'rgba(253, 178, 95, 1)',
  'rgba(254, 216, 131, 1)',
  'rgba(245, 69, 69, 1)',
  'rgba(245, 189, 37, 1)'

]

const noncompliantborder = [
  'rgba(220, 28, 19, 0.2)',
  'rgba(234, 76, 70, 0.2)',
  'rgba(240, 116, 112, 0.2)',
  'rgba(241, 149, 155, 0.2)',
  'rgba(246, 189, 192, 0.2)',
  'rgba(99, 28, 72, 0.2)',
  'rgba(159, 38, 92, 0.2)',
  'rgba(207, 41, 103, 0.2)',
  'rgba(241, 91, 103, 0.2)',
  'rgba(253, 178, 95, 0.2)',
  'rgba(254, 216, 131, 0.2)',
  'rgba(245, 69, 69, 0.2)',
  'rgba(245, 189, 37, 0.2)'

]

const sfMaintenancebackgroundColor = [
  'rgba(68, 108, 146, 1)',
  'rgba(68, 146, 77, 1)'
]

const sfMaintenanceborderColor = [
  'rgba(68, 108, 146, 1)',
  'rgba(68, 146, 77, 1)'
]

const sfMaintenanceYesbackgroundColor = [
  'rgba(180, 209, 224)',
  'rgba(52, 144, 191)',
  'rgba(52, 89, 191)',
  'rgba(151, 170, 222)',
  'rgba(158, 151, 222)'
]
const sfMaintenanceYesborderColor = [
  'rgba(180, 209, 224)',
  'rgba(52, 144, 191)',
  'rgba(52, 89, 191)',
  'rgba(151, 170, 222)',
  'rgba(158, 151, 222)'
]

const sfMaintenanceNobackgroundColor = [
  'rgba(218, 222, 151)',
  'rgba(131, 135, 54)',
  'rgba(145, 136, 54)',
  'rgba(227, 206, 16)',
  'rgba(186, 182, 141)'
]
const sfMaintenanceNoborderColor = [
  'rgba(218, 222, 151)',
  'rgba(131, 135, 54)',
  'rgba(145, 136, 54)',
  'rgba(227, 206, 16)',
  'rgba(186, 182, 141)'
]

const compliantbackgroundColor = [
  'rgba(68, 146, 77, 1)',
  'rgba(155, 190, 87, 1)',
  'rgba(214, 217, 84, 1)',
  'rgba(157, 157, 156, 1)',
  'rgba(153, 187, 187, 1)',
  'rgba(138, 175, 145, 1)',
  'rgba(42, 175, 145, 1)',
  'rgba(116, 146, 77, 1)',
  'rgba(116, 145, 155, 1)',
  'rgba(247, 225, 53, 1)',
  'rgba(241, 193, 53, 1)',
  'rgba(56, 71, 161, 1)',
  'rgba(56, 153, 248, 1)'

]

const compliantborderColor = [
  'rgba(68, 146, 77, 0.2)',
  'rgba(155, 190, 87,  0.2)',
  'rgba(214, 217, 84,  0.2)',
  'rgba(157, 157, 156,  0.2)',
  'rgba(153, 187, 187,  0.2)',
  'rgba(138, 175, 145,  0.2)',
  'rgba(42, 175, 145,  0.2)',
  'rgba(116, 146, 77,  0.2)',
  'rgba(116, 145, 155,  0.2)',
  'rgba(247, 225, 53,  0.2)',
  'rgba(241, 193, 53, 0.2)',
  'rgba(56, 71, 161, 0.2)',
  'rgba(56, 153, 248, 0.2)'
]

const expiredbackgroundColor = [
  'rgba(194, 70, 102, 1)',
  'rgba(164, 70, 102, 1)',
  'rgba(174, 70, 102, 1)',
  'rgba(184, 70, 102, 1)',
  'rgba(154, 70, 102, 1)',
  'rgba(144, 70, 102, 1)',
  'rgba(134, 70, 102, 1)',
  'rgba(114, 20, 102, 1)',
  'rgba(104, 70, 102, 1)',
  'rgba(94, 20, 102, 1)',
  'rgba(84, 70, 102, 1)',
  'rgba(64, 70, 102, 1)',
  'rgba(54, 70, 102, 1)'

]

const expiredborderColor = [
  'rgba(194, 70, 102, 0.2)',
  'rgba(164, 70, 102, 0.2)',
  'rgba(174, 70, 102, 0.2)',
  'rgba(184, 70, 102, 0.2)',
  'rgba(154, 70, 102, 0.2)',
  'rgba(144, 70, 102, 0.2)',
  'rgba(134, 70, 102, 0.2)',
  'rgba(114, 20, 102, 0.2)',
  'rgba(104, 70, 102, 0.2)',
  'rgba(94, 20, 102, 0.2)',
  'rgba(84, 70, 102, 0.2)',
  'rgba(64, 70, 102, 0.2)',
  'rgba(54, 70, 102, 0.2)'
]

const expiringbackgroundColor = [
  'rgba(242, 146, 77, 1)',
  'rgba(211, 190, 87, 1)',
  'rgba(230, 160, 84, 1)',
  'rgba(230, 134, 84, 1)',
  'rgba(230, 111, 84, 1)',
  'rgba(230, 199, 84, 1)',
  'rgba(230, 176, 84, 1)',
  'rgba(226, 165, 84, 1)',
  'rgba(255, 165, 84, 1)',
  'rgba(225, 185, 14, 1)',
  'rgba(235, 165, 14, 1)',
  'rgba(235, 200, 14, 1)',
  'rgba(245, 220, 14, 1)'

]

const expiringborderColor = [
  'rgba(242, 146, 77, 0.2)',
  'rgba(211, 190, 87, 0.2)',
  'rgba(230, 160, 84, 0.2)',
  'rgba(230, 134, 84, 0.2)',
  'rgba(230, 111, 84, 0.2)',
  'rgba(230, 199, 84, 0.2)',
  'rgba(230, 176, 84, 0.2)',
  'rgba(226, 165, 84, 0.2)',
  'rgba(255, 165, 84, 0.2)',
  'rgba(225, 185, 14, 0.2)',
  'rgba(235, 165, 14, 0.2)',
  'rgba(235, 200, 14, 0.2)',
  'rgba(245, 220, 14, 0.2)'

]


const undeterbackgroundColor = [
  'rgba(56, 50, 120, 1)',
  'rgba(76, 87, 166, 1)',
  'rgba(96, 135, 184, 1)',
  'rgba(109, 169, 163, 1)',
  'rgba(75, 138, 143, 1)',
  'rgba(60, 105, 121, 1)',
  'rgba(95, 185, 213, 1)',
  'rgba(122, 198, 220, 1)',
  'rgba(138, 205, 224, 1)',
  'rgba(162, 217, 231, 1)',
  'rgba(179, 226, 235, 1)',
  'rgba(221, 232, 233, 1)',
  'rgba(217, 232, 24, 1)'

]

const undeterborderColor = [
  'rgba(56, 50, 120, 0.2)',
  'rgba(76, 87, 166, 0.2)',
  'rgba(96, 135, 184, 0.2)',
  'rgba(109, 169, 163, 0.2)',
  'rgba(75, 138, 143, 0.2)',
  'rgba(60, 105, 121, 0.2)',
  'rgba(95, 185, 213, 0.2)',
  'rgba(122, 198, 220, 0.2)',
  'rgba(138, 205, 224, 0.2)',
  'rgba(162, 217, 231, 0.2)',
  'rgba(179, 226, 235, 0.2)',
  'rgba(221, 232, 233, 0.2)',
  'rgba(217, 232, 24, 0.2)'
]



const DashboardPage: React.FC = () => {
  const navigate = useNavigate(); 
  let { clientId, siteId } = useParams<{ clientId: string; siteId?: string }>();
  const { pathname } = useLocation();
  
  const [pieValue] = useSearchParams('piefilter');
    
  const { current, renderBreadcrumb } = useSites(adaptPath(pathname, 'dashboard'));

  const {
    authStore: { logout }
  } = useStores();
  const { contextStore } = useStores();
  const curSelectedModules = contextStore.selectedModules;
  const curSelectedModulesStorage = window.localStorage.getItem('selectedModules');
  let preselectedmodules: string | any[] = []
  if (curSelectedModulesStorage) {
    preselectedmodules = JSON.parse(curSelectedModulesStorage);

  }
  
  // console.log("siteIdContext is Currently:", siteIdContext)

  const [searchText, setSearchText] = useState("");
  const [dataSearched, setDataSearched] = useState([]);
  const [showAllData, setShowAllData] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);

  contextStore.setReportViewedValue(false);

  const handleDateChangeStart = (newValue: any) => {
    //setSelectedDate(newValue);


    setStartDate(newValue);

    if (newValue === null) {
      newValue = '2000-01-01'
    }

    let newEnddate = new Date(endDate.valueOf());
    newEnddate.setDate(newEnddate.getDate() + 1);

    // Set calendar dates
    showSelectedSFJobsForDate(newValue, newEnddate);
  };

  const handleDateChangeEnd = (newValue: any) => {
    //setSelectedDate(newValue);
    setEndDate(newValue);

    if (newValue === null) {
      newValue = '2030-01-01'
    }
    let newEnddate = new Date(newValue.valueOf());
    newEnddate.setDate(newEnddate.getDate() + 1);

    showSelectedSFJobsForDate(startDate, newEnddate);
  };

  const handleClearStart = (e: any) => {
    e.stopPropagation();
    handleDateChangeStart(null);

  };

  const handleClearEnd = (e: any) => {
    e.stopPropagation();
    handleDateChangeEnd(null);


  };


  const nameval = "start";
  const placeholder = "Start Date";
  const minValuestart = new Date();

  // console.log("minValuestart is ", minValuestart)

  const namevalend = "end";
  const placeholderend = "End Date";
  const minValueend = new Date();

  const thisWeeksDate = new Date();


  const firstDay = new Date(thisWeeksDate.getFullYear(), thisWeeksDate.getMonth(), 1);
  const lastDay = new Date(thisWeeksDate.getFullYear(), thisWeeksDate.getMonth() + 1, 0);


  const [startDate, setStartDate] = useState(firstDay)
  const [endDate, setEndDate] = useState(lastDay)


  // Panels
  const [activePanel, setActivePanel] = useState<'salesforce' | 'assets' | 'tasks'>('assets');
  useEffect(() => {
    //setActivePanel('assets');
    checktabindex(activePanel);
  }, [activePanel]);


  
  // modules changes
  useEffect(() => {
    // call the various 
    console.log("Selected Modules are now:", preselectedmodules)

    // Update the tasks listings next to calendar 
    fetchDataTaskTableModuleChange();
    getcalendardataformontwithmod();
    getTaskPieData();
    // getcalendardataformont();
    // Now update the calendar 




  }, [curSelectedModules])



  let siteIdValStored = String(localStorage.getItem('siteIDval'));
  // console.log("siteIdValStored is currently:", siteIdValStored)
  /*if (siteIdValStored !== 'null' && siteIdValStored !== 'undefined')
  {
    console.log("IN SETTING THE SITE ID")
    siteId = siteIdValStored;

  } */
  //console.log("siteIdValStored: ", siteIdValStored)
  const [curSiteId, setCurSiteId] = useState(
    siteIdValStored ? siteIdValStored : siteId
  );

  const { t } = useText('tasks', 'assetTypes', 'taskTypes', 'common', 'clients');
  const styles = useStyles();

  const calendarStyleMake = useCalendarStyles();

  const theme = useTheme();

  const monthIndex: number = new Date().getMonth() + 1;
  const yearIndex: number = new Date().getFullYear();

  const [taskviewClick, setTaskviewClick] = React.useState(initialFilter);

  const [weekendView, setWeekendView] = React.useState(false);
  const [weekendViewLabel, setWeekendViewLabel] = React.useState('Show Weekends');

  const [tasksTableTitle, setTasksTableTitle] = React.useState("This Week's Tasks");

  const [doRefresh, setDoRefresh] = React.useState(true);

  const [currentMonth, setCurrentMonth] = useState(monthIndex)
  const [currentYear, setCurrentYear] = useState(yearIndex);

  const [selectedMonth, setSelectedMonth] = useState(monthIndex)
  const [selectedYear, setSelectedYear] = useState(yearIndex);

  // used to determine if Salesforce Link is needed
  const [sfCustomerID, setSfCustomerID] = useState(null)
  const [sfOnly, setsfOnly] = useState(null)

  const [sfParentAcc, setsfParentAcc] = useState(false)
  const [sfParentAccName, setsfParentAccName] = useState(null)

  const [showComplianceOptions, setShowComplianceOption] = useState(true);


  const [showSalesforceCalendar, setSalesforceCalendar] = useState(false);

  const [showTaskOptions, setTaskOption] = useState(false);


  const [monthsTasks, setMonthsTasks] = useState([]);


  const [monthsJobsSF, setMonthsJobsSF] = useState([]);

  const [dateCur, setDate] = useState(new Date())

  const [dateCurSF, setDateSF] = useState(new Date())

  const [selectedSF, setSelectedSF] = useState();

  const [selected, setSelected] = useState();

  const [monthNav, setMonthNav] = useState(false);

  const [monthNavSF, setMonthNavSF] = useState(false);

  const [selectedAssetIds, setSelectedAssetIds] = useState([]);

  const [pieOptionsData, setPieOptionsData] = useState<pieOptions[]>();

  const CustomToolbar = ({ onAllDataChange, isAllData }) => {
    return (
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            checked={isAllData}
            onChange={(e) => onAllDataChange(e.target.checked)}
          />
        }
        label={<StyledTypography>All Data</StyledTypography>}
      />
    );
  };


  const columnsMUITasksTable = [
    {
      name: "taskid", label: "Task ID", options: {
        filter: true,
        filterType: 'multiselect',
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            width: '10%',
          }
        }),
        customBodyRender: (value: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, tableMeta: any, updateValue: any) => {
          let tasklink = '';
          if (siteId) {
            //   console.log("Setting tasklink with SiteID")
            tasklink = `/client/${clientId}/tasks/${siteId}?search=${value}`
          }
          else {
            //   console.log("Setting tasklink with siteIdContext")
            tasklink = `/client/${clientId}/tasks/${siteId}?search=${value}`
          }

          return (
            <Link reloadDocument to={tasklink}>{value}</Link>
          );
        },
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
            width: '10%',
          }
        })
      }
    },
    {
      name: "Task Name", options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 88,
            width: '25%',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 89,
            width: '25%',
          }
        })
      }
    },
    {
      name: "Task Type", options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            width: '10%',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
            width: '10%',
          }
        })
      }
    },
    {
      name: "Deadline", options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            width: '10%',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
            width: '10%',
          }
        }),
        sort: true,
        customBodyRender: (value: string | number | Date) =>
          moment(new Date(value)).format('DD/MM/YYYY')
      }
    },
    {
      name: "orig_status", label: "Status", options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            minWidth: "70%", maxWidth: "100%",
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
            width: '10%',
          }
        }),
        customBodyRender: (statusvalue: string) => {
          let state: StatusBadgeState;
          if (statusvalue) {

            if (statusvalue.toUpperCase() === TaskStatus.SENT || statusvalue.toUpperCase() === TaskStatus.REVIEW_REQ_SENT) {
              state = { background: alpha(theme.colors.yellow, 0.25) };
            } else if (
              statusvalue.toUpperCase() === TaskStatus.CONTRACTOR_ACCEPTED ||
              statusvalue.toUpperCase() === TaskStatus.CONTRACTOR_REJECTED ||
              statusvalue.toUpperCase() === TaskStatus.IN_PROGRESS ||
              statusvalue.toUpperCase() === TaskStatus.COMPLETED ||
              statusvalue.toUpperCase() === TaskStatus.SUPP_INFO_SUBMITTED
            ) {
              state = { background: alpha(theme.colors.orange, 0.25) };
            } else if (
              statusvalue.toUpperCase() === TaskStatus.REVIEW_REQ_REJECTED ||
              statusvalue.toUpperCase() === TaskStatus.REVIEW_REQ_ACCEPTED ||
              statusvalue.toUpperCase() === TaskStatus.CONSULTANT_REJECTED ||
              statusvalue.toUpperCase() === TaskStatus.CONSULTANT_APPROVED
            ) {
              state = { background: alpha(theme.colors.blue, 0.25) };
            } else if (statusvalue.toUpperCase() === TaskStatus.REJECTED_CLOSED || statusvalue.toUpperCase() === TaskStatus.REJECTED) {
              state = { background: alpha(theme.palette.error.main, 0.25) };
            } else if (statusvalue.toUpperCase() === TaskStatus.APPROVED_CLOSED) {
              state = { background: alpha(theme.palette.primary.main, 0.25) };
            } else if (statusvalue.toUpperCase() === TaskStatus.CANCELLED) {
              state = { background: alpha('#000000', 0.12) };
            } else {
              state = { background: 'transparent' };
            }
            return (
              (<StatusBadge block state={state}>
                {statusvalue.replace(/_+/g, ' ')}
              </StatusBadge>)
            );
          }
        }
      }
    }
  ]


  const columnsMUISFTable = [

    {
      name: "sfjobname", label: "Job Name", options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101, width: '25%',
          }
        })
      }
    },
    {
      name: "sfjobtype", label: "Job Type", options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
          }
        })
      }
    },
    {
      name: "sfstatus", label: "Job Status", options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101, width: '9%',
          }
        })
      }
    },

    {
      name: "sfmaintenance", label: "Maintenance", options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101, width: '4%',
          }
        }), customBodyRender: (value: string | number | Date) => {

          if (value === null) {
            return "N/A"
          }
          else {
            return value
          }
        }
      }
    },
    {
      name: "sfjobend", label: "Job End", options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white"
          }
        }), customBodyRender: (value: string | number | Date) => {

          if (value === null) {
            return "N/A"
          }
          else {
            return moment(new Date(value)).format('DD/MM/YYYY')
          }
        }
      }
    },
    {
      name: "sfcontactname", label: "Contact", options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
          }
        })
      }
    },
    {
      name: "sfjobreference", label: "Job Ref", options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
          }
        })
      }
    },
    /* { name: "sfvehiclesonsite", label: "Vehicles on Site", options: {  setCellHeaderProps: () => ({
     style: {
       whiteSpace: "nowrap",
       position: "sticky",
       left: 0,
       fontWeight: 700,
       background: "white",
       zIndex: 101,
     }
   })}}
   , */
    {
      name: "sftypeofwaste", label: "Type of Waste", options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
          }
        })
      }
    },
    {
      name: "sfinvoicenum", label: "Invoice No", options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
          }
        })
      }
    }


  ]


  const columnsMUISFParentTable = [
    
    { name: "sfaccountname", label: "Account", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101,  width: '10%',
      }
    })}},{ name: "sfjobname", label: "Job Name", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101,  width: '15%',
      }
    })}},
    { name: "sfjobtype", label: "Job Type", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101,
      }
    })}},
	  { name: "sfstatus", label: "Job Status", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101, width: '9%',
      }
    })}},

	  { name: "sfmaintenance", label: "Maintenance", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101, width: '4%',
      }
    }),customBodyRender: (value: string | number | Date) =>
    {
   
    if (value === null)
    {
      return "N/A"
    }
    else{
      return value
    }
    }
    }},
    { name: "sfjobend", label: "Job End", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white"     
      }
    }),customBodyRender: (value: string | number | Date) =>
    {
   
    if (value === null)
    {
      return "N/A"
    }
    else{
      return moment(new Date(value)).format('DD/MM/YYYY')
    }
    }
    }},
    { name: "sfcontactname", label: "Contact", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101,
      }
    })}},
    { name: "sfjobreference", label: "Job Ref", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101,
      }
    })}},
 	 /* { name: "sfvehiclesonsite", label: "Vehicles on Site", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101,
      }
    })}}
    , */
   /* { name: "sftypeofwaste", label: "Type of Waste", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101,
      }
    })}}, */
    { name: "sfinvoicenum", label: "Invoice No", options: {  setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        fontWeight: 700,
        background: "white",
        zIndex: 101,
      }
    })}}
   
   
  ]

  // change DatePicker or remove
  // const ExtraButtonsSF = () => (
  //   <>  
  //   <IconButton>
  //     <LocalizationProvider dateAdapter={DateFnsUtils}>
  //       <div className="daterange">
  //         <DatePicker
  //           renderInput={(props) => <TextField {...props} />}
  //           className={cn(styles.datePicker)}
  //           autoOk
  //           label="Start Date"
  //           value={startDate}
  //           format={ 'dd/MM/yyyy'}
  //           onChange={handleDateChangeStart}
  //           InputProps={{
  //             endAdornment: (
  //               <div
  //                 onClick={handleClearStart}
  //                 style={{ marginRight: 20, cursor: "pointer" }}
  //               >
  //                 <ClearIcon />
  //               </div>
  //             )
  //           }}
  //         />
  //         <DatePicker
  //           renderInput={(props) => <TextField {...props} />}
  //           style={{ marginLeft: 20}}
  //           className={cn(styles.datePicker)}
  //           autoOk
  //           label="End Date"
  //           value={endDate}
  //           format={ 'dd/MM/yyyy'}
  //           onChange={handleDateChangeEnd}
  //           InputProps={{
  //             endAdornment: (
  //               <div
  //                 onClick={handleClearEnd}
  //                 style={{ marginRight: 20, cursor: "pointer" }}
  //               >
  //                 <ClearIcon />
  //               </div>
  //             )
  //           }}
  //         />
  //       </div>
  //   </LocalizationProvider>
  //   </IconButton>
  //   </>
  // );

  const columnsMUITable = [
    {
      name: "Name", options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            width: '25%',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 101,
            width: '25%',
          }
        }),
        customBodyRender: (value: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, tableMeta: any, updateValue: any) => {
          let assetlink = `/client/${clientId}/assets/${siteId}/?search=${value}`
          return (<Link reloadDocument to={assetlink}>{value}</Link>)
        },
      }
    },
    {
      name: "Asset Type", options: {
        filterList: [], setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",

            zIndex: 106,
            width: '20%',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            fontWeight: 700,
            zIndex: 107,
            width: '20%',
          }
        })
      }
    },
    {
      name: "System", options: {
        filterList: [], setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",

            background: "white",
            zIndex: 103,
            width: '20%',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            fontWeight: 700,
            zIndex: 104,
            width: '15%',
          }
        })
      }
    },
    {
      name: "System Type", options: {
        display: true, setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",

            background: "white",
            zIndex: 113,
            width: '15%',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            fontWeight: 700,
            background: "white",
            zIndex: 114,
            width: '15%',
            justifyContent: 'center',
            align: 'center',
          }
        })
      }
    },
    //{ name: "Compliant_Display" ,  label: "Is Compliant?",  options: { display: false, viewColumns: false,}},
    {
      name: "Compliant_Display", label: "Is Compliant?",
      options: {
        sort: false, filter: true,
        setCellHeaderProps: () => ({
          style: {
            align: "center",
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            fontWeight: 700,
            zIndex: 104,

          }
        }),
        //setCellHeaderProps: () =>  { return { align:"center",   position: "sticky",fontWeight: 700,} },
        setCellProps: () => { return { align: "center", } },

        customBodyRender: (value: string) => {
          if (value === 'Yes') {

            return (
              <>
                <img alt="compliant" src={compliantIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
              </>

            );
          }
          if (value === 'No') {
            return (
              <>
                <img alt="non compliant" src={nonCompliantIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
              </>
            );
          }
          if (value === 'Expiring') {
            return (
              <>
                <img alt="expiring" src={alarmIconOrange} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
              </>
            );
          }
          if (value === 'Expired') {
            return (
              <>
                <img alt="expired" src={expiredIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
              </>
            );
          }
          if (value === 'Undetermined') {
            return (
              <>

              </>
            );
          }




        }
      }
    },
    {
      name: "task_rep", label: "Last Task Report", options: {
        display: true, viewColumns: true, sort: false,
        filter: false,
        //setCellHeaderProps: () =>  { return { align:"center",   position: "sticky",fontWeight: 700,} },
        setCellHeaderProps: () => ({
          style: {
            align: "center",
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            fontWeight: 700,
            zIndex: 104,

          }
        }),
        setCellProps: () => { return { align: "center", position: "sticky", } },
        customBodyRender: (value: string) => {
          if (value !== '') {
            return (
              <button onClick={() => createHyperlink(value)} >
                <img alt="Task Report" src={documentsIconGrey} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
              </button>
            );
          }
          else {
            return (
              <></>
            );
          }

        }

      }
    },
  ];

  const [isTaskRepClicked, setIsTaskRepClicked] = useState(false);
  const [cols, setCols] = useState(columnsMUITable);
  const [selectedFilter, setSelectedFilter] = useState("All");

  const [colsTasks, setColsTasks] = useState(columnsMUITasksTable);


  const [colsSFJobs, setColsSFJobs] = useState(columnsMUISFTable);


  const ExtraButtons = () => (
    <>
      <Tooltip disableFocusListener title="View Map">
        <IconButton size="large">
          <Link to={`/client/${clientId}/assets/${siteId}/mapping`} className={styles.assetTypeStatisticLink}>
            <MapIconGreen />
          </Link>
        </IconButton>
      </Tooltip>

      <Tooltip disableFocusListener title="View Drawings">
        <IconButton size="large">
          <Link to={`/client/${clientId}/assets/${siteId}/drawings?type=${systemChoice.toUpperCase().replace(/ /g, '_')}}`} className={styles.assetTypeStatisticLink}>
            <DrawingCompassIcon />
          </Link>
        </IconButton>
      </Tooltip>
      <ReportGenerator></ReportGenerator>
    </>
  );


  const ExtraButtonsTasks = () => (
    <>
      <ReportGenerator></ReportGenerator>
    </>
  );


  const ExtraButtonsSelect = () => (
    <>            <div className={styles.createTaskLink}>
      <AppButtonLink
        to={{
          pathname: `/client/${clientId}/tasks/${siteId}/add`,
        }}
        state={{
          assetIds: selectedAssetIds,
        }}
        disabled={selectedAssetIds.length === 0}
      >
        {t('tasks')('createTask')}
      </AppButtonLink></div>

    </>


  );

  const createHyperlink = (passedurl: string) => {
    setIsTaskRepClicked(true);

    setTaskviewClick(passedurl)
  }

  React.useEffect(() => {

    if (!isTaskRepClicked) return;

    let newurl = taskviewClick;

    // set values to display the same data on return
    contextStore.setDashboardPicks(compliancyChoice, systemChoice, systemBarChoice, systemBarCompChoice, true);
    
    navigate(newurl)

  }, [taskviewClick]
  );


  const fetchDataTaskTableModuleChange = async () => {
    // console.log("In fetchDataTaskTableModuleChange")
    let curmodule;
    if (preselectedmodules.length === 2) {
      curmodule = 'all'
    }
    else if (preselectedmodules.length === 1) {
      curmodule = preselectedmodules[0];
    }
    else {
      curmodule = 'all'
    }
    //console.log("curmodule:",curmodule )
    try {
      let urltogoto = `dashboard/taskstable?siteid=${siteId}&month=${selectedMonth}&year=${selectedYear}&module=${curmodule}`
      await fetch(`${process.env.API_ROOT}${urltogoto}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        
        const taskData = Array.isArray(dataret) && dataret.map((u: { id: string; idreadable: string; tasktitle: string; status: string; orig_status: string; internal_responsible: string; assetscount: any; display_value: any; deadline: any; }) => {
          return {
            "taskid": u.idreadable,
            "Task Name": u.tasktitle,
            "Task Type": u.display_value,
            "Deadline": u.deadline,
            "Responsible": u.internal_responsible,
            "Numer of Assets": u.assetscount,
            "orig_status": u.orig_status,
            "Status": u.status,

          };
        });
        setDataTasks(taskData || []);

      });

    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }

  const fetchDataTaskTable = async (month: number, year: number) => {
    let curmodule;
    if (preselectedmodules.length === 2) {
      curmodule = 'all'
    }
    else if (preselectedmodules.length === 1) {
      curmodule = preselectedmodules[0];
    }
    else {
      curmodule = 'all'
    }
    console.log("curmodule:", curmodule)
    try {
      let urltogoto = `dashboard/taskstable?siteid=${siteId}&month=${month}&year=${year}&module=${curmodule}`
      await fetch(`${process.env.API_ROOT}${urltogoto}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        ;
        const taskData = dataret.map((u: { id: string; idreadable: string; tasktitle: string; status: string; orig_status: string; internal_responsible: string; assetscount: any; display_value: any; deadline: any; }) => {
          return {
            "taskid": u.idreadable,
            "Task Name": u.tasktitle,
            "Task Type": u.display_value,
            "Deadline": u.deadline,
            "Responsible": u.internal_responsible,
            "Numer of Assets": u.assetscount,
            "orig_status": u.orig_status,
            "Status": u.status,

          };
        });
        setDataTasks(taskData);

      });

    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }

  const eventStyleGetter = function (event: any, start: any, end: any, isSelected: any) {
    let backgroundColor = event.bgcolor;
    if (event.status === 'sent') {
      backgroundColor = alpha(theme.colors.yellow, 0.25);
    }
    else if (event.status === 'contractor_accepted' || event.status === 'in_progress' || event.status === 'supp_info_submitted' || event.status === 'completed') {

      backgroundColor = alpha(theme.colors.orange, 0.25);

    }
    else if (event.status === 'rejected_closed' || event.status === 'rejected') {
      backgroundColor = alpha(theme.palette.error.main, 0.25);
    }
    else if (event.status === 'approved_closed') {
      backgroundColor = alpha(theme.palette.primary.main, 0.25);
    }
    else if (event.status === 'cancelled') {
      backgroundColor = alpha('#000000', 0.12);
    }
    let style = {
      backgroundColor: isSelected ? "orange" : backgroundColor,
      borderRadius: '5px',
      padding: '1px',
      color: 'black',
      border: '2px',

    };
    return {
      style: style
    };
  }

  async function getCalendarData(fetchInfo: { start: any; end?: Date; startStr?: string; endStr?: string; timeZone?: string; }, successCallback: { (events: EventInput[]): void; (arg0: any): void; }) {
    if (!doRefresh) {
      // console.log("Do not do full refresh")
    }
    else {
      try {

        // console.log("Am in getCalendarData")

        // update the task table as well

        // console.log("Passed Data:", fetchInfo)
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;

        if (fetchInfo) {
          year = new Date(fetchInfo.start).getFullYear();
          month = new Date(fetchInfo.start).getMonth() + 1;
        }

        // console.log("Calendar Month is ", month)
        // console.log("Calendar Year is ", year)
        // const response = await api.get(API, { year, month });
        // console.log("New Current Month", currentMonth) 
        // console.log(`${process.env.API_ROOT}dashboard/calendar?siteid=${siteId}&year=${year}&month=${month}`);
        const response = await fetch(`${process.env.API_ROOT}dashboard/calendar?siteid=${siteId}&year=${year}&month=${month}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();
          successCallback(
            dataret.map((event: { id: any; title: any; start: any; end: any; idreadable: any; bgcolor: any; status: any; }) => {
              return {
                id: event.id,
                title: event.title,
                start: event.start,
                end: event.end,
                idreadable: event.idreadable,
                bgcolor: event.bgcolor,
                status: event.status,
              };
            })
          );
        })
      } catch (error) {
        // console.log(error);
      }

    }
  }



  React.useEffect(() => {

    const updatedvalues = [...cols];
    //console.log("IN HERE AT 1093")
  }, [curSiteId]
  );




  const onFilter = (value: any, indexval: number) => {
    setSelectedFilter(value);
    let filteredCols;

    console.log("IN onFilter, value is", value)
    console.log("IN onFilter, indexval is", indexval)

    //columnsMUISFTable
    let filterList: any[] | undefined = [];

    console.log("IN ONFILTER")

    if (activePanel === 'salesforce') {
      filteredCols = [...colsSFJobs];
      console.log("Set filter for SF Jobs")
      filteredCols[1].options.filterList = filterList;
      console.log("filterList", filterList)

      if (indexval === 3)   // Indexval is 3 which is Maintance
      {
        if (value === 'Reactive') {
          value = 'No'
        }
        else {
          value = 'Yes'
        }

      }

      if (indexval === 5)   // Indexval is Job Type
      {
        filteredCols[3].options.filterList = ['Yes'];
        indexval = 2;
      }
      if (indexval === 6)   // Indexval is Job Type
      {
        filteredCols[3].options.filterList = ['No'];
        indexval = 2;
      }


      if (indexval === 1) {
        filteredCols[2].options.filterList = filterList;
      }

      filterList = [value];
      filteredCols[indexval].options.filterList = filterList;
      console.log("filterList", filterList)
      console.log("filteredCols", filteredCols[indexval].options.filterList)

    }
    else {
      filteredCols = [...cols];
      if (value !== "All") {
        // if pie has been clicked, clear others
        if (indexval === 1) {
          filteredCols[2].options.filterList = filterList;
          filteredCols[4].options.filterList = filterList;
        }

        filterList = [value];
        filteredCols[indexval].options.filterList = filterList;

      }
      else {
        filteredCols[1].options.filterList = filterList;
        filteredCols[2].options.filterList = filterList;
        filteredCols[4].options.filterList = filterList;
      }


    }


    // Target the column to filter on.
    if (activePanel === 'salesforce') {
      console.log("Apply Filter")
      setColsSFJobs(filteredCols);
    }
    else {
      setCols(filteredCols);
    }

  };


  const [dataPie, setDataPie] = useState([]);
  const [dataAssets, setDataAssets] = useState([]);

  // Salesforce Tab
  const [dataSFJobs, setDataSFJobs] = useState([]);

  const [dataTasks, setDataTasks] = useState([]);

  const [dataBarSys, setDataSys] = useState([]);

  const [pieChartDisplay, setPieChartDisplay] = useState([null]);

  const colorCompliant = theme.palette.primary.main;
  const colorNonCompliant = theme.palette.error.main;
  const colorGrey = theme.palette.grey[500];

  const [isLoading, setIsLoading] = useState(false);
  const [compliancyChoice, setCompliancyChoice] = useState(contextStore.compFilterValue);

  const [piesfChoice, setPiesfChoice] = useState('maintenance');
  const [sfDataPulled, setSFDataPulled] = useState([]);

  const [taskOptionChoice, setTaskOptionChoice] = useState('type');

  const [isInitalLoadDone, setInitalLoadDone,] = useState(false);

  const [systemChoice, setSystemChoice] = useState(contextStore.pieFilterValue);
  const [systemBarChoice, setSystemBarChoice] = useState('all');
  const [systemBarCompChoice, setSystemBarCompChoice] = useState('all');

  const [backgroundPieColor, setBackgroundPieColor] = useState(noncompliantbackground);
  const [backgroundBorderPieColor, setBackgroundBorderPieColor] = useState(noncompliantborder);

  //const [piechartdata, setPiechartdata] = useState<any[]>([]);


  const [isAllData, setIsAllData] = useState(false);

  const handleAllDataChange = (checked: boolean | ((prevState: boolean) => boolean)) => {
    setIsAllData(checked);
    console.log("All Data toggled:", checked);
    // Implement your logic for loading all data
   if (checked)
   {
      getSalesForceJobs();
   }
   else
   {
      // get current value
      let month = new Date(dateCurSF).getMonth() + 1;
      // console.log("Current Month of Date is:", month)
      let year = new Date(dateCurSF).getFullYear();

      // proper use is to use get selected 


      getSalesForceJobs(month,year);

   }




  };


  let piechartdata: any[] = [];

  let piechartdataSF: any[] = [];

  let dataforchart: ChartData<"pie", number[], unknown> | undefined;
  let dataforchartbar: ChartData<"bar", number[], unknown> | undefined;
  let labelstoshow: any[] = [];
  let labelstoshowSF: any[] = [];
  let datatoshow: any[] = [[]];

  const [againPie, setAgainPie] = useState(dataforchart);

  const [againBar, setAgainBar] = useState(dataforchartbar);

  const [graph, setGraph] = useState<any>({
    labels: [],
    data: [],
  });

  let taskDataHere: any[] | ((prevState: pieOptions[] | undefined) => pieOptions[] | undefined) | undefined;

  const handleSFPieMaintStatus = () => {

    setPiesfChoice('maintstat');
  };

  const handleSFPieReactStatus = () => {

    setPiesfChoice('reactstat');
  };
  const tableoptions = {
    rowsPerPage: 25,
    rowsPerPageOptions: [25 ,50, 100, 250, 500],
    filter: true,
    filterType: 'multiselect',
    print: false,
    selectableRows: 'multiple',
    expandableRows: false,
    selectableRowsHeader: true,
    fixedHeader: true,
    tableBodyHeight: "700px",
    customToolbar: ExtraButtons,
    customToolbarSelect: ExtraButtonsSelect,
    onRowSelectionChange: (currentRowsSelected: any, allRowsSelected: any) => {
      let arr = [...selectedAssetIds]; //copy array by value
      if (currentRowsSelected.length > 0) {
        arr.push(dataAssets[currentRowsSelected[0].dataIndex].id)
        setSelectedAssetIds(arr) //override state with new values
      }
    },
    textLabels: {
      selectedRows: {
        text: "asset(s) selected",
      },
    }
  };


  // Custom search field with "All Data" checkbox
  const customSearchRender = (searchText = "", handleSearch, hideSearch, options) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <TextField
          value={searchText} // Ensure this is always a string
          onChange={(e) => {
            const query = e.target.value.trim();
            handleSearch(query); // Update search state
          }}
          placeholder="Search..."
          variant="standard" // Use standard variant for flat appearance
          InputProps={{
            disableUnderline: true, // Remove underline
            style: {
              width: "650px", // Set width
              height: "40px", // Set height
              borderBottom: "2px solid #ccc", // Add bottom border
              paddingLeft: "10px", // Add left padding for spacing
            },
          }}
        />
        <Tooltip disableFocusListener title="Past 18 Months">
        <FormControlLabel
          control={
            <Checkbox
              checked={options.showAllData}
              onChange={(e) => options.onAllDataChange(e.target.checked)}
              color="primary"
            />
          }
          label="All Data"
        /></Tooltip>
      </div>
    );
  };


  const tableoptionssf = {
    //  serverSide: true,
      /* search: true,
       customSearchRender,
       onSearchChange: (searchQuery: any) => {
         console.log("Searching for ", searchQuery)
         const trimmedQuery = searchQuery?.trim() || "";
         if (trimmedQuery.length >= 3 || trimmedQuery.length === 0) {
           setSearchText(trimmedQuery); // Only update state if 3+ characters or cleared
         }
       },  */
       rowsPerPage: 25,
       rowsPerPageOptions: [25 ,50, 100, 250, 500],
       customToolbar: () => (
         <CustomToolbar onAllDataChange={handleAllDataChange} isAllData={isAllData} />
       ),
       filter: true,
       filterType: 'multiselect',
       print: false,
       expandableRows: true,
       selectableRows: false,
      // customToolbar: ExtraButtonsSF, 
      /* onDownload: (buildHead, buildBody, columns, data) => {
         // call sepearate routine to export data
         const curtime = new Date();
         // create filename for file
         const yyyy = curtime.getFullYear();
         const mm = curtime.getMonth() < 9 ? "0" + (curtime.getMonth() + 1) : (curtime.getMonth() + 1); // getMonth() is zero-based
         const dd  = curtime.getDate() < 10 ? "0" + curtime.getDate() : curtime.getDate();
         const hh = curtime.getHours() < 10 ? "0" + curtime.getHours() : curtime.getHours();
         const mmm  = curtime.getMinutes() < 10 ? "0" + curtime.getMinutes() : curtime.getMinutes();
         const ss  = curtime.getSeconds() < 10 ? "0" + curtime.getSeconds() : curtime.getSeconds();
         
         const ncfile = "jobs_"+yyyy+mm+dd+"_"+hh+mmm+ss+".xlsx";
       
       //  const urltogoto =   "listings/assetreport?site=" + siteId;
         const urltogoto =   "sf/jobsreport/0011t00000Pub45AAB?month=5&year=2023";
         console.log("urltogoto:", urltogoto)
         // Fetch the task types for the selected asset
         fetch(`${process.env.API_ROOT}${urltogoto}`,  {
           method: 'GET',
           credentials: 'include',
         }).then(response => response.blob())
         .then(blob => {
             var url = window.URL.createObjectURL(blob);
             var a = document.createElement('a');
             a.href = url;
             a.download = ncfile;
             document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
             a.click();
             a.remove();  //afterwards we remove the element again 
   
         });
         return false;
       }, */
       renderExpandableRow:  (rowData: any, rowMeta: any) => {
        
         // changeRowColor(rowMeta.dataIndex);
          return <SFExpandedRows index={dataSFJobs[rowMeta.dataIndex]}  />;
   
        },
       
     };

      // Fetch data whenever search text changes
  useEffect(() => {
        // fetchData();
        console.log("Will trigger search here but need to determine if ALL button was pressed")
  }, [searchText]);
   

  const tasksRowClick = (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {

    let rowpickeddata = dataTasks[rowMeta.dataIndex];

  }

  const tableoptionstasks = {
    rowsPerPage: 25,
    rowsPerPageOptions: [25 ,50, 100, 250, 500],
    filter: true,
    filterType: 'multiselect',
    print: false,
    selectableRows: 'none',
    fixedHeader: true,
    tableBodyHeight: "400px",
    customToolbar: ExtraButtonsTasks,
    onRowClick: tasksRowClick,
  };

  const RadioButton = ({ label, value, onChange, tooltipmessage }) => {
    return (
      <Tooltip disableFocusListener title={tooltipmessage}>
        <label>
          <input type="radio" checked={value} onChange={onChange} className="custom-radio" />
          {label}
        </label>
      </Tooltip>
    );
  };

  const handleSFPieMaintenanceChoice = () => {
    setPiesfChoice('maintenance');
  };

  const handleSFPieJobTypeChoice = () => {
    //   console.log("Setting PiesfChoice to JobType")
    setPiesfChoice('jobtype');
  };


  const handleNonCompliantChoice = () => {
    setCompliancyChoice('noncompliant');
  };

  const handleCompliantChoice = () => {
    setCompliancyChoice('compliant');
  };

  const handleUndeterminedChoice = () => {
    setCompliancyChoice('undetermined');
  };

  const handleExpiringChoice = () => {
    setCompliancyChoice('expiring');
  };

  const handleExpiredChoice = () => {
    setCompliancyChoice('expired');
  };

  const handleTaskOptionChoiceSysRef = () => {
    setTaskOptionChoice('sysref');
  };

  const handleTaskOptionChoiceType = () => {
    setTaskOptionChoice('type');
  };

  const handleTaskOptionChoiceStatus = () => {
    setTaskOptionChoice('status');
  };


  const showPieOptions = async () => {

    // November 27 Update to Only Show Radio Buttons for Entries with data

    let expstatuschk = `dashboard/statuschk/${siteId}`;
    try {
      await fetch(`${process.env.API_ROOT}${expstatuschk}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();

        const taskData = dataret.map((u: { numc: number; compliant_display: string; }) => {
          return {
            "numc": u.numc,
            "compliant_display": u.compliant_display,

          };

        });
        console.log("taskData:", taskData)
        setPieOptionsData(taskData);

      })
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }

  };



  const counter = {
    id: 'counter',
    beforeInit: function (chart: { legend: { fit: () => void; }; }) {
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        this.height += 35;
      };
    },
    afterDraw: function (chart: { data: { datasets: { data: any[]; }[]; }; ctx: any; width: any; height: any; clear: () => void; }) {


      if (chart.data.datasets[0].data.every((item: number) => item === 0)) {

        let ctx = chart.ctx;
        let width = chart.width;
        let height = chart.height;

        chart.clear();
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('No Data to display', width / 2, height / 2);
        ctx.restore();
      }

    }

  }



  ChartJS.register(counter);



  const chartoptionsPie = {

    maintainAspectRatio: false,
    responsive: false,
    hoverOffset: 15,

    plugins: {
      counter,
      legend: {
        display: true,
        position: 'left'
      }
    },

    layout: {
      padding: {
        bottom: 10,  //set that fits the best
        top: 10      //set that fits the best
      }
    },

  }


  const chartoptionsBar = {
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    }
  };


  const chartRef = useRef();
  const chartBarRef = useRef();

  const printElementAtEvent = (element: InteractionItem[]) => {
    if (!element.length) return;


    // check active panel
    console.log("Current Active Panel is:", activePanel)


    const { datasetIndex, index } = element[0];

    if (activePanel === 'salesforce') {
      console.log("Deal with Salesforce Pie")

      // get selected slice and apply as a filter to the salesforce jobs listings. Need reset option/clear filter when calendar changes
      console.log("againPie.labels[index]", againPie.labels[index])
      console.log("The piesfChoice is", piesfChoice)
      if (piesfChoice === 'maintenance') {
        onFilter(againPie.labels[index], 3);
      }
      else if (piesfChoice === 'jobtype') {
        onFilter(againPie.labels[index], 1);
      }
      else if (piesfChoice === 'maintstat') {
        onFilter(againPie.labels[index], 5);
      }
      else if (piesfChoice === 'reactstat') {
        onFilter(againPie.labels[index], 6);
      }


    }
    else if (activePanel === 'assets') {
      setSystemChoice(againPie.labels[index]);

      getBarData(againPie.labels[index]);

      onFilter(againPie.labels[index], 1);
    }
    else {
      // console.log("Will update the task bar based on the pie selection")
      setSystemChoice(againPie.labels[index]);

      getBarDataTask(againPie.labels[index]);

    }


    /* if (showComplianceOptions)
     {
     
     }
     else
     {
       
     } */

  };


  const showAll = () => {

    onFilter('All', 0)
    setSystemChoice('all');
    getBarData('all');
    contextStore.setDashboardPicks('noncompliant', 'all', 'all', 'all', false)

  };

  const printElementAtEventBar = (element: InteractionItem[]) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];

    let valuesel = againBar.labels[index];

    if (showComplianceOptions) {

      setSystemBarChoice(valuesel);

      if (againBar.datasets[datasetIndex].label === 'Compliant') {
        setSystemBarCompChoice('Yes')
      }
      else if (againBar.datasets[datasetIndex].label === 'Non-Compliant') {

        setSystemBarCompChoice('No')
      }
      else if (againBar.datasets[datasetIndex].label === 'Expiring') {

        setSystemBarCompChoice('Expiring')
      }
      else if (againBar.datasets[datasetIndex].label === 'Expired') {

        setSystemBarCompChoice('Expired')
      }
      else {
        setSystemBarCompChoice('Undetermined')
      }


      const filteredCols = [...columnsMUITable];


      onFilter(valuesel, 2);

      let compval = againBar.datasets[datasetIndex].label;
      if (compval === 'Compliant')
        onFilter('Yes', 4);
      else if (compval === 'Non-Compliant')
        onFilter('No', 4);
      else
        onFilter(compval, 4);


    }
    else {

      // need to update the assets table based on the selection - make sure to update the title as well
      // Unlike the Assets Filtering, this needs to involve calling an API and setting the table

      // firstly get the value of what was clicked - will need the radio button value and the label clicked

      // console.log("Label:", valuesel)
      // console.log("taskOptionChoice:", taskOptionChoice)
      let urltogoto = '';
      // Set the label

      let newlabel = systemChoice + " / " + valuesel
      setTasksTableTitle(newlabel);
      urltogoto = `dashboard/taskstable?siteid=${siteId}&option=${taskOptionChoice}&tasktype=${systemChoice}&assettype=${valuesel}`

      // need to also pass the current dates as well for query 







      // console.log("Bar has been clicked on:", urltogoto);
      // Now get the tasks and update the table
      try {

        fetch(`${process.env.API_ROOT}${urltogoto}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();
          ;
          const taskData = dataret.map((u: { id: string; idreadable: string; tasktitle: string; status: string; internal_responsible: string; assetscount: any; display_value: any; deadline: any; orig_status: any; }) => {

            return {
              "taskid": u.idreadable,
              "Task Name": u.tasktitle,
              "Task Type": u.display_value,
              "Deadline": u.deadline,
              "Responsible": u.internal_responsible,
              "Status": u.status,
              "orig_status": u.orig_status,

            };
          });
          // console.log("taskData", taskData);
          setDataTasks(taskData);
        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }




    }
  };

  // USER HAS CLICKED ON PIE SLICE
  const onClickPie = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;

    const { current: chartBar } = chartBarRef;

    if (!chart) {
      return;
    }

    printElementAtEvent(getElementAtEvent(chart, event));

  };

  // USER HAS CLICKED ON BAR
  const onClickBar = (event: MouseEvent<HTMLCanvasElement>) => {

    const { current: chartBar } = chartBarRef;

    if (!chartBar) {
      return;
    }

    printElementAtEventBar(getElementAtEvent(chartBar, event));

  };

  React.useEffect(() => {
    if (!isInitalLoadDone) return;

    // Compliancy Change, get new data
    getPieDataSF()
  }, [piesfChoice]
  );





  React.useEffect(() => {
    if (!isInitalLoadDone) return;

    // Compliancy Change, get new data
    getPieData()
  }, [compliancyChoice, taskOptionChoice, showTaskOptions, monthNav, dateCur]
  );


  /*React.useEffect(() => {
    if (!isInitalLoadDone) return;

    // Compliancy Change, get new data
    console.log("WILL GET SF JOBS")
    let month = new Date(dateCurSF).getMonth() + 1;
    // console.log("Current Month of Date is:", month)
    let year = new Date(dateCurSF).getFullYear();
    getSalesForceJobs(month, year);

  }, [dateCurSF]
  ); */


  React.useEffect(() => {
    if (!isInitalLoadDone) return;
  
    // Compliancy Change, get new data
      let month = new Date(dateCurSF).getMonth() + 1;
      // console.log("Current Month of Date is:", month)
      let year = new Date(dateCurSF).getFullYear();
  
      // Called when the user has moved from month to month. Need to cater for All Data being selected
     
  
      if (isAllData)
      {
        console.log("All DATA selection at line 2619 is currently true:", isAllData)
  
  
        // If true need to update the calendar for selected month from all data
  
  
  
      }
      else
      {
        //setIsAllData(false);
        getSalesForceJobs(month,year);
      }
  
  
     
  
  }, [dateCurSF]
  );





  React.useEffect(() => {

    // console.log("Data Changes")

  }, [dataTasks]
  );

  function checktabindex(indexvalue: any) {

    if (indexvalue === 'tasks') {
      setShowComplianceOption(false)
      setTaskOption(true)
      setSalesforceCalendar(false)
      //setTaskOptionChoice('task')
      getTaskPieData();
      getBarDataTask();


    }
    else if (indexvalue === 'assets') {
      getPieData();
      setShowComplianceOption(true)
      setTaskOption(false)
      setSalesforceCalendar(false)
      showAll();

    }
    else if (indexvalue === 'salesforce') {
      getPieDataSF()
      setSalesforceCalendar(true)
    }


  }

  async function getBarDataTask(tasktype?: any) {

    let barchartdataTasks: any[] = [];
    let barlabelstoshow: any[] = [];

    // Get the current task type
    // console.log("Current task radio: ", taskOptionChoice)

    // Also add the month/year values as a parameters
    // Also pass the current month
    // console.log("Current Value of Date is:", dateCur)
    let month = new Date(dateCur).getMonth() + 1;
    // console.log("Current Month of Date is:", month)
    let year = new Date(dateCur).getFullYear();



    let urltogotoBarComp = 'dashboard/tasksbar?siteid=' + siteId;

    let curmodule;
    if (preselectedmodules.length === 2) {
      curmodule = 'all'
    }
    else if (preselectedmodules.length === 1) {
      curmodule = preselectedmodules[0];
    }
    else {
      curmodule = 'all'
    }
    console.log("curmodule:", curmodule)


    // console.log("urltogotoBarComp:", urltogotoBarComp)

    // console.log("Selected Task Type:", tasktype)

    if (tasktype) {
      if (monthNav) {
        urltogotoBarComp = `dashboard/tasksbar?siteid=${siteId}&option=${taskOptionChoice}&type=${tasktype}&month=${month}&year=${year}&module=${curmodule}`
      }
      else {
        urltogotoBarComp = `dashboard/tasksbar?siteid=${siteId}&option=${taskOptionChoice}&type=${tasktype}&module=${curmodule}`
      }

    }

    // console.log("urltogotoBarComp", urltogotoBarComp);

    try {
      await fetch(`${process.env.API_ROOT}${urltogotoBarComp}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        barchartdataTasks = [];
        barlabelstoshow = []
        for (let i = 0; i < dataret.length; i++) {
          barchartdataTasks.push(dataret[i].count);
          barlabelstoshow.push(dataret[i].display_value)

        }

        // NOW SET THE BAR DETAILS
        const bardataforchart = {
          labels: barlabelstoshow,
          datasets: [

            {
              label: 'Tasks by Asset Type',
              data: barchartdataTasks,
              backgroundColor: [
                'rgba(56, 50, 120, 1)',

              ],
              borderColor: [
                'rgba(56, 50, 120, 0.2)',

              ],
              borderWidth: 1,
              borderRadius: 10,
              hoverBorderColor: ['rgba(56, 50, 120, 1)',
              ],
              hoverBorderWidth: 1
            }
          ],
        };

        setAgainBar(bardataforchart);
      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }


  async function getBarData(assettype: any) {
    // Now Set Sys Bar
    let barchartdataSys: any[] = [];
    let barchartdataSysFalse: any[] = [];
    let barchartdataSysUndeter: any[] = [];
    let barchartdataSysExpiring: any[] = [];
    let barchartdataSysExpired: any[] = [];
    let barlabelstoshow: any[] = [];
    // NEED TO GET EACH BUT WILL PLACE IN ONE

    // Compliant
    let urltogotoBarComp = 'dashboard/systypebartrue?siteid=' + siteId + '&type=' + assettype;
    try {
      await fetch(`${process.env.API_ROOT}${urltogotoBarComp}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        barchartdataSys = [];
        barlabelstoshow = []
        for (let i = 0; i < dataret.length; i++) {
          barchartdataSys.push(dataret[i].numass);
          barlabelstoshow.push(dataret[i].sysname)

        }

      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }

    // Expiring
    let urltogotoBarExpiring = 'dashboard/systypebarexpiring?siteid=' + siteId + '&type=' + assettype;
    try {
      await fetch(`${process.env.API_ROOT}${urltogotoBarExpiring}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        barchartdataSysExpiring = [];

        for (let i = 0; i < dataret.length; i++) {
          barchartdataSysExpiring.push(dataret[i].numass);
        }

      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }



    // Expired
    let urltogotoBarExpired = 'dashboard/systypebarexpired?siteid=' + siteId + '&type=' + assettype;
    try {
      await fetch(`${process.env.API_ROOT}${urltogotoBarExpired}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        barchartdataSysExpired = [];

        for (let i = 0; i < dataret.length; i++) {
          barchartdataSysExpired.push(dataret[i].numass);
        }

      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }




    // NonCompliant
    let urltogotoBarNonComp = 'dashboard/systypebarfalse?siteid=' + siteId + '&type=' + assettype;
    try {
      await fetch(`${process.env.API_ROOT}${urltogotoBarNonComp}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        barchartdataSysFalse = [];

        for (let i = 0; i < dataret.length; i++) {
          barchartdataSysFalse.push(dataret[i].numass);
        }

      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }

    // Undeter
    let urltogotoBarUndeter = 'dashboard/systypebarnull?siteid=' + siteId + '&type=' + assettype;
    try {
      await fetch(`${process.env.API_ROOT}${urltogotoBarUndeter}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        barchartdataSysUndeter = [];

        for (let i = 0; i < dataret.length; i++) {
          barchartdataSysUndeter.push(dataret[i].numass);
        }

        let bardatasets: { label: string; data: any[]; backgroundColor: string[]; borderColor: string[]; borderWidth: number; borderRadius: number; hoverBorderColor: string[]; hoverBorderWidth: number; }[] = [];

        // Need to make the bar charts dynamic
        pieOptionsData?.map((item) => {


          if (item.compliant_display === 'No') {
            let onetopush = {
              label: 'Non-Compliant',
              data: barchartdataSysFalse,
              backgroundColor: [
                'rgba(220, 28, 19, 1)',

              ],
              borderColor: [
                'rgba(220, 28, 19, 0.2)',

              ],
              borderWidth: 1,
              borderRadius: 10,
              hoverBorderColor: ['rgba(220, 28, 19, 1)',
              ],
              hoverBorderWidth: 1
            }
            bardatasets.push(onetopush);

          }

          if (item.compliant_display === 'Yes') {
            let onetopush = {
              label: 'Compliant',
              data: barchartdataSys,
              backgroundColor: [
                'rgba(68, 146, 77, 1)',

              ],
              borderColor: [
                'rgba(68, 146, 77, 0.2)',

              ],
              borderWidth: 1,
              borderRadius: 10,
              hoverBorderColor: ['rgba(68, 146, 77, 1)',
              ],
              hoverBorderWidth: 1
            }
            bardatasets.push(onetopush);

          }

          if (item.compliant_display === 'Undetermined') {
            let onetopush = {
              label: 'Undetermined',
              data: barchartdataSysUndeter,
              backgroundColor: [
                'rgba(56, 50, 120, 1)',

              ],
              borderColor: [
                'rgba(56, 50, 120, 0.2)',

              ],
              borderWidth: 1,
              borderRadius: 10,
              hoverBorderColor: ['rgba(56, 50, 120, 1)',
              ],
              hoverBorderWidth: 1
            }
            bardatasets.push(onetopush);

          }

          if (item.compliant_display === 'Expiring') {
            let onetopush = {
              label: 'Expiring',
              data: barchartdataSysExpiring,
              backgroundColor: [
                'rgba(242, 146, 77, 1)',

              ],
              borderColor: [
                'rgba(242, 146, 77, 0.2)',

              ],
              borderWidth: 1,
              borderRadius: 10,
              hoverBorderColor: ['rgba(242, 146, 77, 1)',
              ],
              hoverBorderWidth: 1
            }
            bardatasets.push(onetopush);
          }

          if (item.compliant_display === 'Expired') {
            let onetopush = {
              label: 'Expired',
              data: barchartdataSysExpired,
              backgroundColor: [
                'rgba(194, 70, 102, 1)',

              ],
              borderColor: [
                'rgba(194, 70, 102, 0.2)',

              ],
              borderWidth: 1,
              borderRadius: 10,
              hoverBorderColor: ['rgba(194, 70, 102, 1)',
              ],
              hoverBorderWidth: 1
            }
            bardatasets.push(onetopush);
          }


        });

        // NOW SET THE BAR DETAILS
        const bardataforchart = {
          labels: barlabelstoshow,
          datasets: bardatasets,
        };

        setAgainBar(bardataforchart);



      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }



  async function getTaskPieData() {

    let urltogoto = 'dashboard/taskspie?siteid=';


    console.log("IN getTaskPieData - line 2664")

    let backgroundcolorchoice: string[];
    let backgroundbordercolorchoice: string[];


    backgroundcolorchoice = undeterbackgroundColor;
    backgroundbordercolorchoice = undeterborderColor;

    let curmodule;
    if (preselectedmodules.length === 2) {
      curmodule = 'all'
    }
    else if (preselectedmodules.length === 1) {
      curmodule = preselectedmodules[0];
    }
    else {
      curmodule = 'all'
    }
    console.log("curmodule:", curmodule)

    try {
      await fetch(`${process.env.API_ROOT}${urltogoto}${siteId}&year=${selectedYear}&month=${selectedMonth}&module=${curmodule}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        console.log("dataret at line 2683:", dataret)
        piechartdata = [];
        labelstoshow = [];
        for (let i = 0; i < dataret.length; i++) {
          piechartdata.push(dataret[i].count);
          labelstoshow.push(dataret[i].display_value)

        }
        setDataPie(dataret);

        const labels: any[] = [];
        const data: any[] = [];


        setGraph({
          labels: labelstoshow,
          data: piechartdata,
        });


        const piedataforchart = {
          labels: labelstoshow,
          datasets: [
            {
              label: '# of Tasks',
              data: piechartdata,
              parsing: {
                key: 'nested.value'
              },

              backgroundColor: backgroundcolorchoice,
              borderColor: backgroundbordercolorchoice,
              borderWidth: 1,

            },
          ],
        };
        setAgainPie(piedataforchart);


      });
    } catch (error) {
      console.error(error);
    }
  }

  async function getPieDataSF() {
    // cater for  sf 


    let jobtypesarr: { jobtype: string; }[] = [];
    const sfData = sfDataPulled.map((u: { Id: string; Maintenance1__c: string; Account__c: string; Account_Name__c: string; Name: string; Status__c: string; Job_Type__c: string; Schedule_Date__c: any; Duration_Hours_Days__c: any; Duration_of_Job__c: any; Job_Start_Date_Time__c: any, Job_End_Date_Time__c: any, Vehicles_on_Site__c: any, productCount__c: any, Total_Price__c: any, Job_Reference__c: any, Site_Contact__c: any, Type_of_Waste__c: any, InvoiceNo: any, }) => {
      let bgcolorsel = '';
      let obj;

      if (piesfChoice === 'maintenance') {
        let valtoshow;

        if (u.Maintenance1__c === 'Yes') {
          obj = { "jobtype": "Maintenance" };
          bgcolorsel = alpha(theme.palette.primary.main, 0.25);
          bgcolorsel = alpha('#59adf6', 0.25);
        }
        else {
          obj = { "jobtype": "Reactive" };
          bgcolorsel = alpha('#924456', 0.25);
        }
        jobtypesarr.push(obj);
      }
      else if (piesfChoice === 'jobtype') {
        obj = { "jobtype": u.Job_Type__c };

        if (u.Status__c === 'Completed') {
          bgcolorsel = alpha(theme.palette.primary.main, 0.25);
          bgcolorsel = alpha('#59adf6', 0.25);
        }


        if (u.Status__c === 'Ready to be Scheduled') {
          bgcolorsel = alpha('#9f8bb0', 0.25);
        }
        if (u.Status__c === 'In Progress') {
          bgcolorsel = alpha('#9bb08b', 0.25);
        }

        if (u.Status__c === 'Invoice') {
          bgcolorsel = alpha('#44924d', 0.25);
        }
        jobtypesarr.push(obj);
      }
      else if (piesfChoice === 'maintstat') {
        // Maintenance By Status
        // obj = { "jobtype": u.Maintenance1__c};


        if (u.Maintenance1__c === 'Yes') {

          obj = { "jobtype": u.Status__c };
          if (u.Status__c === 'Completed') {
            bgcolorsel = alpha(theme.palette.primary.main, 0.25);
            bgcolorsel = alpha('#59adf6', 0.25);
          }


          if (u.Status__c === 'Ready to be Scheduled') {
            bgcolorsel = alpha('#9f8bb0', 0.25);
          }

          if (u.Status__c === 'In Progress') {
            bgcolorsel = alpha('#9bb08b', 0.25);
          }

          if (u.Status__c === 'Invoice') {
            bgcolorsel = alpha('#44924d', 0.25);
          }

          jobtypesarr.push(obj);
        }

      }
      else if (piesfChoice === 'reactstat') {
        // Maintenance By Status
        // obj = { "jobtype": u.Maintenance1__c};


        if (u.Maintenance1__c === 'No') {
          obj = { "jobtype": u.Status__c };
          if (u.Status__c === 'Completed') {
            bgcolorsel = alpha(theme.palette.primary.main, 0.25);
            bgcolorsel = alpha('#caa1ed', 0.25);
          }


          if (u.Status__c === 'Ready to be Scheduled') {
            bgcolorsel = alpha('#c977c0', 0.25);
          }



          if (u.Status__c === 'In Progress') {
            bgcolorsel = alpha('#e0b4db', 0.25);
          }

          if (u.Status__c === 'Invoice') {
            bgcolorsel = alpha('#b4d1e0', 0.25);
          }
          jobtypesarr.push(obj);
        }

      }




      return {
        "id": u.Id,
        "title": u.Job_Type__c,
        "start": u.Job_End_Date_Time__c,
        "end": u.Job_End_Date_Time__c,
        "status": u.Status__c,
        "bgcolor": bgcolorsel,
        "idreadable": u.Job_Reference__c,
        "sfjobid": u.Id,
        "sfaccountid": u.Account__c,
        "sfaccountname": u.Account_Name__c,
        "sfjobname": u.Name,
        "sfstatus": u.Status__c,
        "sfjobtype": u.Job_Type__c,
        "sfscheduledate": u.Schedule_Date__c,
        "sfdurationhours": u.Duration_Hours_Days__c,
        "sfdurationofjob": u.Duration_of_Job__c,
        "sfmaintenance": u.Maintenance1__c,
        "sfjobstart": u.Job_Start_Date_Time__c,
        "sfjobend": u.Job_End_Date_Time__c,
        "sfvehiclesonsite": u.Vehicles_on_Site__c,
        "sfproductcount": u.productCount__c,
        "sftotalprice": u.Total_Price__c,
        "sfjobreference": u.Job_Reference__c,
        "sfcontactname": u.Site_Contact__c,
        "sftypeofwaste": u.Type_of_Waste__c,
        "sfinvoicenum": u.InvoiceNo,
      };
    });


    let sfdatagrouped = Object.values(jobtypesarr.reduce((c, { jobtype }) => {
      c[jobtype] = c[jobtype] || { name: jobtype, value: 0 };
      c[jobtype].value++;
      return c;
    }, {}))


    // now use those values in pie
    for (let i = 0; i < sfdatagrouped.length; i++) {
      piechartdataSF.push(sfdatagrouped[i].value);
      labelstoshowSF.push(sfdatagrouped[i].name)
    }


    let backgroundcol = compliantbackgroundColor;
    let bordercol = noncompliantborder;

    if (piesfChoice === 'maintenance') {
      backgroundcol = sfMaintenancebackgroundColor;
      bordercol = sfMaintenanceborderColor;

    }
    else if (piesfChoice === 'maintstat') {
      backgroundcol = sfMaintenanceYesbackgroundColor;
      bordercol = sfMaintenanceYesborderColor;

    }
    else if (piesfChoice === 'reactstat') {
      backgroundcol = sfMaintenanceNobackgroundColor;
      bordercol = sfMaintenanceNoborderColor;

    }

    const piedataforchartSF = {
      labels: labelstoshowSF,
      datasets: [
        {
          label: '# by Job Type',
          data: piechartdataSF,
          parsing: {
            key: 'nested.value'
          },

          backgroundColor: backgroundcol,
          borderColor: bordercol,
          borderWidth: 1,

        },
      ],
    };

    let filteredCols;
    filteredCols = [...colsSFJobs];
    filteredCols[1].options.filterList = [];
    filteredCols[2].options.filterList = [];
    filteredCols[3].options.filterList = [];



    setAgainPie(piedataforchartSF);

  }


  async function getPieData() {

    let urltogoto = 'dashboard/und/';

    let backgroundcolorchoice: string[];
    let backgroundbordercolorchoice: string[];

    console.log("IN getPieData - line 2876")

    if (compliancyChoice === 'noncompliant') {
      urltogoto = `dashboard/ncpie/${siteId}`;
      backgroundcolorchoice = noncompliantbackground;
      backgroundbordercolorchoice = noncompliantborder;

    }
    else if (compliancyChoice === 'compliant') {
      urltogoto = `dashboard/pie/${siteId}`;
      backgroundcolorchoice = compliantbackgroundColor;
      backgroundbordercolorchoice = compliantborderColor;

    }
    else if (compliancyChoice === 'expiring') {
      urltogoto = `dashboard/expiring/${siteId}`;
      backgroundcolorchoice = expiringbackgroundColor;
      backgroundbordercolorchoice = expiringborderColor;
    }
    else if (compliancyChoice === 'expired') {
      urltogoto = `dashboard/expired/${siteId}`;
      backgroundcolorchoice = expiredbackgroundColor;
      backgroundbordercolorchoice = expiredborderColor;
    }
    else {
      urltogoto = `dashboard/und/${siteId}`;
      backgroundcolorchoice = undeterbackgroundColor;
      backgroundbordercolorchoice = undeterborderColor;

    }
    console.log("At 2916 in getPieData", urltogoto)

    if (showTaskOptions) {

      let month = new Date(dateCur).getMonth() + 1;
      let year = new Date(dateCur).getFullYear();

      console.log("taskOptionChoice:", taskOptionChoice)

      if (!monthNav) {
        urltogoto = `dashboard/taskspie?siteid=${siteId}&option=${taskOptionChoice}`;
      }
      else {
        urltogoto = `dashboard/taskspie?siteid=${siteId}&option=${taskOptionChoice}&month=${month}&year=${year}`;
      }

      backgroundcolorchoice = undeterbackgroundColor;
      backgroundbordercolorchoice = undeterborderColor;
    }

    console.log("Line 2953: urltogoto", urltogoto)
    try {
      await fetch(`${process.env.API_ROOT}${urltogoto}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        console.log("Line 2946 in getPieData. Dataret is ", dataret)
        console.log("Additng More to Pie")
        piechartdata = [];
        labelstoshow = [];
        console.log("Line 2947 showTaskOptions:", showTaskOptions)
        for (let i = 0; i < dataret.length; i++) {
          piechartdata.push(dataret[i].count);
          if (showTaskOptions) { labelstoshow.push(dataret[i].display_value) }
          else { labelstoshow.push(dataret[i].assettype) }


        }
        setDataPie(dataret);

        const labels: any[] = [];
        const data: any[] = [];


        setGraph({
          labels: labelstoshow,
          data: piechartdata,
        });


        const piedataforchart = {
          labels: labelstoshow,
          datasets: [
            {
              label: '# of Assets',
              data: piechartdata,
              parsing: {
                key: 'nested.value'
              },

              backgroundColor: backgroundcolorchoice,
              borderColor: backgroundbordercolorchoice,
              borderWidth: 1,

            },
          ],
        };

        setAgainPie(piedataforchart);
      });
    } catch (error) {
      console.error(error);
    }

  }

  const handleDrillDownSF = (event: React.SetStateAction<undefined>) => {

    console.log(event.id);
    console.log(event.end);


  }

  const handleDrillDown = (event: React.SetStateAction<undefined>) => {

    setSelected(event);

    // console.log(event);

    // User has selected a day, now update the assets list
    showSelectedTaskForDate(event)
  }

  function onRangeChangeSF(range: any) {
    getcalendardataformont(range)

    // also update the graphs for the month selected
    //getPieData();
    setMonthNavSF(true);
  }
  function onRangeChange(range: any) {
    getcalendardataformont(range)

    // also update the graphs for the month selected
    //getPieData();
    setMonthNav(true);
  }

  function onNavigateSF(date: React.SetStateAction<Date>, view: any) {
    console.log("IN onNavigateSF")
    console.log("Date is ", date)
    setDateSF(date)

  }

  function onNavigate(date: React.SetStateAction<Date>, view: any) {

    setDate(date)

  }

  const handleSelectedSF = (event: React.SetStateAction<undefined>) => {
    //setSelected(event);
    console.info('[handleSelected - event]', event);

    // Will update the asset table based on the selection
    //

    console.log(event.id);
    console.log(event.end);
    showSelectedSFJob(event.end, event.id)

    //  setTasksTableTitle(`Job: ${event.idreadable}`)
    //  showSelectedTask(event.id);
  }

  const handleSelected = (event: React.SetStateAction<undefined>) => {
    setSelected(event);
    console.info('[handleSelected - event]', event);

    // Will update the asset table based on the selection
    //
    setTasksTableTitle(`Task: ${event.idreadable}`)
    showSelectedTask(event.id);
  }

  const handleSlotSelectionSF = ({ start, end, action }) => {
    // do something with it all

    // console.log("action Is:", action)

    // now get updated tasks for the selected dates
    showSelectedSFJobsForDate(start, end)
  };

  const handleSlotSelection = ({ start, end, action }) => {
    // do something with it all

    // console.log("action Is:", action)

    // now get updated tasks for the selected dates
    showSelectedTaskForDates(start, end)
  };


  const handleShowMore = (event: any) => {

    console.info('[handleShowMore - event]', event);

  }
  async function showSelectedTaskForDate(start: string | number | Date) {


    let startPass = new Date(start);
    const startDate = moment(new Date(startPass)).format('YYYY-MM-DD')


    const StartDisplay = moment(new Date(startPass)).format('DD/MM/YYYY')


    let urltogoto = `dashboard/taskstable?siteid=${siteId}&datepass=${startDate}`


    setTasksTableTitle(`Tasks for ${StartDisplay}`)

    // Now get the tasks and update the table
    try {

      await fetch(`${process.env.API_ROOT}${urltogoto}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();

        const taskData = dataret.map((u: { id: string; idreadable: string; tasktitle: string; status: string; internal_responsible: string; assetscount: any; display_value: any; deadline: any; orig_status: any; }) => {

          return {
            "taskid": u.idreadable,
            "Task Name": u.tasktitle,
            "Task Type": u.display_value,
            "Deadline": u.deadline,
            "Responsible": u.internal_responsible,

            "Status": u.status,
            "orig_status": u.orig_status,
          };
        });
        // console.log("taskData", taskData);
        setDataTasks(taskData);
      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }

  }

  async function showSelectedSFJobsForDate(start: string | number | Date, end: string | number | Date) {
    let startPass = new Date(start);
    const startDate = moment(new Date(startPass)).format('YYYY-MM-DD')

    let d = new Date(end);
    d.setDate(d.getDate() - 1);
    // console.log('End Should be ' + d); 

    const endDate = moment(new Date(d)).format('YYYY-MM-DD')

    const StartDisplay = moment(new Date(startPass)).format('DD/MM/YYYY')
    const EndDisplay = moment(new Date(d)).format('DD/MM/YYYY')

    console.log("Call the salesforce link here with a date range instead")

    let month = new Date(start).getMonth() + 1;
    // console.log("Current Month of Date is:", month)
    let year = new Date(start).getFullYear();

    getSalesForceJobs(month, year, startDate, endDate)
  }

  async function showSelectedSFJob(start: string | number | Date, jobid: string) {

    console.log("showSelectedSFJob for job id ", jobid)

    let startPass = new Date(start);

    console.log("Call the salesforce link here with a date range instead")

    let month = new Date(start).getMonth() + 1;
    // console.log("Current Month of Date is:", month)
    let year = new Date(start).getFullYear();

    getSalesForceJobs(month, year, null, null, jobid)
  }


  async function showSelectedTaskForDates(start: string | number | Date, end: string | number | Date) {


    let startPass = new Date(start);
    const startDate = moment(new Date(startPass)).format('YYYY-MM-DD')

    let d = new Date(end);
    d.setDate(d.getDate() - 1);
    // console.log('End Should be ' + d); 

    const endDate = moment(new Date(d)).format('YYYY-MM-DD')

    const StartDisplay = moment(new Date(startPass)).format('DD/MM/YYYY')
    const EndDisplay = moment(new Date(d)).format('DD/MM/YYYY')


    let urltogoto = `dashboard/taskstable?siteid=${siteId}&datepass=${startDate}`


    if (startDate === endDate) {

      setTasksTableTitle(`Tasks for ${StartDisplay}`)
    }
    else {
      urltogoto = `dashboard/taskstable?siteid=${siteId}&datepass=${startDate}&dateend=${endDate}`
      setTasksTableTitle(`Tasks for ${StartDisplay} to ${EndDisplay}`)
    }

    // Now get the tasks and update the table
    try {

      await fetch(`${process.env.API_ROOT}${urltogoto}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        ;
        const taskData = dataret.map((u: { id: string; idreadable: string; tasktitle: string; status: string; internal_responsible: string; assetscount: any; display_value: any; deadline: any; orig_status: any; }) => {

          return {
            "taskid": u.idreadable,
            "Task Name": u.tasktitle,
            "Task Type": u.display_value,
            "Deadline": u.deadline,
            "Responsible": u.internal_responsible,
            "Status": u.status,
            "orig_status": u.orig_status,
          };
        });
        // console.log("taskData", taskData);
        setDataTasks(taskData);
        setColsTasks(columnsMUITasksTable)
      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }

  }

  async function showSelectedTask(taskid: string) {
    try {
      let urltogoto = 'dashboard/taskstable?siteid='
      await fetch(`${process.env.API_ROOT}${urltogoto}${siteId}&task=${taskid}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        const taskData = dataret.map((u: { id: string; idreadable: string; tasktitle: string; status: string; internal_responsible: string; assetscount: any; display_value: any; deadline: any; orig_status: any; }) => {

          return {
            "taskid": u.idreadable,
            "Task Name": u.tasktitle,
            "Task Type": u.display_value,
            "Deadline": u.deadline,
            "Responsible": u.internal_responsible,
            "Status": u.status,
            "orig_status": u.orig_status,
          };
        });
        setDataTasks(taskData);
      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }

  }

  function groupBy(array: any[], key: any) {
    return array.reduce((result, currentValue) => {
      (result[currentValue.color] = result[currentValue.color] || []).push(
        currentValue
      );
      console.log(result);
      return result;
    }, {});
  };

  async function getSalesForceJobs(monthval?: number, yearval?: number, startdate?:any, enddate?:any, sfjobid?: any) {
    // Get Salesforce Jobs for Client
  
      console.log("sfParentAcc is: ", sfParentAccName)
  
      let urltogoto = '';
       try {
        if (monthval && yearval)
        {
          urltogoto = `sf/jobs/${sfCustomerID}?month=${monthval}&year=${yearval}&parentacc=${sfParentAccName}`;
  
        }
        else
        {
          urltogoto = `sf/jobs/${sfCustomerID}?parentacc=${sfParentAccName}`;
        }
  
           
  
  
        if (startdate && enddate)
        {
           urltogoto = `sf/jobs/${sfCustomerID}?startdate=${startdate}&enddate=${enddate}&parentacc=${sfParentAccName}`;
  
  
        }
  
        if (sfjobid)
        {
          urltogoto = `sf/jobs/${sfCustomerID}?month=${monthval}&year=${yearval}&sfjobid=${sfjobid}&parentacc=${sfParentAccName}`;
        }
        // NEED TO CALL THIS WHEN sfCustomerID has been retrieved  - 2023-02-03
        
      
  
        //let urltogoto = `sf/jobs/0011t00000Pub45AAB` // TEMP 
  
  
         await fetch(`${process.env.API_ROOT}${urltogoto}`, {
           method: 'GET',
           credentials: 'include',
         }).then(async (response) => {
             let jobtypesarr: { jobtype: string; }[] = [];
             const dataret = await response.json();
             setSFDataPulled(dataret);
             const sfData = dataret.map((u: { Id: string; Maintenance1__c: string; Account__c: string; Account_Name__c: string; Name: string; Status__c: string; Job_Type__c: string; Schedule_Date__c: any; Duration_Hours_Days__c: any; Duration_of_Job__c: any;Job_Start_Date_Time__c:any, Job_End_Date_Time__c:any, Vehicles_on_Site__c:any,productCount__c:any,Total_Price__c:any,Job_Reference__c:any, Site_Contact__c:any,Type_of_Waste__c:any,InvoiceNo:any}) => {
              let bgcolorsel = '';
          //    const obj = { "jobtype": u.Job_Type__c};
              let obj;
  
              if (piesfChoice ==='maintenance')
              {
               //  obj = { "jobtype": u.Maintenance1__c};
             
               if (u.Maintenance1__c === 'Yes')
               {
                  obj = { "jobtype": "Maintenance"};
               }
               else
               {
                  obj = { "jobtype": "Reactive"};
               }
              }
              else if (piesfChoice ==='jobtype')
              {
                 obj = { "jobtype": u.Job_Type__c};
              }
              else
              {
                   obj = { "jobtype": u.Status__c};
              }
              jobtypesarr.push(obj);
  
              if (u.Status__c === 'Completed')
              {
                bgcolorsel = alpha(theme.palette.primary.main, 0.25) ;
                 bgcolorsel = alpha('#59adf6', 0.25) ; 
              }
     
              
              if (u.Status__c === 'Ready to be Scheduled')
              { 
                bgcolorsel = alpha('#9f8bb0', 0.25) ; 
              }
              if (u.Status__c === 'In Progress')
              { 
                bgcolorsel = alpha('#9bb08b', 0.25) ; 
              }
     
              if (u.Status__c === 'Invoice')
              { 
                bgcolorsel = alpha('#44924d', 0.25) ; 
              }
       
             return {
              "id": u.Id,
              "title":u.Job_Type__c,
              "start":u.Job_End_Date_Time__c,
              "end":u.Job_End_Date_Time__c,
              "status":u.Status__c,
              "bgcolor":bgcolorsel,
              "idreadable":u.Job_Reference__c,
               "sfjobid": u.Id,
               "sfaccountid":u.Account__c,
               "sfaccountname":u.Account_Name__c,
               "sfjobname": u.Name,
               "sfstatus":u.Status__c,
               "sfjobtype":u.Job_Type__c,
               "sfscheduledate":u.Schedule_Date__c,
               "sfdurationhours":u.Duration_Hours_Days__c,
               "sfdurationofjob":u.Duration_of_Job__c,
               "sfmaintenance":u.Maintenance1__c,
               "sfjobstart":u.Job_Start_Date_Time__c,
               "sfjobend":u.Job_End_Date_Time__c,
               "sfvehiclesonsite":u.Vehicles_on_Site__c,
               "sfproductcount":u.productCount__c,
               "sftotalprice":u.Total_Price__c,
               "sfjobreference":u.Job_Reference__c,
               "sfcontactname":u.Site_Contact__c,
               "sftypeofwaste":u.Type_of_Waste__c,
               "sfinvoicenum":u.InvoiceNo
             };
           });
  
           // set both the 
  
   
          
         let sfdatagrouped = Object.values(jobtypesarr.reduce((c, {jobtype}) => {
            c[jobtype] = c[jobtype] || {name: jobtype,value: 0};
            c[jobtype].value++;
            return c;
          }, {}))
      
  
  
  
          // now use those values in pie
          for (let i = 0; i < sfdatagrouped.length; i++) {
            piechartdataSF.push(sfdatagrouped[i].value);
            labelstoshowSF.push(sfdatagrouped[i].name)
     
          }
  
          const piedataforchartSF = {
            labels: labelstoshowSF,  
            datasets: [
              {
                label: '# by Job Type',
                data: piechartdataSF,
                parsing: {
                  key: 'nested.value'
                },
            
                backgroundColor: sfMaintenancebackgroundColor,
                borderColor: sfMaintenanceborderColor,
                borderWidth: 1,
             
              },
            ],
            };
  
  
     
            setAgainPie(piedataforchartSF);
           setDataSFJobs(sfData);

           if (sfParentAccName)
            {
             setColsSFJobs(columnsMUISFParentTable);
            }
            else
            {
             setColsSFJobs(columnsMUISFTable);
            }
         
        
         });
       } catch (error) {
         console.error(error); /* eslint-disable-line */
       }
    }

  async function getDataPieSetting(siteIdPassed: any) {

    let firstentry = '';
    let expstatuschk = `dashboard/statuschk/${siteIdPassed}`;
    console.log("Line 3352:", expstatuschk)
    try {
      await fetch(`${process.env.API_ROOT}${expstatuschk}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();

        const taskDataRet = dataret.map((u: { numc: number; compliant_display: string; }) => {
          return {
            "numc": u.numc,
            "compliant_display": u.compliant_display,

          };

        });
        taskDataHere = taskDataRet;
        console.log("taskData:", taskDataHere)
        // set the initial compliance value to show
        firstentry = taskDataHere[0].compliant_display;
        console.log("firstentry:", firstentry)

        if (firstentry !== 'No') {

          if (firstentry === 'Yes') {
            setCompliancyChoice('compliant');

          }
          else if (firstentry === 'Undetermined')// Undetermined
          {
            console.log("In Undetermined at line 3617")
            setCompliancyChoice('undetermined');


          }


        }


        setPieOptionsData(taskDataHere);
        // Need to make the bar charts dynamic

      })
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }

  

  async function getDataInitial() {
    setIsLoading(true);

    if (siteId) {

      contextStore.setSiteId(siteId);


      // Move SF Data to here
      let hasSFLink = 0;

      // 2022-11-24 Fetch if there is a salesforce link
      console.log("Client ID:", clientId)
      let sfurltogoto = 'sf/sfcheck/';

      try {
        await fetch(`${process.env.API_ROOT}${sfurltogoto}?client=${clientId}&site=${siteId}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();

          if (dataret.length > 0)
          {
              //setsfParentAcc
            if (dataret[0].accountparent === true)
            {
                  console.log("A Parent Account")
                  setsfParentAccName(dataret[0].parentname)
            }
    
            console.log("SALESFORCE ID IS = ",dataret[0].salesforceid )
            hasSFLink = 1;
            setsfOnly(dataret[0].sfonly);
            setSfCustomerID(dataret[0].salesforceid);
            setActivePanel('salesforce');

          

          }

        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }

      console.log("LINE 3448 and Compliancy Choice IS:", compliancyChoice)
      let urltogoto = 'dashboard/ncpie/';
      let firstentry = '';
      let expstatuschk = `dashboard/statuschk/${siteId}`;

      let selectedBackground = noncompliantbackground;
      let selectedBorderColor = noncompliantborder;

      console.log("Line 3352:", expstatuschk)
      try {
        await fetch(`${process.env.API_ROOT}${expstatuschk}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();

          const taskDataRet = dataret.map((u: { numc: number; compliant_display: string; }) => {
            return {
              "numc": u.numc,
              "compliant_display": u.compliant_display,

            };

          });
          const taskData = taskDataRet;
          console.log("taskData:", taskDataHere)
          // set the initial compliance value to show
          firstentry = taskData[0].compliant_display;
          console.log("firstentry:", firstentry)

          if (firstentry !== 'No') {
            console.log("At 3582!!!")
            if (firstentry === 'Yes') {
              urltogoto = 'dashboard/pie/';
              selectedBackground = compliantbackgroundColor;
              selectedBorderColor = compliantborderColor;
              setCompliancyChoice('compliant');

            }
            else if (firstentry === 'Undetermined')// Undetermined
            {
              console.log("In Undetermined at line 3617")
              selectedBackground = undeterbackgroundColor;
              selectedBorderColor = undeterborderColor;

              urltogoto = 'dashboard/und/';
              setCompliancyChoice('undetermined');
            }
          }

          console.log("Line 3606, about to setPieOptionsData with ", taskData)
          setPieOptionsData(taskData);
          // Need to make the bar charts dynamic

        })
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }


      console.log("FirstEntry:", firstentry)

      /*      if (compliancyChoice === 'noncompliant')
            {
              urltogoto = 'dashboard/ncpie/';
            }
            else if (compliancyChoice === 'compliant')
            {
              urltogoto = 'dashboard/pie/'; 
            }
            else if (compliancyChoice === 'undetermined')
            {
              urltogoto = 'dashboard/und/'; 
            } */

      if (hasSFLink === 0) {

        try {
          await fetch(`${process.env.API_ROOT}${urltogoto}${siteId}`, {
            method: 'GET',
            credentials: 'include',
          }).then(async (response) => {
            const dataret = await response.json();
            for (let i = 0; i < dataret.length; i++) {
              piechartdata.push(dataret[i].count);
              labelstoshow.push(dataret[i].assettype)

            }


            const labels: any[] = [];
            const data: any[] = [];


            setGraph({
              labels: labelstoshow,
              data: piechartdata,
            });

            const piedataforchart = {
              labels: labelstoshow,
              datasets: [
                {
                  label: '# of Assets',
                  data: piechartdata,
                  parsing: {
                    key: 'nested.value'
                  },

                  backgroundColor: selectedBackground,
                  borderColor: selectedBorderColor,
                  borderWidth: 1,

                },
              ],
            };
            setAgainPie(piedataforchart);


          });
        } catch (error) {
          console.error(error); /* eslint-disable-line */
        }
      }


      // Now Set Sys Bar
      let barchartdataSys: any[] = [];
      let barchartdataSysFalse: any[] = [];
      let barchartdataSysUndeter: any[] = [];
      let barlabelstoshow: any[] = [];
      let barchartdataSysExpiring: any[] = [];
      let barchartdataSysExpired: any[] = [];
      // NEED TO GET EACH BUT WILL PLACE IN ONE

      // Compliant
      let urltogotoBarComp = 'dashboard/systypebartrue?siteid=' + siteId + '&type=' + systemChoice;
      try {
        await fetch(`${process.env.API_ROOT}${urltogotoBarComp}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();
          barchartdataSys = [];
          barlabelstoshow = []
          for (let i = 0; i < dataret.length; i++) {
            barchartdataSys.push(dataret[i].numass);
            barlabelstoshow.push(dataret[i].sysname)

          }

        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }

      // NonCompliant
      let urltogotoBarNonComp = 'dashboard/systypebarfalse?siteid=' + siteId + '&type=' + systemChoice;;
      try {
        await fetch(`${process.env.API_ROOT}${urltogotoBarNonComp}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();

          barchartdataSysFalse = [];

          for (let i = 0; i < dataret.length; i++) {
            barchartdataSysFalse.push(dataret[i].numass);
          }

        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }

      // Expiring
      let urltogotoBarExpiring = 'dashboard/systypebarexpiring?siteid=' + siteId + '&type=' + systemChoice;
      try {
        await fetch(`${process.env.API_ROOT}${urltogotoBarExpiring}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();
          barchartdataSysExpiring = [];

          for (let i = 0; i < dataret.length; i++) {
            barchartdataSysExpiring.push(dataret[i].numass);
          }

        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }



      // Expired
      let urltogotoBarExpired = 'dashboard/systypebarexpired?siteid=' + siteId + '&type=' + systemChoice;
      try {
        await fetch(`${process.env.API_ROOT}${urltogotoBarExpired}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();
          barchartdataSysExpired = [];

          for (let i = 0; i < dataret.length; i++) {
            barchartdataSysExpired.push(dataret[i].numass);
          }

        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }

      // Undeter
      let urltogotoBarUndeter = 'dashboard/systypebarnull?siteid=' + siteId + '&type=' + systemChoice;
      try {
        await fetch(`${process.env.API_ROOT}${urltogotoBarUndeter}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();

          barchartdataSysUndeter = [];

          for (let i = 0; i < dataret.length; i++) {
            barchartdataSysUndeter.push(dataret[i].numass);
          }

          let bardatasets: { label: string; data: any[]; backgroundColor: string[]; borderColor: string[]; borderWidth: number; borderRadius: number; hoverBorderColor: string[]; hoverBorderWidth: number; }[] = [];


          let expstatuschk = `dashboard/statuschk/${siteId}`;
          try {
            await fetch(`${process.env.API_ROOT}${expstatuschk}`, {
              method: 'GET',
              credentials: 'include',
            }).then(async (response) => {
              const dataret = await response.json();

              const taskData = dataret.map((u: { numc: number; compliant_display: string; }) => {
                return {
                  "numc": u.numc,
                  "compliant_display": u.compliant_display,

                };

              });
              console.log("taskData:", taskData)
              setPieOptionsData(taskData);
              // Need to make the bar charts dynamic
              taskData.map((item) => {



                if (item.compliant_display === 'No') {
                  let onetopush = {
                    label: 'Non-Compliant',
                    data: barchartdataSysFalse,
                    backgroundColor: [
                      'rgba(220, 28, 19, 1)',

                    ],
                    borderColor: [
                      'rgba(220, 28, 19, 0.2)',

                    ],
                    borderWidth: 1,
                    borderRadius: 10,
                    hoverBorderColor: ['rgba(220, 28, 19, 1)',
                    ],
                    hoverBorderWidth: 1
                  }
                  bardatasets.push(onetopush);

                }

                if (item.compliant_display === 'Yes') {
                  let onetopush = {
                    label: 'Compliant',
                    data: barchartdataSys,
                    backgroundColor: [
                      'rgba(68, 146, 77, 1)',

                    ],
                    borderColor: [
                      'rgba(68, 146, 77, 0.2)',

                    ],
                    borderWidth: 1,
                    borderRadius: 10,
                    hoverBorderColor: ['rgba(68, 146, 77, 1)',
                    ],
                    hoverBorderWidth: 1
                  }
                  bardatasets.push(onetopush);

                }

                if (item.compliant_display === 'Undetermined') {
                  let onetopush = {
                    label: 'Undetermined',
                    data: barchartdataSysUndeter,
                    backgroundColor: [
                      'rgba(56, 50, 120, 1)',

                    ],
                    borderColor: [
                      'rgba(56, 50, 120, 0.2)',

                    ],
                    borderWidth: 1,
                    borderRadius: 10,
                    hoverBorderColor: ['rgba(56, 50, 120, 1)',
                    ],
                    hoverBorderWidth: 1
                  }
                  bardatasets.push(onetopush);

                }
                if (item.compliant_display === 'Expiring') {
                  let onetopush = {
                    label: 'Expiring',
                    data: barchartdataSysExpiring,
                    backgroundColor: [
                      'rgba(242, 146, 77, 1)',

                    ],
                    borderColor: [
                      'rgba(242, 146, 77, 0.2)',

                    ],
                    borderWidth: 1,
                    borderRadius: 10,
                    hoverBorderColor: ['rgba(242, 146, 77, 1)',
                    ],
                    hoverBorderWidth: 1
                  }
                  bardatasets.push(onetopush);
                }

                if (item.compliant_display === 'Expired') {
                  let onetopush = {
                    label: 'Expired',
                    data: barchartdataSysExpired,
                    backgroundColor: [
                      'rgba(194, 70, 102, 1)',

                    ],
                    borderColor: [
                      'rgba(194, 70, 102, 0.2)',

                    ],
                    borderWidth: 1,
                    borderRadius: 10,
                    hoverBorderColor: ['rgba(194, 70, 102, 1)',
                    ],
                    hoverBorderWidth: 1
                  }
                  bardatasets.push(onetopush);
                }


              });
            })
          } catch (error) {
            console.error(error); /* eslint-disable-line */
          }

          // NOW SET THE BAR DETAILS
          const bardataforchart = {
            labels: barlabelstoshow,
            datasets: bardatasets,
          };

          setAgainBar(bardataforchart);

        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }


      // Assets
      try {
        urltogoto = 'dashboard/assets/'
        await fetch(`${process.env.API_ROOT}${urltogoto}${siteId}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();
          let hyperlink = '';
          const userData = dataret.map((u: { clientid: string; siteid: string; taskid: string; id: string; name: any; type: any; system: any; systemtype: any; compliant: any; compliant_display: any; }) => {
            if (u.taskid === null) {
              hyperlink = '';
            }
            else {
              hyperlink = '/client/' + u.clientid + '/tasks/' + u.siteid + '/' + u.taskid + '/report?asset=' + u.id;

            }


            if (u.taskid !== '') {
            }
            else {

            }

            return {
              "id": u.id,
              "Name": u.name,
              "Asset Type": u.type,
              "System": u.system,
              "System Type": u.systemtype,
              "Compliant": u.compliant,
              "Compliant_Display": u.compliant_display,
              "taskid": u.taskid,
              "task_rep": hyperlink
            };
          });

          setDataAssets(userData);
          setCols(columnsMUITable);
        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }


      // Fetch Tasks But This Will be Moved Out to a separate part
      try {
        urltogoto = 'dashboard/taskstable?siteid='
        await fetch(`${process.env.API_ROOT}${urltogoto}${siteId}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();
          const taskData = dataret.map((u: { id: string; idreadable: string; tasktitle: string; status: string; internal_responsible: string; assetscount: any; display_value: any; deadline: any; orig_status: any; }) => {

            return {
              "taskid": u.idreadable,
              "Task Name": u.tasktitle,
              "Task Type": u.display_value,
              "Deadline": u.deadline,
              "Responsible": u.internal_responsible,
              "Status": u.status,
              "orig_status": u.orig_status,
            };
          });

          setDataTasks(taskData);
        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }



      setIsLoading(false);
      setInitalLoadDone(true);
      //setCols(columnsMUITable)
      //await showPieOptions();


    }
  }
  useEffect(() => {
    console.log("pieOptionsData", pieOptionsData)

  }, [pieOptionsData]);



  useEffect(() => {

    if (sfCustomerID) {
      // use this month/year to begin, calendar will control the changes
      const todaysDate = new Date();
      const thisMonth = todaysDate.getMonth() + 1;
      const thisYear = todaysDate.getFullYear();


      getSalesForceJobs(thisMonth, thisYear);
    }


  }, [sfCustomerID]);



  // Get the data for the PIES, BARS AND ASSETS - WILL BE MOVED TO THEIR OWN COMPONETS
  useEffect(() => {


    if (contextStore.taskvFilterValue === true) {
      setCompliancyChoice(contextStore.compFilterValue);
      setSystemChoice(contextStore.pieFilterValue);
      getBarData(contextStore.pieFilterValue);
      if (contextStore.pieFilterValue !== 'all') {
        onFilter(contextStore.pieFilterValue, 1)
      }
      if (contextStore.barFilterValue !== 'all') {
        onFilter(contextStore.barFilterValue, 2)
      }
      if (contextStore.barncFilterValue !== 'all') {
        onFilter(contextStore.barncFilterValue, 4)
      }

      contextStore.setDashboardPicks('noncompliant', 'all', 'all', 'all', false)
    }

    // Set pie selection based on data available first before getting initial data


    getDataPieSetting(siteId);
    console.log("Data being fetched")
    // Update 2024-02-19 : Refresh the Salesforce Data on Site Change
    getDataInitial();

    getcalendardataformont();
    // console.log("siteId is ", siteId)

    localStorage.setItem('siteIDval', String(siteId));
    setCurSiteId(siteId);

  }, [siteId]);


  function createlink(passedvalue: any) {
    let assetlink = '';
    if (siteId) {
      assetlink = `/client/${clientId}/assets/${siteId}/?search=${passedvalue}`
    }
    else {
      assetlink = `/client/${clientId}/assets/${siteId}/?search=${passedvalue}`
    }


    // console.log("assetlink = ", assetlink)
    return (
      <Link to={assetlink} className={styles.assetTypeStatisticLink}>
        {passedvalue}
      </Link>
    );

  }
  async function getcalendardataformont(rangesent?: any) {
    try {

      // console.log("Am in getallcalendardata")

      // update the task table as well

      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;

      let curmodule;
      if (preselectedmodules.length === 2) {
        curmodule = 'all'
      }
      else if (preselectedmodules.length === 1) {
        curmodule = preselectedmodules[0];
      }
      else {
        curmodule = 'all'
      }
      console.log("curmodule:", curmodule)

      if (rangesent) {
        let monthDisplay = '';
        year = new Date(rangesent.start).getFullYear();
        const daypassed = new Date(rangesent.start).getDate()
        if (daypassed > 1) {
          month = new Date(rangesent.start).getMonth() + 2;
          const datefortasktitle = new Date(year, month - 1, 1);
          monthDisplay = datefortasktitle.toLocaleString('default', { month: 'long' });

        }
        else {
          month = new Date(rangesent.start).getMonth() + 1;
          const datefortasktitle = new Date(year, month - 1, 1);
          monthDisplay = datefortasktitle.toLocaleString('default', { month: 'long' });

        }

        setTasksTableTitle(`Tasks for ${monthDisplay}`)
      }

      const urltogoto = `${process.env.API_ROOT}dashboard/calendar?siteid=${siteId}&year=${year}&month=${month}&module=${curmodule}`
      // console.log("LINE 2446 urltogoto", urltogoto);
      const response = await fetch(urltogoto, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();


        const events = dataret.map((event: { id: any; title: any; start: any; end: any; idreadable: any; bgcolor: any; status: any; }) => {

          return {
            id: event.id,
            title: event.title,
            start: event.start,
            end: event.end,
            idreadable: event.idreadable,
            bgcolor: event.bgcolor,
            status: event.status,
          };
        })


        setSelectedMonth(month)
        setSelectedYear(year)
        // console.log(events);
        setMonthsTasks(events);


      })

      // Also update the tasks table if rangesent
      if (rangesent) {

        fetchDataTaskTable(month, year);


      }




    } catch (error) {
      // console.log(error);
    }

  }

  async function getcalendardataformontwithmod(rangesent?: any) {

    console.log("getcalendardataformontwithmod:", getcalendardataformontwithmod)
    try {

      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;

      let curmodule;
      if (preselectedmodules.length === 2) {
        curmodule = 'all'
      }
      else if (preselectedmodules.length === 1) {
        curmodule = preselectedmodules[0];
      }
      else {
        curmodule = 'all'
      }
      console.log("curmodule:", curmodule)


      const urltogoto = `${process.env.API_ROOT}dashboard/calendar?siteid=${siteId}&year=${selectedYear}&month=${selectedMonth}&module=${curmodule}`
      // console.log("LINE 2446 urltogoto", urltogoto);
      const response = await fetch(urltogoto, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();


        const events = dataret.map((event: { id: any; title: any; start: any; end: any; idreadable: any; bgcolor: any; status: any; }) => {

          return {
            id: event.id,
            title: event.title,
            start: event.start,
            end: event.end,
            idreadable: event.idreadable,
            bgcolor: event.bgcolor,
            status: event.status,
          };
        })


        setMonthsTasks(events);


      })
    } catch (error) {
      // console.log(error);
    }

  }

  if (isLoading) {
    return (
      <div>
        <Breadcrumb to={pathname} name="Dashboard" />
        <Typography variant="h3">Fetching Results....</Typography>
      </div>
    );
  }


  return (
    <DefaultErrorBoundary>

      <div className={styles.container}>
        {renderBreadcrumb(t('common')('dashboard'))}
      </div>

      {!isLoading && againPie && pieOptionsData ? (
        <>

          <div className={styles.container}>
            <div>

            </div>
            {showSalesforceCalendar ? (
              <div className={styles.container}>
                <div style={{ marginLeft: "5%" }}>

                  <Calendar
                    selectable
                    showAllEvents
                    localizer={localizer}
                    events={dataSFJobs}
                    onRangeChange={onRangeChangeSF}
                    eventPropGetter={eventStyleGetter}
                    onNavigate={onNavigateSF}
                    defaultView='month'
                    views={['month']}
                    selected={selected}
                    onDrillDown={handleDrillDownSF}
                    onSelectEvent={handleSelectedSF}
                    onSelectSlot={handleSlotSelectionSF}
                    onShowMore={(events, date) => console.log(date)}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 420, width: 800 }}
                  />







                </div>
                <div style={{ marginLeft: "10%" }}>
                  <FormGroup>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1%' }}>
                      <RadioButton
                        label="Maintenance "
                        value={piesfChoice === 'maintenance'}
                        onChange={handleSFPieMaintenanceChoice}
                        tooltipmessage="View by Maintenance" />
                      <RadioButton
                        label="Job Type"
                        value={piesfChoice === 'jobtype'}
                        onChange={handleSFPieJobTypeChoice}
                        tooltipmessage="View by Job Type" />
                      <RadioButton
                        label="Maintenance Status  "
                        value={piesfChoice === 'maintstat'}
                        onChange={handleSFPieMaintStatus}
                        tooltipmessage="View by Maintenance Status" />
                      <RadioButton
                        label="Reactive Status   "
                        value={piesfChoice === 'reactstat'}
                        onChange={handleSFPieReactStatus}
                        tooltipmessage="View by Reactive Status" />

                    </div>
                  </FormGroup>
                  <Pie ref={chartRef} data={againPie} options={chartoptionsPie} height={400} width={600} onClick={onClickPie} />





                </div>
              </div>


            ) : (
              <><div>
                {showComplianceOptions ? (
                  <FormGroup>

                    <div>
                      {pieOptionsData.map((item, index) => (
                        <span key={index}>
                          {item.compliant_display == 'No' ?
                            (
                              <RadioButton
                                label="Non Compliant             "
                                value={compliancyChoice === 'noncompliant'}
                                onChange={handleNonCompliantChoice}
                                tooltipmessage="Assets that are Non Compliant" />
                            ) : (
                              null

                            )}
                          {item.compliant_display == 'Yes' ?
                            (
                              <RadioButton
                                label="Compliant"
                                value={compliancyChoice === 'compliant'}
                                onChange={handleCompliantChoice}
                                tooltipmessage="Assets that are Compliant" />
                            ) : (
                              null

                            )}


                          {item.compliant_display == 'Undetermined' ?
                            (
                              <RadioButton
                                label="Undetermined"
                                value={compliancyChoice === 'undetermined'}
                                onChange={handleUndeterminedChoice}
                                tooltipmessage="No recorded compliance data" />
                            ) : (
                              null

                            )}

                          {item.compliant_display == 'Expiring' ?
                            (
                              <RadioButton
                                label="Expiring"
                                value={compliancyChoice === 'expiring'}
                                onChange={handleExpiringChoice}
                                tooltipmessage="Compliances due to expire" />
                            ) : (
                              null

                            )}

                          {item.compliant_display == 'Expired' ?
                            (
                              <RadioButton
                                label="Expired"
                                value={compliancyChoice === 'expired'}
                                onChange={handleExpiredChoice}
                                tooltipmessage="Expired Compliances" />
                            ) : (
                              null

                            )}


                        </span>
                      ))}

                    </div>


                  </FormGroup>
                ) : null}

                {showTaskOptions ? (
                  <FormGroup>
                    <div>
                      <RadioButton
                        label="Task Type "
                        value={taskOptionChoice === 'type'}
                        onChange={handleTaskOptionChoiceType}
                        tooltipmessage="Tasks by Task Type" />
                      <RadioButton
                        label="Status"
                        value={taskOptionChoice === 'status'}
                        onChange={handleTaskOptionChoiceStatus}
                        tooltipmessage="Tasks By Status" />
                      <RadioButton
                        label="System Reference"
                        value={taskOptionChoice === 'sysref'}
                        onChange={handleTaskOptionChoiceSysRef}
                        tooltipmessage="Tasks by System Reference" />
                    </div>
                  </FormGroup>
                ) : null}

                <Pie ref={chartRef} data={againPie} options={chartoptionsPie} height={400} width={600} onClick={onClickPie} />

              </div><div className={styles.barChartBox}>

                  {(() => {
                    if (systemChoice === 'all') {
                      return (
                        ''
                      );

                    } else {
                      let labeltodisp = '';
                      if (showComplianceOptions) {
                        labeltodisp = systemChoice + ' by System   ';
                      }

                      else {
                        labeltodisp = systemChoice + ' Tasks by Asset Type   ';
                      }


                      return (
                        <>
                          {labeltodisp}
                          <Tooltip disableFocusListener title="Reset to all Types">
                            <button onClick={showAll}><Autorenew style={{ fontSize: 20, color: "#69CC84", verticalAlign: -5, }} /></button>
                          </Tooltip>
                        </>

                      );
                    }
                  })()}



                  <Bar ref={chartBarRef} options={chartoptionsBar} data={againBar} height={400} width={900} onClick={onClickBar} />

                </div></>)}
          </div>

          <div style={{ marginTop: '2%', padding: '1%', height: '100%', width: '100%', backgroundColor: 'white', position: 'relative' }}>
            {(() => {
              //   console.log("sfCustomerID is ", sfCustomerID)
              if (sfCustomerID && sfOnly) {
                return (
                  <>
                    <AppTabs
                      fullWidth
                      tabs={[
                        {
                          name: "Environmental Services",
                          onActive: () => setActivePanel('salesforce'),
                          active: activePanel === 'salesforce',
                        }
                      ]}
                    />
                  </>
                )
              }
              if (sfCustomerID && !sfOnly) {
                return (
                  <>
                    <AppTabs
                      fullWidth
                      tabs={[
                        {
                          name: "Environmental Services",
                          onActive: () => setActivePanel('salesforce'),
                          active: activePanel === 'salesforce',
                        },
                        {
                          name: "Assets",
                          onActive: () => { setActivePanel('assets') },
                          active: activePanel === 'assets',
                        },
                        {
                          name: "Tasks",
                          onActive: () => setActivePanel('tasks'),
                          active: activePanel === 'tasks',
                        },
                      ]}
                    />
                  </>

                )
              }
              else {
                return (
                  <>
                    <AppTabs
                      fullWidth
                      tabs={[
                        {
                          name: "Assets",
                          onActive: () => { setActivePanel('assets') },
                          active: activePanel === 'assets',
                        },
                        {
                          name: "Tasks",
                          onActive: () => setActivePanel('tasks'),
                          active: activePanel === 'tasks',
                        },


                      ]}
                    />
                  </>

                )

              }
            })()}

            {switcher(activePanel, [

              ['tasks',
                <div className={styles.containerTasks}>
                  <div className={styles.taskItem}>
                    <Calendar
                      selectable
                      showAllEvents
                      localizer={localizer}
                      events={monthsTasks}
                      onRangeChange={onRangeChange}
                      eventPropGetter={eventStyleGetter}
                      onNavigate={onNavigate}
                      defaultView='month'
                      views={['month']}
                      selected={selected}
                      onDrillDown={handleDrillDown}
                      onSelectEvent={handleSelected}
                      onSelectSlot={handleSlotSelection}
                      onShowMore={(events, date) => console.log(date)}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 500 }}
                    />
                  </div>
                  <div className={styles.taskList}>
                    <MUIDataTable title={tasksTableTitle} data={dataTasks} columns={columnsMUITasksTable} options={tableoptionstasks} />
                  </div>
                </div>
              ],
              ['assets', <MUIDataTable title={"Assets"} data={dataAssets} columns={cols} options={tableoptions} />],
              ['salesforce', <MUIDataTable title={"Environmental Services Jobs"} data={dataSFJobs} columns={colsSFJobs} options={tableoptionssf} />]
            ])}
          </div>

        </>


      ) : (
        <div>


        </div>
      )}

    </DefaultErrorBoundary>
  );



};

export default observer(DashboardPage);
