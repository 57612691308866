import { alpha, useTheme } from '@mui/material/styles';
import { TaskStatus } from 'models/graphql';
import React from 'react';
import useText from 'texts/useText.hook';
import StatusBadge, { StatusBadgeState } from './StatusBadge';

const TaskStatusBadge: React.FC<{ status: TaskStatus }> = ({ status }) => {
  const { t } = useText('taskStatuses');
  const theme = useTheme();

  let state: StatusBadgeState;
  if (status === TaskStatus.SENT || status === TaskStatus.REVIEW_REQ_SENT) {
    state = { background: alpha(theme.colors.yellow, 0.25) };
  } else if (
    status === TaskStatus.CONTRACTOR_ACCEPTED ||
    status === TaskStatus.CONTRACTOR_REJECTED ||
    status === TaskStatus.IN_PROGRESS ||
    status === TaskStatus.COMPLETED ||
    status === TaskStatus.SUPP_INFO_SUBMITTED
  ) {
    state = { background: alpha(theme.colors.orange, 0.25) };
  } else if (
    status === TaskStatus.REVIEW_REQ_REJECTED ||
    status === TaskStatus.REVIEW_REQ_ACCEPTED ||
    status === TaskStatus.CONSULTANT_REJECTED ||
    status === TaskStatus.CONSULTANT_APPROVED
  ) {
    state = { background: alpha(theme.colors.blue, 0.25) };
  } else if (status === TaskStatus.REJECTED_CLOSED || status === TaskStatus.REJECTED) {
    state = { background: alpha(theme.palette.error.main, 0.25) };
  } else if (status === TaskStatus.APPROVED_CLOSED) {
    state = { background: alpha(theme.palette.primary.main, 0.25) };
  } else if (status === TaskStatus.CANCELLED) {
    state = { background: alpha('#000000', 0.12) };
  } else {
    state = { background: 'transparent' };
  }

  return (
    <StatusBadge block state={state}>
      {t('taskStatuses')(status)}
    </StatusBadge>
  );
};

export default TaskStatusBadge;
