import React from 'react';
import { useApolloClient } from '@apollo/client';
import downloadExcelTemplateIcon from 'assets/downloadExcelTemplate.svg';
import AppIconButton from 'components/AppIconButton';
import { IconButtonProps } from '@mui/material/IconButton';
import { format } from 'date-fns';
import { excelUtils } from 'utils/excel';
import AppDialog from 'components/AppDialog';
import { getCreateAssetData as GetCreateAssetData, getCreateAssetDataVariables } from 'models/graphql';
import useText from 'texts/useText.hook';
import { GET_CREATE_ASSET_DATA } from '../graphql';

interface IClientAssetsExport {
  clientId: string;
  iconButton?: string;
}

const ClientAssetsExport: React.FC<IconButtonProps & IClientAssetsExport> = ({
  clientId,
  iconButton,
  ...forwardProps
}) => {
  const apolloClient = useApolloClient();
  const { t } = useText('clients', 'systemTypes', 'assetTypes');
  const [error, setError] = React.useState<string | null>(null);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

  const exportAssets = async () => {
    const { data } = await apolloClient.query<GetCreateAssetData, getCreateAssetDataVariables>({
      query: GET_CREATE_ASSET_DATA,
      variables: { id: clientId },
    });

    if (!data?.client) {
      return;
    }
    const { client } = data;

    // Validation
    if (client.sites.length === 0) {
      setError(t('clients')('noSitesForClient'));
      setErrorDialogOpen(true);
      return;
    }
    if (client.users.length === 0) {
      setError(t('clients')('noUsersForClient'));
      setErrorDialogOpen(true);
      return;
    }
    if (!client.allowedAssets || client.allowedAssets.length === 0) {
      setError(t('clients')('noAssetsForClient'));
      setErrorDialogOpen(true);
      return;
    }

    // TODO: make Site names and User names unique
    // TODO: ensure Asset Type's language specific texts unique
    const assetTypes = client.allowedAssets.map((type) => t('assetTypes')(type));

    const users = client.users.map((user) => user.name);

    const drawingIds = client.sites
      .reduce<GetCreateAssetData['client']['sites'][number]['drawings']>((drawings, site) => {
        drawings.push(...site.drawings);
        return drawings;
      }, [])
      .map((drawing) => drawing.drawingId);

    let maxDrawingsSite: GetCreateAssetData['client']['sites'][number] = client.sites[0];
    client.sites.forEach((site) => {
      if (site.drawings.length > maxDrawingsSite.drawings.length) {
        maxDrawingsSite = site;
      }
    });

    await excelUtils.downloadBook(
      excelUtils.createBook(
        'assets',
        [
          {
            header: t('clients')('site'),
            values: [maxDrawingsSite.name],
            validation: excelUtils.validations.ofArray(
              client.sites.map((site) => site.name),
              `Must be one of the Client's registered Sites `,
            ),
          },
          { header: t('clients')('department'), values: ['Maintenance'] },
          {
            header: t('clients')('systemName'),
            values: [client.systems[0].name],
            validation: excelUtils.validations.ofArray(
              client.systems.map((system) => system.name),
              `Must be one of the valid System})`,
            ), 
          },
          {
            header: t('clients')('assetName'),
            values: ['Monitoring point1'],
            validation: excelUtils.validations.requiredText('Asset Name is a required field'),
          },
          {
            header: t('clients')('assetType'),
            values: [t('assetTypes')(client.allowedAssets[0])],
            validation: excelUtils.validations.ofArray(
              assetTypes,
              `Must be one of the allowed Asset Types for Client })`,
            ), 
          },
          {
            header: t('clients')('assetOwner'),
            values: [client.users[0].name],
            validation: excelUtils.validations.ofArray(
              users,
              `Must be one of the Client's registered Users})`,
            ), 
          },
          {
            header: t('clients')('assetCoordinates'),
            values: ['47.482668, 19.066347'],
          },
          {
            header: t('clients')('additionalInformation'),
            values: ['Measurements w23x23cm'],
          },
          
        ],
        { freezeHeader: true },
      ),
      `assets-template-${format(new Date(), 'yyyy-MM-dd_HH_mm')}`,
    );
  };

  return (
    <>
      <AppIconButton {...forwardProps} onClick={exportAssets}>
        <img alt="download template" src={iconButton || downloadExcelTemplateIcon} />
      </AppIconButton>

      <AppDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        type="error"
        title={t('clients')('exportAssetsErrorTitle')}
      >
        {error}
      </AppDialog>
    </>
  );
};

export default ClientAssetsExport;
