import { Box, MenuItem } from '@mui/material';
import AppMenu from 'components/AppMenu';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

const BreadcrumbSelector: React.FC<{
  loading?: boolean;
  options: Array<{
    id: string;
    to: string;
    name: string;
    selected?: boolean;
  }>;
}> = ({ children, options, loading = false }) => {
  const containerRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return <>
    <button type="button" ref={containerRef} onClick={() => setOpen(!open)} disabled={loading}>
      {children}
    </button>
    <AppMenu
      anchorEl={containerRef.current}
      open={open && options.length > 0}
      horizontalPosition="left"
      onClose={() => {
        setOpen(false);
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.id}
          onClick={() => {
            navigate(option.to);
            setOpen(false);
          }}
        >
          <Box sx={{
            whiteSpace: "normal"
          }}>{option.selected ? <strong>{option.name}</strong> : <>{option.name}</>}</Box>
        </MenuItem>
      ))}
    </AppMenu>
  </>;
};

export default BreadcrumbSelector;
