import { AssetType, SystemType } from 'models/graphql';
import assetAssociatedSystems from './assetAssociatedSystems';

interface ValidationErrors {
  systemIsNotAssociatedForAssetType?: boolean;
  systemIsNotFromTheAssetSite?: boolean;
  drawingsAreNotFromTheAssetSite?: string[];
}
export default function assetValidation(asset: {
  siteId: string;
  type: AssetType;
  system: { id: string; type: SystemType; site: { id: string } };
  drawings: Array<{ id: string; site: { id: string } }>;
}): ValidationErrors {
  const errors: ValidationErrors = {};

  errors.systemIsNotAssociatedForAssetType = !assetAssociatedSystems[asset.type].includes(asset.system.type);

  if (asset.system.site.id !== asset.siteId) {
    errors.systemIsNotFromTheAssetSite = true;
  }

  asset.drawings.forEach((drawing) => {
    if (drawing.site.id !== asset.siteId) {
      if (!errors.drawingsAreNotFromTheAssetSite) {
        errors.drawingsAreNotFromTheAssetSite = [];
      }

      errors.drawingsAreNotFromTheAssetSite.push(drawing.id);
    }
  });

  return errors;
}
