import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import AssetsPage from 'containers/Contractor/Tasks/AssetPage';
import HideNavigation from 'components/HideNavigation';
import cn from 'utils/cn';
import cancelSimpleIcon from 'assets/cancelSimpleIcon.svg';
import useCancel from 'utils/useCancel.hook';
import useMode, { Mode } from 'utils/useMode.hook';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    maxWidth: DESKTOP_BREAKPOINT,
    margin: 'auto',
    '& > *': {
      padding: '1.5em',
      '&.buttonWrapper': {
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    },
  },
  cancelButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    padding: theme.spacing(3),
    '& img': {
      maxHeight: 'unset',
    },
  },
}));

const TaskAssetInfoPage: React.FC = () => {
  const { main, cancelButtonContainer, cancelButton } = useStyles();
  const { taskId } = useParams<{ taskId: string }>();
  const { mode } = useMode();
  const cancel = useCancel(`/${mode === Mode.OPERATIVE ? 'operative' : 'technician'}/works/${taskId}/assets`);
  return (
    <div className={main}>
      <HideNavigation />
      <div className={cn('buttonWrapper')}>
        <div className={cancelButtonContainer}>
          <button onClick={cancel} type="button" className={cancelButton}>
            <img src={cancelSimpleIcon} alt="Cancel" />
          </button>
        </div>
      </div>
      <AssetsPage />
    </div>
  );
};

export default TaskAssetInfoPage;
