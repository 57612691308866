import { useFormikContext } from 'formik';
import React, { PropsWithChildren } from 'react';
import styleUtils from 'style/styleUtils';
import cn from 'utils/cn';
import objectPropertyByString from 'utils/objectPropertyByString';
import { AppFieldError } from '.';
import useFieldStyles from './AppField.style';
import { FormControlLabel, Theme, alpha } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  const borderWidth = 1;
  return createStyles({
    container: {
      display: 'flex',
    },
    button: {
      width: theme.sizes.button.minWidth,
      height: theme.sizes.button.height,
      padding: `${theme.spacing(1)} 0`,
      ...styleUtils.rowCenterCenter,
      borderRadius: 0,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.fontSizes[12],
      transition: theme.transitions.create(['background', 'color'], {
        duration: theme.transitions.duration.short,
      }),
      border: `solid ${borderWidth}px ${theme.palette.grey[400]}`,
      borderRightWidth: 0,

      '&:first-child': {
        borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
      },
      '&:last-child': {
        borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
        borderRightWidth: `${borderWidth}px`,
      },

      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },

      // hover
      '&:hover:not(.active)': {
        background: alpha(theme.palette.primary.main, 0.1),
      },
    },
  });
});

export default function ButtonSelector<T>({
  name,
  label,
  value,
  options: { array, key, text },
  onChange,
  nullable,
}: PropsWithChildren<{
  name: string;
  label?: string;
  value?: T;
  options: {
    array: Array<T>;
    key: (value: T) => string;
    text: (value: T) => string;
  };
  onChange?: (newValue: T) => void;
  nullable?: boolean;
}>) {
  const styles = useStyles();
  const fieldStyles = useFieldStyles();
  // values coming form FormikContext.
  // setFieldValue writes the changed value back to FormikContext.
  const { values: formValues, setFieldValue, touched, errors } = useFormikContext<Record<string, unknown>>();
  const formValue = objectPropertyByString(formValues, name);

  return (
    <div>
      <FormControlLabel
        labelPlacement="top"
        className={cn(fieldStyles.label, { 'no-label': !label })}
        label={label}
        control={
          <div className={styles.container}>
            {array.map((element) => (
              <button
                key={key(element)}
                type="button"
                className={cn(styles.button, {
                  active: element === value || element === formValue,
                })}
                onClick={() => {
                  if (!nullable || formValue !== element) setFieldValue(name, element);
                  else if (nullable) setFieldValue(name, null);
                  if (onChange) {
                    onChange(element);
                  }
                }}
              >
                {text(element)}
              </button>
            ))}
          </div>
        }
      />

      <AppFieldError
        message={
          // only show error, if the field is touched
          objectPropertyByString(touched, name) ? objectPropertyByString(errors, name) : undefined
        }
      />
    </div>
  );
}
