import { useCallback } from 'react';
import { useLocation } from 'react-router';

export default function useUpdateSearchParams(): (newParams: Record<string, string | number | null>) => string {
  const location = useLocation();
  const { pathname, search, hash } = location;

  return useCallback(
    (newParams) => {
      const urlSearchParams = new URLSearchParams(search);

      Object.entries(newParams).forEach(([key, value]) => {
        if (value !== '' && value !== null) {
          urlSearchParams.set(key, value.toString());
        } else {
          urlSearchParams.delete(key);
        }
      });

      const newSearchParams = urlSearchParams.toString();
      return `${pathname}${newSearchParams ? `?${newSearchParams}` : ''}${hash ? `#${hash}` : ''}`;
    },
    [pathname, search, hash],
  );
}
