import React from 'react';
import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';

import Breadcrumb from 'components/Breadcrumb';
import HideDrawer from 'components/HideNavigation/HideDrawer';
import { taskForUser, taskForUserVariables } from 'models/graphql';
import useText from 'texts/useText.hook';
import DrawingPage from 'containers/shared/DrawingPage';
import TaskReportPage from 'containers/shared/Tasks/TaskReportPage';
import TaskAssetInfoPage from 'containers/shared/Technician/TaskDetails/TaskAssetInfoPage';
import TaskAssetFormPage from 'containers/shared/Technician/TaskDetails/TaskAssetFormPage';
import TaskAttachmentsPage from 'containers/shared/Technician/TaskDetails/TaskAttachmentsPage';
import TaskCommentsPage from 'containers/shared/Technician/TaskDetails/TaskCommentsPage';
import { GET_TASK } from 'containers/shared/Technician/graphql';
import TaskDetailsContainer from 'containers/shared/Technician/TaskDetails/TaskDetailsContainer';
import TaskAssetsPage from 'containers/shared/Technician/TaskDetails/TaskAssetsPage';
import TaskDetailsPage from './TaskDetailsPage';
import { Navigate, Route, Routes, useParams } from 'react-router';

const TaskDetailsRoutes: React.FC = () => {
  const { taskId } = useParams<{
    taskId: string;
  }>();
  const { data: taskData, loading: taskDataLoading, error } = useQuery<taskForUser, taskForUserVariables>(GET_TASK, {
    variables: { id: taskId },
  });
  const task = taskData?.task;

  if (error) {
    // eslint-disable-next-line array-callback-return
    error.graphQLErrors.map(({ message }) => {
      if (message.includes('was deleted')) {
        throw new Error(`Task ${taskId} was deleted.`);
      }
    });
  }

  if (!taskDataLoading && !task) {
    throw new Error(`Task ${taskId} not found.`);
  }

  const { t } = useText('tasks', 'taskTypes');

  return (
    <HideDrawer>
      {taskDataLoading && <LinearProgress />}
      {task && (
        <TaskDetailsContainer baseUrl={`/technician/works/${taskId}`} task={task}>
          <Breadcrumb to="/technician/works" name={t('tasks')('taskInstructions')} />
          <Breadcrumb to={`/technician/works/${taskId}`} name={t('taskTypes')(task.type)} />
          <Routes>
            <Route path={`assets/:assetId/info`} element={<TaskAssetInfoPage />} />
            <Route path={`assets/:taskAssetId/drawings/:drawingId`} element={<DrawingPage />} />
            <Route path={`assets/:taskAssetId/form`} element={<TaskAssetFormPage />} />
            <Route
              path={`assets`}
              element={<TaskAssetsPage taskStatus={task.status} taskType={task.type}/>}
            />
            <Route path={`report`} element={<TaskReportPage />} />
            <Route path={`attachments`} element={<TaskAttachmentsPage />} />
            <Route path={`comments`} element={<TaskCommentsPage />} />
            <Route path={`/`} element={<TaskDetailsPage />} />

            <Route path="*" element={<Navigate to={`/`}  />} />
          </Routes>
        </TaskDetailsContainer>
      )}
    </HideDrawer>
  );
};

export default TaskDetailsRoutes;
