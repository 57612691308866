import React from 'react';
import Yup from 'utils/yup';
import AppDialog from 'components/AppDialog';
import { Formik } from 'formik';
import { AppForm } from 'components/form';
import { Box, CircularProgress } from '@mui/material';
import AppSelectField from 'components/form/AppSelectField';
import AppButton from 'components/AppButton';
import i18next from 'i18next';

interface FormValues {
  client?: string;
}
const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
  client: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('tasks:client')
    })
  ),
});

const UpdateRequestDialog: React.FC<{
  open: boolean;
  title: string;
  description?: string;
  clientOptions?: { id: string; companyName: string }[];
  ifClientOptionsEmpty: React.ReactNode;
  onClose: () => void;
  onSend: (clientId: string) => void | Promise<void>;
}> = ({ open, title, description, clientOptions, ifClientOptionsEmpty, onClose, onSend }) => {
  return (
    <AppDialog open={open} onClose={onClose} title={title}>
      <Formik<FormValues>
        initialValues={{ client: undefined }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (!values.client) return;
          await onSend(values.client);
          onClose();
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <AppForm handleSubmit={handleSubmit}>
              {description && clientOptions?.length !== 0 ? <Box sx={{
                mb: 1
              }}>{description}</Box> : null}
              {clientOptions ? (
                <AppSelectField
                  name="client"
                  placeholder="Select Client"
                  options={{
                    array: clientOptions,
                    value: (client) => client.id,
                    key: (client) => client.id,
                    template: (client) => client.companyName,
                    ifArrayEmpty: <>{ifClientOptionsEmpty}</>,
                  }}
                />
              ) : (
                <CircularProgress color="primary" size={20} />
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 3
                }}>
                <Box sx={{
                  mr: 1
                }}>
                  <AppButton type="button" variant="outlined" onClick={onClose}>
                    Cancel
                  </AppButton>
                </Box>
                {clientOptions?.length !== 0 ? (
                  <AppButton type="submit" inProgress={isSubmitting}>
                    Send
                  </AppButton>
                ) : null}
              </Box>
            </AppForm>
          );
        }}
      </Formik>
    </AppDialog>
  );
};

export default UpdateRequestDialog;
