import React, { useState, useEffect } from 'react';
import { Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Formik, Form, Field } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import { AppTextField, AppDateField } from 'components/form';

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: '500px', // Adjust the value as needed
    maxWidth: '80%',
  },
  table: {
    width: '100%',
  },
  row: {
    '&:not(:first-child)': {
      paddingTop: '5px', // Add spacing between rows
    },
  },
});

function findKey(array, keyToFind) {
  return array.find(item => item.key === keyToFind);
}

const capitalizeFirstLetter = (string) => {
  if (string.length === 0) return string;
  let newStr = string.replace("_", "");
  console.log("newStr:", newStr)
  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};

interface FormValues {
  idreadable: string;
  taskTitle: string;
  taskjectDesc: string;
  responsible: string;
  startDate: string;
  endDate: string;
  status: string;
}

const ViewProjectTask = (props) => {
  const { open, setOpen, content, onClose, title } = props;
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [projeName, setProjeName] = useState('Hello There');
  const [formDetails, setFormDetails] = useState<FormValues[] | []>();
  const [loading, setLoading] = useState(true);
  const [curTaskId, setCurTaskId] = useState(null);
  const [curProjId, setCurProjId] = useState(null);
  const [taskOptions, setTaskOptions] = useState([]);

  setCurProjId

  console.log("Task Props: ", content)

  const handleCheckboxChange = async (e, setFieldValue) => {
    const { checked } = e.target;
    if (checked) {
      try {
        // Make API call
        let urltogoto = `projects/projectaskslink?projid='${curProjId}'&taskid='${curTaskId}'`
        const response = await fetch(`${process.env.API_ROOT}${urltogoto}`, {
          method: 'GET',
          credentials: 'include',
        });

        const data = await response.json();
        setTaskOptions(data); // Assuming response.data is an array of task objects

        console.log('API response:', data);

        // Handle API response if needed
      } catch (error) {
        console.error('API call error:', error);
      }
    }
    setFieldValue('linkToTask', checked);
  };




  useEffect(() => {
    if (content) {
      setCurTaskId(content.id)
      setCurProjId(content.projectid)
      setFormDetails(content)
      setProjeName(content.tasktitle)


    }

  }, [content]); // Update value when content.controlmeasure changes


  useEffect(() => {
    if (formDetails) {

      setLoading(false);

    }

  }, [formDetails]);


  const addLink = async (values: any, setFieldValue: any) => {
    console.log('Selected Task:', values.selectedTask);     //taskid
    console.log('Current Task:', curTaskId)     //otaskid



    // Call Backend to Add Link - Also need to refresh the add link status - do before call
    try {
      // Make API call
      let urltogoto = `projects/projectaskslink?taskid=${values.selectedTask}&otaskid=${curTaskId}`
      const response = await fetch(`${process.env.API_ROOT}${urltogoto}`, {
        method: 'POST',
        credentials: 'include',
      });

      const data = await response.json();
      console.log('API response:', data);
      setFieldValue('linkToTask', false);

      // Handle API response if needed
    } catch (error) {
      console.error('API call error:', error);
    }


  }


  const handleSubmit = (values: FormValues) => {
    onClose();
  };

  return (
    <div>
      {loading ? (
        <label> </label>
      ) : (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="dialog-title"
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            id="customized-dialog-title"
          >
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ width: '80%' }}>{title}</td>
                  <td style={{ width: '20%', textAlign: 'right', marginLeft: '80%' }}>
                    <IconButton onClick={() => setOpen(false)} aria-label="close" size="large">
                      <ClearIcon />
                    </IconButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                projectName: formDetails.tasktitle,
                projectDesc: formDetails.notes,
                responsible: formDetails.internalresponsible,
                startDate: formDetails.taskstart,
                endDate: formDetails.deadline,
                status: capitalizeFirstLetter(formDetails.status),
                linkToTask: false,
                selectedTask: '',
              }}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <table className={classes.table}>
                    <tbody>
                      <tr className={classes.row}>
                        <td>
                          <AppTextField
                            label="Title"
                            name="projectName"
                            type="text"
                            autoFocus
                          />
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td>
                          <AppTextField
                            label="Description"
                            name="projectDesc"
                            type="text"
                          />
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td>
                          <AppTextField
                            label="Responsible"
                            name="responsible"
                            type="text"
                          />
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td>
                          {formDetails.status !== 'project' && (
                            <AppTextField
                              label="Status"
                              name="status"
                            />
                          )}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td>
                          <AppDateField
                            label="Start Date"
                            name="startDate"
                          />
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td>
                          <AppDateField
                            label="End Date"
                            name="endDate"
                          />
                        </td>
                      </tr>
                      {/*   <tr className={classes.row}>
                        <td>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.linkToTask}
                                  onChange={(e) => {
                                    handleCheckboxChange(e, setFieldValue);
                                    handleChange(e);
                                  }}
                                  name="linkToTask"
                                  color="primary"
                                />
                              }
                              label="Link to Task"
                            />
                          </td>
                        </tr>*/}
                      {values.linkToTask && (
                        <tr className={classes.row}>
                          <td>
                            <FormControl fullWidth>
                              <InputLabel>Select Task</InputLabel>
                              <Field
                                name="selectedTask"
                                as={Select}
                                fullWidth
                                displayEmpty
                              >

                                {taskOptions.map((task) => (
                                  <MenuItem key={task.id} value={task.id}>
                                    {task.tasktitle}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)} color="primary">
                      Cancel
                    </Button>
                    {values.linkToTask && values.selectedTask && (
                      <Button color="primary" onClick={() => {

                        addLink(values, setFieldValue);
                      }

                      }  >
                        Update
                      </Button>
                    )}
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default ViewProjectTask;

