import React from 'react';

import useMode, { Mode } from 'utils/useMode.hook';
import NotificationsPage from 'containers/shared/Technician/NotificationsPage';
import AccountPage from 'containers/shared/AccountPage';
import AssetsRoutes from 'containers/Operative/Assets/AssetsRoutes';
import HomePage from './Home/HomePage';
import TasksPage from './Tasks/TasksPage';
import TaskDetailsRoutes from './Tasks/TaskDetails/TaskDetailsRoutes';
import { Navigate, Route, Routes } from 'react-router';

const OperativeRoutes: React.FC = () => {
  const { mode } = useMode();
  if (mode !== Mode.OPERATIVE) {
    return <Navigate to="/" />;
  }
  return (
    <Routes>
      <Route path={`home`} element={<HomePage />} />
      <Route path={`notifications`} element={<NotificationsPage/>} />
      <Route path={`works/:taskId`}>
        <Route index element={<TaskDetailsRoutes />} />
        <Route path={`*`} element={<TaskDetailsRoutes />} />
      </Route>
      <Route path={`works`} element={<TasksPage />} />
      <Route path={`account`} element={<AccountPage />} />
      <Route path={`:userId/assets/:siteId`}>
        <Route index element={<AssetsRoutes />} />
        <Route path={`*`} element={<AssetsRoutes />} />
      </Route>
      <Route path={`:userId/assets`}>
        <Route index element={<AssetsRoutes />} />
        <Route path={`*`} element={<AssetsRoutes />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={`../home`} replace />}
      />
    </Routes>
  );
};

export default OperativeRoutes;
