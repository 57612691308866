import { useMutation } from '@apollo/client';
import { UPLOAD_REQUEST } from 'components/FileInput/graphql';
import { uploadRequest as UploadRequestResponse, uploadRequestVariables } from 'models/graphql';
import { useCallback } from 'react';
import { fileUpload } from 'utils/Api';

export default function useFileUpload() {
  const [uploadRequest] = useMutation<UploadRequestResponse, uploadRequestVariables>(UPLOAD_REQUEST);

  return useCallback(
    async (file: File, publicAccess?: boolean) => {
      // create record in DB
      const uploadRequestResponse = await uploadRequest({
        variables: { fileName: file.name, public: !!publicAccess },
      });
      if (!uploadRequestResponse.data) {
        throw new Error(`Unable to upload file '${file.name}'.`);
      }
      const { url, id, extension } = uploadRequestResponse.data.uploadRequest;

      // Upload file to Cloud
      await fileUpload(url, file);
      return { id, name: file.name, extension };
    },
    [uploadRequest],
  );
}
