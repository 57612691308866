import React, { useEffect } from 'react';
import { Drawer } from '@mui/material';
import NotificationList from 'containers/shared/Notifications/NotificationList';
import useGetNotifications from 'containers/shared/Notifications/useGetNotifications';

const Notifications: React.FC<{
  isOpen: boolean;
  onClose?: () => void;
  onCount?: (count: number) => void;
}> = ({ isOpen, onCount, onClose }) => {
  const { notifications, unreadCount, loading } = useGetNotifications();
  useEffect(() => onCount && onCount(unreadCount), [onCount, unreadCount]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <NotificationList notificationsToMe={notifications} loading={loading} onClose={onClose} />
    </Drawer>
  );
};

export default Notifications;
