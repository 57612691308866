import { DataValidation } from 'exceljs';

export default function excelValidationRequiredText(
  errorMessage: string,
  errorTitle = 'Required field',
): DataValidation {
  return {
    type: 'textLength',
    operator: 'greaterThan',
    formulae: [0],
    showErrorMessage: true,
    errorTitle,
    error: errorMessage,
  };
}
