import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router';
import useText from 'texts/useText.hook';
import cn from 'utils/cn';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  value: {
    color: theme.palette.grey[500],

    '&.danger': {
      color: theme.palette.error.light,
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  label: {
    paddingBottom: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold,

    '&.small': {
      fontSize: theme.fontSizes[12],
    },
  },
}));

const Item: React.FC<{
  label: string;
  value: string | undefined | null;
  link?: string;
  internalLink?: string;
  small?: boolean;
  danger?: boolean | null;
}> = ({ label, value, link, internalLink, small, danger }) => {
  const styles = useStyles();
  const { t } = useText('common');

  return (
    <div className={styles.container}>
      <Typography variant="body1" className={cn(styles.label, { small })}>
        {label}
      </Typography>
      {link || internalLink ? (
        <>
          {link ? (
            <a href={link} target="_blank" rel="noopener noreferrer">
              <Typography variant="body1" className={cn(styles.value, styles.link, { danger })}>
                {value || t('common')('emptyValue')}
              </Typography>
            </a>
          ) : null}
          {internalLink ? (
            <Link to={internalLink}>
              <Typography variant="body1" className={cn(styles.value, styles.link, { danger })}>
                {value || t('common')('emptyValue')}
              </Typography>
            </Link>
          ) : null}
        </>
      ) : (
        <Typography variant="body1" className={cn(styles.value, { danger })}>
          {value || t('common')('emptyValue')}
        </Typography>
      )}
    </div>
  );
};

export default Item;
