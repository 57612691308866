import React from 'react';
import AppDialog from 'components/AppDialog';
import AppButton from 'components/AppButton';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import warningIcon from 'assets/warningIcon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    height: '30px',
    width: '100%',
  },
  descGridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
}));

const WarningDialog: React.FC<{
  open: boolean;
  title: string;
  descriptionText?: string;
  onClose: () => void;
}> = ({ open, title, descriptionText, onClose }) => {
  const { descGridContainer, paddingLeft, warning } = useStyles();

  const description = (
    <div className={descGridContainer}>
      <img src={warningIcon} alt="Warning" className={warning} />
      <Typography className={paddingLeft}>{descriptionText}</Typography>
    </div>
  );

  return (
    <AppDialog open={open} onClose={onClose} title={title}>
      {descriptionText && <Box sx={{
        mb: 1
      }}>{description}</Box>}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3
        }}>
        <Box sx={{
          mr: 1
        }}>
          <AppButton type="button" variant="outlined" onClick={onClose} color="neutral">
            Go back
          </AppButton>
        </Box>
      </Box>
    </AppDialog>
  );
};

export default WarningDialog;
