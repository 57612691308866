import React, { useEffect, useRef } from 'react';
import {getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

const SCALE_HIGHER_RESOLUTION = (size: number) => (400 / size) * 7.5;

interface Props {
  setLoading: (loading: boolean) => void;
  src: string;
  rotation: number;
}

const PdfRenderer: React.FC<Props> = ({ setLoading, src, rotation }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    async function render() {
      if (!src) return;
      setLoading(true);
      const height = window.innerHeight;
      const width = window.innerWidth;
      const ratio = height / width;
      GlobalWorkerOptions.workerSrc = new URL(
        // 'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
        'pdfjs-dist/build/pdf.worker.min.mjs',
        import.meta.url,
      ).toString();

      const pdfLoading = getDocument(src);
      const pdfFile = await pdfLoading.promise;
      if (!pdfFile) return;
      const page = await pdfFile.getPage(1);

      const rotate = (page.rotate + rotation) % 360;
      const viewport = page.getViewport({ scale: 1 });

      const _scale = ratio > 1 ? height / viewport.height : width / viewport.width;

      const viewportScaledRotated = page.getViewport({
        scale: _scale * SCALE_HIGHER_RESOLUTION(ratio > 1 ? height : width),
        rotation: rotate,
      });

      const viewportratio = viewportScaledRotated.height / viewportScaledRotated.width;

      if (canvasRef.current) {
        canvasRef.current.style.height = height < viewportratio * width ? `${height}px` : `${width * viewportratio}px`;
        canvasRef.current.style.width = width < height / viewportratio ? `${width}px` : `${height / viewportratio}px`;
        canvasRef.current.height = viewportScaledRotated.height;
        canvasRef.current.width = viewportScaledRotated.width;
        const canvasCtx = canvasRef.current.getContext('2d');
        if (canvasCtx) {
          await page.render({
            canvasContext: canvasCtx,
            viewport: viewportScaledRotated,
          }).promise;
        }
      }
      setLoading(false);
    }
    render();
  }, [src, rotation, setLoading]);

  return <canvas ref={canvasRef} />;
};

export default PdfRenderer;
