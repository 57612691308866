// not in use
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import {
  clientSitesWithAssets,
  clientSitesWithAssetsVariables,
  documentUpdate,
  documentUpdateVariables,
  documentCreate,
  documentCreateVariables,
  DocumentCategory,
  getDocument,
  getDocumentVariables,
} from 'models/graphql';
import FileInput from 'components/FileInput';
import Yup from 'utils/yup';

import { AppForm, AppSelectField, AppSelectMultipleField, AppTextField, AppFieldError } from 'components/form';
import { FormLayout, FullScreenPage } from 'components/layout';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import useCancel from 'utils/useCancel.hook';
import useText from 'texts/useText.hook';
import useParentLocation from 'utils/useParentLocation.hook';
import enumToArray from 'utils/enumToArray';
import FilesList from 'components/FileInput/FilesList';
import GraphqlErrorCode from 'shared/graphqlErrorCodes';
import resolveGraphqlErrorMessage from 'utils/resolveGraphqlErrorMessage';
import { CREATE_DOCUMENT, UPDATE_DOCUMENT, GET_DOCUMENT, GET_CLIENT_SITES_WITH_ASSETS } from './graphql';
import { useParams } from 'react-router';

interface FormValues {
  siteId: string;
  name: string;
  category: DocumentCategory;
  systemIds?: string[];
  assetIds?: string[];
  fileId: string;
}

const documentCategoryArray = enumToArray(DocumentCategory);
const ProjectreatePage: React.FC = () => {
  const { clientId, siteId, documentId } = useParams<{
    clientId: string;
    siteId: string;
    documentId?: string;
  }>();
  const parentHref = useParentLocation(documentId ? '../..' : '..');
  const { t } = useText('documents', 'systemTypes');
  const [file, setFile] = useState<{ id: string; name: string } | undefined>();
  const cancel = useCancel(parentHref);

  const { loading: sitesLoading, data: sitesData } = useQuery<clientSitesWithAssets, clientSitesWithAssetsVariables>(
    GET_CLIENT_SITES_WITH_ASSETS,
    {
      variables: { id: clientId! },
    },
  );
  const [create] = useMutation<documentCreate, documentCreateVariables>(CREATE_DOCUMENT, {
    update: (cache) => apolloCacheEvictQuery({ cache, query: 'documents' }),
  });
  const [update] = useMutation<documentUpdate, documentUpdateVariables>(UPDATE_DOCUMENT);
  const { loading: documentLoading, data: documentData } = useQuery<getDocument, getDocumentVariables>(GET_DOCUMENT, {
    skip: !documentId,
    variables: { id: documentId! },
    onCompleted: ({
      document: {
        file: { id, originalName },
      },
    }) => setFile({ id, name: originalName }),
  });
  const document = documentData?.document;

  const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
    siteId: Yup.string().required(),
    name: Yup.string().required(),
    category: Yup.mixed<DocumentCategory>().oneOf(documentCategoryArray).required(),
    systemIds: Yup.array().of(Yup.string().required()),
    assetIds: Yup.array().of(Yup.string().required()),
    fileId: Yup.string().required(),
  });

  return (
    <FullScreenPage title={`${documentId ? 'Edit' : 'Upload new'} Document`} loading={sitesLoading || documentLoading}>
      <Formik<FormValues>
        initialValues={{
          siteId,
          name: document?.name || '',
          category: document?.category || DocumentCategory.GENERAL,
          systemIds: document && document.systems ? document.systems.map((system) => system.id) : [],
          assetIds: document && document.assets ? document.assets.map((asset) => asset.id) : [],
          fileId: document?.file?.id || '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm, setFieldError }) => {
          try {
            if (documentId) {
              await update({
                variables: { id: documentId, changes: { name: values.name, category: values.category } },
              });
            } else {
              await create({
                variables: {
                  document: {
                    ...values,
                    systemIds: values.systemIds || [],
                    assetIds: values.assetIds || [],
                  },
                },
              });
            }
            resetForm();
            cancel();
          } catch (e) {
            const [code] = resolveGraphqlErrorMessage(e);
            if (code === GraphqlErrorCode.uniqueNameError) {
              setFieldError('name', t('documents')('uniqueNameError'));
            }
          }
        }}
      >
        {({ isSubmitting, setFieldValue, handleSubmit, values, errors, touched }) => (
          <AppForm handleSubmit={handleSubmit}>
            <FormLayout
              parentHref={parentHref}
              isSubmitting={isSubmitting}
              sidebar={
                <>
                  <FilesList
                    label="Attachment"
                    files={file ? [file] : []}
                    disabled={!!documentId}
                    onRemove={() => {
                      setFieldValue('fileId', undefined);
                      setFile(undefined);
                    }}
                    bottomSection={
                      !documentId && (
                        <FileInput
                          onUpload={(id, name) => {
                            setFieldValue('fileId', id);
                            setFile({ id, name });
                          }}
                          regular
                          disabled={!!file}
                        />
                      )
                    }
                  />
                  <AppFieldError message={touched.fileId && errors.fileId ? 'Please upload a file' : undefined} />
                </>
              }
            >
              <AppSelectField
                label="Site"
                name="siteId"
                required
                disabled={!!documentId}
                options={{
                  array: sitesData?.client.sites || [],
                  key: (site) => site.id,
                  value: (site) => site.id,
                  template: (site) => site.name,
                }}
                onChange={() => {
                  setFieldValue('assetIds', []);
                }}
              />
              <AppTextField label="Name" name="name" type="text" required />
              <AppSelectField
                label="Category"
                name="category"
                required
                options={{
                  array: documentCategoryArray,
                  value: (category) => category,
                  key: (category) => category,
                  template: (category) => t('documents')(category),
                }}
              />
              <AppSelectMultipleField
                label="System references"
                name="systemIds"
                disabled={!!documentId}
                options={{
                  array: sitesData?.client.sites.find(({ id }) => id === values.siteId)?.systems || [],
                  key: (system) => system.id,
                  value: (system) => system.id,
                  template: (system) => system.name,
                }}
              />
              <AppSelectMultipleField
                label="Assets"
                name="assetIds"
                disabled={!!documentId}
                options={{
                  array: sitesData?.client.sites.find(({ id }) => id === values.siteId)?.assets || [],
                  key: (asset) => asset.id,
                  value: (asset) => asset.id,
                  template: (asset) => asset.name,
                }}
              />
            </FormLayout>
          </AppForm>
        )}
      </Formik>
    </FullScreenPage>
  );
};

export default ProjectreatePage;
