import React, { useEffect, useState } from 'react';
import useText from 'texts/useText.hook';
import { useQuery, useMutation } from '@apollo/client';
import {
  getDocuments,
  getDocumentsVariables,
  DocumentStatus,
  getDocuments_documents as Document,
  documentStatusUpdate,
  documentStatusUpdateVariables,
  downloadRequestVariables,
  downloadRequest,
} from 'models/graphql';
import AppTable, { AppTableStatus, AppTableFilters } from 'components/AppTable';
import theme from 'style/theme';
import AppButtonLink from 'components/AppButtonLink';
import { DOWNLOAD_REQUEST } from 'components/FileInput/graphql';
import DocumentsFilter from 'containers/Client/Documents/DocumentsFilter';
import { GET_DOCUMENTS, UPDATE_DOCUMENT_STATUS } from './graphql';
import DocumentDetails from './DocumentDetails';
import { ContextStore, useStores } from 'stores';
import { useNavigate, useParams, useLocation} from 'react-router';

const DocumentsPage: React.FC = () => {
  const { t } = useText('documents');
  const navigate = useNavigate();
  
  const { pathname } = useLocation();
  const { siteId } = useParams<{
    siteId: string;
  }>();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentsFiltered, setDocumentsFiltered] = useState<Document[]>([]);

  const { data, loading, refetch } = useQuery<getDocuments, getDocumentsVariables>(GET_DOCUMENTS, {
    variables: { siteId },
  });

  const renderStatus = ({ status }: Document) => <AppTableStatus isArchived={status === DocumentStatus.ARCHIVED} />;
  const [statusUpdate] = useMutation<documentStatusUpdate, documentStatusUpdateVariables>(UPDATE_DOCUMENT_STATUS);
  const [getDownloadUrl] = useMutation<downloadRequest, downloadRequestVariables>(DOWNLOAD_REQUEST);

  const { contextStore } = useStores();
 
  contextStore.setReportViewedValue(false);

  useEffect(() => {
    if (data?.documents) {
      setDocuments(data?.documents);
      setDocumentsFiltered(data?.documents);
    }
  }, [data]);

  return (
    <>
      <AppTableFilters
        searchPlaceholder={t('documents')('searchPlaceholder')}
        addNewButton={<AppButtonLink to={`${pathname}/add`}>{t('documents')('createDocument')}</AppButtonLink>}
        onSubmit={async ({ search, showArchived: archived }) => {
          await refetch({ siteId, search, archived });
        }}
      />
      <DocumentsFilter
        documents={documents}
        documentsFiltered={documentsFiltered}
        setDocumentsFiltered={setDocumentsFiltered}
      />
      <AppTable
        data={documentsFiltered}
        loading={loading}
        noDataMessage={t('documents')('noDocuments')}
        doubleLineActions
        columns={[
          {
            name: t('documents')('name'),
            template: (document) => document.name,
          },
          {
            name: t('documents')('category'),
            template: (document) => t('documents')(document.category),
          },
          {
            name: t('documents')('uploadedBy'),
            template: (document) => document.uploadedBy.name,
          },
          {
            name: t('documents')('uploadedAt'),
            template: (document) => theme.dateFormat.normal(document.uploadedAt),
          },
          {
            name: t('documents')('status'),
            align: 'center',
            template: renderStatus,
          },
        ]}
        detailsPanel={(document) => {
          const statusChangeAction =
            document.status === DocumentStatus.ACTIVE
              ? {
                  title: 'Archive',
                  action: () => statusUpdate({ variables: { id: document.id, status: DocumentStatus.ARCHIVED } }),
                }
              : {
                  title: 'Activate',
                  action: () => statusUpdate({ variables: { id: document.id, status: DocumentStatus.ACTIVE } }),
                };
          return {
            title: document.name,
            template: <DocumentDetails {...document} />,
            actions: [
              {
                title: 'Edit',
                action: () => navigate(`${pathname}/edit/${document.id}`),
              },
              {
                title: 'Download',
                action: async () => {
                  const { data: downloadData } = await getDownloadUrl({ variables: { fileId: document.file.id } });
                  if (downloadData?.downloadRequest.url) {
                    window.open(downloadData?.downloadRequest.url, '_self');
                  }
                },
              },
              statusChangeAction,
            ],
          };
        }}
      />
    </>
  );
};

export default DocumentsPage;
