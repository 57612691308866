import React from 'react';

import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import useText from 'texts/useText.hook';
import SitesNotFoundOrLoading from 'components/SitesNotFoundOrLoading';
import useSites from '../useSites';
import DocumentsPage from './DocumentsPage';
import DocumentCreatePage from './DocumentCreatePage';
import { Route, Routes, useLocation } from 'react-router';
import { adaptPath } from 'utils/adaptPath';

const TaskRoutes: React.FC = () => {
  const {pathname} = useLocation();
  const updatedUrl =  adaptPath(pathname, 'documents')
    
  const { current, renderBreadcrumb } = useSites(updatedUrl);

  const { t } = useText('documents');
  return current ? (
    <DefaultErrorBoundary>
      {renderBreadcrumb(t('documents')('documentsTitle'))}
      <Routes>
        <Route path={`edit/:documentId`} element={<DocumentCreatePage />} />
        <Route path={`add`} element={<DocumentCreatePage />} />
        <Route path={`/`} element={<DocumentsPage />} />
      </Routes>
    </DefaultErrorBoundary>
  ) : (
    <SitesNotFoundOrLoading path={'/'} name="Documents" />
  );
};

export default TaskRoutes;
