import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';

import { AnimateHeight } from 'components/animations';
import AppAvatar from 'components/AppAvatar';
import AppButton from 'components/AppButton';
import { AppForm } from 'components/form';
import AppTextAreaField from 'components/form/AppTextAreaField';
import {
  taskClientComment,
  taskContractorComment,
  taskClientComments,
  taskClientCommentsVariables,
  taskContractorComments,
  taskContractorCommentsVariables,
  taskClientCommentVariables,
  taskContractorCommentVariables,
} from 'models/graphql';
import useText from 'texts/useText.hook';
import sendMessage from 'assets/sendMessage.svg';
import cn from 'utils/cn';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import useMode, { Mode } from 'utils/useMode.hook';
import {
  ADD_COMMENT_CLIENT,
  ADD_COMMENT_CONTRACTOR,
  GET_CLIENT_TASK_COMMENTS,
  GET_CONTRACTOR_TASK_COMMENTS,
} from '../graphql';
import { Box, CircularProgress, Theme, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: 'grid',
      gridTemplateRows: '1fr auto',
    },
    commentContainer: {
      padding: theme.spacing(2),
      borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    },
    commentContent: {
      display: 'flex',
      position: 'relative',
    },
    commentAvatar: {
      marginRight: theme.spacing(1),
    },
    commentAuthorCompany: {
      fontSize: theme.fontSizes[12],
    },
    commentAuthorName: {
      marginTop: theme.spacing(0.25),
      color: theme.palette.grey[500],
    },
    commentMessage: {
      marginTop: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
    },
    commentCreatedAt: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[500],
    },
    commentArchived: {
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[600],
      position: 'absolute',
      right: theme.spacing(1),
    },
    addCommentContainer: {
      padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      [theme.breakpoints.down(BREAKPOINT)]: {
        display: 'flex',
        '& :first-child': {
          flex: 1,
        },
      },
    },
    addCommentButton: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down(BREAKPOINT)]: {
        marginTop: 0,
        marginLeft: theme.spacing(2),
        '& button': {
          padding: 0,
          alignSelf: 'start',
          '&[disabled]': {
            filter: 'grayscale(1.0)',
          },
        },
      },
    },
  });
});

interface FormValues {
  message: string;
}

const TaskComments: React.FC<{ taskId: string }> = ({ taskId }) => {
  const { mode } = useMode();
  const { data: clientData, loading: clientDataLoading } = useQuery<taskClientComments, taskClientCommentsVariables>(
    GET_CLIENT_TASK_COMMENTS,
    {
      variables: { taskId },
      skip: mode !== Mode.CLIENT,
    },
  );
  const { data: contractorData, loading: contractorDataLoading } = useQuery<
    taskContractorComments,
    taskContractorCommentsVariables
  >(GET_CONTRACTOR_TASK_COMMENTS, {
    variables: { taskId },
    skip: mode !== Mode.CONTRACTOR,
  });
  const comments = mode === Mode.CLIENT ? clientData?.task.commentsWithArchived : contractorData?.task.comments;

  const [addClientComment] = useMutation<taskClientComment, taskClientCommentVariables>(ADD_COMMENT_CLIENT);
  const [addContractorComment] = useMutation<taskContractorComment, taskContractorCommentVariables>(
    ADD_COMMENT_CONTRACTOR,
  );
  const addComment = mode === Mode.CLIENT ? addClientComment : addContractorComment;

  const { t } = useText('tasks');
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BREAKPOINT));

  if (clientDataLoading || contractorDataLoading) {
    return (
      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "center"
        }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!comments) throw new Error('comments should be defined when loading is false');
  return (
    <div className={styles.container}>
      <div>
        {comments.map((comment) => (
          <AnimateHeight visible key={comment.id}>
            <div className={styles.commentContainer}>
              <div className={styles.commentContent}>
                <div className={styles.commentAvatar}>
                  <AppAvatar name={comment.author.name} imageSrc={comment.author.picture?.srcUrl} />
                </div>
                <div>
                  {comment.archived && <div className={styles.commentArchived}>Archived</div>}
                  <div className={styles.commentAuthorCompany}>{comment.author.companyName}</div>
                  <div className={styles.commentAuthorName}>{comment.author.name}</div>
                  <div className={styles.commentMessage}>{comment.message}</div>
                </div>
              </div>
              <div className={styles.commentCreatedAt}>
                {`${theme.dateFormat.time(comment.createdAt)} | ${theme.dateFormat.normal(comment.createdAt)}`}
              </div>
            </div>
          </AnimateHeight>
        ))}
      </div>
      <Formik<FormValues>
        initialValues={{ message: '' }}
        onSubmit={async (values, { resetForm }) => {
          if (values.message) {
            await addComment({
              variables: { taskId, message: values.message },
            });
            resetForm();
          }
        }}
      >
        {({ isSubmitting, handleSubmit, submitForm, values }) => (
          <AppForm handleSubmit={handleSubmit}>
            <div className={styles.addCommentContainer}>
              <AppTextAreaField
                name="message"
                placeholder={t('tasks')('addComment')}
                onKeyDownCapture={(event) => {
                  if (event.key === 'Enter') {
                    submitForm();
                    event.preventDefault();
                  }
                }}
              />
              <div
                className={cn(styles.addCommentButton, {
                  inactive: !values.message,
                })}
              >
                {isMobile ? (
                  <IconButton type="submit" size="medium" disabled={!values.message || isSubmitting}>
                    <img src={sendMessage} alt={t('tasks')('addCommentSubmit')} />
                  </IconButton>
                ) : (
                  <AppButton
                    variant={values.message ? 'contained' : 'outlined'}
                    type="submit"
                    inProgress={isSubmitting}
                    disabled={!values.message}
                  >
                    <span>{t('tasks')('addCommentSubmit')}</span>
                  </AppButton>
                )}
              </div>
            </div>
          </AppForm>
        )}
      </Formik>
    </div>
  );
};

export default TaskComments;
