import React, { PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';
import removeSimpleIcon from 'assets/removeSimpleIcon.svg';
import cn from 'utils/cn';
import objectPropertyByString from 'utils/objectPropertyByString';
import AppSelectField, { AppSelectFieldType } from './AppSelectField';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const styleRules = (theme: Theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      display: 'flex',
      padding: theme.spacing(1.2),
      background: theme.palette.grey[300],
      borderRadius: theme.shape.borderRadius * 2,
      fontSize: theme.fontSizes[12],
      fontWeight: theme.typography.fontWeightBold,
      margin: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
      '&.error': {
        border: `solid 1px ${theme.palette.error.light}`,
      },
    },
    chipgreen: {
      display: 'flex',
      padding: theme.spacing(1.2),
      //background: theme.palette.grey[300],
      background: '#44924D',
      color: 'white',
     borderRadius: theme.shape.borderRadius * 2,
      fontSize: theme.fontSizes[12],
      fontWeight: theme.typography.fontWeightBold,
      margin: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
      '&.error': {
        border: `solid 1px ${theme.palette.error.light}`,
      },
    },
    chipgred: {
      display: 'flex',
      padding: theme.spacing(1.2),
     // background: theme.palette.grey[300],
      background: '#e08f82',
      color: 'white',
      borderRadius: theme.shape.borderRadius * 2,
      fontSize: theme.fontSizes[12],
      fontWeight: theme.typography.fontWeightBold,
      margin: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
      '&.error': {
        border: `solid 1px ${theme.palette.error.light}`,
      },
    },
    chipDelete: {
      
      marginLeft: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
  });
const useStyles = makeStyles(styleRules);

export default function AppSelectMultipleField<T>({
  name,
  options,
  onAdd,
  onRemove,
  ...propsToPass
}: PropsWithChildren<
  Omit<AppSelectFieldType<T>, 'onChange'> & {
    onAdd?: (newElement: T) => void;
    onRemove?: (removedElement: T) => void;
  }
>) {
  const styles = useStyles();

  const { values, setFieldValue, errors, touched } = useFormikContext<Record<string, unknown>>();

  console.log("values Are:", values)

  const selectedValues = objectPropertyByString(values, name) || [];

  console.log("values:", values)

console.log("selectedValues:", selectedValues)

  const selectableValues = options.array.filter((option) => !selectedValues.includes(options.value(option)));

  const valueTemplate: (value: string) => React.ReactNode = (value: string) => {
    const selectedOption = options.array.find((option) => options.value(option) === value);

    return selectedOption !== undefined ? options.template(selectedOption) : null;
  };

 console.log("selectedValues:", selectedValues)
  const setStyleValue: (value: string) => React.ReactNode = (value: string) => {
  //console.log("In setStyleValue Line 80", value)
 //  console.log("In setStyleValue Line 81",values.assetsList)
  if (values.assetsList !== undefined) {
      let asslist = values.assetsList;
      let selasset = asslist.find((selasset) => selasset.id  === value);
      console.log("selasset compliance:", selasset)
      const compliantValue = selasset?.compliant;
      console.log("compliantValue:", compliantValue)
      return compliantValue;
    }
    else
    {
      let compliantValue = 'null'
      return compliantValue;
    }
  };

  return (
    <div>
      <AppSelectField
        {...propsToPass}
        options={{
          ...options,
          array: selectableValues,
        }}
        disabled={selectableValues.length === 0 || propsToPass.disabled}
        name={name}
        onChange={(newValue) => {
          setFieldValue(name, [...selectedValues, options.value(newValue)]);
          if (onAdd) onAdd(newValue);
        }}
      />

      <div className={styles.chips} >
        
        {selectedValues.map((value: string | T) => ( 
          <div
            key={value as string}
            className={
              setStyleValue(value as string) === true
                ? styles.chipgreen
                : setStyleValue(value as string)  === false
                ? styles.chipgred
                : styles.chip
            }
         
          >
          
            {valueTemplate(value as string)}
            {!propsToPass.disabled && (
              <button
                type="button"
                onClick={() => {
                  setFieldValue(
                    name,
                    selectedValues.filter((selectedValue: string) => selectedValue !== value),
                  );
                  if (onRemove) onRemove(value as T);
                }}
                className={styles.chipDelete}
              >
                <img alt="remove" src={removeSimpleIcon} />
              </button>
            )}
            
          </div>
        ))} 
      </div>
    </div>
  );
}
