import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, useTheme } from '@mui/material';
import AcceptOrDeny from 'components/AcceptOrDeny';
import { AnimateHeight } from 'components/animations';
import AppButton from 'components/AppButton';
import { AppForm } from 'components/form';
import AppTextAreaField from 'components/form/AppTextAreaField';
import { ACCEPT_TASK, REJECT_TASK } from 'containers/shared/graphql';
import useTaskDetailsAcceptOrDenyStyles from 'containers/shared/Tasks/TaskDetailsStyles';
import { GET_TASK_DETAILS } from 'containers/shared/Technician/graphql';
import useTaskDetailsPageStyles from 'containers/shared/Technician/TaskDetailsPageStyles';
import { Formik } from 'formik';
import { motion, useAnimation } from 'motion/react';
import {
  ReOccurenceType,
  taskDetails,
  taskDetailsVariables,
  taskResponsibleAccept,
  taskResponsibleAcceptVariables,
  taskResponsibleReject,
  taskResponsibleRejectVariables,
  TaskStatus,
} from 'models/graphql';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { readDayDate } from 'shared/formatDayDate';
import useText from 'texts/useText.hook';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import cn from 'utils/cn';
import Yup from 'utils/yup';

interface FormValues {
  comment?: string;
}

const TaskDetailsPage: React.FC = () => {  
  const { grid, cell, fullWidth, rightBorder, label, value } = useTaskDetailsPageStyles();
  const { taskId } = useParams<{ taskId: string }>();
  const { t, tt } = useText('reOccurence', 'common', 'tasks');
  const theme = useTheme();
  const [selected, setSelected] = useState<'accept' | 'deny' | undefined>();
  const [acceptOrDenyOpened, setAcceptOrDenyOpened] = useState(false);
  useEffect(() => setSelected(undefined), [setSelected, taskId]);
  const styles = useTaskDetailsAcceptOrDenyStyles();
  const controls = useAnimation();

  useEffect(() => {
    const anim = async () => controls.start({ translateY: 0, transition: { ease: 'easeInOut', duration: 0.3 } });
    if (!selected) anim();
  });

  useEffect(() => {
    const anim = async () => {
      if (!acceptOrDenyOpened && selected) {
        setAcceptOrDenyOpened(true);
        // await controls.start({ translateY: 128, transition: { duration: 0 } });
        await controls.start({ translateY: 0, transition: { ease: 'easeIn', duration: 1 } });
      }
    };
    anim();
  }, [controls, selected, acceptOrDenyOpened]);

  const { data, loading } = useQuery<taskDetails, taskDetailsVariables>(GET_TASK_DETAILS, {
    variables: { id: taskId },
  });

  const [accept] = useMutation<taskResponsibleAccept, taskResponsibleAcceptVariables>(ACCEPT_TASK, {
    update: (cache, { data: _data }) => {
      if (!_data?.taskResponsibleAccept.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: _data.taskResponsibleAccept.id },
      });
    },
  });
  const [reject] = useMutation<taskResponsibleReject, taskResponsibleRejectVariables>(REJECT_TASK, {
    update: (cache, { data: _data }) => {
      if (!_data?.taskResponsibleReject.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: _data.taskResponsibleReject.id },
      });
    },
  });

  const renderRepeatValue = () => {
    if (!data || data.task.reOccurenceType === ReOccurenceType.NEVER) {
      return 'Do not repeat';
    }
    if (data.task.reOccurenceType === ReOccurenceType.NA) {
      return 'N/A';
    }
    return `Repeats ${tt('reOccurence')('types')(data.task.reOccurenceType)}`;
  };

  const urgentValue = data?.task.urgent ? 'Urgent' : 'Normal';

  const validationSchema:  Yup.ObjectSchema<FormValues> = Yup.object().shape({
    comment: selected === 'deny' ? Yup.string().required(
      t('common')('required', {
        field: t('tasks')('comments')
      })
    ) : Yup.string(),
  })

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <>
        <div className={grid}>
          <div className={`${cell} ${fullWidth}`}>
            <div className={label}>Client</div>
            <div className={value}>{data?.task.approval?.companyName}</div>
          </div>
          <div className={`${cell} ${fullWidth}`}>
            <div className={label}>Site Address</div>
            <div className={value}>{data?.task.site.address}</div>
          </div>
          <div className={`${cell} ${rightBorder}`}>
            <div className={label}>Task Order Id</div>
            <div className={value}>{data?.task.contractorResponsibleWorkOrderId || 'N/A'}</div>
          </div>
          <div className={cell}>
            <div className={label}>Task Id</div>
            <div className={value}>{data?.task.idReadable}</div>
          </div>
          <div className={`${cell} ${rightBorder}`}>
            <div className={label}>Task Title</div>
            <div className={value}>{data?.task.tasktitle || 'N/A'}</div>
          </div>
          <div className={cell}>
            <div className={label}>Notes</div>
            <div className={value}>{data?.task.notes || 'N/A'}</div>
          </div>
          <div className={`${cell} ${rightBorder}`}>
            <div className={label}>Contact</div>
            <div className={value}>{data?.task.approval?.name} </div>
          </div>
          <div className={cell}>
            <div className={label}>Phone</div>
            <div className={value}>{data?.task.approval?.phonenumber}</div>
          </div>   
          <div className={`${cell} ${rightBorder}`}>
            <div className={label}>Tasks Deadline</div>
            <div className={value}>
              {data?.task.deadline ? theme.dateFormat.normal(readDayDate(data?.task.deadline)) : null}
            </div>
          </div>
          <div className={cell}>
            <div className={label}>Document Deadline</div>
            <div className={value}>
              {data?.task.documentDeadline ? theme.dateFormat.normal(readDayDate(data?.task.documentDeadline)) : null}
            </div>
          </div>
          <div className={`${cell} ${rightBorder}`}>
            <div className={label}>Occurence</div>
            <div className={value}>{renderRepeatValue()}</div>
          </div>
          <div className={cell}>
            <div className={label}>Priority</div>
            <div className={value}>{data?.task.urgent === null ? 'N/A' : urgentValue}</div>
          </div>
        </div>
      </>
      {data?.task.status === TaskStatus.SENT && (
        <motion.div
          className={cn(styles.acceptOrDenyContainer, styles.absoluteContainer)}
          initial={{ translateY: 72 }}
          animate={controls}
          style={{ height: selected ? '200px' : '72px' }}
        >
          <div className={styles.acceptOrDeny}>
            <AcceptOrDeny
              acceptText={t('common')('accept')}
              selected={selected}
              onAccept={() => setSelected('accept')}
              onDeny={() => setSelected('deny')}
            />
            <AnimateHeight visible={!!selected} slow>
              <div className={styles.acceptOrDenyDetails}>
                <Formik<FormValues>
                  initialValues={{
                    comment: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    if (!data) return;

                    if (selected === 'deny') {
                      await reject({ variables: { taskId: data.task.id, comment: values.comment || '' } });
                    } else {
                      await accept({ variables: { taskId: data.task.id, comment: values.comment } });
                    }
                  }}
                >
                  {({ handleSubmit, isSubmitting }) => (
                    <AppForm handleSubmit={handleSubmit}>
                      <AppTextAreaField name="comment" placeholder={t('tasks')('comments')} />
                      <div className={styles.acceptOrDenySubmit}>
                        <AppButton variant="outlined" type="submit" inProgress={isSubmitting}>
                          {t('common')('submit')}
                        </AppButton>
                      </div>
                    </AppForm>
                  )}
                </Formik>
              </div>
            </AnimateHeight>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default TaskDetailsPage;
