import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Formik, FieldArray } from 'formik';

import useText from 'texts/useText.hook';
import AppTable, { AppTableFilters } from 'components/AppTable';
import {
  assetsForUser,
  assetsForUserVariables,
  AssetType,
  CompliantStatus,
  getAssetFilterByUser,
  getAssetFilterByUserVariables,
  SystemType,
} from 'models/graphql';
import useSearchParams from 'utils/useSearchParams';
import useUpdateSearchParams from 'utils/useUpdateSearchParams';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NFCScanner from 'components/NFCScanner';
import GET_USER_ASSETS from 'containers/Technician/Assets/graphql';
import useGetUser from 'utils/useGetUser.hook';
import Box from '@mui/material/Box';
import { GET_ASSET_FILTER_BY_USER } from 'containers/Client/Assets/graphql';
import AssetDetailsPanel from '../../Client/Assets/AssetDetailsPanel';
import { Navigate, useNavigate, useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  assetName: {
    display: 'flex',
    flexDirection: 'column',
  },
  systemName: {
    paddingTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
}));

interface FormValues {
  selectAll: boolean;
  assetSelections: { id: string; selected: boolean }[];
}

const AssetsPage: React.FC = () => {
  const classes = useStyles();
  const { userId } = useParams<{ userId: string }>();
  const userData = useGetUser(userId);

  const { t, tt } = useText('assets', 'assetTypes', 'clients', 'compliance', 'systemTypes', 'tasks', 'urls');
  const [search, assetParam, typeParam, systemTypeParam, ownerParam] = useSearchParams(
    tt('urls')('queries')('search'),
    tt('urls')('queries')('asset'),
    tt('urls')('queries')('type'),
    tt('urls')('queries')('systemType'),
    tt('urls')('queries')('owner'),
  );
  const updateSearchParams = useUpdateSearchParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery<assetsForUser, assetsForUserVariables>(GET_USER_ASSETS, {
    variables: {
      id: userId,
      search,
      type: AssetType[typeParam as AssetType],
      systemType: SystemType[systemTypeParam as SystemType],
      owner: ownerParam && ownerParam === 'all' ? null : ownerParam,
      pagination: {
        page: 1,
        pageSize: 5000,
      },
    },
  });

  const { data: filter } = useQuery<getAssetFilterByUser, getAssetFilterByUserVariables>(GET_ASSET_FILTER_BY_USER, {
    variables: {
      userId,
    },
  });

  const [assets, setAssets] = useState<any[] | undefined>();
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:700px)');
  const [openNFC, setOpenNFC] = React.useState(false);

  useEffect(() => {
    setAssets(data?.useraAssets?.nodes);
  }, [data?.useraAssets?.nodes]);

  if (!userData?.fullAccess) {
    return <Navigate to="/" />;
  }

  return (
    <Formik<FormValues>
      initialValues={{
        selectAll: false,
        assetSelections: [],
      }}
      onSubmit={() => {
        // do nothing
      }}
    >
      {({ values, setFieldValue }) => {
        if (values.assetSelections.length === 0 && assets?.length) {
          setFieldValue(
            'assetSelections',
            assets.map((asset) => ({
              id: asset.id,
              selected: false,
            })),
          );
        }

        return (
          <>
            <AppTableFilters
              searchPlaceholder={t('assets')('searchPlaceholder')}
              onSubmit={async ({ search: newSearch, type, systemType, owner }) => {
                setFieldValue('assetSelections', []);
                navigate(
                  updateSearchParams({
                    [tt('urls')('queries')('asset')]: null,
                    [tt('urls')('queries')('search')]: newSearch,
                    [tt('urls')('queries')('type')]: type || 'all',
                    [tt('urls')('queries')('systemType')]: systemType || 'all',
                    [tt('urls')('queries')('owner')]: owner || 'all',
                  }),
                );
              }}
              searchValue={search}
              addNewButton={<NFCScanner openNFC={openNFC} setOpenNFC={setOpenNFC} setAssets={setAssets} />}
              filters={[
                {
                  name: 'type',
                  options: {
                    default: typeParam || 'all',
                    array: filter ? ['all', ...filter?.assetFilter.assetsTypes] : [],
                    key: (value) => value as string,
                    value: (value) => value as string,
                    template: (value) =>
                      AssetType[value as AssetType] === undefined
                        ? tt('assets')('filter')('allTypes')
                        : t('assetTypes')(value as AssetType),
                  },
                },
                {
                  name: 'systemType',
                  options: {
                    default: systemTypeParam || 'all',
                    array: filter ? ['all', ...filter?.assetFilter.systemTypes] : [],
                    key: (value) => value as string,
                    value: (value) => value as string,
                    template: (value) =>
                      SystemType[value as SystemType] === undefined
                        ? tt('assets')('filter')('allSystemTypes')
                        : t('systemTypes')(value as SystemType),
                  },
                },
                {
                  name: 'owner',
                  options: {
                    default: ownerParam || 'all',
                    array: filter ? ['all', ...filter?.assetFilter.owners] : [],
                    key: (value: string | { id: string; name: string }) =>
                      typeof value === 'string' ? value : value.id,
                    value: (value: string | { id: string; name: string }) =>
                      typeof value === 'string' ? value : value.id,
                    template: (value: string | { id: string; name: string }) =>
                      typeof value === 'string' ? tt('assets')('filter')('allOwners') : value.name,
                  },
                },
              ]}
              hideShowArchived
            />
            <FieldArray
              name="assetSelections"
              render={() => (
                <AppTable
                  data={assets}
                  loading={loading}
                  noDataMessage={t('clients')('noAssets')}
                  activeRowId={assetParam}
                  doubleLineActions
                  tableInfoLine={
                    assets && (
                      <Typography>
                        {assets.length} {assets.length !== 1 ? t('assets')('assets') : t('assets')('asset')},{' '}
                        {assets.filter((_a) => _a.compliant).length} {t('compliance')(CompliantStatus.true)},{' '}
                        {assets.filter((_a) => _a.compliant !== null && !_a.compliant).length}{' '}
                        {t('compliance')(CompliantStatus.false)}
                      </Typography>
                    )
                  }
                  detailsPanel={(row) => ({
                    title: row.name || '',
                    template: <AssetDetailsPanel asset={row} />,
                  })}
                  columns={
                    isMobile
                      ? [
                          {
                            name: 'Asset Name',
                            // eslint-disable-next-line react/display-name
                            template: (row) => (
                              <Box className={classes.assetName}>
                                <Box>{row.name}</Box>
                                <Box className={classes.systemName}>{row.system.name}</Box>
                              </Box>
                            ),
                          },
                          {
                            name: 'Asset Type',
                            template: (row) => t('assetTypes')(row.type),
                          },
                        ]
                      : [
                          {
                            name: 'Asset Name',
                            template: (row) => row.name,
                          },
                          {
                            name: 'Asset Type',
                            template: (row) => t('assetTypes')(row.type),
                          },
                          {
                            name: 'System Ref',
                            template: (row) => row.system.name,
                          },
                          {
                            name: 'System type',
                            template: (row) => t('systemTypes')(row.system.type),
                          },
                        ]
                  }
                  expandable={isMobile || isTablet}
                />
              )}
            />
          </>
        );
      }}
    </Formik>
  );
};
export default AssetsPage;
