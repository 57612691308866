import React, { useEffect, useState } from 'react';
import AppButtonLink from 'components/AppButtonLink';
import useText from 'texts/useText.hook';
import AppTable, { AppTableFilters, AppTableStatus } from 'components/AppTable';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  clientAssets,
  clientAssetsVariables,
  AssetStatus,
  assetStatusUpdate,
  assetStatusUpdateVariables,
  clientAssets_client_assets as clientAssetsClientAssets,
} from 'models/graphql';
import { useMutation, useQuery } from '@apollo/client';
import AppCheckboxField from 'components/form/AppCheckbox';
import { Formik, FieldArray } from 'formik';
import editIcon from 'assets/editIcon.svg';
import settingsIcon from 'assets/settingsIcon.svg';
import editIconGrey from 'assets/editIconGrey.svg';
import AppIconButton from 'components/AppIconButton';
import ActionsButton from 'components/ActionsButton';
import switcher from 'utils/switcher';
import { GET_CLIENT_ASSETS } from 'containers/shared/graphql';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import ClientAssetsImport from './ClientAssetsImport';
import { UPDATE_ASSET_STATUS } from '../graphql';
import ClientAssetsExport from './ClientAssetsExport';
import { useNavigate, useParams, useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButtons: {
      marginRight: theme.spacing(2),
    },
  }),
);

interface FormValues {
  selectAll: boolean;
  assetSelections: { id: string; selected: boolean }[];
}

const ClientAssetsPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { t } = useText('clients', 'common', 'systemTypes', 'assetTypes');
  const styles = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data, loading, refetch } = useQuery<clientAssets, clientAssetsVariables>(GET_CLIENT_ASSETS, {
    variables: {
      id: clientId,
    },
  });
  const [assets, setAssets] = useState<clientAssetsClientAssets[] | undefined>();
  useEffect(() => {
    setAssets(data?.client.assets);
  }, [data?.client.assets]);

  const [updateStatus] = useMutation<assetStatusUpdate, assetStatusUpdateVariables>(UPDATE_ASSET_STATUS, {
    update: (cache, { data: _data }) => {
      apolloCacheEvict({
        cache,
        typename: 'Site',
        id: _data?.assetStatusUpdate.site.id || '',
        fieldName: 'assets',
      });
      apolloCacheEvict({
        cache,
        typename: 'Site',
        id: _data?.assetStatusUpdate.site.id || '',
        fieldName: 'drawings',
      });
    },
  });

  const isAnySelected = (selections: FormValues['assetSelections']): boolean =>
    !!selections.find((selection) => selection.selected);

  return (
    <Formik<FormValues>
      initialValues={{
        selectAll: false,
        assetSelections: [],
      }}
      onSubmit={() => {
        // do nothing
      }}
    >
      {({ values, setFieldValue }) => {
        if (values.assetSelections.length === 0 && assets?.length) {
          setFieldValue(
            'assetSelections',
            assets.map((asset) => ({
              id: asset.id,
              selected: false,
            })),
          );
        }

        return (
          <>
            <AppTableFilters
              searchPlaceholder={t('clients')('searchAssetsPlaceholder')}
              onSubmit={async ({ search }) => {
                setFieldValue('assetSelections', []);
                refetch({ id: clientId, search });
              }}
              addNewButton={
                <>
                  <AppIconButton className={styles.actionButtons} onClick={() => navigate(`${pathname}/systems`)}>
                    <img alt="systems" src={settingsIcon} />
                  </AppIconButton>
                  <ClientAssetsExport clientId={clientId} className={styles.actionButtons} />
                  <ClientAssetsImport clientId={clientId} className={styles.actionButtons} />
                  <AppIconButton
                    className={styles.actionButtons}
                    disabled={!isAnySelected(values.assetSelections)}
                    onClick={() =>
                      navigate(`${pathname}/update`, {
                        state: {
                          assetIdsToUpdate: [
                            ...values.assetSelections.filter((asset) => asset.selected).map((asset) => asset.id),
                          ],
                        }
                      })
                    }
                  >
                    <img alt="edit assets" src={isAnySelected(values.assetSelections) ? editIcon : editIconGrey} />
                  </AppIconButton>
                  <AppButtonLink to={`${pathname}/add`}>{t('clients')('addAsset')}</AppButtonLink>
                </>
              }
              hideShowArchived
            />

            <FieldArray
              name="assetSelections"
              render={() => (
                <AppTable
                  data={assets}
                  loading={loading}
                  noDataMessage={t('clients')('noAssets')}
                  columns={[
                    {
                      headerTemplate: (
                        <AppCheckboxField
                          name="selectAll"
                          onChange={(_event, checked) => {
                            setFieldValue(
                              'assetSelections',
                              values.assetSelections.map((asset) => ({
                                ...asset,
                                selected: checked,
                              })),
                            );
                          }}
                        />
                      ),
                      key: 'selection',
                      template: function assetSelection(row, index) {
                        return <AppCheckboxField name={`assetSelections.${index}.selected`} />;
                      },
                    },
                    {
                      name: t('clients')('site'),
                      template: (row) => row.site.name,
                    },
                    {
                      name: t('clients')('department'),
                      template: (row) => row.department,
                    },
                    {
                      name: t('clients')('systemName_short'),
                      template: (row) => row.system.name,
                    },
                    {
                      name: t('clients')('systemType'),
                      template: (row) => t('systemTypes')(row.system.type),
                    },
                    {
                      name: t('clients')('assetName'),
                      template: (row) => row.name,
                    },
                    {
                      name: t('clients')('assetType'),
                      template: (row) => t('assetTypes')(row.type),
                    },
                    {
                      name: t('clients')('assetOwner'),
                      template: (row) => row.owner.name,
                    },
                    {
                      name: t('clients')('drawingId'),
                      template: (row) => row.drawings.map((drawing) => drawing.drawingId).join(', '),
                    },
                    {
                      name: t('common')('status'),
                      template: function assetStatus(row) {
                        return <AppTableStatus isArchived={row.status === AssetStatus.ARCHIVED} />;
                      },
                    },
                    {
                      key: 'actions',
                      link: null,
                      template: function ActionsTemplate(row) {
                        return (
                          <ActionsButton
                            actions={[
                              {
                                title: 'Edit Details',
                                action: () => {
                                  navigate(`${pathname}/update`, {
                                    state: {
                                      assetIdsToUpdate: [row.id],
                                    }
                                  })
                                },
                              },
                              {
                                title:
                                  switcher(row.status, [
                                    [AssetStatus.ACTIVE, 'Archive'],
                                    [AssetStatus.ARCHIVED, 'Activate'],
                                  ]) || 'Archive',
                                action: async (setLoading) => {
                                  setLoading(true);
                                  await updateStatus({
                                    variables: {
                                      id: row.id,
                                      status: switcher(row.status, [
                                        [AssetStatus.ACTIVE, AssetStatus.ARCHIVED],
                                        [AssetStatus.ARCHIVED, AssetStatus.ACTIVE],
                                      ])!,
                                    },
                                  });
                                  setLoading(false);
                                },
                              },
                            ]}
                          />
                        );
                      },
                    },
                  ]}
                />
              )}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ClientAssetsPage;
