import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'stores';
import {ClientContainer} from './containers/Client';
import {ContractorContainer} from './containers/Contractor';
import {PmContainer} from './containers/Pm';
import {TechnicianContainer} from './containers/Technician';
import {OperativeContainer} from './containers/Operative';
import DefaultContainer from './containers/Default';
import RequireAuth from 'components/RequireAuth/RequireAuth';
import { Navigate, Route, Routes } from 'react-router';
import AuthContainer from 'containers/Auth/AuthContainer';

const RootRoutes: React.FC = observer(() => {
  const { authStore } = useStores();
  const redirect =  authStore.fallbackRedirect;
  
  return (
    <Routes>
      <Route path="/auth" >
        <Route index element={<AuthContainer />}/>
        <Route path="*" element={<AuthContainer />}/>
      </Route>
      <Route path="/client/:clientId">
        <Route index element={
          <DefaultContainer>
            <RequireAuth>
              <ClientContainer/>
            </RequireAuth>
          </DefaultContainer>
        } />
        <Route path="*"
          element={
            <DefaultContainer>
              <RequireAuth>
                <ClientContainer/>
              </RequireAuth>
            </DefaultContainer>
          }
        ></Route>
      </Route>

      <Route path="/contractor/:contractorId">
        <Route index element={
          <DefaultContainer>
            <RequireAuth>
              <ContractorContainer/>
            </RequireAuth>
          </DefaultContainer>
        } />
        <Route path="*"
          element={
            <DefaultContainer>
              <RequireAuth>
                <ContractorContainer/>
              </RequireAuth>
            </DefaultContainer>
          }
        ></Route>
      </Route>

      <Route path="/operative">
        <Route index element={
          <DefaultContainer>
            <RequireAuth>
              <OperativeContainer/>
            </RequireAuth>
          </DefaultContainer>
        } />
        <Route path="*"
          element={
            <DefaultContainer>
              <RequireAuth>
                <OperativeContainer/>
              </RequireAuth>
            </DefaultContainer>
          }
        ></Route>
      </Route>
      <Route path="/pm">
        <Route index element={
          <DefaultContainer>
            <RequireAuth>
              <PmContainer/>
            </RequireAuth>
          </DefaultContainer>
        } />
        <Route path="*"
          element={
            <DefaultContainer>
              <RequireAuth>
                <PmContainer/>
              </RequireAuth>
            </DefaultContainer>
          }
        ></Route>
      </Route>
      <Route path="/technician">
        <Route index element={
          <DefaultContainer>
            <RequireAuth>
              <TechnicianContainer/>
            </RequireAuth>
          </DefaultContainer>
        } />
        <Route path="*"
          element={
            <DefaultContainer>
              <RequireAuth>
                <TechnicianContainer/>
              </RequireAuth>
            </DefaultContainer>
          }
        ></Route>
      </Route>
      <Route path="*" element={<Navigate to={redirect} replace/>} />
    </Routes>
  );
});
export default RootRoutes;
