import { NotificationScheduleType } from 'models/graphql';

const notificationSchedule = [
  NotificationScheduleType.WEEK1,
  NotificationScheduleType.DAY3,
  NotificationScheduleType.DAY1,
  NotificationScheduleType.NON_COMPLIANCE,
];

export default notificationSchedule;
