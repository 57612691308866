import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppDateField, AppFormControlLabel, AppSearchField, AppSelectField, AppSwitch } from 'components/form';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import { Formik } from 'formik';
import React, { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      alignItems: 'inherit',
      marginBottom: theme.spacing(0),
    },
  },
  filterTypes: {
    flexWrap: 'wrap',
    '& .MuiBox-root': {
      padding: theme.spacing(1, 1.5, 0, 0),
    },
    [theme.breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      alignItems: 'inherit',
      '& .MuiBox-root': {
        padding: theme.spacing(0, 0, 1, 0),
      },
    },
  },
  filterRight: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down(BREAKPOINT)]: {
      marginLeft: 0,
      padding: theme.spacing(1, 0),
    },
  },
  newButton: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(BREAKPOINT)]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  filterShowArchived: {
    marginTop: theme.spacing(3),
    '& .MuiSwitch-root': {
      marginLeft: theme.spacing(1),
    },
  },
  searchField: {
    width: theme.sizes.containerWidth,
    maxWidth: '100%',
    [theme.breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  },
}));

interface FormValues {
  search: string;
  showArchived: boolean;
  type?: string;
  status?: string;
  systemType?: string;
  systemRef?: string;
  asset?: string;
  owner?: string;
  responsible?: string;
  approval?: string;
  start?: Date | null;
  end?: Date | null;
  system?: string;
  compliant?: string;
}

interface Props {
  searchPlaceholder: string;
  searchValue?: string;
  addNewButton?: ReactNode;
  hideShowArchived?: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
  filters?: {
    name: string;
    options: {
      default?: string;
      array: (string | { id: string; name: string })[];
      key: (value: string | { id: string; name: string }) => string;
      value: (value: string | { id: string; name: string }) => string;
      template: (value: string | { id: string; name: string }) => string;
    };
  }[];
  dateFilters?: {
    name: 'start' | 'end';
    placeholder: string;
    default?: Date;
    minValue?: Date;
  }[];
}

const AppTableFilters: React.FC<Props> = ({
  onSubmit,
  addNewButton,
  hideShowArchived,
  searchPlaceholder,
  searchValue,
  filters,
  dateFilters,
}) => {
  const { filter, searchField, filterRight, filterTypes, newButton, filterShowArchived } = useStyles();
  return (
    <Formik<FormValues>
      initialValues={{
        search: searchValue || '',
        showArchived: false,
        type: filters?.find((_f) => _f.name === 'type')?.options.default || 'all',
        status: filters?.find((_f) => _f.name === 'status')?.options.default || 'all',
        asset: filters?.find((_f) => _f.name === 'asset')?.options.default || 'all',
        systemType: filters?.find((_f) => _f.name === 'systemType')?.options.default || 'all',
        systemRef: filters?.find((_f) => _f.name === 'systemRef')?.options.default || 'all',
        owner: filters?.find((_f) => _f.name === 'owner')?.options.default || 'all',
        system: filters?.find((_f) => _f.name === 'system')?.options.default || 'all',
        compliant: filters?.find((_f) => _f.name === 'compliant')?.options.default || 'all',
        responsible: filters?.find((_f) => _f.name === 'responsible')?.options.default || 'all',
        approval: filters?.find((_f) => _f.name === 'approval')?.options.default || 'all',
        start: dateFilters?.find((_f) => _f.name === 'start')?.default || null,
        end: dateFilters?.find((_f) => _f.name === 'end')?.default || null,
      }}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
    >
      {({ submitForm, setFieldValue }) => (
        <>
          <div className={filter}>
            <AppSearchField
              name="search"
              placeholder={searchPlaceholder}
              onCommit={() => submitForm()}
              className={searchField}
            />
            <div className={filterRight}>
              <div className={newButton}>{addNewButton}</div>

              {!hideShowArchived && (
                <div>
                  <AppFormControlLabel
                    control={<AppSwitch name="showArchived" onChange={() => submitForm()} />}
                    labelPlacement="start"
                    label="Show archived"
                    className={filterShowArchived}
                  />
                </div>
              )}
            </div>
          </div>
          {(filters || dateFilters) && (
            <div className={`${filter} ${filterTypes}`}>
              {filters &&
                filters.map((_filter) => (
                  <Box key={uuidv4()}>
                    <AppSelectField<string | { id: string; name: string }>
                      name={_filter.name}
                      options={_filter.options}
                      onChange={submitForm}
                      addInnerPaddingRight
                    />
                  </Box>
                ))}
              {dateFilters &&
                dateFilters.map((_dateFilter) => (
                  <Box key={uuidv4()} sx={{
                    pr: 1.5
                  }}>
                    <AppDateField
                      name={_dateFilter.name}
                      placeholder={_dateFilter.placeholder}
                      minDate={_dateFilter.minValue || new Date()}
                      onChange={(newValue) => {
                        setFieldValue(_dateFilter.name, newValue);
                        submitForm();
                      }}
                      showCancelIcon
                      required
                    />
                  </Box>
                ))}
            </div>
          )}
        </>
      )}
    </Formik>
  );
};

export default AppTableFilters;
