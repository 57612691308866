import { Box, Button, ButtonProps, CircularProgress, Theme } from '@mui/material';
import { WithStyles, createStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';

import cn from 'classnames';
import AnimateWidth from 'components/animations/AnimateWidth';
import React from 'react';

export interface AppButtonProps extends Omit<ButtonProps, 'color'> {
  color?: 'primary' | 'inherit' | 'error' | 'neutral' | 'warning';
  inProgress?: boolean;
  short?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: theme.sizes.button.height,
      minWidth: theme.sizes.button.minWidth,
      padding: `0 ${theme.spacing(4)}`,
      borderRadius: theme.shape.borderRadius * 2,
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey['50'],
      boxShadow: 'none',
      transition: theme.transitions.create(['background', 'color']),
      '&:hover': {
        boxShadow: 'none',
      },
      '&.MuiButton-outlined': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&.Mui-disabled': {
          color: theme.palette.grey['400'],
          borderColor: theme.palette.grey['400'],
        },
      },
      '&.in-progress.Mui-disabled': {
        color: theme.palette.primary.main,
        background: theme.palette.grey['300'],
      },
      '&.error': {
        background: theme.palette.error.main,
      },
      '&.neutral': {
        borderColor: 'rgba(0, 0, 0, 0.54) !important',
        color: 'rgba(0, 0, 0, 0.54) !important',
      },
      '&.warning': {
        background: theme.colors.orange,
      },
      '&.short': {
        padding: `0 ${theme.spacing(2)}`,
      },
    },
  });

const AppButton: React.FC<AppButtonProps & WithStyles<typeof styles>> = React.forwardRef(function AppButton(
  { inProgress, children, classes, variant, color, short, ...forwardProps },
  ref,
) {
  let forwardColor: AppButtonProps['color'];
  if (color === 'error' || color === 'neutral' || color === 'warning') forwardColor = undefined;
  else if (color) forwardColor = color;
  else forwardColor = 'primary'; // default color: primary

  return (
    <Button
      {...forwardProps}
      ref={ref}
      variant={variant || 'contained'} // default variant: contained
      color={forwardColor}
      disabled={inProgress ? true : forwardProps.disabled}
      className={cn(forwardProps.className, classes.root, {
        short,
        'in-progress': inProgress,
        error: color === 'error',
        neutral: color === 'neutral',
        warning: color === 'warning',
      })}
    >
      <AnimateWidth visible={!!inProgress}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
          <CircularProgress size={16} thickness={5} color="primary" />
        </Box>
      </AnimateWidth>
      <span style={{ marginTop: '3.5px' }}>{children}</span>
    </Button>
  );
});

export default withStyles(styles)(AppButton);
