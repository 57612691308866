import { FormContent, FormContentType } from './assetFormContent.interface';

export function validateFormValues(
  formValues: Record<string, string | string[] | null>,
  formTemplateContent: FormContent,
): Record<string, 'mandatory' | 'not_in_template'> {
  const errors: Record<string, 'mandatory' | 'not_in_template'> = {};

  formTemplateContent.forEach((field) => {
    const { parameterName } = field;
    if (field.type === FormContentType.FREQUENCY) return;

    if (field.type === FormContentType.FILES) {
      const formValue = formValues[parameterName];
      if ((!formValue || !formValue.length) && field.mandatory) errors[field.parameterName] = 'mandatory';
    }

    if (!formValues[parameterName] && field.mandatory) errors[parameterName] = 'mandatory';
  });

  Object.keys(formValues).forEach((fieldName) => {
    if (!formTemplateContent.find((field) => field.parameterName === fieldName)) errors[fieldName] = 'not_in_template';
  });

  return errors;
}

export function doesValueComply(
  parameterName: string,
  value: string | string[] | null | undefined,
  formTemplateContent: FormContent,
): boolean | null {
  // NULL if no comply value is defined,
  // otherwise TRUE if value is compliant and FALSE if its not
  const formTemplateField = formTemplateContent.find((field) => field.parameterName === parameterName);
  if (!formTemplateField) throw new Error(`field for parameterName ${parameterName} was not found`);

  if (formTemplateField.type === FormContentType.TEXT) {
    if (!formTemplateField.comply) return null;
    return formTemplateField.value === value;
  }

  if (formTemplateField.type === FormContentType.NUMERIC) {
    if (!formTemplateField.comply) return null;
   //const valueNum = parseInt((value as string) || '', 10);
   const valueNum = parseFloat((value as string));
    if (Number.isNaN(valueNum)) return false;
    //return parseInt(formTemplateField.minValue, 10) <= valueNum && parseInt(formTemplateField.maxValue, 10) >= valueNum;
    return parseFloat(formTemplateField.minValue) <= valueNum && parseFloat(formTemplateField.maxValue) >= valueNum;
  }

  if (formTemplateField.type === FormContentType.MULTIVALUE || formTemplateField.type === FormContentType.BOOLEAN) {
    const complyValues = formTemplateField.values
      .filter((fieldValue) => fieldValue.comply)
      .map((fieldValue) => fieldValue.value);
    if (!complyValues.length) return null;
    return value ? complyValues.includes(value as string) : false;
  }

  if (formTemplateField.type === FormContentType.MULTISELECT) {
    if (Array.isArray(value)) {
      const complyValues = formTemplateField.values
        .filter((fieldValue) => fieldValue.comply)
        .map((fieldValue) => fieldValue.value);
      if (!complyValues.length) return null;
      if (!value.length) return false;
      const nonComplyValues = formTemplateField.values
        .filter((fieldValue) => !fieldValue.comply)
        .map((fieldValue) => fieldValue.value);
      return !value.some((a) => nonComplyValues.includes(a));
    }
  }

  return null;
}

export function doesValuesComply(
  values: Record<string, string[] | string | null | undefined> | null | undefined,
  formTemplateContent: FormContent,
): boolean | null {
  let compliant: boolean | null = null;

  formTemplateContent.forEach((parameter) => {
    if (compliant === false) return; // if one parameter is non-compliant, we mark the whole group of values non-compliant

    if (
      parameter.type === FormContentType.TEXT ||
      parameter.type === FormContentType.NUMERIC ||
      parameter.type === FormContentType.BOOLEAN ||
      parameter.type === FormContentType.MULTIVALUE ||
      parameter.type === FormContentType.MULTISELECT
    ) {
      const parameterComplient = doesValueComply(
        parameter.parameterName,
        values ? values[parameter.parameterName] : undefined,
        formTemplateContent,
      );
      if (parameterComplient === false) compliant = false;
      else if (parameterComplient === true) compliant = true;
    }
  });

  return compliant;
}