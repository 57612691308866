import gql from 'graphql-tag';

const GET_SITE_ASSETS = gql`
  query assetsForSite(
    $siteId: ID!
    $search: String
    $type: AssetType
    $systemType: SystemType
    $systemRef: String
    $owner: ID
  ) {
    site(id: $siteId) {
      id
      assets(search: $search, type: $type, systemType: $systemType, systemRef: $systemRef, owner: $owner) {
        id
        site {
          id
          name
        }
        upcomingWorks {
          id
          type
          deadline
          idReadable
        }
        recentWorks {
          id
          type
          deadline
          idReadable
        }
        department
        system {
          id
          type
          name
        }
        name
        type
        createdAt
        owner {
          id
          name
          picture {
            srcUrl
          }
        }
        status
        coordinates {
          longitude
          latitude
        }
        additionalInformation
        expireOption
        expiration
        drawings {
          id
          drawingId
          file {
            id
            originalName
            extension
          }
        }
        compliant
        pumpInfo {
          id
          name
          pump1
          pump2
          temp
          batt
          tankLevel
          pump1runtime
          pump2runtime
        }
      }
    }
  }
`;

export const GET_SITE_ASSETS_PAGED = gql`
  query assetsForSitePaged(
    $siteId: ID!
    $pagination: PaginationInput
    $search: String
    $type: AssetType
    $systemType: SystemType
    $systemRef: String
    $owner: ID
  ) {
    site(id: $siteId) {
      id
      pageassets(pagination:$pagination, search: $search, type: $type, systemType: $systemType, systemRef: $systemRef, owner: $owner) 
      {
        nodes {
          id
          site {
            id
            name
          }
          upcomingWorks {
            id
            type
            deadline
            idReadable
          }
          recentWorks {
            id
            type
            deadline
            idReadable
          }
          department
          system {
            id
            type
            name
          }
          name
          type
          createdAt
          owner {
            id
            name
            picture {
              srcUrl
            }
          }
          status
          coordinates {
            longitude
            latitude
          }
          additionalInformation
          expireOption
          expiration
          drawings {
            id
            drawingId
            file {
              id
              originalName
              extension
            }
          }
          compliant
          pumpInfo {
            id
            name
            pump1
            pump2
            temp
            batt
            tankLevel
            pump1runtime
            pump2runtime
          }
        }
        totalNodes
        page
        totalPages
      }

    }
  }
`;



export const GET_SITE_DRAWINGS = gql`
  query drawingsForSite(
    $siteId: ID!
    $search: String
    $type: AssetType
    $systemRef: String
    $systemType: SystemType
    $hasAsset: Boolean
  ) {
    site(id: $siteId) {
      id
      drawings(assetType: $type, search: $search, systemRef: $systemRef, systemType: $systemType, hasAsset: $hasAsset) {
        id
        drawingId
        site {
          id
          name
        }
        file {
          id
          originalName
          extension
        }
        systemType
        assets(onlyActive: true) {
          type
          id
          system {
            name
            id
          }
        }
      }
    }
  }
`;

export const GET_ASSET_FILTER_BY_SITE = gql`
  query getAssetFilterBySite($siteId: ID!) {
    assetFilter(siteId: $siteId) {
      assetsTypes
      systemTypes
      systemRefs
      owners {
        id
        name
      }
    }
  }
`;

export const GET_ASSET_FILTER_BY_USER = gql`
  query getAssetFilterByUser($userId: ID!) {
    assetFilter(userId: $userId) {
      assetsTypes
      systemTypes
      owners {
        id
        name
      }
    }
  }
`;

export default GET_SITE_ASSETS;
