import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const UPLOAD_REQUEST = gql`
  mutation uploadRequest($fileName: String!, $public: Boolean!) {
    uploadRequest(fileName: $fileName, public: $public) {
      url
      srcurl
      id
      extension
    }
  }
`;

export const DOWNLOAD_REQUEST = gql`
  mutation downloadRequest($fileId: String!) {
    downloadRequest(fileId: $fileId) {
      url
    }
  }
`;

export const DOWNLOAD_REQUEST_WITH_SRC = gql`
  mutation downloadRequestWithSrc($fileId: String!) {
    downloadRequest(fileId: $fileId) {
      url
      extension
    }
  }
`;
