import { useMutation, useQuery } from '@apollo/client';
import { Box, Theme, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AnimateHeight } from 'components/animations';
import AppButton from 'components/AppButton';
import CircularProgressCentered from 'components/CircualProgressCentered';
import AssetComplianceIcon from 'components/complianceIcons/AssetComplianceIcon';
import { AppForm, AppSelectMultipleField } from 'components/form';
import WarningDialog from 'components/WarningDialog';
import { UPDATE_OPERATIVE_TASK } from 'containers/Client/Tasks/graphql';
import { GET_SITE_ASSETS_AND_FORM_TYPES } from 'containers/Operative/Tasks/graphql';
import { Formik } from 'formik';
import { motion } from 'motion/react';
import { observer } from 'mobx-react';
import {
  assetsAndFormTypesForSite,
  assetsAndFormTypesForSiteVariables,
  SystemType,
  taskAssets,
  TaskAssetStatus,
  taskAssetsVariables,
  taskAssets_task_assets as TaskAsset,
  taskDetails,
  taskDetailsVariables,
  TaskStatus,
  TaskType,
  taskUpdate,
  taskUpdateVariables,
  AssetType,
} from 'models/graphql';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useStores } from 'stores';
import useText from 'texts/useText.hook';
import cn from 'utils/cn';
import AppSelectField from 'components/form/AppSelectField';
import { GET_TASK_ASSETS, GET_TASK_DETAILS } from '../graphql';
import openDetailsArrow from 'assets/openDetailsArrow.svg';
import editFormNoOutlineIcon from 'assets/editFormNoOutlineIcon.svg';
import blackBorderMapIcon from 'assets/blackBorderMapIcon.svg';
import { useNavigate, useParams, useLocation } from 'react-router';


const LIST_ITEM_HEIGHT = '5em';

const useStyles = makeStyles((theme: Theme) => ({
  groupLabel: {
    cursor: 'pointer',
    fontSize: theme.fontSizes[16],
  },
  link: {
    cursor: 'pointer',
    width: 'fit-content',
    fontSize: theme.fontSizes[12],
  },
  label: {
    fontSize: theme.fontSizes[16],
  },
  detail: {
    color: theme.brandColors.grey,
    fontSize: theme.fontSizes[12],
  },
  iconContainerCircle: {
    width: '3em',
    height: '3em',
    border: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey['300'],
    borderRadius: '3em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '0.5em',
    '& > button': {
      border: '0',
      cursor: 'pointer',
    },
  },
  image: {
    '&.hidden': {
      visibility: 'hidden',
    },
    '&.visible': {
      visibility: 'visible',
    },
  },
  listItemContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: LIST_ITEM_HEIGHT,
  },
  listItemLeftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minHeight: LIST_ITEM_HEIGHT,
  },
  listItemRightColumn: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    minHeight: LIST_ITEM_HEIGHT,
  },
  centeredFlexEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  completedItems: {
    paddingRight: '1em',
  },
  listRow: {
    '&.sub': {
      backgroundColor: theme.palette.grey['100'],
    },
    '&.completed': {
      backgroundColor: '#e2ffdc',
    },
  },
  buttons: {
    marginTop: '20px',
  },
  formMessage: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontSize: theme.fontSizes[16],
  },
}));

const ListRow: React.FC<{
  onClick: () => void;
  main?: boolean;
  status?: string;
}> = ({ main, status, children, onClick }) => {
  const classes = useStyles();
  return (
    <Box
      className={cn(classes.listRow, main ? '' : 'sub', status === TaskAssetStatus.COMPLETED ? 'completed' : '')}
      onClick={onClick}
      sx={{
        px: 2.5,
        py: 3.5,
        padding: "15px 15px !important",
        border: 1,
        borderColor: "grey.300"
      }}>
      {children}
    </Box>
  );
};

const groupTaskAssetsBySystem = R.groupBy((taskAsset: TaskAsset) => taskAsset.asset.system.id);

const TaskAssetsPage: React.FC<{ taskStatus: TaskStatus; taskType: TaskType }> = ({ taskStatus, taskType }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { contextStore } = useStores();
  const systemTypeContext = contextStore.systemType;

  const [selectedAssetType, setSelectedAssetType] = useState<AssetType | null>(null);
  const [warning, setWarning] = useState(false);
  const { t } = useText('tasks', 'common', 'systemTypes', 'assetTypes', 'taskTypes');
  const { taskId } = useParams<{ taskId: string }>();
  const { data, loading, refetch } = useQuery<taskAssets, taskAssetsVariables>(GET_TASK_ASSETS, {
    variables: { id: taskId },
  });
  const taskAssetsBySystem = data && groupTaskAssetsBySystem(data.task.assets);
  const { data: taskDetailsData, loading: taskDetailsLoading } = useQuery<taskDetails, taskDetailsVariables>(
    GET_TASK_DETAILS,
    {
      variables: { id: taskId },
    },
  );

  const { data: assetsData, loading: assetsDataLoading } = useQuery<
    assetsAndFormTypesForSite,
    assetsAndFormTypesForSiteVariables
  >(GET_SITE_ASSETS_AND_FORM_TYPES, {
    variables: {
      siteId: taskDetailsData?.task.site.id || '',
    },
  });

  const [updateTask] = useMutation<taskUpdate, taskUpdateVariables>(UPDATE_OPERATIVE_TASK);

  const assetTypes =
    assetsData?.site.formTemplates
      .filter((formTemplate) => formTemplate.formType === taskType)
      .map((formTemplate) => formTemplate.assetType) || [];

  const assets =
    (assetsData && assetsData?.site.assets.filter((assetTemplate) => assetTypes.includes(assetTemplate.type))) || [];
  const assetTypesUnique = assets && R.uniq(assets.map((asset) => asset.type));

  const assetsByType = selectedAssetType
    ? assets.filter((assetTemplate) => assetTemplate.type === selectedAssetType)
    : [];

  const onSelectorChange = (assetIds: string[], formType: TaskType) => {
    updateTask({
      variables: {
        id: taskDetailsData?.task.id || '',
        changes: {
          type: formType,
          assetIds,
        },
      },
    }).then(() => refetch());
  };

  if (loading || taskDetailsLoading || assetsDataLoading) return <CircularProgressCentered />;

  const sortAssetTypesAsc = R.sortBy(R.compose(R.toLower, (systemType: SystemType) => t('systemTypes')(systemType)));
  const sortAssetsAsc = R.sortBy(R.compose(R.toLower, R.pathOr('', ['asset', 'name'])));

  if (!data?.task.assets.length && assetTypes) {
    return (
      <Formik
        initialValues={{
          site: '',
          assetIds: [],
        }}
        onSubmit={({ assetIds }) => {
          onSelectorChange(assetIds, taskType);
        }}
      >
        {({ isSubmitting, setFieldValue, handleSubmit }) => {
          return (
            <AppForm handleSubmit={handleSubmit} key={1} style={{ marginTop: 16 }}>
              <Box sx={{
                marginTop: 2
              }}>
                <AppSelectField
                  label="Asset type"
                  name="assetType"
                  required
                  options={{
                    array: assetTypesUnique,
                    value: (assetType) => assetType,
                    key: (assetType) => assetType,
                    template: (assetType) => t('assetTypes')(assetType),
                  }}
                  value={selectedAssetType || ''}
                  onChange={(assetType) => {
                    setFieldValue('assetIds', []);
                    setSelectedAssetType(assetType);
                  }}
                />
              </Box>
              <Box sx={{
                marginTop: 1
              }}>
                <AppSelectMultipleField
                  label={t('tasks')('asset_optionalPlural')}
                  name="assetIds"
                  required
                  disabled={!selectedAssetType}
                  options={{
                    array: assetsByType,
                    value: (assetType) => assetType.id,
                    key: (assetType) => assetType.id,
                    template: (assetType) => assetType.name,
                  }}
                />
              </Box>
              <AppButton className={classes.buttons} type="submit" color="primary" inProgress={isSubmitting}>
                {t('common')('save')}
              </AppButton>
            </AppForm>
          );
        }}
      </Formik>
    );
  }

  return taskAssetsBySystem ? (
    <>
      {sortAssetTypesAsc(Object.keys(taskAssetsBySystem)).map((systemId) => (
        <React.Fragment key={systemId}>
          <ListRow
            main
            onClick={async () => {
              if (systemTypeContext === systemId) {
                contextStore.setSystemType(undefined);
              } else {
                contextStore.setSystemType(systemId);
              }
            }}
          >
            <div className={classes.listItemContent}>
              <div>
                <div className={classes.groupLabel}>
                  {taskAssetsBySystem[systemId][0].asset.system.name}
                  <div className={classes.detail}>
                    {t('systemTypes')(taskAssetsBySystem[systemId][0].asset.system.type)}
                  </div>
                </div>
              </div>
              <div className={classes.centeredFlexEnd}>
                <div className={classes.completedItems}>{`${
                  taskAssetsBySystem[systemId].filter((taskAsset) => taskAsset.status === TaskAssetStatus.COMPLETED)
                    .length
                }/${taskAssetsBySystem[systemId].length}`}</div>
                <motion.div
                  initial={{ rotate: 0 }}
                  animate={systemTypeContext === systemId ? { rotate: 180 } : { rotate: 0 }}
                  transition={theme.framerTransitions.normal}
                >
                  <img src={openDetailsArrow} alt="icon" />
                </motion.div>
              </div>
            </div>
          </ListRow>
          <AnimateHeight visible={systemTypeContext === systemId}>
            {sortAssetsAsc(taskAssetsBySystem[systemId]).map((taskAsset) => (
              <ListRow key={taskAsset.asset.id} status={taskAsset.status} onClick={async () => ({})}>
                <div className={classes.listItemContent}>
                  <div>
                    <div className={classes.listItemLeftColumn}>
                      <div className={classes.label}>{taskAsset.asset.name}</div>
                      <div className={classes.detail}>{t('assetTypes')(taskAsset.asset.type)}</div>
                      <Typography
                        variant="body1"
                        className={classes.link}
                        onClick={() => {
                          navigate(`${pathname}/${taskAsset.asset.id}/info`);
                        }}
                        sx={{
                          color: "primary"
                        }}
                      >
                        <u>{t('tasks')('moreInfo')}</u>
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.listItemRightColumn}>
                    <div className={classes.centeredFlexEnd}>
                      {taskStatus === TaskStatus.CONTRACTOR_ACCEPTED ||
                      taskStatus === TaskStatus.IN_PROGRESS ||
                      (taskType === TaskType.SAMPLING && taskStatus === TaskStatus.COMPLETED) ? (
                        <div className={classes.iconContainerCircle}>
                          <button
                            onClick={() => {
                              if (taskStatus !== TaskStatus.IN_PROGRESS && taskStatus !== TaskStatus.COMPLETED) {
                                setWarning(true);
                              } else {
                                navigate(`${pathname}/${taskAsset.id}/form`);
                              }
                            }}
                            type="button"
                          >
                            <img src={editFormNoOutlineIcon} alt="icon" />
                          </button>
                        </div>
                      ) : null}
                      {taskAsset.asset.drawings.length > 0 && (
                        <div className={classes.iconContainerCircle}>
                          <button
                            onClick={() => {
                              navigate(`${pathname}/${taskAsset.id}/drawings/${taskAsset.asset.drawings[0].id}`);
                            }}
                            type="button"
                          >
                            <img src={blackBorderMapIcon} alt="icon" />
                          </button>
                        </div>
                      )}
                    </div>
                    <div>
                      <AssetComplianceIcon asset={taskAsset} small />
                    </div>
                  </div>
                </div>
              </ListRow>
            ))}
          </AnimateHeight>
        </React.Fragment>
      ))}
      <WarningDialog
        open={warning}
        onClose={() => setWarning(false)}
        title="Cannot access forms"
        descriptionText="You first need to start the works timer to fill out forms."
      />
    </>
  ) : (
    <div>{t('tasks')('noAssets')}</div>
  );
};

export default observer(TaskAssetsPage);
