/* eslint-disable no-param-reassign */
import imageFileExtensions from 'constants/imageFileExtensions';
import useGetStaticFields from 'constants/staticFields';
import useGetBase64ByUrl from 'utils/useGetBase64ByFileId';
import { Workbook } from 'exceljs';
import { FormContent } from 'shared/assetFormContent.interface';
import useGetHandlersForFields from 'constants/fieldTypeHandlers';
import {
  checkIsValueToken,
  getFieldType,
  getHandlerByType,
  getImageTokenPropertyFromToken,
  getPathToField,
  getStaticFieldByTokenName,
  getTokenImageForFile,
  getValueByPath,
} from './utilsForMapping';

const useSingleReportMapper = () => {
  const getBase64ByUrl = useGetBase64ByUrl();
  const staticFields = useGetStaticFields();
  const fieldHandlers = useGetHandlersForFields();
  return async (
    workbook: Workbook,
    // eslint-disable-next-line @typescript-eslint/ban-types
    taskInfo: Record<string, object | string | boolean | null> | null,
  ) => {
    const promises: Promise<unknown>[] = [];
    type imageExtensions = 'jpeg' | 'png' | 'gif';
    type imageBase64 = string | undefined;
    workbook.eachSheet((currentSheet) => {
      currentSheet.eachRow((currentRow, currentRowId) => {
        currentRow.eachCell(async (currentCell, currentCellId) => {
          const cellValue = currentCell.value;
          if (typeof cellValue === 'string') {
            const matches = checkIsValueToken(cellValue);
            const isCellWithToken = matches.length;
            matches.forEach((match) => {
              const { tokenName } = match;
              if (isCellWithToken && taskInfo) {
                const tokenNameForImage = getTokenImageForFile(tokenName);
                const pathToField = getPathToField(tokenNameForImage || tokenName, staticFields);
                if (tokenNameForImage) {
                  currentCell.value = '-';
                  const imageProperty = getImageTokenPropertyFromToken(tokenName);
                  const images = getValueByPath(pathToField, taskInfo);
                  const fileId = typeof images === 'string' ? images : images[imageProperty.numberOfImage];
                  const imageForPastingInWorkBook = getBase64ByUrl(fileId);
                  imageForPastingInWorkBook.then((response) => {
                    if (imageFileExtensions.includes(response?.extension || '')) {
                      const image = workbook.addImage({
                        base64: response?.imageInBase64 as imageBase64,
                        extension: response?.extension as imageExtensions,
                      });
                      currentSheet.addImage(image, {
                        tl: { col: currentCellId - 1, row: currentRowId - 1 },
                        ext: { width: imageProperty.width, height: imageProperty.height },
                      });
                      currentCell.value = '';
                    } else if (response?.url) {
                      currentCell.value = {
                        text: 'Download File',
                        hyperlink: response.url,
                      };
                      currentCell.font = {
                        color: { argb: 'FF0000FF' },
                      };
                    }
                  });
                  promises.push(imageForPastingInWorkBook);
                } else {
                  const staticField = getStaticFieldByTokenName(tokenName, staticFields);
                  let fieldHandler = staticField?.handlerFunction;

                  if (!staticField) {
                    const fieldType = getFieldType(tokenName, taskInfo.formTemplateContent as FormContent);
                    fieldHandler = getHandlerByType(fieldType, fieldHandlers)?.handlerFunction;
                  }

                  let tokenValue = getValueByPath(pathToField, taskInfo, fieldHandler);
                  const prevCellValue = `${currentCell.value}`;

                  if (tokenName === 'Responsible for Works' && tokenValue === '-') {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const taskContractor: any = taskInfo.contractorResponsible;
                    tokenValue = taskContractor ? taskContractor.companyName : tokenValue;
                  }

                  currentCell.value = prevCellValue.replace(`{{${match.tokenName}}}`, tokenValue || '-');
                  if (!currentCell.value) {
                    currentCell.value = '-';
                  }
                }
              }
            });
          }
        });
      });
    });
    return Promise.all(promises).then(() => {
      return workbook;
    });
  };
};

export default useSingleReportMapper;
