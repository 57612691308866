import React from 'react';
import compliantIcon from 'assets/compliantIcon.svg';
import nonCompliantIcon from 'assets/nonCompliantIcon.svg';
import alarmIcon from 'assets/alarmIcon.svg';
import alarmIconOrange from 'assets/alarmIconOrange.svg';
import { differenceInDays } from 'date-fns';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { completedTaskStatuses } from 'shared/taskStatusGroups';
import { alpha, Theme } from '@mui/material/styles';
import { TaskStatus, tasksForClient_site_tasks_nodes_assets as Assets } from 'models/graphql';

const useStyles = makeStyles(() => {
  return createStyles({
    icon: {
      display: 'inline-block',
      width: 17,
      maxWidth: 'initial',
    },
  });
});

const getCompliantFlag = (assets: Array<Assets>): boolean | null => {
  let compliant: boolean | null = null;
  let compliantCount = 0;

  assets.forEach((asset) => {
    if (asset.compliant) {
      if (compliant === null) {
        compliant = asset.compliant;
      } else {
        compliant = compliant && asset.compliant;
      }

      compliantCount += 1;
    } else {
      compliant = asset.compliant;
    }
  });

  if (compliant && compliantCount < assets.length) {
    compliant = null;
  }

  return compliant;
};

const calculateDayUntilDeadline = (deadline: string): number => differenceInDays(new Date(deadline), new Date());

const TaskComplianceIcon: React.FC<{
  task: { deadline: string | null; assets: Array<Assets> };
}> = ({ task: { deadline, assets } }) => {
  const styles = useStyles();

  const compliant = getCompliantFlag(assets);

  if (compliant === true) return <img alt="compliant" src={compliantIcon} className={styles.icon} />;
  if (compliant === false) {
    if (!deadline) return <></>;

    const dayUntilDeadline = calculateDayUntilDeadline(deadline);
    if (dayUntilDeadline > 7) return <></>;
    if (dayUntilDeadline > 3) return <img alt="due in 7 days" src={alarmIconOrange} className={styles.icon} />;
    if (dayUntilDeadline >= 0) return <img alt="due in 3 days" src={alarmIcon} className={styles.icon} />;
    return <img alt="non compliant" src={nonCompliantIcon} className={styles.icon} />;
  }
  return <></>;
};
export default TaskComplianceIcon;

export const taskComplianceColor = (
  theme: Theme,
  task: {
    deadline: string | null;
    compliant: boolean | null;
    status: TaskStatus;
  },
): string => {
  if (completedTaskStatuses.includes(task.status)) {
    return theme.palette.grey[400];
  }
  if (task.compliant === true || task.compliant === null) {
    return alpha(theme.palette.primary.main, 0.4);
  }
  return alpha(theme.palette.error.main, 0.4);
};
