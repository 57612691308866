import { DataValidation } from 'exceljs';

export default function excelValidationOfArray(
  array: string[],
  errorMessage: string,
  options: { errorTitle?: string; allowEmpty?: boolean } = {},
): DataValidation {
  return {
    type: 'list',
    formulae: [`"${array.join(',')}"`],
    showErrorMessage: true,
    allowBlank: options.allowEmpty,
    errorTitle: options.errorTitle || 'Invalid value',
    error: errorMessage,
  };
}
