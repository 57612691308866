import React, { useEffect } from 'react';
import cn from 'classnames';

import styleUtils from 'style/styleUtils';
import { useAnimation } from 'motion/react';
import Badge from 'components/Badge';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  navigationElementLink: {
    textAlign: 'center',
  },
  navigationElement: {
    position: 'relative',
    [theme.breakpoints.up(BREAKPOINT)]: {
      marginTop: theme.spacing(3),
      width: 129,
      height: 84,
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius * 2,
      '& $navigationElementIcon.white': {
        display: 'none',
      },
      '& $navigationElementIcon.grey': {
        display: 'block',
      },
      '$navigationElementLink.active &': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '& $navigationElementIcon.white': {
          display: 'block',
        },
        '& $navigationElementIcon.grey': {
          display: 'none',
        },
      },
    },
    [theme.breakpoints.down(BREAKPOINT)]: {
      height: theme.sizes.headerHeight - 30,
      width: 100,
      '& $navigationElementIcon.white': {
        display: 'none',
      },
      '& $navigationElementIcon.grey': {
        display: 'block',
      },
      '$navigationElementLink.active &': {
        // color: theme.palette.primary.main,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '& $navigationElementIcon.white': {
          display: 'block',
        },
        '& $navigationElementIcon.grey': {
          display: 'none',
        },
      },
    },
    ...styleUtils.columnCenterCenter,
    color: theme.palette.grey['600'],
  },
  navigationElementIcon: {
    [theme.breakpoints.up(BREAKPOINT)]: {
      marginBottom: theme.spacing(1),
    },
  },
  iconContainer: {
    [theme.breakpoints.down(BREAKPOINT)]: {
      ...styleUtils.columnCenterCenter,
      margin: theme.spacing(1),
      height: theme.sizes.mobileHeaderHeight / 2.5,
    },
  },
}));

interface Props {
  to: string;
  name: string;
  icon: string;
  activeIcon: string;
  badge?: string | number;
}

const DrawerLink: React.FC<Props> = ({ to, name, icon, activeIcon, badge }) => {
  const classes = useStyles();
  const badgeAnimation = useAnimation();
  useEffect(() => {
    if (badge) {
      badgeAnimation.start({ scale: [null, 2, 1] });
    } else {
      badgeAnimation.start({ scale: [null, 0] });
    }
  }, [badge, badgeAnimation]);

  return (
    <NavLink to={to} className={classes.navigationElementLink}>
      <div className={classes.navigationElement}>
        <div className={classes.iconContainer}>
          <img className={cn(classes.navigationElementIcon, 'grey')} alt={name} src={icon} />
          <img className={cn(classes.navigationElementIcon, 'white')} alt={name} src={activeIcon} />
        </div>
        {name}
        <Badge content={badge} small />
      </div>
    </NavLink>
  );
};

export default DrawerLink;
