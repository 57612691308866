import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { UserRequestAccessStatus } from 'models/graphql';
import switcher from 'utils/switcher';
import StatusBadge from './StatusBadge';

const UserRequestStatus: React.FC<{
  accessStatus: UserRequestAccessStatus;
}> = ({ accessStatus }) => {
  const theme = useTheme();

  return (
    (<StatusBadge
      state={
        switcher(accessStatus, [
          [
            UserRequestAccessStatus.ACCEPTED,
            {
              background: alpha(theme.palette.primary.main, 0.25),
              color: '#000',
            },
          ],
          [UserRequestAccessStatus.DENIED, { background: alpha('rgb(241, 85, 65)', 0.25), color: '#000' }],
        ]) || { background: alpha('rgb(62, 80, 180)', 0.25), color: '#000' }
      }
    >
      {accessStatus}
    </StatusBadge>)
  );
};

export default UserRequestStatus;
