import gql from 'graphql-tag';

export const GET_CONTRACTOR_HOME = gql`
  query contractorHome($id: ID!, $accessStatus: UserRequestAccessStatus, $upcoming: Boolean) {
    user(id: $id) {
      id
      clients(accessStatus: $accessStatus) {
        id
        companyName
        tasks(upcoming: $upcoming, contractorId: $id) {
          nodes {
            id
            type
            notes
            adhoc
            deadline
            urgent
            assets {
              asset {
                system {
                  name
                }
              }
              compliant
            }
            assetsCount
            compliant
            status
          }
        }
      }
    }
  }
`;

export const GET_TASKS = gql`
  query tasksForUser($userId: ID!, $dayDateRange: DayDateRange, $clientId: ID, $urgent: Boolean) {
    user(id: $userId) {
      id
      tasks(dayDateRange: $dayDateRange, clientId: $clientId, urgent: $urgent) {
        id
        type
        subform
        notes
        adhoc
        deadline
        status
        site {
          id
          companyName
        }
        assetsCount
        urgent
        assets {
          asset {
            system {
              name
            }
          }
          compliant
        }
        compliant
      }
      clients {
        id
        companyName
      }
    }
  }
`;
