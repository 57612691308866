import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

import Breadcrumb from './Breadcrumb';

const SitesNotFoundOrLoading: React.FC<{ path: string; name: string }> = ({ path, name }) => (
  <>
    <Breadcrumb to={path} name={name} />
    <LinearProgress />
    <Box sx={{
      py: 2
    }}>
      <Typography variant="h3">Sites are loading or were not found.</Typography>
    </Box>
  </>
);

export default SitesNotFoundOrLoading;
