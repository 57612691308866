import { TASK_COMMENT_FIELDS } from 'containers/shared/graphql';
import gql from 'graphql-tag';

export const GET_CLIENT_FOR_TASK_CREATION = gql`
  query getClientForTaskCreation($clientId: ID!, $siteId: ID!) {
    client(id: $clientId) {
      id
      modules
      projects
      requests(accessStatus: ACCEPTED) {
        id
        contractor {
          id
          companyName
          logo {
            id
            srcUrl
          }
        }
        workOrders {
          id
          expiracy
        }
      }
      users {
        id
        name
        role
        picture {
          id
          srcUrl
        }
      }
    }
    site(id: $siteId) {
      id
      name
      formTemplates {
        id
        formType
        assetType
        active
        subForm
      }
    }
  }
`; 

// Original
/* export const GET_CLIENT_FOR_TASK_CREATION = gql`
  query getClientForTaskCreation($clientId: ID!, $siteId: ID!) {
    client(id: $clientId) {
      id
      requests(accessStatus: ACCEPTED) {
        id
        contractor {
          id
          companyName
          logo {
            id
            srcUrl
          }
        }
        workOrders {
          id
          expiracy
        }
      }
      users {
        id
        name
        role
        picture {
          id
          srcUrl
        }
      }
    }
    site(id: $siteId) {
      id
      name
      assets {
        id
        name
        system {
          id
          type
        }
        type
      }
      formTemplates {
        id
        formType
        assetType
        active
        subForm
      }
    }
  }
`;  */

export const GET_TASK_FOR_EDITION = gql`
  query taskForEdition($taskId: ID!) {
    task(id: $taskId) {
      id
      idReadable
      status
      type
      notes
      tasktitle
      urgent
      internalResponsible {
        id
        name
      }
      contractorResponsible {
        id
        companyName
      }
      contractorResponsibleWorkOrderId
      approval {
        id
        name
      }
      attachments {
        id
        originalName
      }
      assets {
        id
        asset {
          id
        }
      }
      project {
        id
        name
        startdate
        enddate
      }
      deadline
      documentDeadline
      haveAnyFutureOccurence
      taskstart
      projectid
    }
  }
`;

export const CREATE_TASK = gql`
  mutation taskCreate($task: TaskCreateInput!) {
    taskCreate(task: $task) {
      id
    }
  }
`;

export const GET_TASKS_FIELD = gql`
  fragment tasksForClientFields on Task {
    id
    idReadable
    type
    subform
    approval {
      id
    }
    internalResponsible {
      id
      name
    }
    contractorResponsible {
      id
      companyName
    }
    assets {
      asset {
        system {
          name
        }
      }
      compliant
    }
    compliant
    contractorResponsibleWorkOrderId
    deadline
    documentDeadline
    status
  }
`;
export const GET_TASKS = gql`
  query tasksForClient(
    $siteId: ID!
    $search: String
    $pagination: PaginationInput
    $type: TaskType
    $status: TaskStatus
    $responsible: ID
    $approval: ID
    $deadlineStart: DateTime
    $deadlineEnd: DateTime
    $assets: AssetType
    $systemRef: ID
    $compliant: Boolean
  ) {
    site(id: $siteId) {
      id
      systems {
        id
        name
      }
      tasks(
        search: $search
        pagination: $pagination
        type: $type
        status: $status
        responsible: $responsible
        approval: $approval
        deadlineStart: $deadlineStart
        deadlineEnd: $deadlineEnd
        assets: $assets
        systemRef: $systemRef
        compliant: $compliant
      ) {
        nodes {
          ...tasksForClientFields
        }
        totalNodes
        page
        totalPages
      }
    }
  }
  ${GET_TASKS_FIELD}
`;

export const GET_TASK_FIELD = gql`
  fragment taskForClientFields on Task {
    id
    idReadable
    type
    internalResponsible {
      id
      name
    }
    contractorResponsible {
      id
      companyName
    }
    contractorResponsibleWorkOrderId
    approval {
      id
      name
      picture {
        id
        srcUrl
        originalName
      }
    }
    assignedAt
    notes
    tasktitle
    urgent
    type
    attachments {
      id
      originalName
      srcUrl
    }
    status
    assets {
      id
      compliant
      status
      updatedAt
      asset {
        id
        name
        system {
          id
          name
        }
      }
    }
    correctiveOf {
      id
      idReadable
    }
    corrective {
      id
      idReadable
    }
    deadline
    documentDeadline
    projectid
  }
`;

export const GET_TASK_OPERATIVE_FIELD = gql`
  fragment taskForOperativeFields on Task {
    id
    idReadable
    type
    internalResponsible {
      id
      name
    }
    contractorResponsible {
      id
      companyName
    }
    contractorResponsibleWorkOrderId
    approval {
      id
      name
      picture {
        id
        srcUrl
        originalName
      }
    }
    assignedAt
    notes
    tasktitle
    urgent
    type
    attachments {
      id
      originalName
      srcUrl
    }
    status
    assets {
      id
      compliant
      asset {
        id
        name
      }
    }
    correctiveOf {
      id
      idReadable
    }
    corrective {
      id
      idReadable
    }
    deadline
    documentDeadline
  }
`;

export const GET_TASK = gql`
  query taskForClient($taskId: ID!) {
    task(id: $taskId) {
      ...taskForClientFields
      timer {
        timeSpent
      }
    }
  }
  ${GET_TASK_FIELD}
`;

export const UPDATE_TASK = gql`
  mutation taskUpdate($id: ID!, $changes: TaskUpdateInput!) {
    taskUpdate(id: $id, changes: $changes) {
      id
      ...tasksForClientFields
      ...taskForClientFields
    }
  }
  ${GET_TASKS_FIELD}
  ${GET_TASK_FIELD}
`;

export const UPDATE_OPERATIVE_TASK = gql`
  mutation taskUpdateOperative($id: ID!, $changes: TaskUpdateInput!) {
    taskUpdate(id: $id, changes: $changes) {
      id
    }
  }
`;

export const CANCEL_TASK = gql`
  mutation taskCancel($taskId: ID!) {
    taskCancel(taskId: $taskId) {
      id
      status
    }
  }
`;

export const DELETE_TASK = gql`
  mutation deleteTask($taskId: ID!) {
    deleteTask(taskId: $taskId) {
      id
    }
  }
`;

export const ACCEPT_TASK = gql`
  mutation taskAccept($taskId: ID!, $rating: Int, $comment: String) {
    taskAccept(taskId: $taskId, rating: $rating, comment: $comment) {
      id
      status
      commentsWithArchived {
        ...taskCommentFields
      }
    }
  }
  ${TASK_COMMENT_FIELDS}
`;

export const REJECT_TASK = gql`
  mutation taskReject($taskId: ID!, $comment: String!, $rating: Int, $close: Boolean) {
    taskReject(taskId: $taskId, comment: $comment, rating: $rating, close: $close) {
      id
      status
      commentsWithArchived {
        ...taskCommentFields
      }
    }
  }
  ${TASK_COMMENT_FIELDS}
`;

export const CLOSE_TASK = gql`
  mutation taskClose($taskId: ID!) {
    taskClose(taskId: $taskId) {
      id
      status
    }
  }
`;

export const GET_CLIENT_USERS_CONTRACTORS = gql`
  query getClientUsersContractors($clientId: ID!) {
    client(id: $clientId) {
      id
      contractors(accessStatus: ACCEPTED) {
        id
        companyName
      }
      users {
        id
        name
      }
    }
  }
`;

export const TASK_REASSIGN = gql`
  mutation taskReassign($taskId: ID!) {
    taskReassign(taskId: $taskId) {
      id
      status
      contractorResponsibleWorkOrderId
      internalResponsible {
        id
      }
      contractorResponsible {
        id
      }
      contractorResponsibleUser {
        id
      }
      commentsWithArchived {
        id
      }
    }
  }
`;

export const GET_TASK_FILTER = gql`
  query getTaskFilter($id: ID!) {
    taskFilter(id: $id) {
      taskTypes
      systems {
        id
        name
      }
      responsibles {
        id
        name
      }
      contractors {
        id
        contactName
      }
      approvals {
        id
        name
      }
      statuses
      assets
    }
  }
`;
