import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { asyncMap } from 'utils/asyncArray';
import dropIcon from 'assets/dropIcon.svg';
import errorIcon from 'assets/errorIcon.svg';

const styles = () =>
  createStyles({
    dropZone: {
      width: '100%',
      height: '20vh',
      minHeight: '140px',
      backgroundColor: '#F3F4F9',
      border: '1px dashed rgba(0, 0, 0, 0.25)',
      boxSizing: 'border-box',
      textAlign: 'center',
      borderRadius: '8px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    activeStyle: {
      border: '2px dashed #4287F5',
    },
    acceptStyle: {
      border: '2px dashed #69CC84',
    },
    rejectStyle: {
      border: '2px dashed #F55142',
    },
    browseLink: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#69CC84',
      '&:active': {
        outline: '0',
      },
      '&:focus': {
        outline: '0',
      },
    },
    gridFileInput: {
      width: '100%',
      height: '100%',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
    },
    dropP: {
      marginBottom: '0',
      marginTop: '14px',
    },
    errorText: {
      color: '#F15541',
      fontSize: '12px',
      lineHeight: '1',
      marginLeft: '8px',
    },
    marginTop: {
      marginTop: '10px',
    },
  });
const useStyles = makeStyles(styles);

interface Props {
  onLoad: (file: File) => void;
  isLoading: boolean;
}

const XlsxFileInput: React.FC<Props> = ({ onLoad, isLoading }) => {
  const classes = useStyles();
  const [rejectOrActiveClass, setRejectOrActiveClass] = useState('');
  const [fileRejected, setFileRejected] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      asyncMap(acceptedFiles, async (file: File) => {
        onLoad(file);
      });
    },
    [onLoad],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    onDropRejected: () => {
      setRejectOrActiveClass(classes.rejectStyle);
      setFileRejected(true);
      // TODO: clear this timeout on component destruction
      setTimeout(() => {
        setRejectOrActiveClass('');
        setFileRejected(false);
      }, 3500);
    },
    onDropAccepted: () => {
      setRejectOrActiveClass(classes.acceptStyle);
      // TODO: clear this timeout on component destruction
      setTimeout(() => setRejectOrActiveClass(''), 2500);
    },
  });

  return (
    <div
      {...getRootProps({
        className: `${classes.dropZone} ${isDragActive ? classes.activeStyle : rejectOrActiveClass}`,
      })}
    >
      <input {...getInputProps()} />
      <div className={classes.gridFileInput}>
        <div />
        <img src={dropIcon} alt="Drop files here" />
        <div className={classes.dropP}>
          {isLoading ? (
            <div className={classes.marginTop}>
              <CircularProgress />
            </div>
          ) : (
            <>
              Drag and drop XLSX file <br />
              OR
              <br />
              <span className={classes.browseLink} onClick={open} onKeyPress={open} role="button" tabIndex={0}>
                Browse File
              </span>{' '}
            </>
          )}
        </div>
        <div />
      </div>
      {fileRejected && (
        <div className={classes.marginTop}>
          <img alt="Error" src={errorIcon} />
          <span className={classes.errorText}>Only XLSX files are accepted.</span>
        </div>
      )}
    </div>
  );
};

export default XlsxFileInput;
