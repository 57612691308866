import React from 'react';

import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import TasksPage from 'containers/Contractor/Tasks/TasksPage';
import useText from 'texts/useText.hook';
import Breadcrumb from 'components/Breadcrumb';
import TaskReportPage from 'containers/shared/Tasks/TaskReportPage';
import TaskAssign from './TaskAssign';
import AssetPage from './AssetPage';
import TaskAttachments from './TaskAttachments';
import { Route, Routes, useParams } from 'react-router';

const TaskRoutes: React.FC = () => {
  const { t } = useText('tasks');
  const contractorId = useParams();
  return (
    <DefaultErrorBoundary>
      <Breadcrumb
        to={`contractor/${contractorId}/work-instructions`}
        name={t('tasks')('workInstructions')}
      />
      <Routes>
        <Route path={`:taskId/assign`} element={<TaskAssign />} />
        <Route path={`:taskId/report`} element={<TaskReportPage />} />
        <Route path={`:taskId/attachments`} element={<TaskAttachments/>} />
        <Route path={`assets/:assetId`} element={<AssetPage/>} />
        <Route path={`/`} element={<TasksPage/>} />
      </Routes>
    </DefaultErrorBoundary>
  );
};

export default TaskRoutes;
