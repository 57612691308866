import { observable, action, IObservableArray, makeObservable, runInAction } from 'mobx';
import FlashMessage from 'models/FlashMessage';
import { v4 as uuidv4 } from 'uuid';

interface Breadcrumb {
  id: string;
  to: string;
  name: string;
  options?: Array<{ id: string; to: string; name: string }>;
}

interface ModulesSelected {
  modules?: Array<{module:string}>
}

export class ContextStore {
  public flashMessages: FlashMessage[] = [];

  public breadcrumbs: IObservableArray<Breadcrumb> = observable([]);


  public modulessel: IObservableArray<ModulesSelected> = observable([]);

  public selectedModules:   [] | undefined;

  public isNavigationHidden = false;

  public systemType: string | undefined;

  public isPaddingBottomRemoved = false;

  public isDrawerHidden = false;

  public previousLocation: string | undefined;
  public actualLocation: string | undefined;

  public siteId: string | undefined;

  // To store the viewing of task report
  public reportViewedValue = false;

  // Task Filters for use after opening task report
  public startdateFilterValue:  Date | undefined;
  public enddateFilterValue:  Date | undefined;
  public taskidFilterValue:   [] | undefined;
  public tasktypeFilterValue:  [] | undefined;
  public respforworksFilterValue:   [] | undefined;
  public statusworkflowFilterValue:   [] | undefined;
  public tasktitleFilterValue:  [] | undefined;
  public reoccurFilterValue:  [] | undefined;
  public deadlinedateFilterValue:  [] | undefined;
  public sysrefFilterValue:  [] | undefined;
  public compliantvalFilterValue:   [] | undefined;




  public compFilterValue: string = 'noncompliant';

  public pieFilterValue: string = 'all';

  public barFilterValue: string | undefined;

  public barncFilterValue: string | undefined;

  public taskvFilterValue: boolean | undefined;

  constructor() {
    makeObservable(this, {
      flashMessages: observable,
      breadcrumbs: observable,
      modulessel: observable,
      selectedModules: observable,
      isNavigationHidden: observable,
      systemType: observable,
      isPaddingBottomRemoved: observable,
      isDrawerHidden: observable,
      previousLocation: observable,
      actualLocation: observable,
      siteId: observable,
      reportViewedValue: observable,
      startdateFilterValue: observable,
      enddateFilterValue: observable,
      taskidFilterValue: observable,
      tasktypeFilterValue: observable,
      respforworksFilterValue: observable,
      statusworkflowFilterValue: observable,
      tasktitleFilterValue: observable,
      reoccurFilterValue: observable,
      deadlinedateFilterValue: observable,
      sysrefFilterValue: observable,
      compliantvalFilterValue: observable,
      compFilterValue: observable,
      pieFilterValue: observable,
      barFilterValue: observable,
      barncFilterValue: observable,
      taskvFilterValue: observable,
      setPieFilter: action.bound,
      setDashboardPicks: action.bound,
      setReportViewedValue: action.bound,
      setTaskFilters: action.bound,
      setTaskStartDate: action.bound,
      setTaskEndDate: action.bound,
      newLocation: action.bound,
      showMessage: action.bound,
      removeMessage: action.bound,
      setNavigationHidden: action.bound,
      setPaddingBottomRemoved: action.bound,
      setDrawerHidden: action.bound,
      addBreadcrumb: action.bound,
      setSiteId: action.bound,
      setSelectedModules: action.bound,
      setSystemType: action.bound
    });
  }

  public setPieFilter(pieFilterType: string) {
    this.pieFilterValue = pieFilterType;
  }

  public setDashboardPicks(
    ncpick: string,
    piepick: string,
    barpick: string,
    barcomppick: string,
    taskview: boolean
  ) {
  
      this.compFilterValue = ncpick;
      this.pieFilterValue = piepick;
      this.barFilterValue = barpick;
      this.barncFilterValue = barcomppick;
      this.taskvFilterValue = taskview;
    
  }

  public setReportViewedValue(isViewed: boolean) {
     this.reportViewedValue = isViewed;
   }

  public setTaskFilters(
    startdate: any,
    enddate: any,
    tasktype: any,
    respforworks: any,
    sysref: any,
    statusworkflow: any,
    compliantval: any,
    tasktitle:any,
    reoccur:any
  ) {
      // Set values for on return from task report
      this.startdateFilterValue = startdate;
      this.enddateFilterValue = enddate;
    //  this.taskidFilterValue = taskid;
      this.tasktypeFilterValue = tasktype;
      this.respforworksFilterValue = respforworks;
      this.statusworkflowFilterValue = statusworkflow;
      this.tasktitleFilterValue = tasktitle;
      this.reoccurFilterValue = reoccur;
    //  this.deadlinedateFilterValue = deadlinedate;
      this.sysrefFilterValue = sysref;
      this.compliantvalFilterValue = compliantval;
  }

  public setTaskStartDate(startdate: any) {
    this.startdateFilterValue = startdate;
  }
  public setTaskEndDate(enddate: any) {
    this.enddateFilterValue = enddate;
  }

  public newLocation(location: string) {
    this.previousLocation = this.actualLocation;
    this.actualLocation = location;
  }

  public showMessage(message: FlashMessage): void {
    this.flashMessages.push(message);
  }

  public removeMessage(id: string): void {
    const index = this.flashMessages.findIndex((element) => element.id === id);
    this.flashMessages.splice(index);
  }

  public setNavigationHidden(isHidden: boolean) {
    this.isNavigationHidden = isHidden;
  }

  public setPaddingBottomRemoved(isHidden: boolean) {
    this.isPaddingBottomRemoved = isHidden;
  }

  public setDrawerHidden(isHidden: boolean) {
    this.isDrawerHidden = isHidden;
  }

  public addBreadcrumb(to: string, name: string, options?: Array<{ to: string; name: string }>) {
    const breadcrumb = {
      id: uuidv4(),
      to,
      name,
      options: options?.map((option) => ({ ...option, id: uuidv4() })),
    };    
    this.breadcrumbs.push(breadcrumb);

    return action(() => {
      this.breadcrumbs.replace(this.breadcrumbs.filter((c) => c.id !== breadcrumb.id));
    });
  }

  public setSiteId(siteId: string) {
    this.siteId = siteId;
  }
  public setSelectedModules(selmods: []) {
    this.selectedModules = selmods;
    window.localStorage.setItem('selectedModules', JSON.stringify(selmods));
  }


  public setSystemType(systemTypeId: string | undefined) {
    this.systemType = systemTypeId;
  }
}

export default ContextStore;