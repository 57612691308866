import { useApolloClient } from '@apollo/client';
import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IconButtonProps } from '@mui/material/IconButton';
import uploadExcelTemplate from 'assets/uploadExcelTemplate.svg';
import AppIconButton from 'components/AppIconButton';
import AppMenu from 'components/AppMenu';
import XlsxFileInput from 'components/FileInput/XlsxFileInput';
import {
  getCreateAssetData,
  getCreateAssetDataVariables,
  assetCreateVariables,
  AssetType,
  assetsCreate,
  assetsCreateVariables,
} from 'models/graphql';
import React, { useRef, useState } from 'react';
import { excelUtils } from 'utils/excel';
import enumToArray from 'utils/enumToArray';
import AppDialog from 'components/AppDialog';
import assetValidation from 'shared/asset.validation';
import { CellValue } from 'exceljs';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import useText from 'texts/useText.hook';
import { GET_CREATE_ASSET_DATA, CREATE_ASSETS } from '../graphql';
import stringToCoordinates from './stringToCoordinates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      padding: theme.spacing(3),
    },
    fileInput: {
      marginTop: theme.spacing(3),
    },
  }),
);

interface OtherProps {
  clientId: string;
  horizontalPosition?: 'left' | 'right' | 'center';
}

const ClientAssetsImport: React.FC<IconButtonProps & OtherProps> = ({
  clientId,
  className,
  horizontalPosition,
  ...forwardProps
}) => {
  const apolloClient = useApolloClient();
  const { t } = useText('clients', 'systemTypes', 'assetTypes');
  const buttonRef = useRef<HTMLDivElement>(null);
  const [openAssetsImport, setOpenAssetsImport] = useState(false);
  const [importProcessing, setImportProcessing] = useState(false);
  const [error, setError] = React.useState<React.ReactNode | null>(null);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

  const styles = useStyles();

  const importAssets = async (file: File) => {
    setImportProcessing(true);
  
    const book = await excelUtils.fileToBook(file);
    const optionsSheet = book.getWorksheet('Options'); // Get the hidden options sheet
    const { data } = await apolloClient.query<getCreateAssetData, getCreateAssetDataVariables>({
      query: GET_CREATE_ASSET_DATA,
      variables: { id: clientId },
    });
    if (!data?.client) return;
    const { client } = data;
  
    // Initialize validation error structure
    const bookValidationError: {
      requiredFields: string[];
      objectNotFound: string[];
      coordinatesAreNotValid: string[];
      systemIsNotAssociatedForAssetType: string[];
      systemIsNotFromTheAssetSite: string[];
      drawingsAreNotFromTheAssetSite: string[];
    } = {
      requiredFields: [],
      objectNotFound: [],
      coordinatesAreNotValid: [],
      systemIsNotAssociatedForAssetType: [],
      systemIsNotFromTheAssetSite: [],
      drawingsAreNotFromTheAssetSite: [],
    };
  
    const assetsToCreate: assetCreateVariables['asset'][] = [];
    const assetTypes = enumToArray(AssetType);
    
    // Define function to check if a value exists in the options sheet for a specific column
    const isValidValueInOptionsSheet = (value: string | undefined, columnIndex: number): boolean => {
      if (!value) return false;
      return optionsSheet.getColumn(columnIndex).values.includes(value);
    };
  
    book.worksheets[0].eachRow(async (row, rowIndex) => {
      if (rowIndex === 1) return; // skip header
  
      let siteId: string | undefined;
      const siteIndex = 1;
  
      let department: string | undefined;
      const departmentIndex = 2;
  
      let systemId: string | undefined;
      const systemIndex = 3;
  
      let assetName: string | undefined;
      const assetNameIndex = 4;
  
      let assetType: AssetType | undefined;
      const assetTypeIndex = 5;
  
      let ownerId: string | undefined;
      const ownerIndex = 6;
  
      let assetCoordinates: ReturnType<typeof stringToCoordinates>;
      const assetCoordinatesIndex = 7;
  
      let additionalInformation: string | undefined;
      const additionalInformationIndex = 8;
  
      const drawingIds: Array<string> = [];
      let drawingObjectNotFound = false;
  
      row.eachCell((cell, cellIndex) => {
        // Resolve and Validate cells
        if (cellIndex === siteIndex) {
          // Site
          if (!cell.value) {
            bookValidationError.requiredFields.push(cell.address);
          } else {
            siteId = client.sites.find((site) => site.name === cell.value)?.id;
            if (!siteId) bookValidationError.objectNotFound.push(cell.address);
          }
        } else if (cellIndex === departmentIndex) {
          // Department
          department = cell.value?.toString();
        } else if (cellIndex === systemIndex) {
          // System
          if (!cell.value) {
            bookValidationError.requiredFields.push(cell.address);
          } else if (!isValidValueInOptionsSheet(cell.value?.toString(), systemIndex)) {
            bookValidationError.objectNotFound.push(cell.address); // Check against options
          } else {
            systemId = client.systems.find((system) => system.name === cell.value)?.id;
            if (!systemId) bookValidationError.objectNotFound.push(cell.address);
          }
        } else if (cellIndex === assetNameIndex) {
          // Asset Name
          assetName = cell.value?.toString();
          if (!assetName) {
            bookValidationError.requiredFields.push(cell.address);
          }
        } else if (cellIndex === assetTypeIndex) {
          // Asset Type
          if (!cell.value) {
            bookValidationError.requiredFields.push(cell.address);
          } else if (!isValidValueInOptionsSheet(cell.value?.toString(), assetTypeIndex)) {
            bookValidationError.objectNotFound.push(cell.address); // Check against options
          } else {
            assetType = assetTypes.find((type) => t('assetTypes')(type) === cell.value);
            if (!assetType) bookValidationError.objectNotFound.push(cell.address);
          }
        } else if (cellIndex === ownerIndex) {
          // Asset Owner
          if (!cell.value) {
            bookValidationError.requiredFields.push(cell.address);
          } else {
            ownerId = client.users.find((user) => user.name === cell.value)?.id;
            if (!ownerId) bookValidationError.objectNotFound.push(cell.address);
          }
        } else if (cellIndex === assetCoordinatesIndex) {
          // Asset Coordinates
          assetCoordinates = stringToCoordinates(cell.value?.toString());
          if (assetCoordinates === null) bookValidationError.coordinatesAreNotValid.push(cell.address);
        } else if (cellIndex === additionalInformationIndex) {
          // Additional Information
          additionalInformation = cell.value?.toString();
        } else {
          // After additionalInformation column, there are only drawingIds
          if (!cell.value) return;
  
          let drawingId;
          client.sites.find((site) => {
            drawingId = site.drawings.find((drawing) => drawing.drawingId === cell.value)?.id;
            return !!drawingId;
          });
          if (drawingId) {
            drawingIds.push(drawingId);
          } else {
            drawingObjectNotFound = true;
            bookValidationError.objectNotFound.push(cell.address);
          }
        }
      });
  
      // Asset validation
      const system = client.systems.find((_system) => _system.id === systemId);
      const site = client.sites.find((_site) => _site.id === siteId);
      const drawings = site ? site.drawings : undefined;
      if (siteId && site && drawings && assetType && system) {
        const assetValidationErrors = assetValidation({
          siteId,
          type: assetType,
          system,
          drawings: drawings
            .filter((drawing) => drawingIds.includes(drawing.id))
            .map((drawing) => ({ id: drawing.id, site })),
        });
  
        if (assetValidationErrors.systemIsNotAssociatedForAssetType) {
          bookValidationError.systemIsNotAssociatedForAssetType.push(row.getCell(assetTypeIndex).address);
        }
        if (assetValidationErrors.systemIsNotFromTheAssetSite) {
          bookValidationError.systemIsNotFromTheAssetSite.push(row.getCell(assetTypeIndex).address);
        }
        if (assetValidationErrors.drawingsAreNotFromTheAssetSite) {
          assetValidationErrors.drawingsAreNotFromTheAssetSite.forEach((drawingId) =>
            bookValidationError.drawingsAreNotFromTheAssetSite.push(
              row.getCell((row.values as CellValue[]).findIndex((cellValue) => cellValue === drawingId)).address,
            ),
          );
        }
  
        if (
          !assetValidationErrors.systemIsNotAssociatedForAssetType &&
          !assetValidationErrors.systemIsNotFromTheAssetSite &&
          !assetValidationErrors.drawingsAreNotFromTheAssetSite &&
          siteId &&
          systemId &&
          assetName &&
          assetType &&
          assetCoordinates !== null &&
          ownerId &&
          !drawingObjectNotFound
        ) {
          assetsToCreate.push({
            siteId,
            department,
            systemId,
            name: assetName,
            type: assetType,
            ownerId,
            coordinates: assetCoordinates,
            additionalInformation,
            drawingIds,
          });
        }
      }
    });

    if (
      bookValidationError.requiredFields.length ||
      bookValidationError.systemIsNotAssociatedForAssetType.length ||
      bookValidationError.systemIsNotFromTheAssetSite.length ||
      bookValidationError.drawingsAreNotFromTheAssetSite.length ||
      bookValidationError.coordinatesAreNotValid.length ||
      bookValidationError.objectNotFound.length
    ) {
      setError(
        <>
          {bookValidationError.requiredFields.length ? (
            <div>
              {`${t('clients')('importAssetsErrorRequired')} `}
              <b>{bookValidationError.requiredFields.join(', ')}</b>
            </div>
          ) : null}
          {bookValidationError.systemIsNotAssociatedForAssetType.length ? (
            <div>
              {`${t('clients')('importAssetsErrorSystemIsNotAssociatedForAssetType')} `}
              <b>{bookValidationError.systemIsNotAssociatedForAssetType.join(', ')}</b>
            </div>
          ) : null}
          {bookValidationError.systemIsNotFromTheAssetSite.length ? (
            <div>
              {`${t('clients')('importAssetsErrorSystemIsNotFromTheAssetSite')} `}
              <b>{bookValidationError.systemIsNotFromTheAssetSite.join(', ')}</b>
            </div>
          ) : null}
          {bookValidationError.drawingsAreNotFromTheAssetSite.length ? (
            <div>
              {`${t('clients')('importAssetsErrorDrawingsAreNotFromTheAssetSite')} `}
              <b>{bookValidationError.drawingsAreNotFromTheAssetSite.join(', ')}</b>
            </div>
          ) : null}
          {bookValidationError.coordinatesAreNotValid.length ? (
            <div>
              {`${t('clients')('assetCoordinatesWrongFormat')}: `}
              <b>{bookValidationError.coordinatesAreNotValid.join(', ')}</b>
            </div>
          ) : null}
          {bookValidationError.objectNotFound.length ? (
            <div>
              {`${t('clients')('importAssetsErrorObjectNotFound')} `}
              <b>{bookValidationError.objectNotFound.join(', ')}</b>
            </div>
          ) : null}
        </>,
      );
      setErrorDialogOpen(true);
    } else {
      await apolloClient.mutate<assetsCreate, assetsCreateVariables>({
        mutation: CREATE_ASSETS,
        variables: {
          assets: assetsToCreate,
        },
        update: (cache, { data: updateData }) => {
          apolloCacheEvict({
            cache,
            typename: 'Client',
            id: clientId,
            fieldName: 'assets',
          });

          const assets = updateData?.assetsCreate;
          if (assets) {
            assets.forEach((asset) => {
              apolloCacheEvict({
                cache,
                typename: 'System',
                id: asset.system.id,
                fieldName: 'numberOfAssets',
              });
            });
          }
        },
      });
    }

    setImportProcessing(false);
    setOpenAssetsImport(false);
  };

  return (
    <>
      <div ref={buttonRef} className={className}>
        <AppIconButton {...forwardProps} onClick={() => setOpenAssetsImport(true)}>
          <img alt="upload template" src={uploadExcelTemplate} />
        </AppIconButton>
      </div>

      <AppMenu
        horizontalPosition={horizontalPosition}
        anchorEl={buttonRef.current}
        open={openAssetsImport}
        onClose={() => setOpenAssetsImport(false)}
      >
        <div className={styles.menu}>
          <Typography variant="h3">{t('clients')('importAssetsTitle')}</Typography>
          <div className={styles.fileInput}>
            <XlsxFileInput isLoading={importProcessing} onLoad={importAssets} />
          </div>
        </div>
      </AppMenu>

      <AppDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        type="error"
        title={t('clients')('importAssetsErrorTitle')}
      >
        {error}
      </AppDialog>
    </>
  );
};

export default ClientAssetsImport;
