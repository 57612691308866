import React from 'react';
import { ThemeProvider} from '@mui/material/styles';

import { BrowserRouter } from 'react-router';
import { Provider } from 'mobx-react';

import { createStores } from 'stores';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import theme from 'style/theme';
import UpdateComponent from './UpdateComponent';
import AutoLogIn from './AutoLogIn';
import Apollo from './Apollo';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

const stores = createStores();

const BootstrapComponent: React.FC = ({ children }) => (
  <DefaultErrorBoundary>
    <Provider {...stores}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UpdateComponent>
            <BrowserRouter>
              <Apollo>
                <AutoLogIn>{children}</AutoLogIn>
              </Apollo>
           </BrowserRouter>
          </UpdateComponent>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </DefaultErrorBoundary>
);

export default BootstrapComponent;
