import React, { PropsWithChildren, useCallback } from 'react';
import objectPropertyByString from 'utils/objectPropertyByString';
import { useFormikContext } from 'formik';
import cn from 'utils/cn';
import useFieldStyles from './AppField.style';
import AppFieldError from './AppFieldError';
import { FormControlLabel, MenuItem, Select, SelectProps, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const styleRules = (theme: Theme) =>
  createStyles({
    selectField: {
      '& .MuiSelect-root': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: theme.fontSizes['14'],
        '&:focus': {
          background: 'inherit',
        },
      },
      '&.Mui-disabled .MuiSelect-icon': {
        filter: 'grayscale(100%)',
      },
      '&.displayPlaceholder .MuiSelect-select': {
        color: theme.palette.grey[500],
      },
    },
    arrayEmptyText: {
      color: theme.palette.grey[500],
    },
    placeholderItem: {
      display: 'none',
    },
    addInnerPaddingRight: {
      paddingRight: theme.spacing(1),
    },
  });
const useStyles = makeStyles(styleRules);

export type AppSelectFieldType<T> = Omit<Omit<SelectProps, 'onChange'>, 'name'> & {
  name: string; // name is required
  options: {
    array: Array<T>;
    value: (arrayElement: T) => string | undefined;
    key: (arrayElement: T) => string;
    template: (arrayElement: T) => React.ReactNode;
    ifArrayEmpty?: React.ReactNode;
  };
  label?: string;
  placeholder?: string;
  onChange?: (value: T) => void;
  value?: string;
  addInnerPaddingRight?: boolean;
};

export default function AppSelectField<T>({
  name,
  options,
  label: _label,
  placeholder,
  onChange,
  value: _value,
  required,
  className,
  disabled,
  addInnerPaddingRight,
  ...propsToPass
}: PropsWithChildren<AppSelectFieldType<T>>) {
  // values, touched and errors coming form FormikContext.
  // setFieldValue writes the changed value back to FormikContext.
  const { values, touched, errors, setFieldValue } = useFormikContext<Record<string, unknown>>();

  // If the field supports be embedded inside <FieldArray />
  // we have to read values by objectPropertyByString
  // (otherwise 'value = values[name]' is enough)
  let value = _value || objectPropertyByString(values, name) || values[name];
  if (value === null || value === undefined) value = '';

  const styles = useStyles();
  const fieldStyles = useFieldStyles();

  return (
    <div>
      <FormControlLabel
        labelPlacement="top"
        className={cn(fieldStyles.label, { 'no-label': !_label })}
        label={_label}
        control={
          options.array.length || !options.ifArrayEmpty || disabled ? (
            <Select
              {...propsToPass}
              name={name}
              value={value}
              required={required}
              disabled={disabled}
              variant="outlined"
              // only show error, if the field is touched
              error={objectPropertyByString(touched, name) && !!objectPropertyByString(errors, name)}
              onChange={(event) => {
                const newValue = event.target.value as string;
                // const payload = { ...values, [name]: newValue };
                // setValues(payload);
                setFieldValue(name, newValue);

                if (onChange) {
                  onChange(options.array.find((element) => options.value(element) === newValue)!);
                }
              }}
              displayEmpty
              className={cn(
                className,
                styles.selectField,
                fieldStyles.field,
                {
                  displayPlaceholder: value === '',
                },
                addInnerPaddingRight ? styles.addInnerPaddingRight : undefined,
              )}
            >
              {placeholder ? (
                <MenuItem value="" disabled className={styles.placeholderItem}>
                  {placeholder}
                </MenuItem>
              ) : null}
              {options.array.map((arrayElement) => (
                <MenuItem value={options.value(arrayElement)} key={options.key(arrayElement)}>
                  {options.template(arrayElement)}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <div className={styles.arrayEmptyText}>{options.ifArrayEmpty}</div>
          )
        }
      />

      <AppFieldError
        message={
          // only show error, if the field is touched
          objectPropertyByString(touched, name) ? objectPropertyByString(errors, name) : undefined
        }
      />
    </div>
  );
}
