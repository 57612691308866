import React, { useEffect, useState } from 'react';
import assetsIcon from 'assets/assetsIcon.svg';
import assetsIconGrey from 'assets/assetsIconGrey.svg';
import contractorIcon from 'assets/contractorIcon.svg';
import contractorIconGrey from 'assets/contractorIcon-grey.svg';
import dashboardIconWhite from 'assets/dashboardIconWhite.svg';
import dashboardIconGrey from 'assets/dashboardIconGrey.svg';
import documentsIcon from 'assets/documentsIcon.svg';
import documentsIconGrey from 'assets/documentsIconGrey.svg';
import projectIconGrey from 'assets/projectIconGrey.svg';
import projectIcon from 'assets/projectIcon.svg';
import tasksIconWhite from 'assets/tasksIconWhite.svg';
import tasksIconGrey from 'assets/tasksIconGrey.svg';
import { useStores } from 'stores';
import { observer } from 'mobx-react';
import currentWeek from 'utils/currentWeek';
import DrawerLink from './DrawerLink';

interface Props {
  id: string;
  projects: boolean;
}

const ClientMenu: React.FC<Props> = ({ id, projects }) => {
  const { contextStore } = useStores();
  const siteId = contextStore.siteId
  const currentWeekString = currentWeek();
  const [showSFOnlyMenus, setShowSFOnlyMenus] = useState(false)

  useEffect(() => {
    let sfurltogoto =  'sf/sfcheck/';
    try {
      fetch(`${process.env.API_ROOT}${sfurltogoto}?client=${id}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        if (dataret.length > 0)
        {
          if (dataret[0].sfonly === true)
            {
              setShowSFOnlyMenus(true)
            }     
        }
      
      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }), []

  return (
    <>
      {showSFOnlyMenus ? (
        <DrawerLink
        to={`/client/${id}/dashboard`}
        name="Dashboard"
        icon={dashboardIconGrey}
        activeIcon={dashboardIconWhite}
      />
      ) : (
        <>
      <DrawerLink
        to={`/client/${id}/dashboard`}
        name="Dashboard"
        icon={dashboardIconGrey}
        activeIcon={dashboardIconWhite}
      />
      <DrawerLink
        to={`/client/${id}/tasks/${siteId}?${currentWeekString}`}
        name="Tasks"
        icon={tasksIconGrey}
        activeIcon={tasksIconWhite}
      />
      <DrawerLink to={`/client/${id}/assets`} name="Asset Register" icon={assetsIconGrey} activeIcon={assetsIcon} />
      {projects ? (
      <DrawerLink to={`/client/${id}/projects`} name="Projects" icon={projectIconGrey} activeIcon={projectIcon} />
         ) : null}
      <DrawerLink to={`/client/${id}/documents`} name="Documents" icon={documentsIconGrey} activeIcon={documentsIcon} />
      <DrawerLink
        to={`/client/${id}/contractors`}
        name="Contractors"
        icon={contractorIconGrey}
        activeIcon={contractorIcon}
      />
    </>)}
    </>
  );
};

export default observer(ClientMenu);
