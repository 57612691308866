import React, { useEffect, useState } from 'react';

import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Theme,
  CircularProgress,
  Typography,
  useTheme,
  Button,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import cn from 'classnames';
import ActionsButton, { Actions } from 'components/ActionsButton';
import useText from 'texts/useText.hook';
import rightArrow from 'assets/rightArrow.svg';
import iotIcon from 'assets/iotIcon.svg';
import styleUtils from 'style/styleUtils';
import nextTick from 'utils/nextTick';
import useUpdateSearchParams from 'utils/useUpdateSearchParams';
import useRemovePaddingBottom from 'components/useRemovePaddingBottom';
import ExpandableTableRow from 'components/AppTable/components/ExpandableTableRow';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import PumpsDialog from 'components/AppTable/components/PumpsDialog';
import { assetsForSite_site_assets_pumpInfo as PumpInfoType, AssetType } from 'models/graphql';
import { Link, useNavigate } from 'react-router';

const styles = (theme: Theme) => {
  const tableColor = theme.palette.grey[100];
  const tableActiveColor = theme.palette.table.main;
  const tableBorder = `solid 1px ${theme.palette.grey[300]}`;
  const tableHeaderRadius = theme.shape.borderRadius * 2;
  const tableWithDetailsPanelWidthPercentage = 70;

  return createStyles({
    container: {
      '&.with-details-panel': {
        display: 'flex',
        alignItems: 'flex-start',
      },
    },
    tableContainer: {
      width: '100%',
      overflow: 'auto',
      '&.with-details-panel': {
        width: `${tableWithDetailsPanelWidthPercentage}%`,
        [theme.breakpoints.down(BREAKPOINT)]: {
          width: '100%',
        },
      },
    },
    table: {
      height: 'min-content',
      width: 'calc(100% - 1px)',
      borderCollapse: 'separate', // with 'collapse' border-radius do not work
      borderSpacing: 0,
    },
    tableHead: {
      background: tableColor,
      '& tr:first-child': {
        '& th:first-child': {
          borderTopLeftRadius: tableHeaderRadius,
        },
        '& th:last-child': {
          borderTopRightRadius: tableHeaderRadius,
        },
      },
      '&.with-details-panel': {
        '& tr:first-child': {
          '& th:last-child': {
            borderTopRightRadius: '0 !important',
          },
        },
      },
      '&.with-sticky_head': {
        position: 'sticky',
        top: 0,
        zIndex: 10,
        '& th': {
          borderTop: `2px solid ${theme.palette.primary.main}`,
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        '& th:first-child': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          borderTopLeftRadius: '5px !important',
        },
        '& th:last-child': {
          borderRight: `2px solid ${theme.palette.primary.main}`,
          borderTopRightRadius: '5px !important',
        },
      },
    },
    tableBody: {
      borderTop: tableBorder,
      '& td': {
        height: 56,
        borderBottom: tableBorder,
        '&:first-child': {
          borderLeft: tableBorder,
        },
        '&:last-child': {
          borderRight: tableBorder,
        },
      },
    },
    tableCell: {
      '&.clickable': {
        cursor: 'pointer',
      },
    },
    stateMessage: {
      paddingTop: theme.spacing(10),
      border: '0 !important',
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
    },
    activeRow: {
      backgroundColor: tableActiveColor,
    },

    pagination: {
      display: 'flex',
      alignItems: 'center',
    },
    paginationSummary: {
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[500],
    },
    paginationNavigation: {
      marginLeft: 'auto',
      padding: theme.spacing(1),
      display: 'flex',
    },
    paginationNavigationElement: {
      width: 28,
      height: 28,
      marginRight: theme.spacing(0.5),
      ...styleUtils.rowCenterCenter,
      padding: theme.spacing(0.75),
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.grey[500],
      fontWeight: theme.typography.fontWeightBold,
      transition: theme.transitions.create(['border', 'color'], {
        duration: theme.transitions.duration.short,
      }),

      '&:last-child': {
        marginRight: 0,
      },

      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      '&.active': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    },
    paginationSectionDivider: {
      color: theme.palette.grey[500],
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.spacing(0.5),
    },

    detailsPanel: {
      width: `${100 - tableWithDetailsPanelWidthPercentage}%`,
      minWidth: 210,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderTopRightRadius: theme.shape.borderRadius,
      overflow: 'auto',
    },
    detailsPanelHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    detailsPanelTitle: {
      color: theme.palette.grey[500],
    },
    iotIcon: {
      marginLeft: 'auto',
    },
  });
};
const useStyles = makeStyles(styles);

const createPaginationNavigation: (totalPages: number, actualPage: number) => Array<number | null> = (
  totalPages,
  actualPage,
) => {
  const pages: Array<number | null> = []; // null means a divider

  if (totalPages <= 5) {
    // if totalPages is less than 5
    for (let i = 1; i <= totalPages; i += 1) {
      // just display all pages
      pages.push(i);
    }
  } else if (actualPage <= 4) {
    // if actualPage is at the beginning
    for (let i = 1; i <= 5; i += 1) {
      // display the first 5 page
      pages.push(i);
    }
    // and the last page with a divider
    pages.push(null, totalPages);
  } else if (totalPages - actualPage <= 3) {
    // if actualPage is at the end
    for (let i = 0; i <= 4; i += 1) {
      // display the last 5 page
      pages.unshift(totalPages - i);
    }
    // and the first page with a divider
    pages.unshift(1, null);
  } else {
    // if actualPage is at the middle
    // display the first page with a divider
    pages.push(1, null);
    // display the actual, the next and the previous page
    pages.push(actualPage - 1, actualPage, actualPage + 1);
    // display the last page with a divider
    pages.push(null, totalPages);
  }

  return pages;
};

export interface Column<T> {
  name?: string;
  headerTemplate?: React.ReactNode;
  key?: string; // unique key for .map() (fallback to 'name' if not provided)

  link?: ((row: T) => string) | null; // with the null value,
  // the rowLink behaviour can be disabled for this column

  template: (row: T, index: number) => React.ReactNode;
  align?: 'left' | 'center' | 'right';
}

export default function AppTable<T extends { id: string }>({
  data,
  dataPaginated,
  columns,
  rowLink,
  detailsPanel: createDetailsPanel,
  loading,
  noDataMessage,
  activeRowId,
  doubleLineActions,
  tableInfoLine,
  expandable = false,
  stickyHead,
}: {
  data?: T[];
  dataPaginated?: {
    nodes: T[];
    totalNodes: number;
    page: number;
    totalPages: number;
  };
  columns: Column<T>[];
  rowLink?: (row: T) => string;
  detailsPanel?: (
    row: T,
  ) => {
    title: string;
    template: React.ReactNode;
    actions?: Actions;
    pumpInfo?: PumpInfoType[] | null;
  };
  loading?: boolean;
  noDataMessage?: string;
  activeRowId?: string;
  doubleLineActions?: boolean;
  tableInfoLine?: React.ReactNode;
  expandable?: boolean;
  stickyHead?: boolean;
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const updateSearchParams = useUpdateSearchParams();
  const { t, tt } = useText('common', 'urls');
  const theme = useTheme();
  useRemovePaddingBottom();

  const [pumpPopup, setPumpPopup] = React.useState(false);

  let OUTER_HEIGHT = theme.sizes.headerHeight + parseInt(theme.spacing(3)) + theme.sizes.button.height + parseInt(theme.spacing(2.5));
  if (doubleLineActions) OUTER_HEIGHT += theme.sizes.button.height + parseInt(theme.spacing(2.5));
  if (tableInfoLine) OUTER_HEIGHT += 40;

  const rows = data || dataPaginated?.nodes;
  const [activeRowIndex, setActiveRowIndex] = useState<number | undefined>();

  // Details panel
  const isDetailsPanelSet = !!createDetailsPanel;
  useEffect(() => {
    if (isDetailsPanelSet && rows) {
      if (activeRowId) {
        const idx = rows.findIndex((r) => r.id === activeRowId);
        setActiveRowIndex(idx === -1 ? undefined : idx);
      } else {
        setActiveRowIndex(0);
      }
    }
  }, [isDetailsPanelSet, activeRowId, rows]);
  useEffect(() => {
    if (isDetailsPanelSet && activeRowIndex === undefined && rows && !activeRowId) {
      setActiveRowIndex(0);
    }
  }, [isDetailsPanelSet, rows, activeRowIndex, activeRowId]);
  const activeRow = activeRowIndex !== undefined && rows ? rows[activeRowIndex] : undefined;
  let detailsPanel = createDetailsPanel && activeRow !== undefined ? createDetailsPanel(activeRow) : undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activeAsset = activeRow ? (activeRow as any) : undefined;

  // Pagination
  const paginationNavigation =
    dataPaginated && dataPaginated.totalPages > 1
      ? // create the pagination only if more than 1 page
        createPaginationNavigation(dataPaginated.totalPages, dataPaginated.page)
      : undefined;
  if (dataPaginated && dataPaginated.totalPages > 0 && dataPaginated.page > dataPaginated.totalPages) {
    nextTick().then(() =>
      // If user try to query a page which is over the last page, go to the last page (after transitions finished)
      navigate(
        updateSearchParams({
          [tt('urls')('queries')('page')]: 1,
        }),
      ),
    );
  }

  return (<>
    {tableInfoLine && (
      <Box
        sx={{
          color: "grey.500",
          p: 0.5
        }}>
        {tableInfoLine}
      </Box>
    )}
    <div
      className={cn(classes.container, {
        'with-details-panel': detailsPanel,
      })}
    >
      <div
        className={cn(classes.tableContainer, {
          'with-details-panel': detailsPanel,
        })}
        style={{
          maxHeight: `calc(100vh - ${OUTER_HEIGHT}px)`,
        }}
      >
        <Table className={classes.table}>
          <TableHead
            className={cn(classes.tableHead, {
              'with-details-panel': detailsPanel,
              'with-sticky_head': stickyHead,
            })}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key || column.name} align={column.align}>
                  {column.headerTemplate || column.name}
                </TableCell>
              ))}
              {expandable && <TableCell padding="checkbox" />}
            </TableRow>
          </TableHead>
          <TableBody className={cn(classes.tableBody)}>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} className={classes.stateMessage}>
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!(rows && rows.length) ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} className={classes.stateMessage}>
                      {noDataMessage || 'No Data'}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, rowIndex) =>
                    expandable ? (
                      <ExpandableTableRow
                        key={row.id}
                        setActiveRowIndex={setActiveRowIndex}
                        rowIndex={rowIndex}
                        expandComponent={
                          <TableCell colSpan={columns.length + 1}>
                            {activeAsset?.type === AssetType.PUMP && !!detailsPanel?.pumpInfo?.length && (
                              <Button className={classes.iotIcon} onClick={() => setPumpPopup(true)}>
                                <img src={iotIcon} alt="iot" />
                              </Button>
                            )}
                            <div>{detailsPanel && detailsPanel.template}</div>
                          </TableCell>
                        }
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.key || column.name}
                            align={column.align}
                            onClick={() => {
                              if (column.link) navigate(column.link(row));
                              else if (column.link === undefined && rowLink) {
                                const target = rowLink(row);
                                if (typeof target === 'string') navigate(target);
                              } else if (detailsPanel) {
                                setActiveRowIndex(rowIndex);
                              } else if (createDetailsPanel) {
                                detailsPanel = createDetailsPanel(row);
                                setActiveRowIndex(rowIndex);
                              }
                            }}
                            className={cn(classes.tableCell, {
                              clickable: column.link || (column.link === undefined && rowLink) || detailsPanel,
                            })}
                          >
                            {column.template(row, rowIndex)}
                          </TableCell>
                        ))}
                      </ExpandableTableRow>
                    ) : (
                      <TableRow key={row.id} className={activeRow?.id === row.id ? classes.activeRow : undefined}>
                        {columns.map((column) => (
                          <TableCell
                            key={column.key || column.name}
                            align={column.align}
                            onClick={() => {
                              if (column.link) navigate(column.link(row));
                              else if (column.link === undefined && rowLink) {
                                const target = rowLink(row);
                                if (typeof target === 'string') navigate(target);
                              } else if (detailsPanel) {
                                setActiveRowIndex(rowIndex);
                              } else if (createDetailsPanel) {
                                detailsPanel = createDetailsPanel(row);
                                setActiveRowIndex(rowIndex);
                              }
                            }}
                            className={cn(classes.tableCell, {
                              clickable: column.link || (column.link === undefined && rowLink) || detailsPanel,
                            })}
                          >
                            {column.template(row, rowIndex)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ),
                  )
                )}
              </>
            )}
          </TableBody>
        </Table>

        {dataPaginated && paginationNavigation ? (
          <div className={classes.pagination}>
            <div className={classes.paginationSummary}>
              {t('common')('paginationSummary', {
                totalNodes: dataPaginated.totalNodes,
                page: dataPaginated.page,
                totalPages: dataPaginated.totalPages,
              })}
            </div>
            <div className={classes.paginationNavigation}>
              {dataPaginated.page > 1 ? (
                <Link
                  to={updateSearchParams({
                    [tt('urls')('queries')('task')]: null,
                    [tt('urls')('queries')('page')]: dataPaginated.page - 1,
                  })}
                  className={classes.paginationNavigationElement}
                >
                  <img src={rightArrow} alt="previous" style={{ transform: 'rotate(180deg)' }} />
                </Link>
              ) : null}
              {paginationNavigation.map((page, index) =>
                page === null ? (
                  // eslint-disable-next-line react/no-array-index-key
                  (<div key={`divider-${index}`} className={classes.paginationSectionDivider}>...
                                        </div>)
                ) : (
                  <Link
                    key={page}
                    to={updateSearchParams({
                      [tt('urls')('queries')('task')]: null,
                      [tt('urls')('queries')('page')]: page,
                    })}
                    className={cn(classes.paginationNavigationElement, { active: page === dataPaginated.page })}
                  >
                    {page}
                  </Link>
                ),
              )}
              {dataPaginated.page < dataPaginated.totalPages ? (
                <Link
                  to={updateSearchParams({
                    [tt('urls')('queries')('task')]: null,
                    [tt('urls')('queries')('page')]: dataPaginated.page + 1,
                  })}
                  className={classes.paginationNavigationElement}
                >
                  <img src={rightArrow} alt="next" />
                </Link>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      {!expandable && detailsPanel ? (
        <div
          className={classes.detailsPanel}
          style={{
            maxHeight: `calc(100vh - ${OUTER_HEIGHT}px)`,
          }}
        >
          <div className={classes.detailsPanelHeader}>
            <Typography className={classes.detailsPanelTitle} variant="h3">
              {detailsPanel.title}
            </Typography>
            {activeAsset?.type === AssetType.PUMP && !!detailsPanel.pumpInfo?.length && (
              <Button className={classes.iotIcon} onClick={() => setPumpPopup(true)}>
                <img src={iotIcon} alt="iot" />
              </Button>
            )}
            {detailsPanel.actions ? <ActionsButton actions={detailsPanel.actions} /> : null}
          </div>
          <div>{detailsPanel.template}</div>
        </div>
      ) : null}
      {detailsPanel?.pumpInfo && (
        <PumpsDialog pumpPopup={pumpPopup} setPumpPopup={setPumpPopup} pumpInfo={detailsPanel.pumpInfo} />
      )}
    </div>
  </>);
}
