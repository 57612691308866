import { Theme, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TaskTypeIcon from 'containers/shared/TaskTypeIcon';
import { TaskStatus, TaskType, tasksForClient_site_tasks_nodes_assets as Assets } from 'models/graphql';
import React from 'react';
import styleUtils from 'style/styleUtils';
import useText from 'texts/useText.hook';
import urgentIcon from 'assets/urgentIcon.svg';
import { readDayDate } from 'shared/formatDayDate';
import TaskComplianceIcon, { taskComplianceColor } from 'components/complianceIcons/TaskComplianceIcon';
import { completedTaskStatuses } from 'shared/taskStatusGroups';
import cn from 'utils/cn';

const useStyles = makeStyles((theme: Theme) => {
  const iconContainerSize = 40;

  return createStyles({
    container: {
      display: 'flex',
      padding: theme.spacing(2),
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderLeft: 'solid 8px transparent',
      borderTopRightRadius: theme.shape.borderRadius * 2,
      borderBottomRightRadius: theme.shape.borderRadius * 2,
    },
    containerCompleted: {
      background: theme.palette.grey[100],
    },
    infoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#44924D',
      textAlign: 'center',
      fontSize: 'initial',
      fontWeight: 'bold',
    },
    iconContainer: {
      ...styleUtils.rowCenterCenter,
      height: iconContainerSize,
      width: iconContainerSize,
      background: theme.palette.grey[100],
      borderRadius: '50%',
    },
    icon: {
      maxWidth: theme.sizes.iconMedium,
      maxHeight: theme.sizes.iconMedium,
    },
    adhocIcon: {
      backgroundColor: '#F3F4F9',
      borderRadius: '50%',
      height: 22,
      width: 22,
    },
    content: {
      flexGrow: 1,
      marginLeft: theme.spacing(1),
    },
    title: {
      display: 'flex',
      alignItems: 'center',

      fontSize: theme.fontSizes[16],
      lineHeight: `${iconContainerSize}px`,
    },
    statusIcons: {
      marginLeft: 'auto',
      display: 'flex',

      '& > :not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    dataRow: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[600],

      '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5),
      },
    },
    clientName: {
      color: theme.palette.primary.main,
    },
  });
});

const TaskCard: React.FC<{
  task: {
    type: TaskType;
    adhoc?: boolean;
    notes?: string | null;
    tasktitle?: string | null;
    deadline: string | null;
    assetsCount: number;
    site: { companyName: string };
    urgent: boolean | null;
    assets: Array<Assets>;
    compliant: boolean | null;
    status: TaskStatus;
  };
}> = ({ task }) => {
  const { t } = useText('taskTypes', 'tasks');
  const theme = useTheme();
  const styles = useStyles();

  return (
    <div
      className={cn(styles.container, { [styles.containerCompleted]: completedTaskStatuses.includes(task.status) })}
      style={{ borderLeftColor: taskComplianceColor(theme, task) }}
    >
      <div className={styles.infoWrapper}>
        <div className={styles.iconContainer}>
          <div className={styles.icon}>
            <TaskTypeIcon type={task.type} />
          </div>
        </div>
        {task.adhoc && <div className={styles.adhocIcon}>A</div>}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          {t('taskTypes')(task.type)}
          <div className={styles.statusIcons}>
            {task.urgent ? <img alt="urgent" src={urgentIcon} /> : null}
            <TaskComplianceIcon task={task} />
          </div>
        </div>
        <div className={styles.dataRow}>
          {t('tasks')('taskInstructionDeadline_short')}
          <div>{task.deadline ? theme.dateFormat.fullMonth(readDayDate(task.deadline)) : null}</div>
        </div>
   
        <div className={styles.dataRow}>
          {t('tasks')('numberOfAssets')}
          <div>{task.assetsCount}</div>
        </div>
        {task.tasktitle && (
          <div className={styles.dataRow}>
            {t('tasks')('tasktitle')}: {task.tasktitle}
          </div>
        )}
        <div className={`${styles.dataRow} ${styles.clientName}`}>{task.site.companyName}</div>
        {task.notes && (
          <div className={styles.dataRow}>
            {t('tasks')('notes')}: {task.notes}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskCard;
