import nextTick from 'utils/nextTick';

import { Workbook } from 'exceljs';

export default async function excelDownloadBook(book: Workbook, fileName: string) {
  const buffer = await book.xlsx.writeBuffer();

  // build anchor tag and attach file
  const a = document.createElement('a');
  const data = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const url = URL.createObjectURL(data);
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  await nextTick();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
