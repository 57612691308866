import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  ImageList,
  ImageListItem,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import PdfRenderer from 'components/pdf';
import { useHideNavigation } from 'components/HideNavigation';
import AppIconButton from 'components/AppIconButton';
import { DOWNLOAD_REQUEST } from 'components/FileInput/graphql';
import { GET_DRAWING, GET_TASK_ASSET_DRAWINGS, ROTATE_DRAWING } from 'containers/shared/graphql';
import cancelIcon from 'assets/cancelIcon.svg';
import rotateLeftIcon from 'assets/rotateLeftIcon.svg';
import rotateRightIcon from 'assets/rotateRightIcon.svg';
import downArrowIconThin from 'assets/downArrowIconThin.svg';
import editFormIcon from 'assets/editFormIcon.svg';
import {
  downloadRequest,
  downloadRequestVariables,
  drawing as drawingGQL,
  drawingVariables,
  getTaskAssetDrawings,
  getTaskAssetDrawingsVariables,
  rotateDrawing as rotateDrawingGQL,
  rotateDrawingVariables,
  TaskStatus,
  TaskType,
} from 'models/graphql';
import BreadcrumbSelector from 'containers/Default/components/Header/Breadcrumb/BreadcrumbSelector';
import useText from 'texts/useText.hook';
import WarningDialog from 'components/WarningDialog';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import useMode, { Mode } from 'utils/useMode.hook';
import { Link, useNavigate, useParams } from 'react-router';

const styleRules = (theme: Theme) =>
  createStyles({
    button: {
      '&:disabled': {
        filter: 'opacity(0.3)',
      },
    },
    mobileDrawingMenu: {
      [theme.breakpoints.down(BREAKPOINT)]: {
        background: 'rgba(0, 0, 0, .25)',
      },
    },
    smallFont: {
      fontSize: theme.fontSizes['10'],
    },
    cardFont: {
      fontSize: theme.fontSizes['12'],
    },
    roundedShadowDesktop: {
      [theme.breakpoints.up(BREAKPOINT)]: {
        boxShadow: theme.shadows[2],
        borderRadius: '50%',
      },
    },
    selectorShadowDesktop: {
      [theme.breakpoints.up(BREAKPOINT)]: {
        boxShadow: theme.shadows[2],
        borderRadius: '20px',
      },
    },
    assetCard: {
      border: '1px solid #E5E5E5',
      borderRadius: '0px 16px 16px 0px',
      height: '96px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      textAlign: 'left',
      marginBottom: theme.spacing(2),
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
    },
    wrapper: {
      '& .react-transform-component': {
        width: 'auto',
        overflow: 'visible',
      },
    },
  });
const useStyles = makeStyles(styleRules);

const DrawingPage: React.FC = () => {
  const {
    assetCard,
    cardFont,
    gridList,
    button,
    mobileDrawingMenu,
    roundedShadowDesktop,
    selectorShadowDesktop,
    smallFont,
    wrapper,
  } = useStyles();
  const navigate = useNavigate();
  useHideNavigation();
  const { drawingId, taskAssetId, taskId } = useParams<{ drawingId: string; taskAssetId?: string; taskId?: string }>();
  const [renderingSrc, setRenderingSrc] = useState('');
  const [renderingRotation, setRenderingRotation] = useState(0);
  const [renderingLoading, setRenderingLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const { mode } = useMode();
  const { t } = useText('assetTypes');
  const mobileScreen = useMediaQuery('(max-width:800px)');

  const { loading: drawingQueryLoading, data: drawingData } = useQuery<drawingGQL, drawingVariables>(GET_DRAWING, {
    variables: {
      drawingId,
    },
  });

  const { data: taskAssetData, loading: taskAssetDataLoading } = useQuery<
    getTaskAssetDrawings,
    getTaskAssetDrawingsVariables
  >(GET_TASK_ASSET_DRAWINGS, {
    skip: !taskAssetId,
    variables: { id: taskAssetId! },
  });

  const drawings: Array<{
    id: string;
    to: string;
    name: string;
    selected: boolean;
  }> = taskAssetData
    ? taskAssetData.taskAsset.asset.drawings.map((drawing) => {
        return {
          id: drawing.id,
          to: drawing.id,
          name: drawing.file.originalName,
          selected: drawing.id === drawingId,
        };
      })
    : [];
  const drawingAssetIds: string[] = drawingData ? drawingData.drawing.assets.map(({ id }) => id) : [];
  const taskAssets = taskAssetData ? taskAssetData.taskAsset.task.assets : [];
  const assets = taskAssets.filter((asset) => drawingAssetIds.includes(asset.asset.id));

  const [getSignedDownloadUrlMutation] = useMutation<downloadRequest, downloadRequestVariables>(DOWNLOAD_REQUEST);
  const [rotateDrawing] = useMutation<rotateDrawingGQL, rotateDrawingVariables>(ROTATE_DRAWING);

  useEffect(() => {
    async function getSignedDownloadUrl(fileId: string) {
      const resp = await getSignedDownloadUrlMutation({ variables: { fileId } });
      return resp.data?.downloadRequest.url;
    }

    const set = async () => {
      if (drawingData) {
        if (drawingData.drawing.rotation) setRenderingRotation(drawingData.drawing.rotation);
        const src = await getSignedDownloadUrl(drawingData.drawing.file.id);
        if (!src) throw new Error('Cannot get file download url.');
        setRenderingSrc(src);
      }
    };

    set();
  }, [drawingData, getSignedDownloadUrlMutation]);

  useEffect(() => {
    const rotationChanged = async () => {
      await rotateDrawing({ variables: { id: drawingId, rotation: renderingRotation } });
    };
    rotationChanged();
  }, [drawingId, rotateDrawing, renderingRotation]);

  return (
    <Box
      sx={{
        lineHeight: "0",
        height: "100vh",
        textAlign: "center",
        position: "relative"
      }}>
      <Box
        className={mobileDrawingMenu}
        sx={{
          py: 0.5,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10
        }}>
        <Grid container sx={{
          alignItems: "center"
        }}>
          <Grid size="grow">
            {[Mode.OPERATIVE, Mode.TECHNICIAN].includes(mode) && (
              <Box
                sx={{
                  px: 3,
                  textAlign: "left"
                }}>
                <BreadcrumbSelector options={drawings} loading={renderingLoading}>
                  <Box
                    className={selectorShadowDesktop}
                    sx={{
                      bgcolor: "grey.100",
                      height: "40px",
                      borderRadius: "20px",
                      p: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center"
                    }}>
                    <Typography className={smallFont}>
                      <strong>
                        {drawingData?.drawing.file.originalName.length &&
                        drawingData?.drawing.file.originalName.length > 10 &&
                        mobileScreen
                          ? drawingData?.drawing.file.originalName.substr(0, 10).concat('...')
                          : drawingData?.drawing.file.originalName}
                      </strong>
                    </Typography>
                    {drawings.length > 0 && (
                      <Box sx={{
                        pl: 1
                      }}>
                        <img src={downArrowIconThin} alt="Choose a drawing" width="15px" />
                      </Box>
                    )}
                  </Box>
                </BreadcrumbSelector>
              </Box>
            )}
          </Grid>
          <Grid size="auto">
            <AppIconButton
              className={button}
              noBorder
              onClick={() => setRenderingRotation((oldValue) => oldValue - 90)}
              disabled={renderingLoading}
            >
              <img alt="Rotate left" src={rotateLeftIcon} height="40px" className={roundedShadowDesktop} />
            </AppIconButton>
            <AppIconButton
              className={button}
              noBorder
              onClick={() => setRenderingRotation((oldValue) => oldValue + 90)}
              disabled={renderingLoading}
            >
              <img alt="Rotate right" src={rotateRightIcon} height="40px" className={roundedShadowDesktop} />
            </AppIconButton>
          </Grid>
          <Grid size="grow">
            <Box
              sx={{
                textAlign: "right",
                pr: 3
              }}>
              <AppIconButton
                noBorder
                // onClick={() =>
                //   [Mode.OPERATIVE, Mode.TECHNICIAN].includes(mode)
                //     ? history.push(`/${mode === Mode.OPERATIVE ? 'operative' : 'technician'}/works/${taskId}/assets`)
                //     : history.go(-1)
                // }
                onClick={() => navigate(-1)}
              >
                <img alt="Close drawing" src={cancelIcon} height="40px" className={roundedShadowDesktop} />
              </AppIconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {(drawingQueryLoading || renderingLoading || (taskAssetId && taskAssetDataLoading)) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
            position: "absolute",
            zIndex: 2
          }}>
          <CircularProgress />
        </Box>
      )}
      <Box
        className={wrapper}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          overflow: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <TransformWrapper defaultScale={1} wheel={{ step: 100 }}>
          <TransformComponent>
            <PdfRenderer setLoading={setRenderingLoading} src={renderingSrc} rotation={renderingRotation} />
          </TransformComponent>
        </TransformWrapper>
      </Box>
      {[Mode.OPERATIVE, Mode.TECHNICIAN].includes(mode) && assets && (
        <Box
          sx={{
            pb: 2,
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10
          }}>
          <ImageList className={gridList} cols={mobileScreen ? 1.1 : 3.3} cellHeight="auto">
            {assets.map((asset) => (
              <ImageListItem key={asset.id}>
                {/* TODO compliance */}
                <Paper className={assetCard}>
                  <Box sx={{
                    py: 1
                  }}>
                    <Typography variant="h4">{asset.asset.name}</Typography>
                  </Box>
                  <Grid container>
                    <Grid size={9}>
                      <Typography className={cardFont} variant="body1">
                        {t('assetTypes')(asset.asset.type)}
                      </Typography>
                      <Link
                        to={`/${mode === Mode.OPERATIVE ? 'operative' : 'technician'}/works/${taskId}/assets/${
                          asset.asset.id
                        }/info`}
                      >
                        <Typography className={cardFont} variant="body1" sx={{
                          color: "primary"
                        }}>
                          Read more info
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid size={3}>
                      {asset.task.status === TaskStatus.CONTRACTOR_ACCEPTED ||
                      asset.task.status === TaskStatus.IN_PROGRESS ||
                      (!taskAssetData?.taskAsset.task.adhoc &&
                        asset.task.type === TaskType.SAMPLING &&
                        asset.task.status === TaskStatus.COMPLETED) ? (
                        <button
                          onClick={() => {
                            if (
                              asset.task.status !== TaskStatus.IN_PROGRESS &&
                              asset.task.status !== TaskStatus.COMPLETED
                            ) {
                              setWarning(true);
                            } else {
                              navigate(
                                `/${mode === Mode.OPERATIVE ? 'operative' : 'technician'}/works/${taskId}/assets/${
                                  asset.id
                                }/form`,
                              );
                            }
                          }}
                          type="button"
                        >
                          <img src={editFormIcon} alt="Edit form" />
                        </button>
                      ) : null}
                    </Grid>
                  </Grid>
                </Paper>
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
      <WarningDialog
        open={warning}
        onClose={() => setWarning(false)}
        title="Cannot access forms"
        descriptionText="You first need to start the works timer to fill out forms."
      />
    </Box>
  );
};
export default DrawingPage;
