import React, { useState } from 'react';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useMutation } from '@apollo/client';
import {
  drawings as drawingsGQL,
  drawingsVariables,
  DrawingStatus,
  drawingUpdateStatus,
  drawingUpdateStatusVariables,
  drawingDelete,
  drawingDeleteVariables,
} from 'models/graphql';
import { Formik } from 'formik';
import { alpha } from '@mui/material/styles';
import AppTable from 'components/AppTable';
import ActionsButton from 'components/ActionsButton';
import AppButtonLink from 'components/AppButtonLink';
import { AppFormControlLabel, AppSwitch, AppSearchField } from 'components/form';
import cn from 'classnames';
import switcher from 'utils/switcher';
import apolloCacheEvict, { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import RemoveDialog from 'components/RemoveDialog';
import { GET_DRAWINGS, DELETE_DRAWING, UPDATE_DRAWING_STATUS } from './graphql';
import { useNavigate, useParams, useLocation } from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    status: {
      display: 'inline-block',
      paddingTop: parseInt(theme.spacing(1)) / 2,
      paddingBottom: parseInt(theme.spacing(1)) / 2,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: theme.fontSizes['12'],
      color: theme.palette.primary.main,
      background: alpha(theme.palette.primary.main, 0.25),
      textTransform: 'uppercase',
      borderRadius: theme.shape.borderRadius,
      '&.archived': {
        background: theme.palette.grey['400'],
        color: theme.palette.grey['600'],
      },
    },
    filter: {
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
    filterRight: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    filterShowArchived: {
      marginTop: theme.spacing(3),
      '& .MuiSwitch-root': {
        marginLeft: theme.spacing(1),
      },
    },
    searchField: {
      width: theme.sizes.containerWidth,
      maxWidth: '100%',
    },
  });
const useStyles = makeStyles(styles);

interface FormValues {
  search: string;
  showArchived: boolean;
}

const ClientDrawingsPage: React.FC = () => {
  const classes = useStyles();
  const { clientId } = useParams<{
    clientId: string;
  }>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeOnly, setActiveOnly] = useState(true);

  const [archive, setArchive] = useState(false);
  const [archiveData, setArchiveData] = useState<{
    id: string;
    status: DrawingStatus;
  }>();
  const closeArchive = () => setArchive(false);
  const openArchive = () => {
    setArchive(true);
  };

  const [deleteDrawingDialog, setDeleteDrawingDialog] = useState(false);
  const [deleteDrawingDialogData, setDeleteDrawingDialogData] = useState('');
  const closeDeleteDrawingDialog = () => setDeleteDrawingDialog(false);
  const openDeleteDrawingDialog = () => {
    setDeleteDrawingDialog(true);
  };

  const [updateStatus] = useMutation<drawingUpdateStatus, drawingUpdateStatusVariables>(UPDATE_DRAWING_STATUS, {
    update(cache, { data }) {
      if (!data) return;
      apolloCacheEvictQuery({ cache, query: 'drawings' });
      apolloCacheEvict({
        cache,
        typename: 'Site',
        id: data?.drawingUpdateStatus.site.id || '',
        fieldName: 'drawings',
      });
    },
  });

  const [deleteDrawing] = useMutation<drawingDelete, drawingDeleteVariables>(DELETE_DRAWING, {
    update(cache, { data }) {
      if (!data) return;
      apolloCacheEvictQuery({ cache, query: 'drawings' });
      apolloCacheEvict({
        cache,
        typename: 'Site',
        id: data?.drawingDelete.site.id,
        fieldName: 'drawings',
      });
    },
  });

  const { loading: drawingsQueryLoading, data: drawingsQuery, refetch: refetchDrawingsQuery } = useQuery<
    drawingsGQL,
    drawingsVariables
  >(GET_DRAWINGS, {
    variables: {
      clientId,
      activeOnly,
    },
  });

  return (
    <>
      <Formik<FormValues>
        initialValues={{
          search: '',
          showArchived: false,
        }}
        onSubmit={async (values) => {
          const { search, showArchived } = values;
          setActiveOnly(!showArchived);
          await refetchDrawingsQuery({
            clientId,
            search: search || undefined,
            activeOnly: !showArchived,
          });
        }}
      >
        {({ submitForm }) => (
          <div className={classes.filter}>
            <AppSearchField
              name="search"
              placeholder="Search by Drawing ID"
              onCommit={() => submitForm()}
              className={classes.searchField}
            />
            <div className={classes.filterRight}>
              <div>
                <AppButtonLink to="add" type="button" color="primary">
                  Upload Drawings
                </AppButtonLink>
              </div>

              <div>
                <AppFormControlLabel
                  control={<AppSwitch name="showArchived" onChange={() => submitForm()} />}
                  labelPlacement="start"
                  label="Show archived"
                  className={classes.filterShowArchived}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      <AppTable
        data={drawingsQuery && drawingsQuery.drawings}
        loading={drawingsQueryLoading}
        noDataMessage="No Drawings"
        rowLink={(row) => `${pathname}/${row.id}`}
        columns={[
          {
            key: 'site',
            name: 'Site',
            template: (row) => row.site?.name,
          },
          {
            key: 'drawingId',
            name: 'Drawing ID',
            template: (row) => row.drawingId,
          },
          {
            key: 'filename',
            name: 'File name',
            template: (row) => row.file?.originalName,
          },
          {
            key: 'systemType',
            name: 'System type',
            template: (row) => row.systemType,
          },
          {
            key: 'noLinkedAssets',
            name: 'NO of linked assets',
            template: (row) => row.assets.length,
          },
          {
            key: 'status',
            name: 'Status',
            align: 'center',
            template: function statusTemplate(row) {
              return (
                <div className={cn(classes.status, row.status === DrawingStatus.ARCHIVED ? 'archived' : '')}>
                  {switcher(row.status, [
                    [DrawingStatus.ACTIVE, 'active'],
                    [DrawingStatus.ARCHIVED, 'archived'],
                  ])}
                </div>
              );
            },
          },
          {
            key: 'actions',
            link: null,
            template: function ActionsTemplate(row) {
              return (
                <ActionsButton
                  actions={[
                    {
                      title: 'Edit Details',
                      action: () => {
                       // navigate(`drawings/${row.id}/edit`);
                       navigate(`${row.id}/edit`);
                      },
                    },
                    {
                      title:
                        switcher(row.status, [
                          [DrawingStatus.ACTIVE, 'Archive'],
                          [DrawingStatus.ARCHIVED, 'Activate'],
                        ]) || 'Archive',
                      action: () => {
                        setArchiveData({ id: row.id, status: row.status });
                        openArchive();
                      },
                    },
                    {
                      title: 'Delete',
                      action: () => {
                        setDeleteDrawingDialogData(row.id);
                        openDeleteDrawingDialog();
                      },
                    },
                  ]}
                />
              );
            },
          },
        ]}
      />
      <RemoveDialog
        open={deleteDrawingDialog}
        title="Delete Drawing"
        descriptionText="Drawing will be deleted."
        submitText="Delete"
        onSend={async () => {
          if (!deleteDrawingDialogData) return;
          await deleteDrawing({
            variables: {
              id: deleteDrawingDialogData,
            },
          });
        }}
        onClose={closeDeleteDrawingDialog}
      />
      <RemoveDialog
        open={archiveData !== undefined && archive}
        title={`${archiveData?.status === DrawingStatus.ACTIVE ? 'Archive' : 'Activate'} Drawing`}
        descriptionText={
          archiveData?.status === DrawingStatus.ACTIVE
            ? 'Drawing will be archived and cannot be linked by assets while archived.'
            : 'Drawing will be activated.'
        }
        submitText={archiveData?.status === DrawingStatus.ACTIVE ? 'Archive' : 'Activate'}
        onSend={async () => {
          if (!archiveData) return;
          await updateStatus({
            variables: {
              id: archiveData?.id,
              isActive: archiveData?.status === DrawingStatus.ACTIVE,
            },
          });
        }}
        onClose={closeArchive}
      />
    </>
  );
};

export default ClientDrawingsPage;
