import { useEffect } from 'react';
import { useStores } from 'stores';

const useHideDrawer = () => {
  const { contextStore } = useStores();
  useEffect(() => {
    contextStore.setDrawerHidden(true);
    return () => contextStore.setDrawerHidden(false);
  });
};

export default useHideDrawer;
