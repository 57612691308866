import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { motion } from 'motion/react';

import AppIconButton from 'components/AppIconButton';
import AppButton from 'components/AppButton';
import playIcon from 'assets/playIcon.svg';
import pauseIcon from 'assets/pauseIcon.svg';
import useText from 'texts/useText.hook';
import {
  taskComplete,
  taskCompleteVariables,
  taskStart,
  taskStartVariables,
  TaskStatus,
  taskTimerPause,
  taskTimerPauseVariables,
  taskTimerResume,
  taskTimerResumeVariables,
  taskForUser_task as Task,
  AssetType,
  taskSubmitSuppInfo,
  taskSubmitSuppInfoVariables,
  taskTimerForUser,
  taskTimerForUserVariables,
  taskForClient_task as ClientTask,
  taskAssets,
  taskAssetsVariables,
} from 'models/graphql';
import resolveGraphqlErrorMessage from 'utils/resolveGraphqlErrorMessage';
import GraphqlErrorCode from 'shared/graphqlErrorCodes';
import AppDialog from 'components/AppDialog';
import useModalState from 'utils/useModalState';
import timeout from 'utils/timeout';
import {
  COMPLETE_TASK,
  GET_TASK_ASSETS,
  GET_TASK_TIMER,
  PAUSE_TASK_TIMER,
  RESUME_TASK_TIMER,
  START_TASK,
  SUBMIT_SUP_INFO,
} from 'containers/shared/Technician/graphql';
import TaskTimerCounter from './TaskTimerCounter';
import { Box, CircularProgress, Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  const smallScreenSize = 350;
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      padding: `0 ${theme.spacing(2)}`,
      [theme.breakpoints.down(smallScreenSize)]: {
        padding: theme.spacing(1),
      },
      background: theme.palette.background.default,
    },
    timeSpent: {
      marginRight: 'auto',
    },
    timeSpentText: {
      marginBottom: theme.spacing(0.2),
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[600],
    },
    timerControl: {
      marginRight: theme.spacing(1),
    },
    hideOnSmallScreen: {
      [theme.breakpoints.down(smallScreenSize)]: {
        display: 'none',
      },
    },
    displayOnSmallScreen: {
      [theme.breakpoints.up(smallScreenSize)]: {
        display: 'none',
      },
    },
    suppInfoSubmittedText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
    },
    timerDesktop: {
      boxShadow: theme.shadows[6],
    },
    timer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: theme.shadows[6],
    },
  };
});

const delayedExecution = async (func: () => Promise<unknown>): Promise<void> => {
  await Promise.all([func(), timeout(500)]);
};

const TaskController: React.FC<{ task: Task | ClientTask; size: number; desktop?: boolean; mode?: boolean }> = ({
  task,
  size,
  desktop,
  mode,
}) => {
  const timerSizeWithShadow = size * 1.1;

  // Queries
  const { data: taskData } = useQuery<taskTimerForUser, taskTimerForUserVariables>(GET_TASK_TIMER, {
    variables: { id: task.id },
    fetchPolicy: 'network-only', // disable cache for the timer counter
    notifyOnNetworkStatusChange: true,
  });
  const timer = taskData?.task.timer;

  const { data: taskAssetsData } = useQuery<taskAssets, taskAssetsVariables>(GET_TASK_ASSETS, {
    variables: { id: task.id },
  });

  // Mutations
  const [startTask, { loading: startTaskLoading }] = useMutation<taskStart, taskStartVariables>(START_TASK, {
    variables: { id: task.id },
  });
  const [completeTask, { loading: completeTaskLoading }] = useMutation<taskComplete, taskCompleteVariables>(
    COMPLETE_TASK,
    {
      variables: { id: task.id },
    },
  );
  const [submitSupInfo, { loading: submitSupInfoLoading }] = useMutation<
    taskSubmitSuppInfo,
    taskSubmitSuppInfoVariables
  >(SUBMIT_SUP_INFO, {
    variables: { id: task.id },
  });
  const [pauseTask, { loading: pauseTaskLoading }] = useMutation<taskTimerPause, taskTimerPauseVariables>(
    PAUSE_TASK_TIMER,
    {
      variables: { id: task.id },
    },
  );
  const [resumeTask, { loading: resumeTaskLoading }] = useMutation<taskTimerResume, taskTimerResumeVariables>(
    RESUME_TASK_TIMER,
    {
      variables: { id: task.id },
    },
  );

  const [
    isIncompleteFormsDialogOpen,
    incompleteFormsDialogContent,
    incompleteFormsDialogOpen,
    incompleteFormsDialogClose,
  ] = useModalState<string[]>();

  const taskPausable = (timer?.onGoing || resumeTaskLoading) && !pauseTaskLoading;

  const styles = useStyles();
  const theme = useTheme();
  const { t } = useText('tasks', 'assetTypes');
  return task && timer && task.status !== TaskStatus.SENT ? (
    <motion.div
      className={desktop === true ? styles.timerDesktop : styles.timer}
      initial={{ translateY: timerSizeWithShadow }}
      animate={{ translateY: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.3 }}
    >
      <Box sx={{
        height: size
      }}>
        <div className={styles.container}>
          <div className={styles.timeSpent}>
            <div className={styles.timeSpentText}>{t('tasks')('timeSpent')}</div>
            <div>
              <TaskTimerCounter seconds={timer.timeSpent} onGoing={taskPausable} />
            </div>
          </div>
          {task.status === TaskStatus.IN_PROGRESS ? (
            <>
              {resumeTaskLoading || pauseTaskLoading ? (
                <AppIconButton className={styles.timerControl}>
                  <CircularProgress size={theme.sizes.iconNormal} />
                </AppIconButton>
              ) : (
                <>
                  {taskPausable ? (
                    <AppIconButton onClick={() => pauseTask()} className={styles.timerControl}>
                      <img alt="pause" src={pauseIcon} />
                    </AppIconButton>
                  ) : (
                    <AppIconButton onClick={() => resumeTask()} className={styles.timerControl}>
                      <img alt="resume" src={playIcon} />
                    </AppIconButton>
                  )}
                </>
              )}
            </>
          ) : null}
          {task.status === TaskStatus.CONTRACTOR_ACCEPTED ? (
            <>
              {taskAssetsData?.task.adhoc && !taskAssetsData?.task.assets.length ? (
                <AppButton short disabled>
                  To start works choose assets in asset list tab
                </AppButton>
              ) : (
                <AppButton short onClick={() => delayedExecution(startTask)} inProgress={startTaskLoading}>
                  {mode ? t('tasks')('startTasks') : t('tasks')('startWorks')}
                </AppButton>
              )}
            </>
          ) : null}
          {task.status === TaskStatus.IN_PROGRESS ? (
            <AppButton
              short
              onClick={async () => {
                try {
                  await delayedExecution(completeTask);
                } catch (e) {
                  const [code, incompleteAssetsString] = resolveGraphqlErrorMessage(e);
                  if (code === GraphqlErrorCode.assetsIncomplete && incompleteAssetsString) {
                    const incompleteAssets = JSON.parse(incompleteAssetsString) as Array<{
                      name: string;
                      type: AssetType;
                    }>;
                    incompleteFormsDialogOpen(
                      incompleteAssets.map((asset) => `${asset.name} - ${t('assetTypes')(asset.type)}`),
                    );
                  }
                }
              }}
              inProgress={completeTaskLoading}
            >
              <span className={styles.hideOnSmallScreen}>
                {mode ? t('tasks')('completeTasks') : t('tasks')('completeWorks')}
              </span>
              <span className={styles.displayOnSmallScreen}>{t('tasks')('complete_short')}</span>
            </AppButton>
          ) : null}
          {task.status === TaskStatus.COMPLETED ? (
            <AppButton short onClick={() => delayedExecution(submitSupInfo)} inProgress={submitSupInfoLoading}>
              {t('tasks')('submitSuppInfo')}
            </AppButton>
          ) : null}
          {task.status === TaskStatus.SUPP_INFO_SUBMITTED ? (
            <div className={styles.suppInfoSubmittedText}>{t('tasks')('submittedSuppInfo')}</div>
          ) : null}
        </div>

        <AppDialog
          open={isIncompleteFormsDialogOpen}
          onClose={() => {
            incompleteFormsDialogClose();
          }}
          type="error"
          title={mode ? t('tasks')('completeErrorTitleTasks') : t('tasks')('completeErrorTitleWorks')}
        >
          {t('tasks')('completeErrorIncompleteAssetsDescription')}
          <br />
          <br />
          {incompleteFormsDialogContent?.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </AppDialog>
      </Box>
    </motion.div>
  ) : null;
};

export default TaskController;
