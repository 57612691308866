import React, { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router';
import useMode, { Mode } from 'utils/useMode.hook';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useMutation, useQuery } from '@apollo/client';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { alpha } from '@mui/material/styles';
import { Box, Theme, useMediaQuery, useTheme, Button, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Item from 'components/Item';
import useText from 'texts/useText.hook';
import MUIDataTable from 'mui-datatables';
import StatusBadge, { StatusBadgeState } from 'components//StatusBadge';      // Keep separate for moment
import { TaskStatus } from 'models/graphql';
import iotIcon from 'assets/iotIcon.svg';
import tempIcon from 'assets/tempIcon.svg';
import nitrateIcon from 'assets/nitrateIcon.svg';
import dropletIcon from 'assets/dropletIcon.svg';
import depthIcon from 'assets/depthIcon.svg';
import solidsIcon from 'assets/solidsIcon.svg';
import moleculeIcon from 'assets/moleculeIcon.svg';
import codIcon from 'assets/codIcon.svg';
import flowIcon from 'assets/flowIcon.svg';
import velocityIcon from 'assets/velocityIcon.svg';
import levelIcon from 'assets/levelIcon.svg';
import phIcon from 'assets/phIcon.svg';
import conductIcon from 'assets/conductIcon.svg';
import filmreelIcon from 'assets/filmreelIcon.svg';
import PumpsDialog from 'components/AppTable/components/PumpsDialog';
import TimeSeriesDialog from 'components/AppTable/components/TimeSeriesDialog';
import FootageViewer from 'components/AppTable/components/FootageViewer';
import DetailsPanelSection from './components/DetailsPanelSection';
import { PumpService } from './PumpData';
import { assetsForSite_site_assets_pumpInfo as PumpInfoType, AssetType } from 'models/graphql';
import {
  Typography,
} from '@mui/material';
import moment from 'moment';
import compliantIcon from 'assets/compliantIcon.svg';
import nonCompliantIcon from 'assets/nonCompliantIcon.svg';
import assetReport from 'assets/assetReport.svg';
import assetLink from 'assets/assetLink.svg';
import cn from 'utils/cn';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import { DOWNLOAD_REQUEST } from 'components/FileInput/graphql';
import {
  downloadRequest,
  downloadRequestVariables,
} from 'models/graphql';

const buttonSize = 40;
const buttonDotSize = 6;
const useStyles = makeStyles((theme: Theme) => ({
  profileDataContainer: {
    padding: theme.spacing(3),
  },
  ownerContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 5fr',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  taskallButton: {
    backgroundColor: "#8c8484",
    color: "white",
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  buttonContainer: {
    position: 'relative',
  },
  button: {
    padding: 0,
    minWidth: 0,
    width: buttonSize,
    height: buttonSize,
    background: theme.palette.grey['100'],
    borderRadius: '50%',
  },
  iotIcon: {
    marginLeft: 'auto',
  },
  linkIcon: {
    marginLeft: '3%',
  },
  value: {
    color: theme.palette.grey[500],

    '&.danger': {
      color: theme.palette.error.light,
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  label: {
    paddingBottom: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold,
    color: "#000000",
    '&.small': {
      fontSize: theme.fontSizes[12],
    },
  },
}));



const getFormattedDateTime = (date) => {
  return date.toISOString().replace(/\.\d{3}Z$/, 'Z'); // Converts to ISO string and removes milliseconds
};

const getCurrentAndPreviousDateTime = () => {
  const currentDate = new Date(); // Current date/time
  const previousDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago

  const currentFormattedDateTime = getFormattedDateTime(currentDate);
  const previousFormattedDateTime = getFormattedDateTime(previousDate);

  return { currentFormattedDateTime, previousFormattedDateTime };
};






const UpcomingSwitch = withStyles({
  switchBase: {
    color: '#9D9D9C',
    '&$checked': {
      color: '#44924D',
    },
    '&$checked + $track': {
      backgroundColor: '#44924D',
    },
  },
  checked: {},
  track: {},
})(Switch);

interface PumpInfo {
  __typename: string;
  id: string;
  name: string;
  pump1: boolean;
  pump2: boolean;
  temp: string;
  batt: number;
  tankLevel: number;
  pump1runtime: string;
  pump2runtime: string;
}

export default function ExpandedRows(props: any) {
  const [tasksCall, setTasksCall] = useState( [] )
  const [drawingsCall, setDrawingsCall] = useState( [] )
  const [documentsCall, setDocumentsCall] = useState( [] )
  const [footageCall, setFootageCall] = useState( [] )
  const [footageFound, setFootageFound] = useState( false )
  let pumpsInfo: Array<PumpInfoType> = [];
  let pumpDisplay: ({ __typename: string; id: string; name: any; pump1: boolean; pump2: boolean; temp: any; batt: number; tankLevel: number; pump1runtime: any; pump2runtime: any; } | null)[] | null = [];

  const styles = useStyles();
  const theme = useTheme();

  

  const { t } = useText('assetTypes', 'taskTypes', 'taskStatuses');
  const isTablet = useMediaQuery('(max-width:1024px)');
  const { pathname } = useLocation();
  const state = useMode();
  const mode = state.mode !== Mode.OPERATIVE && state.mode !== Mode.TECHNICIAN;
  const { siteId, clientId } = useParams<{ siteId: string; clientId: string }>();
  const [pumpDataToShow, setPumpDataToShowl] = useState(false )
  const [linkedAssetsToShow, setLinkedAssetoShow] = useState(false )

  const [temperatureValue, setTemperatureValue] = useState(null)
  const [temperatureHistoric, setTemperatureHistoric] = useState(null)
  const [turbidityHistoric, setTurbidityHistoric] = useState(null)
  const [conductHistoric, setConductHistoric] = useState(null)
  const [phHistoric, setPhHistoric] = useState(null)
  const [no3Historic, setNo3Historic] = useState(null)
  const [nh4Historic, setNh4Historic] = useState(null)
  const [codHistoric, setCodHistoric] = useState(null)
  const [tssHistoric, setTssHistoric]  = useState(null)
  const [levelHistoric, setLevelHistoric]  = useState(null)

  const [phValue, setPhValue] = useState(null)
  const [conductivityValue, setConductivityValue] = useState(null)
  const [turbidityValue, setTurbidityValue] = useState(null)

  const [flowValue, setFlowValue] = useState(null)
  const [depthValue, setDepthValue] = useState(null)
  const [velocityValue, setVelocityValue] = useState(null)

  const [depthHistoric,setDepthHistoric] = useState(null)
  const [velocityHistoric,setVelocityHistoric] = useState(null)
  const [flowHistoric,setFlowHistoric] = useState(null)

  const [wincanFootage, setWincanFootage]  = useState(null)

  const [fullPumpDisplay, setFullPumpDisplay] = useState( [] )

  const [showCamera, setShowCamera]  = React.useState(false);

  const assetcoords=JSON.parse(props.index.coordinates);
  const tasktitle = 'Tasks (Upcoming & Recent)';

  let pumpinfo;
  let pumpinfofull;
  let sampledata = { "id": "2wce40","name": "McBreen","pump1": true, "pump2": false, "temp": 40,"batt": 91.6, "tankLevel": 38,"pump1runtime": 627889, "pump2runtime": 23,"__typename": "FetchedPumpInfoOutput"};

  const [timeData, setTimeData] = useState(null);
  const [dialogTitle, setDialogTitle] = useState('');

  const [getSignedDownloadUrlMutation] = useMutation<downloadRequest, downloadRequestVariables>(DOWNLOAD_REQUEST);
  


  // if pump info details exists, connect to service and get latest values

 /* if (JSON.parse(props.index.specificinfo).pumps.length > 0)
  {
    //  pumpDisplay.push(sampledata)
  } */
/* if (JSON.parse(props.index.specificinfo).pumps.length > 0)
{
 
     async function  getpumpdata (pumpcreds: any) {
          const PumpFetcher = new PumpService();
        
          const fetchedPumpInfo = await PumpFetcher.fetchPumpInfo(pumpcreds.name, pumpcreds.pass);
          if (fetchedPumpInfo) {
            pumpsInfo.push(fetchedPumpInfo);         
            pumpDisplay.push(fetchedPumpInfo)      
          }
     }
 
   pumpDisplay.push(sampledata)
} */
let columnsMUITable = [
   
  {
      name: "idreadable",
      label: "Task",
      options: { 
        setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          left: "0",
          background: "white",
          zIndex: 100
        }}),
        setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px',position: "sticky", zIndex: 101  }}),
        customBodyRender: (value, tableMeta, updateValue) => {
          let tasklink = '/client/' + clientId + '/tasks/' + siteId + '?search=' +value;
          return (
            <a href={tasklink}>{value}</a>
          );
        }
      }
  },
  {
      name: "tasktitle",
      label: "Task Title",
      options: {
        setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
        
      }
  },
  {
      name: "status",
      label: "Status",
      options: {
        setCellProps: () => ({ style: { minWidth: "50%", maxWidth: "50%" }}),
        setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px', alignItems: 'center', textAlign: 'center' }}),
        customBodyRender: (statusvalue: string) => {
          let state: StatusBadgeState;
      
          if (statusvalue.toUpperCase() === TaskStatus.SENT ||statusvalue.toUpperCase()  === TaskStatus.REVIEW_REQ_SENT) {
            state = { background: alpha(theme.colors.yellow, 0.25) };
          } else if (
            statusvalue.toUpperCase()  === TaskStatus.CONTRACTOR_ACCEPTED ||
            statusvalue.toUpperCase()  === TaskStatus.CONTRACTOR_REJECTED ||
            statusvalue.toUpperCase()  === TaskStatus.IN_PROGRESS ||
            statusvalue.toUpperCase()  === TaskStatus.COMPLETED ||
            statusvalue.toUpperCase()  === TaskStatus.SUPP_INFO_SUBMITTED
          ) {
            state = { background: alpha(theme.colors.orange, 0.25) };
          } else if (
            statusvalue.toUpperCase()  === TaskStatus.REVIEW_REQ_REJECTED ||
            statusvalue.toUpperCase()  === TaskStatus.REVIEW_REQ_ACCEPTED ||
            statusvalue.toUpperCase()  === TaskStatus.CONSULTANT_REJECTED ||
            statusvalue.toUpperCase()  === TaskStatus.CONSULTANT_APPROVED
          ) {
            state = { background: alpha(theme.colors.blue, 0.25) };
          } else if (statusvalue.toUpperCase()  === TaskStatus.REJECTED_CLOSED || statusvalue.toUpperCase()  === TaskStatus.REJECTED) {
            state = { background: alpha(theme.palette.error.main, 0.25) };
          } else if (statusvalue.toUpperCase()  === TaskStatus.APPROVED_CLOSED) {
            state = { background: alpha(theme.palette.primary.main, 0.25) };
          } else if (statusvalue.toUpperCase()  === TaskStatus.CANCELLED) {
            state = { background: alpha('#000000', 0.12) };
          } else {
            state = { background: 'transparent' };
          }
        
          return (
            (<StatusBadge block state={state}>
              {statusvalue.replace(/_+/g, ' ')}
            </StatusBadge>)
          );


        }
      }
  },
  {
      name: "tasktype",
      label: "Type",
      options: {
        setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
      }
  },
  {
     name: "deadline",
     label: "Deadline",
     options: {
       setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
       sort: true,
       customBodyRender: (value: string | number | Date) =>
       moment(new Date(value)).format('DD/MM/YYYY')
     }
  },
  {
    name: "internalresponsible",
    label: "Responsible",
    options: {
      setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
    },
  },
  {
    name: "compliant",
    label: "Compliant",
    options: {
      filter: false, 
      setCellProps: () => ({ style: { minWidth: "50%", maxWidth: "50%", alignItems: 'center', textAlign: 'center' }}),
      setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px', alignItems: 'center', textAlign: 'center' }}),
        customBodyRender: (value: boolean) => {
          if (value === true) {
  
            return (
            <>
            
                <img alt="compliant" src={compliantIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
      
            
            </>
              
              );
          }
          if (value === false) {
            return (
              <>
              <img alt="non compliant" src={nonCompliantIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
              </>
            );
          }
       
  
  
  
      }
    }
    },
]

  
  const [tasksTitleDisplay, setTitleDisplay] = useState( tasktitle )
  const [tasksToDisplay, setTasksToDisplay] = useState( 0 )
  const [tasksButtonText, seTasksButtonText] = useState( 'All Tasks' )
  const [pumpPopup, setPumpPopup] = React.useState(false);
  const [tempPopup, setTempPopup] = React.useState(false);   
  const [turbPopup, setTurbPopup] = React.useState(false); 
  const [phPopup, setPhPopup] = React.useState(false); 
  const [conductPopup, setConductPopup] = React.useState(false); 
  const [depthPopup, setDepthPopup] = React.useState(false); 
  const [velocityPopup, setVelocityPopup] = React.useState(false); 
  const [flowPopup, setFlowPopup] = React.useState(false); 
  const [showLinkedAssets, setShowLinkedAssets] = useState( false )
  const [checked, setChecked] = useState(false);
  const [codPopup, setCodPopup] = React.useState(false); 
  const [no3Popup, setNo3Popup] = React.useState(false); 
  const [nh4Popup, setNh4Popup] = React.useState(false); 
  const [tssPopup, setTssPopup] = React.useState(false); 
  const [levelPopup, setLevelPopup] = React.useState(false); 
  const [footagePopup, setFootagePopup] = React.useState(false); 

  const [tasksColumnDisplay, setTasksColumnDisplay] = useState(columnsMUITable)

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  
  const getMuiTheme = () => {
    return createTheme({
      components: {
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#bce0d5"
            },
            actions: {
              backgroundColor: "#bce0d5"
            }
          }
        }
      }
    })
  };

  const changeTaskView = () => {
   
    if (tasksToDisplay === 1)
    {
      setTasksToDisplay(0)
    }
    else
    {
      setTasksToDisplay(1)
    }

  }


  const columnsMUITableLinkedAssets = [
    
    {
        name: "name",
        label: "Asset",
        options: { 
          setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100
          }}),
          setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px',position: "sticky", zIndex: 101  }}),
        }
    },
    {
      name: "compliant",
      label: "Compliant",
      options: {
        filter: false, 
        setCellProps: () => ({ style: {  minWidth: "50%", maxWidth: "50%"}}),
        setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px', alignItems: 'left', textAlign: 'left' }}),
          customBodyRender: (value: boolean) => {
            if (value === true) {
            return (
              <>
                  <img alt="compliant" src={compliantIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
              </>
                );
            }
            if (value === false) {
              return (
                <>
                <img alt="non compliant" src={nonCompliantIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
                </>
              );
            }
        }
      }
    },
  ]


  const expAssetRep = async (assetid: any) => {
    console.log("Export Asset Report:", assetid)

    // need the asset ID to pass to the backend to generate the asset report which also contain all historical tasks that have been completed
   // assetexportreport
   const urltogoto = 'listings/assetexportreport?id='
   const curtime = new Date();
   // create filename for file
   const yyyy = curtime.getFullYear();
   const mm = curtime.getMonth() < 9 ? "0" + (curtime.getMonth() + 1) : (curtime.getMonth() + 1); // getMonth() is zero-based
   const dd  = curtime.getDate() < 10 ? "0" + curtime.getDate() : curtime.getDate();
   const hh = curtime.getHours() < 10 ? "0" + curtime.getHours() : curtime.getHours();
   const mmm  = curtime.getMinutes() < 10 ? "0" + curtime.getMinutes() : curtime.getMinutes();
   const ss  = curtime.getSeconds() < 10 ? "0" + curtime.getSeconds() : curtime.getSeconds();
   
   const ncfile = "asset_"+yyyy+mm+dd+"_"+hh+mmm+ss+".xlsx";
   try {
     await fetch(`${process.env.API_ROOT}${urltogoto}${assetid}&incup=${checked}`, {
       method: 'GET',
       credentials: 'include',
     }).then(response => response.blob())
     .then(blob => {
         var url = window.URL.createObjectURL(blob);
         var a = document.createElement('a');
         a.href = url;
         a.download = ncfile;
         document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
         a.click();
         a.remove();  //afterwards we remove the element again 

     });
    } catch (error) {
        console.error(error); /* eslint-disable-line */
    }
}



  const getAppTasks = async () => {
    const urltogoto = 'listings/tasks/';
    let titleTasks = '';
    let taskButton = '';
    let fulllink=`${urltogoto}${props.index.id}`;

    if (tasksToDisplay === 1)
    {
      titleTasks = 'All Tasks'
      taskButton = 'Recent & Upcoming' 
      fulllink = `${urltogoto}${props.index.id}`
    }
    else
    {
      titleTasks = 'Tasks (Upcoming & Recent)'
      taskButton = 'All Tasks'
      fulllink = `${urltogoto}${props.index.id}?initial=yes`
    }
    // fetch all tasks
    try {
      fetch(`${process.env.API_ROOT}${fulllink}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
          const dataret = await response.json();
          const taskData = dataret.map((u: { taskid: string; status: string; tasktitle: string; idreadable: string; compliant: any; tasktype: any; internalresponsible: any; deadline: any; documentdeadline: any; }) => {
            return {
              "taskid": u.taskid,
              "status": u.status,
              "tasktitle": u.tasktitle,
              "idreadable": u.idreadable,
              "compliant": u.compliant,
              "tasktype":u.tasktype,
              "internalresponsible": u.internalresponsible,
              "deadline": u.deadline,
              "documentdeadline": u.documentdeadline,
            };  
          });
          setTasksCall(dataret);
          setTitleDisplay(titleTasks)
          seTasksButtonText(taskButton)
          setTasksColumnDisplay(columnsMUITable)
          setShowLinkedAssets(false);
        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }

      
  }

  const hideModal = () => {
    setShowCamera(false)
  };

  const getPumpDataForDialog = async () => {
      
      // get the data for display
      const PumpFetcher = new PumpService();
      const fetchedPumpInfo = await PumpFetcher.fetchPumpInfo(JSON.parse(props.index.specificinfo).pumps[0].name, JSON.parse(props.index.specificinfo).pumps[0].pass);

    /*  pumpDisplay.push(fetchedPumpInfo)
       */
      pumpDisplay.unshift(fetchedPumpInfo)

      setFullPumpDisplay(pumpDisplay)
      setPumpDataToShowl(true);
  }

  useEffect(() => {
      console.log("pumpDataToShow is equal to ", pumpDataToShow)
   
      if (pumpDataToShow === true)
      {
        console.log("WILL NOW SET THE DATA")
       
      }
    
  }, [pumpDataToShow]);

  
  const handleOpenDialog = (data: any, title: string) => {
    setTimeData(data);
    setDialogTitle(title);

    if (title === 'Turbidity')
    {
      setTurbPopup(true);
    }
    if (title === 'Temperature')
    {
        setTempPopup(true);
    }
    if (title === 'pH')
    {
      setPhPopup(true);
    }
    if (title === 'Conductivity')
    {
      setConductPopup(true);
    }
    if (title === 'Depth')
    {
        setDepthPopup(true);
    }
    if (title === 'Velocity')
    {
        setVelocityPopup(true);
    }
    if (title === 'Flow')
    {
        setFlowPopup(true);
    }
    if (title === 'NO3')
    {
      setNo3Popup(true);
    }
    if (title === 'COD')
    {
      setCodPopup(true);
    }
    if (title === 'TSS')
    {
        setTssPopup(true);
    }
    if (title === 'NH4')
    {
        setNh4Popup(true);
    }
      
    if (title === 'Level')
    {
        setLevelPopup(true);
    }
        
    if (title === 'Clips')
    {
        setFootagePopup(true);
    }
  
  };

  const ExtraButtonsAllTasks = () => {

    if (linkedAssetsToShow)
    {
      return (
        <>
          <Button onClick={changeTaskView} className={styles.taskallButton}> {tasksButtonText}</Button>   
          <Tooltip title='View Linked Assets'> 
          <Button className={styles.linkIcon}  onClick={() => setShowLinkedAssets(true)} >
            <img src={assetLink} alt="Linked Assets" />
         </Button>
         </Tooltip>
        </>
      ) ;
    }
    else
    { return (
      <>
      <Button onClick={changeTaskView} className={styles.taskallButton}> {tasksButtonText}</Button>    
      </>)
    }
  
  }

 

  const tableoptions = {
    filter: false,
    print: false,
    customToolbar: ExtraButtonsAllTasks, 
    selectableRows: 'none',
    search: false,
    download: false,
    viewColumns: false,
    tableBodyHeight: "300px",
    fixedHeader: true,
    jumpToPage: false
  };
  
  useEffect(() => {
    getAppTasks();
  }, [tasksToDisplay]);


  useEffect(() => {
    getApLinkedAssets();
  }, [showLinkedAssets]);


  const getApLinkedAssets = async () => {

    if (showLinkedAssets)
    {

      const urltogoto = 'listings/assetlink/';
      let fulllink=`${urltogoto}${props.index.id}`;
  
      // fetch linked assets
      try {
        fetch(`${process.env.API_ROOT}${fulllink}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
            const dataret = await response.json();
           
            const taskData = dataret.map((u: { id: string; name: string; compliant: any; }) => {
              return {
                "id": u.id,
                "name": u.name,
                "compliant": u.compliant,
              };  
              
            });
            console.log("taskData  for linked assets:", taskData)
            setTasksCall(dataret);
    
           
          });
        } catch (error) {
          console.error(error); /* eslint-disable-line */
        }
  
        


    }


  }

  useEffect(() => {
  
    async function getSignedDownloadUrl(fileId: string) {
      console.log("fileId:", fileId)
      const resp = await getSignedDownloadUrlMutation({ variables: { fileId } });
      console.log("have made call, uRL is:",resp.data?.downloadRequest.url)
      return resp.data?.downloadRequest.url;
    }

    const set = async (filteredFilespassed) => {
      const fileElements = [];
      console.log("filteredFilespassed:", filteredFilespassed)
      for (let i = 0; i < filteredFiles.length; i++) {

        const src = await getSignedDownloadUrl(filteredFiles[i].fileid);
        console.log("src:", src)

        let fileDisplay = {"displayname":filteredFiles[i].displayname, "link":src, "uploaddate":filteredFiles[i].uploadedat}

        fileElements.push(fileDisplay);

      }
     
        
        console.log("fileElements:", fileElements)

        setFootageCall(fileElements);
        setFootageFound(true)
     
    };


    const filteredFiles = documentsCall.filter(file =>
        file.extension.toLowerCase() === "avi" || file.extension.toLowerCase() === "mpg" || file.extension.toLowerCase() === "mp4"
    );

    if (filteredFiles.length > 0)
    {
      set (filteredFiles);   
    }

  
  },[documentsCall]);




  useEffect(() => {
    // Fetch data

    const urltogoto = 'listings/tasks/'
    
    try {
      fetch(`${process.env.API_ROOT}${urltogoto}${props.index.id}?initial=yes`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
          const dataret = await response.json();

          const taskData = dataret.map((u: { taskid: string; status: string; tasktitle: string; idreadable: string; compliant: any; tasktype: any; internalresponsible: any; deadline: any; documentdeadline: any; }) => {
            return {
              "taskid": u.taskid,
              "status": u.status,
              "tasktitle": u.tasktitle,
              "idreadable": u.idreadable,
              "compliant": u.compliant,
              "tasktype":u.tasktype,
              "internalresponsible": u.internalresponsible,
              "deadline": u.deadline,
              "documentdeadline": u.documentdeadline,
            };  
          });
          setTasksCall(dataret);
          const urltogotolinks = 'listings/assetlink/'
          // Need to also check if asset is linked - assetlink
          try {
            fetch(`${process.env.API_ROOT}${urltogotolinks}${props.index.id}`, {
              method: 'GET',
              credentials: 'include',
            }).then(async (response) => {
              const dataretlink = await response.json();
              console.log("dataretlink:", dataretlink.length)
              if (dataretlink.length > 0)
              {
                setLinkedAssetoShow(true)
              }
              else
              {
                setLinkedAssetoShow(false)
              }
              console.log("DEAL WITH LINKS")
            });
          } catch (error) {
            console.error(error); /* eslint-disable-line */
          }




        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }

      // check if pump data to be got
      if (props.index.specificinfo !== null)
      {
      
          if (JSON.parse(props.index.specificinfo).pumps.length > 0)
            {
                let fulldetails = JSON.parse(props.index.specificinfo).pumps[0];

                if (fulldetails)
                {
                  getPumpDataForDialog();
                }

            } 
      }

      const urltogotodrawings = 'listings/drawings/'
      // fetch the drawings
      try {
        fetch(`${process.env.API_ROOT}${urltogotodrawings}${props.index.id}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
            const dataret = await response.json();
  
  
            const taskData = dataret.map((u: { assetid: string; drawingid: string; addid: string; fileid: string; extension: any; fullname: any; originalname: any; }) => {
              return {
                "assetid": u.assetid,
                "drawingid": u.drawingid,
                "addid": u.addid,
                "fileid": u.fileid,
                "extension": u.extension,
                "fullname":u.fullname,
                "originalname": u.originalname,
              };  
            });
            setDrawingsCall(dataret);
          });
        } catch (error) {
          console.error(error); /* eslint-disable-line */
        }



        const urltogotodocuments = 'listings/documents/'
        // fetch the drawings
        try {
          fetch(`${process.env.API_ROOT}${urltogotodocuments}${props.index.id}`, {
            method: 'GET',
            credentials: 'include',
          }).then(async (response) => {
              const dataret = await response.json();
    
    
              const taskData = dataret.map((u: { assetid: string; documentid: string; addid: string; fileid: string; extension: any; fullname: any; originalname: any; displayname:any; updated_at: any}) => {
                return {
                  "assetid": u.assetid,
                  "drawingid": u.documentid,
                  "addid": u.addid,
                  "fileid": u.fileid,
                  "extension": u.extension,
                  "fullname":u.fullname,
                  "originalname": u.originalname,
                  "displayname": u.displayname,
                  "uploadedat": u.updated_at,
                };  
              });

          
              setDocumentsCall(taskData);
            });
          } catch (error) {
            console.error(error); /* eslint-disable-line */
          }




     
        const iotSources =  props.index.iotsource;
        console.log("iotSources:", iotSources)


        // Check for ayyeka
        if (iotSources === '11ba89c6-d425-4bdd-a832-dc9acc860905')
        {

          const iotAvail =  props.index.iotresources;
          const availableEntries = iotAvail.available.split(',');

          const currentDate = new Date();

          // Subtract 24 hours (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
          const hoursAgo24 = new Date(currentDate.getTime() - (24 * 60 * 60 * 1000));

          console.log("conv:", hoursAgo24.toISOString().split('T')[0])
          console.log("conv:", hoursAgo24.toISOString())


          

          const epochMillisNow = Math.floor(Date.now() / 1000);

          const epochMillis24 = Math.floor(new Date(hoursAgo24.toISOString()) / 1000) ;

          console.log(`Epoch time in milliseconds: ${epochMillis24}`);

          console.log(`Epoch NOW time in milliseconds: ${epochMillisNow}`);

          console.log("hoursAgo24:",hoursAgo24)

          for (let entry of availableEntries) {

            //let urltogotohist =   `iot/apiparam?assetid=${props.index.id}&param=${entry}&startdate=${epochMillis24}&enddate=${epochMillisNow}`
            let urltogotohist =   `iot/apiparam?assetid=${props.index.id}&param=${entry}&startdate=${hoursAgo24}&enddate=${currentDate}`
            console.log("urltogotohist:", urltogotohist)
            
            const getHistoricLevels = async (urltogotohist:any) => {
              try {
                  await fetch(`${process.env.API_ROOT}${urltogotohist}`,{
                    method: 'GET',
                    credentials: 'include',
                  }).then(async (response) => {
                  const historicData = await response.json();
                  console.log("historicData:", historicData)
                  setLevelHistoric(historicData)
                  })
                }catch (error) {
                  console.error(error); /* eslint-disable-line */
              }
            }
            getHistoricLevels(urltogotohist);
           }
        }




        // check for capital water
        if (iotSources === '896a2c20-2f3a-4942-b2a5-273d6323482c')
        {
          const iotAvail =  props.index.iotresources;
          const availableEntries = iotAvail.available.split(',');

          const currentDate = new Date();

          // Subtract 24 hours (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
          const hoursAgo24 = new Date(currentDate.getTime() - (24 * 60 * 60 * 1000));


          // loop through each one
          for (let entry of availableEntries) {

             let urltogotohist =   `iot/apiparam?assetid=${props.index.id}&param=${entry}&startdate=${hoursAgo24.toISOString().split('T')[0]}&enddate=${currentDate.toISOString().split('T')[0]}`
             console.log("urltogotohist:", urltogotohist)
             if (entry === 'COD')
             {

                  // get the historic capitalflowhist
                  const getHistoricCOD = async (urltogotohist:any) => {
                    try {
                        await fetch(`${process.env.API_ROOT}${urltogotohist}`,{
                          method: 'GET',
                          credentials: 'include',
                        }).then(async (response) => {
                        const historicData = await response.json();
                      
                        setCodHistoric(historicData)
                      })
                      } catch (error) {
                        console.error(error); /* eslint-disable-line */
                    }
                    
                    }
                    getHistoricCOD(urltogotohist);
             }
             if (entry === 'pH')
              {
 
                   // get the historic capitalflowhist
                   const getHistoricPh = async (urltogotohist:any) => {
                     try {
                         await fetch(`${process.env.API_ROOT}${urltogotohist}`,{
                           method: 'GET',
                           credentials: 'include',
                         }).then(async (response) => {
                         const historicData = await response.json();
                       
                         setPhHistoric(historicData)
                       })
                       } catch (error) {
                         console.error(error); /* eslint-disable-line */
                     }
                     
                     }
                     getHistoricPh(urltogotohist);
              }
              if (entry === 'Temperature')
              {
   
                     // get the historic capitalflowhist
                     const getHistoricTemperature = async (urltogotohist:any) => {
                       try {
                           await fetch(`${process.env.API_ROOT}${urltogotohist}`,{
                             method: 'GET',
                             credentials: 'include',
                           }).then(async (response) => {
                           const historicData = await response.json();
                          
                           setTemperatureHistoric(historicData)
                         })
                         } catch (error) {
                           console.error(error); /* eslint-disable-line */
                       }
                       
                       }
                       getHistoricTemperature(urltogotohist);
              }
              if (entry === 'Conductivity')
              {
     
                       // get the historic capitalflowhist
                       const getHistoricConductivity = async (urltogotohist:any) => {
                         try {
                             await fetch(`${process.env.API_ROOT}${urltogotohist}`,{
                               method: 'GET',
                               credentials: 'include',
                             }).then(async (response) => {
                             const historicData = await response.json();
                           
                             setConductHistoric(historicData)
                           })
                           } catch (error) {
                             console.error(error); /* eslint-disable-line */
                         }
                         
                         }
                         getHistoricConductivity(urltogotohist);
              }
              if (entry === 'TSS')
              {
       
                         // get the historic capitalflowhist
                         const getHistoricTSS = async (urltogotohist:any) => {
                           try {
                               await fetch(`${process.env.API_ROOT}${urltogotohist}`,{
                                 method: 'GET',
                                 credentials: 'include',
                               }).then(async (response) => {
                               const historicData = await response.json();
                             
                               setTssHistoric(historicData)
                             })
                             } catch (error) {
                               console.error(error); /* eslint-disable-line */
                           }
                           
                           }
                           getHistoricTSS(urltogotohist);
              }
             if (entry === 'NO3')
              {
 
                   // get the historic capitalflowhist
                   const getHistoricNO3 = async (urltogotohist:any) => {
                     try {
                         await fetch(`${process.env.API_ROOT}${urltogotohist}`,{
                           method: 'GET',
                           credentials: 'include',
                         }).then(async (response) => {
                         const historicData = await response.json();
                       
                         setNo3Historic(historicData)
                       })
                       } catch (error) {
                         console.error(error); /* eslint-disable-line */
                     }
                     
                     }
                     getHistoricNO3(urltogotohist);
              }
              if (entry === 'NH4')
                {
   
                     // get the historic capitalflowhist
                     const getHistoricNH4 = async (urltogotohist:any) => {
                       try {
                           await fetch(`${process.env.API_ROOT}${urltogotohist}`,{
                             method: 'GET',
                             credentials: 'include',
                           }).then(async (response) => {
                           const historicData = await response.json();
                           console.log("historicData:", historicData)
                           setNh4Historic(historicData)
                         })
                         } catch (error) {
                           console.error(error); /* eslint-disable-line */
                       }
                       
                       }
                       getHistoricNH4(urltogotohist);
                }
          }
        }

  }, []);


  return (
    
    <React.Fragment>
      <tr>
        <td colSpan={2}>
          
   
        <Box className={styles.profileDataContainer}>
        <Item label="Asset ID" value={props.index.id} />
        {isTablet && (state.mode === Mode.OPERATIVE || state.mode === Mode.TECHNICIAN) && (
          <>
            <Item label="System Reference" value={props.index.system} />
            <Item label="System Type" value={props.index.systemtype} />
          </>
        )}
         <Item
          label="Coordinates"
          value={props.index.coordinates ? `${assetcoords.latitude}, ${assetcoords.longitude}` : null}
          internalLink={    // FW 2022-03-01 Link to Leaflet mapping and not GMaps
            props.index.coordinates
              ? `/client/${clientId}/assets/${siteId}/mapping?lat=${assetcoords.latitude}&long=${assetcoords.longitude}&assetselect=${props.index.assetname}`
              : undefined
          }
        />
       
       <DetailsPanelSection
          label="Drawings"
          values={
            drawingsCall &&
            drawingsCall.map((drawing) => {
              return {
                id: drawing.drawingid,
                value: `${drawing.originalname}`,
                link: `${pathname}/drawing/${drawing.drawingid}`,
              };
            })
          }
          emptyText="No Drawings"
        />   
           <Item label="Additional Information" value={props.index.additionalinformation} />
        {props.index?.currentvalues?.depth && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>Depth </strong>  {props.index?.currentvalues?.depth}<Button onClick={() => handleOpenDialog(depthHistoric, 'Depth')}>
                <img src={depthIcon} alt="depth chart" />
              </Button>
               </Typography>
          </>
        )}

        </Box>
          </td> <td colSpan={2}> <Box className={styles.profileDataContainer}>
          {footageFound && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>Clips </strong> <Button onClick={() => handleOpenDialog(footageCall, 'Clips')}>
                <img src={filmreelIcon} alt="Clips" />
              </Button>
               </Typography>
          
          </>

        )}
          {footageFound &&  (
            <FootageViewer pumpPopup={footagePopup} setPumpPopup={setFootagePopup} docsList={footageCall}  dialogTitle='Clips'  />
        )}
         {levelHistoric &&  (
            <TimeSeriesDialog pumpPopup={levelPopup} setPumpPopup={setLevelPopup} timeData={levelHistoric}  dialogTitle='Level' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources} thresholdPassed={props.index?.currentvalues?.Threshold}/>
        )}
         
        {depthHistoric &&  (
            <TimeSeriesDialog pumpPopup={depthPopup} setPumpPopup={setDepthPopup} timeData={depthHistoric}  dialogTitle='Depth' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}
        {props.index?.currentvalues?.velocity && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>Velocity </strong>  {props.index.currentvalues.velocity}<Button onClick={() => handleOpenDialog(velocityHistoric, 'Velocity')}>
                <img src={velocityIcon} alt="velocity chart" />
              </Button>
               </Typography>
          </>
        )}
        {velocityHistoric &&  (
            <TimeSeriesDialog pumpPopup={velocityPopup} setPumpPopup={setVelocityPopup} timeData={velocityHistoric}  dialogTitle='Velocity' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}
         {props.index?.currentvalues?.flow && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>Flow </strong>  {props.index.currentvalues.flow}<Button onClick={() => handleOpenDialog(flowHistoric, 'Flow')}>
                <img src={flowIcon} alt="flow chart" />
              </Button>
               </Typography>
          </>
        )}
        {flowHistoric &&  (
            <TimeSeriesDialog pumpPopup={flowPopup} setPumpPopup={setFlowPopup} timeData={flowHistoric}  dialogTitle='Flow' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}


        {props.index?.currentvalues?.Temperature && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>Temperature </strong>  {props.index.currentvalues.Temperature}<Button onClick={() => handleOpenDialog(temperatureHistoric, 'Temperature')}>
                <img src={tempIcon} alt="temperature chart" />
              </Button>
               </Typography>
          
          </>

       
              
        )}
        {temperatureHistoric &&  (
            <TimeSeriesDialog pumpPopup={tempPopup} setPumpPopup={setTempPopup} timeData={temperatureHistoric}  dialogTitle='Temperature' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}


        {props.index?.currentvalues?.NO3 && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>NO3 </strong>  {props.index.currentvalues.NO3}<Button onClick={() => handleOpenDialog(no3Historic, 'NO3')}>
                <img src={nitrateIcon} alt="nitrate chart" />
              </Button>
               </Typography>
          
          </>

       
              
        )}
         {no3Historic &&  (
            <TimeSeriesDialog pumpPopup={no3Popup} setPumpPopup={setNo3Popup} timeData={no3Historic}  dialogTitle='NO3' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}

        {props.index?.currentvalues?.Level && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>Level </strong>  {props.index.currentvalues.Level}<Button onClick={() => handleOpenDialog(levelHistoric, 'Level')}>
                <img src={levelIcon} alt="level chart" />
              </Button>
               </Typography>
          
          </>

       
              
        )}
         {levelHistoric &&  (
            <TimeSeriesDialog pumpPopup={levelPopup} setPumpPopup={setLevelPopup} timeData={levelHistoric}  dialogTitle='Level' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources} thresholdPassed={props.index?.currentvalues?.Threshold}/>
        )}


        {props.index?.currentvalues?.TSS && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>TSS </strong>  {props.index.currentvalues.TSS}<Button onClick={() => handleOpenDialog(tssHistoric, 'TSS')}>
                <img src={solidsIcon} alt="tss chart" />
              </Button>
               </Typography>
          
          </>

       
              
        )}
        {tssHistoric &&  (
            <TimeSeriesDialog pumpPopup={tssPopup} setPumpPopup={setTssPopup} timeData={tssHistoric}  dialogTitle='TSS' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}


        {props.index?.currentvalues?.NH4 && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>NH4 </strong>  {props.index.currentvalues.NH4}<Button onClick={() => handleOpenDialog(nh4Historic, 'NH4')}>
                <img src={moleculeIcon} alt="ammonium chart" />
              </Button>
               </Typography>
          
          </>

       
              
        )}
         {nh4Historic &&  (
            <TimeSeriesDialog pumpPopup={nh4Popup} setPumpPopup={setNh4Popup} timeData={nh4Historic}  dialogTitle='NH4' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}

        {props.index?.currentvalues?.COD && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>COD </strong>  {props.index.currentvalues.COD}<Button onClick={() => handleOpenDialog(codHistoric, 'COD')}>
                <img src={codIcon} alt="cod chart" />
              </Button>
               </Typography>
          
          </>

       
              
        )}
        {codHistoric &&  (
            <TimeSeriesDialog pumpPopup={codPopup} setPumpPopup={setCodPopup} timeData={codHistoric}  dialogTitle='COD' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}


         {props.index?.currentvalues?.pH && (
          <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>pH </strong>   {props.index.currentvalues.pH}  <Button onClick={() => handleOpenDialog(phHistoric, 'pH')}>
                <img src={phIcon} alt="ph chart" />
              </Button>
               </Typography>
          
          </>
  
        )}
        {phHistoric &&  (
            <TimeSeriesDialog pumpPopup={phPopup} setPumpPopup={setPhPopup} timeData={phHistoric}  dialogTitle='pH' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}



        {props.index?.currentvalues?.Conductivity && (
        <>
             <Typography variant="body1" className={cn(styles.value)}>
                <strong className={cn(styles.label)}>Conductivity </strong>  {props.index.currentvalues.Conductivity} <Button onClick={() => handleOpenDialog(conductHistoric, 'Conductivity')}>
                <img src={conductIcon} alt="conductivity chart" /> </Button>
              </Typography>
           
         </>

        )}
        {conductHistoric &&  (
            <TimeSeriesDialog pumpPopup={conductPopup} setPumpPopup={setConductPopup} timeData={conductHistoric}  dialogTitle='Conductivity' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}
       {props.index?.currentvalues?.turbidity && (
        <>
             <Typography variant="body1" className={cn(styles.value)}>
             <strong className={cn(styles.label)}>Turbidity </strong>  {props.index.currentvalues.turbidity}  <Button onClick={() => handleOpenDialog(turbidityHistoric, 'Turbidity')}>
                <img src={dropletIcon} alt="turbidity chart" />
              </Button>
               </Typography>
          
         </>
  
        )}
        {turbidityHistoric &&  (
            <TimeSeriesDialog pumpPopup={turbPopup} setPumpPopup={setTurbPopup} timeData={turbidityHistoric} dialogTitle='Turbidity' assetId={props.index.id} iotSource={props.index.iotsource} iotResources={props.index.iotresources}/>
        )}



        {props.index.compexpire && (
          <Item label="Compliant Expiration" value={new Date(props.index.compexpire).toLocaleDateString('en-GB')} />
        )}
        </Box>
        </td>
        <td colSpan={2}>
            <Box className={styles.profileDataContainer}>
         
           <Button className={styles.iotIcon}  onClick={() => expAssetRep(props.index.id)}>
            <img src={assetReport} alt="Export Report" />   
           </Button>
           <FormControlLabel control={<UpcomingSwitch checked={checked} onChange={toggleChecked} name="includeUpcoming" />}  label="Include Upcoming Tasks"
      />

     



      {props.index.assettype === 'PUMP' && (
          
          <Button className={styles.iotIcon}  onClick={() => setPumpPopup(true)}>
            <img src={iotIcon} alt="iot" />
         </Button>
       
       )}
       
        {pumpDataToShow &&  (
            <PumpsDialog pumpPopup={pumpPopup} setPumpPopup={setPumpPopup} pumpInfo={fullPumpDisplay} />
        )}


        </Box>
        </td>
       
      </tr>
      <tr>
      <td colSpan={6}>
        <Table style={{ minWidth: "650" }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>
                <ThemeProvider theme={getMuiTheme()}>
                  {showLinkedAssets === false  && (
                    <MUIDataTable  title={tasksTitleDisplay}  data={tasksCall}  columns={columnsMUITable} options={tableoptions}/>
                  )}
                  {showLinkedAssets === true  && (
                    <MUIDataTable  title={'Linked Assets'}  data={tasksCall}  columns={columnsMUITableLinkedAssets} options={tableoptions}/>      
                  )}
                </ThemeProvider>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>  
        </td>
      </tr>
    </React.Fragment>
  );
  
 }
