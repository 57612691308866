import React from 'react';
import AppButton, { AppButtonProps } from 'components/AppButton/AppButton';
import { Link, LinkProps } from 'react-router';

export interface ButtonLinkProps extends AppButtonProps {
  to: LinkProps['to'];
  state?: LinkProps['state'];
}

const AppButtonLink: React.FC<ButtonLinkProps> = (props) => {
  const { children, to, state, ...forwardProps } = props;

  return (
    <Link to={to} state={state}>
      <AppButton {...forwardProps}>{children}</AppButton>
    </Link>
  );
};

export default AppButtonLink;
