import React, { useEffect, useState } from 'react';
import { CircularProgress, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import assetAssociatedSystems from 'shared/assetAssociatedSystems';
import styleUtils from 'style/styleUtils';
import AppTextField from 'components/form/AppTextField';
import removeIcon from 'assets/removeIcon.svg';
import { AnimateWidth } from 'components/animations';
import { useMutation, useQuery } from '@apollo/client';
import {
  assetCreate,
  assetCreateVariables,
  AssetType,
  getCreateAssetData,
  getCreateAssetDataVariables,
  SystemType,
  getAssets,
  getAssetsVariables,
  assetsUpdate,
  assetsUpdateVariables,
  PumpInput,
} from 'models/graphql';
import Yup from 'utils/yup';
import { FieldArray, Formik } from 'formik';
import AppForm from 'components/form/AppForm';
import useText from 'texts/useText.hook';
import HideNavigation from 'components/HideNavigation';
import { FormikErrors } from 'formik/dist/types';
import AppSelectField from 'components/form/AppSelectField';
import { AppFormControlLabel, AppSwitch } from 'components/form';
import { FormLayout } from 'components/layout';
import AppDialog from 'components/AppDialog';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import useParentLocation from 'utils/useParentLocation.hook';
import AppFieldArrayAdd from 'components/form/AppFieldArrayAdd';
import stringToCoordinates, { coordinatesToString } from './stringToCoordinates';
import { CREATE_ASSET, GET_CREATE_ASSET_DATA, GET_ASSETS, UPDATE_ASSETS } from '../graphql';
import { useLocation, useNavigate, useParams } from 'react-router';

type Drawings = getCreateAssetData['client']['sites'][number]['drawings'];

const styleRules = (theme: Theme) =>
  createStyles({
    container: {
      ...styleUtils.columnStartCenter,
    },
    header: { margin: `${theme.spacing(5)} 0` },
    cancel: {
      marginTop: theme.spacing(5),
      ...styleUtils.rowCenterCenter,
    },
    addDrawing: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    drawingContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    drawingNameField: {
      flexGrow: 1,
    },
    drawingRemoveIcon: {
      ...styleUtils.rowCenterCenter,
      height: theme.sizes.button.height,
      paddingLeft: theme.spacing(1),
    },
    pumpContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      border: '1px solid #9D9D9C',
      borderRadius: 8,
    },
    pumpFields: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '8px',
    },
    pumpRemoveIcon: {
      ...styleUtils.rowCenterCenter,
      height: theme.sizes.button.height,
      paddingLeft: theme.spacing(1),
      border: 0,
      marginTop: '-12px',
    },
  });
const useStyles = makeStyles(styleRules);

// 'null' means here: multiple values set for the field (in bulk editing)
interface FormValues {
  siteId: string | 'null';
  name: string;
  department: string;
  additionalInformation: string;
  coordinates: string;
  systemType: SystemType | 'null';
  systemId: string | 'null';
  type: AssetType | 'null';
  ownerId: string | 'null';
  drawingIds: Array<string | 'null'>;
  pumps: PumpInput[];
  expireOption: boolean;
  expiration: string | 'null';
}

interface ValidationSchema {
  department?: string;
  systemId: string | 'null';
  name?: string;
  additionalInformation?: string;
}

export enum ExpireOptions {
  ONE = 'One Month',
  THREE = 'Three Months',
  SIX = 'Six Months',
  TWELVE = 'Twelve Months'
}


const ClientAssetCreatePage: React.FC = () => {
  const styles = useStyles();
  const { t } = useText('clients', 'common', 'assetTypes', 'systemTypes');
  const { clientId } = useParams<{ clientId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const parentUrl = useParentLocation();
  const assetIdsToUpdate: string[] | undefined = location.state?.assetIdsToUpdate;
  
  const mode = assetIdsToUpdate ? 'update' : 'create';
  const [noClientSites, setNoClientSites] = useState(false);
  const [noAllowedAssets, setNoAllowedAssets] = useState(false);
  const [noUsers, setNoUsers] = useState(false);
  const [allowedSystems, setAllowedSystems] = useState<SystemType[] | undefined>();

  const availableExpireOptions: ExpireOptions[] = [];
  const [expireCompliance, setExpireCompliance] = useState(false);

  const [expireOptions, setExpireOptions] = useState([]);

  let parameterNames: string[];
  const getExpirationOptions = (parameterNames: string[]) => {
    const defaultTypes = [
      { type: 'ONE', name: 'One Month' },
      { type: 'THREE', name: 'Three Months' },
      { type: 'SIX', name: 'Six Months' },
      { type: 'TWELVE', name: 'Twelve Months' },
    ];



    return defaultTypes;
  }
  //const expireOptionsDisplay = [ExpireOptions.ONE, ExpireOptions.THREE, ExpireOptions.SIX, ExpireOptions.TWELVE];

  // Get expiration options from API
  const setComplianceExpiry = async () => {
    // Perform your data fetching logic here
    // For example, make an API call using axios or fetch
    const urltogoto = 'forms/expires';
    let dataret: any [];
    let dataretforms: any [];
    let dataretassets: any [];
    let fullurl = urltogoto;
  
  
    const response = await fetch(`${process.env.API_ROOT}${fullurl}`, {
      method: 'GET',
      credentials: 'include',
    })
    const data = await response.json();
   
     setExpireOptions(data)
     return data;
  };


  


let expireOptionsDisplay = [
      {
        "id" : "ONEM",
        "display" : "One Month"
      },
      {
        "id" : "THREEM",
        "display" : "Three Months"
      },
      {
        "id" : "SIXM",
        "display" : "Six Months"
      },
      {
        "id" : "TWELVEM",
        "display" : "Twelve Months"
      },
      {
        "id" : "EIGHTEENM",
        "display" : "Eighteen Months"
      },
      {
        "id" : "TFOURM",
        "display" : "Twenty Four Months"
      },

  ] 


  const [variousValues, setVariousValues] = useState<{ [K in keyof FormValues]?: boolean } | undefined>();
  const { data: assetsToEditData } = useQuery<getAssets, getAssetsVariables>(GET_ASSETS, {
    skip: !assetIdsToUpdate,
    variables: { ids: assetIdsToUpdate || [] },
  });
  useEffect(() => {
   
    // Initialize variousValues
    if (variousValues) {
      return;
    }

    if (mode === 'update' && assetsToEditData) {
      const { assets } = assetsToEditData;

    

      const _variousValues: { [K in keyof FormValues]?: boolean } = {};
      
      if (assets[0].expireOption !== null)
      {
        setExpireCompliance(assets[0].expireOption)
      }


     

      // Get various values
      if (!assets.every((asset) => asset.site.id === assets[0].site.id)) {
        _variousValues.siteId = true;
      }
      if (!assets.every((asset) => asset.name === assets[0].name)) {
        _variousValues.name = true;
      }
      if (!assets.every((asset) => asset.department === assets[0].department)) {
        _variousValues.department = true;
      }
      if (!assets.every((asset) => asset.additionalInformation === assets[0].additionalInformation)) {

        _variousValues.additionalInformation = true;
      }
      if (!assets.every((asset) => asset.expireOption === assets[0].expireOption)) {
        _variousValues.expireOption = true;

      }
      if (!assets.every((asset) => asset.expiration === assets[0].expiration)) {
        console.log("asset.expiration:", asset.expiration)
        _variousValues.expiration = true;
      }
      if (
        !assets.every(
          (asset) =>
            asset.coordinates?.latitude === assets[0].coordinates?.latitude &&
            asset.coordinates?.longitude === assets[0].coordinates?.longitude,
        )
      ) {
        _variousValues.coordinates = true;
      }
      if (!assets.every((asset) => asset.system.type === assets[0].system.type)) {
        _variousValues.systemType = true;
      }
      if (!assets.every((asset) => asset.system.id === assets[0].system.id)) {
        _variousValues.systemId = true;
      }
      if (!assets.every((asset) => asset.type === assets[0].type)) {
        _variousValues.type = true;
      }
      if (!assets.every((asset) => asset.owner.id === assets[0].owner.id)) {
        _variousValues.ownerId = true;
      }
      if (
        assets.some(
          // If there is at least one asset
          (asset) =>
            asset.drawings.length !== assets[0].drawings.length || // which has different number of drawings than assets[0]
            asset.drawings.some(
              // OR has at least one drawing
              (drawing) =>
                !assets[0].drawings.find(
                  // which not found in assets[0].drawings
                  (referenceDrawing) => referenceDrawing.id === drawing.id,
                ),
            ),
        )
      ) {
        _variousValues.drawingIds = true;
      }

      setVariousValues(_variousValues);
      
    } else if (mode === 'create') {
      setVariousValues({});
    }
    
  }, [setVariousValues, variousValues, assetsToEditData, mode]);

  const { data: clientData } = useQuery<getCreateAssetData, getCreateAssetDataVariables>(GET_CREATE_ASSET_DATA, {
    variables: { id: clientId },
  });

  useEffect(() => {
    if (clientData) {
      setComplianceExpiry();
      if (!clientData.client.sites?.length) {
        setNoClientSites(true);
      } else if (!clientData.client.allowedAssets?.length) {
        setNoAllowedAssets(true);
      } else if (!clientData.client.users?.length) {
        setNoUsers(true);
      } else {
        const _allowedSystems: SystemType[] = [];
        clientData.client.allowedAssets!.forEach((asset) => {
          assetAssociatedSystems[asset].forEach((system) => {
            if (!_allowedSystems.includes(system)) {
              _allowedSystems.push(system);
            }
          });
        });
        setAllowedSystems(_allowedSystems);
      }
    }
  }, [clientData]);

  const allowedAssetsForSystem: (system: SystemType) => AssetType[] = (system) => {
    if (!clientData) throw new Error('Client data not loaded.');
    const { client } = clientData;

    if (!allowedSystems || !clientData?.client.allowedAssets) return [];
    return client.allowedAssets?.filter((_asset) => assetAssociatedSystems[_asset].includes(system)) || [];
  };

  const [createAsset] = useMutation<assetCreate, assetCreateVariables>(CREATE_ASSET, {
    update: (cache, { data }) => {
      apolloCacheEvict({
        cache,
        typename: 'Client',
        id: clientId,
        fieldName: 'assets',
      });

      apolloCacheEvict({
        cache,
        typename: 'Site',
        id: data?.assetCreate.site.id || '',
        fieldName: 'drawings',
      });

      const asset = data?.assetCreate;
      if (asset) {
        apolloCacheEvict({
          cache,
          typename: 'System',
          id: asset.system.id,
          fieldName: 'numberOfAssets',
        });
      }
    },
  });

  const [updateAssets] = useMutation<assetsUpdate, assetsUpdateVariables>(UPDATE_ASSETS, {
    update: (cache, { data }) => {
      apolloCacheEvict({
        cache,
        typename: 'Site',
        id: data?.assetsUpdate[0].site.id || '',
        fieldName: 'drawings',
      });
    },
  });

  const noDataState = () => {
    if (noClientSites || noAllowedAssets || noUsers) {
      let errorMessage: string;
      if (noClientSites) errorMessage = t('clients')('noSitesForClient');
      else if (noAllowedAssets) errorMessage = t('clients')('noAssetsForClient');
      else errorMessage = t('clients')('noUsersForClient');
      return (
        <AppDialog open onClose={() => navigate(parentUrl)} type="error" title={t('clients')('addAssetError')}>
          {errorMessage}
        </AppDialog>
      );
    }
    return <CircularProgress color="primary" />;
  };

  const validationSchema: Yup.ObjectSchema<ValidationSchema> = Yup.object().shape({
    department: Yup.string(),
    systemId: Yup.string().required(
      t('common')('required', {
        field: t('clients')('systemName')
      })
    ),
    name: Yup.string().required(
      t('common')('required', {
        field: t('clients')('assetName')
      })
    ),
    additionalInformation: Yup.string(),
  })

	  
  const handleExpiryChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setExpireCompliance(event.target.checked);
    console.log("Value is ", event.target.checked)
    // Perform actions based on the checked state
    if (event.target.checked) {
      console.log('Switch is checked');
    } else {
      console.log('Switch is unchecked');
      // Your logic for when the switch is unchecked
    }
  };




  const initialValues = (): FormValues => {
    if (!allowedSystems) throw new Error('No systems allowed.');
    if (!clientData) throw new Error('Client data not loaded.');
    const { client } = clientData;

    if (mode === 'create') {
      return {
        siteId: client.sites[0].id,
        department: '',
        systemType: allowedSystems[0],
        systemId: '',
        name: '',
        type: allowedAssetsForSystem(allowedSystems[0])[0],
        coordinates: '',
        ownerId: client.users[0].id,
        drawingIds: client.sites[0].drawings.length ? [client.sites[0].drawings[0].id] : [],
        additionalInformation: '',
        expireOption: expireCompliance,
        expiration: '',
        pumps: [],
      };
    }
    if (assetsToEditData && variousValues) {
      const { assets } = assetsToEditData;

      return {
        siteId: variousValues.siteId ? 'null' : assets[0].site.id,
        department: variousValues.department ? '' : assets[0].department || '',
        systemType: variousValues.systemType ? 'null' : assets[0].system.type,
        systemId: variousValues.systemId ? 'null' : assets[0].system.id,
        name: variousValues.name ? '' : assets[0].name || '',
        type: variousValues.type ? 'null' : assets[0].type,
        coordinates: variousValues.coordinates ? '' : coordinatesToString(assets[0].coordinates || undefined) || '',
        ownerId: variousValues.ownerId ? 'null' : assets[0].owner.id,
        drawingIds: variousValues.drawingIds ? ['null'] : assets[0].drawings.map((drawing) => drawing.id),
        additionalInformation: variousValues.additionalInformation ? '' : assets[0].additionalInformation || '',

        expireOption: variousValues.expireOption ? false : assets[0].expireOption,
        expiration: variousValues.expiration ? 'null' : assets[0].expiration,


        pumps: assets[0].specificInfo
          ? assets[0].specificInfo.pumps.map((pump) => ({ name: pump.name, pass: pump.pass }))
          : [],
      };
    }

    throw new Error('No assets loaded for edit mode.');
  };

  return (
    <div className={styles.container}>
      <HideNavigation />
      <Typography variant="h3" className={styles.header}>
        {mode === 'create' ? t('clients')('addAsset') : t('clients')('editAsset', { count: assetIdsToUpdate?.length })}
      </Typography>
      {(mode === 'create' || (mode === 'update' && assetsToEditData?.assets.length)) &&
      !noClientSites &&
      !noAllowedAssets &&
      variousValues &&
      clientData &&
      allowedSystems ? (
        <Formik<FormValues>
          initialValues={initialValues()}
          validationSchema={validationSchema}
          validate={(values) => {
            const errors: FormikErrors<FormValues> = {};

            if (values.coordinates && stringToCoordinates(values.coordinates) === null) {
              errors.coordinates = `${t('clients')('assetCoordinatesWrongFormat')}. ${t('clients')(
                'assetCoordinatesFormatHelper',
              )}`;
            }

            return errors;
          }}
          onSubmit={async (values) => {
            if (
              mode === 'create' &&
              values.systemId &&
              values.type &&
              values.siteId &&
              values.ownerId &&
              values.drawingIds
            ) {
              if (values.systemId === 'null' || values.type === 'null') {
                throw new Error("Unexpected 'null' values at create.");
              }

              await createAsset({
                variables: {
                  asset: {
                    name: values.name,
                    department: values.department,
                    additionalInformation: values.additionalInformation,
                    coordinates: values.coordinates ? stringToCoordinates(values.coordinates) : undefined,
                    systemId: values.systemId,
                    type: values.type,
                    siteId: values.siteId,
                    ownerId: values.ownerId,
                    drawingIds: values.drawingIds,
                    expireOption: expireCompliance,
                    expiration: values.expiration,
                    specificInfo: {
                      pumps: values.pumps,
                    },
                  },
                },
              });
            } else if (mode === 'update' && assetIdsToUpdate) {
              await updateAssets({
                variables: {
                  assets: assetIdsToUpdate.map((id) => ({
                    id,
                    name: variousValues.name ? values.name || undefined : values.name,
                    department: variousValues.department ? values.department || undefined : values.department,
                    additionalInformation: variousValues.additionalInformation
                      ? values.additionalInformation || undefined
                      : values.additionalInformation,
                    coordinates: stringToCoordinates(values.coordinates) || undefined,
                    systemId: values.systemId === 'null' ? undefined : values.systemId,
                    type: values.type === 'null' ? undefined : values.type,
                    siteId: values.siteId === 'null' ? undefined : values.siteId,
                    ownerId: values.ownerId === 'null' ? undefined : values.ownerId,
                    drawingIds: values.drawingIds && values.drawingIds[0] !== 'null' ? values.drawingIds : undefined,
                    expireOption: expireCompliance,
                    expiration: variousValues.expiration
                    ? values.expiration || undefined
                    : values.expiration,
                    specificInfo: {
                      pumps: values.pumps,
                    },
                  })),
                },
              });
            } else {
              throw new Error('Nor valid create, nor valid update method variables are set.');
            }

            navigate(parentUrl);
          }}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
            const { client } = clientData;
            const siteDrawings: Drawings = client.sites.find((site) => site.id === values.siteId)?.drawings || [];
            const notYetSelectedSiteDrawings: Drawings = siteDrawings.filter(
              (drawing) => !values.drawingIds.includes(drawing.id),
            );

            return (
              <AppForm handleSubmit={handleSubmit}>
                <FormLayout isSubmitting={isSubmitting} parentHref={parentUrl}>
                  <AppSelectField
                    label={t('clients')('site')}
                    required
                    name="siteId"
                    options={{
                      array: [...client.sites, ...(variousValues.siteId ? ['null' as const] : [])],
                      value: (site) => (site === 'null' ? 'null' : site.id),
                      key: (site) => (site === 'null' ? 'null' : site.id),
                      template: (site) => (site === 'null' ? t('common')('variousValues') : site.name),
                    }}
                    onChange={() => {
                      setFieldValue('drawingIds', []);

                      //  If we change the site, we have to change the system too
                      setFieldValue('systemType', '');
                      setFieldValue('systemId', '');
                      variousValues.systemType = false;
                      variousValues.systemId = false;
                      setVariousValues(variousValues);
                    }}
                  />

                  <AppTextField
                    label={t('clients')('department')}
                    placeholder={
                      variousValues.department ? t('common')('variousValues') : t('clients')('departmentPlaceholder')
                    }
                    name="department"
                    type="text"
                  />

                  <AppSelectField
                    label={t('clients')('systemType')}
                    name="systemType"
                    required
                    options={{
                      array: [...allowedSystems, ...(variousValues.systemType ? ['null' as const] : [])],
                      value: (system) => system,
                      key: (system) => system,
                      template: (system) =>
                        system === 'null' ? t('common')('variousValues') : t('systemTypes')(system),
                    }}
                    onChange={(system) => {
                      setFieldValue('type', system === 'null' ? 'null' : allowedAssetsForSystem(system)[0]);
                      setFieldValue('systemId', system === 'null' ? 'null' : '');
                    }}
                  />

                  <AppSelectField
                    label={t('clients')('systemName')}
                    name="systemId"
                    required
                    disabled={!values.systemType || values.systemType === 'null' || values.siteId === 'null'}
                    options={{
                      array:
                        values.systemType && values.systemType !== 'null' && values.siteId && values.siteId !== 'null'
                          ? [
                              ...client.systems.filter(
                                (system) => system.type === values.systemType && system.site.id === values.siteId,
                              ),
                              ...(variousValues.systemId ? ['null' as const] : []),
                            ]
                          : ['null' as const],
                      value: (system) => (system === 'null' ? 'null' : system.id),
                      key: (system) => (system === 'null' ? 'null' : system.id),
                      template: (system) => (system === 'null' ? t('common')('variousValues') : system.name),
                      ifArrayEmpty: t('clients')('noSystemsForFilter', {
                        type: values.systemType !== 'null' ? t('systemTypes')(values.systemType) : undefined,
                        site: client.sites.find((site) => site.id === values.siteId)?.name,
                      }),
                    }}
                  />

                  <AppTextField
                    label={t('clients')('assetName')}
                    placeholder={
                      variousValues.name ? t('common')('variousValues') : t('clients')('assetNamePlaceholder')
                    }
                    name="name"
                    type="text"
                    required
                  />

                  <AppSelectField
                    label={t('clients')('assetType')}
                    name="type"
                    required
                    disabled={values.systemId === 'null'}
                    options={{
                      array:
                        values.systemType !== 'null'
                          ? [
                              ...allowedAssetsForSystem(values.systemType),
                              ...(variousValues.type ? ['null' as const] : []),
                            ]
                          : ['null' as const],

                      value: (asset) => asset,
                      key: (asset) => asset,
                      template: (asset) => (asset === 'null' ? t('common')('variousValues') : t('assetTypes')(asset)),
                    }}
                  />

                  {values.type === AssetType.PUMP && (
                    <FieldArray
                      name="pumps"
                      render={({ push, remove }) => (
                        <>
                          {values.pumps &&
                            values.pumps.map((pump, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <fieldset key={`pump${index}`} className={styles.pumpContainer}>
                                <legend>Pump #{index + 1}</legend>
                                <div className={styles.pumpFields}>
                                  <AppTextField label="Pump ID" name={`pumps.${index}.name`} required />
                                  <AppTextField label="Pump password" name={`pumps.${index}.pass`} required />
                                </div>
                                <button
                                  onClick={() => {
                                    remove(index);
                                  }}
                                  type="button"
                                  className={styles.pumpRemoveIcon}
                                >
                                  <img alt="error" src={removeIcon} />
                                </button>
                              </fieldset>
                            ))}
                          <AppFieldArrayAdd
                            onClick={() => {
                              push({ name: '', pass: '' });
                            }}
                            text="Add pump"
                          />
                        </>
                      )}
                    />
                  )}

                  <AppSelectField
                    label={t('clients')('assetOwner')}
                    name="ownerId"
                    required
                    options={{
                      array: [...client.users, ...(variousValues.ownerId ? ['null' as const] : [])],
                      value: (user) => (user === 'null' ? 'null' : user.id),
                      key: (user) => (user === 'null' ? 'null' : user.id),
                      template: (user) => (user === 'null' ? t('common')('variousValues') : user.name),
                    }}
                  />

                  <FieldArray
                    name="drawingIds"
                    render={({ push, remove }) => (
                      <>
                        {values.drawingIds.map((drawingId, index) => {
                          const selected = siteDrawings.find(
                            (drawing) => values.drawingIds && drawing.id === values.drawingIds[index],
                          );

                          const options: Array<
                            getCreateAssetData['client']['sites'][number]['drawings'][number] | 'null'
                          > = [...notYetSelectedSiteDrawings];
                          if (selected) options.unshift(selected);
                          if (variousValues.drawingIds && values.drawingIds.length === 1) {
                            options.unshift('null');
                          }

                          return (
                            <div key={drawingId} className={styles.drawingContainer}>
                              <div className={styles.drawingNameField}>
                                <AppSelectField
                                  label={t('clients')('drawing')}
                                  name={`drawingIds.${index}`}
                                  disabled={values.siteId === 'null'}
                                  required
                                  options={{
                                    array: options,
                                    value: (drawing) => (drawing === 'null' ? 'null' : drawing.id),
                                    key: (drawing) => (drawing === 'null' ? 'null' : drawing.id),
                                    template: (drawing) =>
                                      drawing === 'null'
                                        ? t('common')('variousValues')
                                        : `${drawing.file.originalName} (${drawing.drawingId})`,
                                  }}
                                />
                              </div>
                              <AnimateWidth visible>
                                <button
                                  onClick={() => {
                                    remove(index);
                                  }}
                                  type="button"
                                  className={styles.drawingRemoveIcon}
                                >
                                  <img alt="error" src={removeIcon} />
                                </button>
                              </AnimateWidth>
                            </div>
                          );
                        })}
                        {notYetSelectedSiteDrawings.length && values.drawingIds[0] !== 'null' ? (
                          <button
                            type="button"
                            onClick={() => {
                              push(notYetSelectedSiteDrawings[0].id);
                            }}
                            className={styles.addDrawing}
                          >
                            + {t('clients')('addDrawing')}
                          </button>
                        ) : null}
                      </>
                    )}
                  />

                  <AppTextField
                    label={t('clients')('assetCoordinates')}
                    placeholder={
                      variousValues.coordinates ? t('common')('variousValues') : t('clients')('assetCoordinatesExample')
                    }
                    name="coordinates"
                  />
                  <AppTextField
                    label={t('clients')('additionalInformation')}
                    placeholder={
                      variousValues.additionalInformation
                        ? t('common')('variousValues')
                        : t('clients')('additionalInformationPlaceholder')
                    }
                    name="additionalInformation"
                    type="text"
                  />
                  {/* expire compliance */}
                  <AppFormControlLabel
                        control={<AppSwitch name="expiry"  checked={expireCompliance}
                        onChange={handleExpiryChange}/>}
                        labelPlacement="start"
                        label={t('clients')('expiry')}
                    />
                     {/* show compliance options*/}
                     {expireCompliance && (
                     <AppSelectField
                    label={'Set Compliance Expiration'}
                    name="expiration"
              
                    options={{
                      array: [...expireOptions, ...(variousValues.expiration ? ['null' as const] : [])],
                      value: (exp) => (exp === 'null' ? 'null' : exp.comperiod),
                      key: (exp) => (exp === 'null' ? 'null' : exp.comperiod),
                      template: (exp) => (exp === 'null' ? t('common')('variousValues') : exp.comdisplay),
                    }}
                    
                  />
                  )}






                </FormLayout>
              </AppForm>
            );
          }}
        </Formik>
      ) : (
        noDataState()
      )}
    </div>
  );
};

export default ClientAssetCreatePage;
