import { useMutation, useQuery } from '@apollo/client';
import ContractorRequestStatus from 'components/ContractorRequestStatus';
import ActionsButton from 'components/ActionsButton';
import ContractorsList from 'containers/shared/Contractors/ContractorsList';
import { GET_CLIENT_REQUESTS, GET_CONTRACTORS } from 'containers/shared/graphql';
import {
  clientDenyRequest,
  clientDenyRequestVariables,
  ContractorRequestAccessStatus,
  getClientRequests,
  getClientRequestsVariables,
  getContractors,
  getContractorsVariables,
} from 'models/graphql';
import React from 'react';
import useText from 'texts/useText.hook';
import { DENY_REQUEST } from './graphql';
import { ContextStore, useStores } from 'stores';
import { useNavigate, useParams } from 'react-router';

const ContractorsPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const { loading: contractorsLoading, data: contractorsData, refetch: contractorsRefetch } = useQuery<
    getContractors,
    getContractorsVariables
  >(GET_CONTRACTORS);
  const contractors = contractorsData?.contractors;
  

  const { loading: requestsLoading, data: requestsData } = useQuery<getClientRequests, getClientRequestsVariables>(
    GET_CLIENT_REQUESTS,
    { variables: { clientId } },
  );
  const requests = requestsData?.client.requests;

  const [denyRequest] = useMutation<clientDenyRequest, clientDenyRequestVariables>(DENY_REQUEST);

  const findRequestFromContractor = (contractorId: string) => {
    return requests?.find((request) => request.contractor.id === contractorId);
  };

  const { t } = useText('contractors');
  const navigate = useNavigate();

  const { contextStore } = useStores();
 
  contextStore.setReportViewedValue(false);

  return (
    <ContractorsList
      onFilterSubmit={async ({ search }) => {
        await contractorsRefetch({
          search: search || undefined,
        });
      }}
      hideShowArchived
      contractors={contractors}
      contractorsLoading={contractorsLoading || requestsLoading}
      rowLink={(row) => `/client/${clientId}/contractors/${row.id}`}
      status={(row) => {
        const requestFromContractor = findRequestFromContractor(row.id);
        if (requestFromContractor) {
          return <ContractorRequestStatus accessStatus={requestFromContractor.accessStatus} />;
        }
        return <></>;
      }}
      actions={(row) => {
        return (
          <ActionsButton
            actions={
              findRequestFromContractor(row.id)?.accessStatus === ContractorRequestAccessStatus.ACCEPTED
                ? [
                    {
                      title: t('contractors')('disableContractor'),
                      action: async (setLoading) => {
                        setLoading(true);
                        await denyRequest({ variables: { clientId, contractorId: row.id } });
                        setLoading(false);
                        contextStore.setReportViewedValue(false);
                      },
                    },
                  ]
                : [
                    {
                      title: t('contractors')('acceptContractor'),
                      action: () => {
                        contextStore.setReportViewedValue(false);
                        navigate(`accept/${row.id}`);
                      },
                    },
                  ]
            }
          />
        );
      }}
    />
  );
};

export default ContractorsPage;
