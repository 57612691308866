import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Expertise,
  createContractor,
  createContractorVariables,
  getContractor,
  getContractorVariables,
  updateContractor,
  updateContractorVariables,
} from 'models/graphql';
import FileInput from 'components/FileInput';
import Yup from 'utils/yup';
import { Formik } from 'formik';
import { AppForm, AppTextField } from 'components/form';
import { FullScreenPage, FormLayout } from 'components/layout';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import useText from 'texts/useText.hook';
import enumToArray from 'utils/enumToArray';
import AppSelectMultipleField from 'components/form/AppSelectMultipleField';
import useCancel from 'utils/useCancel.hook';
import { CREATE_CONTRACTOR, GET_CONTRACTOR, UPDATE_CONTRACTOR } from 'containers/shared/graphql';
import { useParams } from 'react-router';
import i18next from 'i18next';

const expertiseArray = enumToArray(Expertise);

interface FormValues {
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  expertise: Expertise[];
  logoId?: string;
}
interface ValidationSchema {
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  expertise: Expertise[];
}

const validationSchema: Yup.ObjectSchema<ValidationSchema> = Yup.object().shape({
  companyName: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('contractors:companyName')
    })
  ),
  contactName: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('contractors:contactName')
    })
  ),
  contactEmail: Yup.string().email().required(
    i18next.t('common:required', {
      field: i18next.t('contractors:contactEmail')
    })
  ),
  contactPhone: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('contractors:contactPhone')
    })
  ),
  address: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('contractors:address')
    })
  ),
  expertise: Yup.array().of<Expertise>(Yup.mixed<Expertise>().oneOf(expertiseArray).required()).required().min(
    1, i18next.t('common:required', {
      field: i18next.t('contractors:expertise')
    })
  ),
});

const ContractorCreatePage: React.FC = () => {
  const { contractorId } = useParams<{ contractorId: string }>();
  const cancel = useCancel('/contractors');
  const { t, tt } = useText('contractors', 'common');

  const mode = contractorId ? 'update' : 'create';

  const { data: contractorData, loading: contractorLoading } = useQuery<getContractor, getContractorVariables>(
    GET_CONTRACTOR,
    {
      skip: mode === 'create',
      variables: {
        id: contractorId,
      },
    },
  );
  const contractor = contractorData?.contractor;

  const [update] = useMutation<updateContractor, updateContractorVariables>(UPDATE_CONTRACTOR);

  const [create] = useMutation<createContractor, createContractorVariables>(CREATE_CONTRACTOR, {
    update: (cache) => apolloCacheEvictQuery({ cache, query: 'contractors' }),
  });

  return (
    <FullScreenPage
      title={mode === 'update' ? t('contractors')('editContractor') : t('contractors')('addContractor')}
      loading={mode === 'update' && contractorLoading}
    >
      <Formik<FormValues>
        initialValues={{
          companyName: contractor?.companyName || '',
          contactName: contractor?.contactName || '',
          contactEmail: contractor?.contactEmail || '',
          contactPhone: contractor?.contactPhone || '',
          address: contractor?.address || '',
          expertise: contractor?.expertise || [],
          logoId: contractor?.logo?.id,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (mode === 'update') {
            await update({
              variables: {
                id: contractorId,
                changes: {
                  companyName: values.companyName,
                  contactEmail: values.contactEmail,
                  contactName: values.contactName,
                  contactPhone: values.contactPhone,
                  address: values.address,
                  expertise: values.expertise,
                  logoId: values.logoId,
                },
              },
            });
          } else {
            await create({
              variables: {
                contractor: {
                  companyName: values.companyName,
                  contactEmail: values.contactEmail,
                  contactName: values.contactName,
                  contactPhone: values.contactPhone,
                  address: values.address,
                  expertise: values.expertise,
                  logoId: values.logoId,
                },
              },
            });
          }
          cancel();
        }}
      >
        {({ isSubmitting, setFieldValue, handleSubmit }) => (
          <AppForm handleSubmit={handleSubmit}>
            <FormLayout
              isSubmitting={isSubmitting}
              parentHref="/contractors"
              sidebar={
                <FileInput
                  buttonName={mode === 'update' ? t('common')('update') : t('common')('upload')}
                  initialSrc={contractor?.logo?.srcUrl}
                  onUpload={(id: string) => {
                    setFieldValue('logoId', id);
                  }}
                  accept="image/*"
                  label="Logo"
                  publicAccess
                />
              }
            >
              <AppTextField label={t('contractors')('companyName')} name="companyName" type="text" autoFocus required />
              <AppTextField label={t('contractors')('address')} name="address" type="text" required />
              <AppTextField label={t('contractors')('contactName')} name="contactName" type="text" required />
              <AppTextField label={t('contractors')('contactEmail')} name="contactEmail" type="email" required />
              <AppTextField label={t('contractors')('contactPhone')} name="contactPhone" type="text" required />
              <AppSelectMultipleField
                label={t('contractors')('expertise')}
                placeholder={t('common')('selectPlaceholder')}
                name="expertise"
                required
                options={{
                  array: expertiseArray,
                  value: (expertise) => expertise,
                  key: (expertise) => expertise,
                  template: (expertise) => tt('contractors')('expertises')(expertise),
                }}
              />
            </FormLayout>
          </AppForm>
        )}
      </Formik>
    </FullScreenPage>
  );
};

export default ContractorCreatePage;
