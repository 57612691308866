import React, { useState } from 'react';
import AppButtonLink from 'components/AppButtonLink';
import { useMutation, useQuery } from '@apollo/client';
import {
  AssetType,
  subscriptionAllowedAssetsUpdate,
  subscriptionAllowedAssetsUpdateVariables,
  getSubscription,
  getSubscriptionVariables,
} from 'models/graphql';
import { CircularProgress, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import enumKeys from 'utils/enumKeys';
import { AppFormControlLabel, AppSwitch } from 'components/form';
import styleUtils from 'style/styleUtils';
import { AnimateOpacity } from 'components/animations';
import useText from 'texts/useText.hook';
import editIcon from 'assets/editIcon.svg';
import AppIconButton from 'components/AppIconButton';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import useMode, { Mode } from 'utils/useMode.hook';
import { GET_SUBSCRIPTION, UPDATE_ALLOWED_ASSETS } from './graphql';
import { Link, useParams } from 'react-router';

const styleRules = (theme: Theme) =>
  createStyles({
    edit: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    details: {
      width: '100%',
      maxWidth: 650,
      display: 'flex',
      flexWrap: 'wrap',
    },
    detail: {
      width: '33%',
      padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
    },
    detailTitle: {
      fontSize: theme.fontSizes['12'],
    },
    detailValue: {
      marginTop: theme.spacing(0.5),
      color: theme.palette.grey['500'],
    },
    assetsTitle: {
      position: 'relative',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
    assetsLoading: {
      position: 'absolute',
      right: theme.sizes.switch.width / 2,
      top: '50%',
      transform: 'translate(50%, -50%)',
    },
    assets: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 450,
    },
    asset: {
      height: theme.sizes.button.height,
      ...styleUtils.rowStartCenter,
      borderBottom: `solid 1px ${theme.palette.grey['100']}`,
      '&:last-child': {
        borderBottom: 0,
      },
    },
    disabledAsset: {
      opacity: 0.5,
    },
    loader: {
      ...styleUtils.rowCenterCenter,
      marginTop: theme.spacing(3),
    },
  });
const useStyles = makeStyles(styleRules);

type Assets = { [key in AssetType]: boolean };

const ClientSubscriptionPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { mode } = useMode();

  const { loading, data } = useQuery<getSubscription, getSubscriptionVariables>(GET_SUBSCRIPTION, {
    variables: { id: clientId },
  });

  const [updateAllowedAssets] = useMutation<subscriptionAllowedAssetsUpdate, subscriptionAllowedAssetsUpdateVariables>(
    UPDATE_ALLOWED_ASSETS,
    {
      update: (cache) =>
        apolloCacheEvict({
          cache,
          typename: 'Client',
          id: clientId,
          fieldName: 'allowedAssets',
        }),
    },
  );

  const [assetsInitialValue, setAssetsInitialValue] = useState<Assets | undefined>();

  if (data?.client?.subscription && !assetsInitialValue) {
    const subscription = data?.client?.subscription;
    setAssetsInitialValue(
      enumKeys(AssetType).reduce(
        (values, asset) => ({
          ...values,
          [asset]: subscription.allowedAssets.includes(asset as AssetType),
        }),
        {},
      ) as Assets,
    );
  }

  const { t } = useText('assetTypes', 'clients');
  const styles = useStyles();

  if (loading) {
    return (
      <div className={styles.loader}>
        <CircularProgress color="primary" />
      </div>
    );
  }
  if (!data?.client?.subscription || !assetsInitialValue) {
    return mode === Mode.PM ? (
      <AppButtonLink to={`/pm/clients/${clientId}/subscription/add`}>{t('clients')('addSubscription')}</AppButtonLink>
    ) : null;
  }

  return (
    <>
      {mode === Mode.PM && (
        <div className={styles.edit}>
          <Link to={`/pm/clients/${clientId}/subscription/${data.client.subscription.id}/edit`}>
            <AppIconButton>
              <img alt="update" src={editIcon} />
            </AppIconButton>
          </Link>
        </div>
      )}
      <Typography variant="h3">{t('clients')('subscriptionDetails')}</Typography>
      <div className={styles.details}>
        <div className={styles.detail}>
          <div className={styles.detailTitle}>{t('clients')('subscriptionId')}</div>
          <div className={styles.detailValue}>{data.client.subscription.idReadable}</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.detailTitle}>{t('clients')('billingFrequency')}</div>
          <div className={styles.detailValue}>{data.client.subscription.billingFrequency}</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.detailTitle}>{t('clients')('startingMonth')}</div>
          <div className={styles.detailValue}>{data.client.subscription.startingMonth}</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.detailTitle}>{t('clients')('numberOfSites')}</div>
          <div className={styles.detailValue}>{data.client.subscription.sitesMaxNumber}</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.detailTitle}>{t('clients')('numberOfContractors')}</div>
          <div className={styles.detailValue}>{data.client.subscription.contractorsMaxNumber}</div>
        </div>
      </div>
      <div className={styles.details}>
        {data.client.subscription.sites.map((site) => (
          <div key={site.id} className={styles.detail}>
            <div className={styles.detailTitle}>{t('clients')('siteName')}</div>
            <div className={styles.detailValue}>{site.name}</div>
          </div>
        ))}
      </div>
      <Formik<Assets>
        initialValues={assetsInitialValue}
        onSubmit={async (assets, { setSubmitting }) => {
          const allowedAssets: AssetType[] = [];
          enumKeys(assets).forEach((asset) => {
            if (assets[asset]) {
              allowedAssets.push(asset);
            }
          });
          await updateAllowedAssets({
            variables: { id: data.client.subscription!.id, allowedAssets },
          });
          setSubmitting(false);
        }}
      >
        {({ values: assets, submitForm, isSubmitting }) => (
          <div className={styles.assets}>
            <div className={styles.assetsTitle}>
              <Typography variant="h3">{t('clients')('assetTypes')}</Typography>
              <AnimateOpacity visible={isSubmitting} className={styles.assetsLoading}>
                <CircularProgress size={20} thickness={5} color="primary" />
              </AnimateOpacity>
            </div>
            {enumKeys(assets).map((asset) =>
              mode === Mode.PM ? (
                <AppFormControlLabel
                  key={asset}
                  control={<AppSwitch name={asset} />}
                  onChange={() => submitForm()}
                  labelPlacement="start"
                  label={t('assetTypes')(asset)}
                  className={styles.asset}
                />
              ) : (
                <Typography
                  variant="body1"
                  className={`${styles.asset} ${!assets[asset] ? styles.disabledAsset : ''}`}
                  key={asset}
                >
                  {t('assetTypes')(asset)}
                </Typography>
              ),
            )}
          </div>
        )}
      </Formik>
    </>
  );
};

export default ClientSubscriptionPage;
