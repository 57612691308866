import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react';

import AppTabs from 'components/AppTabs';
import { useStores } from 'stores';
import useCreateTabs from 'utils/useCreateTabs.hook';
import { matchRoutes, useLocation } from 'react-router';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface Props {
  baseUrl: string;
}

const ClientDetailsContainer: React.FC<Props> = ({ children, baseUrl }) => {
  const styles = useStyles();
  const location = useLocation();

  const matches = matchRoutes([
    { path: `${baseUrl}/contractors/:contractorId/*` },
  ], location);

  let contractorRoute = false;
  if (matches && matches.length > 0 && matches[0].params && matches[0].params.contractorId) {
    contractorRoute = true;
  }
    
  const { contextStore } = useStores();
  const isNavigationHidden = contextStore.isNavigationHidden;
  const {authStore} = useStores();

  let tabs;

  if (authStore.user?.isconsultant)
  {
     tabs = useCreateTabs(baseUrl, [
      { name: 'Basic Details', relativeUrl: '' },
    ]);
  }
  else
  {
     tabs = useCreateTabs(baseUrl, [
      { name: 'Basic Details', relativeUrl: '' },
      { name: 'Subscription', relativeUrl: '/subscription' },
      { name: 'Users', relativeUrl: '/users' },
      { name: 'Contractors', relativeUrl: '/contractors' },
      { name: 'Drawings', relativeUrl: '/drawings' },
      { name: 'Asset Register', relativeUrl: '/assets' },
      { name: 'Forms', relativeUrl: '/license' },
    ]);
  }

  return (
    <div className={styles.container}>
      {!(isNavigationHidden || contractorRoute) && <AppTabs tabs={tabs} marginBottom />}
      <div>{children}</div>
    </div>
  );
};

export default observer(ClientDetailsContainer);
