import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  ContractorRequestAccessStatus,
  clientDenyRequest,
  clientDenyRequestVariables,
  getContractorForClient,
  getContractorForClientVariables,
} from 'models/graphql';
import useText from 'texts/useText.hook';
import { GET_CONTRACTOR_FOR_CLIENT } from 'containers/shared/graphql';
import AppButtonLink from 'components/AppButtonLink';
import switcher from 'utils/switcher';
import { DENY_REQUEST } from 'containers/Client/Contractors/graphql';
import ContractorDetails from 'containers/shared/Contractors/ContractorDetails';
import AcceptOrDeny from 'components/AcceptOrDeny';
import { useNavigate, useParams } from 'react-router';

const ContractorDetailsPage: React.FC = () => {
  const { contractorId, clientId } = useParams<{
    contractorId: string;
    clientId: string;
  }>();

  const { data: contractorData, loading: contractorDataLoading } = useQuery<
    getContractorForClient,
    getContractorForClientVariables
  >(GET_CONTRACTOR_FOR_CLIENT, {
    variables: { clientId, contractorId },
  });

  const contractor = contractorData?.contractor;

  const [denyRequest] = useMutation<clientDenyRequest, clientDenyRequestVariables>(DENY_REQUEST);

  const { t } = useText('contractors');
  const navigate = useNavigate();

  return (
    <ContractorDetails
      contractor={contractor}
      loading={contractorDataLoading}
      topButton={
        contractor
          ? switcher(contractor.request?.accessStatus, [
              [
                ContractorRequestAccessStatus.REQUESTED,
                <AcceptOrDeny
                  key="AcceptOrDeny"
                  onAccept={() => navigate(`/client/${clientId}/contractors/accept/${contractor.id}`)}
                  onDeny={async (setLoading) => {
                    setLoading(true);
                    await denyRequest({
                      variables: { clientId, contractorId },
                    });
                  }}
                />,
              ],
              [ContractorRequestAccessStatus.ACCEPTED, <></>],
            ]) || (
              <AppButtonLink to={`/client/${clientId}/contractors/accept/${contractorId}`}>
                {t('contractors')('acceptContractor')}
              </AppButtonLink>
            )
          : null
      }
    />
  );
};

export default ContractorDetailsPage;
