import React from 'react';

import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import useText from 'texts/useText.hook';
import SitesNotFoundOrLoading from 'components/SitesNotFoundOrLoading';
import useSites from '../useSites';
import ProjectPage from './ProjectPage';
import ProjectCreatePage from './ProjectCreatePage';
import { Route, Routes, useLocation } from 'react-router';
import { adaptPath } from 'utils/adaptPath';

const TaskRoutes: React.FC = () => {
  const {pathname} = useLocation();
  const updatedUrl =  adaptPath(pathname, 'projects')
  
  const { current, renderBreadcrumb } = useSites(updatedUrl);

  const { t } = useText('projects');
  return current ? (
    <DefaultErrorBoundary>
      {renderBreadcrumb(t('projects')('projectsTitle'))}
      <Routes>
        <Route path={`edit/:documentId`} element={<ProjectCreatePage />} />
        <Route path={`add`} element={<ProjectCreatePage />} />
        <Route path={`/`} element={<ProjectPage />} />
      </Routes>
    </DefaultErrorBoundary>
  ) : (
    <SitesNotFoundOrLoading path={'/'} name="Projects" />
  );
};

export default TaskRoutes;
