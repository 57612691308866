import React, { useEffect, useImperativeHandle, useState } from 'react';
import * as R from 'ramda';
import { FormControlLabel, Theme, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { alpha } from '@mui/material/styles';
import { Formik, FormikErrors, useFormikContext } from 'formik';

import { AppTextField, AppFormControlLabel, AppSwitch, AppDateField } from 'components/form';
import AppSelectField from 'components/form/AppSelectField';
import cn from 'classnames';
import AppButton from 'components/AppButton';
import PhotoIcon from 'assets/photoIcon.svg';
import SignatureIcon from 'assets/signatureIcon.svg';
import trashIcon from 'assets/trashIcon.svg';
import trashIconMono from 'assets/trashIcon_mono.svg';
import alertIcon from 'assets/alertIcon.svg';
import useText from 'texts/useText.hook';
import {
  clientSitesAssetTypes_client_sites as ClientSite,
  ReOccurenceType,
  Day,
  ReOccurenceWeekIndex,
  ReOccurenceInput,
} from 'models/graphql';
import Yup from 'utils/yup';
import AppSelectMultipleField from 'components/form/AppSelectMultipleField';
import styleUtils from 'style/styleUtils';
import ReOccurenceField from 'components/form/ReOccurenceField';
import { reOccurenceResolveInput } from 'components/form/ReOccurenceField/ReOccurenceField';
import { addYears } from 'date-fns';
import { weekDays } from 'shared/days';
import { months } from 'shared/months';
import { FormContentType, FormContentType as types } from 'shared/assetFormContent.interface';
import useGetStaticFields from 'constants/staticFields';
import EditorFormInput from './EditorFormInput';
import Dialog from './Dialog';

enum FREQUENCY_SAVE_STATUS {
  DEFAULT,
  ALERT,
  ACCEPTED,
}

export interface EditorFormValues {
  parameterName: string;
  type: string;
  note: string;
  mandatory: boolean;
  selectedAssetIds: [string];
  selectableAssets: {
    id: string;
    name: string;
  }[];
  assets?: [string];
  value: string;
  comply: boolean;
  values: {
    value: string;
    comply?: boolean;
  }[];
  minValue: number | null;
  maxValue: number | null;
  reOccurence: ReOccurenceInput;
  deadline?: Date;
  documentDeadline?: Date;
}

export interface ValidationSchema {
  parameterName: string;
  values?: {
    value: string;
    comply?: boolean;
  }[];
  deadline?: Date;
  documentDeadline?: Date;
}

const baseButtonStyle = {
  width: 'min-content',
  minWidth: 'min-content',
  padding: '0 20px',
};
const baseMandatoryStyle = {
  width: '100%',
  paddingTop: 0,
};
const styles = (theme: Theme) =>
  createStyles({
    preventOutsideClickOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      '&.active': {
        zIndex: theme.zIndex.drawer + 1,
      },
    },
    formBox: {
      width: '100%',
      position: 'relative',
      zIndex: theme.zIndex.drawer + 2,
    },
    form: {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      width: 'min(575px, 100%)',
      borderRadius: '8px',
      '&>*': {
        padding: theme.spacing(2),
      },
    },
    editorForm: {
      '& > :not(.full-width-form-element)': {
        width: '85%',
      },
      '& .datepicker-element': {
        background: 'none !important',
        minWidth: '100%',
      },
      background: '#F3F4F9',
      width: '75%',
      '& .MuiInputBase-root': {
        background: '#FFFFFF',
        borderRadius: '8px',
      },
      '& .MuiFormControl-root': {
        background: '#FFFFFF',
      },
      '& .MuiButtonBase-root': {
        background: '#FFFFFF',
      },
    },
    buttonLabel: {
      fontSize: theme.fontSizes['12'],
      paddingBottom: '8px',
    },
    label: {
      cursor: 'initial',
      width: '100%',
      alignItems: 'flex-start',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        fontSize: theme.fontSizes['12'],
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-disabled': {
          color: alpha(theme.palette.text.primary, 0.4),
        },
      },
      '&.no-label  .MuiFormControlLabel-label': {
        padding: 0,
      },
      '& > div': {
        cursor: 'pointer',
      },
    },
    iconWrapper: {
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.grey['100'],
      borderRadius: '20px',
    },
    formSectionSeparator: {
      padding: 0,
      height: '1px',
      backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
      paddingLeft: '16px',
      margin: '0 16px',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      '&>*': {
        marginLeft: '16px',
      },
      width: '100%',
    },
    iconButton: {
      width: 'min-content',
      minWidth: 'min-content',
      padding: '0 20px',
      '& > span': {
        width: 'max-content',
        height: 'max-content',
      },
    },
    linkButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: 0,
      margin: '0 16px',
      border: 0,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      width: 'min-content',
      '& > img': {
        paddingRight: theme.spacing(1),
      },
      '& > div': {
        whiteSpace: 'nowrap',
      },
    },
    removeIconContainer: {
      ...styleUtils.rowCenterCenter,
      height: theme.sizes.button.height,
      paddingLeft: theme.spacing(1),
    },
    removeIcon: {
      width: 20,
      height: 20,
      maxWidth: 'none',
      maxHeight: 'none',
    },
    textFieldWrapper: {
      width: '45%',
      '&>*': {
        minWidth: '100% !important',
      },
    },
    dateArea: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&>*': {
        width: '45%',
      },
    },
    basicMandatory: baseMandatoryStyle,
    mandatoryControl: {
      ...baseMandatoryStyle,
      paddingBottom: 0,
    },
    secondaryButton: baseButtonStyle,
    mainButton: {
      ...baseButtonStyle,
      background: '#44924D !important',
    },
  });
const useStyles = makeStyles(styles);

function formValuesDiffer(values1: EditorFormValues, values2: EditorFormValues) {
  const sortById = R.sortBy(R.prop('id'));
  return (
    JSON.stringify({
      ...values1,
      selectableAssets: sortById(values1.selectableAssets),
      selectedAssetIds: values1.selectedAssetIds.sort(),
    }) !==
    JSON.stringify({
      ...values2,
      selectableAssets: sortById(values2.selectableAssets),
      selectedAssetIds: values2.selectedAssetIds.sort(),
    })
  );
}

const OnChangeComponent: React.FC<{
  onChange: (_: EditorFormValues) => void;
}> = ({ onChange }) => {
  const { values }: { values: EditorFormValues } = useFormikContext();
  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  return null;
};

const getAvailableTypes = (parameterNames: string[]) => {
  const defaultTypes = [
    { type: 'TEXT', name: 'Text' },
    { type: 'BOOLEAN', name: 'Boolean' },
    { type: 'MULTIVALUE', name: 'Multivalue' },
    { type: 'NUMERIC', name: 'Integer' },
    { type: 'MULTISELECT', name: 'MultiSelect' },
    { type: 'FILES', name: 'Files' },
    { type: 'SIGNATURE', name: 'Signature' },
    { type: 'DATE', name: 'Date' },
  ];

  const availableTypes = defaultTypes.filter((type) => type.name === 'Date' || !parameterNames.includes(type.name));

  return availableTypes;
};

const EditorForm: React.FC<{
  onCancel: () => void;
  onDelete: () => void;
  onUpdate: (_: Partial<EditorFormValues>) => Promise<void>;
  content: EditorFormValues;
  formTemplateActive: boolean;
  selectedKey?: string;
  paddingTop: number;
  selectedSite?: ClientSite;
  canceling: boolean;
  setCanceling: (_: boolean) => void;
  parameterNames: string[];
  ref: ((instance: unknown) => void) | React.MutableRefObject<unknown> | null;
}> = React.forwardRef(
  (
    {
      onCancel,
      onDelete,
      onUpdate,
      content,
      formTemplateActive,
      selectedKey,
      paddingTop,
      canceling,
      setCanceling,
      parameterNames,
    },
    ref,
  ) => {
    const classes = useStyles();
    const { t } = useText('common', 'tasks', 'form');
    const [deleting, setDeleting] = useState(false);
    const [changeToReOccuring, setChangeToReOccuring] = useState<FREQUENCY_SAVE_STATUS>(FREQUENCY_SAVE_STATUS.DEFAULT);
    const ADD_PARAMETER = selectedKey === '_add_parameter';
    const addParameterType = ADD_PARAMETER ? 'TEXT' : '';
    const typeIsOneOf = R.any(R.equals(content.type || addParameterType));
    const staticFields = useGetStaticFields();

    const getInitialValues = () => ({
      parameterName: content?.parameterName || '',
      type: content?.type || addParameterType || '',
      note: content?.note || '',
      value: content?.value || '',
      values: content?.values || [],
      minValue: content?.minValue || null,
      maxValue: content?.maxValue || null,
      comply: content?.comply || false,
      mandatory: content?.mandatory || false,
      selectedAssetIds: content?.selectedAssetIds || [],
      selectableAssets: content?.selectableAssets || [],
      reOccurence: {
        type: content?.reOccurence?.type || ReOccurenceType.NEVER,
        endDate: content?.reOccurence?.endDate || addYears(new Date(), 1),
        months: content?.reOccurence?.months || [months[new Date().getMonth()]],
        day: content?.reOccurence?.day || weekDays[new Date().getDay()] || Day.MONDAY,
        weekIndex: content?.reOccurence?.weekIndex || ReOccurenceWeekIndex.FIRST,
      },
      deadline: content?.deadline,
      documentDeadline: content?.documentDeadline,
    });

    const [initialValues, setInitialValues] = useState<EditorFormValues>(getInitialValues());

    useEffect(() => {
      setInitialValues(getInitialValues());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedKey]);

    const [formikValues, setValues] = useState<EditorFormValues>(initialValues);
    useImperativeHandle(ref, () => ({
      formChanged() {
        return formValuesDiffer(initialValues, formikValues);
      },
    }));

    const validationSchema: Yup.ObjectSchema<ValidationSchema> = Yup.object().shape({
      parameterName: Yup.string()
        .required('Please fill out this field.')
        .matches(/^[^.]+$/, 'Dots are prohibited.'),
      values: Yup.array().of(
        Yup.object()
          .required()
          .shape({
            value: Yup.string().required('Please fill out this field.'),
            comply: Yup.boolean(),
          }),
      ),
      deadline: Yup.date().when(
        'reOccurence.type',
        ([type]: Array<ReOccurenceType>, schema: Yup.DateSchema<Date | undefined>) => {
          return type !== ReOccurenceType.NEVER ? schema.required(
            t('common')('required', {
              field: t('form')('firstOccurDeadline')
            })
          ) : schema;
        },
      ),
      documentDeadline: Yup.date().when(
        'reOccurence.type',
        ([type]: Array<ReOccurenceType>, schema: Yup.DateSchema<Date | undefined>) => {
          return type !== ReOccurenceType.NEVER ? schema.required(
            t('common')('required', {
              field: t('tasks')('documentDeadline')
            })
          ) : schema;
        },
      ),
    })

    return (
      (<Formik<EditorFormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          let formValues: Partial<EditorFormValues> = {
            type: values.type,
            parameterName: values.parameterName,
          };

          if (values.type === types.TEXT || values.type === types.DATE) {
            formValues = {
              ...formValues,
              value: values.value,
              comply: values.comply,
              note: values.note,
              mandatory: values.mandatory,
              assets: values.selectedAssetIds,
            };
          } else if (
            values.type === types.MULTIVALUE ||
            values.type === types.BOOLEAN ||
            values.type === types.MULTISELECT
          ) {
            formValues = {
              ...formValues,
              values: values.values,
              note: values.note,
              mandatory: values.mandatory,
              assets: values.selectedAssetIds,
            };
          } else if (values.type === types.NUMERIC) {
            formValues = {
              ...formValues,
              minValue: values.minValue,
              maxValue: values.maxValue,
              comply: values.comply,
              note: values.note,
              mandatory: values.mandatory,
              assets: values.selectedAssetIds,
            };
          } else if (values.type === types.FILES || values.type === types.SIGNATURE) {
            formValues = {
              ...formValues,
              mandatory: values.mandatory,
              assets: values.selectedAssetIds,
            };
          } else if (values.type === types.FREQUENCY) {
            formValues = {
              ...formValues,
              reOccurence: reOccurenceResolveInput(values.reOccurence),
              deadline: values.deadline,
              documentDeadline: values.documentDeadline,
            };
          }

          const changeToReOccurence =
            values.reOccurence.type !== ReOccurenceType.NEVER &&
            initialValues.reOccurence.type === ReOccurenceType.NEVER;
          const accepted = changeToReOccuring === FREQUENCY_SAVE_STATUS.ACCEPTED;
          if (changeToReOccurence && !accepted) {
            setChangeToReOccuring(FREQUENCY_SAVE_STATUS.ALERT);
          } else {
            onUpdate(formValues);
            if (changeToReOccuring !== FREQUENCY_SAVE_STATUS.DEFAULT) {
              setChangeToReOccuring(FREQUENCY_SAVE_STATUS.DEFAULT);
            }
          }
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors: FormikErrors<EditorFormValues> = {};

          const isDuplicatedInStaticFields = () => {
            const staticFieldsKeys = staticFields.map((field) => field.key.toLowerCase());
            return staticFieldsKeys.includes(values.parameterName.toLowerCase());
          };

          if (parameterNames.includes(values.parameterName) && values.parameterName !== initialValues.parameterName) {
            errors.parameterName = 'Duplicate parameter name.';
          } else if (isDuplicatedInStaticFields()) {
            errors.parameterName =
              "This field name is already exist in the static fields. User shouldn't add the parameter with the same name.";
          }

          if (values.values.length) {
            const multiselectValues = values.values.map((item) => item.value);
            const obj: { [key: string]: number } = {};
            const occurances = multiselectValues.reduce((acc, curr) => {
              if (!acc[curr]) {
                acc[curr] = 1;
              } else {
                acc[curr] += 1;
              }
              return acc;
            }, obj);
            errors.values = R.range(0, values.values.length).map(() => ({}));
            R.forEachObjIndexed((value, key) => {
              if (value <= 1) return;
              let indexes: number[] = [];
              values.values.forEach((item, index) => {
                if (item.value === key) {
                  indexes = [...indexes, index];
                }
              });
              const [, ...tail] = indexes;
              tail.forEach((index) => {
                if (errors?.values?.[index]) {
                  (errors.values[index] as FormikErrors<{
                    value: string;
                  }>).value = 'Duplicate parameter name.';
                }
              });
            }, occurances);
            values.values.forEach((item, index) => {
              if (!item.value && errors?.values?.[index]) {
                (errors.values[index] as FormikErrors<{
                  value: string;
                }>).value = 'Empty parameter.';
              }
            });

            if (!errors.values.find((valueError) => !R.isEmpty(valueError))) {
              // if all the value's error object is empty
              delete errors.values;
            }
          }

          return errors;
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <>
              <button
                className={cn(classes.preventOutsideClickOverlay, {
                  active: formValuesDiffer(initialValues, values),
                })}
                onClick={() => setCanceling(true)}
                type="button"
              >
                {' '}
              </button>
              <Box className={classes.formBox} style={{ paddingTop }}>
                <OnChangeComponent onChange={setValues} />
                <div className={cn(classes.form, classes.editorForm)}>
                  {typeIsOneOf([
                    types.TEXT,
                    types.BOOLEAN,
                    types.MULTIVALUE,
                    types.NUMERIC,
                    types.MULTISELECT,
                    types.DATE,
                  ]) && (
                    <>
                    
                      <AppSelectField
                        label="Type"
                        name="type"
                        options={{
                          array: getAvailableTypes(parameterNames),
                          value: (type) => type.type,
                          key: (type) => type.type,
                          template: (type) => type.name,
                        }}
                        onChange={(value) => {
                          const fillMissingValues = () => {
                            const missing = Math.max(2 - values.values.length, 0);
                            setFieldValue('values', [...values.values, ...R.times(() => ({ value: '' }), missing)]);
                          };
                          if (value.type === 'BOOLEAN') {
                            if (values.values.length < 2) {
                              fillMissingValues();
                            } else if (values.values.length > 2) {
                              const [a, b] = values.values;
                              setFieldValue('values', [a, b]);
                            }
                          } else if (
                            (value.type === 'MULTIVALUE' || value.type === 'MULTISELECT') &&
                            values.values.length < 2
                          ) {
                            fillMissingValues();
                          }
                          if (value.type !== 'TEXT') {
                            setFieldValue('value', initialValues.value);
                          }
                          if (value.type !== 'BOOLEAN' && value.type !== 'MULTIVALUE' && value.type !== 'MULTISELECT') {
                            setFieldValue('values', initialValues.values);
                          }
                          if (value.type !== 'NUMERIC') {
                            setFieldValue('minValue', initialValues.minValue);
                            setFieldValue('maxValue', initialValues.maxValue);
                          }

                          if (value.type === FormContentType.SIGNATURE) {
                            setFieldValue('parameterName', 'Signature');
                          } else if (value.type === FormContentType.FILES) {
                            setFieldValue('parameterName', 'Files');
                          } else {
                            setFieldValue('parameterName', '');
                          }
                        }}
                      />
                        <AppTextField
                        label="Parameter Name"
                        name="parameterName"
                        type="text"
                        disabled={values.type === FormContentType.FILES || values.type === FormContentType.SIGNATURE}
                      />
                      {values.type !== FormContentType.FILES && values.type !== FormContentType.SIGNATURE && (
                        <EditorFormInput />
                      )}
                      {values.type !== FormContentType.FILES && values.type !== FormContentType.SIGNATURE && (
                        <AppTextField label="Note" name="note" type="text" />
                      )}
                      <AppFormControlLabel
                        className={cn(
                          'full-width-form-element',
                          values.type === FormContentType.FILES || values.type === FormContentType.SIGNATURE
                            ? classes.basicMandatory
                            : classes.mandatoryControl,
                        )}
                        control={<AppSwitch name="mandatory" />}
                        labelPlacement="start"
                        label="Mandatory Value"
                      />
                      <AppSelectMultipleField
                        label="Linked Assets"
                        name="selectedAssetIds"
                        options={{
                          array: content.selectableAssets,
                          value: (asset) => asset.id,
                          key: (asset) => asset.id,
                          template: (asset) => asset.name,
                        }}
                      />
                    </>
                  )}
                  {typeIsOneOf([types.FREQUENCY]) && (
                    <>
                      <div className={classes.buttonLabel}>Frequency</div>
                      <div>
                        <ReOccurenceField
                          name="reOccurence"
                          disabled={formTemplateActive || initialValues.reOccurence.type !== ReOccurenceType.NEVER}
                        />
                      </div>
                      {values.reOccurence.type !== ReOccurenceType.NEVER && (
                        <>
                          <div className={cn(classes.dateArea, 'full-width-form-element', 'no-background')}>
                            <AppDateField
                              label="First Occurence Deadline"
                              name="deadline"
                              minDate={new Date()}
                              required
                              disabled={formTemplateActive || initialValues.reOccurence.type !== ReOccurenceType.NEVER}
                            />
                            <AppDateField
                              label={t('tasks')('documentDeadline')}
                              name="documentDeadline"
                              minDate={new Date()}
                              required
                              disabled={formTemplateActive || initialValues.reOccurence.type !== ReOccurenceType.NEVER}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {typeIsOneOf([types.FILES, types.SIGNATURE]) && (
                    <>
                      <FormControlLabel
                        labelPlacement="top"
                        className={classes.label}
                        label={typeIsOneOf([types.FILES]) ? 'Files' : 'Signature'}
                        control={
                          <div className={classes.iconWrapper}>
                            <img src={typeIsOneOf([types.FILES]) ? PhotoIcon : SignatureIcon} alt={selectedKey} />
                          </div>
                        }
                      />
                      <AppFormControlLabel
                        className={cn('full-width-form-element', classes.basicMandatory)}
                        control={<AppSwitch name="mandatory" />}
                        labelPlacement="start"
                        label="Mandatory Value"
                      />
                      <AppSelectMultipleField
                        label="Linked Assets"
                        name="selectedAssetIds"
                        options={{
                          array: content.selectableAssets,
                          value: (asset) => asset.id,
                          key: (asset) => asset.id,
                          template: (asset) => asset.name,
                        }}
                      />
                    </>
                  )}
                  <div className={cn(classes.formSectionSeparator, 'full-width-form-element')} />
                  <div className={cn(classes.buttonContainer, 'full-width-form-element')}>
                    {!typeIsOneOf([types.FREQUENCY]) && !ADD_PARAMETER && (
                      <AppButton
                        type="button"
                        variant="outlined"
                        onClick={() => setDeleting(true)}
                        className={classes.iconButton}
                      >
                        <img src={trashIcon} alt="Delete parameter" />
                      </AppButton>
                    )}
                    <AppButton
                      type="button"
                      variant="outlined"
                      className={classes.secondaryButton}
                      onClick={() => {
                        if (formValuesDiffer(initialValues, values)) {
                          setCanceling(true);
                        } else {
                          onCancel();
                        }
                      }}
                    >
                      {t('common')('cancel')}
                    </AppButton>
                    {(!typeIsOneOf([types.FREQUENCY]) ||
                      (!formTemplateActive && initialValues.reOccurence.type === ReOccurenceType.NEVER)) && (
                      <AppButton
                        type="submit"
                        color="primary"
                        inProgress={false}
                        className={classes.mainButton}
                        onClick={() => handleSubmit()}
                      >
                        {t('common')('set')}
                      </AppButton>
                    )}
                  </div>
                </div>
                <Dialog
                  open={deleting}
                  onClose={() => setDeleting(false)}
                  title="Delete Parameter?"
                  secondaryButtonText="cancel"
                  onSecondaryButtonClick={() => setDeleting(false)}
                  mainButtonText="delete"
                  onMainButtonClick={onDelete}
                  text="The parameter will be deleted and it cannot be restored after deletion."
                  imgAlt="Delete parameter"
                  imgSrc={trashIconMono}
                />
                <Dialog
                  open={changeToReOccuring === FREQUENCY_SAVE_STATUS.ALERT}
                  onClose={() => {
                    setChangeToReOccuring(FREQUENCY_SAVE_STATUS.DEFAULT);
                    onCancel();
                  }}
                  title="Change Frequency?"
                  secondaryButtonText="discard"
                  onSecondaryButtonClick={() => {
                    setChangeToReOccuring(FREQUENCY_SAVE_STATUS.DEFAULT);
                    onCancel();
                  }}
                  mainButtonText="save changes"
                  onMainButtonClick={() => {
                    setChangeToReOccuring(FREQUENCY_SAVE_STATUS.ACCEPTED);
                    handleSubmit();
                  }}
                  text="Frequency settings cannot be modified after being changed to recurring."
                  imgAlt="Change frequency"
                  imgSrc={alertIcon}
                />
                <Dialog
                  open={canceling}
                  onClose={() => setCanceling(false)}
                  title="Unsaved Changes"
                  secondaryButtonText="discard"
                  onSecondaryButtonClick={onCancel}
                  mainButtonText="save changes"
                  onMainButtonClick={() => {
                    handleSubmit();
                    setCanceling(false);
                  }}
                  text="You are about to close the parameter configuration with unsaved changes. Would you like to save
                  these changes?"
                  imgAlt="Keep changes?"
                  imgSrc={alertIcon}
                />
              </Box>
            </>
          );
        }}
      </Formik>)
    );
  },
);

export default EditorForm;
