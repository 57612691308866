import React, { useEffect, useState } from 'react';

import Yup from 'utils/yup';
import AcceptOrDeny from 'components/AcceptOrDeny';
import { AnimateHeight } from 'components/animations';
import { Formik } from 'formik';
import { AppForm, AppFormControlLabel } from 'components/form';
import AppButton from 'components/AppButton';
import AppCheckboxField from 'components/form/AppCheckbox';
import AppTextAreaField from 'components/form/AppTextAreaField';
import useText from 'texts/useText.hook';
import { useStores } from 'stores';
import useTaskDetailsAcceptOrDenyStyles from 'containers/shared/Tasks/TaskDetailsStyles';
import {
  taskResponsibleAccept,
  taskResponsibleAcceptVariables,
  taskResponsibleReject,
  taskResponsibleRejectVariables,
  taskAccept,
  taskAcceptVariables,
  taskForClient_task as Task,
  taskReject,
  taskRejectVariables,
} from 'models/graphql';
import { MutationTuple } from '@apollo/client';
import Rating from 'components/Rating';
import { Box, Typography, useTheme } from '@mui/material';

interface FormValues {
  comment?: string;
  close?: boolean;
  rating?: number;
}

interface Comment {
  comment?: string;
}
interface Props {
  task: Task;
  accept:
    | MutationTuple<taskAccept, taskAcceptVariables>[0]
    | MutationTuple<taskResponsibleAccept, taskResponsibleAcceptVariables>[0];
  reject:
    | MutationTuple<taskReject, taskRejectVariables>[0]
    | MutationTuple<taskResponsibleReject, taskResponsibleRejectVariables>[0];
}

const TaskAcceptOrDeny: React.FC<Props> = ({ task, accept, reject }) => {
  const { t } = useText('common', 'tasks');
  const styles = useTaskDetailsAcceptOrDenyStyles();
  const theme = useTheme();

  const isContractor = !!task.contractorResponsible;

  const { authStore } = useStores();

  const selfApprove =
    task?.approval?.id === authStore?.user?.id && task?.internalResponsible?.id === authStore?.user?.id;

  // States
  const [selected, setSelected] = useState<'accept' | 'deny' | undefined>();
  useEffect(() => setSelected(undefined), [setSelected, task]);

  const validationSchema: Yup.ObjectSchema<Comment> = Yup.object().shape({
    comment: selected === 'deny' && !selfApprove ? Yup.string().required(
      t('common')('required', {
        field: t('tasks')('reason')
      })
    ) : Yup.string().optional(),
  });
  
  return (
    <div className={styles.acceptOrDenyContainer}>
      <Formik<FormValues>
        initialValues={{
          comment: '',
          rating: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setStatus }) => {
          if (!task) return;
          if (
            (selected === 'accept' && !values.rating && isContractor) ||
            (values.close && !values.rating && isContractor)
          ) {
            setStatus(t('tasks')('ratingNeeded'));
            return;
          }

          if (selected === 'deny') {
            await reject({
              variables: {
                taskId: task.id,
                close: values.close,
                comment: values.comment || '',
                rating: values.rating,
              },
            });
          } else {
            await accept({ variables: { taskId: task.id, comment: values.comment, rating: values.rating! } });
          }
        }}
      >
        {({ status, values, handleSubmit, isSubmitting, setValues }) => (
          <AppForm handleSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center"
              }}>
              <AcceptOrDeny
                acceptText={t('common')('approve')}
                selected={selected}
                onAccept={() => setSelected('accept')}
                onDeny={() => setSelected('deny')}
                selfApprove={selfApprove}
              />
            </Box>
            <>
              {!selfApprove && (
                <AnimateHeight visible={!!selected} slow className={styles.fullwidthForm}>
                  {selected === 'deny' ? (
                    <Box
                      sx={{
                        pt: 2,
                        px: 3
                      }}>
                      <AppTextAreaField name="comment" placeholder={t('tasks')('reason')} />
                      <AppFormControlLabel
                        control={<AppCheckboxField name="close" />}
                        labelPlacement="end"
                        label={t('tasks')('close')}
                      />
                    </Box>
                  ) : null}
                  {((selected === 'accept' && isContractor) || (values.close && isContractor)) && (
                    <Box
                      sx={{
                        py: 1,
                        my: values.close ? 2 : 4,
                        bgcolor: theme.palette.grey['50'],
                        textAlign: "center"
                      }}>
                      <Box sx={{
                        p: 1
                      }}>
                        <Typography variant="body2">{t('tasks')('rating')}</Typography>
                      </Box>
                      <Rating
                        rating={0}
                        changeable
                        addMargin
                        onRatingChanged={(newRating: number) =>
                          setValues({ rating: newRating, comment: values.comment, close: values.close })
                        }
                      />
                      {!!status && (
                        <Typography variant="body2" sx={{
                          color: "error"
                        }}>
                          {status}
                        </Typography>
                      )}
                    </Box>
                  )}
                  <div className={styles.acceptOrDenySubmit}>
                    <AppButton variant="outlined" type="submit" inProgress={isSubmitting}>
                      {t('common')('submit')}
                    </AppButton>
                  </div>
                </AnimateHeight>
              )}
            </>
          </AppForm>
        )}
      </Formik>
    </div>
  );
};

export default TaskAcceptOrDeny;
