import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import cn from 'utils/cn';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'inline-block',
      paddingTop: parseInt(theme.spacing(1)) / 2,
      paddingBottom: parseInt(theme.spacing(1)) / 2,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: theme.fontSizes[12],
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
      borderRadius: theme.shape.borderRadius,

      '&.block': {
        display: 'block',
        textAlign: 'center',
      },
    },
  }),
);

export interface StatusBadgeState {
  color?: string;
  background: string;
}

const StatusBadge: React.FC<{
  state: StatusBadgeState;
  block?: boolean;
}> = ({ children, state, block }) => {
  const styles = useStyles();

  return (
    <div
      className={cn(styles.container, { block })}
      style={{ color: state.color, background: state.background, paddingBottom: '0px', paddingTop: '1.8px' }}
    >
      {children}
    </div>
  );
};

export default StatusBadge;
