import { Workbook } from 'exceljs';
import Uint8ToString from './uint8ToString';

const useGenerateBase64FromWorkbook = () => {
  return async (workbook: Workbook) => {
    const buf = await workbook.xlsx.writeBuffer();
    const b64encoded = btoa(Uint8ToString(buf));
    return b64encoded;
  };
};

export default useGenerateBase64FromWorkbook;
