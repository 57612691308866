import React from 'react';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import Breadcrumb from 'components/Breadcrumb';
import ContractorDetailsRoutes from 'containers/Pm/Contractors/ContractorDetails/ContractorDetailsRoutes';
import ContractorCreatePage from '../../shared/Contractors/ContractorCreatePage';
import ContractorsPage from './ContractorsPage';
import { Route, Routes } from 'react-router';

const ContractorsRoutes: React.FC = () => {
  return (
  <DefaultErrorBoundary>
    <Breadcrumb to={'/pm/contractors'} name="Contractors" />
    <Routes>
      <Route path={`add`} element={<ContractorCreatePage />} />
      <Route path={`:contractorId/edit`} element={<ContractorCreatePage />} />
      <Route path={`:contractorId`}>
        <Route index element={<ContractorDetailsRoutes />} />
        <Route path={`*`} element={<ContractorDetailsRoutes />} />
      </Route>
      <Route path={`/`} element={<ContractorsPage />} />
    </Routes>
  </DefaultErrorBoundary>
)};

export default ContractorsRoutes;
