import React from 'react';

import TaskComments from 'containers/shared/Tasks/TaskComments';
import { useParams } from 'react-router';

const TaskCommentsPage: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>();
  return <TaskComments taskId={taskId} />;
};

export default TaskCommentsPage;
