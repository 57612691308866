import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import styleUtils from 'style/styleUtils';

const useHomePageStyles = makeStyles((theme: Theme) => {
  return createStyles({
    loader: {
      marginTop: theme.spacing(4),
      ...styleUtils.rowCenterCenter,
    },
    clientBox: {
      padding: theme.spacing(2),
      background: theme.palette.grey['100'],
      borderRadius: theme.shape.borderRadius * 2,
    },
    grey: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    clientName: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: theme.fontSizes['24'],
      fontWeight: theme.typography.fontWeightLight,
    },
    boldFont: {
      fontWeight: theme.typography.fontWeightBold,
    },
    heading: {
      fontSize: theme.fontSizes['20'],
    },
    smallFont: {
      fontSize: theme.fontSizes['12'],
    },
    boxBg: {
      background: 'linear-gradient(#F3F4F9, #FFFFFF)',
    },
    padding: {
      padding: theme.spacing(2),
    },
    paddingX: {
      padding: `0 ${theme.spacing(2)}`,
    },
  });
});

export default useHomePageStyles;
