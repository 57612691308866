/* eslint-disable react/button-has-type */
import { CircularProgress, Theme, alpha } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { AnimateOpacity } from 'components/animations';
import React, { useState } from 'react';
import styleUtils from 'style/styleUtils';
import useText from 'texts/useText.hook';
import cn from 'utils/cn';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
    },
    button: {
      width: theme.sizes.button.minWidth,
      height: theme.sizes.button.height,
      padding: `${theme.spacing(1)} 0`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 0,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.fontSizes[12],
      transition: theme.transitions.create(['background', 'color'], {
        duration: theme.transitions.duration.short,
      }),

      '&, &:focus': {
        border: 'solid 2px transparent',
      },

      '&:first-child': {
        borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
      },
      '&:last-child': {
        borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
      },
      '&.accept, &.accept:focus': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,

        '&:hover': {
          background: alpha(theme.palette.primary.main, 0.1),
        },

        '&.selected': {
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      },
      '&.deny, &.deny:focus': {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,

        '&:hover': {
          background: alpha(theme.palette.error.main, 0.1),
        },

        '&.selected': {
          background: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
      },
    },
    loader: {
      ...styleUtils.absoluteFill,
      ...styleUtils.rowCenterCenter,
      background: alpha(theme.palette.background.default, 0.8),
    },
  }),
);

const AcceptOrDeny: React.FC<{
  acceptText?: string;
  selected?: 'accept' | 'deny';
  onAccept: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
  onDeny: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
  selfApprove?: boolean;
}> = ({ acceptText, selected, onAccept, onDeny, selfApprove }) => {
  const { t } = useText('common');
  const styles = useStyles();
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.container}>
      <button
        type={selfApprove ? 'submit' : 'button'}
        onClick={() => onAccept(setLoading)}
        className={cn(styles.button, 'accept', { selected: selected === 'accept' })}
      >
        {acceptText || t('common')('accept')}
      </button>
      <button
        type={selfApprove ? 'submit' : 'button'}
        onClick={() => onDeny(setLoading)}
        className={cn(styles.button, 'deny', { selected: selected === 'deny' })}
      >
        {t('common')('deny')}
      </button>

      <AnimateOpacity visible={loading}>
        <div className={styles.loader}>
          <CircularProgress size={24} color="primary" />
        </div>
      </AnimateOpacity>
    </div>
  );
};

export default AcceptOrDeny;
