import React from 'react';
import { useQuery } from '@apollo/client';
import { AnimateHeight } from 'components/animations';
import useText from 'texts/useText.hook';
import AppAvatar from 'components/AppAvatar';
import { logs as logsGQL, logsVariables } from 'models/graphql';
import { LOGS } from '../graphql';
import { Box, CircularProgress, Theme, Typography, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    logContainer: {
      padding: theme.spacing(2),
      borderBottom: `solid 1px ${theme.palette.grey[300]}`,
      display: 'flex',
    },
    logAvatar: {
      marginRight: theme.spacing(1),
    },
    timestamp: {
      marginTop: theme.spacing(0.25),
      color: theme.palette.grey[500],
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.fontSizes[12],
    },
  });
});

const TaskHistory: React.FC<{ taskId: string }> = ({ taskId }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { t } = useText('tasks');

  const getTitle = (operation: string) => {
    switch (operation) {
      case 'taskCreate':
        return t('tasks')('created');
      case 'taskUpdate':
        return t('tasks')('updated');
      case 'taskAccept':
        return t('tasks')('accepted');
      case 'taskReject':
        return t('tasks')('rejected');
      case 'taskClose':
        return t('tasks')('closed');
      case 'taskCancel':
        return t('tasks')('canceled');
      case 'taskStart':
        return t('tasks')('started');
      case 'taskComplete':
        return t('tasks')('completed');
      case 'taskSubmitSuppInfo':
        return t('tasks')('submittedSuppInfo');
      case 'taskResponsibleReject':
        return t('tasks')('responsibleRejected');
      case 'taskResponsibleAccept':
        return t('tasks')('responsibleAccepted');
      case 'taskSendForReApproval':
        return t('tasks')('sentForReApproval');
      case 'taskReassign':
        return t('tasks')('reassigned');
      default:
        return null;
    }
  };

  const { data, loading } = useQuery<logsGQL, logsVariables>(LOGS, { variables: { dataId: taskId } });
  const logs = data?.logs.filter((log) => {
    return getTitle(log.gqlOperation);
  });

  if (loading || !logs) {
    return (
      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "center"
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return <>
    {logs.map((log) => (
      <AnimateHeight visible key={log.id}>
        <div className={styles.logContainer}>
          <div className={styles.logAvatar}>
            <AppAvatar name={log.user.name} imageSrc={log.user.picture?.srcUrl} />
          </div>
          <div>
            <div className={styles.title}>{getTitle(log.gqlOperation)}</div>
            <div className={styles.timestamp}>
              {`${theme.dateFormat.normal(log.createdAt)} | ${theme.dateFormat.time(
                log.createdAt,
              )} ${theme.dateFormat.dayPeriod(log.createdAt)}`}
            </div>
          </div>
        </div>
      </AnimateHeight>
    ))}
    {logs.length === 0 && (
      <Box sx={{
        p: 3
      }}>
        <Typography align="center" variant="body1">
          No logs yet.
        </Typography>
      </Box>
    )}
  </>;
};

export default TaskHistory;
