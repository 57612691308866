import { logs } from 'models/graphql';
import { Workbook } from 'exceljs';
import { FormContent } from 'shared/assetFormContent.interface';

const regexpForFindToken = /\{\{(.*?)\}\}/g;

export const checkIsValueToken = (value: string) => {
  const matches = [...value.matchAll(regexpForFindToken)]
    .sort((match1, match2) => match2.index! - match1.index!)
    .map((match) => ({ ...match, tokenName: match[1] }));
  return matches;
};

export const getTokenFromString = (value: string) => {
  return value.toString().substring(2, value.toString().length - 2);
};

export const getTokenImageForFile = (value: string) => {
  if (value === 'Signature') {
    return 'Signature';
  }
  if (value === 'Signature 350 150 0') {
    return 'Signature';
  }
  if (value === 'Add Signature 350 150 0') {
    return 'Add Signature';
  }
  if (value === 'Files') {
    return 'Files';
  }
  if (value === 'Files 250 250 0') {
    return 'Files';
  }
  if (value === 'Add Files 250 250 0') {
    return 'Add Files';
  }

  const tokenInfo = value.split(' ');
  tokenInfo.splice(tokenInfo.length - 3, 3);
  const filesNames = ['Signature', 'Files'];
  return filesNames.find((onOfToken) => onOfToken === tokenInfo.join(' '));
};

export const getImageTokenPropertyFromToken = (value: string) => {
  const tokenInfo = value.split(' ');
  const tokenInfoLength = tokenInfo.length;
  const widthPosition = tokenInfoLength - 3;
  const heightPosition = tokenInfoLength - 2;
  const numberOfImagePosition = tokenInfoLength - 1;
  return {
    width: Number(tokenInfo[widthPosition]),
    height: Number(tokenInfo[heightPosition]),
    numberOfImage: Number(tokenInfo[numberOfImagePosition]),
  };
};

export const getPathToField = (
  tokenInFile: string,
  staticFields: {
    key: string;
    token: string[];
  }[],
) => {
  const tokenInStaticFields = staticFields.find((tokenName) => tokenName.key === tokenInFile);
  const pathToValueOfToken = tokenInStaticFields ? tokenInStaticFields.token : [tokenInFile];
  return pathToValueOfToken;
};

export const getHandlerByType = (
  type: string | null,
  handlers: {
    type: string;
    handlerFunction?: (value: string) => string;
  }[],
) => {
  return handlers.find((typeName) => {
    return typeName.type === type;
  });
};

export const getFieldType = (field: string, formTemplateContent: FormContent): string | null => {
  let fieldType = null;
  formTemplateContent.forEach((item) => {
    if (field === item.parameterName) {
      fieldType = item.type;
    }
  });

  return fieldType;
};

export const getStaticFieldByTokenName = (
  tokenInFile: string,
  staticFields: {
    key: string;
    token: string[];
    handlerFunction?: (value: string) => string;
  }[],
) => {
  return staticFields.find((tokenName) => {
    return tokenName.key === tokenInFile;
  });
};

export const getValueByPath = (
  path: string[],
  // eslint-disable-next-line @typescript-eslint/ban-types
  taskInfo: Record<string, object | boolean | string | null> | null,
  handlerFunction?: (value: string) => string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let initialValue: any = taskInfo;

  path.forEach((key) => {
    initialValue = initialValue ? initialValue[key] : '-';
  });

  initialValue = handlerFunction ? handlerFunction(initialValue) : initialValue;

  return initialValue;
};

export const getIndexOfRowWithTokensForMultiReport = (workbook: Workbook): number => {
  let indexOfRowWithToken: number | null = null;
  workbook.eachSheet((sheet) => {
    sheet.eachRow((row, rowIndex) => {
      row.eachCell((cell) => {
        const cellValue = typeof cell.value === 'string' ? cell.value : '';
        const isCellWithToken = checkIsValueToken(cellValue).length;
        if (isCellWithToken) {
          indexOfRowWithToken = rowIndex;
        }
      });
    });
  });
  return indexOfRowWithToken || -1;
};

export type tokenInfoForMultiReport = {
  cellIndex: number;
  tokenName: string;
};

export const getTokensInfoForMultiReport = (workbook: Workbook, rowIndexWithTokens: number) => {
  const tokensInfo: tokenInfoForMultiReport[] = [];

  if (rowIndexWithTokens || rowIndexWithTokens === 0) {
    const finedCell = workbook.worksheets[0].findRow(rowIndexWithTokens!);
    // eslint-disable-next-line no-unused-expressions
    finedCell?.eachCell((cell, cellId) => {
      const tokenWithBreakers = `${cell.value}`.match(regexpForFindToken);
      if (tokenWithBreakers) {
        const token = tokenWithBreakers[0].toString().substring(2, tokenWithBreakers[0].toString().length - 2);
        tokensInfo.push({
          tokenName: token,
          cellIndex: cellId,
        });
      }
    });
  }
  return tokensInfo;
};

export const generateDataForOneTaskForMultiReport = (
  staticFields: {
    key: string;
    token: string[];
  }[],
  tokensInfo: tokenInfoForMultiReport[],
  // eslint-disable-next-line @typescript-eslint/ban-types
  taskInfo: Record<string, object | string | boolean | null> | null,
  handlers: {
    type: string;
    handlerFunction?: (value: string) => string;
  }[],
) => {
  const valuesForOneTask: { cellIndex: number; cellValue: string }[] = [];

  tokensInfo.forEach((tokenInfo) => {
    const pathToField = getPathToField(tokenInfo.tokenName, staticFields);
    const staticField = getStaticFieldByTokenName(tokenInfo.tokenName, staticFields);

    let fieldHandler = staticField?.handlerFunction;

    if (!staticField && taskInfo) {
      const fieldType = getFieldType(tokenInfo.tokenName, taskInfo.formTemplateContent as FormContent);
      fieldHandler = getHandlerByType(fieldType, handlers)?.handlerFunction;
    }

    const valueForCell = taskInfo ? getValueByPath(pathToField, taskInfo, fieldHandler) || '-' : '-';
    valuesForOneTask.push({ cellIndex: tokenInfo.cellIndex, cellValue: valueForCell });
  });
  return valuesForOneTask;
};

export const getReportsLog = (value: logs | undefined) => {
  if (value) {
    const reportLogsArr = value?.logs && value.logs.map((log) => [log.gqlOperation, log.createdAt]);
    const reportLogsObj = reportLogsArr && Object.fromEntries(reportLogsArr);

    return reportLogsObj;
  }

  return {};
};
