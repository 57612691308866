import { useQuery, useMutation } from '@apollo/client';
import { useTheme } from '@mui/material';
import { Actions } from 'components/ActionsButton';
import AppTable, { AppTableFilters } from 'components/AppTable';
import TaskStatusBadge from 'components/TaskStatusBadge';
import {
  tasksForContractor,
  tasksForContractorVariables,
  tasksForContractor_contractor_tasks_nodes as Task,
  TaskStatus,
  taskSendForReApproval,
  taskSendForReApprovalVariables,
  taskSubmitSuppInfo,
  taskSubmitSuppInfoVariables,
} from 'models/graphql';
import React from 'react';
import { readDayDate } from 'shared/formatDayDate';
import useText from 'texts/useText.hook';
import usePaginationParams from 'utils/usePaginationParams';
import useSearchParams from 'utils/useSearchParams';
import useUpdateSearchParams from 'utils/useUpdateSearchParams';
import { SUBMIT_SUP_INFO } from 'containers/shared/Technician/graphql';
import TaskComplianceIcon from 'components/complianceIcons/TaskComplianceIcon';
import { GET_TASKS, TASK_SEND_FOR_RE_APPROVAL } from './graphql';
import TaskDetails from './TaskDetails';
import { useNavigate, useParams } from 'react-router';

const TasksPage: React.FC = () => {
  const { t, tt } = useText('tasks', 'taskTypes', 'common', 'urls');

  const { contractorId } = useParams<{
    contractorId: string;
  }>();
  const [search] = useSearchParams(tt('urls')('queries')('search'));
  const updateSearchParams = useUpdateSearchParams();
  const navigate = useNavigate();

  const { data: tasksData, loading: tasksDataLoading } = useQuery<tasksForContractor, tasksForContractorVariables>(
    GET_TASKS,
    {
      variables: { contractorId, pagination: usePaginationParams(), search },
    },
  );
  const [sendForReApproval] = useMutation<taskSendForReApproval, taskSendForReApprovalVariables>(
    TASK_SEND_FOR_RE_APPROVAL,
  );
  const [submitSuppInfo] = useMutation<taskSubmitSuppInfo, taskSubmitSuppInfoVariables>(SUBMIT_SUP_INFO);
  const tasks = tasksData?.contractor.tasks;

  const theme = useTheme();
  const detailsPanelActions = (task: Task) => {
    const actions: Actions = [];
    const addAttachment = {
      title: t('tasks')('addAttachment'),
      action: () => navigate(`/contractor/${contractorId}/work-instructions/${task.id}/attachments`),
    };

    if (task.status === TaskStatus.CONTRACTOR_ACCEPTED) {
      actions.push({
        title: task.contractorResponsibleUser ? t('tasks')('reAssignToTechnician') : t('tasks')('assignToTechnician'),
        action: () => navigate(`/contractor/${contractorId}/work-instructions/${task.id}/assign`),
      });
    }
    if (task.status === TaskStatus.COMPLETED) {
      actions.push(addAttachment);
      actions.push({
        title: t('tasks')('submitSuppInfo'),
        action: () => submitSuppInfo({ variables: { id: task.id } }),
      });
    }
    if (task.status === TaskStatus.REJECTED) {
      actions.push(addAttachment);
      actions.push({
        title: t('tasks')('sendForReApproval'),
        action: () => sendForReApproval({ variables: { id: task.id } }),
      });
    }
    return actions;
  };
  return (
    <>
      <AppTableFilters
        searchPlaceholder={t('tasks')('searchPlaceholder')}
        onSubmit={async ({ search: newSearch }) => {
          navigate(
            updateSearchParams({
              [tt('urls')('queries')('search')]: newSearch,
              [tt('urls')('queries')('page')]: 1, // at new search, restart the pagination
            }),
          );
        }}
        searchValue={search || undefined}
        hideShowArchived
      />
      <AppTable
        dataPaginated={tasks}
        loading={tasksDataLoading}
        noDataMessage={t('tasks')('noTasks')}
        columns={[
          {
            name: t('tasks')('workOrderId_short'),
            template: (task) => task.contractorResponsibleWorkOrderId || t('common')('emptyValue'),
          },
          {
            name: t('tasks')('workInstructionType'),
            template: (task) => t('taskTypes')(task.type),
          },
          {
            name: t('tasks')('deadline_short'),
            template: (task) => (task.deadline ? theme.dateFormat.normal(readDayDate(task.deadline)) : null),
          },
          {
            name: t('tasks')('documentDeadline_short'),
            template: (task) =>
              task.documentDeadline ? theme.dateFormat.normal(readDayDate(task.documentDeadline)) : null,
          },
          {
            name: t('tasks')('technician'),
            template: (task) => task.contractorResponsibleUser?.name || t('common')('emptyValue'),
          },
          {
            name: t('tasks')('status'),
            align: 'center',
            template: function TaskStatusTemplate(task) {
              return <TaskStatusBadge status={task.status} />;
            },
          },
          {
            key: 'compliance',
            template: function TaskCompliance(task) {
              return <TaskComplianceIcon task={task} />;
            },
          },
        ]}
        detailsPanel={(task) => ({
          title: t('taskTypes')(task.type),
          actions: detailsPanelActions(task),
          template: <TaskDetails taskId={task.id} />,
        })}
      />
    </>
  );
};

export default TasksPage;
