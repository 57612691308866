import React from 'react';
import { useStores } from 'stores';
import backIcon from 'assets/backIcon.svg';
import { observer } from 'mobx-react';
import styleUtils from 'style/styleUtils';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Link } from 'react-router';

const styleRules = (theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      padding: `0 ${theme.spacing(2)}`,
      ...styleUtils.rowCenterCenter,
    },
    icon: {
      maxWidth: 'unset',
      width: 18,
    },
  });
const useStyles = makeStyles(styleRules);

const BreadcrumbBack = () => {
  const { contextStore } = useStores();
  const breadcrumbBeforeLast =
    contextStore.breadcrumbs.length > 1 ? contextStore.breadcrumbs[contextStore.breadcrumbs.length - 2] : undefined;

  const styles = useStyles();
  return breadcrumbBeforeLast ? (
    <Link to={breadcrumbBeforeLast.to}>
      <div className={styles.container}>
        <img alt="navigate back" src={backIcon} className={styles.icon} />
      </div>
    </Link>
  ) : (
    <></>
  );
};

export default observer(BreadcrumbBack);
