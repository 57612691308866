import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { LinearProgress, useTheme } from '@mui/material';
import { addMonths, addWeeks, differenceInDays, endOfWeek, getMonth, getYear, startOfMonth } from 'date-fns';
import { endOfMonth, startOfWeek } from 'date-fns';
import { Formik } from 'formik';

import { DayDateRange, tasksForUser, tasksForUserVariables } from 'models/graphql';
import { useBreadcrumb } from 'components/Breadcrumb';
import ContentStateWrapper from 'components/ContentStateWrapper';
import { AppForm, AppSelectField } from 'components/form';
import AppSelectHorizontalField from 'components/form/AppSelectHorizontalField';
import ButtonSelector from 'components/form/ButtonSelector';
import formatDayDate, { readDayDate } from 'shared/formatDayDate';
import { monthIndexes } from 'shared/months';
import { useStores } from 'stores';
import useText from 'texts/useText.hook';
import cn from 'utils/cn';
import useSearchParams from 'utils/useSearchParams';
import useUpdateSearchParams from 'utils/useUpdateSearchParams';
import noTaskIcon from 'assets/noTaskIcon.svg';
import TaskCard from 'containers/shared/Technician/TaskCard';
import useTasksPageStyles from 'containers/shared/Technician/TasksPageStyles';
import { GET_TASKS } from '../graphql';
import { Link, useNavigate } from 'react-router';

interface FilterValues {
  periodType: 'weekly' | 'monthly';
  week: string; // in ISO string
  month: string; // in ISO string
  clientId: string; // 'all' means no filter
  urgent: 'true' | 'false';
}
const TasksPage: React.FC = () => {
  const { authStore } = useStores();
  if (!authStore.user) throw new Error('User is not logged in');
  const { user } = authStore;

  // Get filter values from URL query
  const { t, tt } = useText('tasks', 'common', 'urls');
  const [clientId, urgent, dateRangeQuery] = useSearchParams(
    tt('urls')('queries')('client'),
    tt('urls')('queries')('urgent'),
    tt('urls')('queries')('dateRange'),
  );
  const updateSearchParams = useUpdateSearchParams();
  const navigate = useNavigate();

  // resolve date range
  const thisWeekStart = startOfWeek(new Date());
  let dayDateRange: DayDateRange | undefined;
  if (dateRangeQuery) {
    const dateRangeStrings = dateRangeQuery.split('~');
    const minDayDate = dateRangeStrings[0];
    const maxDayDate = dateRangeStrings[1];
    if (!Number.isNaN(readDayDate(minDayDate).getTime()) && !Number.isNaN(readDayDate(maxDayDate).getTime())) {
      dayDateRange = {
        minDayDate,
        maxDayDate,
      };
    }
  }
  if (!dayDateRange) {
    dayDateRange = {
      minDayDate: formatDayDate(thisWeekStart),
      maxDayDate: formatDayDate(endOfWeek(thisWeekStart)),
    };
  }
  const { data: tasksData, loading: tasksLoading } = useQuery<tasksForUser, tasksForUserVariables>(GET_TASKS, {
    variables: { userId: user.id, clientId, urgent: urgent === 'true', dayDateRange },
  });
  const tasks = tasksData?.user.tasks;
  // Cache clients for filter change
  const [clients, setClients] = useState<tasksForUser['user']['clients'] | undefined>();
  useEffect(() => {
    if (tasksData?.user.clients) {
      setClients(tasksData?.user.clients);
    }
  }, [tasksData?.user.clients]);

  const isDateRangeMonthly =
    differenceInDays(readDayDate(dayDateRange.maxDayDate), readDayDate(dayDateRange.minDayDate)) > 7;
  const styles = useTasksPageStyles();
  const theme = useTheme();
  useBreadcrumb('/technician/works', t('tasks')('taskInstructions'));
  return !clients ? (
    <LinearProgress />
  ) : (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <Formik<FilterValues>
          initialValues={{
            week: !isDateRangeMonthly ? dayDateRange.minDayDate : formatDayDate(thisWeekStart),
            month: isDateRangeMonthly ? dayDateRange.maxDayDate : formatDayDate(startOfMonth(new Date())),
            periodType: isDateRangeMonthly ? 'monthly' : 'weekly',
            clientId: clientId || 'all',
            urgent: (urgent as 'true') || 'false',
          }}
          onSubmit={(values) => {
            navigate(
              updateSearchParams({
                [tt('urls')('queries')('client')]: values.clientId !== 'all' ? values.clientId : null,
                [tt('urls')('queries')('urgent')]: values.urgent === 'true' ? 'true' : null,
                [tt('urls')('queries')('dateRange')]:
                  values.periodType === 'monthly'
                    ? `${formatDayDate(new Date(values.month))}~${formatDayDate(endOfMonth(new Date(values.month)))}`
                    : `${formatDayDate(new Date(values.week))}~${formatDayDate(endOfWeek(new Date(values.week)))}`,
              }),
            );
          }}
        >
          {({ handleSubmit, values, submitForm }) => {
            return (
              <AppForm handleSubmit={handleSubmit}>
                <div className={styles.filter}>
                  <div className={cn(styles.filterFullRow, styles.filterPeriodType)}>
                    <ButtonSelector<'weekly' | 'monthly'>
                      name="periodType"
                      options={{
                        array: ['weekly', 'monthly'],
                        key: (type) => type,
                        text: (type) => tt('tasks')('filter')(type),
                      }}
                      onChange={submitForm}
                    />
                  </div>

                  <div className={cn(styles.filterFullRow, styles.filterHalfRowMd, styles.paddingTopMd)}>
                    {values.periodType === 'monthly' ? (
                      <AppSelectHorizontalField
                        name="month"
                        options={{
                          array: [
                            formatDayDate(addMonths(readDayDate(values.month), -1)),
                            values.month,
                            formatDayDate(addMonths(readDayDate(values.month), 1)),
                          ],
                          value: (month) => month,
                          template: (month) =>
                            `${tt('common')('months')(monthIndexes[getMonth(new Date(month))])} ${getYear(
                              new Date(month),
                            )}`,
                        }}
                        onChange={submitForm}
                      />
                    ) : null}

                    {values.periodType === 'weekly' ? (
                      <AppSelectHorizontalField
                        name="week"
                        options={{
                          array: [
                            formatDayDate(addWeeks(readDayDate(values.week), -1)),
                            values.week,
                            formatDayDate(addWeeks(readDayDate(values.week), 1)),
                          ],
                          value: (week) => week,
                          template: (week) =>
                            `${theme.dateFormat.shortMonth(week)} - ${theme.dateFormat.shortMonth(
                              endOfWeek(new Date(week)),
                            )}`,
                        }}
                        onChange={submitForm}
                      />
                    ) : null}
                  </div>

                  <div className={styles.nextLineMd}>
                    <AppSelectField
                      name="clientId"
                      options={{
                        array: [tt('tasks')('filter')('allClients'), ...clients],
                        key: (value) => (typeof value === 'string' ? 'all' : value.id),
                        value: (value) => (typeof value === 'string' ? 'all' : value.id),
                        template: (value) =>
                          typeof value === 'string' ? tt('tasks')('filter')('allClients') : value.companyName,
                      }}
                      onChange={submitForm}
                    />
                  </div>
                  <div className={styles.nextLineMd}>
                    <AppSelectField
                      name="urgent"
                      options={{
                        array: ['true', 'false'],
                        key: (value) => value,
                        value: (value) => value,
                        template: (value) =>
                          value === 'true'
                            ? tt('tasks')('filter')('priorityUrgents')
                            : tt('tasks')('filter')('priorityAll'),
                      }}
                      onChange={submitForm}
                    />
                  </div>
                </div>
              </AppForm>
            );
          }}
        </Formik>
      </div>
      <div className={styles.worksContainer}>
        <ContentStateWrapper
          loading={tasksLoading}
          hasData={tasks && tasks.length > 0}
          noData={{ icon: noTaskIcon, title: 'No Task Instructions', subtitle: 'at this time' }}
        >
          {tasks?.map((task) => (
            <div className={styles.taskCardContainer} key={task.id}>
              <Link to={`${task.id}`}>
                <TaskCard task={task} />
              </Link>
            </div>
          ))}
        </ContentStateWrapper>
      </div>
    </div>
  );
};

export default TasksPage;
