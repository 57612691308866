import { useMutation } from '@apollo/client';
import { DOWNLOAD_REQUEST } from 'components/FileInput/graphql';
import { downloadRequest, downloadRequestVariables } from 'models/graphql';
import { useCallback } from 'react';

export default function useFileDownload() {
  const [getSignedDownloadUrl] = useMutation<downloadRequest, downloadRequestVariables>(DOWNLOAD_REQUEST);

  return useCallback(
    async (fileId: string) => {
      const res = await getSignedDownloadUrl({ variables: { fileId } });
      const url = res.data?.downloadRequest.url;
      if (url) {
        window.open(url, '_blank');
      }
    },
    [getSignedDownloadUrl],
  );
}
