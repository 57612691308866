import AppTable, { AppTableFilters } from 'components/AppTable';
import React from 'react';
import { getContractors } from 'models/graphql';
import Rating from 'components/Rating';
import useText from 'texts/useText.hook';

export default function ContractorsList<T extends getContractors['contractors'][number]>({
  onFilterSubmit,
  addNewButton,
  hideShowArchived,
  contractors,
  contractorsLoading,
  rowLink,
  clients,
  status,
  actions,
}: {
  onFilterSubmit: (values: { search: string; showArchived: boolean }) => Promise<void>;
  addNewButton?: React.ReactNode;
  hideShowArchived?: boolean;
  contractors?: T[];
  contractorsLoading: boolean;
  rowLink: (row: T) => string;
  clients?: (row: T) => React.ReactNode;
  status: (row: T) => React.ReactNode;
  actions: (row: T, index: number) => React.ReactNode;
}) {
  const { tt } = useText('contractors');

  return (
    <>
      <AppTableFilters
        searchPlaceholder="Search by company name, contact name, etc."
        onSubmit={onFilterSubmit}
        addNewButton={addNewButton}
        hideShowArchived={hideShowArchived}
      />
      <AppTable
        data={contractors}
        loading={contractorsLoading}
        noDataMessage="No Contractors"
        rowLink={rowLink}
        columns={[
          {
            key: 'name',
            name: 'Company Name',
            template: (row) => row.companyName,
          },
          {
            key: 'contactName',
            name: 'Contact Name',
            template: (row) => row.contactName,
          },
          {
            key: 'contactEmail',
            name: 'Contact Email',
            template: (row) => row.contactEmail,
          },
          {
            key: 'expertise',
            name: 'Expertise',
            template: (row) => row.expertise.map((expertise) => tt('contractors')('expertises')(expertise)).join(', '),
          },
          ...(clients
            ? [
                {
                  key: 'clients',
                  name: 'Clients',
                  template: clients,
                },
              ]
            : []),
          {
            key: 'rating',
            name: 'Rating',
            align: 'center',
            template: function ContractorRating(row) {
              return <Rating rating={row.rating} />;
            },
          },
          {
            key: 'status',
            name: 'Status',
            align: 'center',
            template: status,
          },
          {
            key: 'actions',
            link: null,
            template: actions,
          },
        ]}
      />
    </>
  );
}
