import { CellValue, Workbook } from 'exceljs';

const parseFieldNamesFromExcel = (book: Workbook) => {
  const fieldNamesFromImportFile: Array<string> = [];

  book.worksheets[0].eachRow(async (row) => {
    const cellValues: Array<CellValue> = [];

    row.eachCell((cell) => {
      cellValues.push(cell.value);
    });

    cellValues.forEach((value) => {
      if (typeof value !== 'string') {
        return;
      }
      const regexp = /\{\{(.*?)\}\}/g;
      const matches = [...value.matchAll(regexp)];
      matches.forEach((match) => {
        const fieldName = match[1];
        fieldNamesFromImportFile.push(fieldName);
      });
    });
  });

  return fieldNamesFromImportFile;
};

export default parseFieldNamesFromExcel;
