import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import ActionsButton, { Actions } from 'components/ActionsButton';
import AppButtonLink from 'components/AppButtonLink';
import TaskComplianceIcon2022 from 'components/complianceIcons/TaskComplianceIcon2022';
import formatDayDate from 'shared/formatDayDate';
import taskEditableFields from 'shared/taskEditableFields';
import taskStatusAvailableTransitions from 'shared/taskStatusAvailableTransitions';
import { useStores } from 'stores';
import useText from 'texts/useText.hook';
import usePaginationParams from 'utils/usePaginationParams';
import useSearchParams from 'utils/useSearchParams';
import useUpdateSearchParams from 'utils/useUpdateSearchParams';
import {
  taskCancel,
  taskCancelVariables,
  tasksForClientVariables,
  TaskStatus,
  tasksForClient_site_tasks_nodes as Task,
  Role,
  taskClose,
  taskCloseVariables,
  TaskType,
  taskReassign,
  taskReassignVariables,
  AssetType,
  deleteTaskVariables,
  deleteTask,
  getTaskFilter,
  getTaskFilterVariables,
} from 'models/graphql';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import { CANCEL_TASK, CLOSE_TASK, DELETE_TASK, GET_TASK_FILTER, TASK_REASSIGN } from './graphql';
import moment from "moment";

import MUIDataTable from 'mui-datatables';   // FW Display Asset Data using MUI Table
import StatusBadge, { StatusBadgeState } from 'components//StatusBadge';      // Keep separate for moment
import TasksExpandedRows from "./TaskExpandDetails";
import { Calendar } from 'primereact/calendar';
import calendarIcon from 'assets/calendarIcon.svg';
import { addDays } from 'date-fns';
import ReportGenerator from '../Assets/ReportGenerator';
import assetsIconGrey from 'assets/assetsIconGrey.svg';
import tasksIconGrey from 'assets/tasksIconGrey.svg';
import Popup from 'reactjs-popup';
import { alpha, IconButton, Table, Theme, Tooltip, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router';

const styleRules = (theme: Theme) => {
  const { height } = theme.sizes.button;
  const { borderRadius } = theme.shape;
  const grey = theme.palette.grey['300'];

  return createStyles({

    myPopupContent: {
      width: '800px',
      position: 'relative',
    },
    flex: {
      display: 'flex',
    },
    leftAlign: {
      float: 'right',
      alignItems: 'center',
    },
    viewSwitcher: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3), // FW 2022-02-17
    },
    createTaskLink: {
      display: 'flex',
      padding: '10px',
    },
    actionButtons: {
      // FW 2022-02-14
      display: 'inline-block',
      marginRight: theme.spacing(2),
    }
  });
};

const useStyles = makeStyles(styleRules);

interface FormValues {
  selectAll: boolean;
  assetSelections: { id: string; selected: boolean }[];
}

const TasksPage: React.FC = () => {
  const { authStore } = useStores();
  const { t, tt } = useText(
    'common',
    'compliance',
    'tasks',
    'taskTypes',
    'taskStatuses',
    'assetTypes',
    'systemTypes',
    'urls',
  );






  const { clientId, siteId } = useParams<{ clientId: string; siteId: string }>();
  let [
    search,
    taskParam,
    typeParam,
    statusParam,
    responsibleParam,
    approvalParam,
    deadlineStartParam,
    deadlineEndParam,
    assetParam,
    systemParam,
    compliantParam,
  ] = useSearchParams(
    tt('urls')('queries')('search'),
    tt('urls')('queries')('task'),
    tt('urls')('queries')('type'),
    tt('urls')('queries')('status'),
    tt('urls')('queries')('responsible'),
    tt('urls')('queries')('approval'),
    tt('urls')('queries')('deadlineStart'),
    tt('urls')('queries')('deadlineEnd'),
    tt('urls')('queries')('asset'),
    tt('urls')('queries')('system'),
    tt('urls')('queries')('compliant'),
  );

  const updateSearchParams = useUpdateSearchParams();
  const navigate = useNavigate();
  
  const initialFilter = '';

  const [tasksCall, setTasksCall] = useState([])
  const [isTaskRepClicked, setIsTaskRepClicked] = useState(false);
  const [taskviewClick, setTaskviewClick] = React.useState(initialFilter);

  const [selectedFilter, setSelectedFilter] = useState("All");

  // set initial dates for beginning of week
  const day: number = new Date().getDay();
  const startWeek = addDays(new Date(), -day + 1);
  const endWeek = addDays(new Date(), 7 - day);

  const { contextStore } = useStores();

  let letpassedparamstart = null;
  let letpassedparamend = null;

  let passedtypefilter: never[] = [];
  let respforworksFilterValue: never[] = [];
  let statusworkflowFilterValue: never[] = [];
  let sysrefFilterValue: never[] = [];
  let assetFilterValue: never[] = [];
  let compliantvalFilterValue: never[] = [];
  let tasktitleFilterValue: never[] = [];
  let reoccurFilterValue: never[] = [];

  const curSelectedModules = contextStore.selectedModules;
  
  const curSelectedModulesStorage = window.localStorage.getItem('selectedModules');
  let preselectedmodules: string | any[] = []
  if (curSelectedModulesStorage) {
    preselectedmodules = JSON.parse(curSelectedModulesStorage);

  }

  if (deadlineStartParam) {
    letpassedparamstart = new Date(deadlineStartParam.toString());
    // contextStore.setTaskStartDate(new Date(deadlineStartParam.toString()));
  }

  if (deadlineEndParam) {
    letpassedparamend = new Date(deadlineEndParam.toString());
    //contextStore.setTaskEndDate(new Date(deadlineEndParam.toString()));
  }





  const nameval = "start";
  const placeholder = tt('tasks')('filter')('start');
  const minValuestart = new Date(startWeek);

  const namevalend = "end";
  const placeholderend = tt('tasks')('filter')('end');
  const minValueend = new Date(endWeek);

  // check if the returning from report view
  const isReportViewedValue = contextStore.reportViewedValue;

  if (isReportViewedValue === false) {
    /*if (deadlineStartParam)
    {
     contextStore.setTaskStartDate(new Date(deadlineStartParam.toString()));
    }
    if (deadlineEndParam)
    {
      contextStore.setTaskEndDate(new Date(deadlineEndParam.toString()));
    }
     */
    // View the values shown in Start and End
    // console.log("contextStore.startdateFilterValue:",  contextStore.startdateFilterValue);
    // console.log("contextStore.enddateFilterValue:",  contextStore.enddateFilterValue);

    if (!contextStore.enddateFilterValue) {
      // console.log("Will set the End Date in context store")
      if (deadlineEndParam) {
        contextStore.setTaskEndDate(new Date(deadlineEndParam.toString()));
      }

    }
    if (!contextStore.startdateFilterValue) {
      // console.log("Will set the End Date in context store")
      if (deadlineStartParam) {
        contextStore.setTaskStartDate(new Date(deadlineStartParam.toString()));
      }

    }


  }
  if (isReportViewedValue === true) {

    // console.log("contextStore.startdateFilterValue:",  contextStore.startdateFilterValue);
    // console.log("contextStore.enddateFilterValue:",  contextStore.enddateFilterValue);

    letpassedparamstart = contextStore.startdateFilterValue;
    letpassedparamend = contextStore.enddateFilterValue;


    // console.log("Set Filters and then set to false")
    if (contextStore.tasktypeFilterValue) {
      passedtypefilter = contextStore.tasktypeFilterValue;
      letpassedparamstart = contextStore.startdateFilterValue;
      letpassedparamend = contextStore.enddateFilterValue;

    }

    // console.log("passedtypefilter", passedtypefilter);
    // console.log("Passed start and end data:", contextStore.startdateFilterValue);
    // console.log("Passed end data:", contextStore.enddateFilterValue);



    //contextStore.setReportViewedValue(false);
    // console.log("respforworksFilterValue:",  contextStore.respforworksFilterValue)
    // console.log("statusworkflowFilterValue:",  contextStore.statusworkflowFilterValue)
    // console.log("sysrefFilterValue:",  contextStore.sysrefFilterValue)
    // console.log("compliantvalFilterValue:",  contextStore.compliantvalFilterValue)
    // console.log("tasktitlevalFilterValue:",  contextStore.tasktitleFilterValue)
    // console.log("reoccurFilterValue:",  contextStore.reoccurFilterValue)

    if (contextStore.respforworksFilterValue) {
      respforworksFilterValue = contextStore.respforworksFilterValue;
      letpassedparamstart = contextStore.startdateFilterValue;
      letpassedparamend = contextStore.enddateFilterValue;

    }
    if (contextStore.statusworkflowFilterValue) {
      statusworkflowFilterValue = contextStore.statusworkflowFilterValue;
      letpassedparamstart = contextStore.startdateFilterValue;
      letpassedparamend = contextStore.enddateFilterValue;

    }
    if (contextStore.sysrefFilterValue) {
      sysrefFilterValue = contextStore.sysrefFilterValue;
      letpassedparamstart = contextStore.startdateFilterValue;
      letpassedparamend = contextStore.enddateFilterValue;

    }
    if (contextStore.compliantvalFilterValue) {
      compliantvalFilterValue = contextStore.compliantvalFilterValue;
      letpassedparamstart = contextStore.startdateFilterValue;
      letpassedparamend = contextStore.enddateFilterValue;

    }
    if (contextStore.tasktitleFilterValue) {
      tasktitleFilterValue = contextStore.tasktitleFilterValue;
      letpassedparamstart = contextStore.startdateFilterValue;
      letpassedparamend = contextStore.enddateFilterValue;

    }

    if (contextStore.reoccurFilterValue) {
      reoccurFilterValue = contextStore.reoccurFilterValue;
      letpassedparamstart = contextStore.startdateFilterValue;
      letpassedparamend = contextStore.enddateFilterValue;

    }





  }


  const [startDate, setStartDate] = useState<Date | null>(letpassedparamstart)
  const [endDate, setEndDate] = useState<Date | null>(letpassedparamend)
  const [pickerOpen, setPickerOpen] = useState<boolean>(false)

  // console.log("startDate is equal to:", startDate)
  // console.log("endDate is equal to:", endDate)

  const [initialCall, setInitialCall] = useState(true)

  const [searchVal, setSearchVal] = useState<String | undefined>(search)

  const [rowClicked, setRowClicked] = useState(null)

  // Use variable for moment 2023-01-22
  //const [typeFilters, setTypeFilters] = useState(contextStore.tasktypeFilterValue)

  const styles = useStyles();

  const tasksVariables: tasksForClientVariables = {
    siteId,
    pagination: usePaginationParams(),
    search,
    type: TaskType[typeParam as TaskType],
    status: TaskStatus[statusParam as TaskStatus],
    responsible: responsibleParam && responsibleParam === 'all' ? null : responsibleParam,
    approval: approvalParam && approvalParam === 'all' ? null : approvalParam,
    deadlineStart: deadlineStartParam ? formatDayDate(new Date(deadlineStartParam)) : null,
    deadlineEnd: deadlineEndParam ? formatDayDate(new Date(deadlineEndParam)) : null,
    assets: AssetType[assetParam as AssetType],
    systemRef: systemParam && systemParam === 'all' ? null : systemParam,
  };
  // eslint-disable-next-line no-nested-ternary
  const compliantOption: boolean | null = compliantParam === 'true' ? true : compliantParam === 'false' ? false : null;
  if (compliantOption !== null) tasksVariables.compliant = compliantOption;

  // Old Call, no longer used FW 2024-09-20
  /*const { data: tasksData, loading: tasksDataLoading, refetch } = useQuery<tasksForClient, tasksForClientVariables>(
    GET_TASKS,
    {
      variables: tasksVariables,
    },
  );
  const tasks = tasksData?.site.tasks; */
  const { data: filter } = useQuery<getTaskFilter, getTaskFilterVariables>(GET_TASK_FILTER, {
    variables: {
      id: siteId,
    },
  });
  function changeRowColorBack() {
    let row = document.getElementById(`MUIDataTableBodyRow-${rowClicked}`);
    // row.setAttribute('style', 'background: white');
  }










  function changeRowColor(index: any) {
    if (rowClicked !== null) {

      let row = document.getElementById(`MUIDataTableBodyRow-${rowClicked}`);
      //    row.setAttribute('style', 'background: white');
    }


    let row = document.getElementById(`MUIDataTableBodyRow-${index}`);
    //   row.setAttribute('style', 'background: #F3F4F9');
    setRowClicked(index);
  }

  useEffect(() => {
    getData();

  }, [siteId]);


  // modules changes
  useEffect(() => {
    // call the various 
    console.log("Selected Modules are now:", preselectedmodules)

    getData();



  }, [curSelectedModules])




  const setStoreVals = async () => {
    // capture all of the filters and dates and write to stre


    contextStore.setTaskFilters(startDate, endDate, filterList[2], filterList[6], filterList[7], filterList[8], filterList[10], filterList[1], filterList[3]);


  }



  const getData = async () => {
    const urltogoto = 'listings/tasks/'
    let starttopass;
    let endtopass;

    let fullurl = urltogoto + siteId + '?site=y';

    if (searchVal) {

      fullurl = fullurl + '&search=' + search;

    }
    else {


    }





    try {

      if (startDate) {
        starttopass = moment(startDate).format('YYYY-MM-DD');

      }


      if (endDate) {
        endtopass = moment(endDate).format('YYYY-MM-DD');
      }

      // add modules
      let curmodule;
      if (preselectedmodules.length === 2) {
        curmodule = 'all'
      }
      else if (preselectedmodules.length === 1) {
        curmodule = preselectedmodules[0];
      }
      else {
        curmodule = 'all'
      }
      console.log("curmodule:", curmodule)
      fullurl = fullurl + `&deadlineStart=${starttopass}&deadlineEnd=${endtopass}&module=${curmodule}`

      await fetch(`${process.env.API_ROOT}${fullurl}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        const taskData = dataret.map((u: { id: string; idreadable: string; tasktitle: string; tasktype: string; internalresponsibleid: any; deadline: any; siteid: any; documentdeadline: any; status: any; type: any; internalresponsible: string; displaydocumentdeadline: any; displaydeadline: any; compliant: any; systemref: any; reoccurencetype: any; assetlist: string; approvalid: any;}) => {

          return {
            "id": u.id,
            "idreadable": u.idreadable,
            "tasktitle": u.tasktitle,
            "tasktype": u.tasktype,
            "internalresponsibleid": u.internalresponsibleid,
            "deadline": u.deadline,
            "siteid": u.siteid,
            "documentdeadline": u.documentdeadline,
            "status": u.status,
            "type": u.type,
            "internalresponsible": u.internalresponsible,
            "displaydeadline": u.displaydeadline,
            "displaydocumentdeadline": u.displaydocumentdeadline,
            "compliant": u.compliant,
            "systemref": u.systemref,
            "reoccurencetype": u.reoccurencetype,
            "assetlist": u.assetlist,
            "approvalid": u.approvalid,

          };
        });
        setInitialCall(false)
        setTasksCall(taskData);

        // added 2023-01-23
        // console.log("tasktypeFilterValue:", contextStore.tasktypeFilterValue);
        // // console.log("typeFilters value", typeFilters)
      });
    } catch (error) {
      console.error(error); /* eslint-disable-line */
    }
  }

  useEffect(() => {

    // firstly set the start and end dates 
    //    setStartDate()
    getData();

  }, []);

  useEffect(() => {
    if (!searchVal) {
      getData();
    }

  }, [startDate, endDate]);



  const responsibles =
    filter?.taskFilter.contractors
      .map((c) => {
        return { id: c.id, name: c.contactName };
      })
      .concat(filter?.taskFilter.responsibles || []) || [];

  const DEFAULT_DATE = '1900-01-01';

  // Mutations
  const [cancelTask] = useMutation<taskCancel, taskCancelVariables>(CANCEL_TASK, {
    update: (cache, { data }) => {
      if (!data?.taskCancel.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.taskCancel.id },
      });
    },
  });

  const [taskDelete] = useMutation<deleteTask, deleteTaskVariables>(DELETE_TASK, {
    update: (cache, { data }) => {
      if (!data?.deleteTask.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.deleteTask.id },
      });
    },
  });

  const [close] = useMutation<taskClose, taskCloseVariables>(CLOSE_TASK, {
    update: (cache, { data }) => {
      if (!data?.taskClose.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.taskClose.id },
      });
    },
  });

  const [reassign] = useMutation<taskReassign, taskReassignVariables>(TASK_REASSIGN, {
    update: (cache, { data }) => {
      if (!data?.taskReassign.id) return;
      apolloCacheEvictQuery({
        cache,
        query: 'logs',
        args: { dataId: data.taskReassign.id },
      });
    },
  });

  const theme = useTheme();
  const detailsPanelActions = (task: any) => {
    const actions: Actions = [];


    const userApprovalOrPm =
      (task.approvalid === authStore.user?.id) || authStore.user?.role === Role.PROJECT_MANAGER;


    if (taskEditableFields[task.status.toUpperCase()].length && (task.status.toUpperCase() === TaskStatus.UNASSIGNED || userApprovalOrPm)) {
    actions.push({
      title: t('common')('edit'),
      action: () => navigate(`${task.id}/edit`),
    });} 


    //   if (task.status.toUpperCase() === TaskStatus.REJECTED_CLOSED) {
    actions.push({
      title: t('tasks')('createCorrectiveTask'),
      action: () => navigate(`${task.id}/corrective`),
    });

    actions.push({
      title: t('tasks')('createDuplicateTask'),
      action: () => navigate(`${task.id}/duplicate`),
    });


    // }
    if (taskStatusAvailableTransitions[task.status.toUpperCase()].includes(TaskStatus.CANCELLED)) {
      actions.push({
        title: t('common')('cancel'),
        action: async (setLoading) => {
          //setLoading(true);

          await cancelTask({ variables: { taskId: task.id } });

          getData();
          setLoading(false);
        },
      });
    }
    if (task.status.toUpperCase() === TaskStatus.REJECTED) {
      actions.push({
        title: t('common')('close'),
        action: async (setLoading) => {
          //   contextStore.setReportViewedValue(true);
          setLoading(true);
          await close({ variables: { taskId: task.id } });
          getData();
          setLoading(false);
        },
      });
    }
    if ([TaskStatus.SENT, TaskStatus.CONTRACTOR_REJECTED].includes(task.status.toUpperCase())) {
      actions.push({
        title: t('tasks')('reAssign'),
        action: async (setLoading) => {
          //contextStore.setReportViewedValue(true);
          setLoading(true);
          await reassign({
            variables: {
              taskId: task.id,
            },
          });
          setLoading(false);
          navigate(`${task.id}/edit`);
        },
      });
    }
    if (authStore.user?.isAdmin && [TaskStatus.SENT, TaskStatus.CANCELLED].includes(task.status.toUpperCase())) {
      actions.push({
        title: t('common')('delete'),
        action: async (setLoading) => {
          //   contextStore.setReportViewedValue(true);
          setLoading(true);
          await taskDelete({ variables: { taskId: task.id } });

          //await refetch();
          getData();
          setLoading(false);
        },
      });
    }
    return actions;
  };

  function replaceWithBr(assetlist: string) {
    // console.log(assetlist);
    return assetlist.replace(/\n/g, "<br />");
  }

  const ExtraButtons = () => (
    <>
      <ReportGenerator></ReportGenerator>
      {<AppButtonLink to={`add`}>{t('tasks')('createTask')}</AppButtonLink>}
      <div>
        <Calendar
          dateFormat="dd/mm/yy"
          value={startDate}
          onChange={(e) => {
            setSearchVal(undefined);
            const newValue = e.value;
            let newDateStart = moment(newValue).format('YYYY-MM-DD');
            let newDate = new Date(newDateStart.toString());
            contextStore.setTaskStartDate(newDate);
            setStartDate(newDate);
          }}
          showIcon={true}
          icon={<img alt="calendar" src={calendarIcon} width="18px" />}
          showButtonBar
          onClearButtonClick={() => {
            contextStore.setTaskStartDate(null);
            setStartDate(null);
          }}
        />
        <Calendar
          inputStyle={{
            fontSize: theme.fontSizes['14'],
            fontFamily: theme.typography.fontFamily,
          }}
          panelStyle={{
            fontFamily: theme.typography.fontFamily,
          }}
          showButtonBar
          dateFormat="dd/mm/yy"
          value={endDate}
          onChange={(e) => {
            setSearchVal(undefined);
            const newValue = e.value;
            let newDateEnd = moment(newValue).format('YYYY-MM-DD');
            let newDate = new Date(newDateEnd.toString());
            contextStore.setTaskEndDate(newDate);
            setEndDate(newDate)
          }}
          onClearButtonClick={() => {
            contextStore.setTaskStartDate(null);
            setEndDate(null);
          }}
          showIcon={true}
          icon={<img alt="calendar" src={calendarIcon} width="18px" />}
        />
      </div>
    </>
  );

  const createHyperlink = (passedid: any) => {
    let fullurl =

      setIsTaskRepClicked(true);

    setTaskviewClick(passedid)
  }


  React.useEffect(() => {

    if (!isTaskRepClicked) return;

    // create the URL link 
    let newurl = `/client/${clientId}/tasks/${siteId}/${taskviewClick}/report`

    // console.log("newurl:", newurl)
    // set values to display the same data on return
    navigate(newurl)

  }, [taskviewClick]
  );

  const columnsMUITable = [
    {
      name: "idreadable",
      label: "Task ID",
      options: {
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
      }
    },
    {
      name: "tasktitle",
      label: "Task Title",
      options: {
        filterList: tasktitleFilterValue,
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
      }
    },
    {
      name: "type",
      label: "Task Type",
      options: {
        filterList: passedtypefilter,
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
      }
    },
    {
      name: "reoccurencetype",
      label: "Reoccurence",
      options: {
        filterList: reoccurFilterValue,
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
      }
    },
    {
      name: "deadline",
      label: "Task D/L",
      options: {
        filterList: [],
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
        sort: true,
        customBodyRender: (value: string | number | Date) =>
          moment(new Date(value)).format('DD/MM/YYYY')
      }
    },
    {
      name: "documentdeadline",
      label: "Document D/L",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
        sort: true,
        customBodyRender: (value: string | number | Date) =>
          moment(new Date(value)).format('DD/MM/YYYY')
      }
    },
    {
      name: "internalresponsible",
      label: "Resp. for Works",
      options: {
        filterList: respforworksFilterValue,
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
      }
    },
    {
      name: "systemref",
      label: "System References",
      options: {
        filterList: sysrefFilterValue,
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
      }
    },
    {
      name: "assetlist",
      label: "Assets",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px' } }),
        customBodyRender: (assetlist: string) => {
          if (assetlist) {
            let updatedassets = assetlist.replace(/\n+/g, ',')
            let allassets = updatedassets.split(','); // split string by comma

            return (
              <Table>
                <tbody>
                  {allassets.map((asset, index) => {
                    let assetlink = `/client/${clientId}/assets/${siteId}?search=${asset}`
                    return (
                      <tr key={index} >
                        <td><a href={assetlink}>{asset}</a></td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            );
          } else {
            return (<Table><tbody></tbody></Table>);
          }
        }
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filterList: statusworkflowFilterValue,
        setCellProps: () => ({ style: { minWidth: "60%", maxWidth: "100%" } }),
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px', alignItems: 'center', textAlign: 'center' } }),
        customBodyRender: (statusvalue: string) => {
          let state: StatusBadgeState;

          if (statusvalue.toUpperCase() === TaskStatus.SENT || statusvalue.toUpperCase() === TaskStatus.REVIEW_REQ_SENT) {
            state = { background: alpha(theme.colors.yellow, 0.25) };
          } else if (
            statusvalue.toUpperCase() === TaskStatus.CONTRACTOR_ACCEPTED ||
            statusvalue.toUpperCase() === TaskStatus.CONTRACTOR_REJECTED ||
            statusvalue.toUpperCase() === TaskStatus.IN_PROGRESS ||
            statusvalue.toUpperCase() === TaskStatus.COMPLETED ||
            statusvalue.toUpperCase() === TaskStatus.SUPP_INFO_SUBMITTED
          ) {
            state = { background: alpha(theme.colors.orange, 0.25) };
          } else if (
            statusvalue.toUpperCase() === TaskStatus.REVIEW_REQ_REJECTED ||
            statusvalue.toUpperCase() === TaskStatus.REVIEW_REQ_ACCEPTED ||
            statusvalue.toUpperCase() === TaskStatus.CONSULTANT_REJECTED ||
            statusvalue.toUpperCase() === TaskStatus.CONSULTANT_APPROVED
          ) {
            state = { background: alpha(theme.colors.blue, 0.25) };
          } else if (statusvalue.toUpperCase() === TaskStatus.REJECTED_CLOSED || statusvalue.toUpperCase() === TaskStatus.REJECTED) {
            state = { background: alpha(theme.palette.error.main, 0.25) };
          } else if (statusvalue.toUpperCase() === TaskStatus.APPROVED_CLOSED) {
            state = { background: alpha(theme.palette.primary.main, 0.25) };
          } else if (statusvalue.toUpperCase() === TaskStatus.CANCELLED) {
            state = { background: alpha('#000000', 0.12) };
          } else {
            state = { background: 'transparent' };
          }

          return (
            (<StatusBadge block state={state}>
              {statusvalue.replace(/_+/g, ' ')}
            </StatusBadge>)
          );


        }
      }
    },
    { name: "compliant", label: "Is Compliant?", options: { display: false, viewColumns: false, } },
    {
      name: "compliant",
      label: " ",
      options: {
        filterList: compliantvalFilterValue,
        viewColumns: false,
        filter: false, setCellProps: () => ({
          style: {
            whiteSpace: "normal",
            position: "sticky",
            left: 0,
            zIndex: 118
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            fontWeight: 'bold', fontSize: '14px'
          }
        }),
        customBodyRender: (value: string, tableMeta: any) => {
          let taskdeets = {
            deadline: tasksCall[tableMeta.rowIndex].deadline,
            compliantvalue: value
          }
          return <TaskComplianceIcon2022 taskdeets={taskdeets} />;
        }
      }
    },
    {
      name: "AssetsList",
      label: "Assets",
      options: {
        filter: false,
        viewColumns: false,
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px', alignItems: 'center', textAlign: 'center' } }),
        customBodyRender: (value: string, tableMeta: any) => {
          if (tasksCall[tableMeta.rowIndex].assetlist) {

            let updatedassets = tasksCall[tableMeta.rowIndex].assetlist.replace(/\n+/g, ',')
            let allassets = updatedassets.split(','); // split string by comma

            let lgth = 0;
            let longest;

            for (let i = 0; i < allassets.length; i++) {
              if (allassets[i].length > lgth) {
                let lgth = allassets[i].length;
                longest = allassets[i];
              }
            }

            let displwidth = longest.length + 10 * 10;

            return (
              <Popup contentStyle={{ 
                width: `${displwidth}px`, 
                zIndex: 9999, 
                background: 'white',
                fontSize: '12px',
                padding: '10px',
                borderRadius: 10,
                boxShadow: '2px 2px 5px grey'
              }}
                trigger={open => (
                  <IconButton size="large"><img src={assetsIconGrey} alt="Assets in Task" /></IconButton>
                )}
                on={['hover', 'focus']}
                position="left center"
                arrow={false}
                closeOnDocumentClick
              >
                <Table>
                  <tbody>
                  {allassets.map((asset, index) => {
                    let assetlink = `/client/${clientId}/assets/${siteId}?search=${asset}`
                    return (
                      <tr key={index}>
                        <td>
                          <a href={assetlink} style={{outline: 'none', border: 'none'}}>{asset}</a>
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </Table>
              </Popup>
            );
          }
        },
      },
    },
    {
      name: "Task Results",
      label: "Results",
      options: {
        filter: false,
        viewColumns: false,
        setCellHeaderProps: () => ({ style: { fontWeight: 'bold', fontSize: '14px', alignItems: 'center', textAlign: 'center' } }),
        customBodyRender: (dvalue: string, tableMeta: any) => {
          return (
            (<div onClick={() => createHyperlink(tasksCall[tableMeta.rowIndex].id)} >
              <Tooltip title='View Task Results'>
                <IconButton size="large"><img src={tasksIconGrey} alt="Task Results" /></IconButton>
              </Tooltip>
            </div>)
          );
        },
      },
    },
    {
      name: "Action",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex: string | number, rowIndex: any) => {
          detailsPanelActions(tasksCall[dataIndex])
          return (
            <ActionsButton actions={detailsPanelActions(tasksCall[dataIndex])} />
          );
        },
      },
    },
  ]

  const tableoptions = {
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100, 500],
    filter: true,
    filterType: 'multiselect',
    print: false,
    selectableRows: "none",
    customToolbar: ExtraButtons,
    fixedHeader: true,
    tableBodyHeight: "700px",
    expandableRows: true,
    onFilterChange: (columnChanged, filterList) => {
      // console.log(`onFilterChange columnChanged: ${columnChanged}`);
      // console.log("filterList", filterList)
      // console.log(`filterList[2]: ${JSON.stringify(filterList[2])}`);
      // console.log(`filterList[3]: ${JSON.stringify(filterList[3])}`);

      passedtypefilter = filterList[2];
      respforworksFilterValue = filterList[6];
      statusworkflowFilterValue = filterList[9];
      sysrefFilterValue = filterList[7];
      compliantvalFilterValue = filterList[11];
      tasktitleFilterValue = filterList[1];
      reoccurFilterValue = filterList[3];



      // if (columnChanged === 'type')
      // {
      // startDate  endDate
      // setTypeFilters(filterList[2]);
      //contextStore.setTaskFilters(startDate, endDate, filterList[2]);
      contextStore.setTaskFilters(startDate, endDate, filterList[2], filterList[6], filterList[7], filterList[9], filterList[10], filterList[1], filterList[3]);

      // }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      // call sepearate routine to export data
      const curtime = new Date();
      // create filename for file
      const yyyy = curtime.getFullYear();
      const mm = curtime.getMonth() < 9 ? "0" + (curtime.getMonth() + 1) : (curtime.getMonth() + 1); // getMonth() is zero-based
      const dd = curtime.getDate() < 10 ? "0" + curtime.getDate() : curtime.getDate();
      const hh = curtime.getHours() < 10 ? "0" + curtime.getHours() : curtime.getHours();
      const mmm = curtime.getMinutes() < 10 ? "0" + curtime.getMinutes() : curtime.getMinutes();
      const ss = curtime.getSeconds() < 10 ? "0" + curtime.getSeconds() : curtime.getSeconds();

      const ncfile = "tasks_" + yyyy + mm + dd + "_" + hh + mmm + ss + ".xlsx";
      const urltogoto = "listings/taskexport?site=" + siteId;
      let fullurl = urltogoto;
      let starttopass;
      let endtopass;

      if (startDate) {
        starttopass = moment(startDate).format('YYYY-MM-DD');
      }


      if (endDate) {
        endtopass = moment(endDate).format('YYYY-MM-DD');
      }

      fullurl = fullurl + `&deadlineStart=${starttopass}&deadlineEnd=${endtopass}`

      // console.log("Current Type Filters:", passedtypefilter )

      // need to also apply filters as well. Check filterlist
      if (passedtypefilter.length !== 0) {
        // console.log("Filtered Task Types:", passedtypefilter);
        fullurl = fullurl + `&tasktypes=${passedtypefilter}`
        // console.log("fullurl:", fullurl)
      }
      if (respforworksFilterValue.length !== 0) {
        // console.log("respforworksFilterValue:", respforworksFilterValue);
        fullurl = fullurl + `&resp=${respforworksFilterValue}`
        // console.log("fullurl:", fullurl)
      }
      if (statusworkflowFilterValue.length !== 0) {
        // console.log("statusworkflowFilterValue:", statusworkflowFilterValue);
        fullurl = fullurl + `&status=${statusworkflowFilterValue}`
        // console.log("fullurl:", fullurl)
      }

      if (sysrefFilterValue.length !== 0) {
        // console.log("sysrefFilterValue:", sysrefFilterValue);
        fullurl = fullurl + `&sysref=${sysrefFilterValue}`
        // console.log("fullurl:", fullurl)
      }

      if (compliantvalFilterValue.length !== 0) {
        // console.log("compliantvalFilterValue:", compliantvalFilterValue);
        fullurl = fullurl + `&comp=${compliantvalFilterValue}`
        // console.log("fullurl:", fullurl)
      }

      if (tasktitleFilterValue.length !== 0) {
        // console.log("tasktitleFilterValue:", tasktitleFilterValue);
        fullurl = fullurl + `&title=${tasktitleFilterValue}`
        // console.log("fullurl:", fullurl)
      }

      if (reoccurFilterValue.length !== 0) {
        // console.log("reoccurFilterValue:", reoccurFilterValue);
        fullurl = fullurl + `&reocc=${reoccurFilterValue}`
        // console.log("fullurl:", fullurl)
      }




      // Fetch the task types for the selected asset
      fetch(`${process.env.API_ROOT}${fullurl}`, {
        method: 'GET',
        credentials: 'include',
      }).then(response => response.blob())
        .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = ncfile;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();  //afterwards we remove the element again 

        });
      return false;
    },
    onRowExpansionChange: (curExpanded, allExpanded) => {

    },
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      //changeRowColor(rowMeta.dataIndex);
      // store the current values, will need to capture the selections
      //const filteredCols = [...columnsMUITable];
      //// console.log ("filteredCols is:", filteredCols)

      return <TasksExpandedRows index={tasksCall[rowMeta.dataIndex]} />;
    },
    onRowClick: (rowData: any, rowIndex: any) => {
      changeRowColor(rowIndex.rowIndex);
    }
  };

  return (
    <>
      <MUIDataTable title={"Tasks"} data={tasksCall} columns={columnsMUITable} options={tableoptions} />
    </>
  );
};

export default observer(TasksPage);