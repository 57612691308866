import * as dateFns from 'date-fns';

const useGetHandlersForFields = () => {
  return [
    {
      type: 'DATE',
      handlerFunction: (value: string) => {
        return dateFns.format(new Date(value), 'dd/MM/yyyy');
      },
    },
  ];
};

export default useGetHandlersForFields;
