import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import styleUtils from 'style/styleUtils';
import removeIcon from 'assets/removeIcon.svg';

const useStyles = makeStyles((theme: Theme) => {
  const iconSize = 20;

  return {
    iconContainer: {
      ...styleUtils.rowCenterCenter,
      height: theme.sizes.button.height,
      paddingLeft: theme.spacing(1),
    },
    icon: {
      width: iconSize,
      height: iconSize,
      maxWidth: 'none',
      maxHeight: 'none',
    },
  };
});

const AppFieldArrayRemove: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const styles = useStyles();

  return (
    <button onClick={onClick} type="button" className={styles.iconContainer}>
      <img alt="error" src={removeIcon} className={styles.icon} />
    </button>
  );
};

export default AppFieldArrayRemove;
