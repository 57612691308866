import React from 'react';
import { useFormikContext } from 'formik';
import { Switch, SwitchProps, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const styleRules = (theme: Theme) =>
  createStyles({
    root: {
      width: theme.sizes.switch.width,
      height: theme.sizes.switch.height,
      padding: 0,
      margin: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(32px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: theme.sizes.switch.height - 2,
      height: theme.sizes.switch.height - 2,
      boxShadow: 'none',
    },
    track: {
      borderRadius: theme.sizes.switch.height / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey['400'],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  });
const useStyles = makeStyles(styleRules);

const AppSwitchField: React.FC<Omit<SwitchProps, 'name'> & { name: string }> = ({
  name,
  onChange: onChangeProp,
  ...propsToPass
}) => {
  const { values, setFieldValue } = useFormikContext<Record<string, unknown>>();
  const styles = useStyles();
  return (
    <Switch
      focusVisibleClassName={styles.focusVisible}
      disableRipple
      name={name}
      checked={!!values[name]}
      classes={{
        root: styles.root,
        switchBase: styles.switchBase,
        thumb: styles.thumb,
        track: styles.track,
        checked: styles.checked,
      }}
      onChange={(event, checked) => {
        setFieldValue(name, checked);
        if (onChangeProp) {
          onChangeProp(event, checked);
        }
      }}
      {...propsToPass}
    />
  );
};

export default AppSwitchField;
