import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useTaskDetailsPageStyles = makeStyles((theme: Theme) => {
  return createStyles({
    grid: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
    },
    cell: {
      '--border': `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(1, 2),
      borderBottom: 'var(--border)',
      display: 'flex',
      flexDirection: 'column',
    },
    fullWidth: {
      gridColumn: '1/3',
    },
    rightBorder: {
      borderRight: 'var(--border)',
    },
    label: {
      fontSize: theme.fontSizes[12],
      marginBottom: theme.spacing(1),
    },
    value: {
      fontSize: theme.fontSizes[14],
      color: theme.brandColors.grey,
      minHeight: 20, // Display empty line when there is no value
    },
  });
});

export default useTaskDetailsPageStyles;
