import React, { useCallback } from 'react';
import cn from 'classnames';
import AppFieldError from 'components/form/AppFieldError';
import { useFormikContext } from 'formik';
import objectPropertyByString from 'utils/objectPropertyByString';
import useFieldStyles from 'components/form/AppField.style';
import { FormControlLabel, TextField, TextFieldProps } from '@mui/material';

export type AppTextFieldProps = Omit<Omit<TextFieldProps, 'onChange'>, 'name'> & {
  name: string; // name is required
  onChange?: (value: string) => void;
  value?: string;
  noMinWidth?: boolean;
};

const AppTextField: React.FC<AppTextFieldProps> = ({
  label: _label,
  name,
  onChange,
  required,
  className,
  value: _value,
  noMinWidth,
  ...propsToPass
}) => {
  // values, touched and errors coming form FormikContext.
  // setFieldValue writes the changed value back to FormikContext.
  const formikContext = useFormikContext<Record<string, unknown>>();
  if (formikContext === undefined) {
    throw new Error('Cannot get Formik context. Have you use the AppTextField as child of a <Formik> component?');
  }

  const { values, setFieldValue, touched, errors } = formikContext;

  // If the field supports be embedded inside <FieldArray />
  // we have to read values by objectPropertyByString
  // (otherwise 'value = values[name]' is enough)
  let value = objectPropertyByString(values, name);
  if (value === null || value === undefined) value = '';

  const styles = useFieldStyles();

  const control = () => {
    return <TextField
      {...propsToPass}
      name={name}
      value={_value || value}
      required={required}
      variant="outlined"
      // only show error, if the field is touched
      error={objectPropertyByString(touched, name) && !!objectPropertyByString(errors, name)}
      onChange={(event) => {
        const newValue = event.target.value;
        setFieldValue(name, newValue);
        if (onChange) {
          onChange(newValue);
        }
      }}
      className={cn(className, styles.field, noMinWidth ? 'no-minwidth' : '')}
    />
  }

  return (
    <div>
      {
        _label ? (
          <FormControlLabel
            labelPlacement="top"
            className={cn(styles.label, { 'no-label': !_label })}
            label={_label}
            control={control()}
          />
        ) : (
          control()
        )
      }

      <AppFieldError
        message={
          // only show error, if the field is touched
          objectPropertyByString(touched, name) ? objectPropertyByString(errors, name) : undefined
        }
      />
    </div>
  );
};

export default AppTextField;
