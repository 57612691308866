import CLOUD_CONVERT_API_TOKEN from 'constants/cloudApiToken';

const token = `Bearer ${CLOUD_CONVERT_API_TOKEN}`;

const urls = {
  uploadFile: 'https://api.cloudconvert.com/v2/import/base64',
  createTaskForConvertation: 'https://api.cloudconvert.com/v2/convert',
  getUrlForFile: 'https://api.cloudconvert.com/v2/export/url',
};

const useGenerateAndDownloadPdfFromExcel = () => {
  return async (base64FileFormat: string, fileName: string) => {
    const uploadedFile = await fetch(urls.uploadFile, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        file: base64FileFormat,
        filename: `${fileName}.xlsx`,
      }),
    });
    const uploadedFileResponse = await uploadedFile.json();
    const fileId = uploadedFileResponse.data.id;

    const convertInfo = await fetch(urls.createTaskForConvertation, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        input: fileId,
        input_format: 'xlsx',
        output_format: 'pdf',
        optimize_print: true,
        engine: 'libreoffice',
      }),
    });
    const taskRespData = await convertInfo.json();
    const taskId = taskRespData.data.id;

    await fetch(`https://sync.api.cloudconvert.com/v2/tasks/${taskId}`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    });

    const fileUrlResponse = await fetch(urls.getUrlForFile, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        input: taskId,
      }),
    });
    const fileUrlInfo = await fileUrlResponse.json();

    const taskUrlGenerationWait = await fetch(`https://sync.api.cloudconvert.com/v2/tasks/${fileUrlInfo.data.id}`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    });

    try {
      const taskUrlGenerationWaitResponse = await taskUrlGenerationWait.json();

      if (!taskUrlGenerationWaitResponse) {
        throw Error('PDF generation error');
      }

      const { url } = taskUrlGenerationWaitResponse.data.result.files[0];
      window.open(url, '_blank');
    } catch (e) {
      // PDF generation error
    }
  };
};

export default useGenerateAndDownloadPdfFromExcel;
