import gql from 'graphql-tag';

export const CREATE_TASK_MYSELF = gql`
  mutation taskCreateMyself($task: TaskCreateInput!) {
    taskCreate(task: $task) {
      id
    }
  }
`;

export const GET_SITE_ASSETS_AND_FORM_TYPES = gql`
  query assetsAndFormTypesForSite($siteId: ID!) {
    site(id: $siteId) {
      id
      formTemplates {
        id
        formType
        assetType
        subForm
      }
      assets {
        id
        name
        type
        __typename
      }
      __typename
    }
  }
`;
