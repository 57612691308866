import React, { ReactNode } from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppButton from 'components/AppButton';
import useText from 'texts/useText.hook';
import useCancel from 'utils/useCancel.hook';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0px, 1fr) 450px auto',
    gridGap: theme.spacing(8),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
      gridGap: 0,
    },
  },
  main: {
    gridColumnStart: 2,
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('lg')]: {
      gridColumnStart: 1,
    },
  },
  buttons: {
    gridColumnStart: 2,
    gridRowStart: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(3),
      gridColumnStart: 1,
      gridRowStart: 3,
    },
    '&>*': {
      marginLeft: theme.spacing(3),
    },
  },
}));

interface Props {
  isSubmitting: boolean;
  parentHref: string;
  sidebar?: ReactNode;
  saveButtonText?: string;
}

const FormLayout: React.FC<Props> = ({ children, sidebar, isSubmitting, parentHref, saveButtonText }) => {
  const styles = useStyles();
  const { t } = useText('common');
  const cancel = useCancel(parentHref);

  return (
    <div className={styles.container}>
      <div className={styles.main}>{children}</div>
      {sidebar && <div>{sidebar}</div>}
      <div className={styles.buttons}>
        <AppButton type="button" variant="outlined" onClick={cancel}>
          {t('common')('cancel')}
        </AppButton>
        <AppButton type="submit" color="primary" inProgress={isSubmitting}>
          {saveButtonText || t('common')('save')}
        </AppButton>
      </div>
    </div>
  );
};

export default FormLayout;
