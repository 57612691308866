import React from 'react';
import { CircularProgress, useTheme } from '@mui/material';
import { useQuery } from '@apollo/client';

import { taskDetails, taskDetailsVariables, ReOccurenceType } from 'models/graphql';
import useText from 'texts/useText.hook';
import { readDayDate } from 'shared/formatDayDate';
import { GET_TASK_DETAILS } from 'containers/shared/Technician/graphql';
import useTaskDetailsPageStyles from 'containers/shared/Technician/TaskDetailsPageStyles';
import { useParams } from 'react-router';

const TaskDetailsPage: React.FC = () => {
  const { grid, cell, fullWidth, rightBorder, label, value } = useTaskDetailsPageStyles();
  const { taskId } = useParams<{ taskId: string }>();
  const { tt } = useText('reOccurence');
  const theme = useTheme();
  const { data, loading } = useQuery<taskDetails, taskDetailsVariables>(GET_TASK_DETAILS, {
    variables: { id: taskId },
  });

  const renderRepeatValue = () => {
    if (!data || data.task.reOccurenceType === ReOccurenceType.NEVER) {
      return 'Do not repeat';
    }
    return `Repeats ${tt('reOccurence')('types')(data.task.reOccurenceType)}`;
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <div className={grid}>
      <div className={`${cell} ${fullWidth}`}>
        <div className={label}>Client</div>
        <div className={value}>{data?.task.approval?.companyName}</div>
      </div>
      <div className={`${cell} ${fullWidth}`}>
        <div className={label}>Site Address</div>
        <div className={value}>{data?.task.site.address}</div>
      </div>
      <div className={`${cell} ${rightBorder}`}>
        <div className={label}>Task Order Id</div>
        <div className={value}>{data?.task.contractorResponsibleWorkOrderId}</div>
      </div>
      <div className={cell}>
        <div className={label}>Task Id</div>
        <div className={value}>{data?.task.idReadable}</div>
      </div>
      <div className={`${cell} ${rightBorder}`}>
        <div className={label}>Contact</div>
        <div className={value}>{data?.task.approval?.name} </div>
      </div>
      <div className={cell}>
        <div className={label}>Phone</div>
        <div className={value}>{data?.task.approval?.phonenumber}</div>
      </div>
      <div className={`${cell} ${fullWidth}`}>
        <div className={label}>Notes</div>
        <div className={value}>{data?.task.notes}</div>
      </div>
      <div className={`${cell} ${rightBorder}`}>
        <div className={label}>Tasks Deadline</div>
        <div className={value}>
          {data?.task.deadline ? theme.dateFormat.normal(readDayDate(data?.task.deadline)) : null}
        </div>
      </div>
      <div className={cell}>
        <div className={label}>Document Deadline</div>
        <div className={value}>
          {data?.task.documentDeadline ? theme.dateFormat.normal(readDayDate(data?.task.documentDeadline)) : null}
        </div>
      </div>
      <div className={`${cell} ${rightBorder}`}>
        <div className={label}>Occurence</div>
        <div className={value}>{renderRepeatValue()}</div>
      </div>
      <div className={cell}>
        <div className={label}>Priority</div>
        <div className={value}>{data?.task.urgent ? 'Urgent' : 'Normal'}</div>
      </div>
    </div>
  );
};

export default TaskDetailsPage;
