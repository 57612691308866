import { useMutation, useQuery } from '@apollo/client';
import { AppForm } from 'components/form';
import { FullScreenPage, FormLayout } from 'components/layout';
import { Formik } from 'formik';
import { taskAttachFileVariables, taskAttachFile, taskAttachmentsVariables, taskAttachments } from 'models/graphql';
import React from 'react';
import useText from 'texts/useText.hook';
import FileInput from 'components/FileInput';
import FilesList from 'components/FileInput/FilesList';
import useParentLocation from 'utils/useParentLocation.hook';
import useArrayOfObjectsState from 'utils/useArrayOfObjectsState';
import { asyncMap } from 'utils/asyncArray';
import { TASK_ATTACH_FILE, GET_TASK_ATTACHMENTS } from 'containers/shared/Technician/graphql';
import { useNavigate, useParams } from 'react-router';

const TaskAttachments: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const parentUrl = useParentLocation('../..');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [files, addToFiles, _removeFile, setFiles] = useArrayOfObjectsState<{
    id: string;
    name: string;
  }>();
  const { data, loading } = useQuery<taskAttachments, taskAttachmentsVariables>(GET_TASK_ATTACHMENTS, {
    variables: { id: taskId },
    onCompleted: ({ task }) => setFiles(task.attachments.map(({ id, originalName }) => ({ id, name: originalName }))),
  });
  const onUpload = (id: string, name: string) => addToFiles({ id, name });
  const [addAttachments] = useMutation<taskAttachFile, taskAttachFileVariables>(TASK_ATTACH_FILE);

  const navigate = useNavigate();
  const { t } = useText('tasks');
  return (
    <FullScreenPage title={t('tasks')('addAttachment')} loading={loading}>
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          const originalFiles = data?.task.attachments || [];
          const newFiles = files.filter((file) => !originalFiles.find((c) => c.id === file.id));
          if (newFiles && newFiles.length) {
            await asyncMap(newFiles, (newFile) => addAttachments({ variables: { taskId, fileId: newFile.id } }));
          }
          navigate(parentUrl);
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <AppForm handleSubmit={handleSubmit}>
            <FormLayout isSubmitting={isSubmitting} parentHref={parentUrl}>
              <FilesList
                label={t('tasks')('attachments')}
                files={files}
                bottomSection={<FileInput onUpload={onUpload} regular multiple />}
              />
            </FormLayout>
          </AppForm>
        )}
      </Formik>
    </FullScreenPage>
  );
};

export default TaskAttachments;
