import React from 'react';
import * as R from 'ramda';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FieldArray, useFormikContext } from 'formik';

import cn from 'classnames';
import { AppTextField } from 'components/form';
import AppCheckboxField from 'components/form/AppCheckbox';
import removeIcon from 'assets/removeIcon.svg';
import styleUtils from 'style/styleUtils';
import { FormContentType as types } from 'shared/assetFormContent.interface';
import addIcon from 'assets/addIcon.svg';

const styles = (theme: Theme) =>
  createStyles({
    elementWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    elementValue: {
      width: '100%',
    },
    elementValueColumn: {
      width: '45%',
    },
    complyButtonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20% !important',
    },
    buttonLabel: {
      fontSize: theme.fontSizes['12'],
      paddingBottom: '8px',
    },
    checkbox: {
      background: 'none !important',
    },
    removeIcon: {
      width: 20,
      height: 20,
      maxWidth: 'none',
      maxHeight: 'none',
    },
    linkButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: 0,
      margin: '0 16px',
      border: 0,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      width: 'min-content',
      '& > img': {
        paddingRight: theme.spacing(1),
      },
      '& > div': {
        whiteSpace: 'nowrap',
      },
    },
    removeIconContainer: {
      ...styleUtils.rowCenterCenter,
      height: theme.sizes.button.height,
      paddingLeft: theme.spacing(1),
    },
  });
const useStyles = makeStyles(styles);

const EditorFormInput: React.FC = () => {
  const classes = useStyles();
  const { values } = useFormikContext<{
    values: Record<string, unknown>[];
    type: string;
  }>();
  const equalsType = R.equals(values.type);
  const MULTIVALUE = equalsType(types.MULTIVALUE);
  const MULTISELECT = equalsType(types.MULTISELECT);

  const Comply = (key = 'comply') => (
    <div className={classes.complyButtonWrapper}>
      <div className={classes.buttonLabel}>Comply</div>
      <div>
        <AppCheckboxField className={classes.checkbox} name={key} />
      </div>
    </div>
  );

  if (equalsType(types.TEXT)) {
    return (
      <div className={cn('full-width-form-element', classes.elementWrapper)}>
        <div className={classes.elementValue}>
          <AppTextField label="Value" name="value" type="text" />
        </div>
        {Comply()}
      </div>
    );
  }

  if (equalsType(types.BOOLEAN) || MULTIVALUE || MULTISELECT) {
    return (
      <FieldArray
        name="values"
        render={({ push, remove }) => (
          <>
            {R.times(R.identity, values.values.length || 1).map((key, index) => (
              <React.Fragment key={key}>
                <div className={cn('full-width-form-element', classes.elementWrapper)}>
                  <div className={classes.elementValue}>
                    <AppTextField label="Value" name={`values.${key}.value`} type="text" required />
                  </div>
                  {Comply(`values.${key}.comply`)}
                  {(MULTIVALUE || MULTISELECT) && values.values.length > 2 && (
                    <button onClick={() => remove(index)} type="button" className={classes.removeIconContainer}>
                      <img alt="error" className={classes.removeIcon} src={removeIcon} />
                    </button>
                  )}
                </div>
              </React.Fragment>
            ))}
            {(MULTIVALUE || MULTISELECT) && (
              <button type="button" className={classes.linkButton} onClick={() => push({ value: '' })}>
                <img src={addIcon} alt="Add value" />
                <div>Add value</div>
              </button>
            )}
          </>
        )}
      />
    );
  }
  if (equalsType(types.NUMERIC)) {
    return (
      <div className={cn('full-width-form-element', classes.elementWrapper)}>
        <div className={cn('elementWrapper', classes.elementValue)}>
          <div className={classes.elementValueColumn}>
            <AppTextField label="Min Value" name="minValue" type="text" noMinWidth />
          </div>
          <div className={classes.elementValueColumn}>
            <AppTextField label="Max Value" name="maxValue" type="text" noMinWidth />
          </div>
        </div>
        {Comply()}
      </div>
    );
  }
  return null;
};

export default EditorFormInput;
