import gql from 'graphql-tag';

export const GET_CLIENT_FOR_ACCEPT_REQUEST = gql`
  query getClientForAcceptRequest($clientId: ID!) {
    client(id: $clientId) {
      id
      sites {
        id
        name
      }
      subscription {
        allowedSystems
      }
    }
  }
`;

export const GET_CONTRACTORS_FOR_ACCEPT_REQUEST = gql`
  query getContractorsForAcceptRequest($search: String, $archived: Boolean) {
    contractors(search: $search, archived: $archived) {
      id
      companyName
      users {
        id
        name
      }
    }
  }
`;

export const ACCEPT_REQUEST = gql`
  mutation clientAcceptRequest($clientId: ID!, $contractorId: ID!, $accessRights: ContractorRequestAccessRights!) {
    clientAcceptRequest(clientId: $clientId, contractorId: $contractorId, accessRights: $accessRights) {
      id
      accessStatus
      sites {
        id
        name
      }
      systemTypes
      contractNumber
      contractExpiracy
      workOrders {
        id
        expiracy
      }
      manager {
        id
        name
      }
      twoFactorAuth
    }
  }
`;

export const DENY_REQUEST = gql`
  mutation clientDenyRequest($clientId: ID!, $contractorId: ID!) {
    clientDenyRequest(clientId: $clientId, contractorId: $contractorId) {
      id
      accessStatus
    }
  }
`;
