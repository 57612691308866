import React from 'react';
import AppButton from 'components/AppButton';
import AppDialog from 'components/AppDialog';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const baseButtonStyle = {
  width: 'min-content',
  minWidth: 'min-content',
  padding: '0 20px',
};
const styles = () =>
  createStyles({
    dialog: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      whiteSpace: 'nowrap',
    },
    button: baseButtonStyle,
    mainButton: {
      ...baseButtonStyle,
      background: '#44924D',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageWrapper: {
      paddingRight: '16px',
      width: '3em',
    },
    image: {
      minWidth: '2em',
    },
  });
const useStyles = makeStyles(styles);

const Dialog: React.FC<{
  open: boolean;
  title: string;
  text: string;
  secondaryButtonText: string;
  mainButtonText: string;
  onSecondaryButtonClick: () => void;
  onMainButtonClick: () => void;
  onClose: () => void;
  imgAlt: string;
  imgSrc: string;
}> = ({
  open,
  title,
  text,
  secondaryButtonText,
  mainButtonText,
  onSecondaryButtonClick,
  onMainButtonClick,
  onClose,
  imgAlt,
  imgSrc,
}) => {
  const classes = useStyles();

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={title}
      actions={
        <div className={classes.dialog}>
          <AppButton className={classes.button} type="button" variant="outlined" onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </AppButton>
          <AppButton
            className={classes.mainButton}
            type="submit"
            color="primary"
            inProgress={false}
            onClick={onMainButtonClick}
          >
            {mainButtonText}
          </AppButton>
        </div>
      }
    >
      <div className={classes.content}>
        <div className={classes.imageWrapper}>
          <img className={classes.image} src={imgSrc} alt={imgAlt} />
        </div>
        <span>{text}</span>
      </div>
    </AppDialog>
  );
};

export default Dialog;
