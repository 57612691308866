import React from 'react';
import { Theme, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@apollo/client';
import { FieldArray, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import AppTextField from 'components/form/AppTextField';
import AppDateField from 'components/form/AppDateField';
import { AnimateHeight } from 'components/animations';
import AppNumberField from 'components/form/AppNumberField';
import AppForm from 'components/form/AppForm';
import {
  getSubscription,
  getSubscriptionVariables,
  subscriptionCreate,
  subscriptionCreateVariables,
  subscriptionUpdate,
  subscriptionUpdateVariables,
} from 'models/graphql';
import Yup from 'utils/yup';
import useText from 'texts/useText.hook';
import { FullScreenPage, FormLayout } from 'components/layout';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import AppFormFieldsRow from 'components/form/AppFormFieldsRow';
import AppFieldArrayRemove from 'components/form/AppFieldArrayRemove';
import AppFieldArrayAdd from 'components/form/AppFieldArrayAdd';
import resolveGraphqlErrorMessage from 'utils/resolveGraphqlErrorMessage';
import GraphqlErrorCode from 'shared/graphqlErrorCodes';
import { CREATE_SUBSCRIPTION, GET_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from './graphql';
import { useNavigate, useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  siteContainer: {
    width: '100%',
    display: 'flex',
  },
  siteFields: {
    flexGrow: 1,
  },
  removeContainer: {
    marginTop: theme.spacing(3),
  },
}));

interface FormValues {
  subscriptionId: string;
  billingFrequency: string;
  startingMonth: Date | null;
  sitesMaxNumber: number | null;
  contractorsMaxNumber: number | null;
  sites: { name: string; address: string; id: string; new?: boolean }[];
}

const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
  subscriptionId: Yup.string().required('Please fill out this field.'),
  billingFrequency: Yup.string().required('Please fill out this field.'),
  startingMonth: Yup.date().required('Please fill out this field.'),
  sitesMaxNumber: Yup.number().nullable().integer().positive().required('Please fill out this field.'),
  contractorsMaxNumber: Yup.number().nullable().integer().positive().required('Please fill out this field.'),
  sites: Yup.array()
    .required()
    .of(
      Yup.object()
        .required()
        .shape({
          name: Yup.string().required('Please fill out this field.'),
          address: Yup.string().required('Please fill out this field.'),
          id: Yup.string().required(),
        }),
    ),
});

const ClientSubscriptionCreatePage: React.FC = () => {
  const styles = useStyles();
  const { t } = useText('clients', 'common');
  const navigate = useNavigate();

  const { clientId, subscriptionId } = useParams<{
    clientId: string;
    subscriptionId?: string;
  }>();
  const mode = subscriptionId ? 'update' : 'create';

  const [addSubscription] = useMutation<subscriptionCreate, subscriptionCreateVariables>(CREATE_SUBSCRIPTION, {
    update: (cache) => {
      apolloCacheEvict({
        cache,
        typename: 'Client',
        id: clientId,
        fieldName: 'subscription',
      });
      apolloCacheEvict({
        cache,
        typename: 'Client',
        id: clientId,
        fieldName: 'sites',
      });
    },
  });

  // update mode
  const { loading: subscriptionLoading, data } = useQuery<getSubscription, getSubscriptionVariables>(GET_SUBSCRIPTION, {
    skip: mode === 'create',
    variables: { id: clientId },
  });

  const [updateSubscription] = useMutation<subscriptionUpdate, subscriptionUpdateVariables>(UPDATE_SUBSCRIPTION, {
    update: (cache) => {
      apolloCacheEvict({
        cache,
        typename: 'Client',
        id: clientId,
        fieldName: 'subscription',
      });
      apolloCacheEvict({
        cache,
        typename: 'Client',
        id: clientId,
        fieldName: 'sites',
      });
    },
  });

  const initialValues: () => FormValues = () => {
    if (mode === 'create' || !data?.client?.subscription) {
      return {
        subscriptionId: '',
        billingFrequency: '',
        contractorsMaxNumber: null,
        sitesMaxNumber: null,
        startingMonth: new Date(),
        sites: [{ id: uuidv4(), name: '', address: '', new: true }],
      };
    }

    // mode 'update'
    const {
      client: { subscription },
    } = data;
    const startingMonth = new Date();
    startingMonth.setMonth(subscription.startingMonth);
    return {
      subscriptionId: subscription.idReadable,
      billingFrequency: subscription.billingFrequency,
      contractorsMaxNumber: subscription.contractorsMaxNumber,
      sitesMaxNumber: subscription.sitesMaxNumber,
      startingMonth,
      sites: subscription.sites || [],
    };
  };

  return (
    <FullScreenPage
      title={t('clients')(mode === 'create' ? 'addSubscription' : 'editSubscription')}
      loading={mode === 'update' && subscriptionLoading}
    >
      <Formik<FormValues>
        initialValues={initialValues()}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          try {
            if (mode === 'create') {
              await addSubscription({
                variables: {
                  clientId,
                  subscription: {
                    billingFrequency: values.billingFrequency,
                    idReadable: values.subscriptionId,
                    contractorsMaxNumber: values.contractorsMaxNumber!,
                    sitesMaxNumber: values.sitesMaxNumber!,
                    startingMonth: values.startingMonth!.getMonth(),
                    sites: values.sites.map((site) => ({ name: site.name, address: site.address })),
                  },
                },
              });
            } else if (mode === 'update' && subscriptionId) {
              await updateSubscription({
                variables: {
                  id: subscriptionId,
                  subscription: {
                    billingFrequency: values.billingFrequency,
                    idReadable: values.subscriptionId,
                    contractorsMaxNumber: values.contractorsMaxNumber!,
                    sitesMaxNumber: values.sitesMaxNumber!,
                    startingMonth: values.startingMonth!.getMonth(),
                    sites: values.sites.map((site) =>
                      site.new
                        ? { name: site.name, address: site.address }
                        : { id: site.id, name: site.name, address: site.address },
                    ),
                  },
                },
              });
            }
          } catch (e) {
            const [code, fieldName] = resolveGraphqlErrorMessage(e);
            if (code === GraphqlErrorCode.uniqueViolationError && fieldName === 'idreadable') {
              setFieldError(
                'subscriptionId',
                t('clients')('subscriptionIdAlreadyExists', { id: values.subscriptionId }),
              );
            }
            return;
          }
          navigate(`/pm/clients/${clientId}/subscription`);
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <AppForm handleSubmit={handleSubmit}>
            <FormLayout isSubmitting={isSubmitting} parentHref={`/pm/clients/${clientId}/subscription`}>
              <AppTextField
                label={t('clients')('subscriptionId')}
                name="subscriptionId"
                type="text"
                autoFocus
                required
              />
              <AppFormFieldsRow>
                <AppTextField label={t('clients')('billingFrequency')} name="billingFrequency" type="text" required />
                <AppDateField
                  label={t('clients')('startingMonth')}
                  name="startingMonth"
                  view="month"
                  dateFormat="MM"
                  required
                />
              </AppFormFieldsRow>
              <AppFormFieldsRow>
                <AppNumberField label={t('clients')('numberOfSites')} name="sitesMaxNumber" />
                <AppNumberField label={t('clients')('numberOfContractors')} name="contractorsMaxNumber" />
              </AppFormFieldsRow>
              <FieldArray
                name="sites"
                render={({ push, remove }) => (
                  <>
                    {values.sites.map((site, index) => (
                      <AnimateHeight visible key={site.id}>
                        <div className={styles.siteContainer}>
                          <div className={styles.siteFields}>
                            <AppTextField
                              label={t('clients')('siteName')}
                              name={`sites.${index}.name`}
                              type="text"
                              required
                            />
                            <Box sx={{
                              mt: 3
                            }} />
                            <AppTextField
                              label={t('clients')('siteAddress')}
                              name={`sites.${index}.address`}
                              type="text"
                              required
                            />
                          </div>
                          {site.new && values.sites.length > 1 ? (
                            <div className={styles.removeContainer}>
                              <AppFieldArrayRemove
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </AnimateHeight>
                    ))}
                    <AppFieldArrayAdd
                      text="Add Another Site"
                      onClick={() => {
                        push({ id: uuidv4(), name: '', new: true });
                      }}
                    />
                  </>
                )}
              />
            </FormLayout>
          </AppForm>
        )}
      </Formik>
    </FullScreenPage>
  );
};

export default ClientSubscriptionCreatePage;
