import { useFormikContext } from 'formik';
import { Day, Month, ReOccurenceInput, ReOccurenceType, ReOccurenceWeekIndex } from 'models/graphql';
import React, { useLayoutEffect } from 'react';
import useText from 'texts/useText.hook';
import objectPropertyByString from 'utils/objectPropertyByString';
import { weekDays } from 'shared/days';
import { days }  from 'shared/days';
import { months } from 'shared/months';
import cn from 'utils/cn';
import Yup from 'utils/yup';
import switcher from 'utils/switcher';
import { addMonths, addYears } from 'date-fns';
import AppSelectMultipleField from '../AppSelectMultipleField';
import AppSelectField from '../AppSelectField';
import AppDateField from '../AppDateField';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import i18next from 'i18next';

const reOccurenceTypeOrder: ReOccurenceType[] = [
  ReOccurenceType.NEVER,
  ReOccurenceType.YEARLY,
  ReOccurenceType.MONTHLY,
  ReOccurenceType.WEEKLY,
  ReOccurenceType.DAILY,
];

const reOccurenceWeekIndexOrder: ReOccurenceWeekIndex[] = [
  ReOccurenceWeekIndex.FIRST,
  ReOccurenceWeekIndex.SECOND,
  ReOccurenceWeekIndex.THIRD,
  ReOccurenceWeekIndex.FOURTH,
  ReOccurenceWeekIndex.LAST,
];

// Styles
const useStyles = makeStyles((theme: Theme) => {
  const grid = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
  };

  return createStyles({
    fields: {
      width: '100%',
      ...grid,
      border: `solid 0px ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      transition: theme.transitions.create(['borderWidth', 'padding']),

      '&.active': {
        borderWidth: 1,
        padding: theme.spacing(2),
      },
    },
    row: {
      gridColumn: '1 / 3',
    },
    rowBottom: {
      ...grid,
      alignItems: 'end',
    },
  });
});

const ReOccurenceField: React.FC<{ name: string; disabled?: boolean; projects?: boolean }> = ({ name, disabled, projects }) => {
  const formikContext = useFormikContext<Record<string, unknown>>();
  if (formikContext === undefined) {
    throw new Error('Cannot get Formik context. Have you use the AppTextField as child of a <Formik> component?');
  }
  const { values } = formikContext;
  const value = objectPropertyByString<ReOccurenceInput>(values, name);

  const { t, tt } = useText('reOccurence', 'common');
  const styles = useStyles();

  console.log("projects value in ReOccurenceField:", projects)
  console.log("values in ReOccurrence:", values)

 

  useLayoutEffect(() => {
    if (value && value?.type === ReOccurenceType.YEARLY) {
      value.endDate = addYears(new Date(), 1);
    } else if (value) {
      if (projects)
      {
        value.endDate = values.selprojendset;    
      }
      else
      {
        value.endDate = addMonths(new Date(), 1);
      }
      
  
    }
  }, [value?.type]);

  return (
    <div
      className={cn(styles.fields, {
        active: value?.type !== ReOccurenceType.NEVER,
      })}
    >
      <div>
        <AppSelectField
          disabled={disabled}
          name={`${name}.type`}
          label={t('reOccurence')('ReOccurence')}
          required
          options={{
            array: reOccurenceTypeOrder,
            key: (type) => type,
            value: (type) => type,
            template: (type) => tt('reOccurence')('types')(type),
          }}
        />
      </div>

      {value?.type === ReOccurenceType.WEEKLY ||
      value?.type === ReOccurenceType.MONTHLY ||
      value?.type === ReOccurenceType.YEARLY ||
      value?.type === ReOccurenceType.DAILY ? (
        <div>
          <AppDateField
            disabled={disabled}
            label={t('reOccurence')('endDate')}
            name={`${name}.endDate`}
            minDate={new Date()}
            maxDate={addYears(new Date(), 10)}
            required
            showIcon={false}
          />
        </div>
      ) : null}

      {value?.type === ReOccurenceType.WEEKLY ? (
        <div>
          <AppSelectField
            disabled={disabled}
            name={`${name}.day`}
            label={t('reOccurence')('onDay')}
            options={{
              array: weekDays,
              key: (day) => day,
              value: (day) => day,
              template: (day) => tt('common')('days')(day),
            }}
          />
        </div>
      ) : null}

      {value?.type === ReOccurenceType.DAILY ? (
        <div className={styles.row}>
          <AppSelectMultipleField
            disabled={disabled}
            name={`${name}.days`}
            label={t('reOccurence')('onDays')}
            options={{
              array: days,
              key: (day) => day,
              value: (day) => day,
              template: (day) => tt('common')('days')(day),
            }}
          />
        </div>
      ) : null}


      {value?.type === ReOccurenceType.YEARLY ? (
        <div className={styles.row}>
          <AppSelectMultipleField
            disabled={disabled}
            name={`${name}.months`}
            label={t('reOccurence')('onMonths')}
            options={{
              array: months,
              key: (month) => month,
              value: (month) => month,
              template: (month) => tt('common')('months')(month),
            }}
          />
        </div>
      ) : null}

      {value?.type === ReOccurenceType.YEARLY || value?.type === ReOccurenceType.MONTHLY ? (
        <div className={cn(styles.row, styles.rowBottom)}>
          <AppSelectField
            disabled={disabled}
            name={`${name}.weekIndex`}
            label={t('reOccurence')('on')}
            options={{
              array: reOccurenceWeekIndexOrder,
              key: (index) => index,
              value: (index) => index,
              template: (index) => tt('reOccurence')('weekIndexes')(index),
            }}
          />
          <AppSelectField
            disabled={disabled}
            name={`${name}.day`}
            options={{
              array: weekDays,
              key: (day) => day,
              value: (day) => day,
              template: (day) => tt('common')('days')(day),
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ReOccurenceField;

type ReOccurenceInputType = {
  type: ReOccurenceType;
  endDate?: any | null;
  months?: Month[] | null;
  weekIndex?: ReOccurenceWeekIndex | null;
  day?: Day | null;
}

export const reOccurenceValidationSchema: Yup.ObjectSchema<ReOccurenceInputType> = Yup.object().shape({
  type: Yup.mixed<ReOccurenceType>().oneOf(reOccurenceTypeOrder).required(),
  endDate: Yup.date().when('type', ([type]: Array<ReOccurenceType>, schema: Yup.DateSchema<Date | undefined>) => {
    return type !== ReOccurenceType.NEVER ? schema.required() : schema;
  }),
  months: Yup.array()
    .min(1, i18next.t('common:required', {
      field: i18next.t('reOccurence:onMonths')
    }))
    .of<Month>(Yup.mixed<Month>().oneOf(months).required())
    .when('type', ([type]: Array<ReOccurenceType>, schema: Yup.ArraySchema<Month[] | undefined, Yup.AnyObject, "", "">) => {
      return type === ReOccurenceType.YEARLY ? schema.required() : schema;
    }),
  weekIndex: Yup.mixed<ReOccurenceWeekIndex>()
    .oneOf(reOccurenceWeekIndexOrder)
    .when('type', ([type]: Array<ReOccurenceType>, schema: Yup.MixedSchema<ReOccurenceWeekIndex | undefined>) => {
      return type === ReOccurenceType.YEARLY || type === ReOccurenceType.MONTHLY ? schema.required() : schema;
    }),
  day: Yup.mixed<Day>()
    .oneOf(weekDays)
    .when('type', ([type]: Array<ReOccurenceType>, schema: Yup.MixedSchema<Day | undefined>) => {
      return type === ReOccurenceType.YEARLY || type === ReOccurenceType.MONTHLY ? schema.required() : schema;
    }),
});

export const reOccurenceResolveInput = (input: ReOccurenceInput): ReOccurenceInput | undefined =>
  switcher(input.type, [
    [
      ReOccurenceType.YEARLY,
      {
        type: input.type,
        endDate: input.endDate,
        months: input.months,
        weekIndex: input.weekIndex,
        day: input.day,
      },
    ],
    [
      ReOccurenceType.MONTHLY,
      {
        type: input.type,
        endDate: input.endDate,
        weekIndex: input.weekIndex,
        day: input.day,
      },
    ],
    [
      ReOccurenceType.WEEKLY,
      {
        type: input.type,
        endDate: input.endDate,
        day: input.day,
      },
    ],
    [
      ReOccurenceType.DAILY,
      {
        type: input.type,
        days: input.days,
        endDate: input.endDate,
      },
    ],
    [
      ReOccurenceType.NEVER,
      {
        type: input.type,
      },
    ],
  ]);
