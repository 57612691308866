import React from 'react';
import ClientContractorPage from './ClientContractorPage';
import ClientContractorDetailsContainer from '../../Contractors/ContractorDetailsContainer';
import ClientContractorUsersPage from './ClientContractorUsersPage';
import { Route, Routes, useLocation } from 'react-router';

const ClientContractorDetailsRoutes: React.FC = () => {
  const location = useLocation();  
  const path = location.pathname.replace(/\/(rights|users)$/, '');
  
  return <ClientContractorDetailsContainer baseUrl={path}>
    <Routes>
      <Route path={`users`} element={<ClientContractorUsersPage />} />
      <Route path={`/`} element={<ClientContractorPage />} />
    </Routes>
  </ClientContractorDetailsContainer>
};

export default ClientContractorDetailsRoutes;
