import React from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
  children: React.ReactNode;
  expandComponent: React.ReactNode;
  setActiveRowIndex: (index: number) => void;
  rowIndex: number;
}

const ExpandableTableRow: React.FC<Props> = ({
  children,
  expandComponent,
  setActiveRowIndex,
  rowIndex,
  ...otherProps
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (<>
    <TableRow {...otherProps}>
      {children}
      <TableCell padding="checkbox">
        <IconButton
          onClick={() => {
            setIsExpanded(!isExpanded);
            setActiveRowIndex(rowIndex);
          }}
          size="large">
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
    {isExpanded && <TableRow>{expandComponent}</TableRow>}
  </>);
};

export default ExpandableTableRow;
