import React, { useEffect, useState } from "react";
import useMode, { Mode } from 'utils/useMode.hook';
import useText from 'texts/useText.hook';
import TaskDetailsOnExpand from './TaskDetailsOnExpand'
import { makeStyles } from "@mui/styles";
import { Box, Theme, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useParams } from "react-router";

const buttonSize = 40;
const buttonDotSize = 6;
const useStyles = makeStyles((theme: Theme) => ({
  profileDataContainer: {
    padding: theme.spacing(1),
  },
  ownerContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 5fr',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  taskallButton: {
    backgroundColor: "#8c8484",
    color: "white",
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  buttonContainer: {
    position: 'relative',
  },
  button: {
    padding: 0,
    minWidth: 0,
    width: buttonSize,
    height: buttonSize,
    background: theme.palette.grey['100'],
    borderRadius: '50%',
  },
  iotIcon: {
    marginLeft: 'auto',
  },
}));

interface PumpInfo {
  __typename: string;
  id: string;
  name: string;
  pump1: boolean;
  pump2: boolean;
  temp: string;
  batt: number;
  tankLevel: number;
  pump1runtime: string;
  pump2runtime: string;
}

export default function TasksExpandedRows(props: any) {
  const [tasksCall, setTasksCall] = useState( [] )
  const [drawingsCall, setDrawingsCall] = useState( [] )

  const styles = useStyles();
  const theme = useTheme();
  const { t } = useText('assetTypes', 'taskTypes', 'taskStatuses');
  const isTablet = useMediaQuery('(max-width:1024px)');
  const { pathname } = useLocation();
  const state = useMode();
  const mode = state.mode !== Mode.OPERATIVE && state.mode !== Mode.TECHNICIAN;
  const { siteId, clientId } = useParams<{ siteId: string; clientId: string }>();
  const [pumpDataToShow, setPumpDataToShowl] = useState(false )

  const [fullPumpDisplay, setFullPumpDisplay] = useState( [] )

  const tasktitle = 'Tasks (Upcoming & Recent)';


  // get the assets here



  useEffect(() => {
    // Fetch data
   
  
   
  }, []);


  return (
    
    <React.Fragment>
      <tr>
        <td colSpan={8}>
           <Box className={styles.profileDataContainer}>
              <TaskDetailsOnExpand taskId={props.index.id} />
           </Box>
        </td>
      </tr>
    </React.Fragment>
  );
  
 }

