import React, { useEffect, useState } from 'react';
import { ReactComponent as ReportIconGreen } from "assets/reportIconGreen.svg";
import AppButton from 'components/AppButton';
import {
  AssetType,
  TaskType,
} from 'models/graphql';
import useText from 'texts/useText.hook';
import useFieldStyles from 'components/form/AppField.style';
import calendarIcon from 'assets/calendarIcon.svg';
import { Calendar } from 'primereact/calendar';
import { makeStyles } from '@mui/styles';
import { Grid2, IconButton, MenuItem, Modal, Select, Theme, Tooltip } from '@mui/material';
import { useParams } from 'react-router';

interface DownloadUploadButtonsProps {
  handleExcelDownload: () => void;
  handlePdfDownload?: () => void;
  isExcelInProgress?: boolean;
  isPDFInProgress?: boolean;
  isButtonsDisable?: boolean;
  isAllowPdfReport?: boolean;
}

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '70%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  flex: {
    display: 'flex',
  },
  viewSwitcher: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3), // FW 2022-02-17
  },
  createTaskLink: {
    display: 'flex',
    padding: '10px',
  },
  actionButtons: {
    // FW 2022-02-14
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  downloadExcelButton: {
    padding: '8px',
    marginRight: '8px',
    '& > span': {
      height: '24px',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectField: {
    '& .MuiSelect-root': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: theme.fontSizes['14'],
      '&:focus': {
        background: 'inherit',
      },
    },
    '&.Mui-disabled .MuiSelect-icon': {
      filter: 'grayscale(100%)',
    },
    '&.displayPlaceholder .MuiSelect-select': {
      color: theme.palette.grey[500],
    },
  },
  arrayEmptyText: {
    color: theme.palette.grey[500],
  },
  placeholderItem: {
    display: 'none',
  },
  addInnerPaddingRight: {
    paddingRight: theme.spacing(1),
  },
}));




export default function SimpleModal() {
  const { clientId, siteId } = useParams<{ clientId: string; siteId: string }>();  
  const classes = useStyles();
  const { t } = useText('clients', 'assetTypes', 'common', 'taskTypes');
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
  const [selectedAssetType, setSelectedAssetType] = useState<AssetType | null>(null);
  const [selectedFormTemplate, setSelectedFormTemplate] = useState<TaskType | null>(null);
  const [assetTypes, setAssetTypes] = React.useState([]);
  const [taskTypes, setTaskTypes] = React.useState([]);
  const fieldStyles = useFieldStyles();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [assetValue, setAssetValues] = React.useState({
    assettype: "ALL"
  });
  const [taskValues, setTaskValues] = React.useState({
    tasktype: "ALL"
  });
  const [values, setValues] = React.useState({
    assettype: "ALL"
  });
  const [curAssetSel, setCurAssetSel] = useState<string | null>("ALL");
  const [curTaskSel, setCurTaskSel] = useState<string | null>("ALL");

  function handleTaskChange(event) {
    setTaskValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));

    setCurTaskSel(event.target.value);
  }

  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
    console.log("values:", values)
    console.log("event.target.value", event.target.value)

    setCurAssetSel(event.target.value);

    if (event.target.value !== 'ALL') {
      const urltogoto = "listings/tasktypes/";
      // Fetch the task types for the selected asset
      fetch(`${process.env.API_ROOT}${urltogoto}${siteId}?assettype=${event.target.value}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const datarettasks = await response.json();
        console.log(datarettasks);
        setTaskTypes(datarettasks);
      })

    }
  }


  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  const handleDateChange = (e: any) => {
    const newValue = e.value;
    setSelectedDate(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setTaskTypes([]);
    const resetasset = { assettype: "ALL" };
    const resettask = { tasktype: "ALL" };
    setCurAssetSel("ALL");
    setCurTaskSel("ALL");
    setAssetValues(resetasset)
    setTaskValues(resettask)
    setValues(oldValues => ({
      ...oldValues,
      assettype: "ALL"
    }));
    setIsSubmitting(false);
    setOpen(false);
  };



  //need to get the available asset and task types


  useEffect(() => {
    const urltogoto = "listings/assettypes/";
    //setAssets(data?.site.pageassets);
    fetch(`${process.env.API_ROOT}${urltogoto}${siteId}`, {
      method: 'GET',
      credentials: 'include',
    }).then(async (response) => {
      const dataret = await response.json();
      const results = []
      setAssetTypes(dataret);
    })
  }, []);   // [data?.site.pageassets]);

  function handleAssetChange(event) {
    setAssetValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
  }

  useEffect(() => {
    if (isSubmitting) {
      const curtime = new Date();
      // create filename for file
      const yyyy = curtime.getFullYear();
      const mm = curtime.getMonth() < 9 ? "0" + (curtime.getMonth() + 1) : (curtime.getMonth() + 1); // getMonth() is zero-based
      const dd = curtime.getDate() < 10 ? "0" + curtime.getDate() : curtime.getDate();
      const hh = curtime.getHours() < 10 ? "0" + curtime.getHours() : curtime.getHours();
      const mmm = curtime.getMinutes() < 10 ? "0" + curtime.getMinutes() : curtime.getMinutes();
      const ss = curtime.getSeconds() < 10 ? "0" + curtime.getSeconds() : curtime.getSeconds();

      const monthpicked = selectedDate.getMonth() + 1;
      const yearpicked = selectedDate?.getFullYear();
      const lowermonthname = selectedDate.toLocaleString('default', { month: 'long' });
      const ncfile = "monthly-taskteport-report_" + lowermonthname.toLowerCase() + "_" + yyyy + mm + dd + "_" + hh + mmm + ss + ".xlsx";

      const urltogoto = "listings/taskreport?site=" + siteId + "&month=" + monthpicked + "&year=" + yearpicked + "&assettype=" + curAssetSel + "&tasktype=" + curTaskSel;
      console.log(urltogoto);
      console.log("Current Asset Type:", curAssetSel)
      try {
      fetch(`${process.env.API_ROOT}${urltogoto}`, {
        method: 'GET',
        credentials: 'include',
      }).then(response => response.blob())
        .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = ncfile;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();  //afterwards we remove the element again 


          // close the modal
          handleClose();


        });
      } catch (error) {
        console.error('Error while downloading the file:', error);
    
      }
    }

  }, [isSubmitting]);

  const handleReportGenerate = async () => {

    setIsSubmitting(true);

  }


  return (<>
    <Tooltip disableFocusListener title="Generate Task Report">
      <IconButton onClick={handleOpen} size="large">
        <ReportIconGreen />
      </IconButton>
    </Tooltip>
    <Modal
      aria-labelledby="Generate Monthly Task Report"
      aria-describedby="Generate Monthly Task Report"
      open={open}
      onClose={handleClose}
    >
      <div style={modalStyle} className={classes.paper}>

        <Grid2 container sx={{
          justifyContent: "space-around"
        }}>
          <Calendar
            readOnlyInput
            dateFormat="MM/yy"
            value={selectedDate}
            onChange={handleDateChange}
            view="month"
            showIcon={true}
            icon={<img alt="calendar" src={calendarIcon} width="18px" />}
          />
          <Select
            style={{ 
              width: `${(8 * values.assettype.length) + 200}px`,
              height: '40px'
            }}
            value={values.assettype}
            displayEmpty
            onChange={handleChange}
            inputProps={{
              name: "assettype",
              id: "assettype-simple"
            }}
          >  <MenuItem value={"ALL"}>All Asset Types</MenuItem>
            {assetTypes.map((assetData) => (
              <MenuItem key={assetData.assettype} value={assetData.assettype}>
                {assetData.display_value}
              </MenuItem>
            ))}

          </Select>

          <Select
            style={{
               width: `${(8 * taskValues.tasktype.length) + 200}px`,
              height: '40px' 
            }}
            value={taskValues.tasktype}
            displayEmpty
            onChange={handleTaskChange}
            inputProps={{
              name: "tasktype",
              id: "tasktype-simple"
            }}
          >  <MenuItem value={"ALL"}>All Task Types</MenuItem>
            {taskTypes.map((tasktData) => (
              <MenuItem key={tasktData.formtype} value={tasktData.formtype}>
                {tasktData.display_value}
              </MenuItem>
            ))}

          </Select>
          <AppButton
            inProgress={isSubmitting}
            type="button"
            variant="outlined"
            disableFocusRipple
            disableRipple
            className={classes.downloadExcelButton}
            onClick={handleReportGenerate}
          >  Generate
          </AppButton>

        </Grid2>

      </div>
    </Modal>
  </>);
}