import React from 'react';

import 'texts/i18n';
import BootstrapComponent from './bootstrap';
import { PrimeReactProvider } from 'primereact/api';
import Routes from './Routes';

import './style/globals.css';
import './style/calendar.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";

const App: React.FC = () => (
  <BootstrapComponent>
    <PrimeReactProvider>
      <Routes />
    </PrimeReactProvider>
  </BootstrapComponent>
);

export default App;
