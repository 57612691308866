import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import { useStores } from 'stores';
import useMode from 'utils/useMode.hook';
import styleUtils from 'style/styleUtils';
import cn from 'utils/cn';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import DrawerComponent from './components/Drawer/DrawerComponent';
import FlashMessageComponent from './components/FlashMessage';
import HeaderComponent from './components/Header';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { useLocation } from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      height: theme.sizes.headerHeight,
      [theme.breakpoints.down(BREAKPOINT)]: {
        height: theme.sizes.mobileHeaderHeight,
      },
    },
    content: {
      [theme.breakpoints.up(BREAKPOINT)]: {
        marginLeft: theme.sizes.drawerWidth,
      },
      padding: theme.sizes.defaultContainerPadding.desktop,
      [theme.breakpoints.down(BREAKPOINT)]: {
        marginBottom: theme.sizes.mobileHeaderHeight,
        padding: theme.spacing(1, 1, 0),
      },
    },
    contentWithoutDrawer: {
      [theme.breakpoints.down(BREAKPOINT)]: {
        marginBottom: 0,
      },
    },
    noMobile: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
      backgroundColor: 'white',
      [theme.breakpoints.up(BREAKPOINT)]: {
        display: 'none',
      },
    },
    hidden: {
      [theme.breakpoints.down(BREAKPOINT)]: {
        display: 'none',
      },
    },
    center: {
      ...styleUtils.absoluteCenter,
    },
  });
const useStyles = makeStyles(styles);

const DefaultContainer = observer(({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const state = useMode();
  const isMobileEnabled = !!state.mobile;
  const { contextStore } = useStores();
  const isNavigationHidden = contextStore.isNavigationHidden;
  const isDrawerHidden = contextStore.isDrawerHidden;
  const isPaddingBottomRemoved = contextStore.isPaddingBottomRemoved;

  const location = useLocation();

  useEffect(() => {
    contextStore.newLocation(window.location.href);
  }, [location, contextStore]);

  return (
    <DefaultErrorBoundary>
      {!isNavigationHidden && (
        <>
          <div className={classes.header}>
            <HeaderComponent />
          </div>
          {!isDrawerHidden && <DrawerComponent />}
        </>
      )}
      <div
        className={cn(
          isNavigationHidden ? undefined : classes.content,
          isMobileEnabled ? undefined : classes.hidden,
          isDrawerHidden ? classes.contentWithoutDrawer : undefined,
        )}
        style={isPaddingBottomRemoved ? { paddingBottom: 0 } : undefined}
      >
        {children}
      </div>
      <div className={cn(classes.noMobile, isMobileEnabled ? classes.hidden : undefined)}>
        <div className={classes.center}>
          <Typography variant="h2">Only desktop view is supported.</Typography>
        </div>
      </div>
      <FlashMessageComponent />
    </DefaultErrorBoundary>
  );
})

export default DefaultContainer;