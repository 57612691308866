import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Formik, Form } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import { AppTextField, AppDateField } from 'components/form';

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: '500px', // Adjust the value as needed
    maxWidth: '80%',
  },
  table: {
    width: '100%',
  },
  row: {
    '&:not(:first-child)': {
      paddingTop: '5px', // Add spacing between rows
    },
  },
});

function findKey(array, keyToFind) {
  return array.find(item => item.key === keyToFind);
}

function formatDate(isoString) {
  if (isoString)
  {
    const date = new Date(isoString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(2); // Get last two digits of the year
    
    console.log("Date is:", `${month}-${day}-${year}`)
    return `${month}-${day}-${year}`;

  }

}

/* const capitalizeFirstLetter = (string) => {
  if (string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}; */

interface FormValues {
  projectName: string;
  projectDesc: string;
  responsible: string;
  startDate: string;
  endDate: string;
  status: string;
}

const EditProjectModal = (props) => {
  const { content, open, setOpen, onConfirm, title, onSave, onClose } = props;
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [projeName, setProjeName] = useState('Hello There');
  const [formDetails, setFormDetails] =  useState<FormValues[] | []>();
  const [loading, setLoading] = useState(true);

  console.log("content passed:", content)

  
  useEffect(() => {
      if (content)
      {
          setFormDetails(content)
          setProjeName(content.projectName)

       
      }

  }, [content]); // Update value when content.controlmeasure changes
  

  useEffect(() => {
    if (formDetails)
    {      

      setLoading(false);

    }

  }, [formDetails]);


  const handleSubmit = (values: FormValues) => {
    onClose();
  };

  return (
    <div>
      {loading ? (
       <label> </label>
      ) : (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          id="customized-dialog-title"
        >
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '80%' }}>{title}</td>
                <td style={{ width: '20%', textAlign: 'right', marginLeft: '80%' }}>
                  <IconButton onClick={() => setOpen(false)} aria-label="close" size="large">
                    <ClearIcon />
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogTitle>
        <DialogContent>
        <Formik
                initialValues={{
                  projectName: formDetails?.projectname || '',
                  projectDesc: formDetails?.details || '',
                  responsible: formDetails?.responsible || '',
                  startDate: formatDate(formDetails?.startdate)  || '',
                  endDate: formatDate(formDetails?.enddate)  || '',
              
                }}
                onSubmit={(values) => {
                  handleSubmit(values);
                  onSave(values);
                }}
              >
              {({ values, handleChange, dirty }) => (
                  <Form>
                    <table className={classes.table}>
                      <tbody>
                        <tr className={classes.row}>
                          <td>
                            <AppTextField
                              label="Title"
                              name="projectName"
                              type="text"
                              autoFocus
                            />
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td>
                            <AppTextField
                              label="Description"
                              name="projectDesc"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td>
                            <AppTextField
                              label="Responsible"
                              name="responsible"
                              type="text"
                            />
                          </td>
                        </tr>
               
                        <tr className={classes.row}>
                          <td>
                            <AppDateField
                              label="Start Date"
                              name="startDate"
                            />
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td>
                            <AppDateField
                              label="End Date"
                              name="endDate"
                            />
                          </td>
                        </tr>
                       </tbody>
                    </table>
                    <DialogActions>
                      <Button autoFocus onClick={() => setOpen(false)} color="primary">
                        Cancel
                      </Button>
                      {dirty && (
                        <Button color="primary" type="submit">
                          Update
                        </Button>
                      )}
                    </DialogActions>
                  </Form>
                )}
              </Formik>
        </DialogContent>
      </Dialog>   
      )}     </div>
  );
};

export default EditProjectModal;
