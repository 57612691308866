import React, { useCallback, useState, useRef } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import { fileUpload } from 'utils/Api';
import AppButton from 'components/AppButton/AppButton';
import { uploadRequest as UploadRequestResponse, uploadRequestVariables } from 'models/graphql';
import AppMenu from 'components/AppMenu';
import { UPLOAD_REQUEST } from './graphql';
import DocumentBrowser from './DocumentBrowser';
import { MenuItem, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import imageIcon from 'assets/imageIcon.svg';
import errorIcon from 'assets/errorIcon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    alignItems: 'flex-start',
    margin: 0,
    paddingBottom: theme.spacing(1),
    fontSize: theme.fontSizes['12'],
    fontWeight: theme.typography.fontWeightMedium,
  },
  imageIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '159px',
    maxHeight: '159px',
  },
  dropZone: {
    position: 'relative',
    width: '175px',
    height: '175px',
    backgroundColor: '#F3F4F9',
    border: '1px dashed rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
    borderRadius: '8px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '14px',
  },
  activeStyle: {
    border: '2px dashed #4287F5',
  },
  acceptStyle: {
    border: '2px dashed #69CC84',
  },
  rejectStyle: {
    border: '2px dashed #F55142',
  },
  uploadContainer: {
    width: '175px',
    textAlign: 'center',
  },
  errorText: {
    color: '#F15541',
    fontSize: '12px',
    lineHeight: '1',
    marginLeft: '8px',
  },
  error: {
    marginTop: '8px',
  },
}));

interface Props {
  // returns with false aborts the upload process
  beforeUpload?: (file: File) => boolean | Promise<boolean>;
  onUpload: (id: string, name: string) => void;
  buttonName?: string;
  initialSrc?: string;
  accept?: Accept;
  label?: string;
  regular?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  publicAccess?: boolean;
  contained?: boolean;
  documentRepositoryBrowser?: boolean;
}

const FileInput: React.FC<Props> = ({
  beforeUpload,
  onUpload,
  buttonName,
  initialSrc,
  accept,
  label,
  regular,
  multiple,
  disabled,
  publicAccess,
  contained,
  documentRepositoryBrowser,
}) => {
  const classes = useStyles();
  const defaultImgSrc = imageIcon;
  const [error, setError] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDocumentRepositoryBrowserOpen, setDocumentRepositoryBrowserOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(initialSrc || defaultImgSrc);
  const [rejectOrActiveClass, setRejectOrActiveClass] = useState('');
  const [fileRejected, setFileRejected] = useState(false);

  const [uploadRequest, { loading }] = useMutation<UploadRequestResponse, uploadRequestVariables>(UPLOAD_REQUEST);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles[0]) return; // File was rejected
      for (let index = 0; index < acceptedFiles.length; index += 1) {
        const file = acceptedFiles[index];
        try {
          setError(false);
          if (beforeUpload) {
            if ((await beforeUpload(file)) === false) return; // eslint-disable-line no-await-in-loop
          }

          // eslint-disable-next-line no-await-in-loop
          const response = await uploadRequest({
            variables: { fileName: file.name, public: !!publicAccess },
          });
          if (!response.data) {
            throw new Error('Unable to upload.');
          }
          const { url, srcurl, id } = response.data.uploadRequest;
          await fileUpload(url, file); // eslint-disable-line no-await-in-loop
          setImgSrc(srcurl);
          // eslint-disable-next-line no-await-in-loop
          await onUpload(id, file.name);
        } catch (e) {
          setError(true);
        }
      }
    },
    [uploadRequest, beforeUpload, onUpload, publicAccess],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple,
    accept,
    disabled,
    onDrop,
    onDropRejected: () => {
      setRejectOrActiveClass(classes.rejectStyle);
      setFileRejected(true);
      setTimeout(() => {
        setRejectOrActiveClass('');
        setFileRejected(false);
      }, 3500);
    },
    onDropAccepted: () => {
      setRejectOrActiveClass(classes.acceptStyle);
      setTimeout(() => setRejectOrActiveClass(''), 2500);
    },
  });

  return (
    <>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <div className={regular ? '' : classes.uploadContainer}>
        <div
          {...getRootProps(
            regular
              ? {}
              : {
                  className: `${classes.dropZone} ${isDragActive ? classes.activeStyle : rejectOrActiveClass}`,
                },
          )}
        >
          <input {...getInputProps()} />
          {!regular && (
            <>
              <img className={classes.imageIcon} src={imgSrc} alt="Upload icon" />
              {isDragActive && <p>Drop your image here</p>}
            </>
          )}
        </div>
        <AppButton
          type="button"
          variant={contained ? 'contained' : 'outlined'}
          inProgress={loading}
          onClick={documentRepositoryBrowser ? () => setDropdownOpen(true) : open}
          disabled={disabled}
          ref={buttonRef}
        >
          {buttonName || (documentRepositoryBrowser ? 'Browse File' : 'Upload')}
        </AppButton>
        {documentRepositoryBrowser && (
          <AppMenu
            anchorEl={buttonRef.current}
            open={isDropdownOpen}
            horizontalPosition="left"
            onClose={() => setDropdownOpen(false)}
          >
            <MenuItem
              onClick={() => {
                setDropdownOpen(false);
                setDocumentRepositoryBrowserOpen(true);
              }}
            >
              Document Repository
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDropdownOpen(false);
                open();
              }}
            >
              Upload
            </MenuItem>
          </AppMenu>
        )}
        {error && (
          <div className={classes.error}>
            <img alt="Error" src={errorIcon} />
            <span className={classes.errorText}>Upload error. Please try again later.</span>
          </div>
        )}
        {fileRejected && (
          <div className={classes.error}>
            <img alt="Error" src={errorIcon} />
            <span className={classes.errorText}>
              {`Only ${regular ? 'supported' : 'image'} file types are accepted.`}
            </span>
          </div>
        )}
        {documentRepositoryBrowser && (
          <DocumentBrowser
            open={isDocumentRepositoryBrowserOpen}
            onClose={() => setDocumentRepositoryBrowserOpen(false)}
            onSelect={(files) => {
              files.forEach((file) => onUpload(file.id, file.name));
              setDocumentRepositoryBrowserOpen(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default FileInput;
