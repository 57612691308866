import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { PropsWithChildren } from 'react';
import materialUISelectArrow from 'assets/materialUISelectArrow.svg';
import styleUtils from 'style/styleUtils';
import cn from 'utils/cn';
import objectPropertyByString from 'utils/objectPropertyByString';
import { useFormikContext } from 'formik';
import useFieldStyles from './AppField.style';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    navigationButton: {
      border: 0,
      transform: 'rotate(90deg)',

      '&.next': {
        transform: 'rotate(270deg)',
      },
    },
    arrow: { ...styleUtils.rowCenterCenter, width: theme.sizes.button.height, height: theme.sizes.button.height },
    field: {
      ...styleUtils.rowCenterCenter,
    },
    content: {
      flexGrow: 1,
    },
  });
});

export default function AppSelectHorizontalField<T>({
  name,
  options,
  onChange,
}: PropsWithChildren<{
  name: string; // name is required
  options: {
    array: Array<T>;
    value: (arrayElement: T) => string | undefined;
    template: (arrayElement: T) => React.ReactNode;
  };
  onChange?: (value: T) => void;
}>) {
  const { values, setFieldValue } = useFormikContext<Record<string, unknown>>();
  const value = objectPropertyByString(values, name);
  let selectedOptionIndex: number | undefined;
  const selectedOption = options.array.find((option, index) => {
    if (options.value(option) === value) {
      selectedOptionIndex = index;
      return true;
    }
    return false;
  });

  const setValue = (newValue: T) => {
    setFieldValue(name, options.value(newValue));
    if (onChange) onChange(newValue);
  };

  const fieldStyles = useFieldStyles();
  const styles = useStyles();

  return (
    <div className={cn(fieldStyles.fakeField, styles.field)}>
      <button
        type="button"
        className={styles.navigationButton}
        onClick={() => {
          setValue(
            selectedOptionIndex !== undefined && selectedOptionIndex > 0
              ? options.array[selectedOptionIndex - 1]
              : options.array[options.array.length - 1],
          );
        }}
      >
        <div className={styles.arrow}>
          <img alt="previous" src={materialUISelectArrow} />
        </div>
      </button>
      <div className={styles.content}>{selectedOption ? options.template(selectedOption) : null}</div>
      <button
        type="button"
        className={cn(styles.navigationButton, 'next')}
        onClick={() => {
          setValue(
            selectedOptionIndex !== undefined && selectedOptionIndex < options.array.length - 1
              ? options.array[selectedOptionIndex + 1]
              : options.array[0],
          );
        }}
      >
        <div className={styles.arrow}>
          <img alt="next" src={materialUISelectArrow} />
        </div>
      </button>
    </div>
  );
}
