import React from 'react';
import AppButton from 'components/AppButton/AppButton';
import { Box, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import { createStyles, withStyles } from '@mui/styles';

const styles = (theme: Theme) =>
  createStyles({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  });

interface FormComponentProps extends WithStyles<typeof styles> {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  submitButtonText: string;
  inProgress: boolean;
  justify?: string;
  actionButton?: JSX.Element;
}

const FormComponent: React.FunctionComponent<FormComponentProps> = (props) => {
  const { classes, children, onSubmit, submitButtonText, inProgress, justify, actionButton } = props;

  return (
    <form noValidate className={classes.form} onSubmit={onSubmit}>
      {children}
      <Box
        sx={{
          display: "flex",
          justifyContent: justify || 'center',
          py: 3
        }}>
        {actionButton}
        <AppButton type="submit" variant="contained" color="primary" className={classes.submit} inProgress={inProgress}>
          {submitButtonText}
        </AppButton>
      </Box>
    </form>
  );
};

export default withStyles(styles)(FormComponent);
