import React from 'react';
import { Box, CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { endOfDay, startOfDay } from 'date-fns';

import {
  UserRequestAccessStatus,
  contractorHome,
  contractorHomeVariables,
  contractorHome_user_clients as contractorHomeClient,
  contractorHome_user_clients_tasks_nodes as contractorHomeTask,
} from 'models/graphql';
import { useBreadcrumb } from 'components/Breadcrumb';
import { useStores } from 'stores';
import calendarIcon from 'assets/calendarIcon.svg';
import taskIconGrey from 'assets/taskIconGrey.svg';
import cn from 'utils/cn';
import formatDayDate, { readDayDate } from 'shared/formatDayDate';
import TaskCard from 'containers/shared/Technician/TaskCard';
import useHomePageStyles from 'containers/shared/Technician/HomePageStyles';
import { GET_CONTRACTOR_HOME } from '../graphql';
import { Link } from 'react-router';

const sortClients = (c1: contractorHomeClient, c2: contractorHomeClient) => {
  if (c1.tasks.nodes.length > 0) {
    if (c2.tasks.nodes.length > 0) {
      const d1 = c1.tasks.nodes[0].deadline;
      const d2 = c2.tasks.nodes[0].deadline;
      if (!d1) {
        return d2 ? 1 : 0;
      }
      if (!d2) return -1;
      if (d1 < d2) {
        return -1;
      }
      return d1 > d2 ? 1 : 0;
    }
    return -1;
  }
  return c2.tasks.nodes.length > 0 ? 1 : 0;
};

const isHighlighted = (task: contractorHomeTask) => {
  const date = new Date();
  return (
    task.urgent ||
    (task.deadline && task.deadline > formatDayDate(startOfDay(date)) && task.deadline < formatDayDate(endOfDay(date)))
  );
};

const HomePage: React.FC = () => {
  const classes = useHomePageStyles();
  const theme = useTheme();
  const { authStore } = useStores();
  const user = authStore.user;
  const title = user && user.company ? user.company.name : 'Home';
  useBreadcrumb('/home', title);

  const { loading, data } = useQuery<contractorHome, contractorHomeVariables>(GET_CONTRACTOR_HOME, {
    skip: !user,
    variables: {
      id: user!.id,
      accessStatus: UserRequestAccessStatus.ACCEPTED,
      upcoming: true,
    },
  });

  // Filter clients w/o WIs and sort: closer deadlines come first
  const clients = data ? data.user.clients.filter((client) => client.tasks.nodes.length > 0).sort(sortClients) : [];
  const highlights = clients
    .map((client) => {
      const nodes = client.tasks.nodes.filter((node) => isHighlighted(node));
      return { id: client.id, companyName: client.companyName, tasks: { nodes } };
    })
    .filter((client) => client.tasks.nodes.length > 0);

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <Grid container className={classes.padding}>
      {clients.length > 0 && (
        <Grid
          className={classes.paddingX}
          size={{
            xs: 12,
            md: 6
          }}>
          <h2 className={cn(classes.grey, classes.heading)}>Your clients</h2>
          <Grid container spacing={2}>
            {clients.map((client) => (
              <Grid key={client.id} size={6}>
                <Link to={`/technician/works?client=${client.id}`}>
                  <Paper className={cn(classes.clientBox, classes.grey)}>
                    <Typography className={classes.clientName}>{client.companyName}</Typography>
                    <Box
                      sx={{
                        mt: 2,
                        mb: 1
                      }}>
                      <Typography>
                        {client.tasks.nodes.length} task instruction
                        {client.tasks.nodes.length === 1 ? '' : 's'}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}>
                      <Grid className={classes.smallFont}>
                        {client.tasks.nodes.length > 0 &&
                          client.tasks.nodes[0].deadline &&
                          theme.dateFormat.fullMonth(readDayDate(client.tasks.nodes[0].deadline))}
                      </Grid>
                      <Grid>
                        <Box sx={{
                          display: "grid"
                        }}>
                          <img src={calendarIcon} width={20} alt="Calendar icon" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {highlights.length > 0 && (
        <Grid
          className={classes.paddingX}
          size={{
            xs: 12,
            md: 6
          }}>
          <h2 className={cn(classes.grey, classes.heading)}>Highlighted Tasks</h2>
          <Grid container spacing={3}>
            {highlights.map((client) =>
              client.tasks.nodes.map((node) => (
                <Grid key={node.id} size={12}>
                  <Link to={`/technician/works/${node.id}`}>
                    <TaskCard
                      task={{
                        ...node,
                        site: { companyName: client.companyName },
                      }}
                    />
                  </Link>
                </Grid>
              )),
            )}
          </Grid>
        </Grid>
      )}
      {clients.length < 1 && (
        <Box
          sx={{
            mt: 10,
            px: 3,
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.54)"
          }}>
          <Box
            className={classes.boxBg}
            sx={{
              p: 5,
              borderRadius: "50%",
              maxWidth: "fit-content",
              m: "auto"
            }}>
            <img src={taskIconGrey} width={50} alt="No tasks" />
          </Box>
          <Box sx={{
            p: 1
          }}>
            <Typography variant="body1" className={classes.boldFont}>
              No Task Instructions
            </Typography>
          </Box>
          <Typography variant="body1" className={classes.smallFont}>
            at this time
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default observer(HomePage);
