import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

import { getContractor } from 'models/graphql';
import Logo from 'components/Logo';
import useText from 'texts/useText.hook';
import Rating from 'components/Rating';

const Item: React.FC<{ name: string; value?: string | React.ReactNode }> = ({ name, value }) => (
  <Box sx={{
    mb: 3
  }}>
    <Typography>{name}</Typography>
    <Typography sx={{
      color: "textSecondary"
    }}>{value}</Typography>
  </Box>
);

export default function ContractorDetails<T extends getContractor['contractor']>({
  contractor,
  loading,
  topButton,
}: {
  contractor?: T;
  loading: boolean;
  topButton: React.ReactNode;
}) {
  const { tt } = useText('contractors');

  return <>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}>
        {topButton}
      </Box>
      {loading ? (
        <CircularProgress color="primary" />
      ) : (
        <Box sx={{
          display: "flex"
        }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1
            }}>
            <Item name="Company Name" value={contractor?.companyName} />
            <Item name="Address" value={contractor?.address} />
            <Item name="Contact Name" value={contractor?.contactName} />
            <Item name="Contact Email" value={contractor?.contactEmail} />
            <Item name="Contact Phone" value={contractor?.contactPhone} />
            <Item
              name="Expertise"
              value={contractor?.expertise.map((expertise) => tt('contractors')('expertises')(expertise)).join(', ')}
            />
            {contractor?.rating && (
              <Box sx={{
                mb: 3
              }}>
                <Typography>Rating</Typography>
                <Rating addMargin rating={contractor.rating} />
              </Box>
            )}
          </Box>
          <Box sx={{
            flex: 1
          }}>
            <Typography>Logo</Typography>
            <Logo src={contractor?.logo?.srcUrl || null} alt={`logo of ${contractor?.companyName}`} />
          </Box>
        </Box>
      )}
    </Box>
  </>;
}
