import excelCreateBook from 'utils/excel/excelCreateBook';
import excelDownloadBook from 'utils/excel/excelDownloadBook';
import excelValidationOfArray from 'utils/excel/excelValidationOfArray';
import excelFileToBook from 'utils/excel/excelFileToBook';
import excelValidationRequiredText from 'utils/excel/excelValidationRequiredText';
import parseFieldNamesFromExcel from './parseTokensFromExcel';

export { default as Excel } from './Excel';
export const excelUtils = {
  createBook: excelCreateBook,
  downloadBook: excelDownloadBook,
  validations: {
    ofArray: excelValidationOfArray,
    requiredText: excelValidationRequiredText,
  },
  fileToBook: excelFileToBook,
  parseFieldNamesFromExcel,
};
