import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { ContractorRequestAccessStatus } from 'models/graphql';
import useText from 'texts/useText.hook';
import switcher from 'utils/switcher';
import StatusBadge from './StatusBadge';

const ContractorRequestStatus: React.FC<{
  accessStatus: ContractorRequestAccessStatus;
}> = ({ accessStatus }) => {
  const { tt } = useText('contractors');
  const theme = useTheme();

  return (
    (<StatusBadge
      state={
        switcher(accessStatus, [
          [
            ContractorRequestAccessStatus.ACCEPTED,
            {
              background: alpha(theme.palette.primary.main, 0.25),
              color: '#000',
            },
          ],
          [ContractorRequestAccessStatus.DENIED, { background: alpha('rgb(241, 85, 65)', 0.25), color: '#000' }],
        ]) || { background: alpha('rgb(62, 80, 180)', 0.25), color: '#000' }
      }
    >
      {tt('contractors')('accessStatuses')(accessStatus)}
    </StatusBadge>)
  );
};

export default ContractorRequestStatus;
