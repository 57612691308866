import React from 'react';
import { ClickAwayListener } from '@mui/material';
import AppTooltip from 'components/AppTooltip/AppTooltip';

const TooltipOnClick: React.FC<{
  note?: string;
}> = ({ note }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div onClick={handleTooltipOpen} style={{ marginLeft: '3px' }}>
        <AppTooltip note={note} open={open} disableFocusListener disableHoverListener disableTouchListener />
      </div>
    </ClickAwayListener>
  );
};

export default TooltipOnClick;
