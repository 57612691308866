import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import ReactPlayer from "react-player";

const styles = {
  modal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px', // Updated width
    height: '600px',backgroundColor: 'white',
    color: 'black',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    overflow: 'hidden',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  body: {
    display: 'flex',
    height: '100%',
  },
  split: {
    flex: 1,
    position: 'relative',
    paddingTop: '20px',
  },
  left: {
    flex: '35%', // Left side takes up 30%
    position: 'relative',
    paddingTop: '20px',
    paddingLeft: '3%',
  },
  right: {
    flex: '65%', // Right side takes up 70%
    position: 'relative',
    paddingTop: '20px',
  },
  centered: {
    position: 'absolute',
    top: '20%',
    left: '30%',
    paddingTop: '10px',
    transform: 'translate(-20%, -30%)',
    textAlign: 'center',
  },
  centeredImg: {
    width: '150px',
    borderRadius: '50%',
  },
  button: {
    padding: '10px 20px',
    cursor: 'pointer',
    marginTop: '20px',
  },
};


interface Props {
  pumpPopup: boolean;
  setPumpPopup: Dispatch<SetStateAction<boolean>>;
  docsList: any
  dialogTitle: string;
 
}

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function capitalizeFirstLetter(wordtoset:string) {
  return wordtoset.charAt(0).toUpperCase() + wordtoset.slice(1);
}
const getFormattedDateTime = (date: any) => {
  return date.toISOString().replace(/\.\d{3}Z$/, 'Z'); // Converts to ISO string and removes milliseconds
};

const FootageViewer: React.FC<Props> = ({ pumpPopup, setPumpPopup, docsList, dialogTitle }) => {
  const [currentFile, setCurrentFile] = useState(null); // Selected file


  return (
    <Dialog open={pumpPopup} onClose={() => setPumpPopup(false)}  maxWidth={false} // Disable automatic max-width
    PaperProps={{
      style: {
        width: '800px', // Fixed width
        height: '500px', // Fixed height
        overflow: 'hidden', // Prevent scrollbars
      },
    }}>
  {/* Dialog Title */}
  <DialogTitle>
    <Typography variant="h6">{capitalizeFirstLetter(dialogTitle)}</Typography>
  </DialogTitle>

  {/* Dialog Content */}
  <DialogContent style={styles.body}>
    {/* Left Section */}
    <div style={{ ...styles.split, ...styles.left }}>
      <ul>
        {docsList.map((file, index) => (
          <li key={index}>
            <button onClick={() => setCurrentFile(file)}>
              {file.displayname} &nbsp;&nbsp; {formatDate(file.uploaddate)}
            </button>
          </li>
        ))}
      </ul>
    </div>

    {/* Right Section */}
    <div style={{ ...styles.split, ...styles.right }}>
      <div style={styles.centered}>
        {currentFile ? (
          <>
            <h2>Now Playing: {currentFile.displayname}</h2>
            <ReactPlayer
              url={currentFile.link}
              controls
              width="100%"
              height="100%"
            />
          </>
        ) : (
          <p>Select a file to play.</p>
        )}
      </div>
    </div>
  </DialogContent>

  {/* Dialog Actions */}
  <DialogActions>
    <Button onClick={() => setPumpPopup(false)} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>
  );
};

export default FootageViewer;
