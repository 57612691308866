import React, { useEffect, useState } from "react";
import useMode, { Mode } from 'utils/useMode.hook';
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Item from 'components/Item';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import { useParams } from "react-router";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const buttonSize = 40;
const buttonDotSize = 6;
const useStyles = makeStyles((theme: Theme) => ({
  profileDataContainer: {
    padding: theme.spacing(3),
  },
  attachmemtDataContainer: {
    paddingLeft: theme.spacing(3),
    cursor: 'pointer',
  },
  attachmemtHeaderContainer: {
    paddingLeft: theme.spacing(3),
  },
  ownerContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 5fr',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  taskallButton: {
    backgroundColor: "#8c8484",
    color: "white",
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    position: 'relative',
  },
  button: {
    padding: 0,
    minWidth: 0,
    width: buttonSize,
    height: buttonSize,
    background: theme.palette.grey['100'],
    borderRadius: '50%',
  },
  iotIcon: {
    marginLeft: 'auto',
  },
}));


export default function SFExpandedRows(props: any) {
  const styles = useStyles();
  const theme = useTheme();

  const isTablet = useMediaQuery('(max-width:1024px)');
  const state = useMode();
  const mode = state.mode !== Mode.OPERATIVE && state.mode !== Mode.TECHNICIAN;
  const { siteId, clientId } = useParams<{ siteId: string; clientId: string }>();

  const [dataSFJobsExtra, setDataSFJobsExtra] = useState([]);
  const [dataSFJobsAttachments, setDataSFJobsAttachments] = useState([]);
  const [dataSFJobsAttachmentsRest, setDataSFJobsAttachmentsRest] = useState([]);
  const [isLoading, setIsLoading] =  useState(false);

  const [jobProducts, setJobProducts] = useState( [] )

  const [wasteDest, setWasteDest] = useState( [] )

  console.log("status:", props.index.status)

  const getMuiTheme = () => {
    return createTheme({
      components: {
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#bce0d5"
            },
            actions: {
              backgroundColor: "#bce0d5"
            }
          }
        }
      }
    })
  };

  const tableoptions = {
    filter: false,
    print: false,
    selectableRows: 'none',
    search: false,
    download: false,
    viewColumns: false,
    tableBodyHeight: "200px",
    fixedHeader: true,
    jumpToPage: false
  };

  const columnsMUITable = [
    {
        name: "sfproductname",
        label: "Product",
        options: {
          setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
        }
    },
  
    {
        name: "sfproductquantity",
        label: "Quantity",
        options: {
          setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px'  }}),
      
          customBodyRender: (value: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, tableMeta: any, updateValue: any) => {
     
            if (jobProducts[tableMeta.rowIndex].sfjobstatus.Status__c === 'Invoice')
            {
              return (
                value
               );
            }
            return (
              null
             );

          },
        }
    },
    {
        name: "sfproductunitprice",
        label: "Unit Price",
        options: {
          setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px'}}),
          //customBodyRenderLite: (dataIndex: string | number, rowIndex: any, value: any) => {
          customBodyRender: (value: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, tableMeta: any, updateValue: any) => {
     
            if (jobProducts[tableMeta.rowIndex].sfjobstatus.Status__c === 'Invoice')
            {
              return (
                numberFormat(value)
               );
            }
            return (
              null
             );

          },
        
        }
    },
    {
        name: "sfproducttotalprice",
        label: "Total Price",
        options: {
          setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
          customBodyRender: (value, tableMeta, updateValue) => {
	            
            if (jobProducts[tableMeta.rowIndex].sfjobstatus.Status__c === 'Invoice')
            {
              return (
                numberFormat(value)
               );
            }
            return (
              null
             );
          }
        }
    }
  ]

  async function getJobProducts(sfjobid:any) {
    try {

      let urltogoto = `sf/jobproducts?jobid=${sfjobid}`
      await fetch(`${process.env.API_ROOT}${urltogoto}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
        const dataret = await response.json();
        const sfJobProducts = dataret.map((u: { Product__c:any;		Product_Cost__c: any;	Product_Cost_Hidden__c: any; Product_Name__c: any; Quantity__c: any; Rate_Type__c:any; Total_Price__c:any; Product_Grouping__c: any; UnitPrice__c: any; Vehicle_Amount__c: any;  Job__r:any;}) => {
        
          return {
            "sfproduct": u.Product__c,
            "sfproductname": u.Product_Name__c,
            "sfproductcost": u.Product_Cost__c,
            "sfproducthiddencost":  u.Product_Cost_Hidden__c,
            "sfproductquantity": u.Quantity__c,
            "sfproductrateype":u.Rate_Type__c,
            "sfproducttotalprice":u.Total_Price__c,
            "sfproductgrouping": u.Product_Grouping__c,
            "sfproductunitprice":u.UnitPrice__c,
            "sfjobstatus":u.Job__r
          };

        });
        console.log("sfJobProducts:", sfJobProducts)
        setJobProducts(sfJobProducts);

      }); 
    }
   catch (error) {
    console.error(error); /* eslint-disable-line */
  }
}

  async function getExtaDetails(sfjobid:any, sfjobtype:any) {
    try {
      // urltogoto = `sf/jobs/${clientId}`
      let urltogoto = `sf/jobdetail?jobid=${sfjobid}&jobtype=${sfjobtype}&siteid=${siteId}&status=${props.index.status}`
       await fetch(`${process.env.API_ROOT}${urltogoto}`, {
         method: 'GET',
         credentials: 'include',
       }).then(async (response) => {
         const dataret = await response.json();
         console.log(dataret);

      const sfDataExtrAgain = dataret.map((u) => {
          // Access each object dynamically and extract its key-value pairs
          const objectKeys = Object.keys(u);
          const mappedObject = {};
          objectKeys.forEach((key) => {
            mappedObject[key] = u[key];
          });
          return mappedObject;
        });
        console.log("LINE 258 :", sfDataExtrAgain)

   const sfDataExtra = dataret.map((u: { Number_of_Men_Required__c:string;		MSRA_Required__c: string;	Site_Contact__c: string; Type_of_Line__c: string; Type_of_Trap_Interceptor__c: string; Job_Detail__c:any; Local_Authority__c:string; Is_Work_100_Complete__c: string; EWC_Codes__c: string; Type_of_Waste__c: string; Waste_Destination__c: string; Corrected_Waste_Quantity__c: string; McBreen_Waste_Location__c: string;Job_Start_Date_Time__c:any, Job_End_Date_Time__c:any,productCount__c:any,Total_Price__c:any, Status__c:any,invoiceTotalAmount__c:any, Check_In__c:any, Check_Out__c:any, Vehicles_on_Site__c:any}) => {
          
          return {
            "jobdetail": u.Job_Detail__c,
            "localauthority": u.Local_Authority__c,
            "typeoftrap":  u.Type_of_Trap_Interceptor__c,
            "isworkhundtypeoredcomp": u.Is_Work_100_Complete__c,
            "ewccodes":u.EWC_Codes__c,
            "typeofwaste":u.Type_of_Waste__c,
            "wastedestination": u.Waste_Destination__c,
            "wastequantitycorrected":u.Corrected_Waste_Quantity__c,
            "mcbreenwastelocation":u.McBreen_Waste_Location__c,
            "typeofline": u.Type_of_Line__c,
            "contact": u.Site_Contact__c,
            "msrarequired": u.MSRA_Required__c,
            "menrequired": u.Number_of_Men_Required__c,
            "sfjobstartdatetime":u.Job_Start_Date_Time__c,
            "sfjobenddatetime":u.Job_End_Date_Time__c,
            "sfproductcount":u.productCount__c,
            "sftotalprice":u.Total_Price__c,
            "sfstatus":u.Status__c,
            "sfinvoicetotalamount":u.invoiceTotalAmount__c,
            "sfcheckin":u.Check_In__c,
            "sfcheckout":u.Check_Out__c,
            "vehiclesonsite":u.Vehicles_on_Site__c,
          };
        }); 
       // console.log(sfDataExtra);
       //setDataSFJobsExtra(dataret);
      // setDataSFJobsExtra(sfDataExtra);
        setDataSFJobsExtra(sfDataExtrAgain);
         
       });
     } catch (error) {
       console.error(error); /* eslint-disable-line */
     }

     try {
      // urltogoto = `sf/jobs/${clientId}`
    //  let urltogoto = `sf/jobattachments?jobid=${sfjobid}`
       let urltogoto = `sf/jobattachmentsall?jobid=${sfjobid}`
       await fetch(`${process.env.API_ROOT}${urltogoto}`, {
         method: 'GET',
         credentials: 'include',
       }).then(async (response) => {
         const dataret = await response.json();
         console.log ("Dataret:", dataret)
         const combinedAttachmentsData = dataret.map((item: { CombinedAttachments: any; }) => item.CombinedAttachments);
         console.log("combinedAttachmentsData:", combinedAttachmentsData);

	  // Filter out falsy values (e.g., null, undefined, empty arrays)
	 const validAttachments = combinedAttachmentsData.filter(item => item && (Array.isArray(item) ? item.length > 0 : true));
	 const hasValidEntries = validAttachments.length > 0;

	// Check if there are any entries
	//const hasEntries = (combinedAttachmentsData?.length ?? 0) > 0;
        console.log("hasValidEntries:", hasValidEntries)
         // now get records
        if (hasValidEntries)
        {
        	const combinedAttachmentsDataRecords = combinedAttachmentsData.map((item: { records: any; }) => item.records);
    
        	console.log("combinedAttachmentsDataRecords:", combinedAttachmentsDataRecords);


	     //    const pdfFiles = combinedAttachmentsDataRecords.filter((item: { Title: string; }) => item.Title.toLowerCase().endsWith('.pdf'));
         
   	      	const pdfFiles = combinedAttachmentsDataRecords[0].filter(item => {
    	    	  	const title = item.Title.toLowerCase();
     	    	 	return title.endsWith('.pdf');
     	  	});
         
         	console.log("pdfFiles:", pdfFiles);

         	setDataSFJobsAttachments(pdfFiles);
	}
	console.log("Now the rest");

         // Now fetch rest of the attachments
         let urltogoto = `sf/jobattachments?jobid=${sfjobid}`

         await fetch(`${process.env.API_ROOT}${urltogoto}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          const dataret = await response.json();

          console.log("Rest of Attachments:",dataret)

          const pdfEntries = dataret.filter(item => item.ContentDocument.FileExtension === 'pdf');



          setDataSFJobsAttachmentsRest(pdfEntries);
        });





       });
     } catch (error) {
       console.error(error); /* eslint-disable-line */
     }
     getJobProducts(sfjobid);
  }

  const numberFormat = (value: any) =>
  new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR'
  }).format(value);

  async  function getDownloadLink(docid:string, contentdocname: string) {

    console.log( docid);
    try {
      let urltogoto = `sf/jobattachmentdwn?docid=${docid}&docname=${contentdocname}`
      console.log("urltogoto is", urltogoto)
       await fetch(`${process.env.API_ROOT}${urltogoto}`, {
         method: 'GET',
         credentials: 'include',
       }).then(response => response.blob())
       .then(blob => {
           var url = window.URL.createObjectURL(blob);
           var a = document.createElement('a');
           a.href = url;
           a.download = contentdocname;
           document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
           a.click();
           a.remove();  //afterwards we remove the element again 
 
       });
       
       
       //.then(async results => results.json()) .then(data => {
         
        // console.log("Version Data Value Responded:", data);

         // now fetch the data - would need salesforce value here, not what I want
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }

      
  }

  async  function getDownloadLinkRest(docid:string, contentdocname: string) {

    console.log( docid);
    try {
      let urltogoto = `sf/jobattachmentdwnrest?docid=${docid}&docname=${contentdocname}`
      console.log("urltogoto is", urltogoto)
       await fetch(`${process.env.API_ROOT}${urltogoto}`, {
         method: 'GET',
         credentials: 'include',
       }).then(response => response.blob())
       .then(blob => {
           var url = window.URL.createObjectURL(blob);
           var a = document.createElement('a');
           a.href = url;
           a.download = contentdocname;
           document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
           a.click();
           a.remove();  //afterwards we remove the element again 
 
       });
       
       
       //.then(async results => results.json()) .then(data => {
         
        // console.log("Version Data Value Responded:", data);

         // now fetch the data - would need salesforce value here, not what I want
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }

      
  }

  const handleAttachmentDownload = (event:any, contentdocid:string, contentdocname:string) => {
    console.log(event);
    console.log("contentdocid:", contentdocid)
    getDownloadLink(contentdocid, contentdocname);
    

  };

  const handleAttachmentDownloadRest = (event:any, contentdocid:string, contentdocname:string) => {
    console.log(event);
    console.log("contentdocid:", contentdocid)
    getDownloadLinkRest(contentdocid, contentdocname);
    

  };


  async function getWasteDetails(sfjobid:any) {
    try {
  
      let urltogoto = `sf/sfwastedest?jobid=${sfjobid}`
      console.log("urltogoto: ", urltogoto)
       await fetch(`${process.env.API_ROOT}${urltogoto}`, {
         method: 'GET',
         credentials: 'include',
       }).then(async (response) => {
         const dataret = await response.json();
         console.log("Destinations:", dataret);

         setWasteDest(dataret)
	   })

   
     } catch (error) {
       console.error(error); /* eslint-disable-line */
     }
     
  }

  useEffect(() => {
    console.log("props.index.status: ", props.index.status)
    // Addition on 2024-07-17 to get waste destination
    if(props.index.status === 'Invoice')
    {
      console.log("Getting the waste dest")
      getWasteDetails(props.index.idreadable)
    }
  
    // get the data for the job
     getExtaDetails(props.index.sfjobid, props.index.sfjobtype) 
  

    



  }, []);
  
  if (dataSFJobsExtra)
  {
  // console.log(dataSFJobsExtra[0]);

    
    return (
      <React.Fragment>
        <tr>
          <td colSpan={3}>
            
     
          <Box className={styles.profileDataContainer}>
        
         
           {
                   <>

                   {dataSFJobsExtra.map(d => {
                    if(d['Job Detail']) return  <Item key="{item}" label="Job Detail" value={d['Job Detail']} />
                  
                 })} 
                    {dataSFJobsExtra.map(d => {
                      if(d['Product Count']) return  <Item key="{item}" label="Product Count" value={d['Product Count']} />
                    
                   })} 
                    {dataSFJobsExtra.map(d => {
                     if(props.index.status === 'Invoice')
                     {
                       if(d['Invoice Total'] ) return  <Item key="{item}" label="Invoice Total" value={numberFormat(d['Invoice Total'] )} />
                      
                     
                      }
                   })} 
                  {dataSFJobsExtra.map(d => {
                     if(props.index.status === 'Invoice')
                     {
                      if(d['Invoice No'] ) return  <Item key="{item}" label="Invoice No" value={d['Invoice No']} />      
                     
                      }
                   })} 
   
                   {dataSFJobsExtra.map(d => {
                     if(d['Waste Removed']) return  <Item key="{item}" label="Waste Removed" value='Yes' />
                   })} 
   
                   {dataSFJobsExtra.map(d => {
                     if(d['Vehicles on Site']) return  <Item key="{item}" label="Vehicles on Site" value={d['Vehicles on Site']} />
                   })} 
               
             
                  {dataSFJobsExtra.map(d => {
                    if(props.index.status === 'Invoice')
                      {
                        if(d['Job Start']) return  <Item key="{item}" label="Job Start" value={ format(new Date(d['Job Start']), 'dd/MM/yyyy HH:mm:ss')} />
                      }
                   })} 

                  {dataSFJobsExtra.map(d => {
                     if(props.index.status === 'Invoice')
                      {
                         if(d['Job End']) return  <Item key="{item}" label="Job End" value={ format(new Date(d['Job End']), 'dd/MM/yyyy HH:mm:ss')} />
                      }
                  })} 
               
                     </>
          
              
                 
            } 
         
          </Box>
           
        </td>
        <td colSpan={4}>
            
     
            <Box className={styles.profileDataContainer}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {wasteDest.map((d, index) => (
        <React.Fragment key={d.id}>
          {d['wastedestination'] && (
            <Item key={`${d.id}-destination`} label="Waste Destination" value={d['wastedestination']} />
          )}
          {d['wastetype'] && (
            <Item key={`${d.id}-type`} label="Waste Type" value={d['wastetype']} />
          )}
          {d['quantity'] && (
            <Item key={`${d.id}-quantity`} label="Quantity (Tonnage)" value={d['quantity']} />
          )}
         <br></br>
        </React.Fragment>
      ))}

                  
              
              
            </Box>
            
          </td>
    </tr>
        <tr>
        <td colSpan={7}>
       
                    <tr>
                     { (props.index.status === 'Invoice' && dataSFJobsAttachments?.length >= 1 || dataSFJobsAttachmentsRest?.length >= 1)? ( <th  className={styles.attachmemtHeaderContainer} align='left'>Attachments</th>)
                     : (null)}
             
                    </tr>
                    {props.index.status === 'Invoice' && (
                    <>
                      {dataSFJobsAttachments?.map(d => {
                     //      return  <tr><td className={styles.attachmemtDataContainer} align='left' onClick={event => handleAttachmentDownload(event, d.ContentDocumentId, d.ContentDocument.Title.concat('.', d.ContentDocument.FileExtension))}>{d.ContentDocument.Title.concat('.', d.ContentDocument.FileExtension)}</td></tr>
                            return  <tr><td className={styles.attachmemtDataContainer} align='left' onClick={event => handleAttachmentDownload(event, d.Id, d.Title)}>{d.Title}</td></tr>
                      })} 
                      {dataSFJobsAttachmentsRest?.map(e => {
                            return  <tr><td className={styles.attachmemtDataContainer} align='left' onClick={event => handleAttachmentDownloadRest(event, e.ContentDocumentId, e.ContentDocument.Title.concat('.', e.ContentDocument.FileExtension))}>{e.ContentDocument.Title.concat('.', e.ContentDocument.FileExtension)}</td></tr>
                      })} 
                     </>
                    )}
        </td>
        
        </tr>
        <tr><td colSpan={6}>
        <Table style={{ minWidth: "650" }} aria-label="simple table">
            <TableRow>
              <TableCell>
                <ThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable  title='Products'  data={jobProducts}  columns={columnsMUITable} options={tableoptions}/>
                </ThemeProvider>
              </TableCell>
            </TableRow>
          </Table> 
          </td></tr>
      </React.Fragment>
    );
    

  }
 }