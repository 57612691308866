import { gql } from '@apollo/client';

export const GET_DOCUMENTS = gql`
  query getDocuments($siteId: ID!, $search: String, $archived: Boolean) {
    documents(siteId: $siteId, search: $search, archived: $archived) {
      id
      name
      category
      status
      systems {
        id
        name
        type
      }
      assets {
        id
        name
      }
      file {
        id
        originalName
      }
      uploadedAt
      uploadedBy {
        name
      }
    }
  }
`;

export const GET_CLIENT_SITES_WITH_ASSETS = gql`
  query clientSitesWithAssets($id: ID!) {
    client(id: $id) {
      id
      sites {
        id
        name
        assets {
          id
          name
        }
        systems {
          id
          name
        }
      }
    }
  }
`;

export const GET_DOCUMENT = gql`
  query getDocument($id: ID!) {
    document(id: $id) {
      id
      name
      category
      systems {
        id
        name
        type
      }
      status
      assets {
        id
        name
      }
      file {
        id
        originalName
      }
      uploadedAt
      uploadedBy {
        name
      }
      site {
        id
      }
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation documentCreate($document: DocumentCreate!) {
    documentCreate(document: $document) {
      id
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation documentUpdate($id: ID!, $changes: DocumentUpdate!) {
    documentUpdate(id: $id, changes: $changes) {
      id
      name
      category
    }
  }
`;

export const UPDATE_DOCUMENT_STATUS = gql`
  mutation documentStatusUpdate($id: ID!, $status: DocumentStatus!) {
    documentStatusUpdate(id: $id, status: $status) {
      id
      status
    }
  }
`;
