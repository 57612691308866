import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { alpha, Theme } from '@mui/material/styles';
import cn from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  status: {
    display: 'inline-block',
    paddingTop: parseInt(theme.spacing(1)) / 2,
    paddingBottom: parseInt(theme.spacing(1)) / 2,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: theme.fontSizes['12'],
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.25),
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
  },
  archived: {
    background: theme.palette.grey['400'],
    color: theme.palette.grey['600'],
  },
}));

interface Props {
  isArchived: boolean;
}

const AppTableStatus: React.FC<Props> = ({ isArchived }) => {
  const { status, archived } = useStyles();
  return <div className={cn(status, isArchived ? archived : '')}>{isArchived ? 'archived' : 'active'}</div>;
};

export default AppTableStatus;
