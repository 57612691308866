import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { clientSites, clientSitesVariables } from 'models/graphql';
import { GET_CLIENT_SITES } from 'containers/shared/graphql';
import useParentLocation from 'utils/useParentLocation.hook';
import Breadcrumb from 'components/Breadcrumb';
import { useStores } from 'stores';
import { useLocation, useNavigate, useParams } from 'react-router';

const useSites = (baseUrl: string) => {
  const { clientId, siteId } = useParams<{
    clientId: string;
    siteId?: string;
  }>();
  const { contextStore } = useStores();
  const [siteIdContext, setSiteIdContext] = useState(contextStore.siteId)

  useEffect(() => {
    setSiteIdContext(contextStore.siteId)
  }, [contextStore.siteId]);

  const { data, loading } = useQuery<clientSites, clientSitesVariables>(GET_CLIENT_SITES, {
    variables: { id: clientId },
  });
  const sites = data?.client.sites;
  
  
  const current = sites?.find((site) => site.id === siteId);

  const location = useLocation();
  const navigate = useNavigate();
  const parent = useParentLocation('../', baseUrl);
  useEffect(() => {
    if (loading) {
      return;
    }
    if (!siteId) {
      if (siteIdContext) {
        navigate(`${baseUrl}${siteIdContext}`);
      } else if (sites?.length) {
        // Redirect if no siteId is provided
        contextStore.setSiteId(sites[0].id);
        navigate(`${baseUrl}${sites[0].id}`);
      }
    } else if (!current) {
      // redirect if site id is not found in client sites
      // history.push('/');
    } else {
      contextStore.setSiteId(siteId);
    }
  }, [siteId, baseUrl, location.pathname, sites, loading, current, siteIdContext, contextStore]);

  const renderBreadcrumb = (title: string) => {
    if (!sites || !current) {
      return null;
    }
    const dropdownOptions = sites
      .filter((site) => site.id !== current.id)
      .map(({ name, id }) => ({ name, to: `${parent}/${id}` }));
    return (
      <>
        <Breadcrumb to={`${parent}/${current.id}`} name={title} />
        <Breadcrumb to={`${parent}/${current.id}`} name={`${current.name}`} options={dropdownOptions} />
      </>
    );
  };

  return { sites, current, loading, renderBreadcrumb };
};

export default useSites;
