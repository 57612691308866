import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  TextField,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ExcelJS from 'exceljs';
import { excelUtils } from 'utils/excel';
import downloadExcelIcon from 'assets/downloadExcelTemplateGreen.svg';
import { IconButton } from '@mui/material';
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const styles = (theme: Theme) => {
  const green = theme.palette.primary.main;
  const white = '#FFF';

  return createStyles({
    pumpsContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    dateRangeContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    pumpWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      border: `1px solid ${green}`,
      borderRadius: theme.spacing(1),
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },
    pumpContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
    },
    pumpParams: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    paramTitle: {
      fontWeight: 'bold',
      '& span': {
        fontWeight: 'normal',
      },
    },
    paramSubTitle: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
      '& span': {
        fontWeight: 'normal',
      },
    },
    pumpGraphic: {
      marginLeft: 'auto',
    },
    box1: {
      display: 'flex',
      justifyContent: 'center',
      border: `1px solid ${green}`,
      borderTop: 0,
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      width: 640,
      height: 600,
      margin: '20px 20px 0 20px',
      overflow: 'hidden',
    },
    box2: {
      position: 'relative',
      height: '100%',
      width: '100%',
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
    box3: {
      border: `1px solid ${green}`,
      borderTop: 0,
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      height: '90%',
      backgroundColor: white,
      display: 'flex',
      justifyContent: 'space-around',
      margin: '0 20px 20px',
      paddingTop: 10,
    },
    pump: {
      width: 25,
      height: '60%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      backgroundColor: 'lightgray',
    },
    pumpActive: {
      backgroundColor: 'yellowgreen',
    },
    pumpName: {
      position: 'absolute',
      marginBottom: '-45px',
    },
    pumpSymbol: {
      height: 50,
      width: 50,
      borderRadius: '50%',
      position: 'absolute',
      marginBottom: '-20px',
      backgroundColor: 'lightgray',
      '&:after': {
        top: '60%',
        left: '50%',
        content: "''",
        position: 'absolute',
        transform: 'translateX(-50%) translateY(-80%)',
        width: 0,
        height: 0,
        borderLeft: '21px solid transparent',
        borderRight: '21px solid transparent',
        borderBottom: '35px solid black',
      },
    },
    pumpSymbolActive: {
      backgroundColor: 'yellowgreen',
    },
    pumpInfo: {
      margin: '10px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      '& div': {
        display: 'flex',
        alignItems: 'center',
        '& span': {
          marginTop: 7,
        },
      },
    },
    thermometerIcon: {
      width: 24,
    },
  });
};
const useStyles = makeStyles(styles);


interface Props {
  pumpPopup: boolean;
  setPumpPopup: Dispatch<SetStateAction<boolean>>;
  timeData: { data: { ts: string; f: { [key: string]: { v: number } } }[] } | null;
  dialogTitle: string;
  assetId: string;
  iotSource: string;
  iotResources: any;
  thresholdPassed?: any;
}

function capitalizeFirstLetter(wordtoset:string) {
  return wordtoset.charAt(0).toUpperCase() + wordtoset.slice(1);
}
const getFormattedDateTime = (date: any) => {
  return date.toISOString().replace(/\.\d{3}Z$/, 'Z'); // Converts to ISO string and removes milliseconds
};




const getCurrentAndPreviousDateTime = () => {
  const currentDate = new Date(); // Current date/time
  const previousDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago

  const currentFormattedDateTime = getFormattedDateTime(currentDate);
  const previousFormattedDateTime = getFormattedDateTime(previousDate);

  return { currentFormattedDateTime, previousFormattedDateTime };
};

const TimeSeriesDialog: React.FC<Props> = ({ pumpPopup, setPumpPopup, timeData, dialogTitle, assetId, iotSource, iotResources, thresholdPassed }) => {
  const classes = useStyles();

  const [dataToShow, setDataToShow] = useState<any>(null);
  const [startDate, setStartDate] = useState<string>(moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD HH:mm:ss'));
  const [historicDataToShow, setHistoricDataToShow] = useState([])

  const [optionsToShow, setOptionsToShow] = useState<any>(null);

  console.log("startDate at line 198: ", startDate)
  console.log("endDate at line 198: ", endDate)

  console.log("dialogTitle:", dialogTitle)

  console.log("iotsource:", iotSource)

  let threshold: number = 0;

  if (thresholdPassed)
  {
    threshold = thresholdPassed;

  }
  
  console.log("thresholdPassed:", thresholdPassed)

  const exportToExcel = async () => {
    if (!historicDataToShow) return;
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
  
    // Define headers
    worksheet.columns = [
      { header: 'Timestamp', key: 'timestamp', width: 20 },
      { header: 'Value', key: 'value', width: 20 }
    ];
  
   /* if (iotSource === '11ba89c6-d425-4bdd-a832-dc9acc860905')
    {
        // Add data rows
        historicDataToShow.samples.forEach((entry) => {
          worksheet.addRow({
            timestamp: new Date(entry.sample_date).toISOString(),
            value: entry.value
          });
        });
    }
    else
    { */
        // Add data rows
        historicDataToShow.vals.forEach((entry) => {
          worksheet.addRow({
            timestamp: new Date(entry.t).toISOString(),
            value: entry.v
          });
        });
    //}

   // Get current date and time for filename
    const now = new Date();
    const dateStr = now.toISOString().slice(0, 10); // Format as YYYY-MM-DD
    const timeStr = now.toTimeString().slice(0, 5).replace(':', '-'); // Format as HH-MM
  
    // Generate and download Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    });

    await excelUtils.downloadBook(
      workbook,
      `${dialogTitle}_data_${dateStr}_${timeStr}.xlsx`,
    );
  };










  // create a separate function to call that can be passed the required details from all pages
  async function refreshData(assetid:string, startdate: string, enddate: string, datafeed: string, iotsource: string, iotresource:any)
  {
      
      // cater for ayyeka
      if (iotsource === '11ba89c6-d425-4bdd-a832-dc9acc860905')
      {

        console.log("startdate:", startdate)
        console.log("enddate:", enddate)

        if (!startdate.includes("T")) {
          startdate = startdate.replace(" ", "T");
        }

        if (!enddate.includes("T")) {
          enddate = enddate.replace(" ", "T");
        }

          // Will need to modify the datetimes to cater for epoch

          const epochSeconds = Math.floor(new Date(startdate).getTime() / 1000);

          const epochSeconds24 = Math.floor(new Date(enddate).getTime() / 1000);

          console.log("epochMillisNow:", epochSeconds)
          console.log("epochMillis24:", epochSeconds24)
          console.log("datafeed:", datafeed)
          //let urltogotohist =   `iot/apiparam?assetid=${assetid}&param=${datafeed}&startdate=${epochSeconds}&enddate=${epochSeconds24}`
          let urltogotohist =   `iot/apiparam?assetid=${assetid}&param=${datafeed}&startdate=${startdate}&enddate=${enddate}`

          const getHistoricValues = async (urlhistoric:any) => {
            try {
              await fetch(`${process.env.API_ROOT}${urlhistoric}`,  {
                method: 'GET',
                credentials: 'include',
              }).then(async (response) => {
  
                 const historicData = await response.json();
                 console.log("historicData fetched:", historicData)
                 if (historicData)
                 {
                    // check the data if threshold
                    const valuesToCheck = historicData.vals.map(entry => entry.v);
                    console.log("valuesToCheck:", valuesToCheck)
                    const showThreshold = valuesToCheck.some((value) => Number(value) <= threshold);

                    console.log("showThreshold:", showThreshold)

                    // check the data to be displayed
                    let options = {
                      scales: {
                        x: {
                          type: 'time',
                          time: {
                            unit: 'hour' // You can adjust this depending on the granularity you want to display
                          },
                          title: {
                            display: true,
                            text: 'Time'
                          }
                        },
                        y: {
                          title: {
                            display: true,
                            text: dialogTitle
                          }
                        }
                      },
                      plugins: {
                        legend: {
                          display: true,
                        },
                        annotation: {
                          annotations: showThreshold
                            ? {
                                thresholdLine: {
                                  type: "line",
                                  yMin: threshold,
                                  yMax: threshold,
                                  borderColor: "red",
                                  borderWidth: 2,
                                  label: {
                                    //content: `Threshold (${threshold})`,
                                    enabled: true,
                                    position: "end",
                                  },
                                },
                              }
                            : {}, // No annotation if threshold isn't crossed
                        },
                      },
                  
                    };
              
                    setOptionsToShow(options);



                  setHistoricDataToShow(historicData)
                 }
            
  
               })
            } catch (error) {
                 console.error(error); /* eslint-disable-line */
             }
             
            }
            await getHistoricValues(urltogotohist);


          

          
      }




      if (iotsource === '896a2c20-2f3a-4942-b2a5-273d6323482c')
      {
            let urltogotohist =   `iot/apiparam?assetid=${assetid}&param=${datafeed}&startdate=${startdate}&enddate=${enddate}`
             console.log("urltogotohist:", urltogotohist)
       
             console.log ("Fetching Historical Data")

             const getHistoricTemp = async (urlhistoric:any) => {
              try {
                await fetch(`${process.env.API_ROOT}${urlhistoric}`,  {
                  method: 'GET',
                  credentials: 'include',
                }).then(async (response) => {
    
                   const historicData = await response.json();
                   console.log("historicData fetched:", historicData)
                   if (historicData)
                   {

                     // check the data if threshold
                     const valuesToCheck = historicData.vals.map(entry => entry.v);
                     const showThreshold = valuesToCheck.some((value) => Number(value) <= threshold);

                    // check the data to be displayed
                    let options = {
                      scales: {
                        x: {
                          type: 'time',
                          time: {
                            unit: 'hour' // You can adjust this depending on the granularity you want to display
                          },
                          title: {
                            display: true,
                            text: 'Time'
                          }
                        },
                        y: {
                          title: {
                            display: true,
                            text: dialogTitle
                          }
                        }
                      },
                  
                    };
              
                    setOptionsToShow(options);



                    setHistoricDataToShow(historicData)
                   }
              
    
                 })
              } catch (error) {
                   console.error(error); /* eslint-disable-line */
               }
               
              }
              getHistoricTemp(urltogotohist);
      }




  }

  useEffect(() => {

    console.log("Historic Data Updated")
    console.log("HistoricDataToShow from useEffect", historicDataToShow)


    /*if (iotSource === '11ba89c6-d425-4bdd-a832-dc9acc860905')
    {
      if (Array.isArray(historicDataToShow.samples) && historicDataToShow.samples.length > 0) {
      const chartData = {
        labels: historicDataToShow.samples.map((sample) => sample.sample_date), // Extract timestamps
        datasets: [
          {
            label: "Level Values (in cm)",
            data: historicDataToShow.samples.map((sample) => parseFloat(sample.value)), // Extract values as floats
            borderColor: "rgba(75, 192, 192, 1)", // Line color
            backgroundColor: "rgba(75, 192, 192, 0.2)", // Optional fill color
            tension: 0.4, // Smooth curve
          },
        ],
      };
    
    
    
      console.log("chartData for Historic :", chartData)
      setDataToShow(chartData); 
    }
    }
    else
    { */

      if (Array.isArray(historicDataToShow.vals) && historicDataToShow.vals.length > 0) {
        console.log("in historc data")
        console.log("HistoricDataToShow from useEffect", historicDataToShow)
        const timestamps = historicDataToShow.vals.map(entry => new Date(entry.t));
        //const labels = historicDataToShow.vals.map(entry => new Date(entry.t).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }));
  
       
        const values = historicDataToShow.vals.map(entry => entry.v);
        console.log("values:", values)
        const chartData = {
          labels: timestamps,  // x-axis labels
          datasets: [
            {
              label: 'Values',
              data: values,      // y-axis data points
              fill: false,
              borderColor: 'rgba(75, 192, 192, 1)',  // Line color
              tension: 0.1       // Smooth curve
            }
          ]
        };
        console.log("chartData for Historic :", chartData)
        setDataToShow(chartData);  
      }



    //}
   
    
  

  }, [historicDataToShow]);


  useEffect(() => {

    console.log("time data:",timeData )


    // cater for ayyeka series data
   /* if (iotSource === '11ba89c6-d425-4bdd-a832-dc9acc860905')
    {

      const chartData = {
        labels: timeData.samples.map((sample) => sample.sample_date), // Extract timestamps
        datasets: [
          {
            label: "Level Values (in cm)",
            data: timeData.samples.map((sample) => parseFloat(sample.value)), // Extract values as floats
            borderColor: "rgba(75, 192, 192, 1)", // Line color
            backgroundColor: "rgba(75, 192, 192, 0.2)", // Optional fill color
            tension: 0.4, // Smooth curve
          },
        ],
      };
    
    
    
      console.log("chartData for Historic :", chartData)
      setDataToShow(chartData); 
    
    
    
    }
    else
    { */

      if (timeData && dialogTitle && timeData.length > 0) {

       




        const timestamps = timeData.vals.map(entry => new Date(entry.t));
        console.log("timestamps:", timestamps)
        const values = timeData.vals.map(entry => entry.v);

 


        console.log("values:", values)
        const chartData = {
          labels: timestamps,  // x-axis labels
          datasets: [
            {
              label: 'Values',
              data: values,      // y-axis data points
              fill: false,
              borderColor: 'rgba(75, 192, 192, 1)',  // Line color
              tension: 0.1       // Smooth curve
            }
          ]
        };
        console.log("chartData for Historic :", chartData)
        setDataToShow(chartData); 
  
      }
      else
      {
        const chartData = {
          labels: [],  // x-axis labels
          datasets: []
        };
        console.log("chartData for Historic :", chartData)
        setDataToShow(chartData); 
  
      }

   // }



    
   

   
    
    setStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'));
    setEndDate(moment().format('YYYY-MM-DD HH:mm:ss'));


 

  }, [timeData, dialogTitle]);

  useEffect(() => {

      console.log("Will update the data feed")
      console.log("Asset ID:", assetId)
      console.log("iotSource:", iotSource)
      console.log("startDate:", startDate)
      console.log("endDate:", endDate)
      console.log("dialogTitle:", dialogTitle.toLowerCase())

      // now fetch the data
      refreshData(assetId, startDate, endDate, dialogTitle, iotSource, iotResources)


      // At this point check the data if threshhold is near
      



  }, [startDate, endDate]);



  
  
  return (
    <Dialog open={pumpPopup} onClose={() => setPumpPopup(false)} maxWidth="md" fullWidth>
    <DialogTitle>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h6">{capitalizeFirstLetter(dialogTitle)} Data</Typography>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <div className={classes.dateRangeContainer}>
          <TextField
            label="Start Date"
            type="datetime-local"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="datetime-local"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <IconButton onClick={exportToExcel}>
          <img src={downloadExcelIcon} alt="Download Excel" style={{ width: 24, height: 24 }} />
        </IconButton>
      </div>
    </div>
  </DialogTitle>


      <DialogContent className={classes.pumpsContent}>
         <Line  key={dialogTitle} data={dataToShow} options={optionsToShow} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPumpPopup(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeSeriesDialog;
