import React, { useEffect, useState } from 'react';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import { Backdrop, IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLazyQuery } from '@apollo/client';
import {
  assetsForSite_site_assets as assetsForSiteSiteAssets,
  getAssetForRegister,
  getAssetForRegisterVariables,
  getAssetForRegisterPaged, getAssetForRegisterPaged_site_assets,    // FW 2022-04-10 For Assets Paged
  assetsForSitePaged_site_assets as assetsForSiteSiteAssetsPaged, getAssetForRegisterPaged_pagedAssetsById  // FW 2022-04-10 For Assets Paged
} from 'models/graphql';
import GET_ASSET_REGISTER from 'components/NFCScanner/graphql';

const useStyles = makeStyles((theme: Theme) => {
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    backdropContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    icon: {
      width: 160,
      height: 160,
    },
    button: { marginLeft: '-12px' },
  };
});

interface Props {
  openNFC: boolean;
  setOpenNFC: (val: boolean) => void;
  setAssets: (val: getAssetForRegisterPaged_site_assets | undefined) => void;
}

const NFCScanner: React.FC<Props> = ({ openNFC, setOpenNFC, setAssets }) => {
  const styles = useStyles();
  const [status, setStatus] = useState('');
  const [assetId, setAssetId] = useState('');
  let ctrl: AbortController;

  const [assetRequest, { data: assetData, error: assetError }] = useLazyQuery<
    getAssetForRegisterPaged_pagedAssetsById,
    getAssetForRegisterVariables
  >(GET_ASSET_REGISTER);

  useEffect(() => {
    setStatus('');
    setAssetId('');
  }, []);

  useEffect(() => {
    if (assetId) {
      assetRequest({
        variables: {
          assetId,
        },
      });
    }
  }, [assetId, assetRequest]);

  useEffect(() => {
    if (assetData && assetId) {
      const assetsArr = assetData.pagedAssetsById as getAssetForRegisterPaged_site_assets ;

      setTimeout(() => {
        setOpenNFC(false);
        setAssets(assetsArr);
        setAssetId('');
      }, 500);
    }
  }, [assetId, assetData, setAssets, setOpenNFC]);

  useEffect(() => {
    if (assetError) {
      setStatus('Nothing found. Please try again or ask the administrator.');
      setTimeout(() => {
        setOpenNFC(false);
        setAssetId('');
      }, 500);
    }
  }, [assetError, setOpenNFC]);

  const scanNFC = () => {
    setOpenNFC(true);
    ctrl = new AbortController();

    try {
      const ndef = new NDEFReader();

      ndef
        .scan({ signal: ctrl.signal })
        .then(() => {
          setStatus('Waiting for the RFID tag.');
          ndef.onreadingerror = () => {
            setStatus('Cannot read data from the NFC tag. Try another one?');
          };
          ndef.onreading = ({ message }) => {
            if (message.records.length === 0 || message.records[0].recordType === 'empty') setStatus('RFID tag empty.');
            else {
              const textDecoder = new TextDecoder(message.records[0].encoding);
              const nfcAssetId = textDecoder.decode(message.records[0].data);
              if (nfcAssetId) {
                setStatus('Success.');
                setAssetId(nfcAssetId);
                setTimeout(() => {
                  ctrl.abort();
                }, 2000);
              }
            }
          };
        })
        .catch(() => {
          setStatus('Error! Scan failed to start.');
        });
    } catch (error) {
      setStatus("Your browser don't support NFC scanner.");
    }
  };

  return (<>
    <Backdrop className={styles.backdrop} open={openNFC} onClick={() => setOpenNFC(false)}>
      <div className={styles.backdropContainer}>
        <ContactlessOutlinedIcon className={styles.icon} />
        <h3>{status}</h3>
      </div>
    </Backdrop>
    <IconButton
      disabled={!('NDEFReader' in window)}
      className={styles.button}
      key="NFC-scan"
      aria-label="NFC scan"
      color="primary"
      onClick={scanNFC}
      size="large">
      <ContactlessOutlinedIcon fontSize="large" />
    </IconButton>
  </>);
};

export default NFCScanner;
