import React from 'react';
import { getDocuments_documents as Document } from 'models/graphql';
import useText from 'texts/useText.hook';
import { Theme, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  id: {
    fontSize: theme.fontSizes[12],
    color: theme.brandColors.grey,
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.fontSizes[12],
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(2, 0, 1, 0),
  },
  item: {
    fontSize: theme.fontSizes[10],
    padding: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    background: theme.palette.grey[100],
    marginBottom: 2,
  },
}));

const DocumentDetails: React.FC<Document> = ({ systems, assets, file: { originalName } }) => {
  const { t } = useText('documents', 'systemTypes', 'assetTypes');
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Typography className={styles.id}>{originalName}</Typography>
      {systems && systems.length ? (
        <>
          <Typography className={styles.heading}>{t('documents')('systems')}</Typography>
          {systems.map((system) => (
            <Typography className={styles.item} key={system.id}>
              {system.name}
            </Typography>
          ))}
        </>
      ) : null}
      {assets && assets.length ? (
        <>
          <Typography className={styles.heading}>{t('documents')('assets')}</Typography>
          {assets.map((asset) => (
            <Typography className={styles.item} key={asset.id}>
              {asset.name}
            </Typography>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default DocumentDetails;
