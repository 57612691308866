import React, { useCallback, useState } from 'react';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SignaturePad from 'signature_pad';
import { useFormikContext } from 'formik';

import AppButton from 'components/AppButton';
import useText from 'texts/useText.hook';
import useFileUpload from 'utils/useFileUpload';

function dataURLToFile(dataURL: string, fileName: string): File {
  // Cast base64 dataURL to Blob
  const BASE64_MARKER = ';base64,';
  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; i += 1) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const blob = new Blob([uInt8Array], { type: contentType }) as any;

  // Cast Blob a File type
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob as File;
}

const styleRules = (theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      zIndex: 1,
      top: 0,
      height: '100%',
      left: 0,
      width: '100%',

      display: 'flex',
      flexDirection: 'column',

      background: theme.palette.background.default,
    },
    signaturePadContainer: {
      flexGrow: 1,
      maxHeight: 350,

      display: 'flex',
      flexDirection: 'column',

      padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
    },
    signaturePad: {
      flexGrow: 1,

      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
    },
    signaturePadCanvas: {
      width: '100%',
      height: '100%',
    },
    signaturePadInstructions: {
      paddingTop: theme.spacing(2),

      color: theme.palette.grey[500],
    },
    actions: {
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      padding: `${theme.spacing(3)} 0`,

      '& > :not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  });
const useStyles = makeStyles(styleRules);

const TaskAssetSignature: React.FC<{ name: string; onClose: () => void }> = ({ onClose, name }) => {
  const { setFieldValue } = useFormikContext<Record<string, unknown>>();

  const styles = useStyles();
  const { t } = useText('common', 'tasks');

  // Canvas
  const [signaturePad, setSignaturePad] = useState<SignaturePad>();
  const canvasRef = useCallback(
    (canvas: HTMLCanvasElement) => {
      const containerSize = document.getElementById('signaturePad')?.offsetHeight;
      if (!canvas || signaturePad || !containerSize) return;

      const _signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'rgb(255,255,255)',
      });
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      // eslint-disable-next-line no-param-reassign
      canvas.width = canvas.offsetWidth * ratio;
      // eslint-disable-next-line no-param-reassign
      canvas.height = containerSize > 350 ? 350 * ratio : containerSize * ratio;
      const canvasContext = canvas.getContext('2d');
      if (canvasContext) canvasContext.scale(ratio, ratio);
      _signaturePad.clear(); // otherwise isEmpty() might return incorrect value
      setSignaturePad(_signaturePad);
    },
    [signaturePad, setSignaturePad],
  );

  const fileUpload = useFileUpload();
  const [fileUploading, setFileUploading] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.signaturePadContainer}>
        <div className={styles.signaturePad} id="signaturePad">
          <canvas ref={canvasRef} className={styles.signaturePadCanvas} />
        </div>
        <div className={styles.signaturePadInstructions}>{t('tasks')('signHere')}</div>
      </div>
      <div className={styles.actions}>
        <AppButton onClick={() => onClose()} variant="outlined">
          {t('common')('cancel')}
        </AppButton>
        <AppButton
          onClick={async () => {
            setFileUploading(true);
            if (!signaturePad) return;
            const myFile = dataURLToFile(
              signaturePad.toDataURL('image/jpeg'),
              `signature-${new Date().toISOString()}.jpg`,
            );
            const { id } = await fileUpload(myFile);
            setFieldValue(name, id);
            setFileUploading(false);
            onClose();
          }}
          inProgress={fileUploading}
        >
          {t('common')('done')}
        </AppButton>
      </div>
    </div>
  );
};
export default TaskAssetSignature;
