/* eslint-disable */
import React from 'react';
import { MapContainer } from "react-leaflet";

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useSearchParams from 'utils/useSearchParams';

// For links back to previous - taking the stylings from AppTableFilters
import ListMapSwitch from '../components/ListMapSwitch';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';

// FW 2022-02-17 button icons for linking to drawings 
import AppIconButton from 'components/AppIconButton';
import drawingCompassIcon from 'assets/drawingCompassIcon.svg';

import 'leaflet/dist/leaflet.css';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import './Map.css';

import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import ComplianceView from "./ViewByCompliance";

import { useNavigate, useParams } from "react-router";
import LeafletControlGeocoder from './LeafletControlGeocoder';

const useStyles = makeStyles((theme: Theme) => ({
  iconsRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(BREAKPOINT)]: {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1, 0),
    },
  },
  actionButtons: {
    marginLeft: theme.spacing(2),
  },
}));

const defaultCenter: [number, number] = [53.15658568157794, -7.552211111356754];
const defaultZoom = 7;
const maxZoomVal = 22;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png"
});

// import marker icons
delete L.Icon.Default.prototype._getIconUrl;

const MapPage: React.FC = () => {
  const { clientId, siteId } = useParams<{ clientId: string; siteId: string }>();
  const [latParam, longParam, assetSelectParam, typeParam] = useSearchParams('lat', 'long', 'assetselect', 'type');
  const { iconsRight, actionButtons } = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={iconsRight}>
        <ListMapSwitch
          checked
          onChange={() => {
            navigate(`/client/${clientId}/assets/${siteId}`);
          }}
        />
        <AppIconButton className={actionButtons} onClick={() => navigate(`/client/${clientId}/assets/${siteId}/drawings`)}>
          <img alt="systems" src={drawingCompassIcon} />
        </AppIconButton>
      </div>

      <div className="leaflet-area-div">
        <MapContainer center={defaultCenter} zoom={defaultZoom} maxZoom={maxZoomVal}>
          <LeafletControlGeocoder clientId={clientId}/>
          <ComplianceView assetype={typeParam} clientid={clientId} siteid={siteId} assetselect={assetSelectParam} lat={latParam} lon={longParam} />
        </MapContainer>
      </div></>
  );
}
export default MapPage;