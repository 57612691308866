// Do not remove, import is used as type and needed as such
// import { Theme } from '@material-ui/core/styles/createTheme';
import { AnimationProps } from 'motion/react';
import { Theme } from '@mui/material/styles/createTheme';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    table: Palette['primary'];
  }
  interface PaletteOptions {
    table: PaletteOptions['primary'];
  }
}

// remove
declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    brandColors: {
      main: string;
      light: string;
      vivid: string;
      dark: string;
      black: string;
      grey: string;
    };
    colors: {
      yellow: string;
      orange: string;
      blue: string;
    };
    sizes: {
      drawerWidth: number;
      headerHeight: number;
      mobileHeaderHeight: number;
      defaultContainerPadding: {
        desktop: number;
        mobile: number;
      };
      button: {
        height: number;
        minWidth: number;
      };
      switch: {
        height: number;
        width: number;
      };
      containerWidth: number;
      iconNormal: number;
      iconMedium: number;
    };
    dateFormat: {
      normal: (date: Date | string) => string;
      fullMonth: (date: Date | string) => string;
      shortMonth: (date: Date | string) => string;
      time: (date: Date | string) => string;
      dayPeriod: (date: Date | string) => 'AM' | 'PM';
    };
    framerTransitions: {
      normal: AnimationProps['transition'];
      slow: AnimationProps['transition'];
    };
    fontSizes: {
      10: number;
      11: number;
      12: number;
      14: number;
      16: number;
      18: number;
      20: number;
      24: number;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    brandColors: {
      main: string;
      light: string;
      vivid: string;
      dark: string;
      black: string;
      grey: string;
    };
    colors: {
      yellow: string;
      orange: string;
      blue: string;
    };
    sizes: {
      drawerWidth: number;
      headerHeight: number;
      mobileHeaderHeight: number;
      defaultContainerPadding: {
        desktop: number;
        mobile: number;
      };
      button: {
        height: number;
        minWidth: number;
      };
      switch: {
        height: number;
        width: number;
      };
      containerWidth: number;
      iconNormal: number;
      iconMedium: number;
    };
    dateFormat: {
      normal: (date: Date | string) => string;
      fullMonth: (date: Date | string) => string;
      shortMonth: (date: Date | string) => string;
      time: (date: Date | string) => string;
      dayPeriod: (date: Date | string) => 'AM' | 'PM';
    };
    framerTransitions: {
      normal: AnimationProps['transition'];
      slow: AnimationProps['transition'];
    };
    fontSizes: {
      10: number;
      11: number;
      12: number;
      14: number;
      16: number;
      18: number;
      20: number;
      24: number;
    };
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    brandColors?: {
      main: string;
      light: string;
      vivid: string;
      dark: string;
      black: string;
      grey: string;
    };
    colors?: {
      yellow: string;
      orange: string;
      blue: string;
    };
    sizes?: {
      drawerWidth: number;
      headerHeight: number;
      mobileHeaderHeight: number;
      defaultContainerPadding: {
        desktop: number;
        mobile: number;
      };
      button: {
        height: number;
        minWidth: number;
      };
      switch: {
        height: number;
        width: number;
      };
      containerWidth: number;
      iconNormal: number;
      iconMedium: number;
    };
    dateFormat?: {
      normal: (date: Date | string) => string;
      fullMonth: (date: Date | string) => string;
      shortMonth: (date: Date | string) => string;
      time: (date: Date | string) => string;
      dayPeriod: (date: Date | string) => 'AM' | 'PM';
    };
    framerTransitions?: {
      normal: AnimationProps['transition'];
      slow: AnimationProps['transition'];
    };
    fontSizes?: {
      10: number;
      11: number;
      12: number;
      14: number;
      16: number;
      18: number;
      20: number;
      24: number;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    brandColors?: {
      main: string;
      light: string;
      vivid: string;
      dark: string;
      black: string;
      grey: string;
    };
    colors?: {
      yellow: string;
      orange: string;
      blue: string;
    };
    sizes?: {
      drawerWidth: number;
      headerHeight: number;
      mobileHeaderHeight: number;
      defaultContainerPadding: {
        desktop: number;
        mobile: number;
      };
      button: {
        height: number;
        minWidth: number;
      };
      switch: {
        height: number;
        width: number;
      };
      containerWidth: number;
      iconNormal: number;
      iconMedium: number;
    };
    dateFormat?: {
      normal: (date: Date | string) => string;
      fullMonth: (date: Date | string) => string;
      shortMonth: (date: Date | string) => string;
      time: (date: Date | string) => string;
      dayPeriod: (date: Date | string) => 'AM' | 'PM';
    };
    framerTransitions?: {
      normal: AnimationProps['transition'];
      slow: AnimationProps['transition'];
    };
    fontSizes?: {
      10: number;
      11: number;
      12: number;
      14: number;
      16: number;
      18: number;
      20: number;
      24: number;
    };
  }
}