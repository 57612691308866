import React, { Dispatch, SetStateAction, useState, useRef } from 'react';
import styleUtils from 'style/styleUtils';
import { AnimateOpacity, AnimateWidth } from 'components/animations';
import AppMenu from 'components/AppMenu';
import { ContextStore, useStores } from 'stores';
import { createStyles, makeStyles } from '@mui/styles';
import { Button, CircularProgress, MenuItem, Theme } from '@mui/material';

export type Actions = Array<{
  title: string;
  action: (setLoading: Dispatch<SetStateAction<boolean>>) => void;
}>;

const buttonSize = 40;
const stylesConfig = (theme: Theme) => {
  const buttonDotSize = 6;
  
  return createStyles({
    container: {
      marginLeft: 'auto',
      ...styleUtils.rowCenterCenter,
    },
    buttonContainer: {
      position: 'relative',
    },
    buttonLoader: {
      ...styleUtils.absoluteCenter,
    },
    button: {
      ...styleUtils.rowCenterCenter,
      padding: 0,
      minWidth: 0,
      width: buttonSize,
      height: buttonSize,
      background: theme.palette.grey['100'],
      borderRadius: '50%',
    },
    buttonDot: {
      width: buttonDotSize,
      height: buttonDotSize,
      background: theme.palette.grey['500'],
      borderRadius: '50%',
      '&:not(:first-child)': {
        marginLeft: parseInt(theme.spacing(1)) / 4,
      },
    },
  });
};
const useStyles = makeStyles(stylesConfig);

export default function ActionsButton({
  actions,
  horizontalPosition,
}: {
  actions: Actions;
  horizontalPosition?: 'left' | 'right' | 'center';
}) {
  const classes = useStyles();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { contextStore } = useStores();
 

  if (!actions.length) return <></>; // prevent displaying an empty menu
  
  return (
    <>
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <AnimateOpacity disableInitialAnimation keepInDOM visible={!loading}>
            <Button onClick={() => {contextStore.setReportViewedValue(true);setOpen(true)}} ref={buttonRef} className={classes.button}>
              <div className={classes.buttonDot} />
              <div className={classes.buttonDot} />
              <div className={classes.buttonDot} />
            </Button>
          </AnimateOpacity>
          <AnimateWidth visible={loading} className={classes.buttonLoader}>
            <CircularProgress size={buttonSize / 1.4} thickness={4} color="primary" />
          </AnimateWidth>
        </div>
      </div>

      <AppMenu
        anchorEl={buttonRef.current}
        open={open}
        horizontalPosition={horizontalPosition}
        onClose={() => setOpen(false)}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.title}
            onClick={() => {
              setOpen(false);
              action.action(setLoading);
            }}
          >
            {action.title}
          </MenuItem>
        ))}
      </AppMenu>
    </>
  );
}
