import React, { useState } from 'react';
import FileListToArrayOfFile from 'utils/FileListToArrayOfFile';
import useFileUpload from 'utils/useFileUpload';
import errorIcon from 'assets/errorIcon.svg';
import { CircularProgress, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
    },
    fakeInput: {
      position: 'relative',
    },
    inputFile: {
      width: '.1px',
      height: '.1px',
      opacity: 0,
      overflow: 'hidden',
      position: 'absolute',
      zIndex: -1,
    },
    inputFileLabel: {
      cursor: 'pointer',
    },
    fileButton: {
      position: 'relative',
      margin: `0 0 0 ${theme.spacing(1)}`,
    },
  });
});

const FileInputLink: React.FC<{
  label: string;
  inputId: string;
  onUpload: (id: string, name: string) => void;
}> = ({ label, inputId, onUpload }) => {
  // Upload process
  const fileUpload = useFileUpload();
  const [fileLoading, setFileLoading] = useState(false);
  const [error, setError] = useState(false);
  const onFileChange = async ({ target }: { target: HTMLInputElement }) => {
    if (!target.files) return;
    const filesToUpload = FileListToArrayOfFile(target.files);
    const file = filesToUpload[0];
    setError(false);
    setFileLoading(true);
    try {
      const { id, name } = await fileUpload(file);
      onUpload(id, name);
    } catch (e) {
      setError(true);
    }
    setFileLoading(false);
  };

  const styles = useStyles();

  return (
    <>
      <div className={styles.container}>
        {error ? <img alt="Error" src={errorIcon} height="15px" /> : null}
        {fileLoading ? (
          <div className={styles.fileButton}>
            <CircularProgress size={11} />
          </div>
        ) : null}
        <div className={styles.fileButton}>
          <div className={styles.fakeInput}>
            {!fileLoading && (
              <>
                <input className={styles.inputFile} type="file" id={inputId} onChange={onFileChange} />
                <label htmlFor={inputId} className={styles.inputFileLabel}>
                  {label}
                </label>
              </>
            )}
            {fileLoading && <>{label}</>}
          </div>
        </div>
      </div>
    </>
  );
};

export default FileInputLink;
