import { NotificationScheduleType, NotificationType } from 'models/graphql';

const enNotifications = {
  titles: {
    [NotificationType.CONTRACTOR_REQUESTED]: 'Contractor Access Requested',
    [NotificationType.CONTRACTOR_ACCEPTED]: 'Contractor Access Accepted',
    [NotificationType.CONTRACTOR_DENIED]: 'Contractor Access Rejected',
    [NotificationType.CONTRACTOR_USER_REQUESTED]: 'Contractor User Access Requested',
    [NotificationType.CONTRACTOR_USER_ACCEPTED]: 'Contractor User Access Accepted',
    [NotificationType.CONTRACTOR_USER_DENIED]: 'Contractor User Access Denied',
    [NotificationType.TASK_ASSIGNED_TO_INTERNAL]: 'Task Assigned',
    [NotificationType.TASK_ASSIGNED_TO_CONTRACTOR]: 'Task Assigned',
    [NotificationType.TASK_CONTRACTOR_ACCEPTED]: 'Task Accepted',
    [NotificationType.TASK_CONTRACTOR_REJECTED]: 'Task Rejected',
    [NotificationType.TASK_ASSIGNED_TO_CONTRACTOR_USER]: 'Task Assigned',
    [NotificationType.TASK_ACCEPTED]: 'Task Accepted',
    [NotificationType.TASK_REJECTED]: 'Task Rejected',
  },
  schedule: {
    [NotificationScheduleType.DAY1]: 'On day of task deadline date',
    [NotificationScheduleType.DAY3]: '3 Days before task deadline date',
    [NotificationScheduleType.NON_COMPLIANCE]: 'When a task has form that has non-compliance field activated',
    [NotificationScheduleType.WEEK1]: '1 Week before task deadline date',
  },
  justNow: 'Just now',
  minutesAgo_one: '{{count}} minute ago',
  minutesAgo_other: '{{count}} minutes ago',
  hoursAgo_one: '{{count}} hour ago',
  hoursAgo_other: '{{count}} hours ago',
  daysAgo_one: 'Yesterday',
  daysAgo_other: '{{count}} days ago'
};
export default enNotifications;
