import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';

import { getContractor, getContractorVariables } from 'models/graphql';
import { useBreadcrumb } from 'components/Breadcrumb';
import { GET_CONTRACTOR } from 'containers/shared/graphql';
import { useStores } from 'stores';
import { observer } from 'mobx-react';
import AppTabs from 'components/AppTabs';
import useCreateTabs from 'utils/useCreateTabs.hook';
import useText from 'texts/useText.hook';
import useMode, { Mode } from 'utils/useMode.hook';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    marginBottom: theme.spacing(3),
  },
}));

const ContractorDetailsContainer: React.FC<{ baseUrl: string }> = ({ children, baseUrl }) => {
  const styles = useStyles();
  const { t } = useText('contractors');
  const { mode } = useMode();
  const { contractorId } = useParams<{
    clientId: string;
    contractorId: string;
  }>();
  const { data } = useQuery<getContractor, getContractorVariables>(GET_CONTRACTOR, { variables: { id: contractorId } });
  useBreadcrumb(baseUrl, data?.contractor?.companyName || '');
  const { contextStore } = useStores();
  const isNavigationHidden = contextStore.isNavigationHidden;
  const tabs = useCreateTabs(baseUrl, [
    { name: t('contractors')('basicDetails'), relativeUrl: '' },
    mode === Mode.CLIENT ? { name: t('contractors')('accessRights'), relativeUrl: '/rights' } : undefined,
    { name: t('contractors')('users'), relativeUrl: '/users' },
  ]);

  return (
    <div className={styles.container}>
      {!isNavigationHidden ? <AppTabs tabs={tabs} marginBottom /> : null}
      <div>{children}</div>
    </div>
  );
};

export default observer(ContractorDetailsContainer);
