import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router';
import useText from 'texts/useText.hook';
import AppTable, { AppTableFilters } from 'components/AppTable';
import usePaginationParams from 'utils/usePaginationParams';    // FW Update 2022-04-11 For Asset Pagination
import {
  assetsForSite,
  assetsForSiteVariables,
  assetsForSite_site_assets as assetsForSiteSiteAssets,
  AssetType,
  SystemType,
  Role,
  getAssetFilterBySite,
  getAssetFilterBySiteVariables,
  CompliantStatus,
  assetsForSitePaged,         // FW 2022-04-10 For Assets Paged
  assetsForSitePaged_site_assets as assetsForSiteSiteAssetsPaged,   // FW 2022-04-10 For Assets Paged
  assetsForSitePagedVariables,    // FW 2022-04-10 For Assets Paged
} from 'models/graphql';
import AppCheckboxField from 'components/form/AppCheckbox';
import useSearchParams from 'utils/useSearchParams';
import useUpdateSearchParams from 'utils/useUpdateSearchParams';
import AppButtonLink from 'components/AppButtonLink';
import AssetComplianceIcon from 'components/complianceIcons/AssetComplianceIcon';
import { Typography, Theme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NFCScanner from 'components/NFCScanner';
import { ContextStore, useStores } from 'stores';
import AssetDetailsPanel from './AssetDetailsPanel';
import ListMapSwitch from './components/ListMapSwitch';

//import GET_SITE_ASSETS,  { GET_ASSET_FILTER_BY_SITE } from './graphql';
//import {GET_SITE_ASSETS_PAGED} from './graphql';     // FW 2022-04-10 For Assets Paged
// FW 2022-02-17 button icons for linking to drawings
import AppIconButton from 'components/AppIconButton';/* eslint-disable-line */
import drawingCompassIcon from 'assets/drawingCompassIcon.svg';/* eslint-disable-line */

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DrawingCompassIcon } from "assets/dashboardDrawingIconGreen.svg";
import { ReactComponent as MapIconGreen } from "assets/dashboardMapIconGreen.svg";
import { ReactComponent as ReportIconGreen } from "assets/reportIconGreen.svg";
import cn from 'utils/cn';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';   // FW Display Asset Data using MUI Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import compliantIcon from 'assets/compliantIcon.svg';
import nonCompliantIcon from 'assets/nonCompliantIcon.svg';
import ExpandedRows from "./AssetExpandDetails";
import CameraView from './CameraView';
import ReportGenerator from './ReportGenerator';
import alarmIconOrange from 'assets/alarmIconOrangeListing.svg';
import expiredIcon from 'assets/expiringIcon.svg';



const useStyles = makeStyles((theme: Theme) => ({
  flex: {
    display: 'flex',
  },
  viewSwitcher: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3), // FW 2022-02-17
  },
  createTaskLink: {
    display: 'flex',
    padding: '10px',
  },
  actionButtons: {
    // FW 2022-02-14
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  link: {
    display: 'flex'
  }
}));
interface FormValues {
  selectAll: boolean;
  assetSelections: { id: string; selected: boolean }[];
}

const columnsMUITable = [
  {
      name: "assetname",
      label: "Asset Name",
      options: {filterList: [], 
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            zIndex: 100
          }}),
        setCellHeaderProps: () => ({style: {  fontWeight:'bold', fontSize:'14px',position: "sticky", zIndex: 101 }}),
      }
  },
  {
      name: "Asset Type",
      label: "Asset Type",
      options: {
        setCellHeaderProps: () => ({style: {  fontWeight:'bold', fontSize:'14px' }}),
      }
  },
  {
      name: "System",
      label: "System Ref",
      options: {
        setCellHeaderProps: () => ({style: {  fontWeight:'bold', fontSize:'14px' }}),
      }
  },
  {
     name: "System Type",
     label: "System Type",
     options: {
       setCellHeaderProps: () => ({style: {  fontWeight:'bold', fontSize:'14px' }}),
     }
  },
 // { name: "Compliant_Display" ,  label: "Is Compliant?",  options: { display: false, viewColumns: false,}},
  { 
     name: "Compliant_Display" , 
     label: "Compliant", 
     options: {
        filter: true, 
      
        setCellProps: () => ({
          style: {
            marginLeft:"10%",
            whiteSpace: "normal",
            position: "sticky",
            zIndex: 118
          }
        }),  
        setCellHeaderProps: () => ({
          style: {
            fontWeight:'bold', fontSize:'14px'
          }
        }),
        customBodyRender: (value: string) => {
            if (value === 'Yes') {
              return (
                <> 
                  <img alt="compliant" src={compliantIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
                </>
              );
            }
            if (value === 'No') {
              return (
                <>
                  <img alt="non compliant" src={nonCompliantIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
                </>
              );
            }
            if (value === 'Expiring') {
              return (
                <>
                <img alt="expiring" src={alarmIconOrange} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
                </>
              );
            }
            if (value === 'Expired') {
              return (
                <>
                <img alt="expired" src={expiredIcon} className={cn(useStyles.icon, { [useStyles.smallIcon]: true })} />
                </>
              );
            }
            if (value === 'Undetermined') {
              return (
                <>
                
                </>
              );
            }
        }
      }
    },
]

const AssetsPage: React.FC = () => {  
  const { clientId, siteId } = useParams<{ clientId: string; siteId: string }>();
  const { t, tt } = useText('assets', 'assetTypes', 'clients', 'compliance', 'systemTypes', 'tasks', 'urls');
  const { viewSwitcher, actionButtons, link } = useStyles();  // Updated 2022-02-14 FW to add actionbuttons style
  const [search, assetParam, typeParam, systemTypeParam, systemRefsParam, ownerParam] = useSearchParams(
    tt('urls')('queries')('search'),
    tt('urls')('queries')('asset'),
    tt('urls')('queries')('type'),
    tt('urls')('queries')('systemType'),
    tt('urls')('queries')('systemRef'),
    tt('urls')('queries')('owner'),
  );
  const updateSearchParams = useUpdateSearchParams();
  const { contextStore } = useStores();
 
  contextStore.setReportViewedValue(false);

  const [rowClicked, setRowClicked] = useState(null)
  const [assets, setAssets] = useState<assetsForSiteSiteAssetsPaged | undefined>();
  const [openNFC, setOpenNFC] = React.useState(false);
  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:700px)');
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [assetsCall, setAssetsCall] = useState( [] )
  const [dataAssets, setDataAssets] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [showCamera, setShowCamera]  = React.useState(false);
  const [cols, setCols] = useState(columnsMUITable);


  const hideModal = () => {
    setShowCamera(false)
  };

  const ExtraButtonsSelect = () => (
    <>            <div className={useStyles.createTaskLink  }>
                    <AppButtonLink
                    to={{
                      pathname: `/client/${clientId}/tasks/${siteId}/add`,
                    }}
                    state={{
                      assetIds: selectedAssetIds,
                    }}
                    disabled={selectedAssetIds.length === 0}
                  >
                    {t('tasks')('createTask')}
                  </AppButtonLink></div>

    </>

    
  );

  const ExtraButtons = () => (
    <>
      <Tooltip disableFocusListener title="View Map">
        <IconButton size="large">
        <Link to={`/client/${clientId}/assets/${siteId}/mapping`} className={link}>
          <MapIconGreen />
        </Link>
        </IconButton>
      </Tooltip>

      <Tooltip disableFocusListener title="View Drawings">
        <IconButton size="large">
        <Link to={`/client/${clientId}/assets/${siteId}/drawings`} className={link}>
          <DrawingCompassIcon />
        </Link>
        </IconButton>
      </Tooltip>
      <ReportGenerator></ReportGenerator>
    </>
  );



  const getData = async () => {
    const urltogoto = 'listings/assets/'
   try {
     await fetch(`${process.env.API_ROOT}${urltogoto}${siteId}`, {
       method: 'GET',
       credentials: 'include',
     }).then(async (response) => {
          const dataret = await response.json();
          const assetData = dataret.map((u: { id: string; status: string; assetname: string; department: string; additionalinformation: any; coordinates: any; assettype: any; createdat: any; compliant: any; systemid: any; siteid: string; ownerid: string; specificinfo: any; system: string; systemtype: any; site: any; ownername: any; assettypedisplay: any; fileextension:any; pictureview:any; picture:any; compliant_display: any; compexpire: any;iotresources:any;iotsource:any,currentvalues:any}) => {
            
          return {
            "id": u.id,
            "Status": u.status,
            "assetname": u.assetname,
            "Department": u.department,
            "additionalinformation": u.additionalinformation,
            "coordinates":u.coordinates,
            "createdat": u.createdat,
            "systemid": u.systemid,
            "siteid": u.siteid,
            "ownerid": u.ownerid,
            "specificinfo": u.specificinfo,
            "site": u.site,
            "Asset Type": u.assettypedisplay,
            "System": u.system,
            "System Type": u.systemtype,
            "Compliant": u.compliant,
            "ownername": u.ownername,
            "assettype": u.assettype ,
            "fileextension": u.fileextension,
            "pictureview": u.pictureview,
            "picture": u.picture,
            "Compliant_Display": u.compliant_display,
            "compexpire": u.compexpire,
            "iotsource": u.iotsource,
            "iotresources": u.iotresources,
            "currentvalues": u.currentvalues,
         };
       });
         
       setAssetsCall(assetData);
     });
   } catch (error) {
     console.error(error); /* eslint-disable-line */
   }
}
function changeRowColorBack(){
  let row = document.getElementById(`MUIDataTableBodyRow-${rowClicked}`);
  row.setAttribute('style', 'background: white');
}

function changeRowColor(index: any){

  if (rowClicked !== null) 
    {
      let row = document.getElementById(`MUIDataTableBodyRow-${rowClicked}`);
      row.setAttribute('style', 'background: white');
    } 
    

    let row = document.getElementById(`MUIDataTableBodyRow-${index}`);
    row.setAttribute('style', 'background: #F3F4F9');
    setRowClicked(index);
 
}

const onFilter = (value: any) => {
  setSelectedFilter(value);
  const filteredCols = [...cols];
  let filterList: any[] | undefined = [];

  if (value)
  {
    filterList = [value];
  }

  filteredCols[0].options.filterList = filterList;

}

/*
  const columnsMUITable = [
    { name: "Asset Name", options: {
      filter: true,
      setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          left: "0",
          background: "white",
          zIndex: 100,
          width: '50%',
        }
      }),
      setCellHeaderProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          left: 0,
          fontWeight:'bold', fontSize:'14px',
          background: "white",
          zIndex: 101,
          width: '50%',
        }
      })
    }
    },
    { name: "Asset Type", options: { filterList: [],  setCellProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: "0",
        background: "white",
 
        zIndex: 106,
        width: '50%',
      }
    }),
    setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        left: 0,
        background: "white",
        fontWeight:'bold', fontSize:'14px',
        zIndex: 107,
        width: '50%',
      }
    })} }]; */

   

    const tableoptions = {
      rowsPerPage: 25,
      rowsPerPageOptions: [25, 50, 100, 500],
      filter: true,
      filterType: 'multiselect',
      customToolbar: ExtraButtons, 
      customToolbarSelect: ExtraButtonsSelect, 
      print: false,
      selectableRows: "multiple",
      fixedHeader: true,
	    tableBodyHeight: "700px",
      expandableRows: true,
      onDownload: (buildHead, buildBody, columns, data) => {
        // call sepearate routine to export data
        const curtime = new Date();
        // create filename for file
        const yyyy = curtime.getFullYear();
        const mm = curtime.getMonth() < 9 ? "0" + (curtime.getMonth() + 1) : (curtime.getMonth() + 1); // getMonth() is zero-based
        const dd  = curtime.getDate() < 10 ? "0" + curtime.getDate() : curtime.getDate();
        const hh = curtime.getHours() < 10 ? "0" + curtime.getHours() : curtime.getHours();
        const mmm  = curtime.getMinutes() < 10 ? "0" + curtime.getMinutes() : curtime.getMinutes();
        const ss  = curtime.getSeconds() < 10 ? "0" + curtime.getSeconds() : curtime.getSeconds();
        
        const ncfile = "assets_"+yyyy+mm+dd+"_"+hh+mmm+ss+".xlsx";
      
        const urltogoto =   "listings/assetreport?site=" + siteId;
        // Fetch the task types for the selected asset
        fetch(`${process.env.API_ROOT}${urltogoto}`,  {
          method: 'GET',
          credentials: 'include',
        }).then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = ncfile;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again 
  
        });
        return false;
      },
      renderExpandableRow:  (rowData: any, rowMeta: any) => {
     
       // changeRowColor(rowMeta.dataIndex);
        return <ExpandedRows index={assetsCall[rowMeta.dataIndex]}  />;

      },
      onRowExpansionChange: (curExpanded, allExpanded) => { 
        if (allExpanded.length === 0)
        {
       //   changeRowColorBack();
        } 
      },
      onRowClick: (rowData: any, rowIndex: any) => {
//changeRowColorBack(rowIndex.rowIndex);
      },
      onRowSelectionChange:(currentRowsSelected: any, allRowsSelected: any, rowsSelected: any) => {   
        /*let arr = [...selectedAssetIds]; //copy array by value
        if (currentRowsSelected.length > 0)
        {
          arr.push(assetsCall[currentRowsSelected[0].dataIndex].id)
          setSelectedAssetIds(arr) //override state with new values
        } */
        let temp: never[] = [];
        allRowsSelected.forEach((row: any) => {
          temp.push(assetsCall[row.dataIndex].id);

      });

      setSelectedAssetIds(temp)

        },
        textLabels: {
          selectedRows: {
            text: "asset(s) selected",
        },
      }
    };

    useEffect(() => {
      getData();
    }, [siteId]);
  useEffect(() => {
    getData();
    //setAssets(data?.site.pageassets);
    // set the filter on the asset
      onFilter(search);

  }, []) ;   // [data?.site.pageassets]);



  return (
    <>
   

   
    <div>
    <MUIDataTable  title={"Assets"}  data={assetsCall}  columns={columnsMUITable} options={tableoptions}/>
    </div>
    </>
  );
};
export default AssetsPage;
