import { Dispatch, SetStateAction, useState } from 'react';

export default function useArrayOfObjectsState<T extends { id: string | number }>(
  initialState: Array<T> = [],
): [T[], (item: T) => void, (itemToRemoveId: string) => void, Dispatch<SetStateAction<T[]>>] {
  const [array, setArray] = useState<Array<T>>(initialState);

  const pushToArray = (item: T) => {
    if (array.find(({ id }) => id === item.id)) {
      return;
    }

    setArray((prev) => [...prev, item]);
  };

  const removeFromArray = (itemToRemoveId: string) => {
    setArray(array.filter((item) => item.id !== itemToRemoveId));
  };

  return [array, pushToArray, removeFromArray, setArray];
}
