import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import downloadExcelIcon from 'assets/downloadExcelTemplateGreen.svg';
import downloadExcelIconDisabled from 'assets/downloadExcelTemplate.svg';
import downloadPdfIcon from 'assets/downloadPdfTemplate.svg';
import downloadPdfTemplateDisabled from 'assets/downloadPdfTemplateDisabled.svg';
import AppButton from 'components/AppButton/AppButton';
import React from 'react';
import cn from 'utils/cn';

const styles = () =>
  createStyles({
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& > button': {
        width: 'min-content',
        minWidth: 'min-content',
        borderRadius: '10px',
        borderColor: '#E5E5E5 !important',
      },
    },
    downloadExcelButton: {
      padding: '8px',
      marginRight: '8px',
      '& > span': {
        width: '22px',
        height: '24px',
      },
    },
    downloadPdfButton: {
      padding: '2px',
      '&:hover': {
        backgroundColor: '#FDEEEC',
      },
      '& > span': {
        width: '34px',
        height: '34px',
      },
    },
    disabledBtn: {
      '& > span': {
        width: '32px',
        height: '32px',
        opacity: 0.7,
        padding: 4,
      },
    },
  });
const useStyles = makeStyles(styles);

interface DownloadUploadButtonsProps {
  handleExcelDownload: () => void;
  handlePdfDownload?: () => void;
  isExcelInProgress?: boolean;
  isPDFInProgress?: boolean;
  isButtonsDisable?: boolean;
  isAllowPdfReport?: boolean;
}

const DownloadUploadButtons: React.FC<DownloadUploadButtonsProps> = (props) => {
  const {
    handleExcelDownload,
    handlePdfDownload,
    isExcelInProgress,
    isButtonsDisable,
    isPDFInProgress,
    isAllowPdfReport,
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.buttonsWrapper}>
      <AppButton
        inProgress={!isButtonsDisable && isExcelInProgress}
        disabled={isButtonsDisable}
        type="button"
        variant="outlined"
        disableFocusRipple
        disableRipple
        className={classes.downloadExcelButton}
        onClick={handleExcelDownload}
      >
        {!isExcelInProgress && (
          <img src={isButtonsDisable ? downloadExcelIconDisabled : downloadExcelIcon} alt="Download excel" />
        )}
      </AppButton>
      <AppButton
        inProgress={!isButtonsDisable && isAllowPdfReport && isPDFInProgress}
        disabled={isButtonsDisable || !isAllowPdfReport}
        type="button"
        variant="outlined"
        disableFocusRipple
        disableRipple
        className={cn(classes.downloadPdfButton, isButtonsDisable || !isAllowPdfReport ? classes.disabledBtn : '')}
        onClick={handlePdfDownload}
      >
        {!isPDFInProgress && (
          <img src={isButtonsDisable ? downloadPdfTemplateDisabled : downloadPdfIcon} alt="Download PDF" />
        )}
      </AppButton>
    </Box>
  );
};

export default DownloadUploadButtons;
