// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sent{
  border:2px solid #42cecb;
  color: #42cecb;
  background: #42cecb;
}
.sent .gantt_task_progress{
  background: #0f7a79;
}

.project{
  border:2px solid #cac8da;
  color: #cac8da;
  background: #cac8da;
}
.project .gantt_task_progress{
  background: #d6dac8;
}

.in_progress{
  border:2px solid #42cecb;
  color:#42cecb;
  background: #42cecb;
}
.in_progress .gantt_task_progress{
  background: #17f317;
}

.completed{
  border:2px solid #2ad816;
  color:#2ad816;
  background: #2ad816;
}
.completed .gantt_task_progress{
  background: #2ad816;
}

.weekend {
  background: #f4f7f4;
}

.gantt_selected .weekend {
  background: #f7eb91;
}
.gantt_tooltip {
  font-size: 13px;
  line-height: 16px;
}

.red .gantt_cell, .odd.red .gantt_cell,
		.red .gantt_task_cell, .odd.red .gantt_task_cell {
			background-color: #FDE0E0;
}

.green .gantt_cell, .odd.green .gantt_cell,
		.green .gantt_task_cell, .odd.green .gantt_task_cell {
			background-color: #BEE4BE;
}`, "",{"version":3,"sources":["webpack://./src/components/Gantt/customstyles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;;GAEG,yBAAyB;AAC5B;;AAEA;;GAEG,yBAAyB;AAC5B","sourcesContent":[".sent{\n  border:2px solid #42cecb;\n  color: #42cecb;\n  background: #42cecb;\n}\n.sent .gantt_task_progress{\n  background: #0f7a79;\n}\n\n.project{\n  border:2px solid #cac8da;\n  color: #cac8da;\n  background: #cac8da;\n}\n.project .gantt_task_progress{\n  background: #d6dac8;\n}\n\n.in_progress{\n  border:2px solid #42cecb;\n  color:#42cecb;\n  background: #42cecb;\n}\n.in_progress .gantt_task_progress{\n  background: #17f317;\n}\n\n.completed{\n  border:2px solid #2ad816;\n  color:#2ad816;\n  background: #2ad816;\n}\n.completed .gantt_task_progress{\n  background: #2ad816;\n}\n\n.weekend {\n  background: #f4f7f4;\n}\n\n.gantt_selected .weekend {\n  background: #f7eb91;\n}\n.gantt_tooltip {\n  font-size: 13px;\n  line-height: 16px;\n}\n\n.red .gantt_cell, .odd.red .gantt_cell,\n\t\t.red .gantt_task_cell, .odd.red .gantt_task_cell {\n\t\t\tbackground-color: #FDE0E0;\n}\n\n.green .gantt_cell, .odd.green .gantt_cell,\n\t\t.green .gantt_task_cell, .odd.green .gantt_task_cell {\n\t\t\tbackground-color: #BEE4BE;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
