import React from 'react';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import Breadcrumb from 'components/Breadcrumb';
import ClientDetailsRoutes from 'containers/shared/ClientConfiguration';
import ClientCreatePage from './ClientCreatePage';
import ClientsPage from './ClientsPage';
import { Route, Routes } from 'react-router';

const ClientsRoutes: React.FC = () => (
  <DefaultErrorBoundary>
    <Breadcrumb to={'/pm/clients/'} name="Clients" />
    <Routes>
      <Route path={`add`} element={<ClientCreatePage />} />
      <Route path={`:clientId/edit`} element={<ClientCreatePage />} />
      <Route path={`:clientId`}>
        <Route index element={<ClientDetailsRoutes />} />
        <Route path={`*`} element={<ClientDetailsRoutes />} />
      </Route>
      <Route path={`/`} element={<ClientsPage />} />
    </Routes>
  </DefaultErrorBoundary>
);

export default ClientsRoutes;
