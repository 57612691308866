import { AssetType, TaskType } from 'models/graphql';

const assetAvailableTaskTypes: { [Key in AssetType]: TaskType[] } = {
  [AssetType.AIR_MONITOR]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.BIOFILTER]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.BUNDS]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.DRAIN]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.GENERAL_FACILITY]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.GREASE_TRAP]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.GROUND_WATER]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.GULLEY]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.INTERCEPTOR]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.MANHOLE]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.MISCELLANEOUS]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.NOISE_MONITOR]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.ODOUR_MONITOR]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.PUMP]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.SAMPLING_POINT]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.SURFACE_WATER]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.EQUIPMENT_VEHICLES]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.PEST_CONTROL_POINT]: [
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.TANKS]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.PITS]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
  [AssetType.FIRE_EQUIPMENT]: [
    TaskType.AUDIT,
    TaskType.COMPLIANCE_CHECK,
    TaskType.TESTING,
    TaskType.REPAIR,
    TaskType.SAMPLING,
    TaskType.OTHER,
    TaskType.SURVEY,
    TaskType.TOOLBOX_TALK,
    TaskType.HEALTH_AND_SAFETY,
    TaskType.CLEANING,
    TaskType.PEST_CONTROL,
    TaskType.REPORT,
  ],
};

export default assetAvailableTaskTypes;