import React from 'react';

import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import TasksPage from 'containers/Client/Tasks/TasksPage';
import TaskCreatePage from 'containers/Client/Tasks/TaskCreatePage';
import useText from 'texts/useText.hook';
import TaskReportPage from 'containers/shared/Tasks/TaskReportPage';
import TaskAssetFormPage from 'containers/shared/Technician/TaskDetails/TaskAssetFormPage';
import SitesNotFoundOrLoading from 'components/SitesNotFoundOrLoading';
import useSites from '../useSites';
import { Route, Routes, useLocation } from 'react-router';
import { adaptPath } from 'utils/adaptPath';

const TaskRoutes: React.FC = () => {
  const { pathname } = useLocation();  
  const updatedUrl =  adaptPath(pathname, 'tasks')
  
  const { current, renderBreadcrumb } = useSites(updatedUrl);
  const { t } = useText('tasks');
  return current ? (
    <DefaultErrorBoundary>
      {renderBreadcrumb(t('tasks')('tasksTitle'))}

      <Routes>
        <Route path={`add`} element={<TaskCreatePage />} />
        <Route path={`:taskId/edit`} element={<TaskCreatePage />} />
        <Route path={`:taskId/corrective`} element={<TaskCreatePage />} />
        <Route path={`:taskId/duplicate`} element={<TaskCreatePage />} />
        <Route path={`:taskId/report`} element={<TaskReportPage />} />
        <Route path={`:taskId/:taskAssetId/form`} element={<TaskAssetFormPage />} />
        <Route path={`/`} element={<TasksPage />} />
      </Routes>
    </DefaultErrorBoundary>
  ) : (
    <SitesNotFoundOrLoading path={'/'} name="Tasks" />
  );
};

export default TaskRoutes;
