import React, { useEffect, useState } from "react";
import useMode, { Mode } from 'utils/useMode.hook';
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { alpha } from '@mui/material/styles';
import { Box, Theme, useMediaQuery, useTheme, Button, Switch, TableBody } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import useText from 'texts/useText.hook';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import StatusBadge, { StatusBadgeState } from 'components//StatusBadge';      // Keep separate for moment
import { TaskStatus } from 'models/graphql';
import moment from 'moment';
import compliantIcon from 'assets/compliantIcon.svg';
import nonCompliantIcon from 'assets/nonCompliantIcon.svg';
import assetLink from 'assets/assetLink.svg';
import cn from 'utils/cn';
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as EditIcon } from "assets/editIcon.svg";
import { ReactComponent as TrashIcon } from "assets/trashIcon.svg";
import ViewProjectTask from './ViewProjectTask'
import { useParams } from "react-router";


const buttonSize = 40;
const buttonDotSize = 6;
const useStyles = makeStyles((theme: Theme) => ({
  profileDataContainer: {
    padding: theme.spacing(3),
  },
  ownerContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 5fr',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  taskallButton: {
    backgroundColor: "#8c8484",
    color: "white",
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  buttonContainer: {
    position: 'relative',
  },
  button: {
    padding: 0,
    minWidth: 0,
    width: buttonSize,
    height: buttonSize,
    background: theme.palette.grey['100'],
    borderRadius: '50%',
  },
  iotIcon: {
    marginLeft: 'auto',
  },
  linkIcon: {
    marginLeft: '3%',
  },
}));


const UpcomingSwitch = withStyles({
  switchBase: {
    color: '#9D9D9C',
    '&$checked': {
      color: '#44924D',
    },
    '&$checked + $track': {
      backgroundColor: '#44924D',
    },
  },
  checked: {},
  track: {},
})(Switch);


export default function ExpandedRows(props: any) {
  const [tasksCall, setTasksCall] = useState( [] )
  const styles = useStyles();
  const theme = useTheme();
  const { siteId, clientId } = useParams<{ siteId: string; clientId: string }>();
  const [linkedAssetsToShow, setLinkedAssetoShow] = useState(false )

  const tasktitle = 'Tasks';

  const [tasksTitleDisplay, setTitleDisplay] = useState( tasktitle )
  const [tasksToDisplay, setTasksToDisplay] = useState( 0 )
  const [tasksButtonText, seTasksButtonText] = useState( 'All Tasks' )
  const [showLinkedAssets, setShowLinkedAssets] = useState( false )
  const [selectedTask, setSelectedTask] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  
let columnsMUITable = [
  {
      name: "idreadable",
      label: "Task",
      options: { 
        setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          left: "0",
          background: "white",
          zIndex: 100
        }}),
        setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px',position: "sticky", zIndex: 101  }}),
        customBodyRender: (value, tableMeta, updateValue) => {
          let tasklink = '/client/' + clientId + '/tasks/' + siteId + '?search=' +value;
          return (
            <a href={tasklink}>{value}</a>
          );
        }
      }
  },
  {
      name: "tasktitle",
      label: "Task Title",
      options: {
        setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
        
      }
  },
  {
      name: "status",
      label: "Status",
      options: {
        setCellProps: () => ({ style: { minWidth: "50%", maxWidth: "50%" }}),
        setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px', alignItems: 'center', textAlign: 'center' }}),
        customBodyRender: (statusvalue: string) => {
          let state: StatusBadgeState;
      
          if (statusvalue.toUpperCase() === TaskStatus.SENT ||statusvalue.toUpperCase()  === TaskStatus.REVIEW_REQ_SENT) {
            state = { background: alpha(theme.colors.yellow, 0.25) };
          } else if (
            statusvalue.toUpperCase()  === TaskStatus.CONTRACTOR_ACCEPTED ||
            statusvalue.toUpperCase()  === TaskStatus.CONTRACTOR_REJECTED ||
            statusvalue.toUpperCase()  === TaskStatus.IN_PROGRESS ||
            statusvalue.toUpperCase()  === TaskStatus.COMPLETED ||
            statusvalue.toUpperCase()  === TaskStatus.SUPP_INFO_SUBMITTED
          ) {
            state = { background: alpha(theme.colors.orange, 0.25) };
          } else if (
            statusvalue.toUpperCase()  === TaskStatus.REVIEW_REQ_REJECTED ||
            statusvalue.toUpperCase()  === TaskStatus.REVIEW_REQ_ACCEPTED ||
            statusvalue.toUpperCase()  === TaskStatus.CONSULTANT_REJECTED ||
            statusvalue.toUpperCase()  === TaskStatus.CONSULTANT_APPROVED
          ) {
            state = { background: alpha(theme.colors.blue, 0.25) };
          } else if (statusvalue.toUpperCase()  === TaskStatus.REJECTED_CLOSED || statusvalue.toUpperCase()  === TaskStatus.REJECTED) {
            state = { background: alpha(theme.palette.error.main, 0.25) };
          } else if (statusvalue.toUpperCase()  === TaskStatus.APPROVED_CLOSED) {
            state = { background: alpha(theme.palette.primary.main, 0.25) };
          } else if (statusvalue.toUpperCase()  === TaskStatus.CANCELLED) {
            state = { background: alpha('#000000', 0.12) };
          } else {
            state = { background: 'transparent' };
          }
        
          return (
            (<StatusBadge block state={state}>
              {statusvalue.replace(/_+/g, ' ')}
            </StatusBadge>)
          );


        }
      }
  },
  {
      name: "tasktype",
      label: "Type",
      options: {
        setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
      }
  },
  {
    name: "deadline",
    label: "Start Date",
    options: {
      setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
      sort: true,
      customBodyRender: (value: string | number | Date) =>
      moment(new Date(value)).format('DD/MM/YYYY')
    }
  },
  {
     name: "deadline",
     label: "Deadline",
     options: {
       setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
       sort: true,
       customBodyRender: (value: string | number | Date) =>
       moment(new Date(value)).format('DD/MM/YYYY')
     }
  },
  {
    name: "internalresponsible",
    label: "Responsible",
    options: {
      setCellHeaderProps: () => ({style: {  fontWeight: 'bold', fontSize: '14px' }}),
    },
  },
  {
    name: "Action",
    label: " ", 
    options: {
      filter: false,
      viewColumns: false,
      customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
        return (
          (<div>
            <IconButton
              onClick={() => {
                      console.log(tasksCall[dataIndex])
                      setSelectedTask(tasksCall[dataIndex])
                      setConfirmOpen(true)
                      }}
              size="large">
                                      <EditIcon />
                      </IconButton>
            <IconButton onClick={() => handleDeleteTask(tasksCall[dataIndex])} size="large">
				                    <TrashIcon />
				    </IconButton>
          </div>)
        );
      },
    },
  },
 
]

const handleEdit = (cmeasureentry: any) => {
  // Handle edit logic
  console.log('Edit row with ID:', cmeasureentry.id);
  //setCMeasureEntry(cmeasureentry)
 // setEditOpen(true);
};


const handleDeleteTask = (selproject: any) => {
  // Handle delete logic
  console.log('Delete Task:', selproject.projectname);


  const isConfirmed = window.confirm(`Are you sure you want to remove task "${selproject.tasktitle}" from this project? Warning: This cannot be undone and the task will have to be re-added through the task edit screen.`);
  const cid = selproject.id;
  console.log("cid:", cid)
  // If the user confirms, call the onDelete function
  if (isConfirmed) {
   console.log("DELETE")

   let updCmeasure = `projects/taskremove/${cid}`
   try {
     fetch(`${process.env.API_ROOT}${updCmeasure}`, {
       method: 'DELETE',
       credentials: 'include',
     }).then(async (response:any) => {
      
         // refresh the list
         getAppTasks();


     });
     }  
     catch (error) {
       console.error(error); /* eslint-disable-line */
     }

  }
};


  const getMuiTheme = () => {
    return createTheme({
      components: {
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#bce0d5"
            },
            actions: {
              backgroundColor: "#bce0d5"
            }
          }
        }
      }
    })
  };


  const changeTaskView = () => {
   
    if (tasksToDisplay === 1)
    {
      setTasksToDisplay(0)
    }
    else
    {
      setTasksToDisplay(1)
    }

  }



  const getAppTasks = async () => {
    const urltogoto = 'projects/projectasks/';
    let fulllink=`${urltogoto}${props.index.id}`;


    try {
      fetch(`${process.env.API_ROOT}${fulllink}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
          const dataret = await response.json();
          const taskData = dataret.map((u: { taskid: string; status: string; tasktitle: string; idreadable: string; compliant: any; tasktype: any; internalresponsible: any; deadline: any; documentdeadline: any; }) => {
            return {
              "taskid": u.taskid,
              "status": u.status,
              "tasktitle": u.tasktitle,
              "idreadable": u.idreadable,
              "compliant": u.compliant,
              "tasktype":u.tasktype,
              "internalresponsible": u.internalresponsible,
              "deadline": u.deadline,
              "documentdeadline": u.documentdeadline,
            };  
          });
          setTasksCall(dataret);
          
        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }
  }


  const tableoptions = {
    filter: false,
    print: false,
    selectableRows: 'none',
    search: false,
    download: false,
    viewColumns: false,
    tableBodyHeight: "300px",
    fixedHeader: true,
    jumpToPage: false
  };
  
  useEffect(() => {
    getAppTasks();
  }, [tasksToDisplay]);


  useEffect(() => {
    getApLinkedAssets();
  }, [showLinkedAssets]);


  const getApLinkedAssets = async () => {

    if (showLinkedAssets)
    {

      const urltogoto = 'listings/assetlink/';
      let fulllink=`${urltogoto}${props.index.id}`;
  
      // fetch linked assets
      try {
        fetch(`${process.env.API_ROOT}${fulllink}`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
            const dataret = await response.json();
           
            const taskData = dataret.map((u: { id: string; name: string; compliant: any; }) => {
              return {
                "id": u.id,
                "name": u.name,
                "compliant": u.compliant,
              };  
              
            });
            console.log("taskData  for linked assets:", taskData)
            setTasksCall(dataret);
    
           
          });
        } catch (error) {
          console.error(error); /* eslint-disable-line */
        }
    }
  }

  useEffect(() => {
    // Fetch data

    const urltogoto = 'projects/projectasks/'
    
    try {
      fetch(`${process.env.API_ROOT}${urltogoto}${props.index.id}`, {
        method: 'GET',
        credentials: 'include',
      }).then(async (response) => {
          const dataret = await response.json();

          const taskData = dataret.map((u: { taskid: string; status: string; tasktitle: string; idreadable: string; compliant: any; tasktype: any; internalresponsible: any; deadline: any; documentdeadline: any; }) => {
            return {
              "taskid": u.taskid,
              "status": u.status,
              "tasktitle": u.tasktitle,
              "idreadable": u.idreadable,
              "compliant": u.compliant,
              "tasktype":u.tasktype,
              "internalresponsible": u.internalresponsible,
              "deadline": u.deadline,
              "documentdeadline": u.documentdeadline,
            };  
          });
          setTasksCall(dataret);
         




        });
      } catch (error) {
        console.error(error); /* eslint-disable-line */
      }

    
     



  }, []);


  return <>
    <React.Fragment>
      <tr>
        <td colSpan={7} >
          <Table style={{ minWidth: "650" }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>
                  <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable title={tasksTitleDisplay} data={tasksCall} columns={columnsMUITable} options={tableoptions} />
                  </ThemeProvider>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </td>
      </tr>
    </React.Fragment>
    <tr>
      <td>
      <ViewProjectTask
          content={selectedTask}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onClose={() => setConfirmOpen(false)}
          title="Details" />
      </td>
    </tr>
  </>;
  
 }

