import { IconButton, Snackbar } from '@mui/material';
import React from 'react';
import { ContextStore, withStores } from 'stores';
import {Close} from '@mui/icons-material';
export interface FlashMessageComponentProps {
  contextStore: ContextStore;
}

const FlashMessageComponent: React.FC<FlashMessageComponentProps> = ({ contextStore }) => (
  <>
    {contextStore.flashMessages.map((message) => (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        key={message.id}
        open={message.open}
        autoHideDuration={message.duration}
        onClose={message.close}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        TransitionProps={{
          onExited: () => contextStore.removeMessage(message.id)
        }}
        message={message.content}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={message.close}
            size="large">
            <Close />
          </IconButton>,
        ]}
      />
    ))}
  </>
);

export default withStores('contextStore')(FlashMessageComponent);
