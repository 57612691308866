import { Menu, MenuProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import cn from 'utils/cn';

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    '& .MuiMenu-paper': {
      borderRadius: theme.shape.borderRadius * 2,
      border: `solid 1px ${theme.palette.grey['300']}`,
      boxShadow: theme.shadows[2],
    },
  },
}));

const AppMenu: React.FC<
  MenuProps & {
    anchorEl: MenuProps['anchorEl'];
    onClose: MenuProps['onClose'];
    horizontalPosition?: 'left' | 'right' | 'center';
  }
> = ({ children, className, horizontalPosition, ...forwardMenuProps }) => {
  const styles = useStyles();

  return (
    <Menu
      anchorOrigin={{
        horizontal: horizontalPosition || 'right',
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: horizontalPosition || 'right',
        vertical: 'top',
      }}
      className={cn(styles.menu, className)}
      {...forwardMenuProps}
    >
      {children}
    </Menu>
  );
};

export default AppMenu;
