import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  getClient,
  getClientVariables,
  clientUpdate as clientUpdateGQL,
  clientUpdateVariables,
  clientCreateVariables,
  clientCreate,
} from 'models/graphql';
import FileInput from 'components/FileInput';
import Yup from 'utils/yup';
import { Formik } from 'formik';
import { AppFormControlLabel, AppForm, AppTextField, AppSwitch } from 'components/form';
import { FullScreenPage, FormLayout } from 'components/layout';
import { apolloCacheEvictQuery } from 'utils/apolloCacheEvict';
import { GET_CLIENT, UPDATE_CLIENT, CREATE_CLIENT } from './graphql';
import { useNavigate, useParams } from 'react-router';
import useText from 'texts/useText.hook';
import i18next from 'i18next';

// TODO: add EDAC Project Manager
interface FormValues {
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  requires2Factor: boolean;
  logoId?: string;
}
interface ValidationSchema {
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  requires2Factor: boolean;
}

const validationSchema: Yup.ObjectSchema<ValidationSchema> = Yup.object().shape({
  companyName: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('clients:companyName')
    })
  ),
  contactName: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('clients:contactName')
    })
  ),
  contactEmail: Yup.string().email().required(
    i18next.t('common:required', {
      field: i18next.t('clients:contactEmail')
    })
  ),
  contactPhone: Yup.string().required(
    i18next.t('common:required', {
      field: i18next.t('clients:contactPhone')
    })
  ),
  requires2Factor: Yup.bool().required(),
});

const ClientCreatePage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const navigate = useNavigate();
  const { t } = useText('clients', 'common');

  const mode = clientId ? 'update' : 'create';

  const { data: clientQueryData, loading: clientQueryLoading } = useQuery<getClient, getClientVariables>(GET_CLIENT, {
    skip: mode === 'create',
    variables: {
      id: clientId,
    },
  });

  const [clientUpdate] = useMutation<clientUpdateGQL, clientUpdateVariables>(UPDATE_CLIENT);

  const [create] = useMutation<clientCreate, clientCreateVariables>(CREATE_CLIENT, {
    update: (cache) => apolloCacheEvictQuery({ cache, query: 'clients' }),
  });

  return (
    <FullScreenPage
      title={`${mode === 'update' ? 'Edit' : 'Add'} Client`}
      loading={mode === 'update' && clientQueryLoading}
    >
      <Formik<FormValues>
        initialValues={{
          companyName: clientQueryData?.client.companyName || '',
          contactName: clientQueryData?.client.contactName || '',
          contactEmail: clientQueryData?.client.contactEmail || '',
          contactPhone: clientQueryData?.client.contactPhone || '',
          requires2Factor: clientQueryData?.client.twoFactorAuth || false,
          logoId: clientQueryData?.client.logo?.id,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (mode === 'update') {
            await clientUpdate({
              variables: {
                id: clientId,
                changes: {
                  companyName: values.companyName,
                  contactEmail: values.contactEmail,
                  contactName: values.contactName,
                  contactPhone: values.contactPhone,
                  twoFactorAuth: values.requires2Factor,
                  logoId: values.logoId,
                },
              },
            });
            navigate(`/pm/clients/${clientId}`);
          } else {
            await create({
              variables: {
                client: {
                  companyName: values.companyName,
                  contactEmail: values.contactEmail,
                  contactName: values.contactName,
                  contactPhone: values.contactPhone,
                  twoFactorAuth: values.requires2Factor,
                  logoId: values.logoId,
                },
              },
            });
            navigate('/pm/clients');
          }
        }}
      >
        {({ isSubmitting, setFieldValue, handleSubmit, values }) => (
          <AppForm handleSubmit={handleSubmit}>
            <FormLayout
              isSubmitting={isSubmitting}
              parentHref="/clients"
              sidebar={
                <FileInput
                  buttonName={mode === 'update' ? 'Update' : 'Upload'}
                  initialSrc={clientQueryData?.client.logo?.srcUrl}
                  onUpload={(id: string) => {
                    setFieldValue('logoId', id);
                  }}
                  accept="image/*"
                  label="Logo"
                  publicAccess
                />
              }
            >
              <AppTextField label={t('clients')('companyName')} name="companyName" type="text" autoFocus required />
              <AppTextField label={t('clients')('contactName')} name="contactName" type="text" required />
              <AppTextField label={t('clients')('contactEmail')} name="contactEmail" type="email" required />
              <AppTextField label={t('clients')('contactPhone')} name="contactPhone" type="text" required />
              <AppFormControlLabel
                control={<AppSwitch name={t('clients')('requires2Factor')} checked={values.requires2Factor} />}
                labelPlacement="start"
                label="Client requires 2 factor authentication"
              />
            </FormLayout>
          </AppForm>
        )}
      </Formik>
    </FullScreenPage>
  );
};

export default ClientCreatePage;
