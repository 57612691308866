import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  userUpdateStatus,
  userUpdateStatusVariables,
  contractorUsers,
  contractorUsersVariables,
  Role,
  ContractorRequestAccessStatus,
  userRemoveRequest,
  userRemoveRequestVariables,
  userRequest,
  userRequestVariables,
} from 'models/graphql';
import { UPDATE_USER_STATUS, REMOVE_USER_REQUEST, REQUEST_USER, GET_CONTRACTOR_USERS } from 'containers/shared/graphql';
import AppTable, { AppTableStatus, AppTableFilters } from 'components/AppTable';
import AppButtonLink from 'components/AppButtonLink';
import switcher from 'utils/switcher';
import ActionsButton, { Actions } from 'components/ActionsButton';
import UpdateRequestDialog from 'containers/Pm/Contractors/components/UpdateRequestDialog';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import { useStores } from 'stores';
import useMode, { Mode } from 'utils/useMode.hook';
import { useNavigate, useParams } from 'react-router';

type User = contractorUsers['contractor']['users'][number];

interface UpdateRequestDialogData {
  type: 'request' | 'remove';
  userId: string;
  userName: string;
  alreadyRequestedClientIds: string[];
}

const ContractorUsersPage: React.FC = () => {
  const { contractorId } = useParams<{ contractorId: string }>();

  const [updateStatus] = useMutation<userUpdateStatus, userUpdateStatusVariables>(UPDATE_USER_STATUS);

  const { loading: contractorDataLoading, data: contractorData, refetch: refetchUsers } = useQuery<
    contractorUsers,
    contractorUsersVariables
  >(GET_CONTRACTOR_USERS, {
    variables: {
      id: contractorId,
    },
  });
  const users = contractorData?.contractor.users;
  const contractorAcceptedRequests = contractorData?.contractor.requests.filter(
    (request) => request.accessStatus === ContractorRequestAccessStatus.ACCEPTED,
  );

  const [requestAccessDialogData, setRequestAccessDialogData] = useState<UpdateRequestDialogData>();
  const [requestAccessDialogOpen, setRequestAccessDialogOpen] = useState(false);
  const openRequestAccessDialog = (data: UpdateRequestDialogData) => {
    setRequestAccessDialogData(data);
    setRequestAccessDialogOpen(true);
  };
  const closeRequestAccessDialog = () => setRequestAccessDialogOpen(false);

  const [requestAccess] = useMutation<userRequest, userRequestVariables>(REQUEST_USER, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Contractor',
        id: contractorId,
        fieldName: 'requests',
      }),
  });

  const [removeAccess] = useMutation<userRemoveRequest, userRemoveRequestVariables>(REMOVE_USER_REQUEST, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Contractor',
        id: contractorId,
        fieldName: 'requests',
      }),
  });

  const navigate = useNavigate();
  const { authStore } = useStores();
  const mode = useMode();
  const userEditor = mode.mode === Mode.PM || (mode.mode === Mode.CONTRACTOR && authStore.user?.isAdmin);

  const renderStatus = (row: User) => <AppTableStatus isArchived={!row.active} />;
  return (
    <>
      <AppTableFilters
        searchPlaceholder="Search by user name"
        addNewButton={
          userEditor ? (
            <AppButtonLink to="add" type="button" color="primary">
              Add Contractor User
            </AppButtonLink>
          ) : undefined
        }
        onSubmit={async ({ search, showArchived }) => {
          await refetchUsers({
            id: contractorId,
            search: search || undefined,
            archived: showArchived || undefined,
          });
        }}
      />

      <AppTable
        data={users}
        loading={contractorDataLoading}
        noDataMessage="No Users"
        columns={[
          {
            key: 'name',
            name: 'Name',
            template: (row) => row.name,
          },
          {
            key: 'email',
            name: 'Email',
            template: (row) => row.email,
          },
          {
            key: 'phonenumber',
            name: 'Phone',
            template: (row) => row.phonenumber,
          },
          {
            key: 'role',
            name: 'Role',
            template: (row) =>
              switcher(row.role, [
                [Role.CONTRACTOR_OPERATIONS_MANAGER, 'Operations Manager'],
                [Role.CONTRACTOR_TECHNICIAN, 'Technician'],
              ]),
          },
          {
            key: 'status',
            name: 'Status',
            align: 'center',
            template: renderStatus,
          },
          {
            key: 'actions',
            template: function ActionsTemplate(row) {
              const actions: Actions = [];

              if (mode.mode === Mode.PM || authStore.user?.role === Role.CONTRACTOR_OPERATIONS_MANAGER) {
                actions.push(
                  {
                    title: 'Request User Access to Client',
                    action: () => {
                      openRequestAccessDialog({
                        type: 'request',
                        userId: row.id,
                        userName: row.name,
                        alreadyRequestedClientIds: row.clients.map((client) => client.id),
                      });
                    },
                  },
                  {
                    title: 'Remove User Access to Client',
                    action: () => {
                      openRequestAccessDialog({
                        type: 'remove',
                        userId: row.id,
                        userName: row.name,
                        alreadyRequestedClientIds: row.clients.map((client) => client.id),
                      });
                    },
                  },
                );
              }

              if (userEditor) {
                actions.push(
                  {
                    title: 'Edit Details',
                    action: () => {
                      navigate(`${row.id}`);
                    },
                  },
                  {
                    title: row.active ? 'Archive' : 'Activate',
                    action: async (setLoading) => {
                      setLoading(true);
                      await updateStatus({
                        variables: {
                          id: row.id,
                          isActive: !row.active,
                        },
                      });
                      setLoading(false);
                    },
                  },
                );
              }

              return <ActionsButton actions={actions} />;
            },
          },
        ]}
      />

      <UpdateRequestDialog
        open={requestAccessDialogOpen}
        title={
          requestAccessDialogData?.type === 'remove' ? 'Remove User Access to Client' : 'Request User Access to Client'
        }
        description={
          requestAccessDialogData?.type === 'remove'
            ? 'Select the client the user will no longer have an access.'
            : undefined
        }
        clientOptions={
          requestAccessDialogData
            ? contractorAcceptedRequests
                ?.filter((request) =>
                  requestAccessDialogData.type === 'remove'
                    ? requestAccessDialogData.alreadyRequestedClientIds.includes(request.client.id)
                    : !requestAccessDialogData.alreadyRequestedClientIds.includes(request.client.id),
                )
                .map((request) => request.client)
            : undefined
        }
        ifClientOptionsEmpty={
          requestAccessDialogData?.type === 'remove' ? (
            <div>
              <i>{requestAccessDialogData.userName}</i> has no Access to any Client.
            </div>
          ) : (
            <div>
              {contractorAcceptedRequests?.length ? (
                <>
                  <i>{requestAccessDialogData?.userName}</i> has already requested Access to all available Clients.
                </>
              ) : (
                <>
                  <i>{contractorData?.contractor.companyName}</i> has no Access to any Client.
                </>
              )}
            </div>
          )
        }
        onSend={async (clientId) => {
          if (!requestAccessDialogData) return;

          if (requestAccessDialogData.type === 'remove') {
            await removeAccess({
              variables: {
                clientId,
                userId: requestAccessDialogData.userId,
              },
            });
          } else {
            await requestAccess({
              variables: {
                clientId,
                userId: requestAccessDialogData.userId,
              },
            });
          }
        }}
        onClose={closeRequestAccessDialog}
      />
    </>
  );
};

export default ContractorUsersPage;
