import React from 'react';
import classNames from 'classnames';
import { FormControlLabel, FormControlLabelProps, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const styleRules = (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControlLabel-label': {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&.MuiFormControlLabel-labelPlacementStart': {
        /* Contents should not overflow parent boxes */
        marginLeft: 0,
        marginRight: 0,
        '& .MuiFormControlLabel-label': {
          marginRight: 'auto',
        },
      },
    },
  });
const useStyle = makeStyles(styleRules);

const AppFormControlLabel: React.FC<FormControlLabelProps> = ({ children, className, ...propsToPass }) => {
  const style = useStyle();

  return (
    <FormControlLabel {...propsToPass} className={classNames(className, style.root)}>
      {children}
    </FormControlLabel>
  );
};

export default AppFormControlLabel;
