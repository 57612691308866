export class PumpService {
  async fetchPumpInfo(name: string, pass: string) {
    try {
      const pumpInfo: any = await fetch(`https://www.mobikey.eu/map/J/${name}/pwd/${pass}`);
      const parsedPumpInfo = (await pumpInfo.json()) || undefined;
      const fuelBattory = (parsedPumpInfo.BATT.AVERAGE - 3200) / 10; // batteryGauge = ((~~data.BATT.AVERAGE - 3200)/10);
      const tankLevel = Math.ceil((100 / parsedPumpInfo.A1.top) * parsedPumpInfo.A1.AVERAGE);
      let batt = fuelBattory > 100 ? 100 : fuelBattory;
      batt = fuelBattory < 0 ? 0 : fuelBattory;
      const formattedPumpInfo = {
        __typename: 'FetchedPumpInfoOutput',
        id: name,
        name: parsedPumpInfo.DVC.NAME,
        pump1: !!parsedPumpInfo.MAIN.VALUE, // main.value -> true/false
        pump2: !!parsedPumpInfo.AUX.VALUE, // aux.value -> true/false
        temp: parsedPumpInfo.TEMP.AVERAGE,
        batt,
        tankLevel,
        pump1runtime: parsedPumpInfo.MAIN.runtime,
        pump2runtime: parsedPumpInfo.AUX.runtime,
      };
      return formattedPumpInfo;
    } catch (error) {
      console.log('fetchPumpInfo', error);
      return null;
    }
  }
}