import React from 'react';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    background: '#f3f4f9',
    width: '175px',
    height: '175px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Logo: React.FC<{ src: string | null; alt: string }> = ({ src, alt }) => {
  const styles = useStyles();

  return <div className={styles.logo}>{src !== null ? <img src={src} alt={alt} /> : null}</div>;
};

export default Logo;
