import { Switch, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { SwitchProps } from '@mui/material/Switch';
import listViewIconWhite from 'assets/listViewIconWhite.svg';
import listViewIconGrey from 'assets/listViewIconGrey.svg';
import mapViewIconGrey from 'assets/mapViewIconGrey.svg';
import mapViewIconWhite from 'assets/mapViewIconWhite.svg';

const styleRules = (theme: Theme) =>
  createStyles({
    root: {
      width: 87,
      height: 40,
      padding: 0,
      margin: 0,
    },
    switchBase: {
      padding: 0,
      '&$checked': {
        transform: 'translateX(47px)',
        color: theme.palette.common.white,
        '& $thumb': {
          backgroundColor: '#44924D',
          backgroundImage: `url('${mapViewIconWhite}')`,
        },
        '& + $track': {
          opacity: 1,
          border: 'none',
          backgroundColor: '#F3F4F9',
          backgroundImage: `url('${listViewIconGrey}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 13,
          backgroundPositionY: 'center',
        },
      },
      '&$focusVisible $thumb': {
        color: '#44924D',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 40,
      height: 40,
      boxShadow: 'none',
      backgroundColor: '#44924D',
      backgroundImage: `url('${listViewIconWhite}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    track: {
      borderRadius: 20,
      backgroundColor: '#F3F4F9',
      backgroundImage: `url('${mapViewIconGrey}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 50,
      backgroundPositionY: 'center',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  });
const useStyles = makeStyles(styleRules);

const ListMapSwitch: React.FC<SwitchProps> = ({ checked, onChange: onChangeProp, ...propsToPass }) => {
  const styles = useStyles();
  return (
    <Switch
      focusVisibleClassName={styles.focusVisible}
      disableRipple
      checked={!!checked}
      classes={{
        root: styles.root,
        switchBase: styles.switchBase,
        thumb: styles.thumb,
        track: styles.track,
        checked: styles.checked,
      }}
      onChange={(event, isChecked) => {
        if (onChangeProp) {
          onChangeProp(event, isChecked);
        }
      }}
      {...propsToPass}
    />
  );
};

export default ListMapSwitch;
