import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Logo from 'components/Logo';
import { contractorUsers_contractor_requests_userRequests_user as User, Role } from 'models/graphql';
import switcher from 'utils/switcher';

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
    margin: 'auto',
  },
  profilePictureContainer: {
    display: 'inline-block',
    marginBottom: theme.spacing(4),
  },
  profileDataContainer: {
    padding: theme.spacing(3),
  },
  value: {
    paddingBottom: theme.spacing(2),
    color: '#9F9F9F !important',
  },
  label: {
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    justifyItems: 'space-between',
  },
  setLineHeight: {
    lineHeight: '40px',
  },
}));

const UserDetailsPanel: React.FC<{
  user: User;
}> = ({ user }) => {
  const styles = useStyles();

  return (
    <>
      <Box className={styles.center}>
        <div className={styles.profilePictureContainer}>
          <Logo src={user.picture ? user.picture.srcUrl : null} alt="" />
        </div>
      </Box>
      <Box className={styles.profileDataContainer}>
        <Typography variant="body1" className={styles.label}>
          Name
        </Typography>
        <Typography variant="body1" className={styles.value}>
          {user.name}
        </Typography>
        <Typography variant="body1" className={styles.label}>
          Contact Email
        </Typography>
        <Typography variant="body1" className={styles.value}>
          {user.email}
        </Typography>
        <Typography variant="body1" className={styles.label}>
          Contact Number
        </Typography>
        <Typography variant="body1" className={styles.value}>
          {user.phonenumber}
        </Typography>
        <Typography variant="body1" className={styles.label}>
          Role
        </Typography>
        <Typography variant="body1" className={styles.value}>
          {switcher(user.role, [
            [Role.CONTRACTOR_OPERATIONS_MANAGER, 'Operations Manager'],
            [Role.CONTRACTOR_TECHNICIAN, 'Technician'],
          ])}
        </Typography>
      </Box>
    </>
  );
};

export default UserDetailsPanel;
