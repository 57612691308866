import React, { useEffect } from 'react';
import { motion, useAnimation } from 'motion/react';
import styleUtils from 'style/styleUtils';
import cn from 'utils/cn';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const badgeSize = 20;
const badgeSizeSmall = 16;
const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    position: 'absolute',
    top: '0',
    right: '0',
    ...styleUtils.rowCenterCenter,
    width: badgeSize,
    height: badgeSize,
    margin: theme.spacing(1),
    textAlign: 'center',
    fontSize: theme.fontSizes[12],
    color: 'white',
    background: theme.palette.error.main,
    borderRadius: '50%',
    '&.small': {
      width: badgeSizeSmall,
      height: badgeSizeSmall,
      fontSize: theme.fontSizes[10],
      margin: theme.spacing(0.5),
    },
  },
}));

interface Props {
  content?: number | string;
  small?: boolean;
}

const Badge: React.FC<Props> = ({ content, small }) => {
  const { badge } = useStyles();
  const animation = useAnimation();
  useEffect(() => {
    if (content) {
      animation.start({ scale: [null, 2, 1] });
    } else {
      animation.start({ scale: [null, 0] });
    }
  }, [content, animation]);

  return (
    <motion.div initial={{ scale: 0 }} animate={animation} className={cn(badge, { small })}>
      {content}
    </motion.div>
  );
};

export default Badge;
