import React from 'react';

import NotificationList from 'containers/shared/Notifications/NotificationList';
import useGetNotifications from 'containers/shared/Notifications/useGetNotifications';
import { useBreadcrumb } from 'components/Breadcrumb';
import useMode, { Mode } from 'utils/useMode.hook';

const NotificationsPage: React.FC = () => {
  const { mode } = useMode();
  const { notifications, loading } = useGetNotifications();
  useBreadcrumb(`/${mode === Mode.OPERATIVE ? 'operative' : 'technician'}/notifications`, 'Notifications');
  return <NotificationList notificationsToMe={notifications} loading={loading} />;
};

export default NotificationsPage;
