import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import styleUtils from 'style/styleUtils';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';

const useTaskDetailsAcceptOrDenyStyles = makeStyles((theme: Theme) => {
  return createStyles({
    absoluteContainer: {
      position: 'fixed',
      width: `calc(100% - ${theme.sizes.drawerWidth}px)`,
      left: theme.sizes.drawerWidth,
      right: 0,
      bottom: 0,
      zIndex: 10,
      background: '#fff',
      [theme.breakpoints.down(BREAKPOINT)]: {
        left: 0,
        width: '100%',
      },
    },
    acceptOrDenyContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      ...styleUtils.columnStartCenter,
      boxShadow: theme.shadows[4],
    },
    acceptOrDeny: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    acceptOrDenyDetails: {
      paddingTop: theme.spacing(2),
    },
    acceptOrDenyComment: {
      marginBottom: theme.spacing(2),
    },
    acceptOrDenySubmit: {
      marginTop: theme.spacing(2),
      ...styleUtils.rowCenterCenter,
    },
    fullwidthForm: {
      width: '100%',
    },
  });
});

export default useTaskDetailsAcceptOrDenyStyles;
