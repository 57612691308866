import React from 'react';
import AppButton from 'components/AppButton';
import styleUtils from 'style/styleUtils';
import useText from 'texts/useText.hook';
import errorIcon from 'assets/errorIcon.svg';
import warningIcon from 'assets/warningIcon.svg';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: theme.spacing(3),
    '& .MuiTypography-root': {
      fontSize: theme.fontSizes['18'],
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  content: {
    padding: theme.spacing(3),
    paddingTop: 0,
    maxWidth: 360,
  },
  contentWithIcon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    flexShrink: 0,
    width: 30,
    marginRight: theme.spacing(1),
  },
  line: {
    margin: `0 ${theme.spacing(3)}`,
    height: 1,
    background: theme.palette.grey['300'],
  },
  actions: {
    padding: theme.spacing(3),
    ...styleUtils.rowCenterCenter,
  },
}));

const AppDialog: React.FC<
  Omit<DialogProps, 'onClose'> & {
    type?: 'error' | 'warning';
    title: string | React.ReactNode;
    actions?: React.ReactNode;
    onClose: () => void;
  }
> = ({ type, title, children, actions, onClose, ...dialogProps }) => {
  const styles = useStyles();
  const { t } = useText('common');
  let actionsTemplate = actions;
  if (!actionsTemplate) {
    if (type) {
      actionsTemplate = (
        <>
          <AppButton
            color={type}
            onClick={() => {
              if (onClose) onClose();
            }}
          >
            {t('common')('close')}
          </AppButton>
        </>
      );
    }
  }

  const iconTypeContent = () => {
    switch (type) {
      case 'error':
        return (
          <div className={styles.contentWithIcon}>
            <img alt="Error" src={errorIcon} className={styles.icon} />
            <div>{children}</div>
          </div>
        );
      case 'warning':
        return (
          <div className={styles.contentWithIcon}>
            <img alt="Warning" src={warningIcon} className={styles.icon} />
            <div>{children}</div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog onClose={() => onClose()} {...dialogProps}>
      <DialogTitle className={styles.title}>{title}</DialogTitle>
      <DialogContent className={styles.content}>{type ? iconTypeContent() : <>{children}</>}</DialogContent>
      {actionsTemplate ? (
        <>
          <div className={styles.line} />
          <DialogActions className={styles.actions}>{actionsTemplate}</DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};

export default AppDialog;
