import * as dateFns from 'date-fns';
import useText from 'texts/useText.hook';

const useGetStaticFields = () => {
  const { t } = useText('tasks');

  return [
    {
      key: 'Task ID',
      token: ['idReadable'],
    },
    {
      key: 'Task Type',
      token: ['type'],
    },
    {
      key: 'Task D/L',
      token: ['deadline'],
      handlerFunction: (value: string) => {
        return dateFns.format(new Date(value), 'dd/MM/yyyy');
      },
    },
    {
      key: 'Document D/L',
      token: ['documentDeadline'],
      handlerFunction: (value: string) => {
        return dateFns.format(new Date(value), 'dd/MM/yyyy');
      },
    },
    {
      key: 'Responsible for Works',
      token: ['internalResponsible', 'name'],
    },
    {
      key: 'Status of Workflow',
      token: ['status'],
    },
    {
      key: 'Work Order ID',
      token: ['contractorResponsibleWorkOrderId'],
    },
    {
      key: 'Company name',
      token: ['contractorResponsible', 'companyName'],
    },
    {
      key: 'Works completed in',
      token: ['timer', 'timeSpent'],
      handlerFunction: (value: string) => {
        const result = dateFns.formatDuration({
          hours: Math.floor(+value / 3600),
          minutes: Math.floor((+value % 3600) / 60),
          seconds: +value % 60,
        });
        return result;
      },
    },
    {
      key: 'Assigned Time',
      token: ['assignedAt'],
      handlerFunction: (value: string) => {
        return dateFns.format(new Date(value), 'dd/MM/yyyy');
      },
    },
    {
      key: 'Priority',
      token: ['urgent'],
      handlerFunction: (value: string) => {
        return value ? t('tasks')('priorityUrgent') : t('tasks')('priorityNormal');
      },
    },
    {
      key: 'Notes',
      token: ['notes'],
    },
    {
      key: 'Task Title',
      token: ['tasktitle'],
    },
    {
      key: 'Asset ID',
      token: ['assets', 'id'],
    },
    {
      key: 'Asset Name',
      token: ['assets', 'name'],
    },
    {
      key: 'Asset Type',
      token: ['assets', 'type'],
    },
    {
      key: 'System Reference',
      token: ['assets', 'system', 'name'],
    },
    {
      key: 'System Type',
      token: ['assets', 'system', 'type'],
    },
    {
      key: 'Asset Created',
      token: ['assets', 'createdAt'],
      handlerFunction: (value: string) => {
        return dateFns.format(new Date(value), 'dd/MM/yyyy');
      },
    },
    {
      key: 'Asset Owner',
      token: ['assets', 'owner', 'name'],
    },
    {
      key: 'Department',
      token: ['assets', 'department'],
    },
    {
      key: 'Site',
      token: ['assets', 'site', 'name'],
    },
    {
      key: 'Asset Latitude Coordinate',
      token: ['assets', 'coordinates', 'latitude'],
    },
    {
      key: 'Asset Longitude Coordinate',
      token: ['assets', 'coordinates', 'longitude'],
    },
    {
      key: 'Asset Additional Information',
      token: ['assets', 'additionalInformation'],
    },
    {
      key: 'Task Completed',
      token: ['logs', 'taskComplete'],
      handlerFunction: (value: string) => {
        return value ? dateFns.format(new Date(value), 'dd/MM/yyyy | H:mm') : '';
      },
    },
    {
      key: 'Supp Info Submitted',
      token: ['logs', 'taskSubmitSuppInfo'],
      handlerFunction: (value: string) => {
        return value ? dateFns.format(new Date(value), 'dd/MM/yyyy | H:mm') : '';
      },
    },
  ];
};

export default useGetStaticFields;
