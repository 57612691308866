import React from 'react';
import HideNavigation from 'components/HideNavigation';
import useCancel from 'utils/useCancel.hook';
import cancelSimpleIcon from 'assets/cancelSimpleIcon.svg';
import { CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),

    textAlign: 'center',
  },
  header: {},
  cancelButton: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(1),
    transform: 'translateY(-50%)',
    padding: theme.spacing(1),
  },
}));

interface Props {
  title: string;
  loading?: boolean;
  cancel?: string;
}

const FullScreenPage: React.FC<Props> = ({ title, loading, cancel: _cancel, children }) => {
  const styles = useStyles();
  const cancel = useCancel(_cancel || '/');

  return (
    <div className={styles.container}>
      <HideNavigation />
      <div className={styles.headerContainer}>
        <Typography variant="h3" className={styles.header}>
          {title}
        </Typography>

        {_cancel ? (
          <button className={styles.cancelButton} type="button" onClick={() => cancel()}>
            <img src={cancelSimpleIcon} alt="cancel" />
          </button>
        ) : null}
      </div>
      {loading ? <CircularProgress /> : children}
    </div>
  );
};

export default FullScreenPage;
