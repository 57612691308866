const enUser = {
  name: 'Name',
  email: 'Email',
  phonenumber: 'Phone',
  site: 'Site',
  role: 'Role',
  isAdmin: 'Grant User Management rights',
  sendEmail: 'Send registration email to the user',
  fullAccess: 'Full Asset Register Access on Mobile Device',
  adhoc: 'Enable Adhoc Tasks',
  notifications: 'Notifications',
  notificationStatus: 'Notification Status Selection',
  notificationSchedule: 'Notification Frequency Selection',
  message: 'Message'
}

export default enUser;