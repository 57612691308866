import React from 'react';
import { Box, CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { endOfDay, startOfDay } from 'date-fns';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import {
  operativeHome,
  operativeHomeVariables,
  operativeHome_user_tasks as operativeHomeTask,
  TaskStatus,
  TaskType,
} from 'models/graphql';
import { useBreadcrumb } from 'components/Breadcrumb';
import { useStores } from 'stores';
import calendarIcon from 'assets/calendarIcon.svg';
import taskIconGrey from 'assets/taskIconGrey.svg';
import cn from 'utils/cn';
import formatDayDate, { readDayDate } from 'shared/formatDayDate';
import useText from 'texts/useText.hook';
import { completedTaskStatuses } from 'shared/taskStatusGroups';
import TaskCard from 'containers/shared/Technician/TaskCard';
import useHomePageStyles from 'containers/shared/Technician/HomePageStyles';
import { GET_OPERATIVE_HOME } from '../graphql';
import { Link } from 'react-router';

const isHighlighted = (task: operativeHomeTask) => {
  const date = new Date();
  return (
    task.urgent ||
    (task.deadline && task.deadline > formatDayDate(startOfDay(date)) && task.deadline < formatDayDate(endOfDay(date)))
  );
};

const groupTaskByType = R.groupBy((task: operativeHomeTask) => task.type);

const HomePage: React.FC = () => {  
  const classes = useHomePageStyles();
  const theme = useTheme();
  const { authStore } = useStores();
  const user = authStore.user;
  const title = user && user.company ? user.company.name : 'Home';
  useBreadcrumb('/home', title);
  const { t, tt } = useText('taskTypes', 'urls');

  const { loading, data } = useQuery<operativeHome, operativeHomeVariables>(GET_OPERATIVE_HOME, {
    skip: !user,
    variables: {
      id: user!.id,
      withoutRejected: true,
      status: "SENT"
    },
  });

  const tasks = data ? data.user.tasks : [];
  const tasksInProgress = tasks.filter((task) => ![...completedTaskStatuses, TaskStatus.SENT].includes(task.status));
  const highlights = tasksInProgress.filter((task) => isHighlighted(task));
  const requests = tasks.filter((task) => task.status === TaskStatus.SENT);
  const tasksByType =
    data &&
    groupTaskByType(
      tasksInProgress.slice().sort((a, b) => {
        if (!a.deadline) return b.deadline ? -1 : 0;
        if (!b.deadline) return 1;
        if (a.deadline > b.deadline) {
          return 1;
        }
        return a.deadline < b.deadline ? -1 : 0;
      }),
    );

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <Grid container className={classes.padding}>
      {tasksByType && Object.keys(tasksByType).length > 0 && (
        <Grid
          className={classes.paddingX}
          size={{
            xs: 12,
            md: 6
          }}>
          <h2 className={cn(classes.grey, classes.heading)}>Task Instructions by type</h2>
          <Grid container spacing={2}>
            {Object.keys(tasksByType).map((type) => (
              <Grid key={uuidv4()} size={6}>
                <Link to={`/operative/works?${tt('urls')('queries')('type')}=${type}`}>
                  <Paper className={cn(classes.clientBox, classes.grey)}>
                    <Typography className={classes.clientName}>{t('taskTypes')(TaskType[type as TaskType])}</Typography>
                    <Box
                      sx={{
                        mt: 2,
                        mb: 1
                      }}>
                      <Typography>
                        {tasksByType[type].length} task instruction
                        {tasksByType[type].length === 1 ? '' : 's'}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}>
                      <Grid className={classes.smallFont}>
                        {tasksByType[type].length > 0 &&
                          tasksByType[type][0].deadline &&
                          theme.dateFormat.fullMonth(readDayDate(tasksByType[type][0].deadline || ''))}
                      </Grid>
                      <Grid>
                        <Box sx={{
                          display: "grid"
                        }}>
                          <img src={calendarIcon} width={20} alt="Calendar icon" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      <Grid
        className={classes.paddingX}
        size={{
          xs: 12,
          md: 6
        }}>
        {highlights.length > 0 && (
          <>
            <h2 className={cn(classes.grey, classes.heading)}>Highlighted Tasks</h2>
            <Grid container spacing={3}>
              {highlights.map((task) => (
                <Grid key={task.id} size={12}>
                  <Link to={`/operative/works/${task.id}`}>
                    <TaskCard task={{ ...task }} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {requests.length > 0 && (
          <>
            <h2 className={cn(classes.grey, classes.heading)}>New Task Instructions</h2>
            <Grid container spacing={3}>
              {requests.map((task) => (
                <Grid key={task.id} size={12}>
                  <Link to={`/operative/works/${task.id}`}>
                    <TaskCard task={{ ...task }} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Grid>
      {!requests && (!tasksByType || Object.keys(tasksByType).length) && (
        <Box
          sx={{
            mt: 10,
            px: 3,
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.54)"
          }}>
          <Box
            className={classes.boxBg}
            sx={{
              p: 5,
              borderRadius: "50%",
              maxWidth: "fit-content",
              m: "auto"
            }}>
            <img src={taskIconGrey} width={50} alt="No tasks" />
          </Box>
          <Box sx={{
            p: 1
          }}>
            <Typography variant="body1" className={classes.boldFont}>
              No Task Instructions
            </Typography>
          </Box>
          <Typography variant="body1" className={classes.smallFont}>
            at this time
          </Typography>
        </Box>
      )}
    </Grid>
  )
};

export default observer(HomePage);
