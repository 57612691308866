import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.action.active,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const AppFieldArrayAdd: React.FC<{ text: string; onClick: () => void }> = ({ onClick, text }) => {
  const styles = useStyles();

  return (
    <button className={styles.button} type="button" onClick={onClick}>
      + {text}
    </button>
  );
};

export default AppFieldArrayAdd;
