import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Formik, Form, Field } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import { AppTextField, AppDateField } from 'components/form';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: '500px', // Adjust the value as needed
    maxWidth: '80%',
  },
  table: {
    width: '100%',
  },
  row: {
    '&:not(:first-child)': {
      paddingTop: '5px', // Add spacing between rows
    },
  },
});

function findKey(array, keyToFind) {
  return array.find(item => item.key === keyToFind);
}

const capitalizeFirstLetter = (string) => {
  if (string.length === 0) return string;
  let newStr = string.replace("_", " ");
  console.log("newStr:", newStr)
  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};

interface FormValues {
  projectName: string;
  projectDesc: string;
  responsible: string;
  startDate: string;
  endDate: string;
  status: string;
}

const ViewProjectGantt = (props) => {
  const { open, setOpen, content, onClose, title } = props;
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [projeName, setProjeName] = useState('Hello There');
  const [formDetails, setFormDetails] =  useState<FormValues[] | []>();
  const [loading, setLoading] = useState(true);


  
  useEffect(() => {
      if (content)
      {
          setFormDetails(content)
          setProjeName(content.projectName)

       
      }

  }, [content]); // Update value when content.controlmeasure changes
  

  useEffect(() => {
    if (formDetails)
    {      

      setLoading(false);

    }

  }, [formDetails]);


  const handleSubmit = (values: FormValues) => {
    onClose();
  };

  return (
    <div>
      {loading ? (
       <label> </label>
      ) : (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          id="customized-dialog-title"
        >
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '80%' }}>{title}</td>
                <td style={{ width: '20%', textAlign: 'right', marginLeft: '80%' }}>
                  <IconButton onClick={() => setOpen(false)} aria-label="close" size="large">
                    <ClearIcon />
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogTitle>
        <DialogContent>
        <Formik
                initialValues={{
                  projectName: formDetails.projectName,
                  projectDesc: formDetails.projectDesc,
                  responsible: formDetails.responsible,
                  startDate: formDetails.startDate,
                  endDate: formDetails.endDate,
                  status: capitalizeFirstLetter(formDetails.status),
                  linkToTask: false,
                  selectedTask: '',
                }}
                onSubmit={handleSubmit}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <table className={classes.table}>
                      <tbody>
                        <tr className={classes.row}>
                          <td>
                            <AppTextField
                              label="Title"
                              name="projectName"
                              type="text"
                              autoFocus
                            />
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td>
                            <AppTextField
                              label="Description"
                              name="projectDesc"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td>
                            <AppTextField
                              label="Responsible"
                              name="responsible"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td>
                            {formDetails.status !== 'project' && (
                              <AppTextField
                                label="Status"
                                name="status"
                              />
                            )}
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td>
                            <AppDateField
                              label="Start Date"
                              name="startDate"
                            />
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td>
                            <AppDateField
                              label="End Date"
                              name="endDate"
                            />
                          </td>
                        </tr>
                       </tbody>
                    </table>
                    <DialogActions>
                      <Button autoFocus onClick={() => setOpen(false)} color="primary">
                        Cancel
                      </Button>
                      {values.linkToTask && values.selectedTask && (
                        <Button  color="primary">
                          Update
                        </Button>
                      )}
                    </DialogActions>
                  </Form>
                )}
              </Formik>
        </DialogContent>
      </Dialog>   
      )}     
    </div>
  );
};

export default ViewProjectGantt;

