import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'stores';
import BreadcrumbSelector from 'containers/Default/components/Header/Breadcrumb/BreadcrumbSelector';
import cn from 'utils/cn';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import rightArrowIcon from 'assets/rightArrow.svg'
import downArrowIcon from 'assets/downArrow.svg';
import { Link } from 'react-router';


const useStyles = makeStyles((theme: Theme) => ({
  paddingLeft: {
    paddingLeft: theme.spacing(1.5),
  },
  paddingRight: {
    paddingRight: theme.spacing(1.5),
  },
  path: {
    display: 'inline-block',
    lineHeight: '24px',
  },
  height: {
    lineHeight: '24px',
  },
  hideOnMobile: {
    [theme.breakpoints.down(BREAKPOINT)]: {
      display: 'none',
    },
  },
}));

const Breadcrumb: React.FC = () => {
  const classes = useStyles();
  const { contextStore } = useStores();

  return (<>
    {contextStore.breadcrumbs.map(({ id, to, name, options }, index) => {
      return (
        // on mobile: show only the last breadcrumb
        (<div className={cn({ [classes.hideOnMobile]: index !== contextStore.breadcrumbs.length - 1 })} key={id}>
          {!options || options.length === 0 ? (
            <>
              <Link to={to}>
                <Typography variant="h3" className={`${classes.path}`}>
                  {name}
                </Typography>
              </Link>
              <img
                src={
                  index === contextStore.breadcrumbs.length - 1
                    ? downArrowIcon
                    : rightArrowIcon
                }
                alt="icon"
                className={`${classes.paddingLeft} ${classes.height}  ${classes.hideOnMobile} ${
                  index !== contextStore.breadcrumbs.length - 1 && classes.paddingRight
                }`}
                height={index === contextStore.breadcrumbs.length - 1 ? '9' : '14'}
              />
            </>
          ) : (
            <BreadcrumbSelector options={options}>
              <Typography variant="h3" className={`${classes.path}`}>
                {name}
              </Typography>
              <img
                src={downArrowIcon}
                alt="select"
                className={`${classes.paddingLeft} ${classes.height}`}
                height={9}
              />
            </BreadcrumbSelector>
          )}
        </div>)
      );
    })}
  </>);
};

export default observer(Breadcrumb);
