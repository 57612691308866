import AppTextField, { AppTextFieldProps } from 'components/form/AppTextField';
import React, { useState } from 'react';
import searchIcon from 'assets/searchIcon.svg';
import { useFormikContext } from 'formik';
import { createStyles, makeStyles } from '@mui/styles';
import { InputAdornment, Theme } from '@mui/material';

const styleRules = (theme: Theme) =>
  createStyles({
    icon: {
      width: theme.sizes.iconNormal,
      marginRight: theme.spacing(0.5),
    },
  });
const useStyles = makeStyles(styleRules);

export default function AppSearchField(
  props: AppTextFieldProps & {
    onCommit?: (value: string) => void;
  },
) {
  const { name, onCommit: onCommitProp, onBlur: onBlurProp, ...textFieldProps } = props;
  const { values, setFieldValue } = useFormikContext<Record<string, unknown>>();
  const actualValue = values[name] as string;
  const [committedValue, setCommittedValue] = useState(actualValue);

  const styles = useStyles();

  function onCommit() {
    if (committedValue !== actualValue) {
      setCommittedValue(actualValue);
      setFieldValue(name, actualValue);
      if (onCommitProp) {
        onCommitProp(actualValue);
      }
    }
  }

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onCommit();
      }}
    >
      <AppTextField
        name={name}
        type="search"
        onBlur={(e) => {
          if (onBlurProp) onBlurProp(e);
          onCommit();
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div className={styles.icon}>
                <img alt="search" src={searchIcon} />
              </div>
            </InputAdornment>
          ),
        }}
        {...textFieldProps}
      />
    </form>
  );
}
