import React from 'react';

import DrawingPage from 'containers/shared/DrawingPage';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import AssetsPage from './AssetsPage';
import { Route, Routes } from 'react-router';

const AssetsRoutes: React.FC = () => {
  return (
    <DefaultErrorBoundary>
      <Routes>
        <Route path={`drawing/:drawingId`} element={<DrawingPage />} />
        <Route path={`/`} element={<AssetsPage />} />
      </Routes>
    </DefaultErrorBoundary>
  );
};

export default AssetsRoutes;
