import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Box, FormControlLabel, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { alpha, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';

import { AppDateField, AppFieldError, AppSelectField, AppSelectMultipleField, AppTextField } from 'components/form';
import cn from 'utils/cn';
import { ReOccurenceInput, TaskType } from 'models/graphql';
import useText from 'texts/useText.hook';
import AppButton from 'components/AppButton';
import { AppTooltip } from 'components/AppTooltip';
import { FormContent, FormContentType } from 'shared/assetFormContent.interface';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import photoIcon from 'assets/photoIcon.svg';
import signatureIcon from 'assets/signatureIcon.svg';
import addIcon from 'assets/addIcon.svg';


interface FormItem {
  type: string;
  parameterName: string;
}

interface InputFormItem extends FormItem {
  parameterName: string;
  note: string;
  mandatory: boolean;
  assets: string;
}

interface TextFormItem extends InputFormItem {
  value: string;
}

interface NumericFormItem extends InputFormItem {
  minValue: number;
  maxValue: number;
}

interface MultivalueFormItem extends InputFormItem {
  values: {
    value: string;
    comply?: boolean;
  }[];
}

interface MultiSelectFormItem extends InputFormItem {
  values: {
    value: string;
    comply?: boolean;
  }[];
}

const styles = (theme: Theme) =>
  createStyles({
    label: {
      cursor: 'initial',
      width: '100%',
      alignItems: 'flex-start',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        display: 'inline-block',
        paddingBottom: theme.spacing(1),
        fontSize: theme.fontSizes['12'],
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-disabled': {
          color: alpha(theme.palette.text.primary, 0.4),
        },
      },
      '&.no-label  .MuiFormControlLabel-label': {
        padding: 0,
      },
      '& > div': {
        cursor: 'pointer',
      },
    },
    iconWrapper: {
      cursor: 'pointer',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.grey['100'],
      borderRadius: '20px',
    },
    linkButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: 0,
      margin: '16px',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      width: 'min-content',
      '& > img': {
        paddingRight: theme.spacing(1),
      },
      '& > div': {
        whiteSpace: 'nowrap',
      },
    },
    formSectionSeparator: {
      padding: '0 !important',
      height: '1px',
      backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
      paddingLeft: '16px',
      margin: '0 16px',
    },
    frequency: {
      color: '#9F9F9F',
    },
    formBox: {
      width: '100%',
    },
    form: {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      width: 'min(575px, 100%)',
      borderRadius: '8px',
      '&>*': {
        padding: theme.spacing(2),
        userSelect: 'none',
      },
    },
    black: {
      backgroundColor: '#000000',
      color: 'palevioletred',
    },
    formelement: {
      cursor: 'pointer',
      '&.no-side-padding': {
        padding: '8px 0',
      },
      '&>*': {
        pointerEvents: 'none',
      },
      '&.multi-select': {
        '&>*': {
          '&:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
          },
          '&:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          },
        },
      },
      '& .MuiInputBase-root': {
        color: '#9F9F9F',
      },
    },
    multiSelectValue: {
      padding: '0 20px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      height: '3em',
      width: 'min-content',
      display: 'flex',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '-1px',
      marginLeft: '-1px',
    },
    frequencyDate: {
      whiteSpace: 'nowrap',
      paddingRight: '2em',
    },
    formElement: {
      border: '1px solid #fff',
      backgroundColor: '#fff',
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        color: 'rgba(0, 0, 0, 0.1)',
        marginLeft: 8,
      },
      '&:hover': {
        border: '1px solid #44924D',
        '& .MuiSvgIcon-root': {
          color: '#44924D',
        },
      },
    },
    active: {
      border: '1px solid #44924D',
      '& .MuiSvgIcon-root': {
        color: '#44924D',
      },
    },
    labelElement: {
      paddingBottom: 8,
      fontSize: '12px',
      fontWeight: 700,
    },
    buttonWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  });
const useStyles = makeStyles(styles);

const FormElementWrapper: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  return (
    <div style={{ width: '100%' }}>
      {React.cloneElement(children, {
        onClick: () => children.props.onClick(),
      })}
    </div>
  );
};

const TextElement: React.FC<{
  parameterName: string;
  note: string;
  mandatory: boolean;
  assets: string;
  value?: string;
  minValue?: number;
  maxValue?: number;
}> = ({ parameterName, note, mandatory, value = '', minValue, maxValue }) => {
  const classes = useStyles();

  const numericValue = `${minValue ? `from ${minValue}` : ''}${minValue ? ' ' : ''}${maxValue ? `to ${maxValue}` : ''}`;

  return (
    <>
      <div className={classes.labelElement}>
        {mandatory ? `${parameterName}*` : parameterName} <AppTooltip note={note} />
      </div>
      <div className={classes.formelement}>
        <AppTextField
          name={parameterName}
          type="text"
          required={mandatory}
          style={{
            color: '#9F9F9F',
          }}
          value={value || numericValue}
        />
      </div>
    </>
  );
};

const DateElement: React.FC<{
  parameterName: string;
  note: string;
  mandatory: boolean;
  assets: string;
  value?: string;
}> = ({ parameterName, note, mandatory }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.labelElement}>
        {mandatory ? `${parameterName}*` : parameterName} <AppTooltip note={note} />
      </div>
      <div className={classes.formelement}>
        <AppDateField
          name={parameterName}
          required={mandatory}
          style={{
            color: '#9F9F9F',
          }}
        />
      </div>
    </>
  );
};

const MultiValueElement: React.FC<MultivalueFormItem> = ({ parameterName, note, values, mandatory }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.labelElement}>
        {mandatory ? `${parameterName}*` : parameterName} <AppTooltip note={note} />
      </div>
      <div className={classes.formelement}>
        <AppSelectField
          name={parameterName}
          options={{
            array: values,
            value: (valuesArray) => valuesArray.value,
            key: (valuesArray) => valuesArray.value,
            template: (valuesArray) => valuesArray.value,
          }}
          required={mandatory}
        />
      </div>
    </>
  );
};

const MultiSelectElement: React.FC<MultiSelectFormItem> = ({ parameterName, note, values, mandatory }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.labelElement}>
        {mandatory ? `${parameterName}*` : parameterName} <AppTooltip note={note} />
      </div>
      <div className={classes.formelement}>
        <AppSelectMultipleField
          name={parameterName}
          options={{
            array: values,
            value: (valuesArray) => valuesArray.value,
            key: (valuesArray) => valuesArray.value,
            template: (valuesArray) => valuesArray.value,
          }}
          required={mandatory}
        />
      </div>
    </>
  );
};

const BooleanElement: React.FC<{
  parameterName: string;
  note: string;
  mandatory: boolean;
  assets: string;
  values: {
    value: string;
    comply?: boolean;
  }[];
}> = ({ parameterName, note, values, mandatory }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.labelElement}>
        {mandatory ? `${parameterName}*` : parameterName} <AppTooltip note={note} />
      </div>
      <div
        className={cn(classes.formelement, 'multi-select')}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        {values?.length > 0 &&
          values.map((item) => (
            <div key={item.value} className={classes.multiSelectValue}>
              {item.value}
            </div>
          ))}
      </div>
    </>
  );
};

const PictureElement: React.FC = () => {
  const classes = useStyles();

  return (
    <FormControlLabel
      labelPlacement="top"
      className={classes.label}
      label="Files"
      control={
        <div className={classes.iconWrapper}>
          <img src={photoIcon} alt="Files" />
        </div>
      }
    />
  );
};

const SignatureElement: React.FC = () => {
  const classes = useStyles();

  return (
    <FormControlLabel
      labelPlacement="top"
      className={classes.label}
      label="Signature"
      control={
        <div className={classes.iconWrapper}>
          <img src={signatureIcon} alt="Signature" />
        </div>
      }
    />
  );
};

const FrequencyElement: React.FC<{
  deadline?: string | null;
  reOccurence?: ReOccurenceInput;
}> = ({ deadline, reOccurence: { endDate, type } = {} }) => {
  const { tt } = useText('reOccurence', 'common');
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <FormControlLabel
        labelPlacement="top"
        className={classes.label}
        label="Frequency"
        control={<div className={classes.frequency}>{R.isNil(type) ? '' : tt('reOccurence')('types')(type)}</div>}
      />
      {(deadline || endDate) && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!!deadline && (
            <FormControlLabel
              className={cn(classes.label, classes.frequencyDate)}
              labelPlacement="top"
              label="First Occurence Date"
              control={<div className={classes.frequency}>{theme.dateFormat.normal(new Date(deadline))}</div>}
            />
          )}
          {!!endDate && (
            <FormControlLabel
              className={cn(classes.label, classes.frequencyDate)}
              labelPlacement="top"
              label="End Date"
              control={<div className={classes.frequency}>{theme.dateFormat.normal(new Date(endDate))}</div>}
            />
          )}
        </div>
      )}
    </div>
  );
};

const DraggerWrapper: React.FC<{
  children: React.ReactElement;
  count: number;
  isDraggable: boolean;
  isEditMode: boolean;
  isFormElement: boolean;
  loading: boolean;
}> = ({ children, isDraggable, count, isEditMode, isFormElement, loading }) => {
  const classes = useStyles();

  if (!isFormElement) {
    return (
      <div style={{ backgroundColor: isEditMode ? 'rgba(211, 219, 255, 0.22)' : '#fff', marginBottom: 10 }}>
        {children}
      </div>
    );
  }

  return isDraggable ? (
    <Draggable key={children.key || ''} draggableId={`${children.key}${count}`} index={count}>
      {(provided, snapshot) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <div
            className={cn(classes.formElement, {
              [classes.active]: snapshot.isDragging,
            })}
            style={{ opacity: loading ? 0.2 : 1 }}
          >
            {children}
            <OpenWithIcon />
          </div>
        </div>
      )}
    </Draggable>
  ) : (
    <div className={classes.formElement} style={{ backgroundColor: isEditMode ? 'rgba(211, 219, 255, 0.22)' : '#fff' }}>
      {children}
    </div>
  );
};

const FormTemplate: React.FC<{
  handleClick: (_: React.RefObject<HTMLElement>, __: React.RefObject<HTMLElement>[], ___: TaskType) => () => void;
  activeRef: React.RefObject<HTMLElement> | null;
  formRef?: React.RefObject<HTMLElement>;
  templateForm: string;
  type: TaskType;
  selectedKey: string;
  setSelectedKey: (value: string) => boolean;
  onActivate: () => void;
  active: boolean;
  onOrderUpdate: (value: (JSX.Element | null)[]) => void;
  loading: boolean;
}> = ({
  handleClick,
  formRef,
  activeRef,
  templateForm,
  selectedKey,
  setSelectedKey,
  type,
  onActivate,
  active,
  onOrderUpdate,
  loading,
}) => {
  const classes = useStyles();
  const [reOccurenceHaventSetError, setReOccurenceHaventSetError] = useState(false);
  const [activating, setActivating] = useState(false);
  const { t } = useText('common');

  let template: FormContent;
  try {
    template = JSON.parse(templateForm);
  } catch (error) {
    template = [];
  }

  const formElements = template
    .map((formItem: FormItem | TextFormItem) => {
      switch (formItem.type) {
        case 'TEXT':
          return (
            <div key={formItem.parameterName}>
              <TextElement {...(formItem as TextFormItem)} />
            </div>
          );
        case 'DATE':
          return (
            <div key={formItem.parameterName}>
              <DateElement {...(formItem as TextFormItem)} />
            </div>
          );
        case 'BOOLEAN':
          return (
            <div key={formItem.parameterName}>
              <BooleanElement {...(formItem as MultivalueFormItem)} />
            </div>
          );
        case 'MULTIVALUE':
          return (
            <div key={formItem.parameterName}>
              <MultiValueElement {...(formItem as MultivalueFormItem)} />
            </div>
          );
        case 'MULTISELECT':
          return (
            <div key={formItem.parameterName}>
              <MultiSelectElement {...(formItem as MultiSelectFormItem)} />
            </div>
          );
        case 'NUMERIC': {
          return (
            <div key={formItem.parameterName}>
              <TextElement {...(formItem as NumericFormItem)} />
            </div>
          );
        }
        case 'FILES':
          return (
            <div key={formItem.parameterName}>
              <PictureElement />
            </div>
          );
        case 'SIGNATURE':
          return (
            <div key={formItem.parameterName}>
              <SignatureElement />
            </div>
          );
        default:
          return null;
      }
    })
    .filter((element: React.ReactNode) => !!element);

  formElements.unshift(
    ...[
      <div key="_add_parameter" className={cn(classes.formelement, { 'no-side-padding': true })}>
        <div className={classes.linkButton}>
          <img src={addIcon} alt="Add parameter" />
          <div>Add parameter</div>
        </div>
      </div>,
    ],
  );

  const frequencyItem = template.find((formItem: FormItem) => formItem.type === 'FREQUENCY');
  if (frequencyItem && frequencyItem.type === FormContentType.FREQUENCY) {
    formElements.push(
      ...[
        // <div key="frequencySeparator" className={classes.formSectionSeparator} />,
        <div key={frequencyItem.parameterName}>
          <FrequencyElement {...frequencyItem} />
          {reOccurenceHaventSetError && !frequencyItem.reOccurence ? (
            <AppFieldError message="Please choose Frequency type" />
          ) : null}
        </div>,
      ],
    );
  }

  const [formTemplateRefs, setFormTemplateRefs] = useState<React.RefObject<HTMLElement>[]>();

  useEffect(() => {
    setFormTemplateRefs(formElements.map(() => React.createRef<HTMLElement>()));
  }, [formElements.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnDragEnd = (res: DropResult) => {
    const ordered = Array.from(formElements);

    if (res.destination) {
      const [reorderedItems] = ordered.splice(res.source.index, 1);
      ordered.splice(res.destination.index, 0, reorderedItems);
    }

    if (ordered) {
      onOrderUpdate(ordered);
    }
  };

  return formTemplateRefs ? (
    <Box className={classes.formBox} {...{ ref: formRef }}>
      <div className={classes.form}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="parameters">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {formElements.map((element: JSX.Element | null, count: number) => {
                  return (
                    element && (
                      <DraggerWrapper
                        key={element.key || ''}
                        count={count}
                        isDraggable={!activeRef}
                        isEditMode={activeRef === formTemplateRefs[count]}
                        isFormElement={element.key !== '_add_parameter'}
                        loading={loading}
                      >
                        <FormElementWrapper key={element.key || ''}>
                          {React.cloneElement(element, {
                            ref: formTemplateRefs[count],
                            onClick: () => {
                              const sameKey = selectedKey === element.key;
                              let abort = false;
                              if (sameKey) {
                                abort = setSelectedKey('');
                              } else {
                                abort = setSelectedKey(`${element.key}`);
                              }
                              if (abort) {
                                return;
                              }
                              handleClick(formTemplateRefs[count], formTemplateRefs, type)();
                            },
                          })}
                        </FormElementWrapper>
                      </DraggerWrapper>
                    )
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!active ? (
          <div className={classes.buttonWrapper}>
            <AppButton
              type="submit"
              inProgress={activating}
              onClick={() => {
                setActivating(true);
                const frequency = template.find((field) => field.type === FormContentType.FREQUENCY);
                if (frequency && frequency.type === FormContentType.FREQUENCY && frequency.reOccurence) {
                  onActivate();
                } else {
                  setReOccurenceHaventSetError(true);
                  setActivating(false);
                }
              }}
            >
              {t('common')('save')}
            </AppButton>
          </div>
        ) : null}
      </div>
    </Box>
  ) : null;
};

export default FormTemplate;
