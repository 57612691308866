import React from 'react';

const AppForm: React.FC<
  React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  }
> = ({ children, handleSubmit, ...propsToPass }) => (
  <form noValidate {...propsToPass} onSubmit={handleSubmit}>
    {children}
  </form>
);
export default AppForm;
