import React from 'react';

import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import SitesNotFoundOrLoading from 'components/SitesNotFoundOrLoading';
import DrawingPage from 'containers/shared/DrawingPage';
import AssetsPage from './AssetsPage';
import useSites from '../useSites';
import { Route, Routes, useLocation } from 'react-router';
import { adaptPath } from 'utils/adaptPath';

const AssetsRoutes: React.FC = () => {
  const {pathname} = useLocation();
  const updatedUrl =  adaptPath(pathname, 'assets')
    
  const { current, renderBreadcrumb } = useSites(updatedUrl);


  return current ? (
    <DefaultErrorBoundary>
      {renderBreadcrumb('Asset Register')}
      <Routes>
        <Route path={`drawing/:drawingId`} element={<DrawingPage />} />
        <Route path={`/`} element={<AssetsPage />} />
      </Routes>
    </DefaultErrorBoundary>
  ) : (
    <SitesNotFoundOrLoading path={'/'} name="Assets" />
  );
};

export default AssetsRoutes;
