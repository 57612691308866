import React, { useEffect, useState } from 'react';
import { Box, FormControl, Select, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'utils/cn';
import useFieldStyles from 'components/form/AppField.style';
import useText from 'texts/useText.hook';
import { getDocuments_documents as Document } from 'models/graphql';
import { styleRules } from 'components/form/AppSelectField';

const useStyles = makeStyles(styleRules);

const filterSelectedInit = {
  selectedCategory: 'All',
  selectedUploader: 'All',
  selectedAsset: 'All',
  selectedSystem: 'All',
};

const filterDataInit = {
  filterCategories: [],
  filterUploaders: [],
  filterAssets: [],
  filterSystems: [],
};

interface FilterSelected {
  selectedCategory: string;
  selectedUploader: string;
  selectedAsset: string;
  selectedSystem: string;
}

interface FilterData {
  filterCategories: string[];
  filterUploaders: string[];
  filterAssets: string[];
  filterSystems: string[];
}

interface Props {
  documents: Document[];
  documentsFiltered: Document[];
  setDocumentsFiltered: (val: Document[]) => void;
}

// TODO: create generic Select component?
const DocumentsFilter: React.FC<Props> = ({ documents, documentsFiltered, setDocumentsFiltered }) => {
  const styles = useStyles();
  const fieldStyles = useFieldStyles();
  const { t } = useText('documents');

  const [filterSelected, setFilterSelected] = useState<FilterSelected>(filterSelectedInit);
  const [filterData, setFilterData] = useState<FilterData>(filterDataInit);

  const { selectedCategory, selectedUploader, selectedAsset, selectedSystem } = filterSelected;
  const { filterCategories, filterUploaders, filterAssets, filterSystems } = filterData;

  useEffect(() => {
    if (documents) {
      let filtered = [...documentsFiltered];

      if (
        selectedCategory === 'All' ||
        selectedUploader === 'All' ||
        selectedAsset === 'All' ||
        selectedSystem === 'All'
      ) {
        filtered = [...documents];
      }

      if (selectedCategory !== 'All') {
        filtered = filtered.filter((doc: Document) => doc.category === selectedCategory);
      }
      if (selectedUploader !== 'All') {
        filtered = filtered.filter((doc: Document) => doc.uploadedBy.name === selectedUploader);
      }

      if (selectedAsset !== 'All') {
        filtered = filtered.filter((doc) => doc.assets?.find((asset) => asset.name === selectedAsset));
      }

      if (selectedSystem !== 'All') {
        filtered = filtered.filter((doc) => doc.systems?.find((system) => system.name === selectedSystem));
      }

      setDocumentsFiltered(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, selectedCategory, selectedUploader, selectedAsset, selectedSystem, setDocumentsFiltered]);

  useEffect(() => {
    if (documents) {
      const categoriesFiltered = [...new Set(documents.map((doc: Document) => doc.category))];

      const uploadersFiltered = [...new Set(documents.map((doc: Document) => doc.uploadedBy.name))];

      const assetsFiltered: string[] = [];
      documents.forEach((doc: Document) => {
        return doc.assets?.forEach((asset) => {
          assetsFiltered.push(asset.name);
        });
      }, []);

      const systemsFiltered: string[] = [];
      documents.forEach((doc: Document) => {
        return doc.systems?.forEach((system) => {
          systemsFiltered.push(system.name);
        });
      }, []);

      setFilterData({
        filterCategories: categoriesFiltered as string[],
        filterUploaders: uploadersFiltered as string[],
        filterAssets: [...new Set(assetsFiltered)],
        filterSystems: [...new Set(systemsFiltered)],
      });
    }
  }, [documents]);

  return (
    <div>
      <Box style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }} sx={{
        mb: 2
      }}>
        <FormControl variant="outlined">
          <Select
            value={selectedCategory}
            onChange={(e) => {
              const newValue = e.target.value as string;
              setFilterSelected({ ...filterSelected, selectedCategory: newValue });
            }}
            variant="outlined"
            className={cn(
              styles.selectField,
              fieldStyles.field,
              {
                displayPlaceholder: false,
              },
              styles.addInnerPaddingRight,
            )}
          >
            <MenuItem value="All">All Categories</MenuItem>
            {filterCategories.map((el: string) => (
              <MenuItem value={el} key={el}>
                {t('documents')(el as 'category')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <Select
            value={selectedUploader}
            onChange={(e) => {
              const newValue = e.target.value as string;
              setFilterSelected({ ...filterSelected, selectedUploader: newValue });
            }}
            variant="outlined"
            className={cn(
              styles.selectField,
              fieldStyles.field,
              {
                displayPlaceholder: false,
              },
              styles.addInnerPaddingRight,
            )}
          >
            <MenuItem value="All">All Uploaders</MenuItem>
            {filterUploaders.map((el: string) => (
              <MenuItem value={el} key={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <Select
            value={selectedAsset}
            onChange={(e) => {
              const newValue = e.target.value as string;
              setFilterSelected({ ...filterSelected, selectedAsset: newValue });
            }}
            variant="outlined"
            className={cn(
              styles.selectField,
              fieldStyles.field,
              {
                displayPlaceholder: false,
              },
              styles.addInnerPaddingRight,
            )}
          >
            <MenuItem value="All">All Assets</MenuItem>
            {filterAssets.map((el: string) => (
              <MenuItem value={el} key={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <Select
            value={selectedSystem}
            onChange={(e) => {
              const newValue = e.target.value as string;
              setFilterSelected({ ...filterSelected, selectedSystem: newValue });
            }}
            variant="outlined"
            className={cn(
              styles.selectField,
              fieldStyles.field,
              {
                displayPlaceholder: false,
              },
              styles.addInnerPaddingRight,
            )}
          >
            <MenuItem value="All">All Systems</MenuItem>
            {filterSystems.map((el: string) => (
              <MenuItem value={el} key={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default DocumentsFilter;
