import React from 'react';
import { useFormikContext } from 'formik';
import AppTextField, { AppTextFieldProps } from './AppTextField';

type AppNumberFieldProps = Omit<Omit<Omit<AppTextFieldProps, 'type'>, 'onChange'>, 'value'> & {
  onChange?: (value: number | null) => void;
};

const AppNumberField: React.FC<AppNumberFieldProps> = ({ onChange, name, ...propsToPass }) => {
  const formContext = useFormikContext<Record<string, unknown>>();

  return (
    <AppTextField
      name={name}
      type="number"
      {...propsToPass}
      onChange={(newValue) => {
        let newValueFloat: number | null = parseFloat(newValue);
        if (Number.isNaN(newValueFloat)) {
          newValueFloat = null;
        }
        formContext.setFieldValue(name, newValueFloat);

        if (onChange) {
          onChange(newValueFloat);
        }
      }}
    />
  );
};

export default AppNumberField;
