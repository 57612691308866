import { Day, Month } from 'models/graphql';

const enCommon = {
  dashboard: 'Dashboard',
  set: 'Set',
  save: 'Save',
  done: 'Done',
  edit: 'Edit',
  cancel: 'Cancel',
  delete: 'Delete',
  upload: 'upload',
  update: 'update',
  created: 'Created',
  close: 'Close',
  noResult: 'No Result',
  variousValues: '[Various Values]',
  selectPlaceholder: 'Select',
  message: 'Message',
  accept: 'accept',
  approve: 'approve',
  deny: 'reject',
  form: 'Form',
  next: 'Next',
  status: 'Status',
  cannotLoad: 'Cannot load',
  emptyValue: 'n/a',
  submit: 'submit',
  account: 'Account',
  logout: 'Logout',
  days: {
    [Day.MONDAY]: 'Monday',
    [Day.TUESDAY]: 'Tuesday',
    [Day.WEDNESDAY]: 'Wednesday',
    [Day.THURSDAY]: 'Thursday',
    [Day.FRIDAY]: 'Friday',
    [Day.SATURDAY]: 'Saturday',
    [Day.SUNDAY]: 'Sunday',
  },
  months: {
    [Month.JANUARY]: 'January',
    [Month.FEBRUARY]: 'February',
    [Month.MARCH]: 'March',
    [Month.APRIL]: 'April',
    [Month.MAY]: 'May',
    [Month.JUNE]: 'June',
    [Month.JULY]: 'July',
    [Month.AUGUST]: 'August',
    [Month.SEPTEMBER]: 'September',
    [Month.OCTOBER]: 'October',
    [Month.NOVEMBER]: 'November',
    [Month.DECEMBER]: 'December',
  },
  paginationSummary: '{{totalNodes}} entries. Showing page {{page}} of {{totalPages}}',
  required: '{{field}} is a required field',
};
export default enCommon;
