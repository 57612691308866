import React from 'react';
import { CircularProgress, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { Formik } from 'formik';

import { AppSearchField } from 'components/form';
import useText from 'texts/useText.hook';
import styleUtils from 'style/styleUtils';
import { useBreadcrumb } from 'components/Breadcrumb';
import { getClientsStatistic, getClientsStatisticVariables } from 'models/graphql';
import { GET_CLIENTS_STATISTIC } from '../Clients/graphql';
import { Link } from 'react-router';

const styleRules = (theme: Theme) =>
  createStyles({
    searchField: {
      width: theme.sizes.containerWidth,
      maxWidth: '100%',
    },
    loader: {
      marginTop: theme.spacing(4),
      ...styleUtils.rowCenterCenter,
    },
    clientsContainer: {
      display: 'grid',
      gridGap: theme.spacing(2),
      gridTemplateColumns: 'repeat(auto-fill, 250px)',
      marginTop: theme.spacing(3),
    },
    clientBox: {
      padding: theme.spacing(2),
      background: theme.palette.grey['100'],
      borderRadius: theme.shape.borderRadius * 2,
    },
    clientLogo: {
      width: 40,
      height: 40,
    },
    clientName: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: theme.fontSizes['18'],
      fontWeight: theme.typography.fontWeightLight,
      display: 'inline-block',
    },
    clientDetails: {
      display: 'grid',
      gridTemplateColumns: `${theme.spacing(6)} auto`,
      gridRowGap: theme.spacing(0.5),
    },
    clientDetailsValue: {
      fontWeight: theme.typography.fontWeightBold,
    },
  });
const useStyles = makeStyles(styleRules);

const DashboardPage: React.FC = () => {
  const styles = useStyles();
  const { t } = useText('clients', 'common');
  useBreadcrumb('/dashboard', 'Dashboard');

  const { loading, data, refetch } = useQuery<getClientsStatistic, getClientsStatisticVariables>(GET_CLIENTS_STATISTIC);

  return (
    <>
      <Formik<{ search: string }>
        initialValues={{
          search: '',
        }}
        onSubmit={async ({ search }) => {
          await refetch({ search });
        }}
      >
        {({ submitForm }) => (
          <AppSearchField
            name="search"
            placeholder="Search by company name"
            onCommit={() => submitForm()}
            className={styles.searchField}
          />
        )}
      </Formik>

      {!loading && data && data.clients ? (
        <div className={styles.clientsContainer}>
          {data.clients.length ? (
            data.clients.map((client) => (
              <div key={client.id} className={styles.clientBox}>
                <div>
                  {client.logo ? (
                    <div className={styles.clientLogo}>
                      <img alt={client.companyName} src={client.logo.srcUrl} />
                    </div>
                  ) : null}
                  {/* TODO: status icons */}
                </div>
                <Link to={`/pm/clients/${client.id}`}>
                  <div className={styles.clientName}>{client.companyName}</div>
                </Link>
                <div className={styles.clientDetails}>
                  <div className={styles.clientDetailsValue}>{client.sitesNumber}</div>
                  <div>{t('clients')('site')}</div>
                  <div className={styles.clientDetailsValue}>{client.usersNumber}</div>
                  <div>{t('clients')('users')}</div>
                  <div className={styles.clientDetailsValue}>{client.assetsNumber || 0}</div>
                  <div>{t('clients')('assets')}</div>
                </div>
                {/* TODO: add contract period */}
              </div>
            ))
          ) : (
            <>{t('common')('noResult')}</>
          )}
        </div>
      ) : (
        <div className={styles.loader}>
          <CircularProgress color="primary" />
        </div>
      )}
      {/* TODO: add contractors */}
    </>
  );
};

export default DashboardPage;
