import React, { useState } from 'react';
import { Theme, Typography, Checkbox, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, gql } from '@apollo/client';

import AppButton from 'components/AppButton/AppButton';
import { AppSearchField } from 'components/form';
import AppDialog from 'components/AppDialog';
import { documentFileList, documentFileListVariables } from 'models/graphql';
import CircularProgressCentered from 'components/CircualProgressCentered';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialogContent-root': {
      maxWidth: 'inherit',
    },
  },
  fileList: {
    marginTop: theme.spacing(2),
    height: '300px',
    overflowY: 'scroll',
  },
  file: {
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
    background: '#F3F4F9',
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  leftButton: {
    marginRight: theme.spacing(2),
  },
}));

interface FileProps {
  id: string;
  name: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSelect: (files: FileProps[]) => void;
}

const DOCUMENT_FILE_LIST = gql`
  query documentFileList($siteId: ID!) {
    documents(siteId: $siteId) {
      file {
        id
      }
      name
    }
  }
`;

const DocumentBrowser: React.FC<Props> = ({ open, onClose, onSelect }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const { siteId } = useParams<{ siteId: string }>();
  const [search, setSearch] = useState('');
  const { data, loading } = useQuery<documentFileList, documentFileListVariables>(DOCUMENT_FILE_LIST, {
    variables: { siteId },
    skip: !siteId,
  });

  const documents = data?.documents.filter(({ name }) => name.toLowerCase().includes(search));
  const handleSelect = () => {
    if (!data) {
      return;
    }
    const files = selected.map((id) => {
      const document = data.documents.find(({ file }) => file.id === id);
      if (!document) {
        return { name: 'invalid', id };
      }
      return { name: document.name, id };
    });
    onSelect(files);
  };
  const toggleSelectionState = (id: string) => {
    setSelected((current) => {
      if (current.includes(id)) {
        return current.filter((c) => c !== id);
      }
      return [...current, id];
    });
  };

  const renderList = () => {
    if (loading) {
      return <CircularProgressCentered />;
    }
    if (!documents || documents.length === 0) {
      const message = search ? 'No results' : 'Document repository is empty';
      return <Typography>{message}</Typography>;
    }
    return documents.map(({ name, file }) => (
      <Box className={classes.file} key={file.id} onClick={() => toggleSelectionState(file.id)}>
        <Checkbox checked={selected.includes(file.id)} color="primary" />
        {name}
      </Box>
    ));
  };

  return (
    <AppDialog
      className={classes.dialog}
      open={open}
      onClose={onClose}
      title="Select File from the Document Repository"
      actions={
        <div className={classes.buttons}>
          <AppButton onClick={onClose} variant="outlined" className={classes.leftButton}>
            Cancel
          </AppButton>
          <AppButton onClick={handleSelect}>Select</AppButton>
        </div>
      }
    >
      <>
        <AppSearchField name="documentsearch" onChange={(q) => setSearch(q.toLowerCase())} />
        <div className={classes.fileList}>{renderList()}</div>
      </>
    </AppDialog>
  );
};

export default DocumentBrowser;
