import { Box } from '@mui/material';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import filledStarIcon from 'assets/filledStarIcon.svg';
import starIcon from 'assets/starIcon.svg';

const Rating: React.FC<{
  rating: number | null | undefined;
  changeable?: boolean;
  addMargin?: boolean;
  onRatingChanged?: (i: number) => void;
  noRating?: JSX.Element;
}> = ({ rating, noRating, changeable, onRatingChanged, addMargin }) => {
  const [selected, setSelected] = useState<number | undefined>(undefined);
  if (rating === undefined || rating === null) return noRating || null;

  const onClick = (i: number) => {
    setSelected(i);
    if (onRatingChanged) {
      onRatingChanged(i);
    }
  };

  const stars = [];
  for (let i = 0; i < 5; i += 1) {
    stars.push(
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <img
        src={
          (changeable && selected && selected > i) || (!changeable && Math.round(rating) > i)
            ? filledStarIcon
            : starIcon
        }
        alt="star"
        height={18}
        key={uuidv4()}
        style={{ marginLeft: addMargin && i > 0 ? '5px' : '1px', cursor: changeable ? 'pointer' : 'default' }}
        onClick={() => onClick(i + 1)}
        onKeyPress={() => onClick(i + 1)}
      />,
    );
  }
  return (
    <Box
      sx={{
        minWidth: addMargin ? '110px' : '95px',
        whiteSpace: "nowrap"
      }}>
      {stars}
    </Box>
  );
};

export default Rating;
