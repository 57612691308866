import React, { useEffect, useState } from 'react';

import {
  taskForClient_task as ClientTask,
  taskForContractor_task as ContractorTask,
  taskAttachFile,
  taskAttachFileVariables,
  TaskStatus,
  taskRemoveAttachment,
  taskRemoveAttachmentVariables,
} from 'models/graphql';
import Item from 'components/Item';
import useText from 'texts/useText.hook';
import AssetComplianceIcon from 'components/complianceIcons/AssetComplianceIcon';
import cn from 'utils/cn';
import useMode, { Mode } from 'utils/useMode.hook';
import { completedTaskStatuses } from 'shared/taskStatusGroups';
import { useStores } from 'stores';
import { formatDuration } from 'date-fns';
import FileInputLink from 'components/FileInput/FileInputLink';
import { useMutation } from '@apollo/client';
import apolloCacheEvict from 'utils/apolloCacheEvict';
import { TASK_ATTACH_FILE, TASK_REMOVE_ATTACHMENT } from 'containers/shared/Technician/graphql';
import RemoveDialog from 'components/RemoveDialog';
import { Theme, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import removeIcon from 'assets/removeIcon.svg';
import { Link, useParams } from 'react-router';


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(2),
    },
    section: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
    sectionHeader: {
      marginBottom: theme.spacing(1),
      fontSize: theme.fontSizes[12],
      fontWeight: theme.typography.fontWeightBold,
      '&>span': {
        float: 'right',
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        paddingRight: theme.spacing(1),
      },
    },
    sectionItem: {
      display: 'flex',
      marginBottom: theme.spacing(0.5),
      padding: `${theme.spacing(1)} 0`,
      background: theme.palette.grey[100],
      fontSize: theme.fontSizes[12],

      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
    },
    sectionItemCompleted: {
       backgroundColor: '#E2FFDC',    //'#eff5da', //'#d0f0c0',
    },
    sectionItemInProgress: {
      backgroundColor:  '#80808021', //'#fcf9e1', //#fff9c4',
    },
    sectionItemIntact: {
      backgroundColor: '#FFFFFF',
    },
    sectionItemPart: {
      flexGrow: 2,
      flexBasis: 0,
      padding: `0 ${theme.spacing(1)}`,

      textAlign: 'center',
      '&:first-child': {
        textAlign: 'left!important' as 'left',
      },
      '&:last-child': {
        textAlign: 'right',
      },
    },
    sectionItemPartIcons: {
      flexGrow: 0.5,
    },
    sectionRemoveButton: {
      marginRight: '8',
      height: '17',
    },
  });
});

export interface selproject {
  id: string;
  projectname: string;
  startdate: Date;
  enddate: Date;
}


function formatDate(isoDate: any) {
  // Create a Date object from the ISO string
  if (isoDate)
  {
 const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    // Return the formatted date string as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  }
  else {
    return ` `;
  }
 
}


const TaskDetails: React.FC<{
  task: ClientTask | ContractorTask;
  assetLink?: (assetId: string, siteId: string) => string;
  onDownload: (fileId: string) => void;
}> = ({ task, assetLink, onDownload }) => {
  const { t } = useText('tasks');
  const theme = useTheme();
  const styles = useStyles();
  const mode = useMode();
  const { authStore } = useStores();
  const { siteId } = useParams<{ siteId?: string }>();
  const selfAssignedTask =
    (task as ClientTask).internalResponsible && (task as ClientTask).internalResponsible?.id === authStore.user?.id;
  // Delete attachment of self assigned task
  const [deleteAttachmentDialog, setDeleteAttachmentDialog] = useState(false);
  const [deleteAttachmentDialogData, setDeleteAttachmentDialogData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [projectDisplay, setProjectDisplay] = useState('')
  const [projectDeets, setProjectDeets] = useState<selproject[]>([]);

  const closeDeleteAttachmentDialog = () => setDeleteAttachmentDialog(false);
  const openDeleteAttachmentDialog = () => {
    setDeleteAttachmentDialog(true);
  };

  console.log("Task Looked At:", task)

  const getProjectDets = async (projid: any) => {
    let getProjectDetails = `projects/project/${siteId}?projid=${projid}`;
    try {
     await fetch(`${process.env.API_ROOT}${getProjectDetails}`, {
       method: 'GET',
       credentials: 'include',
     }).then(async (response:any) => {
       const dataret = await response.json();
       console.log("dataret:", dataret)
     let projectdisp = dataret[0].projectname +"  (" +  formatDate(dataret[0].startdate) + " - " + formatDate(dataret[0].enddate) + ")"; 

     setProjectDisplay(projectdisp)

       setProjectDeets(dataret); // Store the data into state
       setIsLoading(false); // Update loading state
     });
     }  
     catch (error) {
       console.error(error); /* eslint-disable-line */
     }



  }


  // Use useEffect to fetch project details on mount
  useEffect(() => {
    if (task.projectid) {
      getProjectDets(task.projectid);
    } else {
      setIsLoading(false); // Ensure we stop loading if there's no project ID
    }
  }, [task.projectid]);
 


  const taskLink = (taskIdReadable: string): string => {
    if (mode.mode === Mode.CLIENT) {
      return `/client/${mode.id}/tasks/${siteId}?search=${taskIdReadable}`;
    }
    if (mode.mode === Mode.CONTRACTOR) {
      return `/contractor/${mode.id}/work-instructions?search=${taskIdReadable}`;
    }
    return '';
  };

  const [attachFile] = useMutation<taskAttachFile, taskAttachFileVariables>(TASK_ATTACH_FILE, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Task',
        id: task.id,
        fieldName: 'attachments',
      }),
  });

  const [removeAttachment] = useMutation<taskRemoveAttachment, taskRemoveAttachmentVariables>(TASK_REMOVE_ATTACHMENT, {
    update: (cache) =>
      apolloCacheEvict({
        cache,
        typename: 'Task',
        id: task.id,
        fieldName: 'attachments',
      }),
  });

 /* if (task.projectid)
  {
          getProjectDets(task.projectid)
    
  } */

  const onFileUpload = (id: string) => attachFile({ variables: { taskId: task.id, fileId: id } });

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
      <div className={styles.container}>
        {task.correctiveOf ? (
          <Item
            small
            label={t('tasks')('correctiveOf')}
            value={task.correctiveOf.idReadable}
            internalLink={taskLink(task.correctiveOf.idReadable)}
          />
        ) : null}
        {task.corrective ? (
          <Item
            small
            label={t('tasks')('correctiveTask')}
            value={task.corrective.idReadable}
            internalLink={taskLink(task.corrective.idReadable)}
          />
        ) : null}
        {'site' in task ? <Item small label={t('tasks')('client')} value={task.site.companyName} /> : null}
        {task.contractorResponsibleWorkOrderId ? (
          <Item small label={t('tasks')('workOrderId')} value={task.contractorResponsibleWorkOrderId} />
        ) : null}
        {'site' in task ? <Item small label={t('tasks')('site')} value={task.site.name} /> : null}
        {'contractorResponsible' in task && task.contractorResponsible ? (
          <Item small label={t('tasks')('contractorResponsible')} value={task.contractorResponsible.companyName} />
        ) : null}
        {'internalResponsible' in task && task.internalResponsible ? (
          <Item small label={t('tasks')('responsible')} value={task.internalResponsible.name} />
        ) : null}
        {'assignedAt' in task && task.assignedAt ? (
          <Item small label={t('tasks')('assignedAt')} value={theme.dateFormat.normal(task.assignedAt)} />
        ) : null}
        {'timer' in task &&
          task.timer &&
          (completedTaskStatuses.includes(task.status) || task.status === TaskStatus.IN_PROGRESS) ? (
          <Item
            small
            label={t('tasks')('worksTime')}
            value={formatDuration({
              hours: Math.floor(task.timer.timeSpent / 3600),
              minutes: Math.floor((task.timer.timeSpent % 3600) / 60),
              seconds: task.timer.timeSpent % 60,
            })}
          />
        ) : null}
        {task.tasktitle ? <Item small label={t('tasks')('tasktitle')} value={task.tasktitle} /> : null}
        {task.notes ? <Item small label={t('tasks')('notes')} value={task.notes} /> : null}


        {task.projectid && projectDeets.length > 0 ? ( // Check if projectDeets has data
              (<Item small label='Project' value={projectDisplay} />)
            ) : null}



        <Item
          small
          danger={task.urgent}
          label={t('tasks')('priority')}
          value={task.urgent ? t('tasks')('priorityUrgent') : t('tasks')('priorityNormal')}
        />
        {task.attachments.length || selfAssignedTask ? (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              {t('tasks')('attachments')}
              {selfAssignedTask &&
                [
                  TaskStatus.CONTRACTOR_ACCEPTED,
                  TaskStatus.IN_PROGRESS,
                  TaskStatus.COMPLETED,
                  TaskStatus.REJECTED,
                ].includes(task.status) && (
                  <span>
                    <FileInputLink
                      inputId={`fileUpload-${task.id}`}
                      label={t('tasks')('addAttachment')}
                      onUpload={onFileUpload}
                    />
                  </span>
                )}
            </div>
            {task.attachments.map((attachment) => (
              <div key={attachment.id} className={styles.sectionItem}>
                <div className={styles.sectionItemPart}>
                  <button type="button" onClick={() => onDownload(attachment.id)}>
                    <u>{attachment.originalName}</u>
                  </button>
                </div>
                {selfAssignedTask &&
                  [
                    TaskStatus.CONTRACTOR_ACCEPTED,
                    TaskStatus.IN_PROGRESS,
                    TaskStatus.COMPLETED,
                    TaskStatus.REJECTED,
                  ].includes(task.status) && (
                    <button
                      className={styles.sectionRemoveButton}
                      type="button"
                      onClick={() => {
                        setDeleteAttachmentDialogData(attachment.id);
                        openDeleteAttachmentDialog();
                      }}
                    >
                      <img src={removeIcon} alt="Delete Attachment" />
                    </button>
                  )}
              </div>
            ))}
          </div>
        ) : null}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            {t('tasks')('assets')}
            <span>
              <Link
                to={mode.mode === Mode.CLIENT ? `${task.id}/report` : `${task.id}/report`}
              >
                {t('tasks')('report')}
              </Link>
            </span>
          </div>
          {[...task.assets]
          .sort((a, b) => a.asset.name.localeCompare(b.asset.name))
          .map((taskAsset) => {
            // Apply conditional classes based on the status
            
           
            const statusDisplay = 
            taskAsset.status === 'COMPLETED'
              ? 'Completed'
              : taskAsset.status === 'IN_PROGRESS'
              ? 'In Progress'
              : 'Sent';
            const statusClass = 
              taskAsset.status === 'COMPLETED'
                ? styles.sectionItemCompleted
                : taskAsset.status === 'IN_PROGRESS'
                ? styles.sectionItemInProgress
                : styles.sectionItemIntact;

            return (
              <div key={taskAsset.id} className={cn(styles.sectionItem, statusClass)}>
                <div className={styles.sectionItemPart}>
                  {assetLink ? (
                    <Link
                      to={
                        mode.mode === Mode.CLIENT
                          ? `${task.id}/report?asset=${taskAsset.asset.id}`
                          : `work-instructions/${task.id}/report`
                      }
                    >
                      {taskAsset.asset.name}
                    </Link>
                  ) : (
                    taskAsset.asset.name
                  )}
                </div>
                <div className={styles.sectionItemPart}>{taskAsset.asset.system.name}</div>
                <div className={styles.sectionItemPart}>{statusDisplay}</div>
                <div className={styles.sectionItemPart}>
                { taskAsset.status === 'COMPLETED' ? (
                      formatDate(taskAsset.updatedAt)
                ): (
                  ''
                )}
                </div>
                <div className={cn(styles.sectionItemPart, styles.sectionItemPartIcons)}>
                  <AssetComplianceIcon asset={taskAsset} small />
                </div>
              </div>
            );
          })}
        </div>
        {selfAssignedTask && (
          <RemoveDialog
            open={deleteAttachmentDialog}
            deleteIcon
            title="Delete Attachment?"
            descriptionText="Attachment will be deleted."
            submitText="Delete"
            onSend={async () => {
              if (!deleteAttachmentDialogData) return;
              await removeAttachment({
                variables: {
                  taskId: task.id,
                  fileId: deleteAttachmentDialogData,
                },
              });
            }}
            onClose={closeDeleteAttachmentDialog}
          />
        )}
      </div>  )}
    </div>
  );
  
};

export default TaskDetails;
