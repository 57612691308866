import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppIconButton from 'components/AppIconButton';
import editIcon from 'assets/editIcon.svg';
import archiveIcon from 'assets/archiveIcon.svg';
import { useMutation, useQuery } from '@apollo/client';
import {
  ClientStatus,
  clientStatusUpdate,
  clientStatusUpdateVariables,
  getClient,
  getClientVariables,
} from 'models/graphql';
import Logo from 'components/Logo';
import useMode, { Mode } from 'utils/useMode.hook';
import switcher from 'utils/switcher';
import RemoveDialog from 'components/RemoveDialog';
import { UPDATE_STATUS } from 'containers/Pm/Clients/graphql';
import { GET_CLIENT } from './graphql';
import { useStores } from 'stores';
import { useParams, useLocation, Link } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  marginRight: {
    marginRight: theme.spacing(3),
  },
  link: {
    textDecorationLine: 'underline',
    color: theme.palette.primary.main,
  },
}));

const Item: React.FC<{ name: string; value?: string | React.ReactNode }> = ({ name, value }) => (
  <Box sx={{
    mb: 3
  }}>
    <Typography>{name}</Typography>
    <Typography sx={{
      color: "textSecondary"
    }}>{value}</Typography>
  </Box>
);

const ClientBasicDetailsPage: React.FC = () => {
  const styles = useStyles();
  const location = useLocation();
  const { clientId } = useParams<{ clientId: string }>();
  const { mode } = useMode();
  const { data, loading } = useQuery<getClient, getClientVariables>(GET_CLIENT, { variables: { id: clientId } });
  const [updateStatus] = useMutation<clientStatusUpdate, clientStatusUpdateVariables>(UPDATE_STATUS);
  const [archive, setArchive] = useState(false);
  const closeArchive = () => setArchive(false);
  const openArchive = () => {
    setArchive(true);
  };

  const {authStore} = useStores();
  const ifconsultant =  authStore.user?.isconsultant

  const clientUrl = `client/${data?.client.id}`;
  return <>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}>
      {mode === Mode.PM && !ifconsultant && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}>
          <AppIconButton onClick={openArchive} className={styles.marginRight}>
            <img alt="archive" src={archiveIcon} />
          </AppIconButton>
          <Link to={`/pm/clients/${clientId}/edit`}>
            <AppIconButton>
              <img alt="update" src={editIcon} />
            </AppIconButton>
          </Link>
        </Box>
      )}
      {loading ? (
        <CircularProgress color="primary" />
      ) : (
        <Box sx={{
          display: "flex"
        }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1
            }}>
            <Item name="Company Name" value={data?.client.companyName} />
            <Item name="Contact Name" value={data?.client.contactName} />
            <Item name="Contact Email" value={data?.client.contactEmail} />
            <Item name="Contact Phone" value={data?.client.contactPhone} />
            {mode === Mode.PM && (
              <Item
                name="Client Portal Address"
                value={
                  <a className={styles.link} href={clientUrl} target="_blank" rel="noopener noreferrer">
                    {`${window.location.href.split(location.pathname)[0]}/${clientUrl}`}
                  </a>
                }
              />
            )}
          </Box>
          <Box sx={{
            flex: 1
          }}>
            <Typography>Logo</Typography>
            <Logo src={data?.client.logo?.srcUrl || null} alt={`logo of ${data?.client.companyName}`} />
          </Box>
        </Box>
      )}
    </Box>
    <RemoveDialog
      open={archive}
      title={`${data?.client.status === ClientStatus.ACTIVE ? 'Archive' : 'Activate'} Client?`}
      descriptionText={
        data?.client.status === ClientStatus.ACTIVE
          ? 'The client details, users and data will be moved to the Archive.'
          : 'The client will be activated.'
      }
      agreementText={
        data?.client.status === ClientStatus.ACTIVE
          ? 'I understand that the client will not be able to access their portal.'
          : 'I understand that the client will be able to access their portal.'
      }
      onSend={async () => {
        await updateStatus({
          variables: {
            id: clientId,
            status: switcher(data?.client.status, [
              [ClientStatus.ACTIVE, ClientStatus.ARCHIVED],
              [ClientStatus.ARCHIVED, ClientStatus.ACTIVE],
            ])!,
          },
        });
      }}
      submitText={data?.client.status === ClientStatus.ACTIVE ? 'Archive' : 'Activate'}
      onClose={closeArchive}
    />
  </>;
};

export default ClientBasicDetailsPage;
