import { ApolloCache, Cache } from '@apollo/client';

export default function apolloCacheEvict({
  cache,
  typename,
  id,
  fieldName,
  args,
  withoutGc,
}: {
  cache: ApolloCache<unknown>;
  typename: 'Client' | 'Contractor' | 'Site' | 'Subscription' | 'AssetOutput' | 'Task' | 'System';
  id: string;
  fieldName?: string;
  args?: Cache.EvictOptions['args'];
  withoutGc?: boolean;
}) {
  cache.evict({ id: `${typename}:${id}`, fieldName, args });
  if (!withoutGc) cache.gc();
}

export function apolloCacheEvictQuery({
  cache,
  query,
  args,
  withoutGc,
}: {
  cache: ApolloCache<unknown>;
  query: string;
  args?: Cache.EvictOptions['args'];
  withoutGc?: boolean;
}) {
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: query,
    args,
  });
  if (!withoutGc) cache.gc();
}
