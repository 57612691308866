import { useQuery } from '@apollo/client';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ActionsButton from 'components/ActionsButton';
import AppButtonLink from 'components/AppButtonLink';
import AppTable from 'components/AppTable';
import ContentStateWrapper from 'components/ContentStateWrapper';
import { AppSelectField } from 'components/form';
import { FullScreenPage } from 'components/layout';
import { Formik } from 'formik';
import { getSystems, getSystemsVariables, SystemType } from 'models/graphql';
import React from 'react';
import useText from 'texts/useText.hook';
import { GET_SYSTEMS } from '../graphql';
import { useNavigate, useParams } from 'react-router';

// Styles
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      minWidth: 650,
    },
    controllers: {
      display: 'flex',
    },
    filters: {
      marginRight: 'auto',
      marginBottom: theme.spacing(3),
      display: 'flex',

      '&>:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
  });
});

const ClientSystemsPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();

  // Queries
  const { data: systemsData, loading: systemsDataLoading } = useQuery<getSystems, getSystemsVariables>(GET_SYSTEMS, {
    variables: { clientId },
  });
  if (!systemsDataLoading && !systemsData) {
    throw new Error('sitesData has to be defined, when sitesDataLoading is false.');
  }
  const systems = systemsData?.client.systems;
  const allowedSystemTypes = systemsData?.client.allowedSystems;
  if (allowedSystemTypes && !allowedSystemTypes[0]) {
    throw new Error('allowedSystemTypes array is empty.');
  }
  const sites = systemsData?.client.sites;
  if (sites && !sites[0]) {
    throw new Error('sites array is empty.');
  }

  const navigate = useNavigate();
  const styles = useStyles();
  const { t } = useText('clients', 'systemTypes');
  return (
    <FullScreenPage title={t('clients')('systemsTitle')} cancel={`/client/${clientId}/configure/assets`}>
      <ContentStateWrapper loading={systemsDataLoading}>
        {sites && systems && allowedSystemTypes ? (
          <Formik<{ siteId: string; systemType: SystemType }>
            initialValues={{ siteId: sites[0].id, systemType: allowedSystemTypes[0] }}
            onSubmit={() => {
              // Do nothing
            }}
          >
            {({ values }) => {
              return (
                <div className={styles.container}>
                  <div className={styles.controllers}>
                    <div className={styles.filters}>
                      <AppSelectField
                        name="siteId"
                        options={{
                          array: sites,
                          value: (site) => site.id,
                          key: (site) => site.id,
                          template: (site) => site.name,
                        }}
                      />
                      <AppSelectField
                        name="systemType"
                        options={{
                          array: allowedSystemTypes,
                          value: (systemType) => systemType,
                          key: (systemType) => systemType,
                          template: (systemType) => t('systemTypes')(systemType),
                        }}
                      />
                    </div>
                    <div>
                      <AppButtonLink
                        to={{ pathname: `/client/${clientId}/configure/assets/systems/add`, state: values }}
                      >
                        {t('clients')('addSystem')}
                      </AppButtonLink>
                    </div>
                  </div>

                  <AppTable
                    data={systems.filter(
                      (system) => system.site.id === values.siteId && system.type === values.systemType,
                    )}
                    noDataMessage={t('clients')('noSystemsForFilter', {
                      type: t('systemTypes')(values.systemType),
                      site: sites.find((site) => site.id === values.siteId)?.name,
                    })}
                    columns={[
                      {
                        name: t('clients')('systemName'),
                        template: (system) => system.name,
                      },
                      {
                        name: t('clients')('systemType'),
                        template: (system) => system.type,
                      },
                      {
                        name: t('clients')('numberOfAssets'),
                        template: (system) => system.numberOfAssets,
                      },
                      {
                        key: 'actions',
                        link: null,
                        template: function ActionsTemplate(system) {
                          return (
                            <ActionsButton
                              actions={[
                                {
                                  title: t('clients')('editSystem'),
                                  action: () => {
                                    navigate(`${system.id}/edit`);
                                  },
                                },
                              ]}
                            />
                          );
                        },
                      },
                    ]}
                  />
                </div>
              );
            }}
          </Formik>
        ) : null}
      </ContentStateWrapper>
    </FullScreenPage>
  );
};

export default ClientSystemsPage;
