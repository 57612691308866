import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useTasksPageStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      padding: `0 ${theme.sizes.defaultContainerPadding.mobile}px ${theme.sizes.defaultContainerPadding.mobile}px`,
    },
    filterContainer: {
      position: 'sticky',
      top: 0,
      background: theme.palette.background.default,
    },
    filter: {
      padding: `${theme.sizes.defaultContainerPadding.mobile}px 0`,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1.5fr',
      },
    },
    filterFullRow: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
    filterHalfRowMd: {
      [theme.breakpoints.up('md')]: {
        gridColumnStart: 1,
        gridColumnEnd: 2,
      },
    },
    filterPeriodType: {
      justifySelf: 'center',
    },
    nextLineMd: {
      [theme.breakpoints.up('md')]: {
        gridColumnStart: 1,
      },
    },
    taskCardContainer: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
    paddingTopMd: {
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    worksContainer: {},
    adhocBtn: { display: 'flex' },
  });
});

export default useTasksPageStyles;
