import React from 'react';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useMode, { Mode } from 'utils/useMode.hook';
import { assetsForSite_site_assets as clientAssetsClientAssets } from 'models/graphql';
import useText from 'texts/useText.hook';
import Item from 'components/Item';
import AppAvatar from 'components/AppAvatar';
import DetailsPanelSection from './components/DetailsPanelSection';
import { useParams, useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  profileDataContainer: {
    padding: theme.spacing(3),
  },
  ownerContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 5fr',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
}));

const AssetDetailsPanel: React.FC<{
  asset: clientAssetsClientAssets;
}> = ({ asset }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { siteId, clientId } = useParams<{ siteId: string; clientId: string }>();
  const { t } = useText('assetTypes', 'taskTypes');
  const isTablet = useMediaQuery('(max-width:1024px)');
  const { pathname } = useLocation();
  const state = useMode();
  const mode = state.mode !== Mode.OPERATIVE && state.mode !== Mode.TECHNICIAN;

  return (
    <div>
      {isTablet && mode && (
        <Box className={styles.ownerContainer}>
          <div className={styles.paddingRight}>
            <AppAvatar imageSrc={asset.owner.picture?.srcUrl} name={asset.owner.name} />
          </div>
          <div>
            <Item label="Asset Owner" value={asset.owner.name} />
          </div>
        </Box>
      )}
      <Box className={styles.profileDataContainer}>
        <Item label="Asset ID" value={asset.id} />
        <Item label="Asset Type" value={t('assetTypes')(asset.type)} />
        {isTablet && (state.mode === Mode.OPERATIVE || state.mode === Mode.TECHNICIAN) && (
          <>
            <Item label="System Reference" value={asset.system.name} />
            <Item label="System Type" value={asset.system.type} />
          </>
        )}
        <Item label="Created" value={theme.dateFormat.normal(asset.createdAt)} />
        {isTablet && (state.mode === Mode.OPERATIVE || state.mode === Mode.TECHNICIAN) && (
          <Item label="Asset owner" value={asset.owner.name} />
        )}
        <Item label="Department" value={asset.department} />
        {isTablet && (state.mode === Mode.OPERATIVE || state.mode === Mode.TECHNICIAN) && (
        <Item
          label="Coordinates"
          value={asset.coordinates ? `${asset.coordinates?.latitude}, ${asset.coordinates?.longitude}` : null}
          link={
            asset.coordinates
              ? `https://maps.google.com?q=${asset.coordinates?.latitude},${asset.coordinates?.longitude}`
              : undefined
          }
        />
        )}
        {!isTablet && (state.mode === Mode.OPERATIVE || state.mode === Mode.TECHNICIAN) && (
        <Item
          label="Coordinates"
          value={asset.coordinates ? `${asset.coordinates?.latitude}, ${asset.coordinates?.longitude}` : null}
          link={
            asset.coordinates
              ? `https://maps.google.com?q=${asset.coordinates?.latitude},${asset.coordinates?.longitude}`
              : undefined
          }
        />
        )}
         {!isTablet && (state.mode !== Mode.OPERATIVE && state.mode !== Mode.TECHNICIAN) && (
        <Item
          label="Coordinates"
          value={asset.coordinates ? `${asset.coordinates?.latitude}, ${asset.coordinates?.longitude}` : null}
          internalLink={    // FW 2022-03-01 Link to Leaflet mapping and not GMaps
            asset.coordinates
              ? `/client/${clientId}/assets/${siteId}/mapping?lat=${asset.coordinates?.latitude}&long=${asset.coordinates?.longitude}&assetselect=${asset.name}`
              : undefined
          }
        />
        )}
        <Item label="Additional Information" value={asset.additionalInformation} />
        <DetailsPanelSection
          label="Drawings"
          values={
            asset.drawings &&
            asset.drawings.map((drawing) => {
              return {
                id: drawing.id,
                value: `${drawing.file.originalName}`,
                link: `${pathname}/drawing/${drawing.id}`,
              };
            })
          }
          emptyText="No Drawings"
        />
        <DetailsPanelSection
          label={mode ? 'Upcoming Works' : 'Upcoming Tasks'}
          values={
            asset.upcomingWorks &&
            asset.upcomingWorks.map((upcomingWork) => {
              return {
                id: upcomingWork.id,
                value: t('taskTypes')(upcomingWork.type),
                secondaryValue: upcomingWork.deadline ? theme.dateFormat.normal(upcomingWork.deadline) : undefined,
                link:
                  state.mode !== Mode.OPERATIVE && state.mode !== Mode.TECHNICIAN
                    ? `/client/${clientId}/tasks/${siteId}?search=${upcomingWork.idReadable}`
                    : undefined,
              };
            })
          }
          useSecondaryValues
          emptyText={mode ? 'No Upcoming Works' : 'No Upcoming Tasks'}
        />
        <DetailsPanelSection
          label={mode ? 'Recent Works' : 'Recent Tasks'}
          values={
            asset.recentWorks &&
            asset.recentWorks.map((recentWork) => {
              return {
                id: recentWork.id,
                value: t('taskTypes')(recentWork.type),
                secondaryValue: recentWork.deadline ? theme.dateFormat.normal(recentWork.deadline) : undefined,
                link:
                  state.mode !== Mode.OPERATIVE && state.mode !== Mode.TECHNICIAN
                    ? `/client/${clientId}/tasks/${siteId}?search=${recentWork.idReadable}`
                    : undefined,
              };
            })
          }
          useSecondaryValues
          emptyText={mode ? 'No Recent Works' : 'No Recent Tasks'}
        />
      </Box>
    </div>
  );
};

export default AssetDetailsPanel;
