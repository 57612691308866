import React, { useEffect, useRef, useState } from 'react';
import gantt from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import './customstyles.css';
import ViewProjectGantt from './ViewProject'
import Tooltip from "@mui/material/Tooltip";
import { Autorenew } from '@mui/icons-material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { CloudDownloadOutlined } from '@mui/icons-material';
import {InsertChartOutlined}  from '@mui/icons-material';
import {PictureAsPdfOutlined}  from '@mui/icons-material';
import {AccountTreeOutlined}  from '@mui/icons-material';
import { duration } from 'moment/moment';


function findKey(array, keyToFind) {
  return array.find(item => item.key === keyToFind);
}

// Helper function to format date
const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

//const GanttChart = ({ tasks, config, scrollToToday, exportPDF }) => {
const GanttChart = ({ tasks, config, site}) => {
  const [loading, setLoading] = useState(true);
  const ganttContainer = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [ganttData, setGanttData] = useState(null);




  gantt.plugins({
    export_api: true,
    marker: true
  });

  const getSiteProjectsBySearch = async (site, searchtext) => {
    const isMounted = { current: true };
    let getProjectsLink = `projects/projgantt/${site}?searchval=${searchtext}`
    console.log("getProjectsLink:", getProjectsLink)
     try {
       fetch(`${process.env.API_ROOT}${getProjectsLink}`, {
         method: 'GET',
         credentials: 'include',
       }).then(async (response) => {
         const data = await response.json();
         console.log("dataret:", data)

        // get links
        let urlLinks = `projects/projganttlinks/${site}`;
        const linkresponse = await fetch(`${process.env.API_ROOT}${urlLinks}`, {
          method: 'GET',
          credentials: 'include',
        });
        const linksdata = await linkresponse.json();

        if (linksdata) {
          setGanttData({ tasks: data ,links:linksdata  });
         
        }
        else if (data) {
          setGanttData({ tasks: data });
        } else {
          console.error('Invalid data format:', data);
      }


      


        // setprojectsList(dataret)
       });
       }  
       catch (error) {
         console.error(error); /* eslint-disable-line */
       }
   }


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value.length >= 3) {
      // Call your function here
      console.log('Function called with:', value);
      getSiteProjectsBySearch(site,value)
      // search backend based on entered value


    }
  };
  

  useEffect(() => {
    if (ganttData !==null)
    {
      // gantt.parse(ganttData);
      const today = new Date();

      gantt.clearAll(); 

      gantt.parse(JSON.stringify(ganttData));



      // Ensure full-day tasks are properly displayed
      gantt.attachEvent("onTaskLoading", function (task) {
        if (task.start_date.getTime() === task.end_date.getTime()) {
            task.end_date.setHours(23, 59, 59);
        }
        return true;
    });

    // Correct duration calculation if needed
    gantt.attachEvent("onTaskCreated", function (task) {
        if (task.start_date && task.end_date) {
            task.duration = gantt.calculateDuration(task.start_date, task.end_date);
        }
        return true;
    });

    // Function to calculate duration
    gantt.calculateDuration = function (start, end) {
        var start_date = gantt.date.date_part(new Date(start));
        var end_date = gantt.date.date_part(new Date(end));
        var duration = gantt.date.add(end_date, 1, "day") - start_date;
        return Math.ceil(duration / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    };




     gantt.showDate(today);
     let todayMarker = gantt.addMarker({ 
      start_date: today, 
      css: "today", 
      text: 'Now',
      });
    }



    // Sort tasks by start_date column
    gantt.sort("start_date", false);

  }, [ganttData])


  //gantt.config.date_format = "%Y-%m-%d %H:%i";

  function getProgress (task) {
    if (task.progress >= 1)
      return "Complete";
    if (task.progress == 0)
      return "Not started";
    return Math.round(task.progress * 100) + "%";
  };
 

  useEffect(() => {
    if (tasks !=="null")
    {
    gantt.config.scale_unit = "day";
    gantt.config.date_scale = "%d %M";
   // gantt.config.date_format = "%Y-%m-%d";
   gantt.config.date_format = "%Y-%m-%d %H:%i";

    gantt.config.drag_links = false; // Disable linking dots


    gantt.config.order_branch = true;  // Allows moving items between branches
    gantt.config.order_branch_free = true;  // Allows free item positioning
    gantt.config.drag_mode = "order";  // Enable drag for reordering



   
    gantt.attachEvent('onTaskDblClick', (id, e) => {
      console.log("IN GANTT ATTACH EVENT")
      tasks.filter
      // would have to query the data and determine the form to open
      const taskfound = gantt.getTask(id);
      // Convert task object to an array of objects with key and value properties
      const taskArrayOfObjects = Object.keys(taskfound).map(key => ({ key: key, value: taskfound[key] }));
   
      const keyToFind = "text";
      const foundItem = findKey(taskArrayOfObjects, keyToFind); 
   
      let contenttopass = {"projectName": findKey(taskArrayOfObjects, "text").value,
        "projectDesc": findKey(taskArrayOfObjects, "details").value,
        "responsible": findKey(taskArrayOfObjects, "responsible").value,
        "status": findKey(taskArrayOfObjects, "status").value,
        "startDate": findKey(taskArrayOfObjects, "start_date").value,
        "endDate": findKey(taskArrayOfObjects, "end_date").value,
      }


      setSelectedTask(contenttopass);

      setConfirmOpen(true);

      setModalIsOpen(true);
      return false; // Return false to prevent the default action
    });


     // Disable task resizing
     gantt.config.sort = true;
    // gantt.config.drag_resize = false;
    // gantt.config.drag_mode= false;
     gantt.config.open_tree_initially = true;
     gantt.config.drag_progress = false;
     gantt.config.click_drag = false;
     gantt.config.show_progress = true;
     gantt.config.grid_resize = true; // Enable column resizing

     gantt.config.order_branch = true;  // Allows moving items between branches
     gantt.config.order_branch_free = true;  // Allows free item positioning
     gantt.config.drag_mode = "order";  // Enable drag for reordering

      gantt.plugins({
        tooltip: true
      });
      gantt.templates.tooltip_date_format = gantt.date.date_to_str("%F %j, %Y");
      gantt.attachEvent("onGanttReady", function () {
        var tooltips = gantt.ext.tooltips;

        gantt.templates.tooltip_text = function (start, end, task) {
          var store = gantt.getDatastore("resource");
          var assignments = task[gantt.config.resource_property] || [];

          var owners = [];
          assignments.forEach(function (assignment) {
            var owner = store.getItem(assignment.resource_id)
            owners.push(owner.text);
          });
          return "<b>Task:</b> " + task.text +
            "<br/><b>Task ID:</b> " + task.idreadable +
            "<br/><b>Details:</b> " + task.details +
            "<br/><b>Start date:</b> " +
            gantt.templates.tooltip_date_format(start) +
            "<br/><b>End date:</b> " + gantt.templates.tooltip_date_format(end)+
            "<br/><b>Duration:</b> " + task.duration +
            "<br/><b>Progress:</b> " + getProgress(task);
        };

   

        tooltips.tooltipFor({
          selector: ".gantt_scale_cell",
          html: function (event, node) {
            var relativePosition = gantt.utils.dom.getRelativeEventPosition(event, gantt.$task_scale);
            return gantt.templates.tooltip_date_format(gantt.dateFromPos(relativePosition.x));
          }
        });

       
      });

      gantt.templates.grid_row_class = function (start_date, end_date, item) {


        // Create Date objects from the strings
        const end_dateval = new Date(end_date);
        const today = new Date();

        if (item.progress == 0 && end_dateval < today ) return "red";
      //  if (item.progress >= 1) return "green";
      };
    /*     gantt.templates.task_row_class = function (start_date, end_date, item) {
        if (item.progress == 0) return "red";
        if (item.progress >= 1) return "green";
      };
      */





   /* gantt.config.columns = [
        {name: "text", label: "Project / Task", width: 200, tree: true, resize: true },
        {name: "start_date", label: "Start date", align: "center", width: 90,     template: (task) => formatDate(task.start_date), resize: true},
        {name: "duration", label: "Duration", align: "center", width: 60, resize: true}
    ]; */

    gantt.config.columns = [
      {name: "text", label: "Project / Task", tree: true, align: "left", width: '*'},
      {name: "details", label: "Details", align: "center", width: '*',  hide: true},
      {name: "start_date", label: "Start date", align: "center", width: 90,     template: (task) => formatDate(task.start_date), resize: true},
      {
        name: "progress", label: "Progress", width: 80, align: "center",
        template: function (item) {
          if (item.progress >= 1)
            return "Complete";
          if (item.progress == 0)
            return "Yet to Start";
          return Math.round(item.progress * 100) + "%";
        }
      },
      {name: "duration", label: "Duration", align: "center", width: 60,  hide: true}
    ];


    // Only available in Pro
    gantt.config.layout = {
      css: "gantt_container",
      rows:[
        {
          cols: [
            {view: "grid", id: "grid", scrollX:"scrollHor", scrollY:"scrollVer"},
            {resizer: true, width: 1},
            {view: "timeline", id: "timeline", scrollX:"scrollHor", scrollY:"scrollVer"},
            {view: "scrollbar", scroll: "y", id:"scrollVer"}
          ]
         },
        {view: "scrollbar", scroll: "x", id:"scrollHor", height:20}
      ]
    };

    gantt.templates.task_class = function (start, end, task) {
		switch (task.status) {
			case "project":
				return "project";
				break;
			case "sent":
				return "sent";
				break;
			case "in_progress":
				return "in_progress";
				break;
            case "contractor_accepted":
                return "contractor_accepted";
                break;
            case "supp_info_submitted":
                return "supp_info_submitted";
                break;
            case "completed":
                return "completed";
                break;
            case "approved_closed":
                return "approved_closed";
                break;
            case "cancelled":
                return "cancelled";
                break;
      }
    };

    gantt.templates.scale_cell_class = function (date) {
      if (date.getDay() == 0 || date.getDay() == 6) {
        return "weekend";
      }
    };
    gantt.templates.timeline_cell_class = function (item, date) {
      if (date.getDay() == 0 || date.getDay() == 6) {
        return "weekend"
      }
    };

    gantt.init(ganttContainer.current);


    let today = new Date();
    let todayMarker = gantt.addMarker({ 
      start_date: today, 
      css: "today", 
      text: 'Now',
      });
    if (tasks) {
   
      // Parse the tasks data
      gantt.parse(tasks);


      // Ensure full-day tasks are properly displayed
      gantt.attachEvent("onTaskLoading", function (task) {
        if (task.start_date.getTime() === task.end_date.getTime()) {
            task.end_date.setHours(23, 59, 59);
        }
        return true;
    });

    // Correct duration calculation if needed
    gantt.attachEvent("onTaskCreated", function (task) {
        if (task.start_date && task.end_date) {
            task.duration = gantt.calculateDuration(task.start_date, task.end_date);
        }
        return true;
    });

    // Function to calculate duration
    gantt.calculateDuration = function (start, end) {
        var start_date = gantt.date.date_part(new Date(start));
        var end_date = gantt.date.date_part(new Date(end));
        var duration = gantt.date.add(end_date, 1, "day") - start_date;
        return Math.ceil(duration / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    };


   

      // Sort tasks by start_date column
      gantt.sort("start_date", false);

   //   gantt.showDate(today);
    
    } else {
      console.error('Invalid data format:', tasks);
    }

   // gantt.resetLayout();
    return () => {
      gantt.clearAll();
    }; }
  }, [tasks ,config]);

/* useEffect(() => {
    if (exportPDF) {
        exportPDF.current = () => {
         gantt.exportToPDF()
        }
    }
  }, [exportPDF]);  */


 /* useEffect(() => {
    if (scrollToToday) {
      scrollToToday.current = () => {
        const today = new Date();
        gantt.showDate(today);
      };
    }
  }, [scrollToToday]); */

  


  const closeModal = () => {
      setModalIsOpen(false);
  };


  const scrollToToday = () => {
    const today = new Date();
    gantt.showDate(today);
  };

  const exportToExcel = () => {
    gantt.exportToExcel()
   // gantt.exportToPDF()

  };
  const exportToPDF = () => {

    gantt.exportToPDF()

  };

  const exportToProject = () => {

    gantt.exportToMSProject()

  };








  const showAll = () => {
    
    // clear the data
    let getProjectsLink = `projects/projgantt/${site}`
    console.log("getProjectsLink:", getProjectsLink)
     try {
       fetch(`${process.env.API_ROOT}${getProjectsLink}`, {
         method: 'GET',
         credentials: 'include',
       }).then(async (response) => {
         const data = await response.json();
         console.log("dataret:", data)

        // get links
        let urlLinks = `projects/projganttlinks/${site}`;
        const linkresponse = await fetch(`${process.env.API_ROOT}${urlLinks}`, {
          method: 'GET',
          credentials: 'include',
        });
        const linksdata = await linkresponse.json();

        if (linksdata) {
          setGanttData({ tasks: data ,links:linksdata  });
         
        }
        else if (data) {
          setGanttData({ tasks: data });
        } else {
          console.error('Invalid data format:', data);
      }


        // setprojectsList(dataret)
       });
       }  
       catch (error) {
         console.error(error); /* eslint-disable-line */
       }


  };


  return (
  
    <div>
      <table  style={{ width: '100%' }}>
        <tbody>
          <tr>
              <td style={{ width: '5%' }}><input  type="text"
                    placeholder="Search project name"
                    value={searchText}
                    onChange={handleSearchChange}
                    style={{ width: '200px' }}
                    />  
              </td> 
              <td style={{ width: '82%' }}>
              <Tooltip disableFocusListener title="Clear Search">
                  <button onClick={showAll}><Autorenew style={{ fontSize: 20, color: "#69CC84", verticalAlign: -5, }} /></button>
              </Tooltip>
                
              </td>
                
              <td  style={{ paddingRight: '1em' }}>
              <Tooltip disableFocusListener title="Scroll to Today">
                 <button onClick={scrollToToday}><CalendarTodayOutlined style={{ fontSize: 20, color: "#69CC84", verticalAlign: -5, }} /></button>
              </Tooltip>
              </td>
                
              <td style={{ paddingRight: '1em' }}>
              <Tooltip disableFocusListener title="Export to Excel">
                 <button onClick={exportToExcel}><InsertChartOutlined style={{ fontSize: 20, color: "#69CC84", verticalAlign: -5, }} /></button>
              </Tooltip>
              </td>
              <td >
              <Tooltip disableFocusListener title="Export to PDF">
                 <button onClick={exportToPDF}><PictureAsPdfOutlined style={{ fontSize: 20, color: "#69CC84", verticalAlign: -5, }} /></button>
              </Tooltip>
              </td>
              <td style={{ paddingRight: '1em' }}>
              <Tooltip disableFocusListener title="Export to MS Project">
                 <button onClick={exportToProject}><AccountTreeOutlined style={{ fontSize: 20, color: "#69CC84", verticalAlign: -5, }} /></button>
              </Tooltip>
              </td>
              

           



          </tr>

        </tbody>


      </table>


      <div ref={ganttContainer} style={{ width: '100%', height: '600px' }} />
      <ViewProjectGantt 
          content={selectedTask}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onClose={() => setConfirmOpen(false)}
          title="Details"/>
  
      
</div> 
  
  );
};

export default GanttChart;