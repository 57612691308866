import gql from 'graphql-tag';

export const GET_TASKS = gql`
  query tasksForContractor($contractorId: ID!, $search: String, $pagination: PaginationInput) {
    contractor(id: $contractorId) {
      id
      tasks(search: $search, pagination: $pagination) {
        nodes {
          id
          type
          subform
          contractorResponsibleWorkOrderId
          contractorResponsibleUser {
            id
            name
          }
          assets {
            asset {
              system {
                name
              }
            }
            compliant
          }
          compliant
          deadline
          documentDeadline
          status
        }
        totalNodes
        page
        totalPages
      }
    }
  }
`;

export const GET_TASK = gql`
  query taskForContractor($taskId: ID!) {
    task(id: $taskId) {
      id
      timer {
        timeSpent
      }
      status
      approval {
        id
        name
        email
        phonenumber
        picture {
          id
          srcUrl
          originalName
        }
      }
      site {
        id
        name
        companyName
      }
      contractorResponsibleWorkOrderId
      notes
      urgent
      attachments {
        id
        originalName
        srcUrl
      }
      assets {
        id
        compliant
        asset {
          id
          name
          system {
            id
            name
          }
        }
      }
      correctiveOf {
        id
        idReadable
      }
      corrective {
        id
        idReadable
      }
    }
  }
`;

export const TASK_SEND_FOR_RE_APPROVAL = gql`
  mutation taskSendForReApproval($id: ID!) {
    taskSendForReApproval(id: $id) {
      id
      status
    }
  }
`;

export const GET_TECHNICIANS = gql`
  query getTechnicians($contractorId: ID!, $taskId: ID!) {
    contractor(id: $contractorId) {
      users {
        id
        name
        picture {
          id
          srcUrl
        }
        role
        clients(accessStatus: ACCEPTED) {
          id
          companyName
        }
        upcomingTasksCount
      }
    }
    task(id: $taskId) {
      id
      clientId
    }
  }
`;

export const ASSIGN_TO_CONTRACTOR_USER = gql`
  mutation taskAssignToContractorUser($taskId: ID!, $userId: ID!) {
    taskAssignToContractorUser(taskId: $taskId, userId: $userId) {
      id
      contractorResponsibleUser {
        id
        name
      }
    }
  }
`;

export const GET_ASSET = gql`
  query getAssetForTask($assetId: ID!) {
    asset(id: $assetId) {
      id
      name
      type
      system {
        id
        type
        name
      }
      createdAt
      owner {
        id
        name
      }
      department
      site {
        name
      }
      coordinates {
        longitude
        latitude
      }
      additionalInformation
      drawings {
        id
        file {
          id
          originalName
          extension
        }
      }
    }
  }
`;
