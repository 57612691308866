import React from 'react';
import { FormContent } from 'shared/assetFormContent.interface';
import { doesValueComply } from 'shared/assetFormValuesChecker';
import compliantIcon from 'assets/compliantIcon.svg';
import nonCompliantIcon from 'assets/nonCompliantIcon.svg';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => {
  return createStyles({
    icon: {
      display: 'inline-block',
    },
  });
});

const ParameterComplianceIcon: React.FC<{
  parameterName: string;
  value: string | null | undefined;
  formTemplateContent: FormContent;
}> = ({ value, parameterName, formTemplateContent }) => {
  const styles = useStyles();
  const compliance = doesValueComply(parameterName, value, formTemplateContent);

  if (compliance === true) return <img alt="compliant" src={compliantIcon} className={styles.icon} />;
  if (compliance === false) return <img alt="non compliant" src={nonCompliantIcon} className={styles.icon} />;

  return <></>;
};
export default ParameterComplianceIcon;
