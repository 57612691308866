import { TaskStatus, TaskUpdateInput } from 'models/graphql';

const taskEditableFields: Record<TaskStatus, Array<keyof TaskUpdateInput>> = {
  [TaskStatus.UNASSIGNED]: [
    'contractorId',
    'internalUserId',
    'workOrderId',
    'approvalUserId',
    'documentDeadline',
    'deadline',
    'fileIds',
    'urgent',
    'notes',
    'tasktitle',
  ],
  [TaskStatus.SENT]: ['fileIds', 'deadline', 'documentDeadline'],
  [TaskStatus.CONTRACTOR_ACCEPTED]: ['fileIds', 'deadline', 'documentDeadline'],
  [TaskStatus.CONTRACTOR_REJECTED]: ['fileIds', 'deadline', 'documentDeadline'],
  [TaskStatus.IN_PROGRESS]: ['fileIds', 'deadline', 'documentDeadline'],
  [TaskStatus.COMPLETED]: ['deadline', 'documentDeadline'],
  [TaskStatus.SUPP_INFO_SUBMITTED]: [],
  [TaskStatus.REVIEW_REQ_SENT]: [],
  [TaskStatus.REVIEW_REQ_ACCEPTED]: [],
  [TaskStatus.REVIEW_REQ_REJECTED]: [],
  [TaskStatus.CONSULTANT_REJECTED]: [],
  [TaskStatus.CONSULTANT_APPROVED]: [],
  [TaskStatus.REJECTED]: [],
  [TaskStatus.REJECTED_CLOSED]: [],
  [TaskStatus.APPROVED_CLOSED]: [],
  [TaskStatus.CANCELLED]: [],
};

export default taskEditableFields;
