import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { MenuItem, Theme, FormControlLabel, Checkbox } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Select, { SelectProps } from '@mui/material/Select';
import objectPropertyByString from 'utils/objectPropertyByString';
import { useFormikContext } from 'formik';
import cn from 'utils/cn';
import useFieldStyles from './AppField.style';
import AppFieldError from './AppFieldError';
import { useStores } from 'stores';





export const styleRules = (theme: Theme) =>
  createStyles({
    selectField: {
      '& .MuiSelect-root': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: theme.fontSizes['14'],
        '&:focus': {
          background: 'inherit',
        },
      },
      '&.Mui-disabled .MuiSelect-icon': {
        filter: 'grayscale(100%)',
      },
      '&.displayPlaceholder .MuiSelect-select': {
        color: theme.palette.grey[500],
      },
    },
    menuItem: {
      '&.Mui-selected': {
        backgroundColor: 'transparent', // No background color when selected
      },
      '&.Mui-selected:hover': {
        backgroundColor: theme.palette.action.hover, // Customize hover color if needed
      },
    },
    arrayEmptyText: {
      color: theme.palette.grey[500],
    },
    placeholderItem: {
      display: 'none',
    },
    addInnerPaddingRight: {
      paddingRight: theme.spacing(1),
    },
   
  });
const useStyles = makeStyles(styleRules);



export type AppSelectFieldType<T> = Omit<Omit<SelectProps, 'onChange'>, 'name'> & {
  name: string; // name is required
  options: {
    array: Array<T>;
    value: (arrayElement: T) => string | undefined;
    key: (arrayElement: T) => string;
    template: (arrayElement: T) => React.ReactNode;
    ifArrayEmpty?: React.ReactNode;
    disabled?: (arrayElement: T) => boolean; // Add disabled function to options type
  };
  label?: string;
  placeholder?: string;
  onChange?: (value: T[]) => void;
  value?: string[];
  preselectedValues?: string[]; // Add preselectedValues prop
  addInnerPaddingRight?: boolean;
};

export default function AppSelectField<T>({
  name,
  options,
  label: _label,
  placeholder,
  onChange,
  value: _value,
  required,
  className,
  disabled,
  preselectedValues = [], // Default to empty array if not provided
  addInnerPaddingRight,
  ...propsToPass
}: PropsWithChildren<AppSelectFieldType<T>>) {
  const { values, touched, errors, setFieldValue } = useFormikContext<Record<string, unknown>>();
  const { contextStore } = useStores();
  let value: string[] = _value || objectPropertyByString(values, name) || values[name] || [];

  if (!Array.isArray(value)) value = [];

  // Merge preselected values with the current value (to ensure they are preselected on render)
  useEffect(() => {
    if (preselectedValues.length > 0) {
      value = Array.from(new Set([...value, ...preselectedValues])); // Avoid duplicates
      setFieldValue(name, value);
    }
  }, [preselectedValues, name, setFieldValue]);

  const styles = useStyles();
  const fieldStyles = useFieldStyles();

  console.log ("Data PAssed through here")

  const control = () => {
    return options.array.length || !options.ifArrayEmpty || disabled ? (
      <Select
        multiple
        {...propsToPass}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        variant="outlined"
        error={objectPropertyByString(touched, name) && !!objectPropertyByString(errors, name)}
        onChange={(event) => {
          const selectedValues = event.target.value as string[];
          // Ensure at least one item is always selected
          if (selectedValues.length === 0) {
            return; // Prevent clearing the last selection
          }


          console.log('selectedValues:', selectedValues);
          setFieldValue(name, selectedValues);
          contextStore.setSelectedModules(selectedValues);
          if (onChange) {
            onChange(options.array.filter((element) => selectedValues.includes(options.value(element)!)));
          }
        }}
        renderValue={(selected) => {
          if ((selected as string[]).length === options.array.length) {
            return 'All';
          }
          return (selected as string[]).join(', ');
        }}
        displayEmpty
        className={cn(
          className,
          styles.selectField,
          fieldStyles.field,
          {
            displayPlaceholder: value.length === 0,
          },
          addInnerPaddingRight ? styles.addInnerPaddingRight : undefined,
        )}
      >
        {placeholder ? (
          <MenuItem value="" disabled className={styles.placeholderItem}>
            {placeholder}
          </MenuItem>
        ) : null}
        {options.array.map((arrayElement) => (
          <MenuItem
            value={options.value(arrayElement)}
            key={options.key(arrayElement)}
            disabled={options.disabled ? options.disabled(arrayElement) : false}
            className={styles.menuItem} // Apply the custom styles here
          >
            <Checkbox color="primary" 
              checked={value.includes(options.value(arrayElement)!)}
              disabled={options.disabled ? options.disabled(arrayElement) : false}
            />
            {options.template(arrayElement)}
          </MenuItem>
        ))}
      </Select>
    ) : (
      <div className={styles.arrayEmptyText}>{options.ifArrayEmpty}</div>
    )
  }

  return (
    <div>
      { _label ? <FormControlLabel
          labelPlacement="top"
          className={cn(fieldStyles.labelModules, { 'no-label': !_label })}
          label={_label}
          control={control()}
        /> : control() }

      <AppFieldError
        message={
          objectPropertyByString(touched, name) ? objectPropertyByString(errors, name) : undefined
        }
      />
    </div>
  );
}
