import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import moment from 'moment';  
import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(() => ({
  popupContainer: {
    backgroundColor: 'white',
    padding: '10px',
    fontSize: 'initial', // Prevent font size conflicts
    zIndex: 2000, // Ensure it appears above other elements
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '1px solid #ccc',
    padding: '8px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  td: {
    borderBottom: '1px solid #ccc',
    padding: '8px',
  },
}));

function capitalizeFirstLetter(passedstr: string) {
  return passedstr.charAt(0).toUpperCase() + passedstr.slice(1);
}


const TaskPopup = ({ feature }) => {
  const [taskData, setTaskData] = useState([]);
  const classes = useStyles(); // Call useStyles to get class names
  const columnsMUITable = [
    { name: "idreadable", label: "Task", options: { setCellProps: () => ({ style: { whiteSpace: "nowrap" } }) } },
    { name: "tasktitle", label: "Task Title" },
    { name: "tasktype", label: "Type" },
    { name: "deadline", label: "Deadline", options: { customBodyRender: (value: string | number | Date) => moment(new Date(value)).format('DD/MM/YYYY') } },
    { name: "internalresponsible", label: "Responsible" }
  ];

  const tableoptions = {
    filter: false,
    print: false,
    selectableRows: 'none',
    search: false,
    download: false,
    viewColumns: false,
    tableBodyHeight: "200px",
    tableBodyWidth: "400px",
    fixedHeader: true,
  };

  useEffect(() => {
    console.log("Passed Feature:", feature)
    const fetchTasks = async () => {
      try {
        const response = await fetch(`${process.env.API_ROOT}listings/tasksmap/${feature.properties.id}?initial=yes`, { method: 'GET', credentials: 'include' });
        const dataret = await response.json();
        const tasks = dataret.map((u: { taskid: any; status: any; tasktitle: any; idreadable: any; compliant: any; tasktype: any; internalresponsible: any; deadline: any; documentdeadline: any; asset: any; siteid:any;clientid:any;}) => ({
          taskid: u.taskid,
          status: u.status,
          tasktitle: u.tasktitle,
          idreadable: u.idreadable,
          compliant: u.compliant,
          tasktype: u.tasktype,
          internalresponsible: u.internalresponsible,
          deadline: u.deadline,
          documentdeadline: u.documentdeadline,
          asset:u.asset,
          siteid: u.siteid,
          clientid: u.clientid,
          tasklink:`${window.location.protocol}//${window.location.host}/client/${u.clientid}/tasks/${u.siteid}?search=${u.idreadable}`,
        }));
        setTaskData(tasks);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTasks();
  }, [feature]);

  return (
    <div  >
     
     <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.th}>Asset</th>
            <th className={classes.th}>Task</th>
            <th className={classes.th}>Task Title</th>
            <th className={classes.th}>Type</th>
            <th className={classes.th}>Deadline</th>
            <th className={classes.th}>Responsible</th>
            <th className={classes.th}>Status</th>
          </tr>
        </thead>
        <tbody>
          {taskData.length > 0 ? (
            taskData.map((task, index) => (
              <tr key={index}>
                 <td className={classes.td}>{task.asset}</td>
                <td className={classes.td}><a href={task.tasklink} target='_blank'>{task.idreadable}</a></td>
                <td className={classes.td}>{task.tasktitle}</td>
                <td className={classes.td}>{task.tasktype}</td>
                <td className={classes.td}>{moment(new Date(task.deadline)).format('DD/MM/YYYY')}</td>
                <td className={classes.td}>{task.internalresponsible}</td>
                <td className={classes.td}>{capitalizeFirstLetter(task.status)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className={classes.td} colSpan="7">No tasks available</td>
            </tr>
          )}
        </tbody>
      </table>
   
    </div>
  );
};

export default TaskPopup;
